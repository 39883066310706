import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import ActionButton from "../ActionButton";
import TextInput from "../inputs/TextInput";
import * as color from "../../constants/color";
import Modal from "../Modal";
import * as favoriteApi from "../../lib/api/favorite";
import blueCircleIcon from "../../images/blue-circle.png";
import Checkbox from "../inputs/Checkbox";

const styles = StyleSheet.create({
	content: {
		minWidth: 400,
	},
	container: {

	},
	header: {

	},
	listContainer: {
		marginBotton: 12,
		maxHeight: "65vh",
		overflowX: "scroll",
	},
	listItem: {
		padding: "12px 20px",
		borderBottom: `solid 1px ${color.lightGray2}`,
		fontSize: 18,
	},
	footer: {
		paddingBottom: 0,
		paddingTop: 20,
		paddingLeft: 20,
		paddingRight: 20,
	},
	newList: {
		float: "left",
		color: color.mediumYellow1,
		cursor: "pointer",
	},
	blueCircle: {
		marginTop: -3,
		marginRight: 8,
		cursor: "pointer",
		float: "left"
	},
	cancelButton: {
		flexGrow: 1,
		marginRight: 16,
		flexBasis: 0,
	},
	saveButton: {
		flexGrow: 1,
		marginLeft: 16,
		flexBasis: 0,
	},
	buttonContainer: {
		marginTop: 24,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-evenly",
	},
	inputContainer: {
		padding: "30px 30px",
	},
	modalContentContainerStyles: {
		padding: "0px 0px 20px 0px",
	},
	clear: {
		clear: "both",
	},
	check: {
		marginTop: 2,
	},
	label: {
		position: "relative",
		top: -4,
		left: 12,
	},
	btnSave: {
		float: "right",
		color: color.mediumYellow1,
		cursor: "pointer",
		marginTop: 2,
	},
	divider: {
		float: "right",
		color: color.darkGray,
		marginRight: 12,
		marginLeft: 12,
		marginTop: 2,
	},
	btnCancel: {
		float: "right",
		color: color.mediumYellow1,
		cursor: "pointer",
		marginTop: 2,
	}
});

const inputNames = {
	favoriteListName: "favoriteListName",
}

export default class FavoritePlanList extends Component {
	static propTypes = {
		params: PropTypes.object,
		onSave: PropTypes.func,
		onCancel: PropTypes.func,
	};

	constructor(props) {
		super(props);

		this._containerRef = React.createRef();

		this.state = {
			containerWidth: 0,
			containerHeight: 0,
			showNameInput: false,
			inputs: {
				[inputNames.favoriteListName]: "",
			},
			favoriteListForPlan: [],
			errors: [],
			selectedFavoriteListIds: [],
			favoriteList: [],
			loading: false,
		};
	}
	componentDidMount() {
		this._setContainerSize();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.params.show !== this.props.params.show) {
			this._setContainerSize();
			this.setState({
				loading: true,
			})

			this._getFavoriteListForPlan();

			favoriteApi.getList()
				.then(response => {
					this.setState({
						favoriteList: response,
						loading: false,
					});
				});
		}
	}

	_getFavoriteListForPlan = () => {
		const { planId, planType } = this.props.params;
		if (planId !== "all") {
			favoriteApi.getListsForPlan(planId, planType)
				.then(response => {
					this.setState({
						favoriteListForPlan: response,
						selectedFavoriteListIds: response.map(r => r.id),
					});
				});
		} else {
			this.setState({
				favoriteListForPlan: [],
				selectedFavoriteListIds: [],
			});
		}
	};

	_setContainerSize = () => {
		if (!this._containerRef.current) {
			return null;
		}

		this.setState({
			containerWidth: this._containerRef.current.offsetWidth,
			containerHeight: this._containerRef.current.offsetHeight,
		});
	};

	_handleSaveNewList = () => {
		const favoriteList = {
			name: this.state.inputs[inputNames.favoriteListName],
		};

		favoriteApi.saveNewList(favoriteList)
			.then(response => {
				favoriteApi.getList()
					.then(favoriteList => {
						this.setState({
							favoriteList,
							showNameInput: false
						});
					});
			});
	};

	_handleNewListClick = () => {
		this.setState({ showNameInput: true });
	};

	_handleCancelClick = () => {
		this.setState({ showNameInput: false });
	};

	_handleTextChanged = e => {
		const { name, value } = e.target;

		this.setState(prevState => {
			const errors = { ...prevState.errors };
			delete errors[name];

			return {
				inputs: {
					...prevState.inputs,
					[name]: value,
				},
				errors,
			};
		});
	};

	_handleListItemClicked = id => {
		this.props.onSave(null, id);
	};

	_handleCheckChanged = id => {
		const { selectedFavoriteListIds } = this.state;

		const index = selectedFavoriteListIds.indexOf(id);

		if (index > -1) {
			selectedFavoriteListIds.splice(index, 1);
		} else {
			selectedFavoriteListIds.push(id);
		}

		this.setState({
			selectedFavoriteListIds,
		});
	};

	_handleSaveClick = () => {
		this.props.onSave(null, this.state.selectedFavoriteListIds);
	};

	render() {
		const { params } = this.props;
		const {
			containerWidth,
			containerHeight,
			favoriteList,
			selectedFavoriteListIds,
			loading,
		} = this.state;

		return (
			<Modal
				show={this.props.params.show && !loading}
				header="Select a List"
				contentContainerStyles={styles.modalContentContainerStyles}
			>
				<div
					className={css(styles.content)}
					style={{
						left: params.pageX - containerWidth,
						top: params.pageY - containerHeight,
					}}>
					<div className={css(styles.container)} ref={this._containerRef}>
						{this.state.showNameInput ? (
							<div className={css(styles.inputContainer)}>
								<label>Give your list a name:</label>
								<TextInput
									name={inputNames.favoriteListName}
									onChange={this._handleTextChanged}
									placeholder="Default List Name"
									validationMessage={this.state.errors[inputNames.favoriteListName]}
									value={this.state.inputs[inputNames.favoriteListName]}
								/>
								<div className={css(styles.buttonContainer)}>
									<ActionButton
										aStyles={styles.cancelButton}
										label="Cancel"
										invertColor={true}
										type="button"
										onClick={this._handleCancelClick}
									/>
									<ActionButton
										aStyles={styles.saveButton}
										label="Save"
										type="submit"
										isLoading={this.state.isSaving}
										onClick={this._handleSaveNewList}
									/>
								</div>
							</div>
						) : (
							<div>
								<div className={css(styles.header)}>

								</div>
								<div className={css(styles.listContainer)}>
									{favoriteList.map((f, index) => (
										<div key={index} className={css(styles.listItem)}>
											<Checkbox
												name={"FavoriteSelect_" + index}
												aStyles={styles.check}
												checked={selectedFavoriteListIds.filter(item => f.id === item).length > 0}
												onChange={() => this._handleCheckChanged(parseInt(f.id))}
											/>
											<span
												key={f.id}
												className={css(styles.label)}
											>
												{f.name}
											</span>
										</div>
									))}
								</div>
								<div className={css(styles.footer)}>
									<img alt="" src={blueCircleIcon} className={css(styles.blueCircle)} onClick={this._handleNewListClick} />
									<div className={css(styles.newList)} onClick={this._handleNewListClick}>New List</div>
									<div className={css(styles.btnSave)} onClick={this._handleSaveClick}>Save</div>
									<div className={css(styles.divider)}>|</div>
									<div className={css(styles.btnCancel)} onClick={() => this.props.onCancel()}>Cancel</div>
									<div className={css(styles.clear)} />
								</div>
							</div>
						)}
					</div>
				</div>
			</Modal>
		);
	}
}