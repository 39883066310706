import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import Infinite from "../Infinite";
import * as favoriteApi from "../../lib/api/favorite";
import * as planColumns from "../../constants/planColumns";
import * as color from "../../constants/color";
import backCircle from "../../images/SVGs/BackCircle.svg";
import FavoritePlanList from "./FavoritePlanList";

const styles = StyleSheet.create({
    container: {
        marginTop: 92,
    },
    sectionHeader: {
        paddingTop: 18,
        paddingBottom: 18,
        backgroundColor: color.lightGray4,
        borderTop: `1px solid ${color.lightGray2}`,
        borderBottom: `1px solid ${color.lightGray2}`,
    },
    sectionTitle: {
        color: color.darkRed,
        fontSize: 20,
        padding: "16px 16px",
        borderBottom: `1px solid ${color.lightGray2}`,
    },
    backButtonImage: {
        display: "block",
    },
    backButton: {
        position: "relative",
        cursor: "pointer",
        border: "none",
        backgroundColor: "transparent",
        display: "block",
        marginLeft: 12,
        marginRight: 16,
        outline: "none",
        height: 36,
    },
    planCount: {
        textAlign: "right",
        float: "right",
        position: "relative",
        top: -28,
        fontSize: 14,
        marginRight: 18,
        color: color.darkestGray,
    },
    favoriteListName: {
        textAlign: "center",
        position: "relative",
        left: "50%",
        transform: "translateX(-50%)",
        float: "left",
        top: -34,
        padding: "4px 24px",
        border: `1px solid ${color.darkGray}`,
        borderRadius: 4,
        backgroundColor: color.white,
        fontSize: 20,
    },
    clear: {
        clear: "both",
    },
});


const strings = {
    medicalPlans: "Medical Plans",
    dentalPlans: "Dental Plans",
    visionPlans: "Vision Plans",
};

export default class FavoriteListPlans extends Component {
    static propTypes = {
        plans: PropTypes.object.isRequired,
        onBack: PropTypes.func.isRequired,
        onUpdatePlans: PropTypes.func.isRequired,
        favoriteListName: PropTypes.string,
        favoriteListId: PropTypes.number,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            favoriteClickParams: {
                pageX: 0,
                pageY: 0,
                show: false,
                planId: "",
                planType: "",
            },
        };
    }

    componentDidMount() {
        document.body.addEventListener("click", this._handleBodyClicked);
    }

    componentWillUnmount() {
        document.body.removeEventListener("click", this._handleBodyClicked);
    }

    _handleFavoriteMedicalPlanClick = (e, id) => {
        favoriteApi.removePlanFromList(id, "Medical", this.props.favoriteListId)
            .then(() => {
                this.setState(prevState => {
                    return {
                        favoriteClickParams: {
                            ...prevState.favoriteClickParams,
                            show: false,
                        },
                    };
                });

                this.props.onUpdatePlans();
            });
    };

    _handleFavoriteDentalPlanClick = (e, id) => {
        favoriteApi.removePlanFromList(id, "Dental", this.props.favoriteListId)
            .then(() => {
                this.setState(prevState => {
                    return {
                        favoriteClickParams: {
                            ...prevState.favoriteClickParams,
                            show: false,
                        },
                    };
                });

                this.props.onUpdatePlans();
            });
    };

    _handleFavoriteVisionPlanClick = (e, id) => {
        favoriteApi.removePlanFromList(id, "Vision", this.props.favoriteListId)
            .then(() => {
                this.setState(prevState => {
                    return {
                        favoriteClickParams: {
                            ...prevState.favoriteClickParams,
                            show: false,
                        },
                    };
                });

                this.props.onUpdatePlans();
            });
    };

    _handleFavoriteSaved = (name, ids) => {
        this._saveFavoritePlan(this.state.favoriteClickParams.planId, ids, this.state.favoriteClickParams.planType);
    };

    _saveFavoritePlan = (planId, favoriteListIds, planType) => {
        favoriteApi.addPlan(planType, planId, favoriteListIds)
            .then(() => {
                this.setState(prevState => {
                    return {
                        favoriteClickParams: {
                            ...prevState.favoriteClickParams,
                            show: false,
                        },
                    };
                });

                this.props.onUpdatePlans();
            });
    };

    _handleFavoriteCanceled = () => {
        this.setState(prevState => {
            return {
                favoriteClickParams: {
                    ...prevState.favoriteClickParams,
                    show: false,
                },
            };
        });
    };

    _handleBodyClicked = e => {
        if (e.target && e.target.className && typeof e.target.className.includes === "function" && e.target.className.includes("modalBackground")) {
            this.setState(prevState => {
                return {
                    favoriteClickParams: {
                        ...prevState.favoriteClickParams,
                        show: false,
                    },
                };
            });
        }
    };

    render() {
        const { plans, favoriteListName } = this.props;
        return (
            <div className={css(styles.container)}>
                <div className={css(styles.sectionHeader)}>
                    <button className={css(styles.backButton)} type="button" onClick={this.props.onBack}>
                        <img src={backCircle} className={css(styles.backButtonImage)} alt="back" />
                    </button>
                    <span className={css(styles.favoriteListName)}>{favoriteListName}</span>
                    <div className={css(styles.planCount)}>
                        Plans: {plans.medicalPlans.length ? (<span>{plans.medicalPlans.length} Medical</span>) : null}
                        {plans.dentalPlans.length ? (<span>, {plans.dentalPlans.length} Dental</span>) : null}
                        {plans.visionPlans.length ? (<span>, {plans.visionPlans.length} Vision</span>) : null}
                    </div>
                </div>
                {plans.medicalPlans.length > 0 &&
                    <div>
                        <div className={css(styles.sectionTitle)}>{strings.medicalPlans}</div>
                        <Infinite
                            results={plans.medicalPlans}
                            length={plans.medicalPlans.length}
                            columnLabels={planColumns["favmedicalColumnLabels"]}
                            columnValues={planColumns["favmedicalColumnValues"]}
                            showChevron={false}
                            checkBox={false}
                            checkList={null}
                            showMore={false}
                            onClick={() => { }}
                            onScroll={() => { }}
                            onFavoriteClick={this._handleFavoriteMedicalPlanClick}
                        />
                    </div>
                }
                {plans.dentalPlans.length > 0 &&
                    <div>
                        <div className={css(styles.sectionTitle)}>{strings.dentalPlans}</div>
                        <Infinite
                            results={plans.dentalPlans}
                            length={plans.dentalPlans.length}
                            columnLabels={planColumns["favdentalColumnLabels"]}
                            columnValues={planColumns["favdentalColumnValues"]}
                            showChevron={false}
                            checkBox={false}
                            checkList={null}
                            showMore={false}
                            onClick={() => { }}
                            onScroll={() => { }}
                            onFavoriteClick={this._handleFavoriteDentalPlanClick}
                        />
                    </div>
                }
                {plans.visionPlans.length > 0 &&
                    <div>
                        <div className={css(styles.sectionTitle)}>{strings.visionPlans}</div>
                        <Infinite
                            results={plans.visionPlans}
                            length={plans.visionPlans.length}
                            columnLabels={planColumns["favvisionColumnLabels"]}
                            columnValues={planColumns["favvisionColumnValues"]}
                            showChevron={false}
                            checkBox={false}
                            checkList={null}
                            showMore={false}
                            onClick={() => { }}
                            onScroll={() => { }}
                            onFavoriteClick={this._handleFavoriteVisionPlanClick}
                        />
                    </div>
                }
                <div className={css(styles.clear)} />
                <FavoritePlanList
                    params={this.state.favoriteClickParams}
                    onSave={this._handleFavoriteSaved}
                    onCancel={this._handleFavoriteCanceled}
                />
            </div>
        );
    }
}