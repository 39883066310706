import * as color from "../../constants/color";
import * as routeHelpers from "../../lib/routeHelpers";
import SearchInput from "../inputs/SearchInput";
import TextInput from "../inputs/TextInput";
import PageTitle from "../text/PageTitle";
import Checkbox from "../inputs/Checkbox";
import Dropdown from "../inputs/Dropdown";
import logger from "../../lib/logger";
import * as texchangePropTypes from "../../texchangePropTypes";
import describeNetworkError from "../../lib/describeNetworkError";
import Headline from "../text/Headline";
import ActionButton from "../ActionButton";
import React, { Component } from "react";
import { Container, Row, Column } from "../grid";
import { StyleSheet, css } from "aphrodite";
import * as dataApi from "../../lib/api/data";
import * as agenciesApi from "../../lib/api/agencies";
import * as brokersApi from "../../lib/api/brokers";
import * as searchApi from "../../lib/api/search";
import * as util from "../../lib/util";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = StyleSheet.create({
    content: {
        paddingRight: 10,
        paddingBottom: 80,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
    },
    brokerForm: {
        padding: "0px 80px 0px 80px",
    },
    contentSection: {
        width: "100%",
        padding: 0,
        backgroundColor: color.white,
        borderTop: `8px solid ${color.mediumRed}`,
        marginBottom: 20,
    },
    title: {
        fontSize: 20,
        fontWeight: 500,
        paddingBottom: 0,
        marginBottom: 4,
    },
    titleWithMargin: {
        fontSize: 20,
        fontWeight: 500,
        paddingBottom: 0,
        marginTop: 26,
        marginBottom: 14,
    },
    titleSection: {
        heigth: 64,
    },
    instructions: {
        color: color.darkestGray,
        fontWeight: 300,
        fontSize: 20,
        marginLeft: 24,
    },
    instructionsSmall: {
        color: color.darkestGray,
        fontWeight: 300,
        fontSize: 15,
        padding: "10px 0px",
    },
    dropdown: {
        marginTop: 10,
        marginBottom: 10,
    },
    checkboxContainer: {
        textAlign: "right",
        marginTop: 12,
    },
    checkLabel: {
        display: "inline-block",
        position: "relative",
        border: "none",
        marginTop: 8,
        marginBottom: 10,
        "-webkit-appearance": "none",
        cursor: "pointer",
        ":first-child": {
            marginRight: 24,
        }
    },
    check: {
        position: "absolute",
        top: -2,
        left: 0,
        marginRight: 10,
    },
    labelText: {
        marginLeft: 28,
        fontSize: 14,
        marginRight: 12,
    },
    buttonContainer: {
        height: 80,
        width: "100%",
        margin: "60px auto 0px auto",
    },
    buttons: {
        width: "100%",
        textAlign: "center",
    },
    brokerSearch: {
        zIndex: 7000,
    }
});

const strings = {
    title: "Brokers",
    instructions: "Add a broker to the system",
    instructionsLookup: "Please type the broker's first and last name, so we can check our database for existing records",
    instructionsNameConfirm: "is not in our system.  Would you like to add this person?",
    addBroker: "Add a Broker",
    broker: "Broker",
    namePlaceholder: "Broker Name",
    agencyPlaceholder: "Choose Agency",
    phonePlaceholder: "Phone number",
    cellPhonePlaceholder: "Mobile phone",
    emailPlaceholder: "Email address",
    licensePlaceholder: "License number",
    nationalProducerNumberPlaceholder: "NPN number",
    faxPlaceholder: "Fax number",
    dateOfBirthPlaceholder: "Date of birth",
    streetAddressPlaceholder: "Street Address",
    cityPlaceholder: "City",
    statePlaceholder: "State",
    zipPlaceholder: "Zip",
    continueButton: "Continue",
    submitButton: "Submit",
    cancelButton: "Cancel",
    required: "Please fill out this field",
    soleProprietor: "Check box if Sole Proprietor",
};

const inputNames = {
    fullName: "fullName",
    agencyId: "agencyId",
    phone: "phone",
    cellPhone: "cellPhone",
    emailAddress: "emailAddress",
    license: "license",
    nationalProducerNumber: "nnationalProducerNumberpn",
    fax: "fax",
    dateOfBirth: "dateOfBirth",
    soleProprietor: "soleProprietor",
    street1: "street1",
    street2: "street2",
    city: "city",
    stateId: "stateId",
    postalCode: "postalCode",
};

export default class BrokerForm extends Component {
    constructor(props) {
        super(props);

        this._searchRef = React.createRef();
    }

    static propTypes = {
        history: texchangePropTypes.history.isRequired
    };

    state = {
        inputs: {
            [inputNames.fullName]: "",
            [inputNames.agencyId]: "",
            [inputNames.phone]: "",
            [inputNames.cellPhone]: "",
            [inputNames.emailAddress]: "",
            [inputNames.license]: "",
            [inputNames.nationalProducerNumber]: "",
            [inputNames.fax]: "",
            [inputNames.dateOfBirth]: "",
        },
        loading: true,
        errors: {},
        states: [],
        agencies: [],
        didLookup: false,
        didNameConfirm: false,
    };

    componentDidMount() {
        Promise.all([dataApi.getStates(), agenciesApi.getAll()])
            .then(responses => {
                this.setState({
                    loading: false,
                    states: responses[0],
                    agencies: responses[1],
                });
            })
            .catch(err => logger.log(err))
            .finally(() => this.setState({ ready: true }));
    }

    _handleTextChange = e => {
        const { name } = e.target;
        let { value } = e.target;

        if (name === inputNames.phone || name === inputNames.cellPhone) {
            value = util.maskPhoneNumber(value);
        } else if (name === inputNames.postalCode) {
            if (value && value.length === 6) {
                return;
            }
        }

        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
            };
        });
    };

    _handleInputChange = e => {
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: val,
                },
                errors,
            };
        });
    };

    _handleDOBChange = date => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.dateOfBirth];
            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.dateOfBirth]: date,
                },
            };
        });
    };

    _handleAgencyChange = e => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.agencyId];
            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.agencyId]: e.value,
                },
                errors,
            };
        });
    };

    _searchResultSelected = (item) => {
        if (item.type === "Broker") {
            this.props.history.push(routeHelpers.brokerDetail(item.value));
        }
    };

    _goToBrokers = () => {
        this.props.history.push(routeHelpers.brokers());
    };

    _validForm = () => {
        const errors = {};
        const { inputs } = this.state;

        Object.keys(inputs).forEach(stateKey => {
            if (stateKey === inputNames.street2 || stateKey === inputNames.cellPhone
                || stateKey === inputNames.fax || stateKey === inputNames.license || stateKey === inputNames.nationalProducerNumber ||
                (stateKey === inputNames.agencyId && this.state.inputs[inputNames.soleProprietor])) {
                return;
            }

            if (inputs[stateKey] === null || inputs[stateKey] === "") {
                errors[stateKey] = strings.required;
            }
        });

        this.setState({ errors });

        return !Object.keys(errors).length;
    };

    _submit = e => {
        e.preventDefault();
        if (this.state.loading) {
            return;
        }

        //if you finished the search...
        if (!this.state.didLookup) {
            const brokerName = this._searchRef.current.state.value;
            const regName = /^[a-zA-Z]+ [a-zA-Z]+$/;
            if (!regName.test(brokerName)) {
                toast.warn("Please enter first and last name with alpha characters only.", { toastId: 1 });
                return;
            }
            //passed
            this.setState(prevState => {
                return {
                    didLookup: true,
                    inputs: {
                        ...prevState.inputs,
                        [inputNames.fullName]: brokerName,
                    },
                };
            });
            return;
        }

        //if you are confirming the name...
        if (!this.state.didNameConfirm) {
            this.setState({
                didNameConfirm: true,
            });
            return;
        }

        //else...  try to save...
        if (!this._validForm()) {
            return;
        }

        this.setState({ isSaving: true });

        const { inputs } = this.state;
        brokersApi
            .saveBroker(inputs)
            .then(() => {
                toast.success("Broker saved", { toastId: 2 });
                setTimeout(() => {
                    this._goToBrokers();
                }, 2500);
            })
            .catch(err => {
                logger.warn(err);
                let errors = {};
                if (err && err.response) {
                    const { response } = err;
                    if (
                        response &&
                        response.data &&
                        response.data.errors &&
                        Object.keys(response.data.errors).length > 0
                    ) {
                        // Server provided validation messages
                        errors = response.data.errors;
                    } else {
                        // 500 or something else unexpected
                        const description = describeNetworkError(err);
                        toast.info(description.join("\n"), { toastId: 3 });
                    }
                }

                this.setState({ errors });
            })
            .finally(() => this.setState({ isSaving: false }));
    };

    render() {
        if (this.state.loading) {
            return null;
        }

        return (
            <div className={css(styles.content)}>
                <ToastContainer position="top-center" autoClose={2500} />
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.titleSection)}>
                        <PageTitle>{strings.title}</PageTitle>
                    </div>
                    <p className={css(styles.instructions)}>
                        {strings.instructions}
                    </p>
                    <div className={css(styles.brokerForm)}>
                        <form onSubmit={this._submit}>
                            <Container>
                                <Row>
                                    <Column large={6} largeOffset={3}>
                                        <Headline aStyles={styles.title}>{strings.addBroker}</Headline>
                                        {!this.state.didLookup ? (
                                            <div>
                                                <div className={css(styles.instructionsSmall)}>{strings.instructionsLookup}</div>
                                                <SearchInput
                                                    ref={this._searchRef}
                                                    aStyles={styles.searchBox}
                                                    search={searchApi.searchBrokers}
                                                    onItemSelected={this._searchResultSelected}
                                                    placeholder="Enter first and last name"
                                                    hideLabel={true}
                                                />
                                            </div>
                                        ) : !this.state.didNameConfirm ? (
                                            <div>
                                                <div className={css(styles.instructionsSmall)}>
                                                    <span className={css(styles.title)}>{this.state.inputs[inputNames.fullName]}</span>
                                                    <br />
                                                    {strings.instructionsNameConfirm}
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <TextInput
                                                    name={inputNames.fullName}
                                                    onChange={this._handleTextChange}
                                                    placeholder={strings.namePlaceholder}
                                                    required={true}
                                                    validationMessage={this.state.errors[inputNames.fullName]}
                                                    value={this.state.inputs[inputNames.fullName]}
                                                    disabled={true}
                                                />
                                                <div className={css(styles.checkboxContainer)}>
                                                    <label className={css(styles.checkLabel)}>
                                                        <Checkbox
                                                            aStyles={styles.check}
                                                            checked={this.state.inputs[inputNames.soleProprietor]}
                                                            name={inputNames.soleProprietor}
                                                            onChange={this._handleInputChange}
                                                        />
                                                        <span className={css(styles.labelText)}>{strings.soleProprietor}</span>
                                                    </label>
                                                </div>
                                                {!this.state.inputs[inputNames.soleProprietor] &&
                                                    <Dropdown
                                                        name={inputNames.agencyId}
                                                        aStyles={styles.dropdown}
                                                        options={Object.keys(this.state.agencies).map(id => ({
                                                            label: this.state.agencies[id].name,
                                                            value: this.state.agencies[id].id.toString(),
                                                        }))}
                                                        onChange={this._handleAgencyChange}
                                                        placeholder={strings.agencyPlaceholder}
                                                        required={true}
                                                        validationMessage={this.state.errors[inputNames.agencyId]}
                                                        value={this.state.inputs[inputNames.agencyId]}
                                                        hideLabel={true}
                                                    />
                                                }
                                                <TextInput
                                                    name={inputNames.phone}
                                                    onChange={this._handleTextChange}
                                                    placeholder={strings.phonePlaceholder}
                                                    required={true}
                                                    validationMessage={this.state.errors[inputNames.phone]}
                                                    value={this.state.inputs[inputNames.phone]}
                                                />
                                                <TextInput
                                                    name={inputNames.cellPhone}
                                                    onChange={this._handleTextChange}
                                                    placeholder={strings.cellPhonePlaceholder}
                                                    required={false}
                                                    value={this.state.inputs[inputNames.cellPhone]}
                                                />
                                                <TextInput
                                                    name={inputNames.emailAddress}
                                                    onChange={this._handleTextChange}
                                                    placeholder={strings.emailPlaceholder}
                                                    required={true}
                                                    type="email"
                                                    validationMessage={this.state.errors[inputNames.emailAddress]}
                                                    value={this.state.inputs[inputNames.emailAddress]}
                                                />
                                                <TextInput
                                                    name={inputNames.license}
                                                    onChange={this._handleTextChange}
                                                    placeholder={strings.licensePlaceholder}
                                                    required={false}
                                                    value={this.state.inputs[inputNames.license]}
                                                />
                                                <TextInput
                                                    name={inputNames.nationalProducerNumber}
                                                    onChange={this._handleTextChange}
                                                    placeholder={strings.nationalProducerNumberPlaceholder}
                                                    required={false}
                                                    value={this.state.inputs[inputNames.nationalProducerNumber]}
                                                />
                                                <TextInput
                                                    name={inputNames.fax}
                                                    onChange={this._handleTextChange}
                                                    placeholder={strings.faxPlaceholder}
                                                    required={false}
                                                    value={this.state.inputs[inputNames.fax]}
                                                />
                                                <TextInput
                                                    name={inputNames.dateOfBirth}
                                                    onChange={this._handleTextChange}
                                                    placeholder={strings.dateOfBirthPlaceholder}
                                                    required={true}
                                                    validationMessage={this.state.errors[inputNames.dateOfBirth]}
                                                    value={this.state.inputs[inputNames.dateOfBirth]}
                                                />
                                            </div>
                                        )}
                                    </Column>
                                </Row>
                                <Row>
                                    <div className={css(styles.buttonContainer)}>
                                        <Column large={3} largeOffset={3}>
                                            <ActionButton
                                                aStyles={styles.buttons}
                                                disabled={this.state.loading}
                                                label={strings.cancelButton}
                                                invertColor={true}
                                                type="button"
                                                onClick={this._goToBrokers}
                                            />
                                        </Column>
                                        <Column large={3}>
                                            <ActionButton
                                                aStyles={styles.buttons}
                                                disabled={this.state.loading}
                                                label={!this.state.didNameConfirm ? strings.continueButton : strings.submitButton}
                                                type="submit"
                                                isLoading={this.state.isSaving}
                                            />
                                        </Column>
                                    </div>
                                </Row>
                            </Container>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}