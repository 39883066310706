import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import MedicalPlans from "./MedicalPlans"
import DentalPlans from "./DentalPlans"
import VisionPlans from "./VisionPlans"
import FavoriteListPlans from "./FavoriteListPlans";
import Tabs from "../Tabs";
import TextInput from "../inputs/TextInput";
import Checkbox from "../inputs/Checkbox";
import Dropdown from "../inputs/Dropdown";
import ActionButton from "../ActionButton";
import * as inputSizes from "../../constants/inputSizes";
import * as favoriteApi from "../../lib/api/favorite";
import * as color from "../../constants/color";
import Modal from "../Modal";
import { ToastContainer, toast } from "react-toastify";
import favoriteIcon from "../../images/SVGs/favorite.svg";
import "react-toastify/dist/ReactToastify.css";

const styles = StyleSheet.create({
    dropdownContainer: {

    },
    container: {
        position: "absolute",
        right: 0,
        display: "flex",
        marginRight: 12,
    },
    favoriteListDropDown: {
        marginRight: 24,
        maxWidth: 600,
        minWidth: 162,
        paddingTop: 0,
    },
    manageListsButton: {
        marginTop: 11,
        marginRight: 24,
    },
    modalContent: {
        width: 690,
    },
    modalButtonContainer: {
        marginTop: 38,
        display: "flex",
        justifyContent: "center",
    },
    modalButton: {
        width: 150,
        marginLeft: 16,
        marginRight: 16,
    },
    deleteAllButtonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        marginBottom: 20,
    },
    deleteAllButton: {
        flexGrow: 0,
        flexBasis: 0,
        display: "none",
    },
    headerRenameListLabel: {
        color: color.darkestGray,
        fontSize: 16,
    },
    favoriteListEditContainer: {
        maxHeight: 386,
        overflowY: "auto",
        padding: "4px 16px 4px 0px",
    },
    favoriteListEditRow: {
        display: "flex",
        alignItems: "center",
    },
    checkLabel: {
        lineHeight: "64px",
        marginLeft: 16,
        marginRight: 16,
    },
    check: {
        top: 4,
        left: 8,
    },
    separator: {
        marginLeft: 12,
        marginRight: 12,
        fontWeight: "bold",
    },
    linkButton: {
        color: color.mediumYellow1,
        cursor: "pointer",
    },
    textInput: {
        flexGrow: 1,
    },
    favoriteIcon: {
        height: 24,
        position: "relative",
        top: 6,
        marginLeft: 2,
        marginRight: 2,
    }
});

const inputNames = {
    favoriteListId: "favoriteListId"
};

const strings = {
    manageLists: "Manage Lists",
    favoriteListPlaceholder: "Load List",
};

export default class FavoritePlans extends Component {
    static propTypes = {

    };

    constructor(props) {
        super(props);
        this.state = {
            inputs: {
                [inputNames.favoriteListId]: "",
            },
            favoriteLists: [],
            loading: true,
            showList: false,
            isSaving: false,
            plans: {
                medicalPlans: [],
                visionPlans: [],
                dentalPlans: [],
            },
            showModal: false,
            favoriteListName: null,
            favoriteListId: null,
            refreshMedicalPlans: false,
            refreshDentalPlans: false,
            refreshVisionPlans: false,
        };
    }

    componentDidMount() {
        favoriteApi.getList()
            .then(response => {
                this.setState({
                    favoriteLists: response,
                    loading: false,
                });
            });
    }

    _handleUpdatePlans = () => {
        favoriteApi.getPlansByListId(this.state.inputs[inputNames.favoriteListId])
            .then(response => {
                this.setState({
                    plans: response,
                });

                this._refreshPlans();
            });
    };

    _handleDropdownChanged = selectedItem => {
        const { name, value } = selectedItem;
        const { favoriteLists } = this.state;

        const favoriteList = favoriteLists.find(fl => fl.id === value);

        favoriteApi.getPlansByListId(value)
            .then(repsonse => {
                this.setState(prevState => {
                    return {
                        plans: repsonse,
                        favoriteListId: value,
                        showList: true,
                        inputs: {
                            ...prevState.inputs,
                            [name]: value,
                        },
                        favoriteListName: favoriteList.name,
                    };
                });
            });
    };

    _handleManageClicked = () => {
        this.setState({
            showModal: true,
        });
    };

    _handleBackClicked = () => {
        this.setState(prevState => {
            return {
                showList: false,
                inputs: {
                    ...prevState.inputs,
                    [inputNames.favoriteListId]: "",
                },
            };
        });
    }

    _handleInputChanged = (e, id) => {
        const { name, value, type, checked } = e.target;
        let val = type === "checkbox" ? checked : value;

        const { favoriteLists } = this.state;

        const favoriteList = favoriteLists.find(f => f.id === id);
        favoriteList[name] = val;

        this.setState({
            favoriteList,
        });
    };

    _handleSavePlans = () => {
        const { favoriteLists } = this.state;

        favoriteApi.updateLists(favoriteLists)
            .then(() => {
                favoriteApi.getList()
                    .then(response => {
                        this.setState({
                            favoriteLists: response,
                            showModal: false,
                        });
                    });
            });
    };

    _handleCancelModal = () => {
        favoriteApi.getList()
            .then(response => {
                this.setState({
                    favoriteLists: response,
                    showModal: false,
                });
            });
    };

    _handleDeleteAll = () => {

    };

    _refreshPlans = () => {
        this._refreshMedicalPlans();
        this._refreshDentalPlans();
        this._refreshVisionPlans();
    };

    _deleteList = id => {
        if (!window.confirm("Are you sure you want to delete the favorite list?")) {
            return;
        }

        favoriteApi.deleteList(id)
            .then(() => {
                favoriteApi.getList()
                    .then(response => {
                        toast.success("The favorite list has been deleted!");
                        this.setState({
                            favoriteLists: response,
                        });

                        this._refreshPlans();
                        this._handleUpdatePlans();
                    });
            });
    };

    _clearList = id => {
        if (!window.confirm("Are you sure you want to clear the favorite list?")) {
            return;
        }

        favoriteApi.clearList(id)
            .then(() => {
                toast.success("The favorite list was cleared!");
                this._refreshPlans();
            });
    };

    _refreshMedicalPlans = () => {
        this.setState({ refreshMedicalPlans: !this.state.refreshMedicalPlans });
    };

    _refreshDentalPlans = () => {
        this.setState({ refreshDentalPlans: !this.state.refreshDentalPlans });
    };

    _refreshVisionPlans = () => {
        this.setState({ refreshVisionPlans: !this.state.refreshVisionPlans })
    };

    _renderManageLists = () => {
        const { favoriteLists } = this.state;
        return (
            <Modal
                show={this.state.showModal}
                header="Manage Lists"
            >
                <div className={css(styles.modalContent)}>
                    <div className={css(styles.deleteAllButtonContainer)}>
                        <ActionButton
                            aStyles={styles.deleteAllButton}
                            label="Delete All Lists"
                            invertColor={true}
                            type="button"
                            onClick={this._handleDeleteAll}
                        />
                    </div>

                    {favoriteLists.length > 0 ? (
                        <React.Fragment>
                            <div className={css(styles.headerRenameListLabel)}>
                                Rename List
                            </div>
                            <div className={css(styles.favoriteListEditContainer)}>
                                {favoriteLists.map((f, index) => (
                                    <div key={f.id} className={css(styles.favoriteListEditRow)}>
                                        <TextInput
                                            aStyles={styles.textInput}
                                            name="name"
                                            onChange={e => this._handleInputChanged(e, f.id)}
                                            placeholder={""}
                                            value={favoriteLists[index].name}
                                            size={inputSizes.small}
                                            hideLabel={true}
                                        />
                                        <label className={css(styles.checkLabel)}>
                                            <span className={css(styles.labelText)}>Public</span>
                                            <Checkbox
                                                aStyles={styles.check}
                                                name="isPublic"
                                                checked={favoriteLists[index].isPublic}
                                                onChange={e => this._handleInputChanged(e, f.id)}
                                            />
                                        </label>
                                        <div className={css(styles.separator)}>|</div>
                                        <div
                                            className={css(styles.linkButton)}
                                            onClick={() => this._clearList(f.id)}
                                        >
                                            Clear List
                                        </div>
                                        <div className={css(styles.separator)}>|</div>
                                        <div
                                            className={css(styles.linkButton)}
                                            onClick={() => this._deleteList(f.id)}
                                        >
                                            Delete List
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </React.Fragment>
                    ) : (
                        <div>
                            You don’t have any favorite lists.&nbsp;&nbsp;Create one by clicking on a plan&apos;s <img className={css(styles.favoriteIcon)} src={favoriteIcon} alt="favorite" /> icon below.
                        </div>
                    )}

                    <div className={css(styles.modalButtonContainer)}>
                        <ActionButton
                            aStyles={styles.modalButton}
                            label="Close"
                            // invertColor={true}
                            type="button"
                            onClick={this._handleCancelModal}
                        />
                        {favoriteLists.length > 0 ? (
                            <ActionButton
                                aStyles={styles.modalButton}
                                label="Save"
                                onClick={this._handleSavePlans}
                                type="button"
                                isLoading={this.state.isSaving}
                            />
                        ) : null}
                    </div>
                </div>
            </Modal>
        );
    };

    render() {
        const { favoriteLists, showList, plans } = this.state;
        return (
            <React.Fragment>
                <ToastContainer position="top-center" autoClose={2500} />
                <div className={css(styles.container)}>
                    <div className={css(styles.dropdownContainer)}>
                        <Dropdown
                            name={inputNames.favoriteListId}
                            options={favoriteLists.map(item => ({
                                label: item.firstInitial + item.lastInitial + " - " + item.name,
                                value: item.id,
                            }))}
                            onChange={this._handleDropdownChanged}
                            placeholder={strings.favoriteListPlaceholder}
                            value={this.state.inputs[inputNames.favoriteListId]}
                            hideLabel={true}
                            size="small"
                            aStyles={styles.favoriteListDropDown}

                        />
                    </div>
                    <ActionButton
                        aStyles={styles.manageListsButton}
                        label={strings.manageLists}
                        onClick={this._handleManageClicked}
                        type="button"
                        isLoading={this.state.isSaving}
                    />
                </div>
                <div
                    style={{
                        display: showList ? "block" : "none"
                    }}
                >
                    <FavoriteListPlans
                        plans={plans}
                        onBack={this._handleBackClicked}
                        onUpdatePlans={this._handleUpdatePlans}
                        favoriteListName={this.state.favoriteListName}
                        favoriteListId={this.state.favoriteListId}
                    />
                </div>
                <div
                    style={{
                        display: showList ? "none" : "block"
                    }}
                >
                    <Tabs>
                        <div label="Medical">
                            <MedicalPlans refresh={this.state.refreshMedicalPlans} />
                        </div>
                        <div label="Dental">
                            <DentalPlans refresh={this.state.refreshDentalPlans} />
                        </div>
                        <div label="Vision">
                            <VisionPlans refresh={this.state.refreshVisionPlans} />
                        </div>
                    </Tabs>
                </div>
                {this._renderManageLists()}
            </React.Fragment>
        );
    }
}