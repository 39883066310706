import instance from "./instance";

const responseData = response => response.data;

export function getAll() {
    return instance
        .request({
            url: "/dentalPlan/get-dental-plans",
            method: "GET",
        })
        .then(responseData);
}

export function getPaged(page, sortColumn, sortDirection, quoteId, current, carrierIds, networks, planTypes, tierIds, deductibleRange, searchTerm, areaId,
    coinsuranceRange, fundingTypes, perioEndoTypes, annualMaximums, orthoMaximums, ortho, reimbursements, all = false) {
    return instance
        .request({
            url: "/dentalPlan/get-dental-plans-page",
            method: "POST",
            data: {
                page,
                sortColumn,
                sortDirection,
                quoteId,
                current,
                carrierIds,
                networks,
                planTypes,
                deductibleRange,
                coinsuranceRange,
                fundingTypes,
                perioEndoTypes,
                annualMaximums,
                orthoMaximums,
                ortho,
                reimbursements,
                searchTerm,
                all,
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function getPagedForQuote(page, sortColumn, sortDirection, quoteId, carrierIds, networks, planTypes, tierIds, deductibleRange, coinsuranceRange, oopRange, employeePremiumRange, monthlyPremiumRange, searchTerm, packages, fundingTypes, perioEndoTypes, annualMaximums, orthoMaximums, ortho, reimbursements, fillings, implants, medInfertility, medHSA, all = false) {
    return instance
        .request({
            url: "/dentalPlan/get-quote-dental-plans-page",
            method: "POST",
            data: {
                page,
                sortColumn,
                sortDirection,
                quoteId,
                carrierIds,
                networks,
                planTypes,
                tierIds,
                deductibleRange,
                coinsuranceRange,
                oopRange,
                employeePremiumRange,
                monthlyPremiumRange,
                searchTerm,
                packages,
                fundingTypes,
                perioEndoTypes,
                annualMaximums,
                orthoMaximums,
                ortho,
                reimbursements,
                fillings,
                implants,
                all,
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function getDentalPlansDisplay(areaId, carrierId, year, planType) {
    return instance
        .request({
            url: "/dentalPlan/get-dental-plans-display",
            method: "POST",
            data: {
                areaId,
                carrierId,
                year,
                planType,
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function getById(id) {
    return instance
        .request({
            url: `/dentalPlan/get-dental-plan/${id}`,
            method: "GET",
        })
        .then(responseData);
}