export const medicalColumnLabels = ["Carrier", "Plan", "Network", "Type", "Mtl", "Ded", "CoIns", "OOP", "Copay", "Rx"];
export const medicalColumnValues = ["carrierName", "displayName", "networkName", "planTypeName", "metallicLevel", "deductible", "coInsurance", "oop", "copay", "rxCard"];

export const dentalColumnLabels = ["Carrier", "Plan", "Network", "Type", "Ded", "CoIns", "Max", "Funding", "Per/End", "Ortho", "O.Max", "OON", "Notes"];
export const dentalColumnValues = ["carrierName", "displayName", "networkName", "planTypeName", "deductible", "coInsurance", "annualMaximum", "fundingType", "perioEndo", "childOrtho", "orthoAnnualMax", "outOfNetworkLevel", "notes"];

export const visionColumnLabels = ["Carrier", "Plan", "Exam", "Materials", "Frames", "Contacts", "Funding", "Frequency", "Notes"];
export const visionColumnValues = ["carrierName", "displayName", "examCopay", "materialsCopay", "framesAllowance", "contactLensAllowance", "fundingType", "frequency", "notes"];

export const lifeColumnLabels = ["Carrier", "Plan", "Carrier", "Life Amount", "Guaranteed Issue Amount", "Reduction Schedule"];
export const lifeColumnValues = ["carrierName", "displayName", "carrierName", "coverageAmount", "", "schedule"];

export const newmedicalColumnLabels = ["Carrier", "Plan", "Network", "Type", "Mtl", "M. Prem", "EE", "ES", "EC", "EF", "Ded", "CoIns", "OOP", "Copay (PCP/Spec)", "Rx", "SBC"];
export const newmedicalColumnValues = ["carrierName", "displayName", "networkName", "planTypeName", "metallicLevel", "monthlyPremium", "ee", "es", "ec", "ef", "deductible", "coInsurance", "oop", "copay", "rxCard", "summaryPDFName"];

export const newAmedicalColumnLabels = ["Carrier", "Plan", "Network", "Type", "Mtl", "M. Prem", "EE", "Dep", "Ded", "CoIns", "OOP", "Copay (PCP/Spec)", "Rx", "SBC"];
export const newAmedicalColumnValues = ["carrierName", "displayName", "networkName", "planTypeName", "metallicLevel", "ageMonthlyPremium", "ageEmployeePremium", "ageDependentPremium", "deductible", "coInsurance", "oop", "copay", "rxCard", "summaryPDFName"];

export const newdentalColumnLabels = ["Carrier", "Plan", "Network", "Type", "M. Prem", "EE", "ES", "EC", "EF", "Ded", "CoIns", "Max", "Funding", "Per/End", "Ortho", "O.Max", "OON", "Notes", "Summary"];
export const newdentalColumnValues = ["carrierName", "displayName", "networkName", "planTypeName", "monthlyPremium", "ee", "es", "ec", "ef", "deductible", "coInsurance", "annualMaximum", "fundingType", "perioEndo", "childOrtho", "orthoAnnualMax", "outOfNetworkLevel", "notes", "summaryPDFName"];

export const newvisionColumnLabels = ["Carrier", "Plan", "M. Prem", "EE", "ES", "EC", "EF", "Exam", "Materials", "Frames", "Contacts", "Funding", "Frequency", "Notes", "Summary"];
export const newvisionColumnValues = ["carrierName", "displayName", "monthlyPremium", "ee", "es", "ec", "ef", "examCopay", "materialsCopay", "framesAllowance", "contactLensAllowance", "fundingType", "frequency", "notes", "summaryPDFName"];

export const newlifeColumnLabels = ["Carrier", "Plan", "Life Amount", "AD&D Amount", "Guarantee Issue", "Age Reduction Schedule", "Rate Guarantee", "Employer Cont.", "Participation Req.", "Total Volume", "Life and AD&D Per $1,000", "Total Monthly", "Summary"];
export const newlifeColumnValues = ["carrierName", "displayName", "coverageAmount", "coverageAmount", "guaranteeIssueAmount", "ageReductionSchedule", "rateGuarantee", "employerContribution", "participationRequirement", "totalVolume", "lifeAndADDPer1000", "monthlyPremium", "summaryPDFName"];

/* Favorite List... display plan effective dates */
export const favmedicalColumnLabels = ["Carrier", "Plan", "Effective", "Network", "Type", "Mtl", "Ded", "CoIns", "OOP", "Copay", "Rx"];
export const favmedicalColumnValues = ["carrierName", "displayName", "effectiveDateString", "networkName", "planTypeName", "metallicLevel", "deductible", "coInsurance", "oop", "copay", "rxCard"];

export const favdentalColumnLabels = ["Carrier", "Plan", "Effective", "Network", "Type", "Ded", "CoIns", "Max", "Funding", "Per/End", "Ortho", "O.Max", "OON", "Notes"];
export const favdentalColumnValues = ["carrierName", "displayName", "effectiveDateString", "networkName", "planTypeName", "deductible", "coInsurance", "annualMaximum", "fundingType", "perioEndo", "childOrtho", "orthoAnnualMax", "outOfNetworkLevel", "notes"];

export const favvisionColumnLabels = ["Carrier", "Plan", "Effective", "Exam", "Materials", "Frames", "Contacts", "Funding", "Frequency", "Notes"];
export const favvisionColumnValues = ["carrierName", "displayName", "effectiveDateString", "examCopay", "materialsCopay", "framesAllowance", "contactLensAllowance", "fundingType", "frequency", "notes"];
