export const SESSION_SIGN_IN = "SESSION_SIGN_IN";
export const SESSION_SIGN_OUT = "SESSION_SIGN_OUT";
export const SESSION_SET_ME = "SESSION_SET_ME";
export const SESSION_UPDATE_ME = "SESSION_UPDATE_ME";

export const APP_SET_OFFICES = "APP_SET_OFFICES";
export const APP_SET_NEWS = "APP_SET_NEWS";

export const PROPOSAL_SET_SPREADSHEET_TYPE = "PROPOSAL_SET_SPREADSHEET_TYPE";
export const PROPOSAL_SET_QUOTE = "PROPOSAL_SET_QUOTE";
export const PROPOSAL_SET_SELECTED_PLANS = "PROPOSAL_SET_SELECTED_PLANS";
export const PROPOSAL_SET_SELECTED_BASE_PLANS = "PROPOSAL_SET_SELECTED_BASE_PLANS";
export const PROPOSAL_SET_SPREADSHEETS = "PROPOSAL_SET_SPREADSHEETS";
export const PROPOSAL_SET_CARRIERS = "PROPOSAL_SET_CARRIERS";
export const PROPOSAL_SET_PLANTYPES = "PROPOSAL_SET_PLANTYPES";
export const PROPOSAL_SET_NETWORKS = "PROPOSAL_SET_NETWORKS";
export const PROPOSAL_SET_MIN_MAX_FILTERS = "PROPOSAL_SET_MIN_MAX_FILTERS";
export const PROPOSAL_SET_PLANTYPESD = "PROPOSAL_SET_PLANTYPESD";
export const PROPOSAL_SET_COINSURANCESD = "PROPOSAL_SET_COINSURANCESD";
export const PROPOSAL_SET_DEDUCTIBLESD = "PROPOSAL_SET_DEDUCTIBLESD";
export const PROPOSAL_SET_PERIOENDOSD = "PROPOSAL_SET_PERIOENDOSD";
export const PROPOSAL_SET_ANNUALMAXIMUMSD = "PROPOSAL_SET_ANNUALMAXIMUMSD";
export const PROPOSAL_SET_ORTHOMAXIMUMSD = "PROPOSAL_SET_ORTHOMAXIMUMSD";
export const PROPOSAL_SET_REIMBURSEMENTSD = "PROPOSAL_SET_REIMBURSEMENTSD";
export const PROPOSAL_SET_EXAMCOPAYSV = "PROPOSAL_SET_EXAMCOPAYSV";
export const PROPOSAL_SET_FUNDINGTYPESV = "PROPOSAL_SET_FUNDINGTYPESV";
export const PROPOSAL_SET_MATERIALCOPAYSV = "PROPOSAL_SET_MATERIALCOPAYSV";
export const PROPOSAL_SET_FRAMEALLOWANCESV = "PROPOSAL_SET_FRAMEALLOWANCESV";
export const PROPOSAL_SET_CONTACTLENSALLOWANCESV = "PROPOSAL_SET_CONTACTLENSALLOWANCESV";
export const PROPOSAL_SET_FRAMEFREQUENCIESV = "PROPOSAL_SET_FRAMEFREQUENCIESV";
export const PROPOSAL_SET_FAVORITE_LISTS = "PROPOSAL_SET_FAVORITE_LISTS";
export const PROPOSAL_SET_FAVORITE_PLAN_LIST = "PROPOSAL_SET_FAVORITE_PLAN_LIST";
export const PROPOSAL_SET_FAVORITES_APPLIED = "PROPOSAL_SET_FAVORITES_APPLIED";

export const ENROLLMENT_SET = "ENROLLMENT_SET";
export const ENROLLMENT_UPDATE_EMPLOYER = "ENROLLMENT_UPDATE_EMPLOYER";
export const ENROLLMENT_UPDATE_BILLING_CONTACT = "ENROLLMENT_UPDATE_BILLING_CONTACT";
export const ENROLLMENT_UPDATE_ENROLLMENT_PORTAL = "ENROLLMENT_UPDATE_ENROLLMENT_PORTAL";
export const ENROLLMENT_INSURANCE_TYPE_PLAN_ENROLLMENT_UPDATE = "ENROLLMENT_INSURANCE_TYPE_PLAN_ENROLLMENT_UPDATE";
export const ENROLLMENT_INSURANCE_TYPE_PLAN_ELIGIBILITY_UPDATE = "ENROLLMENT_INSURANCE_TYPE_PLAN_ELIGIBILITY_UPDATE";
export const ENROLLMENT_INSURANCE_TYPE_PLAN_DETAIL_UPDATE = "ENROLLMENT_INSURANCE_TYPE_PLAN_DETAIL_UPDATE";
export const ENROLLMENT_UPDATE_INSURANCE_TYPE_PLAN_ENROLLMENTS = "ENROLLMENT_UPDATE_INSURANCE_TYPE_PLAN_ENROLLMENTS";
export const ENROLLMENT_UPDATE_INSURANCE_TYPE_PLAN_ELIGIBILITIES = "ENROLLMENT_UPDATE_INSURANCE_TYPE_PLAN_ELIGIBILITIES";
export const ENROLLMENT_UPDATE_INSURANCE_TYPE_PLAN_DETAILS = "ENROLLMENT_UPDATE_INSURANCE_TYPE_PLAN_DETAILS";
