import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import bugsnag from "./lib/bugsnag";
import store from "./store";
import { Provider } from "react-redux";

import "normalize.css";
import "./index.css";
import "./styles/login.css";
import "./styles/quote.css";

const ErrorBoundary = bugsnag.getPlugin("react");
ReactDOM.render(
	<ErrorBoundary>
	    <Provider store={store}>
	        <App />
	    </Provider>
	</ErrorBoundary>,
    document.getElementById("root"),
);
