import instance from "./instance";

const responseData = response => response.data;

export function getStates() {
    return instance
        .request({
            url: "/data/get-states",
            method: "GET",
        })
        .then(responseData);
}

export function getUsers() {
    return instance
        .request({
            url: "/data/get-users",
            method: "GET",
        })
        .then(responseData);
}

export function getQuoters() {
    return instance
        .request({
            url: "/data/get-quoters",
            method: "GET",
        })
        .then(responseData);
}

export function getSalesReps() {
    return instance
        .request({
            url: "/data/get-sales-reps",
            method: "GET",
        })
        .then(responseData);
}

export function getPayrollCycles() {
    return instance
        .request({
            url: "/data/get-payroll-cycles",
            method: "GET",
        })
        .then(responseData);
}

export function getEntityTypes() {
    return instance
        .request({
            url: "/data/get-entity-types",
            method: "GET",
        })
        .then(responseData);
}

export function getCarriers(year = null, areaId = null) {
    let url = "";
    if (year && areaId) {
        url = `/data/get-carriers/${year}/${areaId}`;
    } else {
        url = `/data/get-carriers`;
    }

    return instance
        .request({
            url: url,
            method: "GET",
        })
        .then(responseData);

}

export function getAllCarriers() {
    return instance
        .request({
            url: "/data/get-all-carriers",
            method: "GET",
        })
        .then(responseData);
}

export function getAreas() {
    return instance
        .request({
            url: "/data/get-areas",
            method: "GET",
        })
        .then(responseData);
}

export function getRatingAreas() {
    return instance
        .request({
            url: "/data/get-rating-areas",
            method: "GET",
        })
        .then(responseData);
}

export function getMedicalPlanTypes() {
    return instance
        .request({
            url: "/data/get-medical-plan-types",
            method: "GET",
        })
        .then(responseData);
}

export function getDisclaimers() {
    return instance
        .request({
            url: "/data/get-disclaimers",
            method: "GET",
        })
        .then(responseData);
}

export function getCarriersFilter(quoteId) {
    return instance
        .request({
            url: `/data/get-carriers-filter/${quoteId}`,
            method: "POST",
        })
        .then(responseData);
}

export function getCarriersFilterCR(quoteId, current, lob) {
    return instance
        .request({
            url: `/data/get-carriers-filter-cr/${quoteId}/${current}/${lob}`,
            method: "POST",
        })
        .then(responseData);
}

export function getMedicalPlanTypesFilter(quoteId) {
    return instance
        .request({
            url: `/data/get-medical-plan-types-filter/${quoteId}`,
            method: "POST",
        })
        .then(responseData);
}

export function getNetworksFilter(quoteId) {
    return instance
        .request({
            url: `/data/get-networks-filter/${quoteId}`,
            method: "POST",
        })
        .then(responseData);
}

export function getMinMaxFilters(quoteId) {
    return instance
        .request({
            url: `/data/get-min-max-filter-values/${quoteId}`,
            method: "POST",
        })
        .then(responseData);
}

export function getDentalPlanTypesFilter(quoteId) {
    let url = "/data/get-dental-plan-types-filter/";
    if (quoteId) {
        url = `/data/get-dental-plan-types-filter/${quoteId}`;
    }

    return instance
        .request({
            url: url,
            method: "POST",
        })
        .then(responseData);
}

export function getDentalCoinsurancesFilter(quoteId) {
    let url = "/data/get-dental-coinsurances-filter/";
    if (quoteId) {
        url = `/data/get-dental-coinsurances-filter/${quoteId}`;
    }

    return instance
        .request({
            url: url,
            method: "POST",
        })
        .then(responseData);
}

export function getDentalDeductiblesFilter(quoteId) {
    let url = "/data/get-dental-deductibles-filter/";
    if (quoteId) {
        url = `/data/get-dental-deductibles-filter/${quoteId}`;
    }

    return instance
        .request({
            url: url,
            method: "POST",
        })
        .then(responseData);
}

export function getDentalPerioEndosFilter(quoteId) {
    let url = "/data/get-dental-perioendos-filter/";
    if (quoteId) {
        url = `/data/get-dental-perioendos-filter/${quoteId}`;
    }
    return instance
        .request({
            url: url,
            method: "POST",
        })
        .then(responseData);
}

export function getDentalAnnualMaximumsFilter(quoteId) {
    let url = "/data/get-dental-annual-maximums-filter/";
    if (quoteId) {
        url = `/data/get-dental-annual-maximums-filter/${quoteId}`;
    }

    return instance
        .request({
            url: url,
            method: "POST",
        })
        .then(responseData);
}

export function getDentalOrthoMaximumsFilter(quoteId) {
    let url = "/data/get-dental-ortho-maximums-filter/";
    if (quoteId) {
        url = `/data/get-dental-ortho-maximums-filter/${quoteId}`;
    }
    return instance
        .request({
            url: url,
            method: "POST",
        })
        .then(responseData);
}

export function getDentalReimbursementsFilter(quoteId) {

    let url = "/data/get-dental-reimbursements-filter/";
    if (quoteId) {
        url = `/data/get-dental-reimbursements-filter/${quoteId}`;
    }
    return instance
        .request({
            url: url,
            method: "POST",
        })
        .then(responseData);
}

export function getVisionExamCopaysFilter(quoteId) {
    let url = "/data/get-vision-exam-copays-filter/";
    if (quoteId) {
        url = `/data/get-vision-exam-copays-filter/${quoteId}`;
    }
    return instance
        .request({
            url: url,
            method: "POST",
        })
        .then(responseData);
}

export function getVisionFundingTypesFilter(quoteId) {
    let url = "/data/get-vision-funding-types-filter/";
    if (quoteId) {
        url = `/data/get-vision-funding-types-filter/${quoteId}`;
    }
    return instance
        .request({
            url: url,
            method: "POST",
        })
        .then(responseData);
}

export function getVisionMaterialCopaysFilter(quoteId) {
    let url = "/data/get-vision-material-copays-filter/";
    if (quoteId) {
        url = `/data/get-vision-material-copays-filter/${quoteId}`;
    }
    return instance
        .request({
            url: url,
            method: "POST",
        })
        .then(responseData);
}

export function getVisionFrameAllowancesFilter(quoteId) {
    let url = "/data/get-vision-frame-allowances-filter/";
    if (quoteId) {
        url = `/data/get-vision-frame-allowances-filter/${quoteId}`;
    }
    return instance
        .request({
            url: url,
            method: "POST",
        })
        .then(responseData);
}

export function getVisionContactLensAllowancesFilter(quoteId) {
    let url = "/data/get-vision-contact-lens-allowances-filter/";
    if (quoteId) {
        url = `/data/get-vision-contact-lens-allowances-filter/${quoteId}`;
    }
    return instance
        .request({
            url: url,
            method: "POST",
        })
        .then(responseData);
}

export function getVisionFrameFrequenciesFilter(quoteId) {
    let url = "/data/get-vision-frame-frequencies-filter/";
    if (quoteId) {
        url = `/data/get-vision-frame-frequencies-filter/${quoteId}`;
    }
    return instance
        .request({
            url: url,
            method: "POST",
        })
        .then(responseData);
}

export function saveMedicalPlanType(planType) {
    return instance
        .request({
            url: "/data/save-medical-plan-type",
            method: "POST",
            data: { ...planType },
        })
        .then(responseData);
}

export function deleteMedicalPlanType(id) {
    return instance
        .request({
            url: `/data/delete-medical-plan-type/${id}`,
            method: "POST",
        })
        .then(responseData);
}

export function saveDisclaimer(disclaimer) {
    return instance
        .request({
            url: "/data/save-disclaimer",
            method: "POST",
            data: { ...disclaimer },
        })
        .then(responseData);
}

export function deleteDisclaimer(id) {
    return instance
        .request({
            url: `/data/delete-disclaimer/${id}`,
            method: "POST",
        })
        .then(responseData);
}

export function getCounties() {
    return instance
        .request({
            url: "/data/get-counties",
            method: "GET",
        })
        .then(responseData);
}

export function getQuoteMessages(quoteId) {
    return instance
        .request({
            url: `/data/get-quote-messages/${quoteId}`,
            method: "POST",
        })
        .then(responseData);
}

export function getMedicalProductLines(params) {
    return instance
        .request({
            url: "/data/get-medical-product-lines",
            method: "POST",
            data: { ...params },
        })
        .then(responseData);
}

export function checkISLAndPEPM(quoteId) {
    return instance
        .request({
            url: `/data/check-isl-and-pepm/${quoteId}`,
            method: "POST",
        })
        .then(responseData);
}

export function updateISLAndPEPM(quoteId, isl, pepm) {
    return instance
        .request({
            url: `/data/update-isl-and-pepm/${quoteId}/${isl}/${pepm}`,
            method: "POST",
        })
        .then(responseData);
}

export function getChartData(chart, brokerId) {
    return instance
        .request({
            url: `/data/get-chart-data/${chart}/${brokerId}`,
            method: "POST",
        })
        .then(responseData);
}

export function getProductAvailability() {
    return instance
        .request({
            url: "/data/get-product-availability",
            method: "GET",
        })
        .then(responseData);
}

export function getProductAvailabilityReport() {
    return instance
        .request({
            url: "/data/get-product-availability-report",
            method: "POST",
            responseType: "blob",
        })
        .then((response) => {
            if (response.data && response.data.size > 0) {
                if (response.data.type.startsWith("application")) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'PRODUCT_AVAILABILITY.xlsx');
                    document.body.appendChild(link);
                    link.click();
                }
                else if (response.data.type === "text/plain") {
                    return response.data.text();
                }
            }
            else {
                return "ERROR";
            }
        });
}
