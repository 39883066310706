import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import * as color from "../../constants/color";
import * as texchangePropTypes from "../../texchangePropTypes";
import * as searchInputTypes from "../../constants/searchInputTypes";
import Checkbox from "../inputs/Checkbox";
import Headline from "../text/Headline";
import SearchInput from "../inputs/SearchInput";
import DatePicker from "../inputs/DatePicker";
import TextInput from "../inputs/TextInput";
import Dropdown from "../inputs/Dropdown";
import ActionButton from "../ActionButton";
import Toggle from "../inputs/Toggle";
import { connect } from "react-redux";
import * as util from "../../lib/util";
import * as searchApi from "../../lib/api/search";
import * as employerApi from "../../lib/api/employer";
import * as dataApi from "../../lib/api/data";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = StyleSheet.create({
    searchGroupContainer: {
        paddingTop: 32,
    },
    headline: {
        color: color.darkestGray,
        fontSize: 24,
        fontWeight: 700,
        marginBottom: 8,
    },
    inputContainer: {
        width: 440,
        marginLeft: "auto",
        marginRight: "auto",
    },
    groupSearchBox: {
        position: "relative",
        zIndex: 10000,
    },
    brokerSearchBox: {
        position: "relative",
        width: 440,
        zIndex: 8650,
    },
    sicSearchBox: {
        position: "relative",
        width: 440,
        zIndex: 8649,
    },
    createGroupContainer: {
        marginTop: 16,
    },
    checkboxContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: 12,
    },
    checkboxContainer2: {
        marginTop: 16,
    },
    check: {
        position: "absolute",
        top: -2,
        left: 0,
        marginRight: 10,
    },
    checkLabel: {
        display: "inline-block",
        position: "relative",
        border: "none",
        marginTop: 8,
        marginBottom: 10,
        "-webkit-appearance": "none",
        cursor: "pointer",
        ":first-child": {
            marginRight: 24,
        }
    },
    checkLabel2: {
        display: "block",
        position: "relative",
        border: "none",
        marginTop: 12,
        marginBottom: 12,
        "-webkit-appearance": "none",
        cursor: "pointer",
    },
    labelText: {
        marginLeft: 28,
        fontSize: 14,
        marginRight: 12,
        whiteSpace: "nowrap",
    },
    toggleContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        margin: "10px auto",
        fontSize: 14,
    },
    buttonContainer: {
        height: 80,
        width: "100%",
        margin: "24px auto 0px auto",
        textAlign: "center",
    },
    proceedToCensusButton: {
        width: 200,
    },
    clearButton: {
        marginRight: 12,
        width: 200,
    },
    itemTitle: {
        width: "100%",
        minWidth: 400,
        height: 18,
        display: "block",
        paddingTop: 10,
        marginLeft: 5,
        marginRight: 5,
        marginTop: 4,
        fontSize: 14,
        fontWeight: 500,
        whiteSpace: "nowrap",
        color: color.darkGray,
    },
    item: {
        width: "100%",
        height: 48,
        display: "block",
        paddingTop: 18,
        marginLeft: 5,
        marginRight: 5,
        fontSize: 16,
        color: color.darkestGray,
        borderBottom: `1px solid ${color.darkGray6}`,
    },
    instructions: {
        fontSize: 14,
        marginTop: 12,
        marginBottom: 8,
        color: color.darkestGray,
    },
    submitButton: {
        width: "100%",
    },
    newGroupNameHeader: {
        marginTop: 20,
        fontWeight: 500,
        fontSize: 20,
    },
    confirmButtonContainer: {
        display: "flex",
        marginTop: 32,
        marginBottom: 32,
    },
    confirmButton: {
        flexGrow: 1,
        flexBasis: 0,
        ":first-child": {
            marginRight: 32,
        },
    },
    searchGroupButtonContainer: {
        marginTop: 8,
        marginButton: 24,
        textAlign: "center",
    },
    requiredText: {
        float: "right",
        color: color.red,
    },
    warning: {
        marginLeft: 5,
        fontSize: 14,
        fontWeight: 500,
        color: color.red,
    },
});

const strings = {
    required: "Please fill out this field",
    title: "Find or Create a Group",
    groupSearchPlaceholder: "Type Group Name",
    editGroupTitle: "Group",
    virginGroup: "Virgin group",
    testGroup: "Test group",
    brokerNamePlaceholder: "Broker Name",
    requiredBroker: "Please choose an existing Broker",
    namePlaceholder: "Group Name",
    zipPlaceholder: "Zip",
    sicPlaceholder: "SIC",
    requiredSIC: "Please choose an existing SIC Code",
    statePlaceholder: "State",
    countyPlaceholder: "County",
    effectiveDatePlaceholder: "Effective Date",
    totalNumberEmployeesPlaceholder: "Total Employees on Payroll",
    totalNumberEligibleEmployeesPlaceholder: "Total Eligible Employees",
    atnePlaceholder: "ATNE or FTE (number)",
    currentCarrierPlaceholder: "Current Medical Carrier",
    selectedCarriersPlaceholder: "Get Rates for the Following Carriers",
    proceedToCensusButton: "Proceed to Census",
    cancelButton: "Cancel",
    backButton: "Back",
    clearButton: "Clear",
    submitButton: "Submit",
};

const inputNames = {
    employerId: "employerId",
    isVirgin: "isVirgin",
    isTest: "isTest",
    isLargeGroup: "isLargeGroup",
    brokerId: "brokerId",
    name: "name",
    postalCode: "postalCode",
    countyId: "countyId",
    sicCodeId: "sicCodeId",
    totalNumberEmployees: "totalNumberEmployees",
    totalNumberEligibleEmployees: "totalNumberEligibleEmployees",
    atne: "atne",
    effectiveDate: "effectiveDate",
    currentMedicalCarrierId: "currentMedicalCarrierId",
    selectedCarrierIds: "selectedCarrierIds",
};

export class FindCreateGroup extends Component {
    static propTypes = {
        quote: texchangePropTypes.quote,
        employerId: PropTypes.number,
        counties: PropTypes.arrayOf(PropTypes.object).isRequired,
        saveQuote: PropTypes.func.isRequired,
        isSaving: PropTypes.bool.isRequired,
        me: texchangePropTypes.me.isRequired,
    };

    state = {
        inputs: {
            [inputNames.employerId]: "",
            [inputNames.isVirgin]: false,
            [inputNames.isTest]: false,
            [inputNames.isLargeGroup]: false,
            [inputNames.name]: "",
            [inputNames.brokerId]: "",
            [inputNames.postalCode]: "",
            [inputNames.countyId]: null,
            [inputNames.sicCodeId]: null,
            [inputNames.totalNumberEmployees]: "",
            [inputNames.totalNumberEligibleEmployees]: "",
            [inputNames.atne]: "",
            [inputNames.effectiveDate]: null,
            [inputNames.currentMedicalCarrierId]: "-1",
            [inputNames.selectedCarrierIds]: [],
        },
        allCarriers: [],
        smallGroupCarriers: [],
        largeGroupCarriers: [],
        selectedCarriers: [],
        currentCarrierWarning: "",
        states: [],
        payrollCycles: [],
        errors: {},
        selectedEmployer: null,
        selectedEmployerId: "",
        employerName: "",
        confirmAddEmployer: false,
        isLoadingEmployer: false,
    };

    componentDidMount() {
        const { quote, employerId, me } = this.props;
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.employerId]: quote[inputNames.employerId] ? quote[inputNames.employerId] : employerId ? employerId : null,
                    [inputNames.isVirgin]: quote[inputNames.isVirgin] ? quote[inputNames.isVirgin] : false,
                    [inputNames.isTest]: quote[inputNames.isTest] ? quote[inputNames.isTest] : false,
                    [inputNames.isLargeGroup]: quote[inputNames.isLargeGroup] ? quote[inputNames.isLargeGroup] : false,
                    [inputNames.name]: quote[inputNames.name] ? quote[inputNames.name] : "",
                    [inputNames.brokerId]: quote[inputNames.brokerId] ? quote[inputNames.brokerId] : "",
                    [inputNames.postalCode]: quote[inputNames.postalCode] ? quote[inputNames.postalCode] : "",
                    [inputNames.countyId]: quote[inputNames.countyId] ? quote[inputNames.countyId] : null,
                    [inputNames.sicCodeId]: quote[inputNames.sicCodeId] ? quote[inputNames.sicCodeId] : null,
                    [inputNames.totalNumberEmployees]: quote[inputNames.totalNumberEmployees] ? quote[inputNames.totalNumberEmployees].toString() : "",
                    [inputNames.totalNumberEligibleEmployees]: quote[inputNames.totalNumberEligibleEmployees] ? quote[inputNames.totalNumberEligibleEmployees].toString() : "",
                    [inputNames.atne]: quote[inputNames.atne] ? quote[inputNames.atne].toString() : "",
                    [inputNames.effectiveDate]: quote[inputNames.effectiveDate] ? new Date(quote[inputNames.effectiveDate]) : null,
                    [inputNames.currentMedicalCarrierId]: quote[inputNames.currentMedicalCarrierId] ? quote[inputNames.currentMedicalCarrierId].toString() : "-1",
                },
            };
        });

        if (quote[inputNames.employerId]) {
            this._getEmployer(quote[inputNames.employerId]);
        } else if (employerId && employerId > 0) {
            this._getEmployer(employerId, me.regionalOfficeId);
        }

        dataApi.getPayrollCycles()
            .then(response => {
                this.setState({ payrollCycles: response });
            });

        dataApi.getCarriers()
            .then(response => {
                //pre-select the options
                const allCarriers = response.filter(c => c.isMine === true);
                const smallGroupCarriers = allCarriers.filter(c => c.isLargeGroup === false);
                const largeGroupCarriers = allCarriers.filter(c => c.isLargeGroup === true);
                let allPlusNoCarrier = allCarriers;
                allPlusNoCarrier.unshift({ "id": -1, "name": "No Current Carrier" });
                smallGroupCarriers.unshift({ "id": -1, "name": "--- Select/Deselect All ---" });
                largeGroupCarriers.unshift({ "id": -1, "name": "--- Select/Deselect All ---" });
                let selected = [];
                smallGroupCarriers.forEach(item => {
                    //CA offices don't want LF selcted by default
                    if (!item.area || (item.area && (!item.area.includes("CA") || (item.area.includes("CA") && item.id !== 16 && item.id !== 18)))) {
                        selected.push(item.id.toString());
                    }
                });
                //set state
                this.setState(prevState => {
                    return {
                        inputs: {
                            ...prevState.inputs,
                            [inputNames.selectedCarrierIds]: selected,
                        },
                        allCarriers: allPlusNoCarrier,
                        smallGroupCarriers: smallGroupCarriers,
                        largeGroupCarriers: largeGroupCarriers,
                        selectedCarriers: selected,
                    }
                });
            });

        dataApi.getStates()
            .then(response => {
                this.setState({ states: response });
            });
    }

    _clearClicked = () => {
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.employerId]: "",
                },
                errors: {},
                selectedEmployer: null,
                selectedEmployerId: "",
                employerName: "",
                confirmAddEmployer: false,
                isLoadingEmployer: false,
            };
        });
    };

    _employerSelected = item => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.employerId];

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.employerId]: item.value,
                },
                errors,
                selectedEmployerId: item.value,
            };
        });
    };

    _getEmployer = (id, officeId) => {
        this.setState({
            isLoadingEmployer: true,
        });
        employerApi.getById(id)
            .then(result => {
                if (officeId && officeId !== result.regionalOfficeId) {
                    toast.error("You have attempted to load a Group from another office.", { toastId: 1 });
                    this.setState({
                        isLoadingEmployer: false,
                    });
                } else {
                    this.setState(prevState => {
                        return {
                            inputs: {
                                ...prevState.inputs,
                                [inputNames.postalCode]: result.employerAddress && result.employerAddress.postalCode ? result.employerAddress.postalCode : "",
                                [inputNames.sicCode]: result.sicCode,
                                [inputNames.brokerId]: result.brokerId ? result.brokerId.toString() : "",
                                [inputNames.isVirgin]: result.isVirgin,
                                [inputNames.isTest]: result.isTest,
                            },
                            selectedEmployer: result,
                            isLoadingEmployer: false,
                        };
                    });
                }
            })
            .catch(err => {
                toast.error("You have attempted to load an invalid Group.", { toastId: 1 });
                this.setState({
                    isLoadingEmployer: false,
                });
            });
    };

    _handleInputChange = e => {
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;

        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: val,
                },
            };
        });
    };

    _handleGroupSizeChange = value => {
        //pre-select the options
        let selected = [];
        let carriers = this.state.smallGroupCarriers;
        if (value) {
            carriers = this.state.largeGroupCarriers;
        }
        carriers.forEach(item => {
            selected.push(item.id.toString());
        });
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.isLargeGroup]: value,
                    [inputNames.selectedCarrierIds]: selected,
                },
                selectedCarriers: selected,
            };
        });
    };

    _handleEffectiveDateChanged = date => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            if (date) {
                delete errors[inputNames.effectiveDate];
            }

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.effectiveDate]: date,
                },
                errors,
            };
        }, () => {
            this._getFilteredCarrierList();
        });
    };

    _brokerSelected = item => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.brokerId];

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.brokerId]: item.value,
                },
                errors,
            };
        });
    };

    _sicCodeSelected = item => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.sicCodeId];

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.sicCodeId]: item.value,
                },
                errors,
            };
        });
    };

    _sicCodeTextChanged = text => {
        if (text === "") {
            this.setState(prevState => {
                return {
                    inputs: {
                        ...prevState.inputs,
                        [inputNames.sicCodeId]: "",
                        [inputNames.sicCode]: "",
                    },
                };
            });
        }
    };

    _handleCountyIdChanged = item => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.countyId];

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.countyId]: item.value,
                },
                errors,
            };
        });
    };

    _handleCurrentMedicalCarrierIdChanged = item => {
        //override isVirgin if needed
        let isVirgin = false;
        if (item.value === "-1") {
            isVirgin = true;
        }
        //set state
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.currentMedicalCarrierId];

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.currentMedicalCarrierId]: item.value,
                    [inputNames.isVirgin]: isVirgin,
                },
                errors,
            };
        }, () => {
            this._checkCarrierWarnings();
        });
    };

    _checkCarrierWarnings = () => {
        const { inputs, selectedCarriers } = this.state;
        const currentMedicalCarrierId = inputs[inputNames.currentMedicalCarrierId];

        let warning = "";
        //check
        if (currentMedicalCarrierId && selectedCarriers) {
            //Post warning message for UHC, BCBS, Humana or Aetna
            if (currentMedicalCarrierId === "1" || currentMedicalCarrierId === "2" || currentMedicalCarrierId === "3" || currentMedicalCarrierId === "4" ||
                currentMedicalCarrierId === "16" || currentMedicalCarrierId === "17" || currentMedicalCarrierId === "18" || currentMedicalCarrierId === "25") {
                //Fully insured to Level Funded
                if (currentMedicalCarrierId === "1" && selectedCarriers.indexOf("16") > -1) {
                    warning = "You have selected UnitedHealthcare as the current carrier and UHC Level Funded is currently selected as a carrier to be quoted.  UHC Level Funded plans will be included in the quote, but no rates will be included."
                }
                if (currentMedicalCarrierId === "2" && selectedCarriers.indexOf("25") > -1) {
                    warning = "You have selected BCBS as the current carrier and Blue Balance Funded is currently selected as a carrier to be quoted.  Blue Balance Funded plans will be included in the quote, but no rates will be included.";
                }
                if (currentMedicalCarrierId === "3" && selectedCarriers.indexOf("17") > -1) {
                    warning = "You have selected Humana as the current carrier and Humana Level Funded is currently selected as a carrier to be quoted.  Humana Level Funded plans will be included in the quote, but no rates will be included.";
                }
                if (currentMedicalCarrierId === "4" && selectedCarriers.indexOf("18") > -1) {
                    warning = "You have selected Aetna as the current carrier and Aetna AFA is currently selected as a carrier to be quoted.  Aetna AFA plans will be included in the quote, but no rates will be included.";
                }
                //Level Funded to the SAME Level Funded
                if (currentMedicalCarrierId === "16" && selectedCarriers.indexOf("16") > -1) {
                    warning = "You have selected UHC Level Funded as the current carrier and UHC Level Funded is currently selected as a carrier to be quoted.  UHC Level Funded plans will be included in the quote, but no rates will be included.";
                }
                if (currentMedicalCarrierId === "17" && selectedCarriers.indexOf("17") > -1) {
                    warning = "You have selected Humana Level Funded as the current carrier and Humana Level Funded is currently selected as a carrier to be quoted.  Humana Level Funded plans will be included in the quote, but no rates will be included.";
                }
                if (currentMedicalCarrierId === "18" && selectedCarriers.indexOf("18") > -1) {
                    warning = "You have selected Aetna AFA as the current carrier and Aetna AFA is currently selected as a carrier to be quoted.  Aetna AFA plans will be included in the quote, but no rates will be included.";
                }
                if (currentMedicalCarrierId === "25" && selectedCarriers.indexOf("25") > -1) {
                    warning = "You have selected Blue Balance Funded as the current carrier and Blue Balance Funded is currently selected as a carrier to be quoted.  Blue Balance Funded plans will be included in the quote, but no rates will be included.";
                }
            }
            //update state
            this.setState({ currentCarrierWarning: warning });
        }
    };

    _handleSelectedCarriersChanged = (e) => {
        const { selectedCarriers } = this.state;
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;
        let currentOptions = selectedCarriers;

        if (val) {
            //select all?
            if (value === "-1") {
                currentOptions = this._getFilteredCarrierList().map(p => p.id.toString());
            } else {
                currentOptions.push(value);
            }
        } else {
            //deselect all?
            if (value === "-1") {
                currentOptions = [];
            } else {
                const index = currentOptions.indexOf(value);
                if (index > -1) {
                    currentOptions.splice(index, 1)
                }
            }
        }

        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.selectedCarrierIds]: currentOptions,
                },
                selectedCarriers: currentOptions,
            };
        }, () => {
            this._checkCarrierWarnings();
        });
    };

    _handleTextChange = e => {
        const { name } = e.target;
        let { value } = e.target;

        if (name === inputNames.phone) {
            value = util.maskPhoneNumber(value);
        } else if (name === inputNames.atne || name === inputNames.totalNumberEmployees || name === inputNames.totalNumberEligibleEmployees || name === inputNames.postalCode) {
            value = value.replace(/[^0-9]+/g, "");
            if (value) {
                //maxlength of 3 for atne and 5 for postal
                if ((name === inputNames.atne && value.length === 4) ||
                    (name === inputNames.totalNumberEmployees && value.length === 4) ||
                    (name === inputNames.totalNumberEligibleEmployees && value.length === 4) ||
                    (name === inputNames.postalCode && value.length === 6)) {
                    return;
                }
                //if postalcode is less than 5 then filter carrier list
                if (name === inputNames.postalCode && value.length < 6) {
                    this._getFilteredCarrierList();
                }
            }
        }

        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
            };
        });
    };

    _validForm = () => {
        const errors = {};
        const { inputs } = this.state;
        const { counties } = this.props;

        if (inputs[inputNames.employerId]) {
            Object.keys(inputs).forEach(stateKey => {
                if (!inputs[stateKey] && (stateKey === inputNames.atne || stateKey === inputNames.totalNumberEmployees || stateKey === inputNames.totalNumberEligibleEmployees || stateKey === inputNames.effectiveDate || stateKey === inputNames.postalCode || (stateKey === inputNames.countyId && counties && counties.length > 0))) {
                    errors[stateKey] = strings.required;
                }
                if (!inputs[stateKey] && stateKey === inputNames.sicCodeId && !inputs[inputNames.sicCode]) {
                    errors[inputNames.sicCodeId] = strings.requiredSIC;
                }
                if (stateKey === inputNames.selectedCarrierIds && (inputs[stateKey].length === 0 || (inputs[stateKey].length === 1 && inputs[stateKey][0] === "-1"))) {
                    errors[inputNames.selectedCarrierIds] = strings.required;
                }
                if (!inputs[stateKey] && stateKey === inputNames.brokerId) {
                    errors[inputNames.brokerId] = strings.requiredBroker;
                }
            });
        } else {
            Object.keys(inputs).forEach(stateKey => {
                if (stateKey === inputNames.employerId || stateKey === inputNames.currentMedicalCarrierId || (stateKey === inputNames.countyId && (!counties || counties.length === 0))) {
                    return;
                }

                if (stateKey === inputNames.selectedCarrierIds && (inputs[stateKey].length === 0 || (inputs[stateKey].length === 1 && inputs[stateKey][0] === "-1"))) {
                    errors[inputNames.selectedCarrierIds] = strings.required;
                }

                if (inputs[stateKey] === null || inputs[stateKey] === "") {
                    if (stateKey === inputNames.brokerId) {
                        errors[inputNames.brokerId] = strings.requiredBroker;
                    } else if (stateKey === inputNames.sicCodeId) {
                        errors[inputNames.sicCodeId] = strings.requiredSIC;
                    } else {
                        errors[stateKey] = strings.required;
                    }
                }
            });
        }
        //check for field errors
        this.setState({ errors });
        //return errors found yes/no
        return !Object.keys(errors).length;
    };

    _saveQuote = e => {
        e.preventDefault();

        if (this.state.loading) {
            return;
        }

        if (!this._validForm()) {
            return;
        }

        const { inputs } = this.state;

        //ensure that selected carriers only includes available carriers
        const availableCarriers = this._getFilteredCarrierList();
        const currentlySelected = inputs[inputNames.selectedCarrierIds];
        let selected = [];
        currentlySelected.forEach(item => {
            if (item !== "-1" && availableCarriers.find(c => c.id.toString() === item)) {
                selected.push(item);
            }
        });
        //reset
        inputs[inputNames.selectedCarrierIds] = selected;
        //save
        this.props.saveQuote(inputs);
    };

    _handleEmployerSelected = () => {
        if (this.state.selectedEmployerId) {
            this._getEmployer(this.state.selectedEmployerId);
        } else if (this.state.employerName) {
            this.setState(prevState => {
                return {
                    inputs: {
                        ...prevState.inputs,
                        [inputNames.employerId]: "",
                    },
                    selectedEmployer: {
                        name: this.state.employerName,
                    },
                };
            });
        }
    };

    _handleEmployerNameChanged = name => {
        this.setState({
            employerName: name,
            selectedEmployerId: "",
        });
    };

    _handleYesClicked = () => {
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.employerId]: 0,
                    [inputNames.name]: this.state.employerName,
                    [inputNames.isVirgin]: false,
                    [inputNames.isTest]: false,
                    [inputNames.isLargeGroup]: false,
                    [inputNames.brokerId]: "",
                    [inputNames.postalCode]: "",
                    [inputNames.countyId]: null,
                    [inputNames.sicCodeId]: null,
                    [inputNames.totalNumberEmployees]: "",
                    [inputNames.totalNumberEligibleEmployees]: "",
                    [inputNames.atne]: "",
                    [inputNames.effectiveDate]: null,
                    [inputNames.currentMedicalCarrierId]: "-1",
                },
                confirmAddEmployer: true,
            };
        });
    };

    _getFilteredCarrierList = () => {
        const { inputs, smallGroupCarriers, largeGroupCarriers } = this.state;
        //set initial carrier list.  Filter after we determine the state
        let carrierList = smallGroupCarriers;
        if (inputs[inputNames.isLargeGroup]) {
            carrierList = largeGroupCarriers;
        }

        //verify...
        const zipString = inputs[inputNames.postalCode];
        if (!zipString || zipString.length != 5) {
            //show only select/deselect
            return carrierList.filter(c => c.id === -1);
        }

        //parse int value
        const zipcode = parseInt(zipString, 10);

        let st;
        if (zipcode >= 35000 && zipcode <= 36999) {
            st = 'AL';
        } else if (zipcode >= 99500 && zipcode <= 99999) {
            st = 'AK';
        } else if (zipcode >= 85000 && zipcode <= 86999) {
            st = 'AZ';
        } else if (zipcode >= 71600 && zipcode <= 72999) {
            st = 'AR';
        } else if (zipcode >= 90000 && zipcode <= 96699) {
            st = 'CA';
        } else if (zipcode >= 80000 && zipcode <= 81999) {
            st = 'CO';
        } else if ((zipcode >= 6000 && zipcode <= 6389) || (zipcode >= 6391 && zipcode <= 6999)) {
            st = 'CT';
        } else if (zipcode >= 19700 && zipcode <= 19999) {
            st = 'DE';
        } else if (zipcode >= 32000 && zipcode <= 34999) {
            st = 'FL';
        } else if ((zipcode >= 30000 && zipcode <= 31999) || (zipcode >= 39800 && zipcode <= 39999)) {
            st = 'GA';
        } else if (zipcode >= 96700 && zipcode <= 96999) {
            st = 'HI';
        } else if (zipcode >= 83200 && zipcode <= 83999) {
            st = 'ID';
        } else if (zipcode >= 60000 && zipcode <= 62999) {
            st = 'IL';
        } else if (zipcode >= 46000 && zipcode <= 47999) {
            st = 'IN';
        } else if (zipcode >= 50000 && zipcode <= 52999) {
            st = 'IA';
        } else if (zipcode >= 66000 && zipcode <= 67999) {
            st = 'KS';
        } else if (zipcode >= 40000 && zipcode <= 42999) {
            st = 'KY';
        } else if (zipcode >= 70000 && zipcode <= 71599) {
            st = 'LA';
        } else if (zipcode >= 3900 && zipcode <= 4999) {
            st = 'ME';
        } else if (zipcode >= 20600 && zipcode <= 21999) {
            st = 'MD';
        } else if ((zipcode >= 1000 && zipcode <= 2799) || (zipcode == 5501) || (zipcode == 5544)) {
            st = 'MA';
        } else if (zipcode >= 48000 && zipcode <= 49999) {
            st = 'MI';
        } else if (zipcode >= 55000 && zipcode <= 56899) {
            st = 'MN';
        } else if (zipcode >= 38600 && zipcode <= 39999) {
            st = 'MS';
        } else if (zipcode >= 63000 && zipcode <= 65999) {
            st = 'MO';
        } else if (zipcode >= 59000 && zipcode <= 59999) {
            st = 'MT';
        } else if (zipcode >= 27000 && zipcode <= 28999) {
            st = 'NC';
        } else if (zipcode >= 58000 && zipcode <= 58999) {
            st = 'ND';
        } else if (zipcode >= 68000 && zipcode <= 69999) {
            st = 'NE';
        } else if (zipcode >= 88900 && zipcode <= 89999) {
            st = 'NV';
        } else if (zipcode >= 3000 && zipcode <= 3899) {
            st = 'NH';
        } else if (zipcode >= 7000 && zipcode <= 8999) {
            st = 'NJ';
        } else if (zipcode >= 87000 && zipcode <= 88499) {
            st = 'NM';
        } else if ((zipcode >= 10000 && zipcode <= 14999) || (zipcode == 6390) || (zipcode == 501) || (zipcode == 544)) {
            st = 'NY';
        } else if (zipcode >= 43000 && zipcode <= 45999) {
            st = 'OH';
        } else if ((zipcode >= 73000 && zipcode <= 73199) || (zipcode >= 73400 && zipcode <= 74999)) {
            st = 'OK';
        } else if (zipcode >= 97000 && zipcode <= 97999) {
            st = 'OR';
        } else if (zipcode >= 15000 && zipcode <= 19699) {
            st = 'PA';
        } else if (zipcode >= 300 && zipcode <= 999) {
            st = 'PR';
        } else if (zipcode >= 2800 && zipcode <= 2999) {
            st = 'RI';
        } else if (zipcode >= 29000 && zipcode <= 29999) {
            st = 'SC';
        } else if (zipcode >= 57000 && zipcode <= 57999) {
            st = 'SD';
        } else if (zipcode >= 37000 && zipcode <= 38599) {
            st = 'TN';
        } else if ((zipcode >= 75000 && zipcode <= 79999) || (zipcode >= 73301 && zipcode <= 73399) || (zipcode >= 88500 && zipcode <= 88599)) {
            st = 'TX';
        } else if (zipcode >= 84000 && zipcode <= 84999) {
            st = 'UT';
        } else if (zipcode >= 5000 && zipcode <= 5999) {
            st = 'VT';
        } else if ((zipcode >= 20100 && zipcode <= 20199) || (zipcode >= 22000 && zipcode <= 24699) || (zipcode == 20598)) {
            st = 'VA';
        } else if ((zipcode >= 20000 && zipcode <= 20099) || (zipcode >= 20200 && zipcode <= 20599) || (zipcode >= 56900 && zipcode <= 56999)) {
            st = 'DC';
        } else if (zipcode >= 98000 && zipcode <= 99499) {
            st = 'WA';
        } else if (zipcode >= 24700 && zipcode <= 26999) {
            st = 'WV';
        } else if (zipcode >= 53000 && zipcode <= 54999) {
            st = 'WI';
        } else if (zipcode >= 82000 && zipcode <= 83199) {
            st = 'WY';
        } else {
            //show only select/deselect
            return carrierList.filter(c => c.id === -1);
        }
        //filter the carrierList;
        const effDate = new Date(inputs[inputNames.effectiveDate]);
        return carrierList.filter(c => c.id === -1 || (c.area.indexOf(st) > -1 && c.validThroughDate !== null && new Date(c.validThroughDate) >= effDate));
    }


    _renderQuoteForm = () => {
        const { selectedEmployer, employerName, confirmAddEmployer, inputs, allCarriers, largeGroupCarriers, selectedCarriers, currentCarrierWarning } = this.state;
        const { counties } = this.props;

        const carrierList = this._getFilteredCarrierList();

        if (!selectedEmployer.id && employerName && !confirmAddEmployer) {
            return (
                <React.Fragment>
                    <div className={css(styles.newGroupNameHeader)}>{employerName}</div>
                    <div className={css(styles.instructions)}>is not in our system.  Would you like to add this group?</div>
                    <div className={css(styles.confirmButtonContainer)}>
                        <ActionButton
                            aStyles={styles.confirmButton}
                            disabled={this.state.loading}
                            label={strings.cancelButton}
                            invertColor={true}
                            type="button"
                            onClick={this._clearClicked}
                        />
                        <ActionButton
                            aStyles={styles.confirmButton}
                            disabled={this.state.loading}
                            label="Yes"
                            onClick={this._handleYesClicked}
                        />
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <div className={css(styles.createGroupContainer, styles.inputContainer)}>
                    <form onSubmit={this._saveQuote}>
                        <div className={css(styles.checkboxContainer)}>
                            {/*<label className={css(styles.checkLabel)}>
                                <Checkbox
                                    aStyles={styles.check}
                                    checked={inputs[inputNames.isVirgin]}
                                    name={inputNames.isVirgin}
                                    onChange={this._handleInputChange}
                                    disabled={selectedEmployer.id ? true : false}
                                />
                                <span className={css(styles.labelText)}>{strings.virginGroup}</span>
                            </label>*/}
                            {largeGroupCarriers.length > 1 &&
                                <div className={css(styles.toggleContainer)}>
                                    <span>Large Group</span>
                                    <Toggle
                                        aStyles={styles.toggle}
                                        defaultChecked={inputs[inputNames.isLargeGroup]}
                                        onChange={this._handleGroupSizeChange}
                                        checkedLabel=""
                                        uncheckedLabel=""
                                    />
                                    <span>Small Group</span>
                                </div>
                            }
                            <label className={css(styles.checkLabel)}>
                                <Checkbox
                                    aStyles={styles.check}
                                    checked={inputs[inputNames.isTest]}
                                    name={inputNames.isTest}
                                    onChange={this._handleInputChange}
                                    disabled={false}//selectedEmployer.id ? true : false}
                                />
                                <span className={css(styles.labelText)}>{strings.testGroup}</span>
                            </label>
                        </div>
                        {selectedEmployer.id ? (
                            <React.Fragment>
                                <div className={css(styles.itemTitle)}>Group Name</div>
                                <div className={css(styles.item)}>{selectedEmployer.name}</div>
                                {selectedEmployer.brokerId && <div className={css(styles.itemTitle)}>Broker name</div>}
                                {selectedEmployer.brokerId && <div className={css(styles.item)}>{selectedEmployer.brokerName}</div>}
                                {!selectedEmployer.brokerId &&
                                    <div className={css(styles.brokerSearchBox)}>
                                        <SearchInput
                                            tabIndex="1"
                                            search={searchApi.searchBrokers}
                                            onItemSelected={this._brokerSelected}
                                            placeholder={strings.brokerNamePlaceholder}
                                            aStyles={styles.brokerSearchBox}
                                            type={searchInputTypes.text}
                                            validationMessage={this.state.errors[inputNames.brokerId]}
                                            value={inputs[inputNames.brokerId]}
                                        />
                                    </div>
                                }
                                <DatePicker
                                    tabIndex="2"
                                    name={inputNames.effectiveDate}
                                    onChange={this._handleEffectiveDateChanged}
                                    placeholder={strings.effectiveDatePlaceholder}
                                    validationMessage={this.state.errors[inputNames.effectiveDate]}
                                    value={inputs[inputNames.effectiveDate]}
                                />
                                <TextInput
                                    tabIndex="3"
                                    name={inputNames.totalNumberEmployees}
                                    onChange={this._handleTextChange}
                                    placeholder={strings.totalNumberEmployeesPlaceholder}
                                    validationMessage={this.state.errors[inputNames.totalNumberEmployees]}
                                    value={inputs[inputNames.totalNumberEmployees]}
                                />
                                <TextInput
                                    tabIndex="4"
                                    name={inputNames.totalNumberEligibleEmployees}
                                    onChange={this._handleTextChange}
                                    placeholder={strings.totalNumberEligibleEmployeesPlaceholder}
                                    validationMessage={this.state.errors[inputNames.totalNumberEligibleEmployees]}
                                    value={inputs[inputNames.totalNumberEligibleEmployees]}
                                />
                                <TextInput
                                    tabIndex="5"
                                    name={inputNames.atne}
                                    onChange={this._handleTextChange}
                                    placeholder={strings.atnePlaceholder}
                                    validationMessage={this.state.errors[inputNames.atne]}
                                    value={inputs[inputNames.atne]}
                                />
                                <TextInput
                                    tabIndex="6"
                                    name={inputNames.postalCode}
                                    onChange={this._handleTextChange}
                                    placeholder={strings.zipPlaceholder}
                                    validationMessage={this.state.errors[inputNames.postalCode]}
                                    value={inputs[inputNames.postalCode]}
                                />
                                {counties.length > 1 &&
                                    <Dropdown
                                        tabIndex="7"
                                        name={inputNames.countyId}
                                        options={counties.map(item => ({
                                            label: item.name,
                                            value: item.id.toString(),
                                        }))}
                                        onChange={this._handleCountyIdChanged}
                                        placeholder={strings.countyPlaceholder}
                                        validationMessage={this.state.errors[inputNames.countyId]}
                                        value={inputs[inputNames.countyId]}
                                    />
                                }
                                <div className={css(styles.sicSearchBox)}>
                                    <SearchInput
                                        tabIndex="8"
                                        search={searchApi.searchSICCodes}
                                        onItemSelected={this._sicCodeSelected}
                                        onTextChanged={this._sicCodeTextChanged}
                                        placeholder={strings.sicPlaceholder}
                                        validationMessage={this.state.errors[inputNames.sicCodeId]}
                                        value={inputs[inputNames.sicCodeId] ? inputs[inputNames.sicCodeId] : inputs[inputNames.sicCode]}
                                        aStyles={styles.sicSearchBox}
                                        type={searchInputTypes.textNoIcon}
                                    />
                                </div>
                                <Dropdown
                                    tabIndex="9"
                                    name={inputNames.currentMedicalCarrierId}
                                    options={allCarriers.map(item => ({
                                        label: item.name,
                                        value: item.id.toString(),
                                    }))}
                                    onChange={this._handleCurrentMedicalCarrierIdChanged}
                                    placeholder={strings.currentCarrierPlaceholder}
                                    validationMessage={this.state.errors[inputNames.currentMedicalCarrierId]}
                                    value={inputs[inputNames.currentMedicalCarrierId]}
                                    warning={currentCarrierWarning}
                                />
                                <div className={css(styles.itemTitle)}>{strings.selectedCarriersPlaceholder}<div className={css(styles.requiredText)}>{this.state.errors[inputNames.selectedCarrierIds]}</div></div>
                                <div className={css(styles.checkboxContainer2)}>
                                    {carrierList.length > 1 ? carrierList.map((option, index) => (
                                        <div key={index}>
                                            <label className={css(styles.checkLabel2)}>
                                                <Checkbox
                                                    tabIndex={index + 10}
                                                    aStyles={styles.check}
                                                    checked={selectedCarriers.includes(option.id ? option.id.toString() : option)}
                                                    name={"carriers"}
                                                    value={option.id ? option.id : option}
                                                    onChange={e => this._handleSelectedCarriersChanged(e)}
                                                />
                                                <span className={css(styles.labelText)}>
                                                    {option.name ? option.name : option}
                                                </span>
                                            </label>
                                        </div>
                                    )) : <div className={css(styles.warning)}>No carriers available</div>}
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <TextInput
                                    tabIndex="20"
                                    name={inputNames.name}
                                    onChange={this._handleTextChange}
                                    placeholder={strings.namePlaceholder}
                                    validationMessage={this.state.errors[inputNames.name]}
                                    value={inputs[inputNames.name]}
                                />
                                <div className={css(styles.brokerSearchBox)}>
                                    <SearchInput
                                        tabIndex="21"
                                        search={searchApi.searchBrokers}
                                        onItemSelected={this._brokerSelected}
                                        placeholder={strings.brokerNamePlaceholder}
                                        aStyles={styles.brokerSearchBox}
                                        type={searchInputTypes.text}
                                        validationMessage={this.state.errors[inputNames.brokerId]}
                                        value={inputs[inputNames.brokerId]}
                                    />
                                </div>
                                <DatePicker
                                    tabIndex="22"
                                    name={inputNames.effectiveDate}
                                    onChange={this._handleEffectiveDateChanged}
                                    placeholder={strings.effectiveDatePlaceholder}
                                    validationMessage={this.state.errors[inputNames.effectiveDate]}
                                    value={inputs[inputNames.effectiveDate]}
                                />
                                <TextInput
                                    tabIndex="23"
                                    name={inputNames.totalNumberEmployees}
                                    onChange={this._handleTextChange}
                                    placeholder={strings.totalNumberEmployeesPlaceholder}
                                    validationMessage={this.state.errors[inputNames.totalNumberEmployees]}
                                    value={inputs[inputNames.totalNumberEmployees]}
                                />
                                <TextInput
                                    tabIndex="24"
                                    name={inputNames.totalNumberEligibleEmployees}
                                    onChange={this._handleTextChange}
                                    placeholder={strings.totalNumberEligibleEmployeesPlaceholder}
                                    validationMessage={this.state.errors[inputNames.totalNumberEligibleEmployees]}
                                    value={inputs[inputNames.totalNumberEligibleEmployees]}
                                />
                                <TextInput
                                    tabIndex="25"
                                    name={inputNames.atne}
                                    onChange={this._handleTextChange}
                                    placeholder={strings.atnePlaceholder}
                                    validationMessage={this.state.errors[inputNames.atne]}
                                    value={inputs[inputNames.atne]}
                                />
                                <TextInput
                                    tabIndex="26"
                                    name={inputNames.postalCode}
                                    onChange={this._handleTextChange}
                                    placeholder={strings.zipPlaceholder}
                                    validationMessage={this.state.errors[inputNames.postalCode]}
                                    value={inputs[inputNames.postalCode]}
                                />
                                {counties.length > 1 &&
                                    <Dropdown
                                        tabIndex="27"
                                        name={inputNames.countyId}
                                        options={counties.map(item => ({
                                            label: item.name,
                                            value: item.id.toString(),
                                        }))}
                                        onChange={this._handleCountyIdChanged}
                                        placeholder={strings.countyPlaceholder}
                                        validationMessage={this.state.errors[inputNames.countyId]}
                                        value={inputs[inputNames.countyId]}
                                    />
                                }
                                <div className={css(styles.sicSearchBox)}>
                                    <SearchInput
                                        tabIndex="28"
                                        search={searchApi.searchSICCodes}
                                        onItemSelected={this._sicCodeSelected}
                                        onTextChanged={this._sicCodeTextChanged}
                                        placeholder={strings.sicPlaceholder}
                                        validationMessage={this.state.errors[inputNames.sicCodeId]}
                                        value={inputs[inputNames.sicCodeId]}
                                        aStyles={styles.sicSearchBox}
                                        type={searchInputTypes.textNoIcon}
                                    />
                                </div>
                                <Dropdown
                                    tabIndex="29"
                                    name={inputNames.currentMedicalCarrierId}
                                    options={allCarriers.map(item => ({
                                        label: item.name,
                                        value: item.id.toString(),
                                    }))}
                                    onChange={this._handleCurrentMedicalCarrierIdChanged}
                                    placeholder={strings.currentCarrierPlaceholder}
                                    validationMessage={this.state.errors[inputNames.currentMedicalCarrierId]}
                                    value={inputs[inputNames.currentMedicalCarrierId]}
                                    warning={currentCarrierWarning}
                                />
                                <div className={css(styles.itemTitle)}>{strings.selectedCarriersPlaceholder}</div>
                                <div className={css(styles.checkboxContainer2)}>
                                    {carrierList.map((option, index) => (
                                        <div key={index}>
                                            <label className={css(styles.checkLabel2)}>
                                                <Checkbox
                                                    tabIndex={index + 30}
                                                    aStyles={styles.check}
                                                    checked={selectedCarriers.includes(option.id ? option.id.toString() : option)}
                                                    name={"carriers"}
                                                    value={option.id ? option.id : option}
                                                    onChange={e => this._handleSelectedCarriersChanged(e)}
                                                />
                                                <span className={css(styles.labelText)}>
                                                    {option.name ? option.name : option}
                                                </span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </React.Fragment>
                        )}
                        <div className={css(styles.buttonContainer)}>
                            <ActionButton
                                aStyles={styles.clearButton}
                                disabled={this.state.loading}
                                label={strings.backButton}
                                invertColor={true}
                                type="button"
                                onClick={this._clearClicked}
                            />
                            <ActionButton
                                tabIndex="20"
                                aStyles={styles.proceedToCensusButton}
                                disabled={this.state.loading || carrierList.length === 1}
                                label={strings.proceedToCensusButton}
                                type="submit"
                                isLoading={this.props.isSaving}
                            />
                        </div>
                    </form>
                </div>
            );
        }
    };

    render() {
        const { selectedEmployer, employerName } = this.state;

        return (
            <div className={css(styles.searchGroupContainer, styles.inputContainer)}>
                <ToastContainer position="top-center" autoClose={5000} />
                <Headline aStyles={styles.headline}>{strings.title}</Headline>
                {selectedEmployer === null ? (
                    <React.Fragment>
                        <div className={css(styles.instructions)}>
                            Please type the group name, so we can check our database for existing records.
                        </div>
                        <div className={css(styles.groupSearchBox)}>
                            <SearchInput
                                search={searchApi.searchEmployers}
                                onItemSelected={this._employerSelected}
                                placeholder={strings.groupSearchPlaceholder}
                                type={searchInputTypes.textNoIcon}
                                aStyles={styles.groupSearchBox}
                                value={employerName}
                                validationMessage={this.state.errors[inputNames.employerId]}
                                onTextChanged={this._handleEmployerNameChanged}
                                hideLabel={true}
                            />
                        </div>
                        <div className={css(styles.searchGroupButtonContainer)}>
                            <ActionButton
                                aStyles={styles.submitButton}
                                disabled={this.state.loading}
                                label={strings.submitButton}
                                isLoading={this.state.isLoadingEmployer}
                                onClick={this._handleEmployerSelected}
                            />
                        </div>
                    </React.Fragment>
                ) : (
                    this._renderQuoteForm()
                )}
            </div>
        );
    }
}


const mapDispatchToProps = state => ({
    me: state.session.me,
});

export default connect(mapDispatchToProps)(FindCreateGroup);
