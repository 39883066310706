import * as color from "../constants/color";
import PropTypes from "prop-types";
import React from "react";

export default function CloseIcon(props) {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
        >
            <g
                className={props.pathClassName}
                fill={props.fill}
                fillRule="evenodd"
                stroke={props.fill}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                opacity=".5"
            >
                <path d="M1 1l12 12M13 1L1 13" />
            </g>
        </svg>
    );
}

CloseIcon.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string.isRequired,
    pathClassName: PropTypes.string,
};

CloseIcon.defaultProps = {
    fill: color.darkestBlue,
};
