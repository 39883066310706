import * as color from "../../constants/color";
import * as routeHelpers from "../../lib/routeHelpers";
import * as texchangePropTypes from "../../texchangePropTypes";
import Spinner from "../Spinner";
import Dropdown from "../inputs/Dropdown";
import TextInput from "../inputs/TextInput";
import Checkbox from "../inputs/Checkbox";
import InlineEdit from "../inputs/InlineEdit";
import Headline from "../text/Headline";
import { Container, Row, Column } from "../grid";
import PageTitle from "../text/PageTitle";
import React, { Component } from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import Tabs from "../Tabs";
import Infinite from "../Infinite";
import * as brokersApi from "../../lib/api/brokers";
import * as employerApi from "../../lib/api/employer";
import * as agenciesApi from "../../lib/api/agencies";
import * as searchApi from "../../lib/api/search";
import * as accountApi from "../../lib/api/account";
import * as dataApi from "../../lib/api/data";
import * as util from "../../lib/util";
import * as searchInputTypes from "../../constants/searchInputTypes";
import logger from "../../lib/logger";
import describeNetworkError from "../../lib/describeNetworkError";
import { ToastContainer, toast } from 'react-toastify';
import SearchInput from "../inputs/SearchInput";
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as Close } from "../../images/close.svg";

import { Chart, Doughnut, Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

Chart.defaults.global.defaultFontFamily = "'Roboto','Helvetica','Arial',sans-serif";

const styles = StyleSheet.create({
    content: {
        paddingRight: 10,
        paddingBottom: 80,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
    },
    contentSection: {
        width: "100%",
        padding: 0,
        backgroundColor: color.white,
        borderTop: `8px solid ${color.mediumRed}`,
        marginBottom: 20,
    },
    title: {
        fontSize: 20,
        fontWeight: 500,
        paddingBottom: 0,
        marginBottom: 16,
    },
    titleSection: {
        heigth: 64,
        backgroundColor: color.white,
    },
    topRightHolder: {
        float: "right",
        cursor: "pointer",
        marginTop: 8,
        marginRight: 24,
    },
    topRightText: {
        display: "inline-block",
        fontSize: 14,
    },
    blueCircle: {
        paddingTop: 6,
        marginLeft: 12,
        position: "relative",
        top: 4,
    },
    link: {
        color: color.mediumYellow1,
        paddingRight: 20,
        cursor: "pointer",
    },
    tabContent: {
        padding: 15,
        backgroundColor: color.white,
    },
    tabActivity: {
        fontSize: 12,
    },
    leftColumn: {
        paddingRight: 16,
    },
    centerColumn: {
        paddingRight: 16,
        paddingLeft: 16,
    },
    rightColumn: {
        paddingLeft: 16,
    },
    row: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        marginBottom: 20,
    },
    column: {
        display: "flex",
        flexDirection: "column",
        flexBasis: "100%",
        flex: 1,
        marginLeft: 15,
        marginRight: 15,
    },
    heading: {
        width: "100%",
        fontSize: 18,
        borderBottom: `2px solid ${color.mediumRed}`,
        padding: 5,
        marginLeft: 5,
        marginRight: 5,
    },
    item: {
        width: "100%",
        height: 60,
        display: "block",
        paddingTop: 10,
        paddingBottom: 12,
        marginBottom: 12,
        borderBottom: `1px solid ${color.darkGray6}`,
    },
    active: {
        color: color.darkGray,
    },
    checkLabel: {
        display: "block",
        position: "relative",
        border: "none",
        marginTop: 8,
        marginBottom: 16,
        "-webkit-appearance": "none",
        textAlign: "left",
    },
    check: {
        position: "absolute",
        top: 0,
        right: 20,
    },
    container: {
        borderBottom: `1px solid ${color.darkGray6}`,
        paddingBottom: 12,
        marginBottom: 12,
        height: 60,
    },
    headerLabel: {
        float: "left",
        fontSize: 14,
        fontWeight: 500,
        color: color.darkGray,
    },
    displayContainer: {
        clear: "both",
        color: color.darkestGray,
        fontSize: 16,
        paddingTop: 10,
    },
    displayRow: {
        marginTop: 4,
        marginBottom: 4,
        minHeight: 18,
    },
    noResults: {
        backgroundColor: color.white,
        color: color.darkGray,
        padding: 20,
        marginBottom: 40,
    },
    teamMembersColumn: {
        flex: "3 1 0%",
    },
    resetPassword: {
        color: color.mediumYellow1,
        float: "right",
        cursor: "pointer",
    },
    charts: {
        textAlign: "center",
        padding: 30,
    },
    table: {
        width: "100%",
        marginTop: 10,
        marginBottom: 20,
        borderCollapse: "collapse",
    },
    tableHeader: {
        fontSize: 13,
        color: color.borderBackgroundGray,
        backgroundColor: color.white,
        padding: 10,
        whiteSpace: "nowrap",
        borderBottom: `1px solid ${color.mediumGray}`,
    },
    tableRow: {
        fontSize: 14,
        color: color.tableText,
        padding: "20px 10px",
        borderBottom: `1px solid ${color.tableBorder}`,
    },
    tdChart: {
        backgroundColor: color.white,
        padding: "10px 10px 40px 10px",
        verticalAlign: "top",
    },
    thirty: {
        width: "30%",
    },
    forty: {
        width: "40%",
    },
    chartTitle: {
        fontSize: 27,
        fontWeight: 500,
        color: color.tableText,
        display: "inline-block",
        padding: "15px 0px",
    },
    chartInfo: {
        fontSize: 20,
        fontWeight: 200,
        color: color.tableText,
    },
    chartLegend: {
        display: "inline-block",
        color: color.white,
        fontWeight: "bold",
    },
    table2: {
        width: "100%",
        marginLeft: 10,
        borderCollapse: "collapse",
    },
    stage1Color: {
        backgroundColor: "#268FE2",
        padding: 6,
    },
    stage2Color: {
        backgroundColor: "#0A6FBE",
        padding: 6,
    },
    stage3Color: {
        backgroundColor: "#065592",
        padding: 6,
    },
    brokerColor: {
        backgroundColor: "#6D5DCE",
        padding: 6,
    },
    rbgColor: {
        backgroundColor: "#271EB2",
        padding: 6,
    },
    barChartHolder: {
        backgroundColor: color.lightGray4,
        borderRadius: 8,
        paddingTop: 20,
        minHeight: 240,
    },
    doughnutHolderLeft: {
        float: "left",
    },
    doughnutHolderRight: {
        float: "right",
    },
    groupSizeHolder: {
        clear: "both",
    },
    relative: {
        position: "relative",
    },
    absoluteCenter: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    textCenter: {
        textAlign: "center",
    },
    minus10: {
        marginTop: -10,
    },
    largeText: {
        fontSize: 30,
        fontWeight: "bold",
    },
    smallText: {
        fontSize: 18,
        fontWeight: "bold",
    },
    gray: {
        color: color.mediumGray,
    },
    green: {
        color: color.mediumRed,
    },
    minHeight: {
        minHeight: 200,
        padding: 20,
    },
    right: {
        float: "right",
    },
    teamMemberContainer: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "space-between",
    },
    teamMemberItem: {
        // flex: "1 1 50%",
        borderBottom: `1px solid ${color.darkGray6}`,
        lineHeight: "16px",
        color: color.darkestGray,
        padding: "6px 12px",
        width: "48%"
    },
    teamMemberName: {
        fontSize: 16,
        paddingTop: 10,
        paddingBottom: 4,
    },
    teamMemberText: {
        fontSize: 14,
        paddingTop: 4,
        paddingBottom: 4,
    },
    close: {
        float: "right",
        cursor: "pointer",
    },
    loadingSpinner: {
        paddingTop: 48,
        paddingBottom: 48,
    },
});

const strings = {
    addBroker: "New Broker",
    generalInformation: "General Information",
    contactInformation: "Contact Information",
    accountInformation: "Account Information",
    brokerName: "Broker Name",
    fullNamePlaceholder: "Broker Name",
    firstNamePlaceholder: "First Name",
    lastNamePlaceholder: "First Name",
    agencyName: "Agency Name",
    agencyNamePlaceholder: "Select Agency",
    brokerType: "Type",
    brokerTypePlaceholder: "Type",
    license: "License #",
    licensePlaceholder: "License",
    nationalProducerNumber: "National Producer # (NPN)",
    nationalProducerNumberPlaceholder: "National Producer #",
    dateOfBirth: "Date of Birth",
    dateOfBirthPlaceholder: "Date of Birth",
    federalTaxId: "Federal Tax #",
    federalTaxIdPlaceholder: "Federal Tax #",
    emailAddress: "Email Address",
    emailAddressPlaceholder: "Email Address",
    phone: "Office Phone #",
    phonePlaceholder: "Office Phone",
    cellPhone: "Mobile Phone #",
    cellPhonePlaceholder: "Mobile Phone",
    homePhone: "Home Phone #",
    homePhonePlaceholder: "Home Phone",
    fax: "Fax Number",
    faxPlaceholder: "Fax Number",
    userName: "User Name",
    userNamePlaceholder: "User Name",
    password: "Password",
    passwordPlaceholder: "Password",
    lastLogin: "Last Login",
    otherOffices: "Onboarded in Other Offices",
    access: "Access",
    ratingEnginePermission: "Allow Rating Engine Access",
    ratingEngineQuotePermission: "Allow Rating Engine Quoting",
    enrollmentPortalPermission: "Allow Enrollment Access",
    brokerHubPermission: "Allow Broker Hub Access",
    saveButton: "Save All Changes",
};

const inputNames = {
    id: "id",
    fullName: "fullName",
    firstName: "firstName",
    lastName: "lastName",
    agencyId: "agencyId",
    agencyName: "agencyName",
    brokerType: "brokerType",
    license: "license",
    nationalProducerNumber: "nationalProducerNumber",
    dateOfBirth: "dateOfBirth",
    federalTaxId: "federalTaxId",
    emailAddress: "emailAddress",
    phone: "phone",
    cellPhone: "cellPhone",
    homePhone: "homePhone",
    fax: "fax",
    userName: "userName",
    password: "password",
    lastLogin: "lastLogin",
    otherOffices: "otherOffices",
    brokerId: "brokerId",
    ratingEnginePermission: "ratingEnginePermission",
    ratingEngineQuotePermission: "ratingEngineQuotePermission",
    enrollmentPortalPermission: "enrollmentPortalPermission",
    brokerHubPermission: "brokerHubPermission",
};

export class BrokerDetailScreen extends Component {
    static propTypes = {
        history: texchangePropTypes.history.isRequired,
        match: texchangePropTypes.match.isRequired,
        me: texchangePropTypes.me.isRequired,
    };

    state = {
        inputs: {
            [inputNames.id]: "",
            [inputNames.fullName]: "",
            [inputNames.firstName]: "",
            [inputNames.lastName]: "",
            [inputNames.agencyId]: null,
            [inputNames.agencyName]: "",
            [inputNames.brokerType]: "",
            [inputNames.license]: "",
            [inputNames.nationalProducerNumber]: "",
            [inputNames.dateOfBirth]: "",
            [inputNames.federalTaxId]: "",
            [inputNames.emailAddress]: "",
            [inputNames.phone]: "",
            [inputNames.cellPhone]: "",
            [inputNames.homePhone]: "",
            [inputNames.fax]: "",
            [inputNames.username]: "",
            [inputNames.password]: "",
            [inputNames.lastLogin]: "",
            [inputNames.otherOffices]: "",
            [inputNames.ratingEnginePermission]: false,
            [inputNames.ratingEngineQuotePermission]: false,
            [inputNames.enrollmentPortalPermission]: false,
            [inputNames.brokerHubPermission]: false,
            [inputNames.regionalOfficeId]: "",
        },
        broker: {},
        brokers: [],
        employers: [],
        employersCount: 0,
        employersMore: false,
        agencies: [],
        brokerLoaded: false,
        dashboardLoaded: false,
        brokerTeamLoaded: false,
        groupsLoaded: false,
        errors: {},
        columnLabels: ["Group", "SIC", "Renewal Date", "Sales Rep", "Special Rep", "Status", "Size"],
        columnValues: ["name", "sicCode", "renewalMonth", "salesRepresentativeName", "specialSalesRepresentativeName", "status", "segmentSize"],
        groupsQuotedData: {},
        incompleteQuotesData: {},
        brokerRBGQuotesData: {},
        renewalsData: {},
        closeRatio3AgoData: {},
        closeRatio2AgoData: {},
        closeRatio1AgoData: {},
        brokerTypes: ["Producer", "Assistant"],
    };

    componentDidMount() {
        this._mounted = true;

        const brokerId = this.props.match.params.id;

        brokersApi.getById(brokerId)
            .then(response => {
                this._brokerLoaded(response);
                Promise.all([employerApi.getByBrokerId(brokerId, 1, null, null), agenciesApi.getAll()])
                    .then(responses => {
                        if (responses && responses[0] && responses[1] && this._mounted) {
                            this.setState({
                                employers: responses[0],
                                employersCount: responses[0][0] ? responses[0][0].recordCount : 0,
                                employersMore: responses[0][0] ? responses[0][0].recordCount > 100 : false,
                                agencies: responses[1],
                                groupsLoaded: true
                            });
                        }
                    })
                    .catch(err => logger.log(err))
                    .finally(() => this.setState({ ready: true }))
            });

        this._getChartData();
        this._getBrokerTeamMembers(brokerId);
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    _getChartData = () => {
        const brokerId = this.props.match.params.id;
        Promise.all([dataApi.getChartData("groups_quoted", brokerId), dataApi.getChartData("incomplete_quotes", brokerId), dataApi.getChartData("broker_vs_rbg_quotes", brokerId), dataApi.getChartData("renewals", brokerId), dataApi.getChartData("close_ratio", brokerId)])
            .then(responses => {
                if (responses && responses[0] && responses[1] && responses[2] && responses[3] && responses[4] && this._mounted) {
                    this.setState({
                        groupsQuotedData: responses[0],
                        incompleteQuotesData: responses[1],
                        brokerRBGQuotesData: responses[2],
                        renewalsData: responses[3],
                        closeRatio3AgoData: responses[4][0],
                        closeRatio2AgoData: responses[4][1],
                        closeRatio1AgoData: responses[4][2],
                        dashboardLoaded: true
                    });
                }
            })

    };

    _getBrokerTeamMembers = () => {
        brokersApi.getBrokersByTeam(this.props.match.params.id)
            .then(response => {
                this.setState({
                    brokers: response,
                    brokerTeamLoaded: true
                });
            });
    }

    _brokerLoaded = broker => {
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.id]: broker.id,
                    [inputNames.firstName]: broker.firstName,
                    [inputNames.lastName]: broker.lastName,
                    [inputNames.fullName]: broker.fullName,
                    [inputNames.agencyId]: broker.agencyId ? broker.agencyId.toString() : null,
                    [inputNames.agencyName]: broker.agencyName,
                    [inputNames.brokerType]: broker.brokerType,
                    [inputNames.license]: broker.license,
                    [inputNames.nationalProducerNumber]: broker.nationalProducerNumber,
                    [inputNames.dateOfBirth]: broker.dateOfBirth,
                    [inputNames.federalTaxId]: broker.federalTaxId,
                    [inputNames.emailAddress]: broker.emailAddress,
                    [inputNames.phone]: broker.phone,
                    [inputNames.cellPhone]: broker.cellPhone,
                    [inputNames.homePhone]: broker.homePhone,
                    [inputNames.fax]: broker.fax,
                    [inputNames.userName]: broker.userName,
                    [inputNames.password]: "********",
                    [inputNames.lastLogin]: broker.lastLogin,
                    [inputNames.otherOffices]: broker.otherOffices,
                    [inputNames.ratingEnginePermission]: broker.ratingEnginePermission,
                    [inputNames.ratingEngineQuotePermission]: broker.ratingEngineQuotePermission,
                    [inputNames.enrollmentPortalPermission]: broker.enrollmentPortalPermission,
                    [inputNames.brokerHubPermission]: broker.brokerHubPermission,
                    [inputNames.regionalOfficeId]: broker.regionalOfficeId,
                },
                broker,
                brokerLoaded: true
            };
        });
    }

    _handleTextChange = e => {
        const { name } = e.target;
        let { value } = e.target;

        if (name === inputNames.phone || name === inputNames.cellPhone || name === inputNames.homePhone || name === inputNames.fax) {
            value = util.maskPhoneNumber(value);
        }
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
            };
        });
    };

    _handleInputChange = e => {
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;

        // --- Hub Permissions --------------------------------------------------------
        //check Hub Quoting permission change
        if (name === "ratingEngineQuotePermission" && value === "on") {
            //check to make sure we ALREADY have the Hub permission
            if (!this.state.inputs[inputNames.ratingEnginePermission]) {
                toast.error("Cannot add Quoting permission before adding Rating Engine access.", { toastId: 1 });
                return;
            }
        }
        //check Hub permission change... turn off Quoting if ALSO removing Hub permission
        if (name === "ratingEnginePermission" && value === "off") {
            //check to make sure we ALREADY have the Hub permission
            if (this.state.inputs[inputNames.ratingEngineQuotePermission]) {
                this.setState(prevState => {
                    return {
                        inputs: {
                            ...prevState.inputs,
                            [inputNames.ratingEngineQuotePermission]: false,
                            [name]: val,
                        },
                    };
                }, () => {
                    this._handleOnSave()
                });
                return;
            }
        }
        // --- Hub Permissions --------------------------------------------------------

        //normal save
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: val,
                },
            };
        }, () => {
            this._handleOnSave()
        });
    };

    _handleBrokerTypeChange = value => {
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.brokerType]: value,
                },
            };
        });
    };

    _goToBrokers = () => {
        this.props.history.push(routeHelpers.brokers());
    };

    /*_goToAddBroker = () => {
        this.props.history.push(routeHelpers.editBroker());
    };*/

    _goToAgency = id => {
        if (id > 0) {
            window.location.href = "/agencies/detail/" + id;
        }
    };

    _handleResetPassword = () => {
        const { broker } = this.state;

        if (!broker.emailAddress || broker.emailAddress.length === 0) {
            toast.error("User account has no email address.", { toastId: 1 });
            return;
        }

        accountApi.forgotPassword(broker.emailAddress, broker.id)
            .then(() => {
                toast.success("Password reset email sent to " + broker.emailAddress, { toastId: 1 });
            })
            .catch(err => {
                logger.warn(err);
                if (!this._mounted) {
                    return;
                }

                let errors = {};
                if (err && err.response) {
                    const { response } = err;
                    if (
                        response &&
                        response.data &&
                        response.data.errors &&
                        Object.keys(response.data.errors).length > 0
                    ) {
                        // Server provided validation messages
                        errors = response.data.errors;
                    }
                    else if (response && response.status === 400) {
                        errors[inputNames.email] = [strings.emailNotFound];
                    }
                    else {
                        // 500 or something else unexpected
                        const description = describeNetworkError(err);
                        toast.error(description.join("\n"), { toastId: 1 });
                    }
                }

                this.setState({
                    errors: errors,
                });
            });
    }

    _handleOnSave = () => {
        const brokerSaved = new Promise(resolve => {
            const { broker } = this.state;

            Object.keys(inputNames).forEach(key => {
                broker[key] = this.state.inputs[key];
            });

            brokersApi.saveBroker(broker)
                .then((response => {
                    this._brokerLoaded(response);
                    resolve(true);
                }))
                .catch(err => {
                    const { response } = err;
                    if (response && (response.status === 400 || response.status === 500) && (response.data.error || response.data.errors)) {
                        const errors = [];
                        let messages = "";
                        if (response.data.errors) {
                            const validationErrors = response.data.errors;
                            Object.keys(validationErrors).forEach(key => {
                                errors[key] = "required";
                                messages += validationErrors[key] + "\n";
                            });
                        }
                        else {
                            messages = response.data.error;
                            if (messages.indexOf("permissions") > -1) {
                                this.setState(prevState => {
                                    return {
                                        inputs: {
                                            ...prevState.inputs,
                                            [inputNames.ratingEnginePermission]: false,
                                            [inputNames.ratingEngineQuotePermission]: false,
                                            [inputNames.enrollmentPortalPermission]: false,
                                            [inputNames.brokerHubPermission]: false,
                                        },
                                    };
                                });
                            }
                        }
                        this.setState({
                            errors,
                        });
                        toast.error(messages, { toastId: 1 });
                        resolve(false);
                    }
                });
        });
        return brokerSaved;
    };

    _handleOnCancel = () => {
        const { broker } = this.state;

        this._brokerLoaded(broker);
    };

    _groupsScroll = (page, col, dir) => {
        if (((page - 1) * 100) < this.state.employersCount) {
            employerApi.getByBrokerId(this.props.match.params.id, page, col, dir).then(result => {
                let joined = {};
                if (page === 1) {
                    joined = result;
                } else {
                    joined = this.state.employers.concat(result);
                }
                this.setState({
                    employers: joined,
                    employersCount: result[0].recordCount,
                    employersMore: result[0].recordCount > (page * 100),
                });
            });
        }
        else {
            this.setState({
                employersMore: false,
            });
        }
    };

    _deleteTeamMember = id => {
        brokersApi.deleteBrokerTeam(this.props.match.params.id, id)
            .then(() => {
                this._getBrokerTeamMembers();
            });
    };

    _handleRowClick = id => {
        this.props.history.push(routeHelpers.groupDetail(id));
    };

    _brokerSelected = item => {
        const brokerId = this.props.match.params.id
        //const brokerTeamMemberId = item.value;

        brokersApi.saveBrokerTeam(brokerId, item.value)
            .then(() => {
                this.setState(prevState => {
                    const errors = { ...prevState.errors };
                    delete errors[inputNames.brokerId];

                    return {
                        inputs: {
                            ...prevState.inputs,
                            [inputNames.brokerId]: item.value,
                        },
                        errors,
                    };
                }, () => {
                    this._getBrokerTeamMembers();
                });
            });
    };

    renderDashboard = () => {
        const { groupsQuotedData, incompleteQuotesData, brokerRBGQuotesData, renewalsData, closeRatio3AgoData, closeRatio2AgoData, closeRatio1AgoData, dashboardLoaded } = this.state;

        if (!dashboardLoaded) {
            return (
                <Spinner
                    aStyles={styles.loadingSpinner}
                    text="Loading Dashboard..."
                />
            );
        }

        const closeRatio1Ago = closeRatio1AgoData.datasets && closeRatio1AgoData.datasets[0] && closeRatio1AgoData.datasets[0].data && closeRatio1AgoData.datasets[0].data[0] !== "0" && closeRatio1AgoData.datasets[0].data[1] !== 0 ? parseInt((closeRatio1AgoData.datasets[0].data[1] / (closeRatio1AgoData.datasets[0].data[0] + closeRatio1AgoData.datasets[0].data[1])) * 100) : 0;
        const closeRatio2Ago = closeRatio2AgoData.datasets && closeRatio2AgoData.datasets[0] && closeRatio2AgoData.datasets[0].data && closeRatio2AgoData.datasets[0].data[0] !== "0" && closeRatio2AgoData.datasets[0].data[1] !== 0 ? parseInt((closeRatio2AgoData.datasets[0].data[1] / (closeRatio2AgoData.datasets[0].data[0] + closeRatio2AgoData.datasets[0].data[1])) * 100) : 0;
        const closeRatio3Ago = closeRatio3AgoData.datasets && closeRatio3AgoData.datasets[0] && closeRatio3AgoData.datasets[0].data && closeRatio3AgoData.datasets[0].data[0] !== "0" && closeRatio3AgoData.datasets[0].data[1] !== 0 ? parseInt((closeRatio3AgoData.datasets[0].data[1] / (closeRatio3AgoData.datasets[0].data[0] + closeRatio3AgoData.datasets[0].data[1])) * 100) : 0;

        const closeRatio1AgoQuoted = closeRatio1AgoData.datasets && closeRatio1AgoData.datasets[0] && closeRatio1AgoData.datasets[0].data ? (closeRatio1AgoData.datasets[0].data[0] + closeRatio1AgoData.datasets[0].data[1]) : "0";
        const closeRatio1AgoSold = closeRatio1AgoData.datasets && closeRatio1AgoData.datasets[0] && closeRatio1AgoData.datasets[0].data ? closeRatio1AgoData.datasets[0].data[1] : "0";
        const closeRatio2AgoQuoted = closeRatio2AgoData.datasets && closeRatio2AgoData.datasets[0] && closeRatio2AgoData.datasets[0].data ? (closeRatio2AgoData.datasets[0].data[0] + closeRatio2AgoData.datasets[0].data[1]) : "0";
        const closeRatio2AgoSold = closeRatio2AgoData.datasets && closeRatio2AgoData.datasets[0] && closeRatio2AgoData.datasets[0].data ? closeRatio2AgoData.datasets[0].data[1] : "0";
        const closeRatio3AgoQuoted = closeRatio3AgoData.datasets && closeRatio3AgoData.datasets[0] && closeRatio3AgoData.datasets[0].data ? (closeRatio3AgoData.datasets[0].data[0] + closeRatio3AgoData.datasets[0].data[1]) : "0";
        const closeRatio3AgoSold = closeRatio3AgoData.datasets && closeRatio3AgoData.datasets[0] && closeRatio3AgoData.datasets[0].data ? closeRatio3AgoData.datasets[0].data[1] : "0";

        const censusAvg = parseInt((closeRatio1AgoData.censusAvg + closeRatio2AgoData.censusAvg + closeRatio3AgoData.censusAvg) / 3);

        //have data?
        const groupsQuotedHasData = groupsQuotedData && groupsQuotedData.datasets &&
            groupsQuotedData.datasets[0] && groupsQuotedData.datasets[0].data && (groupsQuotedData.datasets[0].data[0] > 0 || groupsQuotedData.datasets[0].data[1] > 0 || groupsQuotedData.datasets[0].data[2] > 0) ? true : false;
        const incompleteQuotesHasData = incompleteQuotesData && incompleteQuotesData.datasets &&
            ((incompleteQuotesData.datasets[0] && incompleteQuotesData.datasets[0].data && (incompleteQuotesData.datasets[0].data[0] > 0 || incompleteQuotesData.datasets[0].data[1] > 0 || incompleteQuotesData.datasets[0].data[2] > 0)) ||
                (incompleteQuotesData.datasets[1] && incompleteQuotesData.datasets[1].data && (incompleteQuotesData.datasets[1].data[0] > 0 || incompleteQuotesData.datasets[1].data[1] > 0 || incompleteQuotesData.datasets[1].data[2] > 0)) ||
                (incompleteQuotesData.datasets[2] && incompleteQuotesData.datasets[2].data && (incompleteQuotesData.datasets[2].data[0] > 0 || incompleteQuotesData.datasets[2].data[1] > 0 || incompleteQuotesData.datasets[2].data[2] > 0))) ? true : false;
        const brokerRBGQuotesHasData = brokerRBGQuotesData && brokerRBGQuotesData.datasets &&
            ((brokerRBGQuotesData.datasets[0] && brokerRBGQuotesData.datasets[0].data && (brokerRBGQuotesData.datasets[0].data[0] > 0 || brokerRBGQuotesData.datasets[0].data[1] > 0 || brokerRBGQuotesData.datasets[0].data[2] > 0)) ||
                (brokerRBGQuotesData.datasets[1] && brokerRBGQuotesData.datasets[1].data && (brokerRBGQuotesData.datasets[1].data[0] > 0 || brokerRBGQuotesData.datasets[1].data[1] > 0 || brokerRBGQuotesData.datasets[1].data[2] > 0))) ? true : false;
        const renewalsHasData = renewalsData && renewalsData.datasets &&
            renewalsData.datasets[0] && renewalsData.datasets[0].data && (renewalsData.datasets[0].data[0] > 0 || renewalsData.datasets[0].data[1] > 0 || renewalsData.datasets[0].data[2] > 0) ? true : false;
        const closeRatio1AgoHasData = closeRatio1AgoQuoted > 0;
        const closeRatio2AgoHasData = closeRatio2AgoQuoted > 0;
        const closeRatio3AgoHasData = closeRatio3AgoQuoted > 0;

        //chart options
        const doughnut_options1 = {
            responsive: true,
            cutoutPercentage: 65,
            plugins: {
                datalabels: {
                    display: false,
                },
            },
        };
        const doughnut_options2 = {
            legend: {
                display: false
            },
            responsive: true,
            cutoutPercentage: 60,
            plugins: {
                datalabels: {
                    display: false,
                },
            },
        };
        const bar_options = {
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        fontSize: 20,
                        fontStyle: 'bold',
                        fontColor: color.darkBlue2,
                    },
                    position: 'top',
                }],
                yAxes: [{
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                        beginAtZero: true,
                    }
                }],
            },
            responsive: true,
            plugins: {
                datalabels: {
                    color: 'white',
                    font: {
                        weight: 'bold',
                        size: 20,
                    },
                    anchor: 'end',
                    align: 'start',
                    offset: 0,
                },
            },
        };
        return (
            <div className={css(styles.charts)}>
                <table className={css(styles.table)}>
                    <tbody>
                        <tr>
                            <td className={css(styles.tdChart, styles.thirty)}>
                                <span className={css(styles.chartTitle)}>Groups Quoted</span>
                                <div className={css(styles.barChartHolder)}>
                                    {groupsQuotedHasData ? (
                                        <Bar data={groupsQuotedData} options={bar_options} />
                                    ) : <span className={css(styles.smallText)}>No Data</span>}
                                </div>
                            </td>
                            <td className={css(styles.tdChart, styles.forty)} rowSpan="2">
                                <span className={css(styles.chartTitle)}>Close Ratio</span>
                                {closeRatio1AgoHasData ? (
                                    <div className={css(styles.relative)}>
                                        <Doughnut data={closeRatio1AgoData} options={doughnut_options1} />
                                        <div className={css(styles.absoluteCenter, styles.textCenter)}>
                                            <span className={css(styles.largeText)}><span className={css(styles.gray)}>{closeRatio1AgoQuoted}</span><br /><span className={css(styles.green)}>{closeRatio1AgoSold}</span><br />{closeRatio1Ago}%</span>
                                        </div>
                                        <div className={css(styles.textCenter)}>
                                            <span className={css(styles.largeText)}>{closeRatio1AgoData.month}</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={css(styles.relative, styles.minHeight)}>
                                        <span className={css(styles.smallText)}>No Data<br />{closeRatio1AgoData.month}</span>
                                    </div>
                                )}
                                <div className={css(styles.doughnutHolderLeft)}>
                                    {closeRatio2AgoHasData ? (
                                        <div className={css(styles.relative)}>
                                            <Doughnut data={closeRatio2AgoData} options={doughnut_options2} />
                                            <div className={css(styles.absoluteCenter, styles.textCenter, styles.minus10)}>
                                                <span className={css(styles.smallText)}><span className={css(styles.gray)}>{closeRatio2AgoQuoted}</span><br /><span className={css(styles.green)}>{closeRatio2AgoSold}</span><br />{closeRatio2Ago}%</span>
                                            </div>
                                            <div className={css(styles.textCenter)}>
                                                <span className={css(styles.smallText)}>{closeRatio2AgoData.month}</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={css(styles.relative, styles.minHeight)}>
                                            <span className={css(styles.smallText)}>No Data<br />{closeRatio2AgoData.month}</span>
                                        </div>
                                    )}
                                </div>
                                <div className={css(styles.doughnutHolderRight)}>
                                    {closeRatio3AgoHasData ? (
                                        <div className={css(styles.relative)}>
                                            <Doughnut data={closeRatio3AgoData} options={doughnut_options2} />
                                            <div className={css(styles.absoluteCenter, styles.textCenter, styles.minus10)}>
                                                <span className={css(styles.smallText)}><span className={css(styles.gray)}>{closeRatio3AgoQuoted}</span><br /><span className={css(styles.green)}>{closeRatio3AgoSold}</span><br />{closeRatio3Ago}%</span>
                                            </div>
                                            <div className={css(styles.textCenter)}>
                                                <span className={css(styles.smallText)}>{closeRatio3AgoData.month}</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={css(styles.relative, styles.minHeight)}>
                                            <span className={css(styles.smallText)}>No Data<br />{closeRatio3AgoData.month}</span>
                                        </div>
                                    )}
                                </div>
                                <div className={css(styles.groupSizeHolder)}>
                                    <span className={css(styles.chartTitle)}><br />Avg Group Size: {censusAvg}<br /></span>
                                    <span className={css(styles.chartInfo)}><br />For in force business</span>
                                </div>
                            </td>
                            <td className={css(styles.tdChart, styles.thirty)}>
                                <span className={css(styles.chartTitle)}>Incomplete Quotes</span>
                                <span className={css(styles.chartLegend)}>
                                    <table className={css(styles.table2)}>
                                        <tbody>
                                            <tr>
                                                <td className={css(styles.stage1Color)}>Stage 1</td>
                                                <td className={css(styles.stage2Color)}>Stage 2</td>
                                                <td className={css(styles.stage3Color)}>Stage 3</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </span>
                                <div className={css(styles.barChartHolder)}>
                                    {incompleteQuotesHasData ? (
                                        <Bar data={incompleteQuotesData} options={bar_options} />
                                    ) : <span className={css(styles.smallText)}>No Data</span>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className={css(styles.tdChart, styles.thirty)}>
                                <span className={css(styles.chartTitle)}>Broker vs. Emerson Rogers Quotes</span>
                                <span className={css(styles.chartLegend)}>
                                    <table className={css(styles.table2)}>
                                        <tbody>
                                            <tr>
                                                <td className={css(styles.brokerColor)}>Broker</td>
                                                <td className={css(styles.rbgColor)}>RBG</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </span>
                                <div className={css(styles.barChartHolder)}>
                                    {brokerRBGQuotesHasData ? (
                                        <Bar data={brokerRBGQuotesData} options={bar_options} />
                                    ) : <span className={css(styles.smallText)}>No Data</span>}
                                </div>
                            </td>
                            <td className={css(styles.tdChart, styles.thirty)}>
                                <span className={css(styles.chartTitle)}>Renewals</span>
                                <div className={css(styles.barChartHolder)}>
                                    {renewalsHasData ? (
                                        <Bar data={renewalsData} options={bar_options} />
                                    ) : <span className={css(styles.smallText)}>No Data</span>}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    renderGeneralInfo = () => {
        const { broker, brokerTypes } = this.state;
        const { me } = this.props;
        return (
            <div className={css(styles.tabContent)}>
                <ToastContainer position="top-center" autoClose={5000} />
                <Container>
                    <Row>
                        <Column large={4}>
                            <div className={css(styles.leftColumn)}>
                                <Headline aStyles={styles.title}>{strings.generalInformation}</Headline>
                                <div className={css(styles.container)}>
                                    <div className={css(styles.headerLabel)}>
                                        {strings.brokerName}
                                    </div>
                                    <div className={css(styles.displayContainer)}>
                                        {this.state.inputs[inputNames.fullName]}
                                    </div>
                                </div>
                                <div className={css(styles.container)}>
                                    <div className={css(styles.headerLabel)}>
                                        {strings.agencyName}
                                    </div>
                                    <div className={css(styles.displayContainer, styles.link)} onClick={() => this._goToAgency(broker.agencyId)}>
                                        {this.state.inputs[inputNames.agencyName]}
                                    </div>
                                </div>
                                {/*<div className={css(styles.container)}>
                                    <div className={css(styles.headerLabel)}>
                                        {strings.brokerType}
                                    </div>
                                    <div className={css(styles.displayContainer)}>
                                        {this.state.inputs[inputNames.brokerType]}
                                    </div>
                                </div>*/}
                                <InlineEdit
                                    headerLabel={strings.brokerType}
                                    onSave={this._handleOnSave}
                                    onCancel={this._handleOnCancel}
                                    editable={!broker.deleted}
                                >
                                    <div edit>
                                        <Dropdown
                                            options={brokerTypes.map(typ => ({
                                                label: typ,
                                                value: typ,
                                            }))}
                                            onChange={this._handleBrokerTypeChange}
                                            placeholder={strings.brokerTypePlaceholder}
                                            value={this.state.inputs[inputNames.brokerType]}
                                        />
                                    </div>
                                    <div display>
                                        <div className={css(styles.displayRow)}>
                                            {this.state.inputs[inputNames.brokerType]}
                                        </div>
                                    </div>
                                </InlineEdit>
                                <div className={css(styles.container)}>
                                    <div className={css(styles.headerLabel)}>
                                        {strings.dateOfBirth}
                                    </div>
                                    <div className={css(styles.displayContainer)}>
                                        {this.state.inputs[inputNames.dateOfBirth]}
                                    </div>
                                </div>
                                <InlineEdit headerLabel={strings.license}
                                    onSave={this._handleOnSave}
                                    onCancel={this._handleOnCancel}
                                    editable={(me.isSysAdmin || me.isOfficeAdmin) && !broker.deleted}
                                >
                                    <div edit>
                                        <TextInput
                                            name={inputNames.license}
                                            onChange={this._handleTextChange}
                                            placeholder={strings.licensePlaceholder}
                                            value={this.state.inputs[inputNames.license]}
                                        />
                                    </div>
                                    <div display>
                                        <div className={css(styles.displayRow)}>
                                            {this.state.inputs[inputNames.license]}
                                        </div>
                                    </div>
                                </InlineEdit>
                                <InlineEdit headerLabel={strings.nationalProducerNumber}
                                    onSave={this._handleOnSave}
                                    onCancel={this._handleOnCancel}
                                    editable={(me.isSysAdmin || me.isOfficeAdmin) && !broker.deleted}
                                >
                                    <div edit>
                                        <TextInput
                                            name={inputNames.nationalProducerNumber}
                                            onChange={this._handleTextChange}
                                            placeholder={strings.nationalProducerNumberPlaceholder}
                                            value={this.state.inputs[inputNames.nationalProducerNumber]}
                                        />
                                    </div>
                                    <div display>
                                        <div className={css(styles.displayRow)}>
                                            {this.state.inputs[inputNames.nationalProducerNumber]}
                                        </div>
                                    </div>
                                </InlineEdit>
                                <InlineEdit headerLabel={strings.federalTaxId}
                                    onSave={this._handleOnSave}
                                    onCancel={this._handleOnCancel}
                                    editable={!(me.isSysAdmin || me.isOfficeAdmin) && broker.deleted}
                                >
                                    <div edit>
                                        <TextInput
                                            name={inputNames.federalTaxId}
                                            onChange={this._handleTextChange}
                                            placeholder={strings.federalTaxIdPlaceholder}
                                            value={this.state.inputs[inputNames.federalTaxId]}
                                        />
                                    </div>
                                    <div display>
                                        <div className={css(styles.displayRow)}>
                                            {this.state.inputs[inputNames.federalTaxId]}
                                        </div>
                                    </div>
                                </InlineEdit>
                            </div>
                        </Column>
                        <Column large={4}>
                            <div className={css(styles.centerColumn)}>
                                <Headline aStyles={styles.title}>{strings.contactInformation}</Headline>
                                <div className={css(styles.container)}>
                                    <div className={css(styles.headerLabel)}>
                                        {strings.emailAddress}
                                    </div>
                                    <div className={css(styles.displayContainer)}>
                                        {this.state.inputs[inputNames.emailAddress]}
                                    </div>
                                </div>
                                <div className={css(styles.container)}>
                                    <div className={css(styles.headerLabel)}>
                                        {strings.phone}
                                    </div>
                                    <div className={css(styles.displayContainer)}>
                                        {this.state.inputs[inputNames.phone]}
                                    </div>
                                </div>
                                <div className={css(styles.container)}>
                                    <div className={css(styles.headerLabel)}>
                                        {strings.cellPhone}
                                    </div>
                                    <div className={css(styles.displayContainer)}>
                                        {this.state.inputs[inputNames.cellPhone]}
                                    </div>
                                </div>
                                <div className={css(styles.container)}>
                                    <div className={css(styles.headerLabel)}>
                                        {strings.homePhone}
                                    </div>
                                    <div className={css(styles.displayContainer)}>
                                        {this.state.inputs[inputNames.homePhone]}
                                    </div>
                                </div>
                                <div className={css(styles.container)}>
                                    <div className={css(styles.headerLabel)}>
                                        {strings.fax}
                                    </div>
                                    <div className={css(styles.displayContainer)}>
                                        {this.state.inputs[inputNames.fax]}
                                    </div>
                                </div>
                            </div>
                        </Column>
                        <Column large={4}>
                            <div className={css(styles.rightColumn)}>
                                <Headline aStyles={styles.title}>{strings.accountInformation}</Headline>
                                <div className={css(styles.container)}>
                                    <div className={css(styles.headerLabel)}>
                                        {strings.userName}
                                    </div>
                                    <div className={css(styles.displayContainer)}>
                                        {this.state.inputs[inputNames.userName]}
                                    </div>
                                </div>
                                <div className={css(styles.container)}>
                                    <div className={css(styles.headerLabel)}>
                                        {strings.password}
                                    </div>
                                    <div className={css(styles.displayContainer)}>
                                        {this.state.inputs[inputNames.password]}
                                        {(me.isSysAdmin || me.isOfficeAdmin) && broker.emailAddress && broker.emailAddress.length > 0 && (broker.ratingEnginePermission || broker.brokerHubPermission) &&
                                            <span className={css(styles.resetPassword)} onClick={this._handleResetPassword}>Reset Password</span>
                                        }
                                    </div>
                                </div>
                                <div className={css(styles.container)}>
                                    <div className={css(styles.headerLabel)}>
                                        {strings.lastLogin}
                                    </div>
                                    <div className={css(styles.displayContainer)}>
                                        {this.state.inputs[inputNames.lastLogin]}
                                    </div>
                                </div>
                                <div className={css(styles.container)}>
                                    <div className={css(styles.headerLabel)}>
                                        {strings.otherOffices}
                                    </div>
                                    <div className={css(styles.displayContainer)}>
                                        {this.state.inputs[inputNames.otherOffices]}
                                    </div>
                                </div>
                                <span className={css(styles.item)}>
                                    <label className={css(styles.checkLabel)}>
                                        <Checkbox
                                            aStyles={styles.check}
                                            checked={this.state.inputs[inputNames.ratingEnginePermission]}
                                            name={inputNames.ratingEnginePermission}
                                            onChange={this._handleInputChange}
                                            disabled={!(me.isSysAdmin || me.isOfficeAdmin) || broker.deleted}
                                        />
                                        {strings.ratingEnginePermission}
                                    </label>
                                </span>
                                <span className={css(styles.item)}>
                                    <label className={css(styles.checkLabel)}>
                                        <Checkbox
                                            aStyles={styles.check}
                                            checked={this.state.inputs[inputNames.ratingEngineQuotePermission]}
                                            name={inputNames.ratingEngineQuotePermission}
                                            onChange={this._handleInputChange}
                                            disabled={!(me.isSysAdmin || me.isOfficeAdmin) || broker.deleted}
                                        />
                                        {strings.ratingEngineQuotePermission}
                                    </label>
                                </span>
                                <span className={css(styles.item)}>
                                    <label className={css(styles.checkLabel)}>
                                        <Checkbox
                                            aStyles={styles.check}
                                            checked={this.state.inputs[inputNames.enrollmentPortalPermission]}
                                            name={inputNames.enrollmentPortalPermission}
                                            onChange={this._handleInputChange}
                                            disabled={!(me.isSysAdmin || me.isOfficeAdmin) || broker.deleted}
                                        />
                                        {strings.enrollmentPortalPermission}
                                    </label>
                                </span>
                                {/*<span className={css(styles.item)}>
                                    <label className={css(styles.checkLabel)}>
                                        <Checkbox
                                            aStyles={styles.check}
                                            checked={this.state.inputs[inputNames.brokerHubPermission]}
                                            name={inputNames.brokerHubPermission}
                                            onChange={this._handleInputChange}
                                            disabled={!(me.isSysAdmin || me.isOfficeAdmin) || broker.deleted}
                                        />
                                        {strings.brokerHubPermission}
                                    </label>
                                </span>*/}
                            </div>
                        </Column>
                    </Row>
                </Container>
            </div>
        );
    }

    renderBrokerTeamMemberItem = item => {
        return (
            <div className={css(styles.teamMemberItem)} key={item.id}>
                <div className={css(styles.teamMemberName)}>{item.fullName}</div>
                <div className={css(styles.teamMemberText)}>{item.emailAddress}</div>
                <div className={css(styles.teamMemberText)}>
                    {item.phone}
                    <Close
                        className={css(styles.close)}
                        alt="delete"
                        onClick={() => this._deleteTeamMember(item.id)}
                    />
                </div>
            </div>
        )
    }

    renderBrokerTeam = () => {
        const { broker, brokers, brokerTeamLoaded } = this.state;

        if (!brokerTeamLoaded) {
            return (
                <Spinner
                    aStyles={styles.loadingSpinner}
                    text="Loading Broker Team..."
                />
            );
        }

        return (
            <div className={css(styles.tabContent)}>
                <div className={css(styles.row)}>
                    <div className={css(styles.column)}>
                        <div className={css(styles.row)}>
                            <div className={css(styles.heading)}>Add Team Member</div>
                            <SearchInput
                                search={searchApi.searchBrokers}
                                onItemSelected={this._brokerSelected}
                                placeholder="Broker Name"
                                type={searchInputTypes.text}
                                validationMessage={this.state.errors[inputNames.brokerId]}
                                value={this.state.inputs[inputNames.brokerId]}
                                extraId={broker.agencyId}
                            />
                        </div>
                    </div>
                    <div className={css(styles.column, styles.teamMembersColumn)}>
                        <div className={css(styles.row)}>
                            <div className={css(styles.heading)}>
                                {brokers &&
                                    <div className={css(styles.right)}>
                                        <span className={css(styles.topRightText)}>{brokers.length === 1 ? "1 Member" : brokers.length + " Members"}</span>
                                    </div>
                                }
                                Team Members
                            </div>
                            <div className={css(styles.teamMemberContainer)}>
                                {brokers.map(this.renderBrokerTeamMemberItem)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderRBGTeam = () => {
        return (
            <div className={css(styles.tabContent)}>
                <div className={css(styles.row)}>
                    <div className={css(styles.column)}>
                        <div className={css(styles.row)}>
                            <div className={css(styles.heading)}>Sales Representative</div>
                        </div>
                    </div>
                    <div className={css(styles.column)}>
                        <div className={css(styles.row)}>
                            <div className={css(styles.heading)}>Renewal Specialist</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderGroups = () => {
        const { employers, employersMore, groupsLoaded } = this.state;

        if (!groupsLoaded) {
            return (
                <Spinner
                    aStyles={styles.loadingSpinner}
                    text="Loading Groups..."
                />
            );
        }

        return (
            <div className={css(styles.tabContent)}>
                <div className={css(styles.row)}>
                    {employers && employers.length > 0 ? (
                        <Infinite
                            results={employers}
                            length={employers.length}
                            columnLabels={this.state.columnLabels}
                            columnValues={this.state.columnValues}
                            showChevron={true}
                            showMore={employersMore}
                            onClick={this._handleRowClick}
                            onScroll={this._groupsScroll} />
                    ) : (
                        <div className={css(styles.noResults)}>No Matching Groups</div>
                    )}
                </div>
            </div>
        );
    }

    render() {
        const { broker, employersCount, brokerLoaded } = this.state;

        if (!brokerLoaded) {
            return (
                <div className={css(styles.content)}>
                    <div className={css(styles.contentSection)}>
                        <Spinner
                            aStyles={styles.loadingSpinner}
                            text="Loading Broker..."
                        />
                    </div>
                </div>
            );
        }

        return (
            <div className={css(styles.content)}>
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.titleSection)}>
                        {/*<div className={css(styles.topRightHolder)} onClick={this._goToAddBroker}>
                            <span className={css(styles.topRightText)}>{strings.addBroker}</span>
                            <img alt="" src={blueCircleIcon} className={css(styles.blueCircle)} />
                        </div>*/}
                        <PageTitle>Broker: {broker.fullName} {broker.deleted ? "(DELETED)" : ""}</PageTitle>
                    </div>
                    <Tabs history={this.props.history} backUrl={routeHelpers.brokers()}>
                        <div label="General Info">
                            {this.renderGeneralInfo()}
                        </div>
                        <div label="Dashboard">
                            {this.renderDashboard()}
                        </div>
                        <div label="Broker Team">
                            {this.renderBrokerTeam()}
                        </div>
                        <div label="Account Team">
                            {this.renderRBGTeam()}
                        </div>
                        <div label={"Groups (" + employersCount + ")"}>
                            {this.renderGroups()}
                        </div>
                    </Tabs>
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = state => ({
    me: state.session.me,
});

export default connect(mapDispatchToProps)(BrokerDetailScreen);
