import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import * as color from "../../constants/color";
import * as inputSizes from "../../constants/inputSizes";
import * as util from "../../lib/util";
import * as enrollmentApi from "../../lib/api/enrollment";
import format from "date-fns/format";
import InputGroup from "./InputGroup";
import ActionButton from "../ActionButton";
import Headline from "../text/Headline";
import TextInput from "../inputs/TextInput";
import { ToastContainer } from 'react-toastify';
//import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = StyleSheet.create({
    buttonContainer: {
        marginTop: 36,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    table: {
        width: "100%",
        marginTop: 0,
        marginBottom: 10,
        borderCollapse: "collapse",
        fontSize: 14,
    },
    tableCell: {
        color: color.tableText,
        padding: "12px 6px",
        borderBottom: `1px solid ${color.tableBorder}`,
    },
    tableHeader: {
        color: color.mediumYellow1,
        backgroundColor: color.white,
        fontWeight: 500,
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: 12,
        paddingBottom: 12,
        whiteSpace: "nowrap",
        borderBottom: `1px solid ${color.mediumGray}`,
        borderTop: `1px solid ${color.darkGray2}`,
    },
    backButton: {
        flexGrow: 1,
        marginRight: 16,
    },
    continueButton: {
        flexGrow: 1,
        marginLeft: 16,
    },
    greenBar: {
        borderLeft: `4px solid ${color.mediumRed}`,
        fontWeight: 500,
    },
    blueBar: {
        borderLeft: `4px solid ${color.borderBackgroundGray}`,
    },
    headline: {
        fontSize: 20,
        fontWeight: 500,
        marginLeft: 24,
        marginRight: 24,
        marginBottom: 12,
        paddingBottom: 0,
    },
    employeeCount: {
        float: "right",
        fontSize: 14,
        top: 12,
        position: "relative",
        marginRight: 28,
    },
    leftPadding: {
        paddingLeft: 26,
    },
    inputCell: {
        width: "180px",
    }
});

export class Census extends Component {
    static propTypes = {
        onContinue: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        quote: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        const { census } = this.props.quote;

        this.state = {
            isSaving: false,
            errors: [],
            census: census,
            isLoading: true,
        };
    }

    _continueClicked = () => {
        const { quote } = this.props;
        //hire date required
        /*if (quote.census.some(cen => cen.relationship === "EE" && cen.hireDate === null)) {
            toast.error("Please enter a Hire Date for each employee.", { toastId: 1 });
            return;
        }*/
        //continue
        enrollmentApi.updateEnrollmentCensus(quote)
            .then(() => {
                this.props.onContinue();
            })
    };

    _setEmail = (e, c) => {
        const val = e.target.value;
        const { census } = this.props.quote;
        const match = census.find(cen => cen.id === c.id);
        match.email = val;

        this.setState({
            census: this.state.census.map(
                person => person.id === c.id
                    ? { ...person, email: val, }
                    : person,
            )
        });
    }

    _setHireDate = (e, c) => {
        //valid date
        const val = util.maskDate(e.target.value);
        const { census } = this.props.quote;
        const match = census.find(cen => cen.id === c.id);
        match.hireDate = val;
        match.hireDateString = val;

        this.setState({
            census: this.state.census.map(
                person => person.id === c.id
                    ? { ...person, hireDateString: val, }
                    : person,
            )
        });
    };

    _setSalary = (e, c) => {
        const val = parseInt(e.target.value);
        const { census } = this.props.quote;
        const match = census.find(cen => cen.id === c.id);
        match.salary = val;

        this.setState({
            census: this.state.census.map(
                person => person.id === c.id
                    ? { ...person, salary: val, }
                    : person,
            )
        });
    }

    render() {
        const { census } = this.state;
        return (
            <div className={css(styles.censusContainer)}>
                <ToastContainer position="top-center" autoClose={2500} />
                <div className={css(styles.employeeCount)}>
                    {census.filter(c => c.relationship === "EE").length} employees
                </div>
                <Headline aStyles={styles.headline}>Census</Headline>
                <table className={css(styles.table)}>
                    <thead>
                        <tr>
                            <td className={css(styles.tableHeader, styles.leftPadding)}>No.</td>
                            <td className={css(styles.tableHeader)}>Rel</td>
                            <td className={css(styles.tableHeader)}>Name</td>
                            <td className={css(styles.tableHeader)}>Email</td>
                            <td className={css(styles.tableHeader)}>DOB</td>
                            <td className={css(styles.tableHeader)}>Hire Date</td>
                            <td className={css(styles.tableHeader)}>Gen</td>
                            <td className={css(styles.tableHeader)}>Med</td>
                            <td className={css(styles.tableHeader)}>Den</td>
                            <td className={css(styles.tableHeader)}>Vis</td>
                            <td className={css(styles.tableHeader)}>Lif</td>
                            <td className={css(styles.tableHeader)}>H Zip</td>
                            <td className={css(styles.tableHeader)}>State</td>
                            <td className={css(styles.tableHeader)}>W Zip</td>
                            <td className={css(styles.tableHeader)}>Title</td>
                            <td className={css(styles.tableHeader)}>Salary</td>
                            <td className={css(styles.tableHeader)}>Tob</td>
                            <td className={css(styles.tableHeader)}>COB</td>
                        </tr>
                    </thead>
                    <tbody>
                        {census.map(c => (
                            <tr key={c.id} className={c.relationship === "EE" ? "" : "sub"}>
                                <td className={css(styles.tableCell, styles.leftPadding, (c.relationship === "EE" ? styles.greenBar : styles.blueBar))}>{c.sequenceNumber}</td>
                                <td className={css(styles.tableCell)}>{c.relationship}</td>
                                <td className={css(styles.tableCell)}>{c.fullName}</td>
                                <td className={css(styles.tableCell, styles.inputCell)}>
                                    {c.relationship === "EE" &&
                                        <TextInput
                                            value={c.email ? c.email.toString() : ""}
                                            placeholder="- Email -"
                                            onChange={e => this._setEmail(e, c)}
                                            hideLabel={true}
                                            type="email"
                                            size={inputSizes.small}
                                        />}
                                </td>
                                <td className={css(styles.tableCell)}>{c.birthDateString}</td>
                                <td className={css(styles.tableCell, styles.inputCell)}>
                                    {c.relationship === "EE" &&
                                        <TextInput
                                            value={c.hireDateString && c.hireDateString.length >= 10 ? format(c.hireDateString, "MM/DD/YYYY") : c.hireDateString ? c.hireDateString : ""}
                                            placeholder="- MM/DD/YYYY -"
                                            onChange={e => this._setHireDate(e, c)}
                                            hideLabel={true}
                                            size={inputSizes.small}
                                        />}
                                </td>
                                <td className={css(styles.tableCell)}>{c.gender}</td>
                                <td className={css(styles.tableCell)}>{c.medicalCoverage}</td>
                                <td className={css(styles.tableCell)}>{c.dentalCoverage}</td>
                                <td className={css(styles.tableCell)}>{c.visionCoverage}</td>
                                <td className={css(styles.tableCell)}>{c.lifeCoverage}</td>
                                <td className={css(styles.tableCell)}>{c.homePostalCode}</td>
                                <td className={css(styles.tableCell)}>{c.homeState}</td>
                                <td className={css(styles.tableCell)}>{c.workPostalCode}</td>
                                <td className={css(styles.tableCell)}>{c.occupation}</td>
                                <td className={css(styles.tableCell, styles.inputCell)}>
                                    {c.relationship === "EE" &&
                                        <TextInput
                                            value={c.salary ? c.salary.toString() : ""}
                                            placeholder="- Salary -"
                                            onChange={e => this._setSalary(e, c)}
                                            hideLabel={true}
                                            size={inputSizes.small}
                                        />}
                                </td>
                                <td className={css(styles.tableCell)}>{c.isTobacco ? "Yes" : "No"}</td>
                                <td className={css(styles.tableCell)}>{c.onCobra ? "Yes" : "No"}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <InputGroup>
                    <div className={css(styles.buttonContainer)}>
                        <ActionButton
                            aStyles={styles.backButton}
                            label="Back"
                            invertColor={true}
                            type="button"
                            onClick={this.props.onCancel}
                        />
                        <ActionButton
                            aStyles={styles.continueButton}
                            label="Continue"
                            type="button"
                            isLoading={this.state.isSaving}
                            onClick={this._continueClicked}
                        />
                    </div>
                </InputGroup>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    quote: state.enrollment.quote,
});

export default connect(mapStateToProps)(Census);
