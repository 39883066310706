import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import * as texchangePropTypes from "../../texchangePropTypes";
import format from "date-fns/format";
import TextInput from "./TextInput";
import calendarIcon from "../../images/SVGs/Calendar3.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const styles = StyleSheet.create({
	container: {
		flex: 1,
		position: "relative",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "stretch",
		outline: 0,
	},
	datepickerContainer: {
		border: "none",
		position: "absolute",
		top: 60,
		right: 60,
	},
	hide: {
		display: "none",
	},
	clear: {
		clear: "both",
	},
});

const strings = {
	dateFormat: "MM/DD/YYYY",
}

export default class DatePicker2 extends Component {
	static propTypes = {
		name: PropTypes.string,
		value: PropTypes.instanceOf(Date),
		onChange: PropTypes.func.isRequired,
		placeholder: PropTypes.string,
		size: PropTypes.string,
		hideLabel: PropTypes.bool,
		aStyles: texchangePropTypes.aphroditeStyles,
		disabled: PropTypes.bool,
		validationMessage: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.string),
			PropTypes.string,
		]),
	};

	constructor(props) {
		super(props);

		this.state = {
			open: false,
			selectedDate: null,
		};

		this._inputRef = React.createRef();
	}

	componentDidMount() {
		this._mounted = true;

		//current year or next year?
		const mth = new Date().getMonth();
		if (mth >= 10) {
			this.setState({
				selectedYear: new Date().getFullYear() + 1,
			});
		}

		setTimeout(() => {
			if (!this._mounted) {
				return;
			}
		});

		window.addEventListener("click", this._onClick);
	}

	componentWillUnmount() {
		this._mounted = false;
		window.removeEventListener("click", this._onClick);
	}

	_textChanged = () => {

	};

	_getDateText = () => {
		const { value } = this.props;
		if (value) {
			return format(value, strings.dateFormat);
		} else {
			return "";
		}
	};

	_showCalendar = () => {
		if (!this.props.disabled) {
			this.setState({
				open: true,
			});
		}
	}

	_dayClicked = e => {
		this.setState({ selectedDate: e });

		this._closeCalendar();
		this.props.onChange(new Date(e), this.props.name);
	};

	_closeCalendar = () => {
		this.setState({
			open: false
		});
	};

	_onClick = e => {
		if (e && this._inputRef.current.contains(e.target)) {
			return;
		}

		this._closeCalendar();
	};

	render() {
		const { open, selectedDate } = this.state;
		const { aStyles, placeholder, validationMessage, hideLabel, disabled } = this.props;

		return (
			<div
				className={css(styles.container, aStyles)}
				ref={this._inputRef}
				style={{
					zIndex: open ? 10000 : null
				}}
			>
				<TextInput
					placeholder={placeholder}
					value={this._getDateText()}
					onChange={this._textChanged}
					onClick={this._showCalendar}
					aStyles={styles.input}
					validationMessage={validationMessage}
					icon={calendarIcon}
					size={this.props.size}
					hideLabel={hideLabel}
					disabled={disabled}
				/>
				{open &&
					<div className={css(styles.datepickerContainer)}>
						<DatePicker inline selected={selectedDate} onChange={this._dayClicked} />
					</div>
				}
			</div>
		);
	}
}