import * as routeHelpers from "../../lib/routeHelpers";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export function PublicRoute(props) {
    const { component: Component, isAuthenticated, ...rest } = props;
    
    //Allow get-sbc route
    if (props.location && props.location.pathname && props.location.pathname.startsWith("/account/get-sbc")) {
        return (
            <Route {...rest}
                // eslint-disable-next-line react/jsx-no-bind
                render={innerProps => {
                    return <Component {...innerProps} />;
                }}
            />
        );
    } else {
        return (
            <Route
                {...rest}
                // eslint-disable-next-line react/jsx-no-bind
                render={innerProps => {
                    const { from } = innerProps.location.state || {
                        from: { pathname: routeHelpers.home() },
                    };
    
                    return isAuthenticated ? <Redirect to={from} /> : <Component {...innerProps} />;
                }}
            />
        );
    }
}

PublicRoute.propTypes = {
    component: PropTypes.any.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    isAuthenticated: !!state.session.accessToken && !!state.session.me,
});

export default connect(mapStateToProps)(PublicRoute);
