import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import * as color from "../../constants/color";
import * as inputSizes from "../../constants/inputSizes";
import * as texchangePropTypes from "../../texchangePropTypes";
import format from "date-fns/format";
import TextInput from "./TextInput";
import calendarIcon from "../../images/SVGs/Calendar3.svg";

const styles = StyleSheet.create({
	container: {
		flex: 1,
		position: "relative",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "stretch",
		outline: 0,
	},
	monthList: {
		monthListStyleType: "none",
		padding: 0,
		marginRight: 12,
		marginLeft: 12,
		marginTop: 12,
		marginBottom: 12,
	},
	monthItem: {
		cursor: "pointer",
		fontSize: 14,
		width: 56,
		display: "block",
		float: "left",
		paddingTop: 18,
		paddingBottom: 18,
		textAlign: "center",
		borderRadius: 5,
		margin: 2,
		":nth-child(4n+1)": {
			clear: "both",
		},
		":hover": {
			backgroundColor: color.inputFieldGray,
		},
	},
	yearSelector: {
		backgroundColor: color.mediumRed,
		textAlign: "center",
		paddingTop: 16,
		paddingBottom: 16,
		color: color.white,
		borderRadius: "8px 8px 0px 0px",
		borderColor: color.mediumRed,
	},
	year: {
		fontSize: 20,
		fontWeight: "bold",
		display: "inline-block",
		marginLeft: 12,
		marginRight: 12,
	},
	clearButton: {
		fontSize: 16,
		cursor: "pointer",
		position: "absolute",
		right: 18,
		top: 18,
	},
	datepickerContainer: {
		border: `1px solid ${color.lightGray2}`,
		backgroundColor: color.white,
		borderRadius: 8,
		position: "absolute",
		top: 48,
		right: 28,
		width: 268,
	},
	datepickerContainerSmall: {
		top: 46,
		right: 8,
	},
	active: {
		backgroundColor: color.mediumRed,
		color: color.white,
		":hover": {
			backgroundColor: color.mediumRed,
		},
	},
	arrow: {
		border: `solid ${color.white}`,
		borderWidth: "0 3px 3px 0",
		display: "inline-block",
		padding: 6,
		cursor: "pointer",
	},
	smallArrow: {
		border: `solid ${color.white}`,
		borderWidth: "0 2px 2px 0",
		display: "inline-block",
		padding: 3,
		cursor: "pointer",
		position: "relative",
		top: -1,
	},
	arrowLeft: {
		transform: "rotate(135deg)",
		"-webkit-transform": "rotate(135deg)",
	},
	arrowRight: {
		transform: "rotate(-45deg)",
		"-webkit-transform": "rotate(-45deg)",
	},
	daySelector: {
		width: 266,
		textAlign: "center",
	},
	hide: {
		display: "none",
	},
	backContainer: {
		fontSize: 16,
		cursor: "pointer",
		position: "absolute",
		left: 18,
		top: 18,
	},
	back: {
		display: "inline-block",
		marginLeft: 4,
	},
	dayContainer: {
		textAlign: "center",
	},
	dayItem: {
		height: 36,
		width: 136,
		fontSize: 18,
		color: color.darkestGray,
		cursor: "pointer",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: 24,
		marginBottom: 24,
		lineHeight: "36px",
		borderRadius: 8,
		border: `solid 1px ${color.mediumYellow1}`,
		":hover": {
			color: color.white,
			backgroundColor: color.mediumRed,
		}
	},
	clear: {
		clear: "both",
	},
});

const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
const fullMonths = ["January", "February", "March", "April", "May", "Jun", "July", "August", "September", "October", "November", "December"];
const strings = {
	dateFormat: "MM/DD/YYYY",
	clear: "Clear",
}

export default class DatePicker extends Component {
	static propTypes = {
		name: PropTypes.string,
		value: PropTypes.instanceOf(Date),
		onChange: PropTypes.func.isRequired,
		placeholder: PropTypes.string,
		size: PropTypes.string,
		hideLabel: PropTypes.bool,
		aStyles: texchangePropTypes.aphroditeStyles,
		disabled: PropTypes.bool,
		isEndDate: PropTypes.bool,
		validationMessage: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.string),
			PropTypes.string,
		]),
	};

	constructor(props) {
		super(props);

		this.state = {
			open: false,
			showDays: false,
			selectedYear: new Date().getFullYear(),
			selectedMonth: null,
			selectedDay: null,
		};

		this._inputRef = React.createRef();
	}

	componentDidMount() {
		this._mounted = true;

		//current year or next year?
		const mth = new Date().getMonth();
		const end = this.props.isEndDate;
		let addYears = 0;
		if (mth >= 10) {
			addYears++;
		}
		if (end) {
			addYears++;
		}
		if (addYears > 0) {
			this.setState({
				selectedYear: new Date().getFullYear() + addYears,
			});
		}

		setTimeout(() => {
			if (!this._mounted) {
				return;
			}
		});

		window.addEventListener("click", this._onClick);
	}

	componentWillUnmount() {
		this._mounted = false;
		window.removeEventListener("click", this._onClick);
	}

	_getDateText = () => {
		const { value } = this.props;
		if (value) {
			return format(value, strings.dateFormat);
		} else {
			return "";
		}
	};

	_textChanged = () => {

	};

	_showCalendar = () => {
		if (!this.props.disabled) {
			this.setState({
				open: true,
			});
		}
	}

	_monthClicked = e => {
		const { target } = e;
		let index = parseInt(target.getAttribute("data-id"));

		if (index === this.props.value) {
			index = null;
		}

		this.setState({
			selectedMonth: index,
			showDays: true,
		});
	};

	_dayClicked = e => {
		const { target } = e;
		const selectedDay = parseInt(target.getAttribute("data-id"));
		const { selectedYear, selectedMonth } = this.state;

		this.setState({ selectedDay });

		this._closeCalendar();
		this.props.onChange(new Date(selectedYear, selectedMonth, selectedDay), this.props.name);
	};

	_closeCalendar = () => {
		this.setState({
			open: false,
			showDays: false,
		});
	};

	_clearClicked = () => {
		this.setState({
			selectedDay: null,
			selectedMonth: null,
		});
		this.props.onChange(null, this.props.name);
		this._closeCalendar();
	}

	_onClick = e => {
		if (e && this._inputRef.current.contains(e.target)) {
			return;
		}

		this._closeCalendar();
	};

	_previousYearClicked = () => {
		const { selectedYear } = this.state;
		this.setState({ selectedYear: selectedYear - 1 });
	};

	_nextYearClicked = () => {
		const { selectedYear } = this.state;
		this.setState({ selectedYear: selectedYear + 1 });
	};

	_backClicked = () => {
		this.setState({
			showDays: false,
		});
	};

	_getDaysInMonth = () => {
		const { selectedMonth } = this.state;
		return new Date(new Date().getFullYear(), selectedMonth + 1, 0).getDate();
	}

	render() {
		const { open, selectedYear, showDays, selectedMonth } = this.state;
		const { aStyles, placeholder, validationMessage, hideLabel, disabled, isEndDate } = this.props;
		const selectedMonthText = selectedMonth !== null ? fullMonths[selectedMonth] : "";
		const isSmall = this.props.size === inputSizes.small;
		const daysInMonth = this._getDaysInMonth();

		return (
			<div
				className={css(styles.container, aStyles)}
				ref={this._inputRef}
				style={{
					zIndex: open ? 10000 : null
				}}
			>
				<TextInput
					placeholder={placeholder}
					value={this._getDateText()}
					onChange={this._textChanged}
					onClick={this._showCalendar}
					aStyles={styles.input}
					validationMessage={validationMessage}
					icon={calendarIcon}
					size={this.props.size}
					hideLabel={hideLabel}
					disabled={disabled}
				/>
				{open &&
					<div
						className={css(
							styles.datepickerContainer,
							isSmall ? styles.datepickerContainerSmall : null,
						)}>
						<div className={css(styles.yearSelector)}>
							<i
								className={css(
									styles.arrow,
									styles.arrowLeft,
									showDays ? styles.hide : null
								)}
								onClick={this._previousYearClicked}
							/>
							<div
								className={css(styles.backContainer, showDays ? styles.show : styles.hide)}
								onClick={this._backClicked}
							>
								<i
									className={css(styles.smallArrow, styles.arrowLeft)}
								/>
								<div className={css(styles.back)}>
									Back
								</div>
							</div>
							<div className={css(styles.year)}>
								{selectedYear}
							</div>
							<i
								className={css(
									styles.arrow,
									styles.arrowRight,
									showDays ? styles.hide : null
								)}
								onClick={this._nextYearClicked}
							/>
							<div className={css(styles.clearButton)} onClick={this._clearClicked}>
								{strings.clear}
							</div>
						</div>
						<div
							className={css(
								styles.daySelector,
								showDays ? null : styles.hide
							)}>
							{selectedMonth !== null && !isEndDate &&
								<div className={css(styles.dayContainer)}>
									<div className={css(styles.dayItem)} onClick={this._dayClicked} data-id="1">
										{selectedMonthText} 1st
									</div>
									<div className={css(styles.dayItem)} onClick={this._dayClicked} data-id="15">
										{selectedMonthText} 15th
									</div>
								</div>
							}
							{selectedMonth !== null && isEndDate &&
								<div className={css(styles.dayContainer)}>
									<div className={css(styles.dayItem)} onClick={this._dayClicked} data-id="14">
										{selectedMonthText} 14th
									</div>
									<div className={css(styles.dayItem)} onClick={this._dayClicked} data-id={this._getDaysInMonth()}>
										{selectedMonthText} {daysInMonth === 31 ? daysInMonth.toString() + "st" : daysInMonth.toString() + "th"}
									</div>
								</div>
							}
						</div>
						<ul className={css(
							styles.monthList,
							showDays ? styles.hide : null
						)}>
							{months.map((month, index) => {
								return (
									<li
										key={index}
										data-id={index}
										className={css(
											styles.monthItem,
											this.props.value === index + 1 ? styles.active : null
										)}
										onClick={this._monthClicked}
									>
										{months[index]}
									</li>
								)
							})}
							<div className={css(styles.clear)} />
						</ul>
					</div>
				}
			</div>
		);
	}
}