const segmentSizes = [{
        value: "1-50",
    }, {
        value: "2-99 (CA 1-100)",
    }, {
        value: "51-99",
    }, {
        value: "100+ (CA 101+)"
    },   
];

export default segmentSizes;