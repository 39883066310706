import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import * as util from "../../lib/util";
import * as color from "../../constants/color";
import coverageTypes from "../../constants/coverageTypes";
import ActionButton from "../ActionButton";

const styles = StyleSheet.create({
	container: {
		color: color.darkestGray,
	},
	coverageContainer: {
		display: "flex",
		justifyContent: "center",
	},
	header: {
		fontWeight: 300,
		fontSize: 20,
		marginTop: 40,		
		marginBottom: 60,
		marginLeft: 30,		
	},
	coverageItem: {
		width: 126,
		height: 100,
		borderRadius: 8,
		backgroundColor: color.lightGray,
		border: `1px solid ${color.darkGray2}`,
		textAlign: "center",
		lineHeight: "100px",
		marginLeft: 20,
		marginRight: 20,
		cursor: "pointer",
		fontSize: 20,
	},
	selected: {
		backgroundColor: color.mediumRed,
		color: color.white,
	},
	nextButton: {
		width: 160,		
	},
	buttonContainer: {		
        height: 80,
        margin: "60px auto 0px auto",
        textAlign: "center",
    },
});

const strings = {
	headerText: "Select lines of coverage:",
	nextButtonText: "Next"
}

export default class LinesOfCoverage extends Component {
	static propTypes = {
        selectedCoverage: PropTypes.arrayOf(PropTypes.string).isRequired,
        coverageSelected: PropTypes.func.isRequired,
    };

	constructor(props) {
		super(props);

		this.state = {
			selectedCoverage: this.props.selectedCoverage,
	    };
	}
    
    _selectedCoverageItemClicked = e => {
        const { selectedCoverage } = this.state;
        const coverageType = e.target.getAttribute("data-value");
        const index = selectedCoverage.indexOf(coverageType);
        if (index > -1) {
            selectedCoverage.splice(index, 1)
        } else {
            selectedCoverage.push(coverageType);
        }

        this.setState({ selectedCoverage });
    };

    _nextClicked = () => {
    	this.props.coverageSelected(this.state.selectedCoverage);    	
    }    

	render() {
		const { selectedCoverage } = this.state;
		
		return (
			<div className={css(styles.container)}>
				<div className={css(styles.header)}>
					{strings.headerText}
				</div>
				<div className={css(styles.coverageContainer)}>			
					{Object.keys(coverageTypes).map(key => (
						<div 
							className={css(
								styles.coverageItem,
								selectedCoverage.includes(coverageTypes[key]) ? styles.selected : null
							)} 
							data-value={coverageTypes[key]}
							key={key}
							onClick={this._selectedCoverageItemClicked}
						>
							{util.capitalize(coverageTypes[key])}
						</div>	
					))}					
				</div>
				<div className={css(styles.buttonContainer)}>
					<ActionButton                                    
	                    aStyles={styles.nextButton}                      
	                    label={strings.nextButtonText}
	                    type="button"                                        
	                    onClick={this._nextClicked}
	                />
                </div>
			</div>
		);
	}
}