import React, { Component } from "react";
import PropTypes from "prop-types";
import * as color from "../../constants/color";
import * as routeHelpers from "../../lib/routeHelpers";
import * as texchangePropTypes from "../../texchangePropTypes";
import PageTitle from "../text/PageTitle";
import logger from "../../lib/logger";
import backCircle from "../../images/SVGs/BackCircle.svg";
import ActionButton from "../ActionButton";
import ReQuoteButton from "../ReQuoteButton";
import { StyleSheet, css } from "aphrodite";
import QuoteSteps from "../QuoteSteps";
import FindCreateGroup from "../quote/FindCreateGroup";
import Census from "../quote/Census";
import Modal from "../Modal";
import ProposalBuilder from "../proposal-builder";
import describeNetworkError from "../../lib/describeNetworkError";
import { connect } from "react-redux";
import * as proposalActions from "../../actions/proposalActions";
import * as quoteApi from "../../lib/api/quotes";
import * as censusApi from "../../lib/api/census";
import * as employerApi from "../../lib/api/employer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = StyleSheet.create({
    content: {
        paddingRight: 10,
        paddingBottom: 80,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
    },
    quoteForm: {
        minHeight: 500,
    },
    contentSection: {
        width: "100%",
        padding: 0,
        backgroundColor: color.white,
        borderTop: `8px solid ${color.mediumRed}`,
        marginBottom: 20,
        paddingBottom: 10,
    },
    title: {
        fontSize: 20,
        fontWeight: 500,
        paddingBottom: 0,
        marginBottom: 4,
    },
    titleSection: {
        heigth: 64,
    },
    headerSection: {
        paddingTop: 20,
    },
    backButtonContainer: {
        position: "absolute",
        marginTop: -10,
    },
    backButtonImage: {
        display: "block",
    },
    backButton: {
        position: "relative",
        top: 32,
        cursor: "pointer",
        border: "none",
        backgroundColor: "transparent",
        display: "block",
        left: 36,
        marginRight: 16,
        outline: "none",
    },
    check: {
        position: "absolute",
        top: -2,
        left: 0,
        marginRight: 10,
    },
    groupNameText: {
        color: color.darkestGray,
        fontSize: 20,
        fontWeight: 300,
    },
    enrollingText: {
        color: color.darkestGray,
        fontSize: 20,
        fontWeight: 800,
    }
    ,
    titleLink: {
        color: color.mediumYellow1,
    },
    buttons: {},
    reQuote: {
        float: "right",
        cursor: "pointer",
        marginTop: 18,
        marginRight: 24,
    },
    modalContent: {
        minWidth: 550,
        overflowY: "auto",
    },
    modalInstructions: {
        color: color.darkestGray,
        fontSize: 16,
        marginBottom: 8,
        textAlign: "center",
    },
    modalTableContainer: {
        marginBottom: 8,
        maxHeight: 600,
    },
    modalButtonContainer: {
        marginTop: 24,
        display: "flex",
        justifyContent: "space-between",
    },
    modalCloseContainer: {
        position: "absolute",
        top: 3,
        right: 3,
    },
    modalButton: {
        width: 150,
    },
    table: {
        width: "100%",
        marginTop: 0,
        marginBottom: 10,
        borderCollapse: "collapse",
        fontSize: 14,
    },
    tableFirstColumn: {
        paddingLeft: 26,
    },
    tableHeader: {
        color: color.mediumYellow1,
        fontWeight: 500,
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: 12,
        paddingBottom: 12,
        whiteSpace: "nowrap",
        borderBottom: `1px solid ${color.mediumGray}`,
        borderTop: `1px solid ${color.darkGray2}`,
    },
    tableRow: {
        color: color.tableText,
        padding: "12px 6px",
        borderBottom: `1px solid ${color.tableBorder}`,
    },
    noWrap: {
        whiteSpace: "nowrap",
    },
    greenBar: {
        borderLeft: `4px solid ${color.mediumRed}`,
        fontWeight: 500,
    },
    blueBar: {
        borderLeft: `4px solid ${color.borderBackgroundGray}`,
    },
});

const strings = {
    quote: "Quote",
    newQuote: "New Quote",
    reQuote: "Re Quote",
    reQuoteComment: "You are about to re-quote this Group because you need to change the Census, SIC code or Zip Code for the group.  All generated proposals will no longer be valid.",
    reQuoteQuestion: "Would you like to proceed?",
    cancelButtonText: "Cancel",
    yesButtonText: "Yes",
    closeButtonText: "Close",
};

export class QuoteEditScreen extends Component {
    static propTypes = {
        location: texchangePropTypes.location.isRequired,
        history: texchangePropTypes.history.isRequired,
        match: texchangePropTypes.match,
        me: texchangePropTypes.me.isRequired,
        setQuote: PropTypes.func.isRequired,
        setSpreadsheets: PropTypes.func.isRequired,
        setSelectedPlans: PropTypes.func.isRequired,
    };

    state = {
        step: 0,
        subStep: 0,
        quote: {},
        employerId: 0,
        loading: true,
        isSaving: false,
        isSubmittingCensus: false,
        errors: {},
        showReQuoteModal: false,
        showCensusModal: false,
        isReQuote: false,
        counties: [],
    };

    componentDidMount() {
        //Check if loading for re-quote
        if (this.props.location.search === "?r") {
            this.setState({
                subStep: 0,
                isReQuote: true,
            });
        }
        //Check if coming from Group screen
        if (this.props.location.search.startsWith("?g")) {
            const params = new URLSearchParams(this.props.location.search);
            let gpId = params.get("g");
            if (!Number.isInteger(gpId)) {
                this.setState({
                    subStep: 0,
                    employerId: gpId,
                });
            }
        }
        if (this.props.match.params.id) {
            quoteApi.getById(this.props.match.params.id)
                .then(quote => {
                    if (quote) {
                        this.props.setQuote(quote);
                        this._resetSelectedPlansAndSpreadsheets();

                        let step = 0;
                        //move to prop builder if quoted, unless re-quoting
                        if (quote.censusSize > 0 && !this.state.isReQuote) {
                            step = 2;
                        }

                        this.setState({
                            quote: quote,
                            step,
                            loading: false,
                        });
                    } else {
                        setTimeout(() => {
                            this.props.history.push(routeHelpers.quotes());
                        }, 1000);
                    }
                });
        } else {
            this.setState({ loading: false });
        }
    }

    _onBackButtonClick = () => {
        const { step } = this.state;
        if (step === 0) {
            this.props.history.goBack();
        } else {
            this.setState({ step: step - 1 });
        }
    }

    _saveQuote = saveQuote => {
        const { quote, isReQuote } = this.state;
        //help with insert/update
        if (quote && quote.id) {
            saveQuote.id = quote.id;
        }
        //ReQuote
        if (isReQuote) {
            saveQuote.isReQuote = true;
            saveQuote.parentQuoteId = quote.parentQuoteId;
        }

        //Check the zip code...  if we haven't already chosen a county.
        if (saveQuote.postalCode && !saveQuote.countyId) {
            //Need to check the zip code entered...  make sure there aren't multiple counties
            employerApi
                .checkZip(saveQuote.postalCode, "-1")
                .then(response => {
                    if (response && response.success) {
                        this._saveQuotePt2(saveQuote);
                    } else if (response && !response.success && response.message && response.message.startsWith("multiple")) {
                        const countiesJSON = JSON.parse(response.message.split("|")[1]);
                        if (quote.county !== null && quote.county !== undefined) {
                            countiesJSON.forEach(c => {
                                if (c.name.startsWith(quote.county)) {
                                    saveQuote.countyId = c.id.toString();
                                }
                            });
                            toast.info("We auto-selected the same county on this multi-county zip. You can adjust the selected county (if needed) before proceeding.", { toastId: 1 });
                        } else {
                            toast.warning("The multi-county zip code that you entered requires you to choose a county before proceeding.", { toastId: 1 });
                        }
                        //set
                        this.setState({
                            counties: countiesJSON,
                        });
                    } else if (response && !response.success && response.message) {
                        toast.warning(response.message, { toastId: 1 });
                    }
                });
        } else if (saveQuote.postalCode && saveQuote.countyId) {
            this._saveQuotePt2(saveQuote);
        }
    }

    _saveQuotePt2 = saveQuote => {
        //saving
        this.setState({ isSaving: true });
        quoteApi
            .saveQuote(saveQuote)
            .then(response => {
                this.props.setQuote(response);
                this.setState({
                    quote: response,
                    step: 1,
                });
                this.props.history.push(routeHelpers.editQuote(response.id));
            })
            .catch(err => {
                logger.warn(err);
                let errors = {};
                if (err && err.response) {
                    const { response } = err;
                    if (
                        response &&
                        response.data &&
                        response.data.errors &&
                        Object.keys(response.data.errors).length > 0
                    ) {
                        // Server provided validation messages
                        errors = response.data.errors;
                    } else {
                        // 500 or something else unexpected
                        const description = describeNetworkError(err);
                        toast.error(description.join("\n"), { toastId: 4 });
                    }
                }
                this.setState({ errors });
            })
            .finally(() => this.setState({ isSaving: false }));
    };

    _resetSelectedPlansAndSpreadsheets = () => {
        this.props.setSpreadsheets({
            singlePlan: [],
            multiPlan: [],
            stackedMultiPlan: [],
            automatic: [],
        });
        //Clear selected for new or requote.  Requotes will re-slect matching from parent's last proposal
        this.props.setSelectedPlans({ medical: [], dental: [], vision: [], life: [] }, [], null);
    };

    _uploadCensus = (censusUploadFile, manualCensus) => {
        this.setState({ isSubmittingCensus: true });
        const { step, quote } = this.state;
        const quoteId = this.state.quote.id;
        censusApi
            .uploadCensus(quoteId, censusUploadFile, quote.census && quote.census.length > 0 ? quote.census : manualCensus)
            .then(response => {
                if (response.success) {
                    toast.success(response.message, { toastId: 1 });
                    quoteApi.submitQuote(quoteId)
                        .then(quoteResponse => {
                            this.props.setQuote(quoteResponse);
                            this._resetSelectedPlansAndSpreadsheets();
                            this.setState({
                                quote: quoteResponse,
                                step: step + 1,
                                censusUpload: null,
                                isSubmittingCensus: false,
                                isReQuote: false,
                            });
                        })
                        .catch(err => {
                            if (err && err.response && err.response.data) {
                                toast.error(err.response.data, { toastId: 2 });
                            } else if (err && err.response) {
                                toast.error(err.response, { toastId: 2 });
                            }
                            this.setState({ isSubmittingCensus: false })
                        });
                } else {
                    //There was an error during upload...
                    if (response && response.census) {
                        quote.census = response.census;
                    }
                    if (response && response.message) {
                        quote.censusUploadErrors = response.message;
                        toast.error("There were some problems with your census.  Please fix and resubmit.", { toastId: 3 });
                    } else if (response && response.data) {
                        toast.error(response.data, { toastId: 3 });
                    }
                    this.setState({ isSubmittingCensus: false })
                }
            })
    };

    _copyCensus = () => {
        this.setState({ isSubmittingCensus: true });
        const { quote } = this.state;
        const quoteId = this.state.quote.id;
        const fromQuoteId = this.state.quote.lastQuoteIDWithCensus;
        if (fromQuoteId) {
            censusApi
                .copyCensus(fromQuoteId, quoteId)
                .then(response => {
                    if (response.success) {
                        //We don't save at the "copy" point...  we ask the user to confirm the census.
                        if (response && response.census) {
                            quote.census = response.census;
                        }
                        this.setState({ isSubmittingCensus: false })
                        toast.success(response.message, { toastId: 1 });
                        /*quoteApi.submitQuote(quoteId)
                            .then(quoteResponse => {
                                this.props.setQuote(quoteResponse);
                                this._resetSelectedPlansAndSpreadsheets();
                                this.setState({
                                    quote: quoteResponse,
                                    step: step + 1,
                                    censusUpload: null,
                                    isSubmittingCensus: false,
                                    isReQuote: false,
                                });
                            })
                            .catch(err => {
                                if (err && err.response && err.response.data) {
                                    toast.error(err.response.data, { toastId: 2 });
                                } else if (err && err.response) {
                                    toast.error(err.response, { toastId: 2 });
                                }
                                this.setState({ isSubmittingCensus: false })
                            });
                        */
                    } else {
                        if (response && response.message) {
                            toast.error(response.message, { toastId: 3 });
                        } else if (response && response.data) {
                            toast.error(response.data, { toastId: 3 });
                        }
                        this.setState({ isSubmittingCensus: false })
                    }
                });
        }
    };

    _copyCensusU = () => {
        this.setState({ isSubmittingCensus: true });
        const { quote } = this.state;
        const quoteId = this.state.quote.id;
        const fromEnrollmentId = this.state.quote.lastEnrollmentIDWithCensus;
        if (fromEnrollmentId) {
            censusApi
                .copyCensusU(fromEnrollmentId, quoteId)
                .then(response => {
                    if (response.success) {
                        //We don't save at the "copy" point...  we ask the user to confirm the census.
                        if (response && response.census) {
                            quote.census = response.census;
                        }
                        this.setState({ isSubmittingCensus: false })
                        toast.success(response.message, { toastId: 1 });
                    } else {
                        if (response && response.message) {
                            toast.error(response.message, { toastId: 3 });
                        } else if (response && response.data) {
                            toast.error(response.data, { toastId: 3 });
                        }
                        this.setState({ isSubmittingCensus: false })
                    }
                });
        }
    };

    _subStepChanged = subStep => {
        this.setState({
            subStep,
        }, () => {
            window.scrollTo(0, 0);
        });
    };

    _showReQuote = () => {
        this.setState({ showReQuoteModal: true });
    };

    _showCensus = () => {
        this.setState({ showCensusModal: true });
    };

    _gotoDetails = () => {
        const quoteId = this.state.quote.id;
        if (quoteId && quoteId > 0) {
            this.props.history.push(routeHelpers.quoteDetail(quoteId));
        }
    };

    _reset = () => {
        this.setState({
            step: 0,
            subStep: 0,
            isReQuote: true,
            showReQuoteModal: false,
            counties: [],
        });
    };

    render() {
        if (this.state.loading) {
            return null;
        }

        const { me } = this.props;
        const { quote, employerId, step, subStep, isReQuote, counties } = this.state;

        const censusHeader = `Census: ${quote.censusSize} Employees, ${quote.censusEnrolling} Enrolling`;

        return (
            <div className={css(styles.content)}>
                <ToastContainer position="top-center" autoClose={2500} />
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.titleSection)}>
                        {me.ratingEngineQuotePermission && step === 2 &&
                            <ReQuoteButton
                                aStyles={styles.reQuote}
                                onClick={this._showReQuote}
                                label={strings.reQuote}
                            />
                        }
                        <PageTitle>
                            {step === 2 ? strings.quote : isReQuote ? strings.reQuote : strings.newQuote}
                            {": "}
                            {step === 2 ? (
                                <span className={css(styles.titleLink)} onClick={this._gotoDetails}>{quote.quoteNumber}</span>
                            ) : quote.id ? quote.quoteNumber : null}
                            {step > 0 && quote.id &&
                                <span className={css(styles.groupNameText)}>
                                    &nbsp;|&nbsp;{quote.groupName}
                                    &nbsp;|&nbsp;{"Eff: "}{quote.effectiveDateString}
                                    &nbsp;|&nbsp;{quote.county && quote.county.trim() + " County, "}{quote.state}{" "}{quote.zip}
                                </span>
                            }
                            {step > 1 && quote.censusEnrolling > 0 &&
                                <span className={css(styles.enrollingText)}>
                                    &nbsp;|&nbsp;<span className={css(styles.titleLink)} onClick={this._showCensus}>{quote.censusEnrolling}{" Enrolling"}</span>
                                </span>
                            }
                        </PageTitle>
                    </div>
                    <div className={css(styles.headerSection)}>
                        {step < 2 &&
                            <div className={css(styles.backButtonContainer)}>
                                <button className={css(styles.backButton)} type="button" onClick={this._onBackButtonClick}>
                                    <img src={backCircle} className={css(styles.backButtonImage)} alt="back" />
                                </button>
                            </div>
                        }
                        <QuoteSteps step={step} subStep={subStep} />
                    </div>
                    <div className={css(styles.quoteForm)}>
                        {step === 0 &&
                            <FindCreateGroup
                                quote={quote}
                                employerId={employerId}
                                counties={counties}
                                saveQuote={this._saveQuote}
                                isSaving={this.state.isSaving}
                            />
                        }
                        {step === 1 &&
                            <Census
                                quote={quote}
                                uploadCensus={this._uploadCensus}
                                copyCensus={this._copyCensus}
                                copyCensusU={this._copyCensusU}
                                isSaving={this.state.isSubmittingCensus}
                            />
                        }
                        {step === 2 &&
                            <ProposalBuilder
                                subStep={subStep}
                                subStepChanged={this._subStepChanged}
                            />
                        }
                    </div>
                </div>
                <Modal show={this.state.showReQuoteModal} header={strings.reQuote}>
                    <div className={css(styles.modalContent)}>
                        <div className={css(styles.modalInstructions)}>
                            {strings.reQuoteComment}
                            <br />
                            <br />
                            {strings.reQuoteQuestion}
                        </div>
                    </div>
                    <div className={css(styles.modalButtonContainer)}>
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.cancelButtonText}
                            invertColor={true}
                            type="button"
                            onClick={() => this.setState({ showReQuoteModal: false })}
                        />
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.yesButtonText}
                            onClick={this._reset}
                            type="button"
                        />
                    </div>
                </Modal>
                <Modal show={this.state.showCensusModal} header={censusHeader}>
                    <div className={css(styles.modalContent)}>
                        <div className={css(styles.modalTableContainer)}>
                            <table className={css(styles.table)}>
                                <thead>
                                    <tr>
                                        <td className={css(styles.tableHeader, styles.tableFirstColumn)}>No.</td>
                                        <td className={css(styles.tableHeader)}>Rel</td>
                                        <td className={css(styles.tableHeader)}>Name</td>
                                        <td className={css(styles.tableHeader)}>DOB</td>
                                        <td className={css(styles.tableHeader)}>Age</td>
                                        <td className={css(styles.tableHeader)}>Gen</td>
                                        <td className={css(styles.tableHeader)}>Med</td>
                                        <td className={css(styles.tableHeader)}>Den</td>
                                        <td className={css(styles.tableHeader)}>Vis</td>
                                        <td className={css(styles.tableHeader)}>Lif</td>
                                        <td className={css(styles.tableHeader)}>H Zip</td>
                                        <td className={css(styles.tableHeader)}>State</td>
                                        <td className={css(styles.tableHeader)}>W Zip</td>
                                        <td className={css(styles.tableHeader)}>Title</td>
                                        <td className={css(styles.tableHeader)}>Tob</td>
                                        <td className={css(styles.tableHeader)}>COB</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {quote.census ? (quote.census.map(c => {
                                        return (
                                            <tr key={c.id} className={c.relationship === "EE" ? "" : "sub"}>
                                                <td className={css(styles.tableRow, styles.tableFirstColumn, (c.relationship === "EE" ? styles.greenBar : styles.blueBar))}>{c.sequenceNumber}</td>
                                                <td className={css(styles.tableRow)}>{c.relationship}</td>
                                                <td className={css(styles.tableRow, styles.noWrap)}>{c.fullName}</td>
                                                <td className={css(styles.tableRow)}>{c.birthDateString}</td>
                                                <td className={css(styles.tableRow)}>{c.age}</td>
                                                <td className={css(styles.tableRow)}>{c.gender}</td>
                                                <td className={css(styles.tableRow)}>{c.medicalCoverage}</td>
                                                <td className={css(styles.tableRow)}>{c.dentalCoverage}</td>
                                                <td className={css(styles.tableRow)}>{c.visionCoverage}</td>
                                                <td className={css(styles.tableRow)}>{c.lifeCoverage}</td>
                                                <td className={css(styles.tableRow)}>{c.homePostalCode}</td>
                                                <td className={css(styles.tableRow)}>{c.homeState}</td>
                                                <td className={css(styles.tableRow)}>{c.workPostalCode}</td>
                                                <td className={css(styles.tableRow)}>{c.occupation}</td>
                                                <td className={css(styles.tableRow)}>{c.isTobacco ? "Yes" : "No"}</td>
                                                <td className={css(styles.tableRow)}>{c.onCobra ? "Yes" : "No"}</td>
                                            </tr>
                                        )
                                    })) : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={css(styles.modalCloseContainer)}>
                        {me.ratingEngineQuotePermission &&
                            <ActionButton
                                aStyles={styles.modalButton}
                                label={strings.closeButtonText}
                                invertColor={true}
                                type="button"
                                onClick={() => this.setState({ showCensusModal: false })}
                            />
                        }
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    me: state.session.me,
});

export default connect(
    mapStateToProps, {
    setQuote: proposalActions.setQuote,
    setSelectedPlans: proposalActions.setSelectedPlans,
    setSpreadsheets: proposalActions.setSpreadsheets,
},
)(QuoteEditScreen);
