import PropTypes from "prop-types";
import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import * as texchangePropTypes from "../../texchangePropTypes";
import * as color from "../../constants/color";
import * as inputSizes from "../../constants/inputSizes";

const styles = StyleSheet.create({
    container: {
        display: "inline-block",
        cursor: "pointer",
        position: "relative",
        width: 20,
        height: 20,
    },
    containerDisabled: {
        cursor: "not-allowed",
        opacity: 0.3,
    },
    checkbox: {
        cursor: "inherit",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        opacity: 0,
        width: "100%",
        height: "100%",
        zIndex: 2,
        margin: 0,
        padding: 0,
        ":checked": {
            backgroundColor: "#2196F3",
        }
    },
    checkmark: {
        position: "absolute",
        top: 0,
        left: 0,
        height: 20,
        width: 20,
        backgroundColor: color.white,
        borderRadius: 2,
        ":after": {
            content: '""',
            position: "absolute",
            display: "none",
            left: 4,
            top: 0,
            width: 7,
            height: 12,
            border: `solid ${color.white}`,
            borderWidth: "0 2px 2px 0",
            "-webkit-transform": "rotate(45deg)",
            "-ms-transform": "rotate(45deg)",
            transform: "rotate(45deg)",
        }
    },
    checkmarkBorderBlue: {
        border: `solid 2px ${color.darkOrange}`,
    },
    checkmarkBorderGreen: {
        border: `solid 2px ${color.mediumRed}`,
    },
    largeContainer: {
        width: 36,
        height: 36,
    },
    largeCheckmark: {
        width: 36,
        height: 36,
        borderRadius: 4,
        border: `solid 3px ${color.mediumYellow1}`,
        ":after": {            
            left: 9,
            top: 0,
            width: 13,
            height: 23,            
            borderWidth: "0 4px 4px 0",            
        }
    },
    checkmarkCheckedBlue: {
        backgroundColor: color.mediumYellow1,
        borderColor: color.mediumYellow1,
        ":after": {
            display: "block",
        }
    },
    checkmarkCheckedGreen: {
        backgroundColor: color.mediumRed,
        borderColor: color.mediumRed,
        ":after": {
            display: "block",
        }
    },
});

export default class Checkbox extends Component {
    static propTypes = {
        checked: PropTypes.bool,
        disabled: PropTypes.bool.isRequired,
        aStyles: texchangePropTypes.aphroditeStyles,
        size: PropTypes.string,
        showAsGreen: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
    };

    render() {
        const { checked, disabled, aStyles, size, showAsGreen, ...rest } = this.props;
        return (
            <div
                className={css(
                    styles.container,
                    aStyles,
                    size === inputSizes.large ? styles.largeContainer : null,
                    disabled ? styles.containerDisabled : null,
                )}
            >
                <input
                    {...rest}
                    checked={checked}
                    className={css(styles.checkbox)}
                    disabled={disabled}
                    type="checkbox"
                />
                <span
                    className={css(
                        styles.checkmark,
                        showAsGreen ? styles.checkmarkBorderGreen : styles.checkmarkBorderBlue,
                        size === inputSizes.large ? styles.largeCheckmark : null,
                        checked && showAsGreen ? styles.checkmarkCheckedGreen : checked ? styles.checkmarkCheckedBlue : null
                    )} />
            </div>
        );
    }
}
