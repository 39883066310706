import instance from "./instance";

const responseData = response => response.data;

export function saveNewList(favoriteList) {
    return instance
        .request({
            url: "/favorite/save-list",
            method: "POST",
            data: {
                ...favoriteList
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function addPlan(planType, planId, favoriteListIds) {
    return instance
        .request({
            url: "/favorite/add-plan",
            method: "POST",
            data: {
                planType,
                planId,
                favoriteListIds,
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function getList() {    
    return instance
        .request({
            url: "/favorite/get-list",
            method: "GET",
        })
        .then(responseData);
}

export function getListForQuote(quoteId) {    
    return instance
        .request({
            url: `/favorite/get-list-for-quote/${quoteId}`,
            method: "GET",
        })
        .then(responseData);
}

export function getFavoritePlansByListId(favoriteListId) {    
    return instance
        .request({
            url: `/favorite/get-favorite-plans-by-list-id/${favoriteListId}`,
            method: "GET",
        })
        .then(responseData);
}

export function getPlansByListId(favoriteListId) {    
    return instance
        .request({
            url: `/favorite/get-plans-by-list-id/${favoriteListId}`,
            method: "GET",
        })
        .then(responseData);
}

export function removePlanFromList(planId, coverageType, favoriteListId) {    
    return instance
        .request({
            url: `/favorite/remove-plan`,
            method: "POST",
            data: {
                planId,                
                coverageType,
                favoriteListId,
            }
        })
        .then(responseData);
}

export function getListsForPlan(planId, coverageType) {
    return instance
        .request({
            url: `/favorite/get-list-by-plan/${planId}/${coverageType}`,
            method: "GET",
        })
        .then(responseData);    
}

export function updateLists(favoriteLists) {
    return instance
        .request({
            url: "/favorite/update-lists",
            method: "POST",
            data: {
                favoriteLists,
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function deleteList(favoriteListId) {
    return instance
        .request({
            url: `/favorite/delete-list/${favoriteListId}`,
            method: "DELETE",            
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function clearList(favoriteListId) {
    return instance
        .request({
            url: `/favorite/clear-list/${favoriteListId}`,
            method: "POST",            
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function addPlans(planType, planIds, favoriteListIds) {
    return instance
        .request({
            url: "/favorite/add-plans",
            method: "POST",
            data: {
                planType,
                planIds,
                favoriteListIds,
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}