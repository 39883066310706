import * as color from "../../constants/color";
import PageTitle from "../text/PageTitle";
import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import FavoritePlans from "../favorites/FavoritePlans";

const styles = StyleSheet.create({
    content: {
        backgroundColor: color.white,
        paddingRight: 0,
        paddingBottom: 80,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
    }, 
    contentSection: {
        width: "100%",
        padding: 0,
        backgroundColor: color.white,
        borderTop: `1px solid ${color.mediumRed}`,
        marginBottom: 20,
    },
    titleSection: {
        heigth: 64,
        backgroundColor: color.white,
    },
    productTitleSection: {
        width: "100%",
        paddingBottom: 10,
        borderBottom: `1px solid ${color.darkRed}`,
    }
});

const strings = {    
    title: "Favorite Plans",
};

export default class FavoritesScreen extends Component {
    static propTypes = {        
    };

    constructor(props) {
        super(props);
        this.state = {            
        };
    }    

    componentDidMount() {        
    }        

    render() {
        return (
            <div className={css(styles.content)}>
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.titleSection)}>                        
                        <PageTitle>{strings.title}</PageTitle>
                    </div>
                </div>
                <FavoritePlans />
            </div>
        );
    }
}
