import instance from "./instance";

const responseData = response => response.data;

export function getAll() {
    return instance
        .request({
            url: "/employer/get-employers",
            method: "GET",
        })
        .then(responseData);
}

export function getPaged(page, sortColumn, sortDirection, current, statusIds, renewalMonth, segmentSizes, salesRepIds) {
    return instance
        .request({
            url: "/employer/get-employers-page",
            method: "POST",
            data: {
                page,
                sortColumn,
                sortDirection,
                current,
                statusIds,
                renewalMonth,
                segmentSizes,
                salesRepIds
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function getByBrokerId(brokerId, page, sortColumn, sortDirection) {
    return instance
        .request({
            url: "/employer/get-employers-by-broker",
            method: "POST",
            data: {
                brokerId,
                page,
                sortColumn,
                sortDirection,
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function searchEmployers(term) {
    term = term.replace(/[^0-9a-zA-Z\s]+/g, "");
    if (term.length === 0) { term = "*"; }
    return instance
        .request({
            url: `/employer/search-employers/${term}`,
            method: "GET",
        })
        .then(responseData);
}

export function getById(id) {
    return instance
        .request({
            url: `/employer/get-employer/${id}`,
            method: "GET",
        })
        .then(responseData);
}

export function saveEmployer(employer) {
    return instance
        .request({
            url: "/employer/save-employer",
            method: "POST",
            data: { ...employer },
        })
        .then(responseData);
}

export function saveEmployerAddress(employerAddress) {
    return instance
        .request({
            url: "/employer/saveEmployerAddress",
            method: "POST",
            data: { ...employerAddress },
        })
        .then(responseData);
}

export function updateEmployer(employer) {
    return instance
        .request({
            url: "/employer/updateEmployer",
            method: "POST",
            data: { ...employer },
        })
        .then(responseData);
}

export function checkZip(postalCode, ratingArea) {
    return instance
        .request({
            url: `/employer/check-zip/${postalCode}/${ratingArea}`,
            method: "POST",
        })
        .then(responseData);
}

export function saveNote(employerNote) {
    return instance
        .request({
            url: "/employer/saveNote",
            method: "POST",
            data: { ...employerNote },
        })
        .then(responseData);
}

export function getNotes(employerId) {
    return instance
        .request({
            url: `/employer/getNotes/${employerId}`,
            method: "GET",
        })
        .then(responseData);
}