export const DEBUG_GRID = 10000;
export const DRAWER = 9000;
export const HEADER = 9000;
export const TAB_NAVIGATOR = 8000;
export const OVERLAY = 9500;
export const USER_PROFILE = 7000;
export const DROPDOWN = 6000;
export const DATEPICKER = 9000;
export const SEARCH = 6000;
export const FOOTER = 8250;
