import * as color from "../../constants/color";
import * as breakpoints from "../../constants/breakpoints";
import * as texchangePropTypes from "../../texchangePropTypes";
import * as inputSizes from "../../constants/inputSizes";
import ErrorMessage from "../text/ErrorMessage";
import Tooltip from "../Tooltip";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";

const placeholder = {
    color: color.darkGray5,
    fontWeight: 400,
};

const styles = StyleSheet.create({
    container: {
        position: "relative",
    },
    input: {
        width: "100%",
        backgroundColor: color.inputFieldGray,
        border: "solid",
        borderWidth: 1,
        borderRadius: 4,
        borderColor: color.darkGray7,
        color: color.darkestGray,
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "22px",
        outline: "none",
        padding: "18px 16px",
        margin: "10px 0px",
        "::-webkit-input-placeholder": placeholder,
        "::-moz-placeholder": placeholder,
        "::-ms-input-placeholder": placeholder,
        "::-placeholder": placeholder,

        ":focus": {
            borderColor: color.lightOrange,
            borderWidth: 2,
        },

        [breakpoints.tabletAndDown]: {
            letterSpacing: 0.17,
        },

        [breakpoints.desktopOnly]: {

        },
    },
    inputSmall: {
        borderColor: color.lightGray2,
        color: color.darkestGray,
        fontSize: 14,
        fontWeight: "normal",
        lineHeight: "19px",
        outline: "none",
        padding: "7px 14px",
        margin: "7px 0px",
        minWidth: "90px",
        maxWidth: "300px",
        boxShadow: "0 1px 1px 0 rgba(0,0,0,0.4)",
    },
    inputPrependLabel: {
        paddingLeft: 36,
        textAlign: "center",
    },
    inputAppendLabel: {
        paddingRight: 30,
    },
    hasValue: {
        paddingTop: 24,
        paddingBottom: 12,
    },
    title: {
        position: "absolute",
        top: 18,
        left: 16,
        fontSize: 13,
        color: color.darkGray,
    },
    disabled: {
        color: color.darkestBlueAlpha30,
    },
    withValidationError: {
        color: color.darkRed,
    },
    validationMessage: {
        position: "absolute",
        top: 16,
        right: 8,
        pointerEvents: "none",
    },
    icon: {
        position: "absolute",
        top: 28,
        right: 24,
    },
    iconSmall: {
        top: 22,
        right: 22,
    },
    prependLabelSmall: {
        position: "absolute",
        top: 18,
        left: 12,
        fontSize: 14,
        fontWeight: 500,
        color: color.darkGray,
    },
    appendLabelSmall: {
        position: "absolute",
        top: 18,
        right: 12,
        fontSize: 14,
        fontWeight: 500,
        color: color.darkGray,
    },
    prefixLabel: {
        color: color.darkGray,
        position: "absolute",
        top: 32,
        fontSize: 16,
        right: "calc(100% + 6px)",
    },
    postfixLabel: {
        color: color.darkGray,
        position: "absolute",
        top: 32,
        fontSize: 16,
        left: "calc(100% + 6px)",
    },
    blue: {
        backgroundColor: color.inputFieldGray,
    }
});

class TextInput extends Component {
    static propTypes = {
        aStyles: texchangePropTypes.aphroditeStyles,
        forwardedRef: PropTypes.object,
        validationMessage: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.string,
        ]),
        disabled: PropTypes.bool,
        value: PropTypes.string,
        placeholder: PropTypes.string,
        hideLabel: PropTypes.bool,
        icon: PropTypes.string,
        size: PropTypes.string,
        prependLabel: PropTypes.string,
        appendLabel: PropTypes.string,
        prefixLabel: PropTypes.string,
        postfixLabel: PropTypes.string,
        showAsBlue: PropTypes.bool,
        tooltip: PropTypes.string,
        autoComplete: PropTypes.string,
    };

    render() {
        const { aStyles, forwardedRef, validationMessage, hideLabel, icon, prependLabel, appendLabel, prefixLabel, postfixLabel, showAsBlue, ...rest } = this.props;
        const isSmall = this.props.size === inputSizes.small;

        const hasPrependLabel = typeof prependLabel !== "undefined" && prependLabel !== null;
        const hasAppendLabel = typeof appendLabel !== "undefined" && appendLabel !== null;

        const hasPrefixLabel = typeof prefixLabel !== "undefined" && prefixLabel !== null;
        const hasPostfixLabel = typeof postfixLabel !== "undefined" && postfixLabel !== null;

        return (
            <div className={css(styles.container, aStyles)}>
                {hasPrefixLabel &&
                    <div className={css(styles.prefixLabel)}>
                        {prefixLabel}
                    </div>
                }
                {hasPrependLabel &&
                    <div className={css(styles.prependLabelSmall)}>
                        {prependLabel}
                    </div>
                }
                <input
                    {...rest}
                    ref={forwardedRef}
                    className={css(
                        styles.input,
                        isSmall ? styles.inputSmall : null,
                        showAsBlue ? styles.blue : null,
                        hasPrependLabel ? styles.inputPrependLabel : null,
                        hasAppendLabel ? styles.inputAppendLabel : null,
                        this.props.disabled ? styles.disabled : null,
                        validationMessage && this.props.value === "" ? styles.withValidationError : null,
                        this.props.value !== "" && !hideLabel ? styles.hasValue : null
                    )}
                />
                {hasAppendLabel &&
                    <div className={css(styles.appendLabelSmall)}>
                        {appendLabel}
                    </div>
                }
                {hasPostfixLabel &&
                    <div className={css(styles.postfixLabel)}>
                        {postfixLabel}
                    </div>
                }
                {icon &&
                    <img
                        src={icon}
                        alt="icon"
                        className={css(
                            styles.icon,
                            isSmall ? styles.iconSmall : null,
                        )} />
                }

                {this.props.value !== "" && this.props.value !== null && !hideLabel ? (
                    <div
                        className={css(styles.title)}>{this.props.placeholder}</div>
                ) : null}

                {validationMessage ? (
                    <ErrorMessage aStyles={styles.validationMessage}>
                        {validationMessage}
                    </ErrorMessage>
                ) : null}

                {this.props.tooltip ? <Tooltip text={this.props.tooltip} /> : null}
            </div>
        );
    }
}

const WrappedComponent = React.forwardRef((props, ref) => {
    return <TextInput {...props} forwardedRef={ref} />;
});

WrappedComponent.displayName = "WrappedTextInput";
export default WrappedComponent;
