import * as actionTypes from "../constants/actionTypes";
import createReducer from "../lib/createReducer";

const defaultState = {
    spreadsheetType: null,
    quote: null,
    selectedPlans: {
        medical: [],
        dental: [],
        vision: [],
        life: [],
    },
    spreadsheets: {
        singlePlan: [],
        multiPlan: [],
        stackedMultiPlan: [],
        automatic: [],
    },
    carriers: {
        medical: [],
        dental: [],
        vision: [],
        life: [],
    },
    planTypes: [],
    networks: {
        medical: [],
        dental: [],
        vision: [],
        life: [],
    },
    minMaxFilters: [],
    planTypesD: [],
    coinsurancesD: [],
    deductiblesD: [],
    perioEndosD: [],
    annualMaximumsD: [],
    orthoMaximumsD: [],
    reimbursementsD: [],
    examCopaysV: [],
    fundingTypesV: [],
    materialCopaysV: [],
    frameAllowancesV: [],
    contactLensAllowancesV: [],
    frameFrequenciesV: [],
    favoriteLists: [],
    favoritePlans: [],
    favoritePlanMode: null,
    favoritesApplied: false,
};

function PROPOSAL_SET_SPREADSHEET_TYPE(state, action) {
    const { spreadsheetType } = action;
    return {
        ...state,
        spreadsheetType,
    };
}

function PROPOSAL_SET_QUOTE(state, action) {
    const { quote } = action;
    return {
        ...state,
        quote,
    };
}

function PROPOSAL_SET_SELECTED_PLANS(state, action) {
    const { selectedPlans, selectedNewPlanIds, coverageType } = action;
    selectedPlans[coverageType] = selectedNewPlanIds;
    return {
        ...state,
        selectedPlans: Object.assign({}, selectedPlans),
    };
}

function PROPOSAL_SET_SPREADSHEETS(state, action) {
    const { spreadsheets } = action;
    return {
        ...state,
        spreadsheets,
    }
}

function PROPOSAL_SET_CARRIERS(state, action) {
    const { carriers } = action;
    return {
        ...state,
        carriers,
    }
}

function PROPOSAL_SET_PLANTYPES(state, action) {
    const { planTypes } = action;
    return {
        ...state,
        planTypes,
    }
}

function PROPOSAL_SET_NETWORKS(state, action) {
    const { networks } = action;
    return {
        ...state,
        networks,
    }
}

function PROPOSAL_SET_MIN_MAX_FILTERS(state, action) {
    const { minMaxFilters } = action;
    return {
        ...state,
        minMaxFilters,
    }
}

function PROPOSAL_SET_PLANTYPESD(state, action) {
    const { planTypesD } = action;
    return {
        ...state,
        planTypesD,
    }
}

function PROPOSAL_SET_COINSURANCESD(state, action) {
    const { coinsurancesD } = action;
    return {
        ...state,
        coinsurancesD,
    }
}

function PROPOSAL_SET_DEDUCTIBLESD(state, action) {
    const { deductiblesD } = action;
    return {
        ...state,
        deductiblesD,
    }
}

function PROPOSAL_SET_PERIOENDOSD(state, action) {
    const { perioEndosD } = action;
    return {
        ...state,
        perioEndosD,
    }
}

function PROPOSAL_SET_ANNUALMAXIMUMSD(state, action) {
    const { annualMaximumsD } = action;
    return {
        ...state,
        annualMaximumsD,
    }
}

function PROPOSAL_SET_ORTHOMAXIMUMSD(state, action) {
    const { orthoMaximumsD } = action;
    return {
        ...state,
        orthoMaximumsD,
    }
}

function PROPOSAL_SET_REIMBURSEMENTSD(state, action) {
    const { reimbursementsD } = action;
    return {
        ...state,
        reimbursementsD,
    }
}

function PROPOSAL_SET_EXAMCOPAYSV(state, action) {
    const { examCopaysV } = action;
    return {
        ...state,
        examCopaysV,
    }
}

function PROPOSAL_SET_FUNDINGTYPESV(state, action) {
    const { fundingTypesV } = action;
    return {
        ...state,
        fundingTypesV,
    }
}

function PROPOSAL_SET_MATERIALCOPAYSV(state, action) {
    const { materialCopaysV } = action;
    return {
        ...state,
        materialCopaysV,
    }
}

function PROPOSAL_SET_FRAMEALLOWANCESV(state, action) {
    const { frameAllowancesV } = action;
    return {
        ...state,
        frameAllowancesV,
    }
}

function PROPOSAL_SET_CONTACTLENSALLOWANCESV(state, action) {
    const { contactLensAllowancesV } = action;
    return {
        ...state,
        contactLensAllowancesV,
    }
}

function PROPOSAL_SET_FRAMEFREQUENCIESV(state, action) {
    const { frameFrequenciesV } = action;
    return {
        ...state,
        frameFrequenciesV,
    }
}

function PROPOSAL_SET_FAVORITE_LISTS(state, action) {
    const { favoriteLists } = action;
    return {
        ...state,
        favoriteLists,
    }
}

function PROPOSAL_SET_FAVORITE_PLAN_LIST(state, action) {
    const { favoritePlans, favoritePlanMode } = action;
    return {
        ...state,
        favoritePlans,
        favoritePlanMode,
    }
}

function PROPOSAL_SET_FAVORITES_APPLIED(state, action) {
    const { favoritesApplied } = action;
    return {
        ...state,
        favoritesApplied,
    }
}


export default createReducer(defaultState, {
    [actionTypes.PROPOSAL_SET_SPREADSHEET_TYPE]: PROPOSAL_SET_SPREADSHEET_TYPE,
    [actionTypes.PROPOSAL_SET_QUOTE]: PROPOSAL_SET_QUOTE,
    [actionTypes.PROPOSAL_SET_SELECTED_PLANS]: PROPOSAL_SET_SELECTED_PLANS,
    [actionTypes.PROPOSAL_SET_SPREADSHEETS]: PROPOSAL_SET_SPREADSHEETS,
    [actionTypes.PROPOSAL_SET_CARRIERS]: PROPOSAL_SET_CARRIERS,
    [actionTypes.PROPOSAL_SET_PLANTYPES]: PROPOSAL_SET_PLANTYPES,
    [actionTypes.PROPOSAL_SET_NETWORKS]: PROPOSAL_SET_NETWORKS,
    [actionTypes.PROPOSAL_SET_MIN_MAX_FILTERS]: PROPOSAL_SET_MIN_MAX_FILTERS,
    [actionTypes.PROPOSAL_SET_PLANTYPESD]: PROPOSAL_SET_PLANTYPESD,
    [actionTypes.PROPOSAL_SET_COINSURANCESD]: PROPOSAL_SET_COINSURANCESD,
    [actionTypes.PROPOSAL_SET_DEDUCTIBLESD]: PROPOSAL_SET_DEDUCTIBLESD,
    [actionTypes.PROPOSAL_SET_PERIOENDOSD]: PROPOSAL_SET_PERIOENDOSD,
    [actionTypes.PROPOSAL_SET_ANNUALMAXIMUMSD]: PROPOSAL_SET_ANNUALMAXIMUMSD,
    [actionTypes.PROPOSAL_SET_ORTHOMAXIMUMSD]: PROPOSAL_SET_ORTHOMAXIMUMSD,
    [actionTypes.PROPOSAL_SET_REIMBURSEMENTSD]: PROPOSAL_SET_REIMBURSEMENTSD,
    [actionTypes.PROPOSAL_SET_EXAMCOPAYSV]: PROPOSAL_SET_EXAMCOPAYSV,
    [actionTypes.PROPOSAL_SET_FUNDINGTYPESV]: PROPOSAL_SET_FUNDINGTYPESV,
    [actionTypes.PROPOSAL_SET_MATERIALCOPAYSV]: PROPOSAL_SET_MATERIALCOPAYSV,
    [actionTypes.PROPOSAL_SET_FRAMEALLOWANCESV]: PROPOSAL_SET_FRAMEALLOWANCESV,
    [actionTypes.PROPOSAL_SET_CONTACTLENSALLOWANCESV]: PROPOSAL_SET_CONTACTLENSALLOWANCESV,
    [actionTypes.PROPOSAL_SET_FRAMEFREQUENCIESV]: PROPOSAL_SET_FRAMEFREQUENCIESV,
    [actionTypes.PROPOSAL_SET_FAVORITE_LISTS]: PROPOSAL_SET_FAVORITE_LISTS,
    [actionTypes.PROPOSAL_SET_FAVORITE_PLAN_LIST]: PROPOSAL_SET_FAVORITE_PLAN_LIST,
    [actionTypes.PROPOSAL_SET_FAVORITES_APPLIED]: PROPOSAL_SET_FAVORITES_APPLIED,
});

