import * as color from "../../constants/color";
import * as routeHelpers from "../../lib/routeHelpers";
import * as texchangePropTypes from "../../texchangePropTypes";
import PageTitle from "../text/PageTitle";
import TextInput from "../inputs/TextInput";
import TextArea from "../inputs/TextArea";
import Dropdown from "../inputs/Dropdown";
import MetallicLevel from "../inputs/MetallicLevel";
import Headline from "../text/Headline";
import DatePicker from "../inputs/DatePicker";
import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import ActionButton from "../ActionButton";
import backCircle from "../../images/SVGs/BackCircle.svg";
import logger from "../../lib/logger";
import { ToastContainer, toast } from 'react-toastify';
import describeNetworkError from "../../lib/describeNetworkError";
import * as dataApi from "../../lib/api/data";
import * as medicalPlansApi from "../../lib/api/medicalPlans";
import 'react-toastify/dist/ReactToastify.css';

const styles = StyleSheet.create({
    content: {
        paddingRight: 10,
        paddingBottom: 100,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
        fontSize: 15,
    },
    contentSection: {
        width: "100%",
        paddingBottom: 10,
        backgroundColor: color.white,
        borderTop: `8px solid ${color.mediumRed}`,        
    },
    titleSection: {
        heigth: 64,
        backgroundColor: color.white,
    },
    headline: {
        fontSize: 18,
        fontWeight: 500,
        marginBottom: 8,
        marginTop: 8,
    },    
    instructions: {
        color: color.darkestGray,
        fontWeight: 300,
        fontSize: 20,
        marginLeft: 24,
        marginTop: 20,
        marginBottom: 20,
    },    
    planForm: {        
        maxWidth: 418,
        marginBottom: 20,        
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 4,  
        // boxShadow: "0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.2)",
    },    
    backButtonContainer: {
        float: "left",
    },
    backButtonImage: {
        display: "block",
    },
    backButton: {
        position: "relative",
        top: 4,
        cursor: "pointer",
        border: "none",
        backgroundColor: "transparent",
        display: "block",
        marginLeft: 12,
        marginRight: 12,
        marginTop: 7,
        outline: "none",
    },   
    buttonContainer: {
        width: "100%",
        textAlign: "center",
        display: "flex",
        marginTop: 24,        
    },
    submitButton: {                
        flexGrow: 1,
        flexBasis: 0,
    },
    cancelButton: {
        marginRight: 32,
        flexGrow: 1,
        flexBasis: 0,
    },
    inputContainer: {
        display: "flex",
    },
    inputLabel: {
        alignSelf: "center",
        width: "40%",
        minWidth: "40%",
        fontSize: 14,
    },
    input: {
        flexGrow: 2,
        marginLeft: 8,
        marginRight: 8,
    },
    networkContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: 12,
    },
    networkLabel: {
        fontSize: 14,
        width: "30%",
        paddingLeft: 8,
    }
});

const strings = {
    title: "Add A Plan",
    required: "required",
};

const inputNames = {
    areaId: "areaId",
    carrierId: "carrierId",
    effectiveDateStart: "effectiveDateStart",
    effectiveDateEnd: "effectiveDateEnd",
    market: "market",
    // fundingType: "fundingType",
    metallicLevelId: "metallicLevelId",    
    networkName: "networkName",
    planTypeName: "planTypeName",
    name: "name",
    packageName: "packageName",
    telehealth: "telehealth",
    wellnessCredit: "wellnessCredit",
    individualDeductibleIn: "individualDeductibleIn",
    individualDeductibleOut: "individualDeductibleOut",
    officeVisitCopayIn: "officeVisitCopayIn",
    officeVisitCopayOut: "officeVisitCopayOut",
    specialistVisitCopayIn: "specialistVisitCopayIn",
    specialistVisitCopayOut: "specialistVisitCopayOut",
    rxCardIn: "rxCardIn",
    rxCardOut: "rxCardOut",
    rxCardDescription: "rxCardDescription",
    mailOrderIn: "mailOrderIn",
    mailOrderOut: "mailOrderOut",
    urgentCareIn: "urgentCareIn",
    urgentCareOut: "urgentCareOut",
    erIn: "erIn",
    erOut: "erOut",
    labXRayIn: "labXRayIn",
    labXRayOut: "labXRayOut",
    advancedImagingIn: "advancedImagingIn",
    advancedImagingOut: "advancedImagingOut",
    familyDeductibleIn: "familyDeductibleIn",
    familyDeductibleOut: "familyDeductibleOut",    
    inpatientHospitalizationIn: "inpatientHospitalizationIn",
    inpatientHospitalizationOut: "inpatientHospitalizationOut",
    outpatientSurgeryIn: "outpatientSurgeryIn",
    outpatientSurgeryOut: "outpatientSurgeryOut",
    specialtyMedicationIn: "specialtyMedicationIn",
    coInsuranceIn: "coInsuranceIn",
    coInsuranceOut: "coInsuranceOut",
    individualOOPIn: "individualOOPIn",
    individualOOPOut: "individualOOPOut",
    familyOOPIn: "familyOOPIn",
    familyOOPOut: "familyOOPOut",    
};

export default class PlanEditScreen extends Component {
    static propTypes = {
        history: texchangePropTypes.history.isRequired,
        match: texchangePropTypes.match.isRequired,
    };

    constructor(props) {
        super(props);        

        this.state = {
            inputs: {
                [inputNames.areaId]: "",
                [inputNames.carrierId]: "",
                [inputNames.effectiveDateStart]: null,
                [inputNames.effectiveDateEnd]: null,
                [inputNames.carrierId]: "",
                [inputNames.market]: "",
                // [inputNames.fundingType]: "",
                [inputNames.metallicLevelId]: null,                
                [inputNames.networkName]: "",
                [inputNames.planTypeName]: "",
                [inputNames.packageName]: "",
                [inputNames.name]: "",
                [inputNames.telehealth]: "",
                [inputNames.wellnessCredit]: "",
                [inputNames.individualDeductibleIn]: "",
                [inputNames.individualDeductibleOut]: "",
                [inputNames.officeVisitCopayIn]: "",
                [inputNames.officeVisitCopayOut]: "",
                [inputNames.specialistVisitCopayIn]: "",
                [inputNames.specialistVisitCopayOut]: "",
                [inputNames.rxCardIn]: "",
                [inputNames.rxCardOut]: "",
                [inputNames.rxCardDescription]: "",
                [inputNames.mailOrderIn]: "",
                [inputNames.mailOrderOut]: "",
                [inputNames.urgentCareIn]: "",
                [inputNames.urgentCareOut]: "",
                [inputNames.erIn]: "",
                [inputNames.erOut]: "",
                [inputNames.labXRayIn]: "",
                [inputNames.labXRayOut]: "",
                [inputNames.advancedImagingIn]: "",
                [inputNames.advancedImagingOut]: "",
                [inputNames.familyDeductibleIn]: "",
                [inputNames.familyDeductibleOut]: "",    
                [inputNames.inpatientHospitalizationIn]: "",
                [inputNames.inpatientHospitalizationOut]: "",
                [inputNames.outpatientSurgeryIn]: "",
                [inputNames.outpatientSurgeryOut]: "",
                [inputNames.specialtyMedicationIn]: "",
                [inputNames.coInsuranceIn]: "",
                [inputNames.coInsuranceOut]: "",
                [inputNames.individualOOPIn]: "",
                [inputNames.individualOOPOut]: "",
                [inputNames.familyOOPIn]: "",
                [inputNames.familyOOPOut]: "", 
            },
            errors: [],
            areas: [],            
            carriers: [],
            planTypes: [ "PPO", "EPO", "POS", "HMO" ],
            marketSizes: ["1 - 50", "51 - 99"],
            allNetworks: [],
            networks: [],
            productLines: [],
            ready: false,
            isSaving: false,
        };
    }   

    componentDidMount() {
        //redirect back to plans list
        setTimeout(() => {
            this.props.history.push(routeHelpers.plans());
        }, 0);
        /*
        dataApi.getAreas()
            .then(response => {
                this.setState({                                        
                    areas: response,
                });
            });

        dataApi.getCarriers()
            .then(response => {
                this.setState({
                    carriers: response,    
                });                            
            });

        dataApi.getNetworksFilter(0)
            .then(response => {
                this.setState({
                    networks: response.medical,    
                    allNetworks: response.medical,
                });                            
            });
        */
    }

    _onBackButtonClick = () => {
        this.props.history.push(routeHelpers.plans());
    };

    _getProductLines = () => {        
        const { inputs } = this.state;

        const param = {
            areaId: inputs[inputNames.areaId],
            carrierId: inputs[inputNames.carrierId],
            effectiveDateStart: inputs[inputNames.effectiveDateStart],
            effectiveDateEnd: inputs[inputNames.effectiveDateEnd],
        };

        if (param.areaId && param.carrierId && param.effectiveDateStart && param.effectiveDateEnd) {
            dataApi.getMedicalProductLines(param)
                .then(response => {
                    this.setState({
                        productLines: response,    
                    });                                        
                });
        }        
    }

    _handleDropdownChanged = selectedItem => {
        const { name, value } = selectedItem;

        if (name === inputNames.carrierId) {
            const networks = this.state.allNetworks.filter(p => p.carrierId === parseInt(value));
            this.setState({
                networks,
            });
        }

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
                errors,
            };
        }, () => {
            if (name === inputNames.areaId || name === inputNames.carrierId) {
                this._getProductLines();
            }
        });        
    };

    _handleTextChanged = e => {
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;
        
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: val,
                },
                errors,
            };
        }); 
    }

    _handleDateChanged = (value, name) => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
                errors,
            };
        }, () => {
            this._getProductLines();
        });        
    }

    _metallicLevelChanged = value => {     
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.metallicLevelId];

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.metallicLevelId]: value,
                },
                errors,
            };
        });
    }

    _validForm = () => {
        const errors = {};
        const { inputs } = this.state;

        Object.keys(inputs).forEach(stateKey => {            
            if (inputs[stateKey] === null || inputs[stateKey] === "") {
                errors[stateKey] = strings.required;
            }
        });

        this.setState({ errors });
        return !Object.keys(errors).length;
    };

    _submit = e => {
        e.preventDefault();
        if (this.state.loading) {
            return;
        }

        if (!this._validForm()) {
            return;
        }

        this.setState({ isSaving: true });

        const { inputs } = this.state;
        medicalPlansApi.saveMedicalPlan(inputs)
            .then(response => {
                toast.success("Plan Saved", { toastId: 5 });
                setTimeout(() => {
                    this.props.history.push(routeHelpers.planDetail(response.id));
                }, 2500);
            })
            .catch(err => {
                logger.warn(err);
                let errors = {};
                if (err && err.response) {
                    const { response } = err;
                    if (
                        response &&
                        response.data &&
                        response.data.errors &&
                        Object.keys(response.data.errors).length > 0
                    ) {
                        // Server provided validation messages
                        errors = response.data.errors;
                    } else {
                        // 500 or something else unexpected
                        const description = describeNetworkError(err);
                        toast.error(description.join("\n"), { toastId: 6 });
                    }
                }

                this.setState({ errors });
            })
            .finally(() => this.setState({ isSaving: false }));
    };

    render() {
        return (
            <div className={css(styles.content)}>
                <ToastContainer position="top-center" autoClose={2500} />
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.titleSection)}>
                        <div className={css(styles.backButtonContainer)}>
                            <button className={css(styles.backButton)} type="button" onClick={this._onBackButtonClick}>
                                <img src={backCircle} className={css(styles.backButtonImage)} alt="back" />
                            </button>
                        </div>
                        <PageTitle>{strings.title}</PageTitle>
                    </div>                    
                    <div className={css(styles.instructions)}>Please fill out all the plan fields.</div>
                    <div className={css(styles.planForm)}>
                        <Dropdown
                            name={inputNames.areaId}
                            options={this.state.areas.map(item => ({
                                label: item.name,
                                value: item.id.toString(),
                            }))}
                            onChange={this._handleDropdownChanged}
                            placeholder="State"
                            validationMessage={this.state.errors[inputNames.areaId]}
                            value={this.state.inputs[inputNames.areaId]}                            
                        />
                        <DatePicker
                            name={inputNames.effectiveDateStart}
                            onChange={this._handleDateChanged}
                            placeholder="Effective Start Date"
                            validationMessage={this.state.errors[inputNames.effectiveDateStart]}
                            value={this.state.inputs[inputNames.effectiveDateStart]}                            
                        />
                        <DatePicker
                            name={inputNames.effectiveDateEnd}
                            onChange={this._handleDateChanged}
                            placeholder="Effective End Date"
                            validationMessage={this.state.errors[inputNames.effectiveDateEnd]}
                            value={this.state.inputs[inputNames.effectiveDateEnd]}                            
                            isEndDate={true}
                        />
                        <Dropdown
                            name={inputNames.carrierId}
                            options={this.state.carriers.map(item => ({
                                label: item.name,
                                value: item.id.toString(),
                            }))}
                            onChange={this._handleDropdownChanged}
                            placeholder="Carrier"
                            validationMessage={this.state.errors[inputNames.carrierId]}
                            value={this.state.inputs[inputNames.carrierId]}                            
                        />
                        <Dropdown
                            name={inputNames.market}                            
                            onChange={this._handleDropdownChanged}
                            options={this.state.marketSizes.map(item => ({
                                label: item,
                                value: item,
                            }))}
                            placeholder="Market Size"
                            validationMessage={this.state.errors[inputNames.market]}
                            value={this.state.inputs[inputNames.market]}                            
                        />
                        {/*
                        <Dropdown
                            name={inputNames.fundingType}                            
                            onChange={this._handleDropdownChanged}
                            placeholder="Funding Type"
                            validationMessage={this.state.errors[inputNames.fundingType]}
                            value={this.state.inputs[inputNames.fundingType]}                            
                        />*/}
                        <MetallicLevel 
                            onChange={this._metallicLevelChanged}
                            value={this.state.inputs[inputNames.metallicLevelId]}
                        />
                        <Dropdown
                            name={inputNames.planTypeName}
                            options={this.state.planTypes.map(item => ({
                                label: item,
                                value: item,
                            }))}
                            onChange={this._handleDropdownChanged}
                            placeholder="Plan Type"
                            validationMessage={this.state.errors[inputNames.planTypeName]}
                            value={this.state.inputs[inputNames.planTypeName]}                            
                        />
                        <TextArea
                            name={inputNames.name}
                            onChange={this._handleTextChanged}
                            placeholder="Plan Name"
                            maxLength="70"
                            validationMessage={this.state.errors[inputNames.name]}
                        />
                        <Dropdown
                            name={inputNames.networkName}
                            options={this.state.networks.map(item => ({
                                label: item.name,
                                value: item.name,
                            }))}
                            onChange={this._handleDropdownChanged}
                            placeholder="Network Name"
                            validationMessage={this.state.errors[inputNames.networkName]}
                            value={this.state.inputs[inputNames.networkName]}                            
                        />
                        <Dropdown
                            name={inputNames.packageName}
                            options={this.state.productLines.map(item => ({
                                label: item,
                                value: item,
                            }))}
                            onChange={this._handleDropdownChanged}
                            placeholder="Product Line"
                            validationMessage={this.state.errors[inputNames.packageName]}
                            value={this.state.inputs[inputNames.packageName]}                            
                        />
                        <Headline aStyles={styles.headline}>Enter Plan Benefits</Headline>
                        <div className={css(styles.inputContainer)}>
                            <label className={css(styles.inputLabel)}>Telehealth</label>
                            <TextInput
                                name={inputNames.telehealth}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.telehealth]}
                                aStyles={styles.input}
                            />
                        </div>
                         <div className={css(styles.inputContainer)}>
                            <label className={css(styles.inputLabel)}>Wellness Credit</label>
                            <TextInput
                                name={inputNames.wellnessCredit}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.wellnessCredit]}
                                aStyles={styles.input}                           
                            />
                        </div>
                        <div className={css(styles.networkContainer)}>
                            <div className={css(styles.networkLabel)}>In Network</div>
                            <div className={css(styles.networkLabel)}>Out Of Network</div>
                        </div>
                        <div className={css(styles.inputContainer)}>
                            <label className={css(styles.inputLabel)}>Ind. Deductible</label>
                            <TextInput
                                name={inputNames.individualDeductibleIn}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.individualDeductibleIn]}
                                aStyles={styles.input}
                            />
                            <TextInput
                                name={inputNames.individualDeductibleOut}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.individualDeductibleOut]}
                                aStyles={styles.input}
                            />
                        </div>
                        <div className={css(styles.inputContainer)}>
                            <label className={css(styles.inputLabel)}>Fam. Deductible</label>
                            <TextInput
                                name={inputNames.familyDeductibleIn}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.familyDeductibleIn]}
                                aStyles={styles.input}
                            />
                            <TextInput
                                name={inputNames.familyDeductibleOut}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.familyDeductibleOut]}
                                aStyles={styles.input}
                            />
                        </div>
                        <div className={css(styles.inputContainer)}>
                            <label className={css(styles.inputLabel)}>Coinsurance - Carrier</label>
                            <TextInput
                                name={inputNames.coInsuranceIn}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.coInsuranceIn]}
                                aStyles={styles.input}
                            />
                            <TextInput
                                name={inputNames.coInsuranceOut}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.coInsuranceOut]}
                                aStyles={styles.input}
                            />
                        </div>
                        <div className={css(styles.inputContainer)}>
                            <label className={css(styles.inputLabel)}>Ind. Out of Pocket Max</label>
                            <TextInput
                                name={inputNames.individualOOPIn}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.individualOOPIn]}
                                aStyles={styles.input}
                            />
                            <TextInput
                                name={inputNames.individualOOPOut}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.individualOOPOut]}
                                aStyles={styles.input}
                            />
                        </div>
                        <div className={css(styles.inputContainer)}>
                            <label className={css(styles.inputLabel)}>Fam. Out of Pocket Max</label>
                            <TextInput
                                name={inputNames.familyOOPIn}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.familyOOPIn]}
                                aStyles={styles.input}
                            />
                            <TextInput
                                name={inputNames.familyOOPOut}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.familyOOPOut]}
                                aStyles={styles.input}
                            />
                        </div>
                        <div className={css(styles.inputContainer)}>
                            <label className={css(styles.inputLabel)}>Office Visit Copay</label>
                            <TextInput
                                name={inputNames.officeVisitCopayIn}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.officeVisitCopayIn]}
                                aStyles={styles.input}
                            />
                            <TextInput
                                name={inputNames.officeVisitCopayOut}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.officeVisitCopayOut]}
                                aStyles={styles.input}
                            />
                        </div>
                        <div className={css(styles.inputContainer)}>
                            <label className={css(styles.inputLabel)}>Specialist Visit Copay</label>
                            <TextInput
                                name={inputNames.specialistVisitCopayIn}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.specialistVisitCopayIn]}
                                aStyles={styles.input}
                            />
                            <TextInput
                                name={inputNames.specialistVisitCopayOut}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.specialistVisitCopayOut]}
                                aStyles={styles.input}
                            />
                        </div>
                        <div className={css(styles.inputContainer)}>
                            <label className={css(styles.inputLabel)}>Rx Card</label>                            
                            <TextInput
                                name={inputNames.rxCardIn}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.rxCardIn]}
                                aStyles={styles.input}
                            />
                            <TextInput
                                name={inputNames.rxCardOut}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.rxCardOut]}
                                aStyles={styles.input}
                            />
                        </div>
                        <div className={css(styles.inputContainer)}>
                            <label className={css(styles.inputLabel)}>Mail Order</label>                            
                            <TextInput
                                name={inputNames.mailOrderIn}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.mailOrderIn]}
                                aStyles={styles.input}
                            />
                            <TextInput
                                name={inputNames.mailOrderOut}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.mailOrderOut]}
                                aStyles={styles.input}
                            />
                        </div>
                        <div className={css(styles.inputContainer)}>
                            <label className={css(styles.inputLabel)}>Urgent Care</label>                            
                            <TextInput
                                name={inputNames.urgentCareIn}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.urgentCareIn]}
                                aStyles={styles.input}
                            />
                            <TextInput
                                name={inputNames.urgentCareOut}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.urgentCareOut]}
                                aStyles={styles.input}
                            />
                        </div>
                        <div className={css(styles.inputContainer)}>
                            <label className={css(styles.inputLabel)}>ER</label>                            
                            <TextInput
                                name={inputNames.erIn}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.erIn]}
                                aStyles={styles.input}
                            />
                            <TextInput
                                name={inputNames.erOut}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.erOut]}
                                aStyles={styles.input}
                            />
                        </div>
                        <div className={css(styles.inputContainer)}>
                            <label className={css(styles.inputLabel)}>Lab/Xray</label>                            
                            <TextInput
                                name={inputNames.labXRayIn}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.labXRayIn]}
                                aStyles={styles.input}
                            />
                            <TextInput
                                name={inputNames.labXRayOut}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.labXRayOut]}
                                aStyles={styles.input}
                            />
                        </div>
                        <div className={css(styles.inputContainer)}>
                            <label className={css(styles.inputLabel)}>Advanced Imaging</label>                            
                            <TextInput
                                name={inputNames.advancedImagingIn}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.advancedImagingIn]}
                                aStyles={styles.input}
                            />
                            <TextInput
                                name={inputNames.advancedImagingOut}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.advancedImagingOut]}
                                aStyles={styles.input}
                            />
                        </div>
                        <div className={css(styles.inputContainer)}>
                            <label className={css(styles.inputLabel)}>Inpatient Hospitalization</label>                            
                            <TextInput
                                name={inputNames.inpatientHospitalizationIn}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.inpatientHospitalizationIn]}
                                aStyles={styles.input}
                            />
                            <TextInput
                                name={inputNames.inpatientHospitalizationOut}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.inpatientHospitalizationOut]}
                                aStyles={styles.input}
                            />
                        </div>
                        <div className={css(styles.inputContainer)}>
                            <label className={css(styles.inputLabel)}>Outpatient Surgery</label>                            
                            <TextInput
                                name={inputNames.outpatientSurgeryIn}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.outpatientSurgeryIn]}
                                aStyles={styles.input}
                            />
                            <TextInput
                                name={inputNames.outpatientSurgeryOut}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.outpatientSurgeryOut]}
                                aStyles={styles.input}
                            />
                        </div>
                        <div className={css(styles.inputContainer)}>
                            <label className={css(styles.inputLabel)}>Specialty Medication In</label>                            
                            <TextInput
                                name={inputNames.specialtyMedicationIn}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.specialtyMedicationIn]}
                                aStyles={styles.input}
                            />
                        </div>
                        <div className={css(styles.inputContainer)}>
                            <label className={css(styles.inputLabel)}>Rx Card Description</label>                            
                            <TextInput
                                name={inputNames.rxCardDescription}
                                onChange={this._handleTextChanged}                                
                                validationMessage={this.state.errors[inputNames.rxCardDescription]}
                                aStyles={styles.input}
                            />
                        </div>
                        {/*<Headline aStyles={styles.headline}>Choose Plan Availability</Headline>*/}
                        <div className={css(styles.buttonContainer)}>
                            <ActionButton                                    
                                aStyles={styles.cancelButton}
                                disabled={this.state.loading}
                                label="Cancel"
                                invertColor={true}
                                type="button"
                                onClick={this._onBackButtonClick}
                            />   
                            <ActionButton
                                aStyles={styles.submitButton}
                                label="Preview Plan"
                                type="submit"
                                onClick={this._submit}
                                disabled={this.state.loading}
                                isLoading={this.state.isSaving}
                            />                        
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}