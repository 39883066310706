import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import sortBy from "lodash.sortby";
import Modal from "../Modal";
import Infinite from "../Infinite";
import SmallDropdown from "../inputs/SmallDropdown";
import TextInput from "../inputs/TextInput";
import Toggle from "../inputs/Toggle";
import Checkbox from "../inputs/Checkbox";
import ActionButton from "../ActionButton";
import coverageTypes from "../../constants/coverageTypes";
import * as inputSizes from "../../constants/inputSizes";
import * as proposalActions from "../../actions/proposalActions";
import * as color from "../../constants/color";
import * as planTypes from "../../constants/planTypes";
import * as texchangePropTypes from "../../texchangePropTypes";
import * as planColumns from "../../constants/planColumns";
import * as quoteApi from "../../lib/api/quotes";
import * as censusApi from "../../lib/api/census";
import * as dataApi from "../../lib/api/data";
import * as util from "../../lib/util";
import Range from "../inputs/Range";
import Spinner from "../Spinner";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = StyleSheet.create({
    left: {
        float: "left",
        position: "relative",
        top: 2,
        left: 2,
        fontSize: 13,
        color: color.tableText,
    },
    right: {
        float: "right",
        position: "relative",
        top: -38,
        right: 20,
        fontSize: 13,
        color: color.darkestGray,
    },
    planContainer: {
        marginBottom: 64,
    },
    filterText: {
        display: "inline-block",
        fontSize: 13,
        marginLeft: 16,
        lineHeight: "28px",
    },
    dropdown: {
        display: "inline-block",
        width: 160,
        marginLeft: 16,
    },
    similarPlanDropdown: {
        display: "inline-block",
        width: "95%",
        minWidth: 340,
        marginLeft: 16,
    },
    inlineFilterContainer: {
        paddingTop: 1,
        paddingBottom: 1,
        backgroundColor: color.lightGray4,
        borderTop: `1px solid ${color.lightGray2}`,
        borderBottom: `1px solid ${color.lightGray2}`,
    },
    inlineTable: {
        borderCollapse: "collapse",
        verticalAlign: "middle",
        marginLeft: 10,
    },
    tblCell: {
        backgroundColor: color.lightGray4,
        textAlign: "left",
    },
    tblCellRight: {
        backgroundColor: color.lightGray4,
        textAlign: "right",
        padding: "0px 10px",
    },
    tblCellSearch: {
        backgroundColor: color.lightGray4,
        textAlign: "left",
        minWidth: "240px",
    },
    filterContainer: {
        display: "flex",
        alignItems: "flex-start",
        padding: "20px",
    },
    filterControl: {
        marginLeft: 12,
        marginRight: 12,
    },
    checkboxFilter: {
        marginBottom: 15,
        marginRight: 20,
        maxHeight: "268px",
        maxWidth: "350px",
        overflowX: "hidden",
        overflowY: "auto",
        paddingRight: 10,
    },
    checkboxFilterNoHeight: {
        marginBottom: 15,
        marginRight: 30,
        overflowX: "hidden",
        overflowY: "auto",
        paddingRight: 10,
    },
    carrierFilter: {
        minWidth: "150px",
        marginRight: 10,
    },
    filterLabel: {
        fontWeight: 500,
        fontSize: 14,
        color: color.darkestGray,
        whiteSpace: "nowrap",
    },
    effectiveDateFilter: {
        width: "20%",
    },
    loadingSpinner: {
        paddingTop: 24,
        paddingBottom: 24,
    },
    contributionToggle: {
        display: "inline-block",
    },
    toggle: {
        marginTop: 12,
    },
    contribution: {
        width: 90,
        marginLeft: 12,
    },
    contributionSubtext: {
        height: 0,
        fontSize: 12,
        textAlign: "right",
    },
    checkboxContainer: {
        marginTop: 16,
    },
    checkLabel: {
        display: "block",
        position: "relative",
        border: "none",
        marginTop: 16,
        marginBottom: 16,
        "-webkit-appearance": "none",
        cursor: "pointer",
    },
    pkgLabel: {
        marginLeft: 8,
    },
    check: {
        position: "absolute",
        top: -2,
        left: 0,
        marginRight: 10,
    },
    labelText: {
        marginLeft: 30,
        fontSize: 13,
        whiteSpace: "nowrap",
    },
    pkgText: {
        fontStyle: "italic",
        color: color.borderBackgroundGray,
    },
    rangeControlContainer: {
        display: "flex",
        flexWrap: "wrap",
        width: "50%",
        maxWidth: "800px",
        marginLeft: 40,
        justifyContent: "space-evenly",
    },
    filterControlColumn: {
        minWidth: "150px",
        maxWidth: "250px",
        padding: "0px 20px",
        overflowX: "hidden",
    },
    filterControlColumnWide: {
        minWidth: "250px",
        maxWidth: "350px",
        padding: "0px 20px",
        overflowX: "hidden",
    },
    rangeControlColumn: {
        width: "50%",
        paddingLeft: 64,
        paddingRight: 64,
    },
    rangeControlTitle: {
        paddingBottom: 10,
    },
    rangeControl: {
        marginBottom: 24,
        paddingBottom: 24,
        maxWidth: "350px",
    },
    planButtonContainer: {
        position: "fixed",
        backgroundColor: color.white,
        paddingTop: 10,
        paddingBottom: 10,
        boxShadow: "0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.2)",
    },
    planButtons: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
        width: "60%",
        margin: "0px auto",
    },
    modalContent: {
        width: "70vw",
        height: "65vh",
        overflow: "auto",
        minWidth: "800px",
        fontSize: 13,
        padding: "0px !important",
    },
    modalContentSmall: {
        width: "40vw",
        height: "20vh",
        overflow: "auto",
        minWidth: "540px",
        fontSize: 13,
        padding: "0px !important",
    },
    modalContentSave: {
        width: 386,
    },
    modalButtonContainer: {
        marginTop: 24,
        width: "50%",
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    modalButtonContainer2: {
        marginTop: 24,
        display: "flex",
        justifyContent: "space-between",
    },
    modalButton: {
        width: 150,
    },
    modalToggle: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: -2,
    },
    modalTitle: {
        fontSize: 15,
        fontWeight: "bold",
        color: color.mediumYellow1,
        padding: "10px 0px",
    },
    modalInstructions: {
        color: color.darkestGray,
        fontSize: 16,
        marginBottom: 8,
    },
    modalLabel: {
        fontSize: 13,
        paddingTop: 24,
        paddingRight: 16,
    },
    modalLabelTotal: {
        fontSize: 15,
        fontWeight: "bold",
        paddingTop: 22,
    },
    table: {
        width: "100%",
        marginTop: 10,
        marginBottom: 20,
        borderCollapse: "collapse",
    },
    block: {
        display: "block",
    },
    scrollingTableBodyShort: {
        maxHeight: 300,
        overflowY: "auto",
    },
    scrollingTableBody: {
        maxHeight: 400,
        overflowY: "auto",
    },
    tableHeader: {
        fontSize: 13,
        color: color.mediumYellow1,
        padding: 10,
        fontWeight: 500,
        textAlign: "left",
        whiteSpace: "nowrap",
        cursor: "pointer",
        borderBottom: `1px solid ${color.mediumGray}`,
    },
    scrollingTableCellRates: {
        ":nth-child(1)": {
            minWidth: 260,
        },
        ":nth-child(2)": {
            minWidth: 120,
        },
        ":nth-child(3)": {
            minWidth: 120,
        },
        ":nth-child(4)": {
            minWidth: 120,
        },
        ":nth-child(5)": {
            minWidth: 120,
        },
    },
    scrollingTableCellAgeRated: {
        height: 38,
        ":first-child": {
            minWidth: 50,
        },
        ":nth-child(2)": {
            minWidth: 40,
        },
        ":nth-child(3)": {
            minWidth: 200,
        },
        ":nth-child(4)": {
            minWidth: 85,
        },
        ":nth-child(5)": {
            minWidth: 50,
        },
        ":nth-child(6)": {
            minWidth: 50,
        },
        ":nth-child(8)": {
            minWidth: 260,
        },
        ":last-child": {
            minWidth: 120,
        },
    },
    scrollingTableCell: {
        height: 38,
        ":first-child": {
            minWidth: 50,
        },
        ":nth-child(2)": {
            minWidth: 50,
        },
        ":nth-child(3)": {
            minWidth: 200,
        },
        ":nth-child(4)": {
            minWidth: 85,
        },
        ":nth-child(5)": {
            minWidth: 50,
        },
        ":nth-child(6)": {
            minWidth: 50,
        },
        ":last-child": {
            minWidth: 300,
        },
    },
    scrollingTableCellAbnormal: {
        backgroundColor: color.lightRed,
    },
    tableCell: {
        fontSize: 13,
        color: color.tableText,
        padding: "4px 8px",
        borderBottom: `1px solid ${color.tableBorder}`,
    },
    noWrap: {
        whiteSpace: "nowrap",
    },
    greenBar: {
        borderLeft: `4px solid ${color.mediumRed}`,
    },
    blueBar: {
        borderLeft: `4px solid ${color.borderBackgroundGray}`,
    },
    loading: {
        backgroundColor: color.white,
        color: color.tableText,
        padding: 20,
        marginBottom: 40,
    },
    noResults: {
        backgroundColor: color.white,
        color: color.darkRed,
        padding: 20,
        marginBottom: 40,
    },
    waiving: {
        paddingLeft: 20,
        textAlign: "center",
        color: color.red,
    },
    contain: {
        width: "100%",
        overflowX: "scroll",
    },
    submitButton: {
        minWidth: 200,
    },
    metalCircle: {
        height: "14px",
        width: "14px",
        borderRadius: "50%",
        display: "inline-block",
        marginLeft: "-4px",
        marginRight: "4px",
        textAlign: "center",
        fontSize: 13,
        fontWeight: "bold",
    },
    planTypeCarriers: {
        marginLeft: 6,
        fontSize: 11,
        color: color.mediumGray,
    },
    modalFavoriteContent: {
        width: 480,
        overflow: "auto",
        fontSize: 13,
        padding: "0px !important",
    },
    modalFavoriteButtonContainer: {
        marginTop: 24,
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    favoriteWarningText: {
        fontSize: 16,
        color: color.darkGray,
    },
    missingPlansList: {
        overflowY: "auto",
        maxHeight: 500,
    },
});

const strings = {
    effectiveDateFilterLabel: "Choose effective date",
    ratesFilterLabel: "Rates:",
    planNameFilterLabel: "Find Plans by search term",
    medicalContributionFilterLabel: "Medical Contribution Settings*",
    effectiveDatePlaceholder: "Effective Date",
    planNamePlaceholder: "Enter plan details",
    contributionSubtext: "* applies to base plan",
    carrierFilterLabel: "Carriers",
    planTypeFilterLabel: "Plan Type",
    networkFilterLabel: "Networks",
    tierFilterLabel: "Metal Tier",
    deductibleFilterLabel: "Deductible",
    coinsuranceInDFilterLabel: "Co-Ins",
    fundingTypeFilterLabel: "Funding Type",
    perioEndoTypeFilterLabel: "Perio/Endo",
    annualMaximumFilterLabel: "Annual Max",
    orthoFilterLabel: "Ortho",
    orthoMaximumFilterLabel: "Ortho Max",
    reimbursementFilterLabel: "Reimbursement",
    fillingsFilterLabel: "Fillings",
    implantsFilterLabel: "Implants",
    examCopayFilterLabel: "Exam Copay",
    materialCopayFilterLabel: "Materials Copay",
    frameAllowanceFilterLabel: "Frame Allowance",
    contactLensAllowanceFilterLabel: "Contact Lens Allowance",
    frameFrequencyFilterLabel: "Frequency",
    retinalImagingLabel: "Retinal Imaging",
    childPolycarbonateLabel: "Child Polycarbonate",
    coinsuranceFilterLabel: "Co-Insurance",
    oopFilterLabel: "OOP",
    employeePremiumFilterLabel: "Employee Premium",
    monthlyPremiumFilterLabel: "Total Monthly Premium",
    newSearch: "New Search",
    assignPlans: "Assign Plans & Add Rates",
    mapPlans: "Map Plans & Add Rates",
    continue: "Continue",
    cancelButtonText: "Cancel",
    saveButtonText: "Save",
    applyRatesButtonText: "Apply Rates",
    findSimilarToCurrent: "Find Plans similar to Current",
    spreadsheetNameInstructions: "Please name your spreadsheet. (max length 30)",
    spreadsheetNamePlaceholder: "Enter spreadsheet name",
};

const filterInputNames = {
    effectiveDate: "effectiveDate",
    planName: "planName",
    isAgeRated: "isAgeRated",
    isPercentageContribution: "isPercentageContribution",
    employeeContribution: "employeeContribution",
    dependentContribution: "dependentContribution",
    carriers: "carriers",
    networks: "networks",
    planTypes: "planTypes",
    tiers: "tiers",
    infertility: "infertility",
    hsa: "hsa",
    deductibleRange: "deductibleRange",
    coinsuranceRange: "coinsuranceRange",
    oopRange: "oopRange",
    employeePremiumRange: "employeePremiumRange",
    monthlyPremiumRange: "monthlyPremiumRange",
    packages: "packages",
    planTypesD: "planTypesD",
    coinsurancesD: "coinsurancesD",
    fundingTypesD: "fundingTypesD",
    deductiblesD: "deductiblesD",
    perioEndosD: "perioEndosD",
    annualMaximumsD: "annualMaximumsD",
    ortho: "ortho",
    orthoMaximumsD: "orthoMaximumsD",
    reimbursementsD: "reimbursementsD",
    fillingsD: "fillingsD",
    implantsD: "implantsD",
    examCopaysV: "examCopaysV",
    fundingTypesV: "fundingTypesV",
    materialCopaysV: "materialCopaysV",
    frameAllowancesV: "frameAllowancesV",
    contactLensAllowancesV: "contactLensAllowancesV",
    frameFrequenciesV: "frameFrequenciesV",
    retinalImagingV: "retinalImagingV",
    childPolycarbonateV: "childPolycarbonateV",
}

export class Plans extends Component {
    static propTypes = {
        quote: texchangePropTypes.quote.isRequired,
        coverageType: PropTypes.string.isRequired,
        planType: PropTypes.number.isRequired,
        setQuote: PropTypes.func.isRequired,
        setSelectedPlans: PropTypes.func.isRequired,
        selectedPlans: PropTypes.object.isRequired,
        setSelectedBasePlans: PropTypes.func.isRequired,
        selectedBasePlans: PropTypes.array,
        onContinue: PropTypes.func,
        saveSinglePlanSpreadsheet: PropTypes.func,
        carriers: PropTypes.object.isRequired,
        planTypes: PropTypes.array.isRequired,
        networks: PropTypes.object.isRequired,
        minMaxFilters: PropTypes.array.isRequired,
        planTypesD: PropTypes.array.isRequired,
        coinsurancesD: PropTypes.array.isRequired,
        deductiblesD: PropTypes.array.isRequired,
        perioEndosD: PropTypes.array.isRequired,
        annualMaximumsD: PropTypes.array.isRequired,
        orthoMaximumsD: PropTypes.array.isRequired,
        reimbursementsD: PropTypes.array.isRequired,
        examCopaysV: PropTypes.array.isRequired,
        fundingTypesV: PropTypes.array.isRequired,
        materialCopaysV: PropTypes.array.isRequired,
        frameAllowancesV: PropTypes.array.isRequired,
        contactLensAllowancesV: PropTypes.array.isRequired,
        frameFrequenciesV: PropTypes.array.isRequired,
        favoritePlans: PropTypes.array,
        favoritePlanMode: PropTypes.string,
        favoritePlanName: PropTypes.string,
        showAddReplacePlansModal: PropTypes.bool,
        setFavoritesApplied: PropTypes.func.isRequired,
        favoritesApplied: PropTypes.bool,
    };

    static defaultProps = {
        plans: [],
    };

    constructor(props) {
        super(props);

        this._allPlansLoaded = false;
        this._planContainerRef = React.createRef();
        const { quote } = this.props;
        const census = Object.assign([], quote.census);

        /*for (const c of census) {
            c.currentMedicalPlanId = c.currentMedicalPlanId ? c.currentMedicalPlanId : 0;
            c.currentMedicalCompositeRate = c.currentMedicalCompositeRate ? c.currentMedicalCompositeRate : 0;
            c.currentMedicalAgeRate = c.currentMedicalAgeRate ? c.currentMedicalAgeRate : 0;
            c.renewalMedicalPlanId = c.renewalMedicalPlanId ? c.renewalMedicalPlanId : 0;
            c.renewalMedicalCompositeRate = c.renewalMedicalCompositeRate ? c.renewalMedicalCompositeRate : 0;
            c.renewalMedicalAgeRate = c.renewalMedicalAgeRate ? c.renewalMedicalAgeRate : 0;
        }*/

        this.state = {
            showCurrentPlansModal: false,
            showRenewalPlansModal: false,
            showMore: false,
            api: null,
            plans: [],
            carriers: [],
            carriersCR: [],
            networks: [],
            networkDesignations: ["Broad", "Narrow"],
            networkDesignationsAFA: ["Broad", "Narrow", "Indemnity"],
            planTypes: [],
            planTypesCR: [],
            tiers: [
                { id: 3, name: "Bronze" },
                { id: 5, name: "Exp. Bronze" },
                { id: 2, name: "Silver" },
                { id: 1, name: "Gold" },
                { id: 4, name: "Platinum" },
            ],
            infertility: ["No Infertility plans"],
            hsa: ["Only HSA plans"],
            planTypesD: ["PPO", "DHMO"],
            fundingTypesD: ["Employer Paid", "Voluntary"],
            coinsurancesD: ["100/100/60", "100/90/60", "100/80/50"],
            deductiblesD: ["0/0", "25/25", "25/50", "50/100"],
            perioEndosD: ["Basic", "Major"],
            annualMaximumsD: ["1000", "1500", "2000", "2500", "3000+"],
            ortho: ["Child", "None"],
            orthoMaximumsD: ["1000", "1500", "2000+"],
            reimbursementsD: ["MAC", "80th UCR", "85th UCR", "90th UCR"],
            fillingsD: ["Standard", "Composite"],
            implantsD: ["Included", "None"],
            examCopaysV: ["0", "10", "15", "25"],
            fundingTypesV: ["Employer Paid", "Voluntary"],
            materialCopaysV: ["10", "15", "25"],
            frameAllowancesV: ["100", "130", "150", "180", "200"],
            contactLensAllowancesV: ["130", "160", "200"],
            frameFrequenciesV: ["12/12/12", "12/12/24"],
            retinalImagingV: ["Included", "None"],
            childPolycarbonateV: ["Included", "None"],
            isLoading: true,
            isUpdating: false,
            selectedPlans: [],
            selectedBasePlans: [],
            selectedCarrier: "",
            selectedNetwork: "",
            selectedPlanType: "",
            selectedTier: "",
            selectedDeductible: "",
            filter: {
                [filterInputNames.effectiveDate]: null,
                [filterInputNames.planName]: "",
                [filterInputNames.isAgeRated]: false,
                [filterInputNames.isPercentageContribution]: true,
                [filterInputNames.employeeContribution]: "",
                [filterInputNames.dependentContribution]: "",
                [filterInputNames.carriers]: [],
                [filterInputNames.networks]: [],
                [filterInputNames.planTypes]: [],
                [filterInputNames.tiers]: [],
                [filterInputNames.infertility]: [],
                [filterInputNames.hsa]: [],
                [filterInputNames.deductibleRange]: [],
                [filterInputNames.coinsuranceRange]: [],
                [filterInputNames.oopRange]: [],
                [filterInputNames.employeePremiumRange]: [],
                [filterInputNames.monthlyPremiumRange]: [],
                [filterInputNames.packages]: [],
                [filterInputNames.planTypesD]: [],
                [filterInputNames.coinsurancesD]: [],
                [filterInputNames.fundingTypesD]: [],
                [filterInputNames.deductiblesD]: [],
                [filterInputNames.perioEndosD]: [],
                [filterInputNames.annualMaximumsD]: [],
                [filterInputNames.ortho]: [],
                [filterInputNames.orthoMaximumsD]: [],
                [filterInputNames.reimbursementsD]: [],
                [filterInputNames.fillingsD]: [],
                [filterInputNames.implantsD]: [],
                [filterInputNames.examCopaysV]: [],
                [filterInputNames.fundingTypesV]: [],
                [filterInputNames.materialCopaysV]: [],
                [filterInputNames.frameAllowancesV]: [],
                [filterInputNames.contactLensAllowancesV]: [],
                [filterInputNames.frameFrequenciesV]: [],
                [filterInputNames.retinalImagingV]: [],
                [filterInputNames.childPolycarbonateV]: [],
            },
            census,
            currentMedicalPlans: [],
            renewalMedicalPlans: [],
            currentDentalPlans: [],
            renewalDentalPlans: [],
            currentVisionPlans: [],
            renewalVisionPlans: [],
            manualPlanId: "",
            manualPlanName: "",
            showManualModal: false,
            manualEE: "",
            manualES: "",
            manualEC: "",
            manualEF: "",
            deductibleMin: 0,
            deductibleMax: 100000,
            coinsuranceMin: 0,
            coinsuranceMax: 100,
            oopMin: 0,
            oopMax: 100000,
            employeePremiumMin: 0,
            employeePremiumMax: 100000,
            monthlyPremiumMin: 0,
            monthlyPremiumMax: 100000,
            plansButtonContainerBottom: 0,
            allChecked: false,
            medicalPlans: [],
            dentalPlans: [],
            visionPlans: [],
            lifePlans: [],
            allPlans: [],
            selectedCurrentPlanId: "",
            spreadsheetName: "",
            showSaveSpreadsheetModal: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.carriers[this.props.coverageType] !== prevProps.carriers[this.props.coverageType]) {
            this.setState({
                carriers: this.props.carriers[this.props.coverageType],
            });
        }

        if (this.props.networks[this.props.coverageType] !== prevProps.networks[this.props.coverageType]) {
            this.setState({
                networks: this.props.networks[this.props.coverageType],
            });
        }

        if (prevProps.favoritePlans !== this.props.favoritePlans) {
            const { planType } = this.props;
            if (planType === planTypes.newPlan) {
                if (!this.props.favoritesApplied) {
                    this._showAddOrReplacePlansModal();
                } else {
                    this.setState({
                        showAddReplacePlansModal: false,
                    });
                }
            }
        }

        if (prevProps.favoritesApplied !== this.props.favoritesApplied && this.props.favoritesApplied) {
            this.setState({
                showAddReplacePlansModal: false,
            });
        }

        if (this.props.minMaxFilters !== prevProps.minMaxFilters) {
            const deductibleMinMax = this.props.minMaxFilters.find(item => item.name === "deductible");
            const coinsuranceMinMax = this.props.minMaxFilters.find(item => item.name === "coinsurance");
            const oopMinMax = this.props.minMaxFilters.find(item => item.name === "oop");
            const employeePremiumMinMax = this.props.minMaxFilters.find(item => item.name === "employeePremium");
            const monthlyPremiumMinMax = this.props.minMaxFilters.find(item => item.name === "monthlyPremium");
            //dental
            const employeePremiumMinMaxD = this.props.minMaxFilters.find(item => item.name === "employeePremiumD");
            const monthlyPremiumMinMaxD = this.props.minMaxFilters.find(item => item.name === "monthlyPremiumD");
            //vision
            const employeePremiumMinMaxV = this.props.minMaxFilters.find(item => item.name === "employeePremiumV");
            const monthlyPremiumMinMaxV = this.props.minMaxFilters.find(item => item.name === "monthlyPremiumV");

            let
                deductibleMin = null,
                deductibleMax = null,
                coinsuranceMin = null,
                coinsuranceMax = null,
                oopMin = null,
                oopMax = null,
                employeePremiumMin = null,
                employeePremiumMax = null,
                monthlyPremiumMin = null,
                monthlyPremiumMax = null,
                employeePremiumMinD = null,
                employeePremiumMaxD = null,
                monthlyPremiumMinD = null,
                monthlyPremiumMaxD = null,
                employeePremiumMinV = null,
                employeePremiumMaxV = null,
                monthlyPremiumMinV = null,
                monthlyPremiumMaxV = null;

            if (deductibleMinMax) {
                deductibleMin = deductibleMinMax.min;
                deductibleMax = deductibleMinMax.max;
            }

            if (coinsuranceMinMax) {
                coinsuranceMin = coinsuranceMinMax.min;
                coinsuranceMax = coinsuranceMinMax.max;
            }

            if (oopMinMax) {
                oopMin = oopMinMax.min;
                oopMax = oopMinMax.max;
            }

            if (employeePremiumMinMax) {
                employeePremiumMin = employeePremiumMinMax.min;
                employeePremiumMax = employeePremiumMinMax.max;
            }
            if (employeePremiumMinMaxD) {
                employeePremiumMinD = employeePremiumMinMaxD.min;
                employeePremiumMaxD = employeePremiumMinMaxD.max;
            }
            if (employeePremiumMinMaxV) {
                employeePremiumMinV = employeePremiumMinMaxV.min;
                employeePremiumMaxV = employeePremiumMinMaxV.max;
            }

            if (monthlyPremiumMinMax) {
                monthlyPremiumMin = monthlyPremiumMinMax.min;
                monthlyPremiumMax = monthlyPremiumMinMax.max;
            }
            if (monthlyPremiumMinMaxD) {
                monthlyPremiumMinD = monthlyPremiumMinMaxD.min;
                monthlyPremiumMaxD = monthlyPremiumMinMaxD.max;
            }
            if (monthlyPremiumMinMaxV) {
                monthlyPremiumMinV = monthlyPremiumMinMaxV.min;
                monthlyPremiumMaxV = monthlyPremiumMinMaxV.max;
            }

            this.setState({
                deductibleMin,
                deductibleMax,
                coinsuranceMin,
                coinsuranceMax,
                oopMin,
                oopMax,
                employeePremiumMin,
                employeePremiumMax,
                employeePremiumMinD,
                employeePremiumMaxD,
                employeePremiumMinV,
                employeePremiumMaxV,
                monthlyPremiumMin,
                monthlyPremiumMax,
                monthlyPremiumMinD,
                monthlyPremiumMaxD,
                monthlyPremiumMinV,
                monthlyPremiumMaxV,
            }, () => {
                this._getPlans();
            });

            /*if (!this._allPlansLoaded) {
                this._allPlansLoaded = true;

                this._getPlansByType("medical")
                    .then(medicalPlans => {
                        this.setState({ medicalPlans });
                    });

                this._getPlansByType("dental")
                    .then(dentalPlans => {
                        this.setState({ dentalPlans });
                    });

                this._getPlansByType("vision")
                    .then(visionPlans => {
                        this.setState({ visionPlans });
                    });

                this._getPlansByType("life")
                    .then(lifePlans => {
                        this.setState({ lifePlans });
                    });      
            }*/
        }

        const { planType, coverageType, selectedPlans } = this.props;

        //selected current, renewal and new plans
        let selectedPlanIds = [];
        if (planType === planTypes.newPlan) {
            if (this.props.selectedPlans !== prevProps.selectedPlans && selectedPlans[coverageType]) {
                selectedPlanIds = selectedPlans[coverageType].map(p => {
                    return p.id;
                });
                this.setState({ selectedPlanIds });
            }
        } else {
            const planTypeText = planType === planTypes.renewalPlan ? "renewal" : "current";
            const selectedPlansPropertyName = `${planTypeText}${util.capitalize(this.props.coverageType)}Plans`;

            if (this.props.quote[selectedPlansPropertyName].length !== prevProps.quote[selectedPlansPropertyName].length) {
                selectedPlanIds = this.props.quote[`${planTypeText}${util.capitalize(coverageType)}Plans`].map(p => {
                    return p.id;
                });
                this.setState({
                    selectedPlans: selectedPlanIds
                });
            }
        }

        //keep census mappings
        if (this.props.quote.census !== prevProps.quote.census) {
            this.setState({
                census: this.props.quote.census,
            });
        }
    }

    componentDidMount() {
        const { quote, planType, coverageType, selectedPlans } = this.props;

        this.setState(prevState => {
            return {
                filter: {
                    ...prevState.filter,
                    quoteId: quote.id,
                    [filterInputNames.isAgeRated]: quote.areaId === 7 || quote.areaId === 23 || quote.areaId === 32 ? true : false,
                },
                currentMedicalPlans: quote.currentMedicalPlans,
                renewalMedicalPlans: quote.renewalMedicalPlans,
                currentDentalPlans: quote.currentDentalPlans,
                renewalDentalPlans: quote.renewalDentalPlans,
                currentVisionPlans: quote.currentVisionPlans,
                renewalVisionPlans: quote.renewalVisionPlans,
            };
        });

        let selectedPlanIds = [];
        let selectedBasePlanIds = [];
        if (planType === planTypes.newPlan) {
            //load previously selected plans
            const previousPlans = quote[`new${util.capitalize(coverageType)}Plans`];
            if (previousPlans && previousPlans.length > 0) {
                this.props.setSelectedPlans(selectedPlans, previousPlans, coverageType);
                selectedPlanIds = previousPlans.map(p => {
                    return p.id;
                });
            } else if (selectedPlans[coverageType]) {
                selectedPlanIds = selectedPlans[coverageType].map(p => {
                    return p.id;
                });
            }
        } else {
            const planTypeText = planType === planTypes.renewalPlan ? "renewal" : "current";
            selectedPlanIds = quote[`${planTypeText}${util.capitalize(coverageType)}Plans`].map(p => {
                return p.id;
            });
            selectedBasePlanIds = quote[`${planTypeText}${util.capitalize(coverageType)}Plans`].filter(p => p.isBase).map(p => {
                return p.id;
            });
        }

        this.setState({
            selectedPlans: selectedPlanIds,
            selectedBasePlans: selectedBasePlanIds
        });

        window.addEventListener("scroll", this._handleScroll, false);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this._handleScroll);
    }

    _showAddOrReplacePlansModal = () => {
        this.setState({
            showAddReplacePlansModal: true,
        });
    };

    _hideAddOrReplacePlansModal = () => {
        this.props.setFavoritesApplied(true);
        this.setState({
            showAddReplacePlansModal: false,
        });
    };

    _renderFavoriteActionWarning = () => {
        const { quote, favoritePlans, favoritePlanMode, planType, coverageType } = this.props;
        const { allPlans, showAddReplacePlansModal } = this.state;

        //don't load this until user selects favorites
        if (!showAddReplacePlansModal) {
            return;
        }

        //need to load plans
        if (allPlans.length === 0) {
            //need to load ALL plans
            import(`../../lib/api/${coverageType}Plans`)
                .then(api => {
                    api.getPagedForQuote(1, null, null, quote.id, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, true)
                        .then(plans => {
                            this.setState({
                                allPlans: plans,
                            });
                        })
                });
        }

        //Return a loading message
        if (allPlans.length === 0) {
            return (
                <div className={css(styles.favoriteWarningText)}>
                    <div>Matching favorites...</div>
                </div>
            );
        }

        //Format real message
        let planCount = 0;
        let recordCount = 0;
        const foundPlans = [];
        let missingPlans = [];

        favoritePlans.forEach(item => {
            if (recordCount === 0) {
                recordCount = item.recordCount;
            }
            let planId = null;
            if (item.medicalPlanId && coverageType === coverageTypes.medical) {
                planId = parseInt(item.medicalPlanId);
            } else if (item.dentalPlanId && coverageType === coverageTypes.dental) {
                planId = parseInt(item.dentalPlanId);
            } else if (item.visionPlanId && coverageType === coverageTypes.vision) {
                planId = parseInt(item.visionPlanId);
            }

            if (planId && !isNaN(planId)) {
                if (planType === planTypes.newPlan && this.props.selectedPlans[coverageType]) {
                    const plan = allPlans.find(p => p.id === planId);
                    if (plan) {
                        planCount++;
                        if (foundPlans.indexOf(item.planName) === -1) {
                            foundPlans.push(item.planName);
                        }
                    } else {
                        if (missingPlans.indexOf(item.planName) === -1) {
                            missingPlans.push(item.planName);
                        }
                    }
                }
            }
        });

        //cleanup of missing plans
        missingPlans = missingPlans.filter(function (e) {
            return !foundPlans.includes(e);
        });

        return (
            <div className={css(styles.favoriteWarningText)}>
                {favoritePlanMode === "add" ? (
                    <div>
                        All of your currently selected plans are still selected.
                        In addition, {planCount} out of {recordCount} favorite plans have been selected.
                    </div>
                ) : (
                    <div>
                        All of your currently selected plans have been deselected and replaced by {planCount} out of {recordCount} favorite plans.
                    </div>
                )}
                {missingPlans.length > 0 ? (
                    <div>
                        The following favorite plans were not returned in this quote and were not selected:
                    </div>
                ) : null}
                <div>
                    <ul className={css(styles.missingPlansList)}>
                        {missingPlans.map((item, index) => (
                            <li key={index}>
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    };

    _getPlansByType = coverageType => {
        return new Promise(resolve => {
            const { quote } = this.props;
            const { filter } = this.state;
            const filterCarriers = filter[filterInputNames.carriers].length > 0 ? filter[filterInputNames.carriers].join() : null;
            const filterNetworks = filter[filterInputNames.networks].length > 0 ? filter[filterInputNames.networks].join() : null;
            const filterPlanTypes = coverageType === coverageTypes.dental && filter[filterInputNames.planTypesD].length > 0 ? filter[filterInputNames.planTypesD].join() : filter[filterInputNames.planTypes].length > 0 ? filter[filterInputNames.planTypes].join() : null;
            const filterTiers = filter[filterInputNames.tiers].length > 0 ? filter[filterInputNames.tiers].join() : null;
            const filterDeductibles = coverageType === coverageTypes.dental && filter[filterInputNames.deductiblesD].length > 0 ? filter[filterInputNames.deductiblesD].join() : (filter[filterInputNames.deductibleRange] && filter[filterInputNames.deductibleRange].length === 2) ? filter[filterInputNames.deductibleRange].join() : null;
            const filterCoinsurance = coverageType === coverageTypes.dental && filter[filterInputNames.coinsurancesD].length > 0 ? filter[filterInputNames.coinsurancesD].join() : (filter[filterInputNames.coinsuranceRange] && filter[filterInputNames.coinsuranceRange].length === 2) ? filter[filterInputNames.coinsuranceRange].join() : null;
            const filterOOP = (filter[filterInputNames.oopRange] && filter[filterInputNames.oopRange].length === 2) ? filter[filterInputNames.oopRange].join() : null;
            const filterEmployeePremium = (filter[filterInputNames.employeePremiumRange] && filter[filterInputNames.employeePremiumRange].length === 2) ? filter[filterInputNames.employeePremiumRange].join() : null;
            const filterMonthlyPremium = (filter[filterInputNames.monthlyPremiumRange] && filter[filterInputNames.monthlyPremiumRange].length === 2) ? filter[filterInputNames.monthlyPremiumRange].join() : null;
            const filterPackages = filter[filterInputNames.packages].length > 0 ? filter[filterInputNames.packages].join() : null;
            const filterInfertility = filter[filterInputNames.infertility].length > 0 ? filter[filterInputNames.infertility].join() : null;
            const filterHSA = filter[filterInputNames.hsa].length > 0 ? filter[filterInputNames.hsa].join() : null;
            //dental/vision
            const denVis1 = coverageType === coverageTypes.dental && filter[filterInputNames.fundingTypesD].length > 0 ? filter[filterInputNames.fundingTypesD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.fundingTypesV].length > 0 ? filter[filterInputNames.fundingTypesV].join() : null;
            const denVis2 = coverageType === coverageTypes.dental && filter[filterInputNames.perioEndosD].length > 0 ? filter[filterInputNames.perioEndosD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.examCopaysV].length > 0 ? filter[filterInputNames.examCopaysV].join() : null;
            const denVis3 = coverageType === coverageTypes.dental && filter[filterInputNames.annualMaximumsD].length > 0 ? filter[filterInputNames.annualMaximumsD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.materialCopaysV].length > 0 ? filter[filterInputNames.materialCopaysV].join() : null;
            const denVis4 = coverageType === coverageTypes.dental && filter[filterInputNames.orthoMaximumsD].length > 0 ? filter[filterInputNames.orthoMaximumsD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.frameAllowancesV].length > 0 ? filter[filterInputNames.frameAllowancesV].join() : null;
            const denVis5 = coverageType === coverageTypes.dental && filter[filterInputNames.ortho].length > 0 ? filter[filterInputNames.ortho].join() : coverageType === coverageTypes.vision && filter[filterInputNames.contactLensAllowancesV].length > 0 ? filter[filterInputNames.contactLensAllowancesV].join() : null;
            const denVis6 = coverageType === coverageTypes.dental && filter[filterInputNames.reimbursementsD].length > 0 ? filter[filterInputNames.reimbursementsD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.frameFrequenciesV].length > 0 ? filter[filterInputNames.frameFrequenciesV].join() : null;
            const denVis7 = coverageType === coverageTypes.dental && filter[filterInputNames.fillingsD].length > 0 ? filter[filterInputNames.fillingsD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.retinalImagingV].length > 0 ? filter[filterInputNames.retinalImagingV].join() : null;
            const denVis8 = coverageType === coverageTypes.dental && filter[filterInputNames.implantsD].length > 0 ? filter[filterInputNames.implantsD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.childPolycarbonateV].length > 0 ? filter[filterInputNames.childPolycarbonateV].join() : null;
            //api
            import(`../../lib/api/${coverageType}Plans`)
                .then(api => {
                    api.getPagedForQuote(0, null, null, quote.id, filterCarriers, filterNetworks, filterPlanTypes, filterTiers, filterDeductibles, filterCoinsurance, filterOOP, filterEmployeePremium, filterMonthlyPremium, filter[filterInputNames.planName], filterPackages, denVis1, denVis2, denVis3, denVis4, denVis5, denVis6, denVis7, denVis8, filterInfertility, filterHSA)
                        .then(plans => {
                            resolve(plans);
                        })
                });
        });
    }

    _addFavoritePlans = () => {
        const { favoritePlans, favoritePlanMode, planType } = this.props;
        const { allPlans } = this.state;

        let selectedNewMedical = [];
        let selectedNewDental = [];
        let selectedNewVision = [];
        let selectedNewLife = [];

        if (favoritePlanMode === "add") {
            selectedNewMedical = Object.assign([], this.props.selectedPlans["medical"]);
            selectedNewDental = Object.assign([], this.props.selectedPlans["dental"]);
            selectedNewVision = Object.assign([], this.props.selectedPlans["vision"]);
            selectedNewLife = Object.assign([], this.props.selectedPlans["life"]);
        }

        if (planType === planTypes.newPlan) {
            favoritePlans.forEach(item => {
                let planId = null;
                let coverageType = ""
                if (item.medicalPlanId) {
                    coverageType = "medical";
                    planId = parseInt(item.medicalPlanId);
                } else if (item.dentalPlanId) {
                    coverageType = "dental";
                    planId = parseInt(item.dentalPlanId);
                } else if (item.visionPlanId) {
                    coverageType = "vision";
                    planId = parseInt(item.visionPlanId);
                } else if (item.lifePlanId) {
                    coverageType = "life";
                    planId = parseInt(item.lifePlanId);
                }

                if (planId && !isNaN(planId)) {
                    if (planType === planTypes.newPlan && this.props.selectedPlans[coverageType]) {
                        const plan = allPlans.find(p => p.id === planId);
                        if (plan) {
                            switch (coverageType) {
                                case "medical":
                                    if (!selectedNewMedical.find(p => p.id === planId)) {
                                        selectedNewMedical.push(plan);
                                    }
                                    break;
                                case "dental":
                                    if (!selectedNewDental.find(p => p.id === planId)) {
                                        selectedNewDental.push(plan);
                                    }
                                    break;
                                case "vision":
                                    if (!selectedNewVision.find(p => p.id === planId)) {
                                        selectedNewVision.push(plan);
                                    }
                                    break;
                                case "life":
                                    if (!selectedNewLife.find(p => p.id === planId)) {
                                        selectedNewLife.push(plan);
                                    }
                                    break;
                            }
                        }
                    }
                }
            });
        }

        if (planType === planTypes.newPlan) {
            this.props.setSelectedPlans(this.props.selectedPlans, selectedNewMedical, "medical");
            this.props.setSelectedPlans(this.props.selectedPlans, selectedNewDental, "dental");
            this.props.setSelectedPlans(this.props.selectedPlans, selectedNewVision, "vision");
            this.props.setSelectedPlans(this.props.selectedPlans, selectedNewLife, "life");
        }

        this.props.setFavoritesApplied(true);
        this.setState({
            showAddReplacePlansModal: false,
        });
    }

    _handleScroll = () => {
        this._setPlansButtonContainerPosition();
    };

    _setPlansButtonContainerPosition = () => {
        const footerHeight = 126;
        /*const footerHeight = 226; --removed 100pc from footer*/
        const verticalOffset = document.body.clientHeight - document.documentElement.scrollTop - window.innerHeight;

        let plansButtonContainerBottom = 0;

        if (verticalOffset > footerHeight) {
            plansButtonContainerBottom = 0;
        } else {
            plansButtonContainerBottom = footerHeight - verticalOffset + 20;
        }
        this.setState({
            plansButtonContainerBottom,
        });
    };

    _getPlans = () => {
        const { quote, planType, coverageType } = this.props;
        if (planType === planTypes.newPlan && (coverageType === coverageTypes.medical || coverageType === coverageTypes.dental || coverageType === coverageTypes.vision || coverageType === coverageTypes.life)) {
            //filters...
            const { filter } = this.state;
            const filterCarriers = filter[filterInputNames.carriers].length > 0 ? filter[filterInputNames.carriers].join() : null;
            const filterNetworks = filter[filterInputNames.networks].length > 0 ? filter[filterInputNames.networks].join() : null;
            const filterPlanTypes = coverageType === coverageTypes.dental && filter[filterInputNames.planTypesD].length > 0 ? filter[filterInputNames.planTypesD].join() : filter[filterInputNames.planTypes].length > 0 ? filter[filterInputNames.planTypes].join() : null;
            const filterTiers = filter[filterInputNames.tiers].length > 0 ? filter[filterInputNames.tiers].join() : null;
            const filterDeductibles = coverageType === coverageTypes.dental && filter[filterInputNames.deductiblesD].length > 0 ? filter[filterInputNames.deductiblesD].join() : (filter[filterInputNames.deductibleRange] && filter[filterInputNames.deductibleRange].length === 2) ? filter[filterInputNames.deductibleRange].join() : null;
            const filterCoinsurance = coverageType === coverageTypes.dental && filter[filterInputNames.coinsurancesD].length > 0 ? filter[filterInputNames.coinsurancesD].join() : (filter[filterInputNames.coinsuranceRange] && filter[filterInputNames.coinsuranceRange].length === 2) ? filter[filterInputNames.coinsuranceRange].join() : null;
            const filterOOP = (filter[filterInputNames.oopRange] && filter[filterInputNames.oopRange].length === 2) ? filter[filterInputNames.oopRange].join() : null;
            const filterEmployeePremium = (filter[filterInputNames.employeePremiumRange] && filter[filterInputNames.employeePremiumRange].length === 2) ? filter[filterInputNames.employeePremiumRange].join() : null;
            const filterMonthlyPremium = (filter[filterInputNames.monthlyPremiumRange] && filter[filterInputNames.monthlyPremiumRange].length === 2) ? filter[filterInputNames.monthlyPremiumRange].join() : null;
            const filterPackages = filter[filterInputNames.packages].length > 0 ? filter[filterInputNames.packages].join() : null;
            const filterInfertility = filter[filterInputNames.infertility].length > 0 ? filter[filterInputNames.infertility].join() : null;
            const filterHSA = filter[filterInputNames.hsa].length > 0 ? filter[filterInputNames.hsa].join() : null;
            //dental/vision
            const denVis1 = coverageType === coverageTypes.dental && filter[filterInputNames.fundingTypesD].length > 0 ? filter[filterInputNames.fundingTypesD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.fundingTypesV].length > 0 ? filter[filterInputNames.fundingTypesV].join() : null;
            const denVis2 = coverageType === coverageTypes.dental && filter[filterInputNames.perioEndosD].length > 0 ? filter[filterInputNames.perioEndosD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.examCopaysV].length > 0 ? filter[filterInputNames.examCopaysV].join() : null;
            const denVis3 = coverageType === coverageTypes.dental && filter[filterInputNames.annualMaximumsD].length > 0 ? filter[filterInputNames.annualMaximumsD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.materialCopaysV].length > 0 ? filter[filterInputNames.materialCopaysV].join() : null;
            const denVis4 = coverageType === coverageTypes.dental && filter[filterInputNames.orthoMaximumsD].length > 0 ? filter[filterInputNames.orthoMaximumsD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.frameAllowancesV].length > 0 ? filter[filterInputNames.frameAllowancesV].join() : null;
            const denVis5 = coverageType === coverageTypes.dental && filter[filterInputNames.ortho].length > 0 ? filter[filterInputNames.ortho].join() : coverageType === coverageTypes.vision && filter[filterInputNames.contactLensAllowancesV].length > 0 ? filter[filterInputNames.contactLensAllowancesV].join() : null;
            const denVis6 = coverageType === coverageTypes.dental && filter[filterInputNames.reimbursementsD].length > 0 ? filter[filterInputNames.reimbursementsD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.frameFrequenciesV].length > 0 ? filter[filterInputNames.frameFrequenciesV].join() : null;
            const denVis7 = coverageType === coverageTypes.dental && filter[filterInputNames.fillingsD].length > 0 ? filter[filterInputNames.fillingsD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.retinalImagingV].length > 0 ? filter[filterInputNames.retinalImagingV].join() : null;
            const denVis8 = coverageType === coverageTypes.dental && filter[filterInputNames.implantsD].length > 0 ? filter[filterInputNames.implantsD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.childPolycarbonateV].length > 0 ? filter[filterInputNames.childPolycarbonateV].join() : null;
            //api
            import(`../../lib/api/${coverageType}Plans`)
                .then(api => {
                    api.getPagedForQuote(1, null, null, quote.id, filterCarriers, filterNetworks, filterPlanTypes, filterTiers, filterDeductibles, filterCoinsurance, filterOOP, filterEmployeePremium, filterMonthlyPremium, filter[filterInputNames.planName], filterPackages, denVis1, denVis2, denVis3, denVis4, denVis5, denVis6, denVis7, denVis8, filterInfertility, filterHSA)
                        .then(plans => {
                            this.setState({
                                plans,
                                isLoading: false,
                                isUpdating: false,
                                showMore: plans.length > 0 && plans[0].recordCount > 100,
                            });
                        });
                });
        } else {
            const { selectedCarrier } = this.state;
            //year
            let year = new Date(quote.effectiveDate).getFullYear();
            if (planType === planTypes.currentPlan) {
                year = year - 1;
            }
            //filters
            dataApi.getCarriersFilterCR(quote.id, planType === planTypes.currentPlan ? 1 : 0, coverageType)
                .then(response => {
                    this.setState({
                        carriersCR: response.lob,
                    });
                });
            dataApi.getMedicalPlanTypesFilter(0)
                .then(response => {
                    let filtered = response;
                    if (selectedCarrier) {
                        filtered = filtered.filter(p => p.startsWith(selectedCarrier + "|" + year + "|"));
                    }
                    this.setState({
                        planTypesCR: filtered,
                    });
                });
            //api
            import(`../../lib/api/${coverageType}Plans`)
                .then(api => {
                    const { selectedCarrier, selectedNetwork, selectedPlanType, selectedTier, selectedDeductible, filter } = this.state;
                    api.getPaged(1, null, null, quote.id, year, selectedCarrier, selectedNetwork, selectedPlanType, selectedTier, selectedDeductible, filter[filterInputNames.planName], null, null).then(plans => {
                        this.setState({
                            api,
                            plans,
                            isLoading: false,
                            isUpdating: false,
                            showMore: plans.length > 0 && plans[0].recordCount > 100,
                        });
                    });
                });
        }
    };

    _changeCarrier = (item, lob) => {
        let networks = [];
        if (lob === "medical") {
            networks = this.props.networks.medical.filter(p => p.carrierId === parseInt(item.value));
        } else if (lob === "dental") {
            networks = this.props.networks.dental.filter(p => p.carrierId === parseInt(item.value));
        } else if (lob === "vision") {
            networks = this.props.networks.vision.filter(p => p.carrierId === parseInt(item.value));
        } else if (lob === "life") {
            networks = this.props.networks.life.filter(p => p.carrierId === parseInt(item.value));
        }
        this.setState({
            allChecked: false,
            selectedCarrier: item.value,
            networks,
            selectedNetwork: "",
            selectedPlanType: "",
            isUpdating: true,
        }, () => {
            this._getPlans();
        });
    };

    _changeNetwork = item => {
        this.setState({
            allChecked: false,
            selectedNetwork: item.value,
            isUpdating: true,
        }, () => {
            this._getPlans();
        });
    };

    _changePlanType = item => {
        this.setState({
            allChecked: false,
            selectedPlanType: item.value,
            isUpdating: true,
        }, () => {
            this._getPlans();
        });
    };

    _changeTier = item => {
        this.setState({
            allChecked: false,
            selectedTier: item.value,
            isUpdating: true,
        }, () => {
            this._getPlans();
        });
    };

    _changeDeductible = item => {
        this.setState({
            allChecked: false,
            selectedDeductible: item.value,
            isUpdating: true,
        }, () => {
            this._getPlans();
        });
    };

    _effectiveDateChanged = date => {
        this.setState(prevState => {
            return {
                allChecked: false,
                filter: {
                    ...prevState.filter,
                    [filterInputNames.effectiveDate]: date,
                },
            };
        });
    };

    _handleFilterTextChanged = e => {
        const { name } = e.target;
        let { value } = e.target;

        //save values to quote
        if (name.includes("Contribution") && value) {
            const { quote } = this.props;
            const { filter } = this.state;

            if (isNaN(parseInt(value))) { value = 0; }

            if (filter[filterInputNames.isPercentageContribution]) {
                value = parseInt(value);
                if (value < 0) { value = 0; }
                else if (value > 100) { value = 100; }
                quote[name] = value + "%";
            } else {
                if (value.indexOf('.') === -1) { value = parseInt(value); }
                else {
                    if (value.split('.').length === 2) {
                        if (value.split('.')[1] === "0") {
                            value = parseFloat(value) + ".0";
                        } else {
                            value = parseFloat(value).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                        }
                    } else {
                        value = parseFloat(value);
                    }
                }
                quote[name] = "$" + value;
            }
        }
        //update state
        this.setState(prevState => {
            return {
                allChecked: false,
                filter: {
                    ...prevState.filter,
                    [name]: value,
                },
            };
        }, () => {
            if (name === filterInputNames.planName && (value.length === 0 || value.length > 2)) {
                this.setState({ isUpdating: true });
                setTimeout(() => {
                    this._getPlans();
                }, 750);
            }
        });
    }

    _isAgeRatedChanged = value => {
        this.setState(prevState => {
            return {
                allChecked: false,
                filter: {
                    ...prevState.filter,
                    [filterInputNames.isAgeRated]: value,
                },
            };
        });
    };

    _isPercentageContributionChanged = value => {
        //fix any saved values if they exist
        const { quote } = this.props;
        if (quote["employeeContribution"]) {
            if (value) {
                quote["employeeContribution"] = quote["employeeContribution"].replace("$", "") + "%";
            } else {
                quote["employeeContribution"] = "$" + quote["employeeContribution"].replace("%", "");
            }
        }
        if (quote["dependentContribution"]) {
            if (value) {
                quote["dependentContribution"] = quote["dependentContribution"].replace("$", "") + "%";
            } else {
                quote["dependentContribution"] = "$" + quote["dependentContribution"].replace("%", "");
            }
        }
        //update state
        this.setState(prevState => {
            return {
                allChecked: false,
                filter: {
                    ...prevState.filter,
                    [filterInputNames.isPercentageContribution]: value,
                },
            };
        });
    };

    _changeSelectedCurrentPlanId = item => {
        //find match...
        const { tiers, deductibleMin, deductibleMax, coinsuranceMin, coinsuranceMax, oopMin, oopMax } = this.state;
        const { quote, planTypes } = this.props;
        const plan = quote["currentMedicalPlans"].find(p => p.id === parseInt(item.value));

        //found a match?
        if (plan) {
            //going to clear any selected carriers below...

            //plan type... only the generic ones
            const planTypeArray = [];
            let planType = plan.planTypeName ? plan.planTypeName : null;
            //reduce to the simplest form... if applicable
            if (planType) {
                if (planType.indexOf("HMO") > -1) {
                    planType = "HMO";
                } else if (planType.indexOf("PPO") > -1) {
                    planType = "PPO";
                } else if (planType.indexOf("EPO") > -1) {
                    planType = "EPO";
                } else if (planType.indexOf("POS") > -1) {
                    planType = "POS";
                }
            }

            //check to see if the results include this plan type...
            if (planType && planType.length === 3 && planTypes && planTypes.length > 0) {
                Object.keys(planTypes).forEach(key => {
                    let split = planTypes[key].split('|');
                    if (planType === "PPO" || planType === "POS") {
                        if (split[3] === "PPO" || split[3] === "POS") {
                            planTypeArray.push(split[3]);
                        } else if (split[3].indexOf("PPO") > -1 || split[3].indexOf("POS") > -1) {
                            planTypeArray.push(split[3]);
                        }
                    } else {
                        if (split[3] === planType) {
                            planTypeArray.push(split[3]);
                        } else if (split[3].indexOf(planType) > -1) {
                            planTypeArray.push(split[3]);
                        }
                    }
                });
            }

            /*
            //metallic level
            let metallicLevel = plan.metallicLevelId ? plan.metallicLevelId.toString() : null;
            //check to see if the results include this tier...
            if (!tiers.find(t => t.id.toString() === metallicLevel)) { metallicLevel = null; }
            */

            //deductible
            const planDed = plan.individualDeductibleIn ? Number(plan.individualDeductibleIn.replace(/[^0-9.-]+/g, "")) : 0;
            //calculate
            const dedMin = planDed - 1000 < deductibleMin ? deductibleMin : planDed - 1000;
            const dedMax = planDed + 1000 > deductibleMax ? deductibleMax : planDed + 1000;

            //coinsurance...
            const planCoins = plan.coInsuranceIn ? plan.coInsuranceIn * 100 : 0;
            //calculate
            const coinsMin = planCoins - 10 < coinsuranceMin ? coinsuranceMin : planCoins - 10;
            const coinsMax = planCoins + 10 > coinsuranceMax ? coinsuranceMax : planCoins + 10;

            //oop
            const planOop = plan.individualOOPIn ? Number(plan.individualOOPIn.replace(/[^0-9.-]+/g, "")) : 0;
            //calculate
            const opMin = planOop - 1000 < oopMin ? oopMin : planOop - 1000;
            const opMax = planOop + 1000 > oopMax ? oopMax : planOop + 1000;

            //pre-set the spreadsheet name
            const spreadsheetNm = ("Similar to " + plan.displayName.substr(0, 19)).trim();

            //update state
            this.setState(prevState => {
                return {
                    filter: {
                        ...prevState.filter,
                        [filterInputNames.carriers]: [],
                        [filterInputNames.packages]: [],
                        [filterInputNames.planTypes]: planTypeArray,
                        //[filterInputNames.tiers]: [metallicLevel],
                        [filterInputNames.deductibleRange]: [dedMin, dedMax],
                        [filterInputNames.coinsuranceRange]: [coinsMin, coinsMax],
                        [filterInputNames.oopRange]: [opMin, opMax],
                    },
                    selectedCurrentPlanId: item.value,
                    spreadsheetName: spreadsheetNm,
                    allChecked: false,
                };
            }, () => {
                this._getPlans();
            });
        } else {
            //reset
            this.setState(prevState => {
                return {
                    filter: {
                        ...prevState.filter,
                        [filterInputNames.carriers]: [],
                        [filterInputNames.packages]: [],
                        [filterInputNames.planTypes]: [],
                        [filterInputNames.tiers]: [],
                        [filterInputNames.deductibleRange]: [],
                        [filterInputNames.coinsuranceRange]: [],
                        [filterInputNames.oopRange]: [],
                    },
                    selectedCurrentPlanId: null,
                    spreadsheetName: "",
                    allChecked: false,
                };
            }, () => {
                this._getPlans();
            });
        }
    };

    _rowClicked = id => {
        const { selectedPlans, selectedBasePlans } = this.state;
        const { planType, quote, coverageType } = this.props;

        // in case save fails
        const origSelectedPlans = selectedPlans;
        const origSelectedBasePlans = selectedBasePlans;

        let add = true;
        if (selectedPlans.indexOf(id) > -1) {
            // Remove
            add = false;
            const filtered = selectedPlans.filter(function (value) {
                return value !== id;
            });
            const filteredB = selectedBasePlans.filter(function (value) {
                return value !== id;
            });
            this.setState({
                selectedPlans: filtered,
                selectedBasePlans: filteredB,
            });
        } else {
            // Add
            const joined = selectedPlans.concat(id);
            this.setState({
                selectedPlans: joined,
            });
        }

        if (planType === planTypes.newPlan && this.props.selectedPlans[coverageType]) {
            const selectedNewPlans = Object.assign([], this.props.selectedPlans[coverageType]);
            const plan = this.state.plans.find(p => p.id === parseInt(id));
            if (plan) {
                const selectedPlan = selectedNewPlans.find(p => p.id === id);
                if (!selectedPlan) {
                    selectedNewPlans.push(plan);
                } else {
                    const index = selectedNewPlans.indexOf(selectedPlan);
                    if (index > -1) {
                        selectedNewPlans.splice(index, 1);
                    }
                }

                this.props.setSelectedPlans(this.props.selectedPlans, selectedNewPlans, coverageType);
            }
        } else {
            quoteApi.saveCurrentRenewal(quote.id, id.toString(), coverageType, add, planType === 1, null)
                .then(saved => {
                    if (!saved) {
                        this.setState({
                            selectedPlans: origSelectedPlans,
                            selectedBasePlans: origSelectedBasePlans,
                        });
                    }
                    // reload - updated
                    quoteApi.getById(quote.id)
                        .then(response => {
                            this.props.setQuote(response);
                            this.setState({
                                currentMedicalPlans: response.currentMedicalPlans,
                                renewalMedicalPlans: response.renewalMedicalPlans,
                                currentDentalPlans: response.currentDentalPlans,
                                renewalDentalPlans: response.renewalDentalPlans,
                                currentVisionPlans: response.currentVisionPlans,
                                renewalVisionPlans: response.renewalVisionPlans
                            });
                        });
                });
        }
    };

    _allClicked = () => {
        const { quote, planType, coverageType } = this.props;
        const add = !this.state.allChecked;
        if (planType === planTypes.newPlan && this.props.selectedPlans[coverageType]) {
            //loading
            this.setState({
                isUpdating: true,
            });

            //save selected plans
            const selectedNewPlansProps = Object.assign([], this.props.selectedPlans[coverageType]);
            const selectedNewPlansState = Object.assign([], this.state.selectedPlans);

            //need to get ALL matching IDs...  not just the page * 100 loaded currently
            const { filter } = this.state;
            const filterCarriers = filter[filterInputNames.carriers].length > 0 ? filter[filterInputNames.carriers].join() : null;
            const filterNetworks = filter[filterInputNames.networks].length > 0 ? filter[filterInputNames.networks].join() : null;
            const filterPlanTypes = coverageType === coverageTypes.dental && filter[filterInputNames.planTypesD].length > 0 ? filter[filterInputNames.planTypesD].join() : filter[filterInputNames.planTypes].length > 0 ? filter[filterInputNames.planTypes].join() : null;
            const filterTiers = filter[filterInputNames.tiers].length > 0 ? filter[filterInputNames.tiers].join() : null;
            const filterDeductibles = coverageType === coverageTypes.dental && filter[filterInputNames.deductiblesD].length > 0 ? filter[filterInputNames.deductiblesD].join() : (filter[filterInputNames.deductibleRange] && filter[filterInputNames.deductibleRange].length === 2) ? filter[filterInputNames.deductibleRange].join() : null;
            const filterCoinsurance = coverageType === coverageTypes.dental && filter[filterInputNames.coinsurancesD].length > 0 ? filter[filterInputNames.coinsurancesD].join() : (filter[filterInputNames.coinsuranceRange] && filter[filterInputNames.coinsuranceRange].length === 2) ? filter[filterInputNames.coinsuranceRange].join() : null;
            const filterOOP = (filter[filterInputNames.oopRange] && filter[filterInputNames.oopRange].length === 2) ? filter[filterInputNames.oopRange].join() : null;
            const filterEmployeePremium = (filter[filterInputNames.employeePremiumRange] && filter[filterInputNames.employeePremiumRange].length === 2) ? filter[filterInputNames.employeePremiumRange].join() : null;
            const filterMonthlyPremium = (filter[filterInputNames.monthlyPremiumRange] && filter[filterInputNames.monthlyPremiumRange].length === 2) ? filter[filterInputNames.monthlyPremiumRange].join() : null;
            const filterPackages = filter[filterInputNames.packages].length > 0 ? filter[filterInputNames.packages].join() : null;
            const filterInfertility = filter[filterInputNames.infertility].length > 0 ? filter[filterInputNames.infertility].join() : null;
            const filterHSA = filter[filterInputNames.hsa].length > 0 ? filter[filterInputNames.hsa].join() : null;
            //dental/vision
            const denVis1 = coverageType === coverageTypes.dental && filter[filterInputNames.fundingTypesD].length > 0 ? filter[filterInputNames.fundingTypesD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.fundingTypesV].length > 0 ? filter[filterInputNames.fundingTypesV].join() : null;
            const denVis2 = coverageType === coverageTypes.dental && filter[filterInputNames.perioEndosD].length > 0 ? filter[filterInputNames.perioEndosD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.examCopaysV].length > 0 ? filter[filterInputNames.examCopaysV].join() : null;
            const denVis3 = coverageType === coverageTypes.dental && filter[filterInputNames.annualMaximumsD].length > 0 ? filter[filterInputNames.annualMaximumsD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.materialCopaysV].length > 0 ? filter[filterInputNames.materialCopaysV].join() : null;
            const denVis4 = coverageType === coverageTypes.dental && filter[filterInputNames.orthoMaximumsD].length > 0 ? filter[filterInputNames.orthoMaximumsD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.frameAllowancesV].length > 0 ? filter[filterInputNames.frameAllowancesV].join() : null;
            const denVis5 = coverageType === coverageTypes.dental && filter[filterInputNames.ortho].length > 0 ? filter[filterInputNames.ortho].join() : coverageType === coverageTypes.vision && filter[filterInputNames.contactLensAllowancesV].length > 0 ? filter[filterInputNames.contactLensAllowancesV].join() : null;
            const denVis6 = coverageType === coverageTypes.dental && filter[filterInputNames.reimbursementsD].length > 0 ? filter[filterInputNames.reimbursementsD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.frameFrequenciesV].length > 0 ? filter[filterInputNames.frameFrequenciesV].join() : null;
            const denVis7 = coverageType === coverageTypes.dental && filter[filterInputNames.fillingsD].length > 0 ? filter[filterInputNames.fillingsD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.retinalImagingV].length > 0 ? filter[filterInputNames.retinalImagingV].join() : null;
            const denVis8 = coverageType === coverageTypes.dental && filter[filterInputNames.implantsD].length > 0 ? filter[filterInputNames.implantsD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.childPolycarbonateV].length > 0 ? filter[filterInputNames.childPolycarbonateV].join() : null;
            //api
            import(`../../lib/api/${coverageType}Plans`)
                .then(lobApi => {
                    lobApi.getPagedForQuote(1, null, null, quote.id, filterCarriers, filterNetworks, filterPlanTypes, filterTiers, filterDeductibles, filterCoinsurance, filterOOP, filterEmployeePremium, filterMonthlyPremium, filter[filterInputNames.planName], filterPackages, denVis1, denVis2, denVis3, denVis4, denVis5, denVis6, denVis7, denVis8, filterInfertility, filterHSA, true).then(plans => {
                        plans.map(plan => {
                            //handle New plan state object
                            if (add && selectedNewPlansState.indexOf(plan.id) === -1) {
                                selectedNewPlansState.push(plan);
                            } else {
                                const index = selectedNewPlansState.indexOf(plan.id);
                                if (index > -1) {
                                    selectedNewPlansState.splice(index, 1);
                                }
                            }
                            //handle NEW plan props object
                            const selectedPlan = selectedNewPlansProps.find(p => p.id === plan.id);
                            if (add && !selectedPlan) {
                                selectedNewPlansProps.push(plan);
                            } else if (add && selectedPlan) {
                                //do nothing...
                            } else {
                                const index = selectedNewPlansProps.indexOf(selectedPlan);
                                if (index > -1) {
                                    selectedNewPlansProps.splice(index, 1)
                                }
                            }
                            return null;
                        });
                        this.setState({
                            selectedPlans: selectedNewPlansState,
                            isUpdating: false,
                        });
                        this.props.setSelectedPlans(this.props.selectedPlans, selectedNewPlansProps, coverageType);
                    });
                });
        }

        //update toggle
        this.setState({
            allChecked: add,
        });
    };

    _basePlanClicked = id => {
        const { selectedPlans, selectedBasePlans } = this.state;
        const { planType, quote, coverageType } = this.props;

        // in case save fails
        const origSelectedBasePlans = selectedBasePlans;

        //continue if this is already selected
        if (selectedPlans.indexOf(id) > -1 && planType !== planTypes.newPlan) {
            let add = true;
            //handle base plans
            if (selectedBasePlans.indexOf(id) > -1) {
                // Remove
                add = false;
                const filtered = selectedBasePlans.filter(function (value) {
                    return value !== id;
                });
                this.setState({
                    selectedBasePlans: filtered,
                });
            } else {
                // Add
                this.setState({
                    selectedBasePlans: [id],
                });
            }

            quoteApi.saveCurrentRenewal(quote.id, id.toString(), coverageType, true, planType === 1, add)
                .then(saved => {
                    if (!saved) {
                        this.setState({
                            selectedBasePlans: origSelectedBasePlans,
                        });
                    }
                    quoteApi.getById(quote.id)
                        .then(response => {
                            this.props.setQuote(response);
                        });
                });
        }
    };

    _manualClicked = (id, name) => {
        //check to see if there are rates to edit
        const { plans } = this.state;
        const plan = plans.find(p => p.id === id);

        this.setState({
            showManualModal: true,
            manualPlanId: id,
            manualPlanName: name,
            manualEE: plan.ee ? this._checkNumber(plan.ee) : "",
            manualES: plan.es ? this._checkNumber(plan.es) : "",
            manualEC: plan.ec ? this._checkNumber(plan.ec) : "",
            manualEF: plan.ef ? this._checkNumber(plan.ef) : "",
        });
    }

    _setManualRate = (e, field) => {
        const val = this._checkNumber(e.target.value);

        //save
        if (field === "EE") {
            this.setState({
                manualEE: val,
            });
        } else if (field === "ES") {
            this.setState({
                manualES: val,
            });
        } else if (field === "EC") {
            this.setState({
                manualEC: val,
            });
        } else if (field === "EF") {
            this.setState({
                manualEF: val,
            });
        }
    };

    _saveManualCompositeRates = () => {
        const { quote, coverageType } = this.props;
        const { selectedPlans, manualPlanId, manualEE, manualES, manualEC, manualEF, census } = this.state;

        if (!manualEE || !manualES || !manualEC || !manualEF) {
            return;
        }

        if (manualPlanId) {
            //calculate the monthly
            const eeCount = census.filter(x => x.relationship === "EE" && x.medicalCoverageId === 1).length;
            const esCount = census.filter(x => x.relationship === "EE" && x.medicalCoverageId === 2).length;
            const ecCount = census.filter(x => x.relationship === "EE" && x.medicalCoverageId === 3).length;
            const efCount = census.filter(x => x.relationship === "EE" && x.medicalCoverageId === 4).length;
            const manualMthly = (eeCount * manualEE) + (esCount * manualES) + (ecCount * manualEC) + (efCount * manualEF);

            quoteApi.saveManualCompositeRates(quote.id, coverageType, manualPlanId, manualMthly, manualEE, manualES, manualEC, manualEF)
                .then(() => {
                    //update selected plans?
                    if (selectedPlans.indexOf(manualPlanId) === -1) {
                        this._rowClicked(manualPlanId);
                    }
                    //hide modal and reload page
                    this.setState({ showManualModal: false });
                    quoteApi.getById(quote.id)
                        .then(response => {
                            this.props.setQuote(response);
                        });
                    setTimeout(() => {
                        //update the matching selected plan
                        const matchingPlan = this.props.selectedPlans && this.props.selectedPlans.medical ? this.props.selectedPlans.medical.find(x => x.id === manualPlanId) : null;
                        if (matchingPlan) {
                            matchingPlan.ee = '$' + manualEE.toString();
                            matchingPlan.es = '$' + manualES.toString();
                            matchingPlan.ec = '$' + manualEC.toString();
                            matchingPlan.ef = '$' + manualEF.toString();
                            matchingPlan.monthlyPremium = '$' + manualMthly.toString();
                        }
                        //reload plans
                        this._getPlans();
                    }, 750);
                });
        }
    };

    _onScroll = (page, col, dir) => {
        const { planType, quote, coverageType } = this.props;
        const { api, plans, selectedCarrier, selectedNetwork, selectedPlanType, selectedTier, selectedDeductible, filter } = this.state;
        const loadedRecords = ((page - 1) * 100);

        if (loadedRecords < plans[0].recordCount) {
            this.setState({
                showMore: true,
            });

            if (planType === planTypes.newPlan) {
                const filterCarriers = filter[filterInputNames.carriers].length > 0 ? filter[filterInputNames.carriers].join() : null;
                const filterNetworks = filter[filterInputNames.networks].length > 0 ? filter[filterInputNames.networks].join() : null;
                const filterPlanTypes = coverageType === coverageTypes.dental && filter[filterInputNames.planTypesD].length > 0 ? filter[filterInputNames.planTypesD].join() : filter[filterInputNames.planTypes].length > 0 ? filter[filterInputNames.planTypes].join() : null;
                const filterTiers = filter[filterInputNames.tiers].length > 0 ? filter[filterInputNames.tiers].join() : null;
                const filterDeductibles = coverageType === coverageTypes.dental && filter[filterInputNames.deductiblesD].length > 0 ? filter[filterInputNames.deductiblesD].join() : (filter[filterInputNames.deductibleRange] && filter[filterInputNames.deductibleRange].length === 2) ? filter[filterInputNames.deductibleRange].join() : null;
                const filterCoinsurance = coverageType === coverageTypes.dental && filter[filterInputNames.coinsurancesD].length > 0 ? filter[filterInputNames.coinsurancesD].join() : (filter[filterInputNames.coinsuranceRange] && filter[filterInputNames.coinsuranceRange].length === 2) ? filter[filterInputNames.coinsuranceRange].join() : null;
                const filterOOP = (filter[filterInputNames.oopRange] && filter[filterInputNames.oopRange].length === 2) ? filter[filterInputNames.oopRange].join() : null;
                const filterEmployeePremium = (filter[filterInputNames.employeePremiumRange] && filter[filterInputNames.employeePremiumRange].length === 2) ? filter[filterInputNames.employeePremiumRange].join() : null;
                const filterMonthlyPremium = (filter[filterInputNames.monthlyPremiumRange] && filter[filterInputNames.monthlyPremiumRange].length === 2) ? filter[filterInputNames.monthlyPremiumRange].join() : null;
                const filterPackages = filter[filterInputNames.packages].length > 0 ? filter[filterInputNames.packages].join() : null;
                const filterInfertility = filter[filterInputNames.infertility].length > 0 ? filter[filterInputNames.infertility].join() : null;
                const filterHSA = filter[filterInputNames.hsa].length > 0 ? filter[filterInputNames.hsa].join() : null;
                //dental/vision
                const denVis1 = coverageType === coverageTypes.dental && filter[filterInputNames.fundingTypesD].length > 0 ? filter[filterInputNames.fundingTypesD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.fundingTypesV].length > 0 ? filter[filterInputNames.fundingTypesV].join() : null;
                const denVis2 = coverageType === coverageTypes.dental && filter[filterInputNames.perioEndosD].length > 0 ? filter[filterInputNames.perioEndosD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.examCopaysV].length > 0 ? filter[filterInputNames.examCopaysV].join() : null;
                const denVis3 = coverageType === coverageTypes.dental && filter[filterInputNames.annualMaximumsD].length > 0 ? filter[filterInputNames.annualMaximumsD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.materialCopaysV].length > 0 ? filter[filterInputNames.materialCopaysV].join() : null;
                const denVis4 = coverageType === coverageTypes.dental && filter[filterInputNames.orthoMaximumsD].length > 0 ? filter[filterInputNames.orthoMaximumsD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.frameAllowancesV].length > 0 ? filter[filterInputNames.frameAllowancesV].join() : null;
                const denVis5 = coverageType === coverageTypes.dental && filter[filterInputNames.ortho].length > 0 ? filter[filterInputNames.ortho].join() : coverageType === coverageTypes.vision && filter[filterInputNames.contactLensAllowancesV].length > 0 ? filter[filterInputNames.contactLensAllowancesV].join() : null;
                const denVis6 = coverageType === coverageTypes.dental && filter[filterInputNames.reimbursementsD].length > 0 ? filter[filterInputNames.reimbursementsD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.frameFrequenciesV].length > 0 ? filter[filterInputNames.frameFrequenciesV].join() : null;
                const denVis7 = coverageType === coverageTypes.dental && filter[filterInputNames.fillingsD].length > 0 ? filter[filterInputNames.fillingsD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.retinalImagingV].length > 0 ? filter[filterInputNames.retinalImagingV].join() : null;
                const denVis8 = coverageType === coverageTypes.dental && filter[filterInputNames.implantsD].length > 0 ? filter[filterInputNames.implantsD].join() : coverageType === coverageTypes.vision && filter[filterInputNames.childPolycarbonateV].length > 0 ? filter[filterInputNames.childPolycarbonateV].join() : null;
                //api
                import(`../../lib/api/${coverageType}Plans`)
                    .then(lobApi => {
                        lobApi.getPagedForQuote(page, col, dir, quote.id, filterCarriers, filterNetworks, filterPlanTypes, filterTiers, filterDeductibles, filterCoinsurance, filterOOP, filterEmployeePremium, filterMonthlyPremium, filter[filterInputNames.planName], filterPackages, denVis1, denVis2, denVis3, denVis4, denVis5, denVis6, denVis7, denVis8, filterInfertility, filterHSA).then(result => {
                            let joined = {};
                            if (page === 1) {
                                joined = result;
                            } else {
                                joined = this.state.plans.concat(result);
                            }
                            this.setState({
                                plans: joined,
                                showMore: joined.length > 0 && result[0] && result[0].recordCount > loadedRecords,
                            });

                            this._setPlansButtonContainerPosition();
                        });
                    });
            } else {
                let year = new Date(quote.effectiveDate).getFullYear();
                if (planType === planTypes.currentPlan) {
                    year = year - 1;
                }
                api.getPaged(page, col, dir, quote.id, year, selectedCarrier, selectedNetwork, selectedPlanType, selectedTier, selectedDeductible, filter[filterInputNames.planName], null, null).then(result => {
                    let joined = {};
                    if (page === 1) {
                        joined = result;
                    } else {
                        joined = this.state.plans.concat(result);
                    }
                    this.setState({
                        plans: joined,
                        showMore: joined.length > 0 && result[0] && result[0].recordCount > loadedRecords,
                    });

                    this._setPlansButtonContainerPosition();
                });
            }
        } else {
            this.setState({
                showMore: false,
            });
        }
    };

    _handleFilterCheckboxChanged = e => {
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;
        const currentOptions = this.state.filter[name];

        //handle carrier/package selection --------------------------------------------
        if (val && name === "packages") {
            //make sure parent is included
            const carrierOptions = this.state.filter["carriers"];
            const parentCarrier = this.state.carriers.find(p => p.packages && p.packages.indexOf(value) > -1);
            if (parentCarrier && parentCarrier.id && !carrierOptions.includes(parentCarrier.id.toString())) {
                carrierOptions.push(parentCarrier.id.toString());
                this.setState(prevState => {
                    return {
                        allChecked: false,
                        filter: {
                            ...prevState.filter,
                            ["carriers"]: carrierOptions,
                        },
                    };
                });
            }
        }
        if (!val && name === "carriers") {
            //make sure children Packages are removed
            let packageOptions = this.state.filter["packages"];
            const thisCarrier = this.state.carriers.find(p => p.id.toString() === value && p.packages);
            if (thisCarrier && (packageOptions.length === 1 && thisCarrier.packages.split(',').indexOf(packageOptions[0]) > -1) || (packageOptions.length > 1 && packageOptions.some(r => thisCarrier.packages.split(',').indexOf(r)))) {
                const thisPackages = thisCarrier.packages.split(',');
                packageOptions = packageOptions.filter((pkg) => !thisPackages.includes(pkg));
                this.setState(prevState => {
                    return {
                        allChecked: false,
                        filter: {
                            ...prevState.filter,
                            ["packages"]: packageOptions,
                        },
                    };
                });
            }
            //reset PlanTypes to empty
            let planTypeOptions = this.state.filter["planTypes"];
            if (thisCarrier && planTypeOptions.length > 0) {
                this.setState(prevState => {
                    return {
                        filter: {
                            ...prevState.filter,
                            ["planTypes"]: [],
                        },
                    };
                });
            }
        }
        //end - handle carrier/package selection -------------------------------------

        //keep options selected
        if (val) {
            currentOptions.push(value);
        } else {
            const index = currentOptions.indexOf(value);
            if (index > -1) {
                currentOptions.splice(index, 1)
            }
        }

        this.setState(prevState => {
            return {
                allChecked: false,
                filter: {
                    ...prevState.filter,
                    [name]: currentOptions,
                },
                isUpdating: true,
            };
        }, () => {
            this._getPlans();
        });
    };

    _newSearchClicked = () => {
        this.setState({
            allChecked: false,
            selectedCarrier: "",
            selectedNetwork: "",
            selectedPlanType: "",
            selectedTier: "",
            selectedDeductible: "",
            filter: {
                [filterInputNames.effectiveDate]: null,
                [filterInputNames.planName]: "",
                [filterInputNames.isAgeRated]: false,
                [filterInputNames.isPercentageContribution]: true,
                [filterInputNames.employeeContribution]: "",
                [filterInputNames.dependentContribution]: "",
                [filterInputNames.carriers]: [],
                [filterInputNames.networks]: [],
                [filterInputNames.planTypes]: [],
                [filterInputNames.tiers]: [],
                [filterInputNames.infertility]: [],
                [filterInputNames.hsa]: [],
                [filterInputNames.deductibleRange]: [],
                [filterInputNames.coinsuranceRange]: [],
                [filterInputNames.oopRange]: [],
                [filterInputNames.employeePremiumRange]: [],
                [filterInputNames.monthlyPremiumRange]: [],
                [filterInputNames.packages]: [],
                [filterInputNames.planTypesD]: [],
                [filterInputNames.coinsurancesD]: [],
                [filterInputNames.fundingTypesD]: [],
                [filterInputNames.deductiblesD]: [],
                [filterInputNames.perioEndosD]: [],
                [filterInputNames.annualMaximumsD]: [],
                [filterInputNames.ortho]: [],
                [filterInputNames.orthoMaximumsD]: [],
                [filterInputNames.reimbursementsD]: [],
                [filterInputNames.fillingsD]: [],
                [filterInputNames.implantsD]: [],
                [filterInputNames.examCopaysV]: [],
                [filterInputNames.fundingTypesV]: [],
                [filterInputNames.materialCopaysV]: [],
                [filterInputNames.frameAllowancesV]: [],
                [filterInputNames.contactLensAllowancesV]: [],
                [filterInputNames.frameFrequenciesV]: [],
                [filterInputNames.retinalImagingV]: [],
                [filterInputNames.childPolycarbonateV]: [],
            },
            isUpdating: true,
        }, () => {
            this._getPlans();
        });
    };

    _assignPlansClicked = () => {
        const { planType } = this.props;
        if (planType === planTypes.currentPlan) {
            this.setState({
                showCurrentPlansModal: true,
            });
        } else {
            this.setState({
                showRenewalPlansModal: true,
            });
        }
    };

    _setCurrentMedicalPlanId = (e, index) => {
        const { census, filter, currentMedicalPlans } = this.state;
        const employee = census[index];
        employee.currentMedicalPlanId = parseInt(e.value);
        const match = currentMedicalPlans.find(plan => plan.id === parseInt(e.value));
        if (match) {
            if (filter[filterInputNames.isAgeRated]) {
                //age rated...  lookup
                if (match.ageRates && employee.age && employee.medicalCoverageId < 5) {
                    let age = employee.age - 1;
                    let spot = age <= 14 ? 0 : age >= 64 ? 50 : age - 14;
                    employee.currentMedicalAgeRate = match.ageRates.split(',')[spot];
                    //search for spouse
                    let spouseCount = 0;
                    if (employee.spouseAge && (employee.medicalCoverage === "ES" || employee.medicalCoverage === "EF")) {
                        const spouse = census[index + 1];
                        if (spouse && spouse.relationship === "SP" && spouse.age) {
                            spouseCount = 1;
                            age = spouse.age - 1;
                            spot = age <= 14 ? 0 : age >= 65 ? 50 : age - 14;
                            spouse.currentMedicalAgeRate = match.ageRates.split(',')[spot];
                        }
                    }
                    //search for children
                    if (employee.numberOfChildren > 0 && (employee.medicalCoverage === "EC" || employee.medicalCoverage === "EF")) {
                        //auto-populate three children under 21
                        const children = sortBy(census.slice(index + spouseCount + 1, index + spouseCount + 1 + employee.numberOfChildren + 1), c => c.age).reverse();
                        let auto = 0;
                        for (let i = 0; i <= employee.numberOfChildren; i++) {
                            const child = children[i];
                            if (child && child.relationship === "C" && child.age >= 0) {
                                age = child.age - 1;
                                if (age > 20 || (age <= 20 && auto < 3)) {
                                    if (age <= 20) { auto++; }
                                    spot = age <= 14 ? 0 : age - 14;
                                    child.currentMedicalAgeRate = match.ageRates.split(',')[spot];
                                }
                            }
                        }
                    }
                }
            } else {
                //composite
                if (employee.medicalCoverage === "EE" && match.currentMedicalCompositeEERate > 0) {
                    employee.currentMedicalCompositeRate = match.currentMedicalCompositeEERate;
                } else if (census[index].medicalCoverage === "ES" && match.currentMedicalCompositeESRate > 0) {
                    employee.currentMedicalCompositeRate = match.currentMedicalCompositeESRate;
                } else if (census[index].medicalCoverage === "EC" && match.currentMedicalCompositeECRate > 0) {
                    employee.currentMedicalCompositeRate = match.currentMedicalCompositeECRate;
                } else if (census[index].medicalCoverage === "EF" && match.currentMedicalCompositeEFRate > 0) {
                    employee.currentMedicalCompositeRate = match.currentMedicalCompositeEFRate;
                }
            }
        }
        this.setState({ census: Object.assign([], census) });
    };

    _setCurrentDentalPlanId = (e, index) => {
        const { census, currentDentalPlans } = this.state;
        const employee = census[index];
        employee.currentDentalPlanId = parseInt(e.value);
        const match = currentDentalPlans.find(plan => plan.id === parseInt(e.value));
        if (match) {
            if (employee.dentalCoverage === "EE" && match.currentDentalCompositeEERate > 0) {
                employee.currentDentalCompositeRate = match.currentDentalCompositeEERate;
            } else if (employee.dentalCoverage === "ES" && match.currentDentalCompositeESRate > 0) {
                employee.currentDentalCompositeRate = match.currentDentalCompositeESRate;
            } else if (employee.dentalCoverage === "EC" && match.currentDentalCompositeECRate > 0) {
                employee.currentDentalCompositeRate = match.currentDentalCompositeECRate;
            } else if (employee.dentalCoverage === "EF" && match.currentDentalCompositeEFRate > 0) {
                employee.currentDentalCompositeRate = match.currentDentalCompositeEFRate;
            }
        }
        this.setState({ census: Object.assign([], census) });
    };

    _setCurrentVisionPlanId = (e, index) => {
        const { census, currentVisionPlans } = this.state;
        const employee = census[index];
        employee.currentVisionPlanId = parseInt(e.value);
        const match = currentVisionPlans.find(plan => plan.id === parseInt(e.value));
        if (match) {
            if (employee.VisionCoverage === "EE" && match.currentVisionCompositeEERate > 0) {
                employee.currentVisionCompositeRate = match.currentVisionCompositeEERate;
            } else if (employee.VisionCoverage === "ES" && match.currentVisionCompositeESRate > 0) {
                employee.currentVisionCompositeRate = match.currentVisionCompositeESRate;
            } else if (employee.VisionCoverage === "EC" && match.currentVisionCompositeECRate > 0) {
                employee.currentVisionCompositeRate = match.currentVisionCompositeECRate;
            } else if (employee.VisionCoverage === "EF" && match.currentVisionCompositeEFRate > 0) {
                employee.currentVisionCompositeRate = match.currentVisionCompositeEFRate;
            }
        }
        this.setState({ census: Object.assign([], census) });
    };

    _checkNumber = val => {
        let rpl = val.replace(/[^0-9.]+/g, "");
        if (parseFloat(rpl).toString()) {
            let split = rpl.split('.');
            if (split) {
                if (split.length === 2 && split[1].length > 2) {
                    return split[0] + '.' + split[1].substr(0, 2);
                } else if (split.length > 2) {
                    return split[0] + '.' + split[1];
                }
            }
            return rpl;
        } else {
            return null;
        }
    }

    _setCurrentMedicalAgeRate = (e, index, planId) => {
        //test for valid number
        const val = this._checkNumber(e.target.value);
        const { census } = this.state;
        census[index].currentMedicalAgeRate = val;
        if (planId) {
            census[index].currentMedicalPlanId = planId;
        }
        this.setState({ census: Object.assign([], census) });
    };

    _setCurrentMedicalCompositeRate = (e, p, rel) => {
        //test for valid number
        const val = this._checkNumber(e.target.value);
        const { currentMedicalPlans } = this.state;
        const match = currentMedicalPlans.find(plan => plan.id === p.id);
        if (rel === "EE") {
            match.currentMedicalCompositeEERate = val;
        } else if (rel === "ES") {
            match.currentMedicalCompositeESRate = val;
        } else if (rel === "EC") {
            match.currentMedicalCompositeECRate = val;
        } else if (rel === "EF") {
            match.currentMedicalCompositeEFRate = val;
        }

        this.setState({
            census: this.state.census.map(
                person => person.currentMedicalPlanId === p.id && person.medicalCoverage === rel
                    ? { ...person, currentMedicalCompositeRate: val, }
                    : person,
            ),
            currentMedicalPlans: Object.assign([], currentMedicalPlans)
        });
    };

    _setCurrentDentalCompositeRate = (e, p, rel) => {
        //test for valid number
        const val = this._checkNumber(e.target.value);
        const { currentDentalPlans } = this.state;
        const match = currentDentalPlans.find(plan => plan.id === p.id);
        if (rel === "EE") {
            match.currentDentalCompositeEERate = val;
        } else if (rel === "ES") {
            match.currentDentalCompositeESRate = val;
        } else if (rel === "EC") {
            match.currentDentalCompositeECRate = val;
        } else if (rel === "EF") {
            match.currentDentalCompositeEFRate = val;
        }

        this.setState({
            census: this.state.census.map(
                person => person.currentDentalPlanId === p.id && person.dentalCoverage === rel
                    ? { ...person, currentDentalCompositeRate: val, }
                    : person,
            ),
            currentDentalPlans: Object.assign([], currentDentalPlans)
        });
    };

    _setCurrentVisionCompositeRate = (e, p, rel) => {
        //test for valid number
        const val = this._checkNumber(e.target.value);
        const { currentVisionPlans } = this.state;
        const match = currentVisionPlans.find(plan => plan.id === p.id);
        if (rel === "EE") {
            match.currentVisionCompositeEERate = val;
        } else if (rel === "ES") {
            match.currentVisionCompositeESRate = val;
        } else if (rel === "EC") {
            match.currentVisionCompositeECRate = val;
        } else if (rel === "EF") {
            match.currentVisionCompositeEFRate = val;
        }

        this.setState({
            census: this.state.census.map(
                person => person.currentVisionPlanId === p.id && person.VisionCoverage === rel
                    ? { ...person, currentVisionCompositeRate: val, }
                    : person,
            ),
            currentVisionPlans: Object.assign([], currentVisionPlans)
        });
    };

    _setRenewalMedicalPlanId = (e, c, index) => {
        const { census, filter, currentMedicalPlans, renewalMedicalPlans } = this.state;
        currentMedicalPlans[index].renewalMedicalPlanId = parseInt(e.value);
        this.setState({ currentMedicalPlans: Object.assign([], currentMedicalPlans) });
        //set the renewal plan id on each employee
        const match = renewalMedicalPlans.find(plan => plan.id === parseInt(e.value));
        if (match) {
            for (let index = 0; index < census.length; index++) {
                const employee = census[index];
                if (employee && employee.relationship === "EE" && employee.currentMedicalPlanId === c.id) {
                    employee.renewalMedicalPlanId = match.id;
                    //grab the age rate if we have it
                    if (filter[filterInputNames.isAgeRated] && match.ageRates && employee.age) {
                        //age rated...  lookup
                        let age = employee.age;
                        let spot = age <= 14 ? 0 : age >= 64 ? 50 : age - 14;
                        employee.renewalMedicalAgeRate = match.ageRates.split(',')[spot];
                        //search for spouse
                        let spouseCount = 0;
                        if (employee.spouseAge && (employee.medicalCoverage === "ES" || employee.medicalCoverage === "EF")) {
                            const spouse = census[index + 1];
                            if (spouse && spouse.relationship === "SP" && spouse.age) {
                                spouseCount = 1;
                                age = spouse.age;
                                spot = age <= 14 ? 0 : age >= 65 ? 50 : age - 14;
                                spouse.renewalMedicalAgeRate = match.ageRates.split(',')[spot];
                            }
                        }
                        //search for children
                        if (employee.numberOfChildren > 0 && (employee.medicalCoverage === "EC" || employee.medicalCoverage === "EF")) {
                            //auto-populate three children under 21
                            const children = sortBy(census.slice(index + spouseCount + 1, index + spouseCount + 1 + employee.numberOfChildren + 1), c => c.age).reverse();
                            let auto = 0;
                            for (let i = 0; i <= employee.numberOfChildren; i++) {
                                const child = children[i];
                                if (child && child.relationship === "C" && child.age >= 0) {
                                    age = child.age;
                                    if (age > 20 || (age <= 20 && auto < 3)) {
                                        if (age <= 20) { auto++; }
                                        spot = age <= 14 ? 0 : age - 14;
                                        child.renewalMedicalAgeRate = match.ageRates.split(',')[spot];
                                    }
                                }
                            }
                        }
                    }
                }
            }
            this.setState({ census: Object.assign([], census) });
        }
    };

    _setRenewalDentalPlanId = (e, c, index) => {
        const { currentDentalPlans } = this.state;
        currentDentalPlans[index].renewalDentalPlanId = parseInt(e.value);
        this.setState({
            census: this.state.census.map(
                person => person.currentDentalPlanId === c.id
                    ? { ...person, renewalDentalPlanId: e.value, }
                    : person,
            ),
            currentDentalPlans: Object.assign([], currentDentalPlans)
        });
    };

    _setRenewalVisionPlanId = (e, c, index) => {
        const { currentVisionPlans } = this.state;
        currentVisionPlans[index].renewalVisionPlanId = parseInt(e.value);
        this.setState({
            census: this.state.census.map(
                person => person.currentVisionPlanId === c.id
                    ? { ...person, renewalVisionPlanId: e.value, }
                    : person,
            ),
            currentVisionPlans: Object.assign([], currentVisionPlans)
        });
    };

    _setRenewalMedicalAgeRate = (e, index, planId) => {
        //test for valid number
        const val = this._checkNumber(e.target.value);
        const { census } = this.state;
        census[index].renewalMedicalAgeRate = val;
        if (planId) {
            census[index].renewalMedicalPlanId = planId;
        }
        this.setState({ census: Object.assign([], census) });
    };

    _setRenewalMedicalCompositeRate = (e, c, index, rel) => {
        //test for valid number
        const val = this._checkNumber(e.target.value);
        const { currentMedicalPlans, renewalMedicalPlans } = this.state;
        //have renewal plan id?
        if (!currentMedicalPlans[index].renewalMedicalPlanId && renewalMedicalPlans && renewalMedicalPlans[0]) {
            currentMedicalPlans[index].renewalMedicalPlanId = renewalMedicalPlans[0].id;
        }
        //save rate
        if (rel === "EE") {
            currentMedicalPlans[index].renewalMedicalCompositeEERate = val;
        } else if (rel === "ES") {
            currentMedicalPlans[index].renewalMedicalCompositeESRate = val;
        } else if (rel === "EC") {
            currentMedicalPlans[index].renewalMedicalCompositeECRate = val;
        } else if (rel === "EF") {
            currentMedicalPlans[index].renewalMedicalCompositeEFRate = val;
        }

        this.setState({
            census: this.state.census.map(
                person => person.currentMedicalPlanId === c.id && person.medicalCoverage === rel
                    ? { ...person, renewalMedicalCompositeRate: val, }
                    : person,
            ),
            currentMedicalPlans: Object.assign([], currentMedicalPlans)
        });
    };

    _setRenewalDentalCompositeRate = (e, c, index, rel) => {
        //test for valid number
        const val = this._checkNumber(e.target.value);
        const { currentDentalPlans, renewalDentalPlans } = this.state;
        //have renewal plan id?
        if (!currentDentalPlans[index].renewalDentalPlanId && renewalDentalPlans && renewalDentalPlans[0]) {
            currentDentalPlans[index].renewalDentalPlanId = renewalDentalPlans[0].id;
        }
        //save rate
        if (rel === "EE") {
            currentDentalPlans[index].renewalDentalCompositeEERate = val;
        } else if (rel === "ES") {
            currentDentalPlans[index].renewalDentalCompositeESRate = val;
        } else if (rel === "EC") {
            currentDentalPlans[index].renewalDentalCompositeECRate = val;
        } else if (rel === "EF") {
            currentDentalPlans[index].renewalDentalCompositeEFRate = val;
        }

        this.setState({
            census: this.state.census.map(
                person => person.currentDentalPlanId === c.id && person.dentalCoverage === rel
                    ? { ...person, renewalDentalCompositeRate: val, }
                    : person,
            ),
            currentDentalPlans: Object.assign([], currentDentalPlans)
        });
    };

    _setRenewalVisionCompositeRate = (e, c, index, rel) => {
        //test for valid number
        const val = this._checkNumber(e.target.value);
        const { currentVisionPlans, renewalVisionPlans } = this.state;
        //have renewal plan id?
        if (!currentVisionPlans[index].renewalVisionPlanId && renewalVisionPlans && renewalVisionPlans[0]) {
            currentVisionPlans[index].renewalVisionPlanId = renewalVisionPlans[0].id;
        }
        //save rate
        if (rel === "EE") {
            currentVisionPlans[index].renewalVisionCompositeEERate = val;
        } else if (rel === "ES") {
            currentVisionPlans[index].renewalVisionCompositeESRate = val;
        } else if (rel === "EC") {
            currentVisionPlans[index].renewalVisionCompositeECRate = val;
        } else if (rel === "EF") {
            currentVisionPlans[index].renewalVisionCompositeEFRate = val;
        }

        this.setState({
            census: this.state.census.map(
                person => person.currentVisionPlanId === c.id && person.VisionCoverage === rel
                    ? { ...person, renewalVisionCompositeRate: val, }
                    : person,
            ),
            currentVisionPlans: Object.assign([], currentVisionPlans)
        });
    };

    _saveCensusMapping = () => {
        const { census, currentMedicalPlans, currentDentalPlans, currentVisionPlans } = this.state;
        const { quote, coverageType } = this.props;

        if (census && census[0] && !census[0].quoteId) {
            census[0].quoteId = quote.id;
        }

        //save the composite rates if we have them
        if (coverageType === coverageTypes.medical) {
            quoteApi.saveCurrentRenewalCompositeRates(quote.id, "medical", currentMedicalPlans, null, null);
        } else if (coverageType === coverageTypes.dental) {
            quoteApi.saveCurrentRenewalCompositeRates(quote.id, "dental", null, currentDentalPlans, null);
        } else if (coverageType === coverageTypes.vision) {
            quoteApi.saveCurrentRenewalCompositeRates(quote.id, "vision", null, null, currentVisionPlans);
        }
        //save the individual rates to the census
        censusApi.saveCensusMapping(census)
            .then(() => {
                this.setState({
                    showCurrentPlansModal: false,
                    showRenewalPlansModal: false,
                });
                quoteApi.getById(quote.id)
                    .then(response => {
                        this.props.setQuote(response);
                    });
            });
    }

    _continueClicked = () => {
        if (this.props.onContinue) {
            this.props.onContinue();
        }
    };

    _deductibleRangeChanged = value => {
        this.setState(prevState => {
            return {
                allChecked: false,
                filter: {
                    ...prevState.filter,
                    [filterInputNames.deductibleRange]: value,
                },
                isUpdating: true,
            };
        }, () => {
            this._getPlans();
        });
    };

    _coinsuranceRangeChanged = value => {
        this.setState(prevState => {
            return {
                allChecked: false,
                filter: {
                    ...prevState.filter,
                    [filterInputNames.coinsuranceRange]: value,
                },
                isUpdating: true,
            };
        }, () => {
            this._getPlans();
        });
    };

    _oopRangeChanged = value => {
        this.setState(prevState => {
            return {
                allChecked: false,
                filter: {
                    ...prevState.filter,
                    [filterInputNames.oopRange]: value,
                },
                isUpdating: true,
            };
        }, () => {
            this._getPlans();
        });
    };

    _employeePremiumRangeChanged = value => {
        this.setState(prevState => {
            return {
                allChecked: false,
                filter: {
                    ...prevState.filter,
                    [filterInputNames.employeePremiumRange]: value,
                },
                isUpdating: true,
            };
        }, () => {
            this._getPlans();
        });
    };

    _monthlyPremiumRangeChanged = value => {
        this.setState(prevState => {
            return {
                allChecked: false,
                filter: {
                    ...prevState.filter,
                    [filterInputNames.monthlyPremiumRange]: value,
                },
                isUpdating: true,
            };
        }, () => {
            this._getPlans();
        });
    };

    _showSaveSpreadsheetModal = () => {
        this.setState({ showSaveSpreadsheetModal: true });
    };

    _setSpreadsheetName = e => {
        this.setState({ spreadsheetName: e.target.value });
    };

    _saveSpreadsheetClicked = () => {
        const { planType, coverageType, selectedPlans } = this.props;
        const { plans, selectedCurrentPlanId, showSaveSpreadsheetModal, spreadsheetName, filter } = this.state;
        const isNewMedical = planType === planTypes.newPlan && coverageType === "medical";

        let selectedPlansByCoverageType = selectedPlans[coverageType];
        if (selectedPlansByCoverageType.length > 0) {
            //sort selected plans by Carrier then Premium
            selectedPlansByCoverageType = sortBy(selectedPlansByCoverageType, ["carrierName", "ee", "ageMonthlyPremium"]);

            const selectedPlansList = isNewMedical ? selectedPlansByCoverageType.map(p => p.id) : [];
            const plansOnScreen = isNewMedical ? plans.map(p => p.id) : [];
            const newMedicalPlansThatOverlap = isNewMedical && selectedPlansList && selectedPlansList.length > 0 && plansOnScreen && plansOnScreen.length > 0 && plansOnScreen.find(p => selectedPlansList.some(s => s === p)) ? true : false;
            //try to save?
            if (showSaveSpreadsheetModal && selectedCurrentPlanId && newMedicalPlansThatOverlap) {
                if (!spreadsheetName) {
                    this.setState({ spreadsheetNameError: true });
                } else {
                    const selectedPlans = [];
                    //add the current plan selected
                    selectedPlans.push({ id: parseInt(selectedCurrentPlanId), type: "current" });
                    //skip renewal spot...
                    selectedPlans.push({});
                    //add plans from page... up to 22 of them
                    let tooManyPlans = false;
                    let i = 0;
                    plansOnScreen.filter(p => selectedPlansList.some(s => s === p)).forEach(p => {
                        if (i < 22) {
                            let plan = {
                                id: p,
                                type: "new",
                            };
                            selectedPlans.push(plan);
                        } else {
                            tooManyPlans = true;
                        }
                        i++;
                    });
                    //check final length...
                    while (selectedPlans.length < 24) {
                        selectedPlans.push({});
                    }
                    //save to spreadsheet object
                    const spreadsheet = {
                        name: spreadsheetName,
                        rateType: filter[filterInputNames.isAgeRated] ? "Age" : "Composite",
                        plans: selectedPlans,
                    }

                    this.props.saveSinglePlanSpreadsheet(spreadsheet);

                    this.setState({
                        showSaveSpreadsheetModal: false,
                        spreadsheetName: "",
                    }, () => {
                        if (tooManyPlans) {
                            toast.warn("The Save was successful, but only the first 22 New plans were saved.", { toastId: 1 });
                        } else {
                            toast.success("Save Successful", { toastId: 1 });
                        }
                    });
                }
            }
        } else {
            toast.error("Save was not successful because no plans were selected.", { toastId: 1 });
        }
    };

    _renderCheckboxes = (options, optionType) => {
        if (optionType === "planTypes") {
            options = [];
            const { planTypes } = this.props;
            const selectedCarriers = this.state.filter["carriers"];

            //plan types depends on selected carrier
            if (planTypes && planTypes.length > 0) {
                Object.keys(planTypes).forEach(key => {
                    let split = planTypes[key].split('|');
                    if (selectedCarriers && selectedCarriers.length > 0) {
                        if (selectedCarriers.indexOf(split[0]) > -1 && options.indexOf(split[3]) === -1) {
                            options.push(split[3]);
                        }
                    } else {
                        if (options.indexOf(split[3]) === -1) {
                            options.push(split[3]);
                        }
                    }
                });
            }
        }

        //check for Expanded Bronze in results
        if (optionType === "tiers" && options.length === 5) {
            const { newMedicalPlans } = this.props.quote;
            if (newMedicalPlans) {
                const expBronze = newMedicalPlans.filter(p => p.metallicLevelId === 5).length;
                if (expBronze === 0 && options[1].id === 5) {
                    options.splice(1, 1);
                }
            }
        }

        return (
            <div className={css(styles.checkboxContainer)}>
                {options.map((option, index) => (
                    <div key={index}>
                        <label className={css(styles.checkLabel)}>
                            <Checkbox
                                aStyles={styles.check}
                                checked={this.state.filter[optionType].includes(option.id ? option.id.toString() : option)}
                                name={optionType}
                                value={option.id ? option.id : option}
                                onChange={this._handleFilterCheckboxChanged}
                            />
                            <span className={css(styles.labelText)}>
                                {optionType === "tiers" &&
                                    <span className={css(styles.metalCircle)} style={{ backgroundColor: (option.name === "Gold" ? "#ffd700" : option.name === "Silver" ? "#c0c0c0" : (option.name === "Bronze" || option.name === "Exp. Bronze") ? "#cd7f32" : option.name === "Platinum" ? "#f7f7f7" : option.name), border: (option.name === "Platinum" ? "solid 1px #cccccc" : "") }}>{option.name === "Exp. Bronze" ? "+" : ""}</span>
                                }
                                {option.name ? option.name : option}
                            </span>
                        </label>
                        {optionType === "carriers" && option.packages ? (
                            option.packages.split(',').map((pkg, idx) => (
                                <div key={idx}>
                                    <label className={css(styles.checkLabel, styles.pkgLabel)}>
                                        <Checkbox
                                            aStyles={styles.check}
                                            checked={this.state.filter["packages"].includes(pkg)}
                                            name="packages"
                                            value={pkg}
                                            onChange={this._handleFilterCheckboxChanged}
                                        />
                                        <span className={css(styles.labelText, styles.pkgText)}>
                                            {pkg}
                                        </span>
                                    </label>
                                </div>
                            ))
                        ) : null}
                    </div>
                ))}
            </div>
        );
    };

    _renderMedicalPlanFilter = () => (
        <div>
            <div className={css(styles.inlineFilterContainer)}>
                <table className={css(styles.inlineTable)}>
                    <tbody>
                        <tr>
                            <td className={css(styles.tblCell, styles.filterLabel)}>Filter By:</td>
                            <td className={css(styles.tblCell)}>
                                <SmallDropdown
                                    aStyles={styles.dropdown}
                                    options={[{ label: "All Carriers", value: "-1" }].concat(this.state.carriersCR.map(item => ({
                                        label: item.name,
                                        value: item.id.toString()
                                    })))}
                                    value={this.state.selectedCarrier}
                                    placeholder="Carrier"
                                    onChange={e => this._changeCarrier(e, 'medical')}
                                    hideLabel={true}
                                />
                                <SmallDropdown
                                    aStyles={styles.dropdown}
                                    options={[{ label: "All Networks", value: "-1" }].concat(this.state.networks.map(item => ({
                                        label: item.name,
                                        value: item.name
                                    })))}
                                    value={this.state.selectedNetwork}
                                    placeholder="Network"
                                    onChange={this._changeNetwork}
                                    hideLabel={true}
                                />
                                <SmallDropdown
                                    aStyles={styles.dropdown}
                                    options={[{ label: "All Plan Types", value: "-1" }].concat(this.state.planTypesCR.map(item => ({
                                        label: item.split('|')[3],
                                        value: item.split('|')[3]
                                    })))}
                                    value={this.state.selectedPlanType}
                                    placeholder="Plan Type"
                                    onChange={this._changePlanType}
                                    hideLabel={true}
                                />
                                <SmallDropdown
                                    aStyles={styles.dropdown}
                                    options={[{ label: "All Tiers", value: "-1" }].concat(this.state.tiers.map(item => ({
                                        label: item.name,
                                        value: item.id.toString()
                                    })))}
                                    value={this.state.selectedTier}
                                    placeholder="Metal Tier"
                                    onChange={this._changeTier}
                                    hideLabel={true}
                                />
                                {/*<SmallDropdown
                                    aStyles={styles.dropdown}
                                    options={[{ label: "All Deductibles", value: "-1" }].concat([].map(item => ({
                                        label: item.name,
                                        value: item.id.toString(),
                                    })))}
                                    value={this.state.selectedDeductible}
                                    placeholder="Deductible"                    
                                    onChange={this._changeDeductible}                        
                                    hideLabel={true}                        
                                />*/}
                            </td>
                            <td className={css(styles.tblCellRight, styles.filterLabel)}>Search:</td>
                            <td className={css(styles.tblCellSearch)}>
                                <TextInput
                                    name={filterInputNames.planName}
                                    onChange={this._handleFilterTextChanged}
                                    placeholder={strings.planNameFilterLabel}
                                    value={this.state.filter[filterInputNames.planName]}
                                    hideLabel={true}
                                    size={inputSizes.small}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );

    _renderNewMedicalPlanFilter = () => (
        <div>
            <div className={css(styles.filterContainer)}>
                <div className={css(styles.filterControlColumn)}>
                    <div className={css(styles.checkboxFilter)}>
                        <div className={css(styles.filterLabel)}>
                            {strings.carrierFilterLabel}
                        </div>
                        {this._renderCheckboxes(this.state.carriers, "carriers")}
                    </div>
                </div>
                <div className={css(styles.filterControlColumnWide)}>
                    <div className={css(styles.checkboxFilter)}>
                        <div className={css(styles.filterLabel)}>
                            {strings.planTypeFilterLabel}
                            {this.state.filter["carriers"] && this.state.filter["carriers"].length > 0 ?
                                (<span className={css(styles.planTypeCarriers)}>Selected Carrier(s)</span>) :
                                (<span className={css(styles.planTypeCarriers)}>All Carriers</span>)
                            }
                        </div>
                        {this._renderCheckboxes(this.state.planTypes, "planTypes")}
                    </div>
                    {this.props.quote.areaId === 32 && this.state.carriers &&
                        <div className={css(styles.checkboxFilter)}>
                            <div className={css(styles.filterLabel)}>
                                {strings.networkFilterLabel}
                            </div>
                            {this._renderCheckboxes(this.state.carriers.find(p => p.id === 18) ? this.state.networkDesignationsAFA : this.state.networkDesignations, "networks")}
                        </div>}
                </div>
                <div className={css(styles.filterControlColumn)}>
                    <div className={css(styles.checkboxFilter)}>
                        <div className={css(styles.filterLabel)}>
                            {strings.tierFilterLabel}
                        </div>
                        {this._renderCheckboxes(this.state.tiers, "tiers")}
                    </div>
                    <div className={css(styles.checkboxFilter)}>
                        <div className={css(styles.filterLabel)}>
                            Additional Coverage
                        </div>
                        {this._renderCheckboxes(this.state.hsa, "hsa")}
                        {this.props.quote.areaId === 32 && this.state.carriers && this.state.carriers.filter(p => (p.id === 4 || p.id === 63 || p.id === 88 || p.id === 93 || p.id === 94 || p.id === 96 || p.id === 101)).length > 0 &&
                            this._renderCheckboxes(this.state.infertility, "infertility")
                        }
                    </div>
                </div>
                <div className={css(styles.rangeControlContainer)}>
                    <div className={css(styles.rangeControlColumn)}>
                        <div className={css(styles.rangeControl)}>
                            <div className={css(styles.filterLabel, styles.rangeControlTitle)}>
                                {strings.deductibleFilterLabel}
                            </div>
                            <Range
                                defaultValue={[this.state.deductibleMin, this.state.deductibleMax]}
                                value={this.state.filter[filterInputNames.deductibleRange] && this.state.filter[filterInputNames.deductibleRange].length === 2 ? this.state.filter[filterInputNames.deductibleRange] : [this.state.deductibleMin, this.state.deductibleMax]}
                                step={10}
                                min={this.state.deductibleMin}
                                max={this.state.deductibleMax}
                                onChange={this._deductibleRangeChanged}
                            />
                        </div>
                        <div className={css(styles.rangeControl)}>
                            <div className={css(styles.filterLabel, styles.rangeControlTitle)}>
                                {strings.coinsuranceFilterLabel}
                            </div>
                            <Range
                                defaultValue={[this.state.coinsuranceMin, this.state.coinsuranceMax]}
                                value={this.state.filter[filterInputNames.coinsuranceRange] && this.state.filter[filterInputNames.coinsuranceRange].length === 2 ? this.state.filter[filterInputNames.coinsuranceRange] : [this.state.coinsuranceMin, this.state.coinsuranceMax]}
                                step={10}
                                min={this.state.coinsuranceMin}
                                max={this.state.coinsuranceMax}
                                onChange={this._coinsuranceRangeChanged}
                                showAsPercent={true}
                            />
                        </div>
                        <div className={css(styles.rangeControl)}>
                            <div className={css(styles.filterLabel, styles.rangeControlTitle)}>
                                {strings.oopFilterLabel}
                            </div>
                            <Range
                                defaultValue={[this.state.oopMin, this.state.oopMax]}
                                value={this.state.filter[filterInputNames.oopRange] && this.state.filter[filterInputNames.oopRange].length === 2 ? this.state.filter[filterInputNames.oopRange] : [this.state.oopMin, this.state.oopMax]}
                                step={10}
                                min={this.state.oopMin}
                                max={this.state.oopMax}
                                onChange={this._oopRangeChanged}
                            />
                        </div>
                    </div>
                    <div className={css(styles.rangeControlColumn)}>
                        <div className={css(styles.rangeControl)}>
                            <div className={css(styles.filterLabel, styles.rangeControlTitle)}>
                                {strings.employeePremiumFilterLabel}
                            </div>
                            <Range
                                defaultValue={[this.state.employeePremiumMin, this.state.employeePremiumMax]}
                                value={this.state.filter[filterInputNames.employeePremiumRange] && this.state.filter[filterInputNames.employeePremiumRange].length === 2 ? this.state.filter[filterInputNames.employeePremiumRange] : [this.state.employeePremiumMin, this.state.employeePremiumMax]}
                                step={10}
                                min={this.state.employeePremiumMin}
                                max={this.state.employeePremiumMax}
                                onChange={this._employeePremiumRangeChanged}
                            />
                        </div>
                        <div className={css(styles.rangeControl)}>
                            <div className={css(styles.filterLabel, styles.rangeControlTitle)}>
                                {strings.monthlyPremiumFilterLabel}
                            </div>
                            <Range
                                defaultValue={[this.state.monthlyPremiumMin, this.state.monthlyPremiumMax]}
                                value={this.state.filter[filterInputNames.monthlyPremiumRange] && this.state.filter[filterInputNames.monthlyPremiumRange].length === 2 ? this.state.filter[filterInputNames.monthlyPremiumRange] : [this.state.monthlyPremiumMin, this.state.monthlyPremiumMax]}
                                step={10}
                                min={this.state.monthlyPremiumMin}
                                max={this.state.monthlyPremiumMax}
                                onChange={this._monthlyPremiumRangeChanged}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={css(styles.inlineFilterContainer)}>
                <table className={css(styles.inlineTable)}>
                    <tbody>
                        <tr>
                            <td className={css(styles.tblCell, styles.filterLabel)} style={{ width: "3%" }}>{strings.ratesFilterLabel}</td>
                            <td className={css(styles.tblCell)} style={{ width: "5%" }}>
                                <Toggle
                                    defaultChecked={this.state.filter[filterInputNames.isAgeRated]}
                                    onChange={this._isAgeRatedChanged}
                                    checkedLabel="Age"
                                    uncheckedLabel="Comp."
                                />
                            </td>
                            <td className={css(styles.tblCellRight, styles.filterLabel)} style={{ width: "12%" }}>Contribution:</td>
                            <td className={css(styles.tblCell)} style={{ width: "5%" }}>
                                <Toggle
                                    defaultChecked={this.state.filter[filterInputNames.isPercentageContribution]}
                                    onChange={this._isPercentageContributionChanged}
                                    checkedLabel="%"
                                    uncheckedLabel="$"
                                />
                            </td>
                            <td className={css(styles.tblCell)} style={{ width: "5%" }}>
                                <TextInput
                                    aStyles={styles.contribution}
                                    name={filterInputNames.employeeContribution}
                                    onChange={this._handleFilterTextChanged}
                                    value={this.state.filter[filterInputNames.employeeContribution] ? this.state.filter[filterInputNames.employeeContribution].toString() : ""}
                                    size={inputSizes.small}
                                    hideLabel={true}
                                    prependLabel="EE"
                                    appendLabel={this.state.filter[filterInputNames.isPercentageContribution] ? "%" : "$"}
                                    type="number"
                                />
                            </td>
                            <td className={css(styles.tblCell)} style={{ width: "5%" }}>
                                <TextInput
                                    aStyles={styles.contribution}
                                    name={filterInputNames.dependentContribution}
                                    onChange={this._handleFilterTextChanged}
                                    value={this.state.filter[filterInputNames.dependentContribution] ? this.state.filter[filterInputNames.dependentContribution].toString() : ""}
                                    size={inputSizes.small}
                                    hideLabel={true}
                                    prependLabel="Dep"
                                    appendLabel={this.state.filter[filterInputNames.isPercentageContribution] ? "%" : "$"}
                                    type="number"
                                />
                            </td>
                            <td className={css(styles.tblCellRight, styles.filterLabel)} style={{ width: "10%" }}>Search:</td>
                            {this.props.quote["currentMedicalPlans"] && this.props.quote["currentMedicalPlans"].length > 0 &&
                                <td className={css(styles.tblCell)} style={{ width: "30%" }}>
                                    <SmallDropdown
                                        aStyles={styles.similarPlanDropdown}
                                        options={this.props.quote["currentMedicalPlans"].concat([{ displayName: "- Reset -", id: -1 }]).map(item => ({
                                            label: item.displayName === "- Reset -" ? item.displayName : "Plans similar to " + item.displayName + " (" + item.individualDeductibleIn + " ded, " + item.coInsurance + " coins, " + item.individualOOPIn + " oop, " + item.planTypeName + (item.metallicLevel ? ", " + item.metallicLevel + ")" : ")"),
                                            value: item.id.toString()
                                        }))}
                                        value={this.state.selectedCurrentPlanId}
                                        placeholder={strings.findSimilarToCurrent}
                                        onChange={this._changeSelectedCurrentPlanId}
                                    />
                                </td>
                            }
                            <td className={css(styles.tblCellSearch)} style={{ width: "25%" }}>
                                <TextInput
                                    name={filterInputNames.planName}
                                    onChange={this._handleFilterTextChanged}
                                    placeholder={strings.planNameFilterLabel}
                                    value={this.state.filter[filterInputNames.planName]}
                                    hideLabel={true}
                                    size={inputSizes.small}
                                />
                            </td>
                            {!this.props.quote["currentMedicalPlans"] &&
                                <td className={css(styles.tblCell)} style={{ width: "30%" }}>{" "}</td>
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );

    _renderDentalPlanFilter = () => (
        <div>
            <div className={css(styles.inlineFilterContainer)}>
                <table className={css(styles.inlineTable)}>
                    <tbody>
                        <tr>
                            <td className={css(styles.tblCell, styles.filterLabel)}>Filter By:</td>
                            <td className={css(styles.tblCell)}>
                                <SmallDropdown
                                    aStyles={styles.dropdown}
                                    options={[{ label: "All Carriers", value: "-1" }].concat(this.state.carriersCR.map(item => ({
                                        label: item.name,
                                        value: item.id.toString()
                                    })))}
                                    value={this.state.selectedCarrier}
                                    placeholder="Carrier"
                                    onChange={e => this._changeCarrier(e, 'dental')}
                                    hideLabel={true}
                                />
                                <SmallDropdown
                                    aStyles={styles.dropdown}
                                    options={[{ label: "All Networks", value: "-1" }].concat(this.state.networks.map(item => ({
                                        label: item.name,
                                        value: item.name
                                    })))}
                                    value={this.state.selectedNetwork}
                                    placeholder="Network"
                                    onChange={this._changeNetwork}
                                    hideLabel={true}
                                />
                            </td>
                            <td className={css(styles.tblCellRight, styles.filterLabel)}>Search:</td>
                            <td className={css(styles.tblCellSearch)}>
                                <TextInput
                                    name={filterInputNames.planName}
                                    onChange={this._handleFilterTextChanged}
                                    placeholder={strings.planNameFilterLabel}
                                    value={this.state.filter[filterInputNames.planName]}
                                    hideLabel={true}
                                    size={inputSizes.small}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );

    _renderNewDentalPlanFilter = () => (
        <div>
            <div className={css(styles.filterContainer)}>
                <div className={css(styles.filterControlColumnWide)}>
                    <div className={css(styles.checkboxFilter, styles.carrierFilter)}>
                        <div className={css(styles.filterLabel)}>
                            {strings.carrierFilterLabel}
                        </div>
                        {this._renderCheckboxes(this.state.carriers, "carriers")}
                    </div>
                    {this.props.planTypesD.length > 0 &&
                        <div className={css(styles.checkboxFilter)}>
                            <div className={css(styles.filterLabel)}>
                                {strings.planTypeFilterLabel}
                            </div>
                            {this._renderCheckboxes(this.props.planTypesD, "planTypesD")}
                        </div>
                    }
                </div>
                <div className={css(styles.filterControlColumn)}>
                    <div className={css(styles.checkboxFilter)}>
                        <div className={css(styles.filterLabel)}>
                            {strings.fundingTypeFilterLabel}
                        </div>
                        {this._renderCheckboxes(this.state.fundingTypesD, "fundingTypesD")}
                    </div>
                    {this.props.deductiblesD.length > 0 &&
                        <div className={css(styles.checkboxFilter)}>
                            <div className={css(styles.filterLabel)}>
                                {strings.deductibleFilterLabel}
                            </div>
                            {this._renderCheckboxes(this.props.deductiblesD, "deductiblesD")}
                        </div>
                    }
                </div>
                <div className={css(styles.filterControlColumn)}>
                    {this.props.annualMaximumsD.length > 0 &&
                        <div className={css(styles.checkboxFilterNoHeight)}>
                            <div className={css(styles.filterLabel)}>
                                {strings.annualMaximumFilterLabel}
                            </div>
                            {this._renderCheckboxes(this.props.annualMaximumsD, "annualMaximumsD")}
                        </div>
                    }
                </div>
                <div className={css(styles.filterControlColumn)}>
                    <div className={css(styles.checkboxFilter)}>
                        <div className={css(styles.filterLabel)}>
                            {strings.orthoFilterLabel}
                        </div>
                        {this._renderCheckboxes(this.state.ortho, "ortho")}
                    </div>
                    {this.props.orthoMaximumsD.length > 0 &&
                        <div className={css(styles.checkboxFilter)}>
                            <div className={css(styles.filterLabel)}>
                                {strings.orthoMaximumFilterLabel}
                            </div>
                            {this._renderCheckboxes(this.props.orthoMaximumsD, "orthoMaximumsD")}
                        </div>
                    }
                    {this.props.reimbursementsD.length > 0 &&
                        <div className={css(styles.checkboxFilterNoHeight)}>
                            <div className={css(styles.filterLabel)}>
                                {strings.reimbursementFilterLabel}
                            </div>
                            {this._renderCheckboxes(this.props.reimbursementsD, "reimbursementsD")}
                        </div>
                    }
                </div>
                <div className={css(styles.filterControlColumnWide)}>
                    {this.props.coinsurancesD.length > 0 &&
                        <div className={css(styles.checkboxFilterNoHeight)}>
                            <div className={css(styles.filterLabel)}>
                                {strings.coinsuranceInDFilterLabel}
                            </div>
                            {this._renderCheckboxes(this.props.coinsurancesD, "coinsurancesD")}
                        </div>
                    }
                    <div className={css(styles.checkboxFilter)}>
                        <div className={css(styles.filterLabel)}>
                            {strings.fillingsFilterLabel}
                        </div>
                        {this._renderCheckboxes(this.state.fillingsD, "fillingsD")}
                    </div>
                    <div className={css(styles.checkboxFilter)}>
                        <div className={css(styles.filterLabel)}>
                            {strings.implantsFilterLabel}
                        </div>
                        {this._renderCheckboxes(this.state.implantsD, "implantsD")}
                    </div>
                </div>
                <div className={css(styles.filterControlColumnWide)}>
                    {this.props.perioEndosD.length > 0 &&
                        <div className={css(styles.checkboxFilterNoHeight)}>
                            <div className={css(styles.filterLabel)}>
                                {strings.perioEndoTypeFilterLabel}
                            </div>
                            {this._renderCheckboxes(this.props.perioEndosD, "perioEndosD")}
                        </div>
                    }
                    <div className={css(styles.rangeControl)}>
                        <div className={css(styles.filterLabel, styles.rangeControlTitle)}>
                            {strings.employeePremiumFilterLabel}
                        </div>
                        <Range
                            defaultValue={[this.state.employeePremiumMinD, this.state.employeePremiumMaxD]}
                            value={this.state.filter[filterInputNames.employeePremiumRange] && this.state.filter[filterInputNames.employeePremiumRange].length === 2 ? this.state.filter[filterInputNames.employeePremiumRange] : [this.state.employeePremiumMinD, this.state.employeePremiumMaxD]}
                            step={10}
                            min={this.state.employeePremiumMinD}
                            max={this.state.employeePremiumMaxD}
                            onChange={this._employeePremiumRangeChanged}
                        />
                    </div>
                </div>
                {/*<div className={css(styles.filterLabel)}>
                    {strings.monthlyPremiumFilterLabel}
                </div>  
                <div className={css(styles.rangeControl)}>
                    <Range 
                        defaultValue={[this.state.monthlyPremiumMinD, this.state.monthlyPremiumMaxD]} 
                        value={this.state.filter[filterInputNames.monthlyPremiumRange] && this.state.filter[filterInputNames.monthlyPremiumRange].length === 2 ? this.state.filter[filterInputNames.monthlyPremiumRange] : [this.state.monthlyPremiumMinD, this.state.monthlyPremiumMaxD]}
                        step={10}
                        min={this.state.monthlyPremiumMinD}
                        max={this.state.monthlyPremiumMaxD}
                        onChange={this._monthlyPremiumRangeChanged}
                    />
                </div>*/}
            </div>
            <div className={css(styles.inlineFilterContainer)}>
                <table className={css(styles.inlineTable)}>
                    <tbody>
                        <tr>
                            <td className={css(styles.tblCellRight, styles.filterLabel)}>Search:</td>
                            <td className={css(styles.tblCellSearch)}>
                                <TextInput
                                    name={filterInputNames.planName}
                                    onChange={this._handleFilterTextChanged}
                                    placeholder={strings.planNameFilterLabel}
                                    value={this.state.filter[filterInputNames.planName]}
                                    hideLabel={true}
                                    size={inputSizes.small}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );

    _renderVisionPlanFilter = () => (
        <div>
            <div className={css(styles.inlineFilterContainer)}>
                <table className={css(styles.inlineTable)}>
                    <tbody>
                        <tr>
                            <td className={css(styles.tblCell, styles.filterLabel)}>Filter By:</td>
                            <td className={css(styles.tblCell)}>
                                <SmallDropdown
                                    aStyles={styles.dropdown}
                                    options={[{ label: "All Carriers", value: "-1" }].concat(this.state.carriersCR.map(item => ({
                                        label: item.name,
                                        value: item.id.toString()
                                    })))}
                                    value={this.state.selectedCarrier}
                                    placeholder="Carrier"
                                    onChange={e => this._changeCarrier(e, 'vision')}
                                    hideLabel={true}
                                />
                            </td>
                            <td className={css(styles.tblCellRight, styles.filterLabel)}>Search:</td>
                            <td className={css(styles.tblCellSearch)}>
                                <TextInput
                                    name={filterInputNames.planName}
                                    onChange={this._handleFilterTextChanged}
                                    placeholder={strings.planNameFilterLabel}
                                    value={this.state.filter[filterInputNames.planName]}
                                    hideLabel={true}
                                    size={inputSizes.small}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );

    _renderNewVisionPlanFilter = () => (
        <div>
            <div className={css(styles.filterContainer)}>
                <div className={css(styles.filterControlColumnWide)}>
                    <div className={css(styles.checkboxFilter, styles.carrierFilter)}>
                        <div className={css(styles.filterLabel)}>
                            {strings.carrierFilterLabel}
                        </div>
                        {this._renderCheckboxes(this.state.carriers, "carriers")}
                    </div>
                    {this.props.fundingTypesV.length > 0 &&
                        <div className={css(styles.checkboxFilterNoHeight)}>
                            <div className={css(styles.filterLabel)}>
                                {strings.fundingTypeFilterLabel}
                            </div>
                            {this._renderCheckboxes(this.props.fundingTypesV, "fundingTypesV")}
                        </div>
                    }
                </div>
                <div className={css(styles.filterControlColumn)}>
                    {this.props.examCopaysV.length > 0 &&
                        <div className={css(styles.checkboxFilter)}>
                            <div className={css(styles.filterLabel)}>
                                {strings.examCopayFilterLabel}
                            </div>
                            {this._renderCheckboxes(this.props.examCopaysV, "examCopaysV")}
                        </div>
                    }
                    {this.props.frameAllowancesV.length > 0 &&
                        <div className={css(styles.checkboxFilter)}>
                            <div className={css(styles.filterLabel)}>
                                {strings.frameAllowanceFilterLabel}
                            </div>
                            {this._renderCheckboxes(this.props.frameAllowancesV, "frameAllowancesV")}
                        </div>
                    }
                </div>
                <div className={css(styles.filterControlColumn)}>
                    {this.props.frameFrequenciesV.length > 0 &&
                        <div className={css(styles.checkboxFilterNoHeight)}>
                            <div className={css(styles.filterLabel)}>
                                {strings.frameFrequencyFilterLabel}
                            </div>
                            {this._renderCheckboxes(this.props.frameFrequenciesV, "frameFrequenciesV")}
                        </div>
                    }
                </div>
                <div className={css(styles.filterControlColumn)}>
                    {this.props.contactLensAllowancesV.length > 0 &&
                        <div className={css(styles.checkboxFilterNoHeight)}>
                            <div className={css(styles.filterLabel)}>
                                {strings.contactLensAllowanceFilterLabel}
                            </div>
                            {this._renderCheckboxes(this.props.contactLensAllowancesV, "contactLensAllowancesV")}
                        </div>
                    }
                </div>
                <div className={css(styles.filterControlColumn)}>
                    {this.props.materialCopaysV.length > 0 &&
                        <div className={css(styles.checkboxFilter)}>
                            <div className={css(styles.filterLabel)}>
                                {strings.materialCopayFilterLabel}
                            </div>
                            {this._renderCheckboxes(this.props.materialCopaysV, "materialCopaysV")}
                        </div>
                    }
                    <div className={css(styles.rangeControl)}>
                        <div className={css(styles.filterLabel, styles.rangeControlTitle)}>
                            {strings.employeePremiumFilterLabel}
                        </div>
                        <Range
                            defaultValue={[this.state.employeePremiumMinV, this.state.employeePremiumMaxV]}
                            value={this.state.filter[filterInputNames.employeePremiumRange] && this.state.filter[filterInputNames.employeePremiumRange].length === 2 ? this.state.filter[filterInputNames.employeePremiumRange] : [this.state.employeePremiumMinV, this.state.employeePremiumMaxV]}
                            step={10}
                            min={this.state.employeePremiumMinV}
                            max={this.state.employeePremiumMaxV}
                            onChange={this._employeePremiumRangeChanged}
                        />
                    </div>
                    {/*<div className={css(styles.rangeControl)}>
                        <Range 
                            defaultValue={[this.state.monthlyPremiumMinV, this.state.monthlyPremiumMaxV]} 
                            value={this.state.filter[filterInputNames.monthlyPremiumRange] && this.state.filter[filterInputNames.monthlyPremiumRange].length === 2 ? this.state.filter[filterInputNames.monthlyPremiumRange] : [this.state.monthlyPremiumMinV, this.state.monthlyPremiumMaxV]}
                            step={10}
                            min={this.state.monthlyPremiumMinV}
                            max={this.state.monthlyPremiumMaxV}
                            onChange={this._monthlyPremiumRangeChanged}
                        />
                    </div>*/}
                </div>
                <div className={css(styles.filterControlColumn)}>
                    <div className={css(styles.checkboxFilter)}>
                        <div className={css(styles.filterLabel)}>
                            {strings.retinalImagingLabel}
                        </div>
                        {this._renderCheckboxes(this.state.retinalImagingV, "retinalImagingV")}
                    </div>
                    <div className={css(styles.checkboxFilter)}>
                        <div className={css(styles.filterLabel)}>
                            {strings.childPolycarbonateLabel}
                        </div>
                        {this._renderCheckboxes(this.state.childPolycarbonateV, "childPolycarbonateV")}
                    </div>
                </div>
            </div>
            <div className={css(styles.inlineFilterContainer)}>
                <table className={css(styles.inlineTable)}>
                    <tbody>
                        <tr>
                            <td className={css(styles.tblCellRight, styles.filterLabel)}>Search:</td>
                            <td className={css(styles.tblCellSearch)}>
                                <TextInput
                                    name={filterInputNames.planName}
                                    onChange={this._handleFilterTextChanged}
                                    placeholder={strings.planNameFilterLabel}
                                    value={this.state.filter[filterInputNames.planName]}
                                    hideLabel={true}
                                    size={inputSizes.small}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );

    _renderLifePlanFilter = () => (
        <div>
            <div className={css(styles.inlineFilterContainer)}>
                <table className={css(styles.inlineTable)}>
                    <tbody>
                        <tr>
                            <td className={css(styles.tblCell, styles.filterLabel)}>Filter By:</td>
                            <td className={css(styles.tblCell)}>
                                <SmallDropdown
                                    aStyles={styles.dropdown}
                                    options={[{ label: "All Carriers", value: "-1" }].concat(this.state.carriers.map(item => ({
                                        label: item.name,
                                        value: item.id.toString()
                                    })))}
                                    value={this.state.selectedCarrier}
                                    placeholder="Carrier"
                                    onChange={e => this._changeCarrier(e, 'life')}
                                    hideLabel={true}
                                />
                            </td>
                            <td className={css(styles.tblCellRight, styles.filterLabel)}>Search:</td>
                            <td className={css(styles.tblCellSearch)}>
                                <TextInput
                                    name={filterInputNames.planName}
                                    onChange={this._handleFilterTextChanged}
                                    placeholder={strings.planNameFilterLabel}
                                    value={this.state.filter[filterInputNames.planName]}
                                    hideLabel={true}
                                    size={inputSizes.small}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );

    _renderNewLifePlanFilter = () => (
        <div>
            <div className={css(styles.filterContainer)}>
                <div className={css(styles.filterControlColumnWide)}>
                    <div className={css(styles.checkboxFilter, styles.carrierFilter)}>
                        <div className={css(styles.filterLabel)}>
                            {strings.carrierFilterLabel}
                        </div>
                        {this._renderCheckboxes(this.state.carriers, "carriers")}
                    </div>
                </div>
                <div className={css(styles.filterControlColumn)}>
                    {/*<div className={css(styles.rangeControl)}>
                        <Range 
                            defaultValue={[this.state.monthlyPremiumMinV, this.state.monthlyPremiumMaxV]} 
                            value={this.state.filter[filterInputNames.monthlyPremiumRange] && this.state.filter[filterInputNames.monthlyPremiumRange].length === 2 ? this.state.filter[filterInputNames.monthlyPremiumRange] : [this.state.monthlyPremiumMinV, this.state.monthlyPremiumMaxV]}
                            step={10}
                            min={this.state.monthlyPremiumMinV}
                            max={this.state.monthlyPremiumMaxV}
                            onChange={this._monthlyPremiumRangeChanged}
                        />
                    </div>*/}
                </div>
            </div>
            <div className={css(styles.inlineFilterContainer)}>
                <table className={css(styles.inlineTable)}>
                    <tbody>
                        <tr>
                            <td className={css(styles.tblCellRight, styles.filterLabel)}>Search:</td>
                            <td className={css(styles.tblCellSearch)}>
                                <TextInput
                                    name={filterInputNames.planName}
                                    onChange={this._handleFilterTextChanged}
                                    placeholder={strings.planNameFilterLabel}
                                    value={this.state.filter[filterInputNames.planName]}
                                    hideLabel={true}
                                    size={inputSizes.small}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );

    _renderFilterControl = () => {
        const { planType, coverageType } = this.props;

        if (planType !== planTypes.newPlan) {
            if (coverageType === coverageTypes.medical) {
                return this._renderMedicalPlanFilter();
            } else if (coverageType === coverageTypes.dental) {
                return this._renderDentalPlanFilter();
            } else if (coverageType === coverageTypes.vision) {
                return this._renderVisionPlanFilter();
            } else if (coverageType === coverageTypes.life) {
                return this._renderLifePlanFilter();
            }
        } else {
            if (coverageType === coverageTypes.medical) {
                return this._renderNewMedicalPlanFilter();
            } else if (coverageType === coverageTypes.dental) {
                return this._renderNewDentalPlanFilter();
            } else if (coverageType === coverageTypes.vision) {
                return this._renderNewVisionPlanFilter();
            } else if (coverageType === coverageTypes.life) {
                return this._renderNewLifePlanFilter();
            }
        }

        return null;
    };

    _getPlansContainerWidth = () => {
        const defaultWidth = "calc(100vw - 144px)";
        /*const defaultWidth = "calc(100vw - 298px)"; --if we go back to gray sidebars*/
        if (!this._planContainerRef.current) {
            return defaultWidth;
        }
        else {
            const containerWidth = this._planContainerRef.current.getBoundingClientRect().width;
            if (!containerWidth) {
                return defaultWidth;
            } else {
                return containerWidth;
            }
        }
    };

    _renderPlanButtons = () => {
        const { planType, coverageType, selectedPlans } = this.props;
        const { plans, selectedCurrentPlanId } = this.state;
        const isCurrentOrRenewal = planType !== planTypes.newPlan
        const isNewMedical = planType === planTypes.newPlan && coverageType === "medical";
        //show any buttons?
        if (isCurrentOrRenewal || isNewMedical) {
            const { plansButtonContainerBottom } = this.state;
            const containerWidth = this._getPlansContainerWidth();

            const selectedPlansList = isNewMedical ? selectedPlans[coverageType].map(p => p.id) : [];
            const plansOnScreen = isNewMedical ? plans.map(p => p.id) : [];
            const newMedicalPlansThatOverlap = isNewMedical && selectedPlansList && selectedPlansList.length > 0 && plansOnScreen && plansOnScreen.length > 0 && plansOnScreen.find(p => selectedPlansList.some(s => s === p)) ? true : false;

            return (
                <div
                    className={css(styles.planButtonContainer)}
                    style={{
                        width: containerWidth,
                        bottom: plansButtonContainerBottom,
                    }}
                >
                    <div className={css(styles.planButtons)}>
                        {isCurrentOrRenewal &&
                            <React.Fragment>
                                <ActionButton
                                    aStyles={styles.submitButton}
                                    label={strings.newSearch}
                                    type="button"
                                    invertColor={true}
                                    onClick={this._newSearchClicked}
                                />
                                <ActionButton
                                    aStyles={styles.submitButton}
                                    label={planType === planTypes.currentPlan ? strings.assignPlans : strings.mapPlans}
                                    type="button"
                                    invertColor={true}
                                    onClick={this._assignPlansClicked}
                                />
                            </React.Fragment>
                        }
                        {/*<ActionButton
                            aStyles={styles.submitButton}
                            label="Continue"
                            type="button"
                            onClick={this._continueClicked}
                        />*/}
                        {isNewMedical && newMedicalPlansThatOverlap && selectedCurrentPlanId &&
                            <ActionButton
                                aStyles={styles.submitButton}
                                label="Generate 'Similar to Current' Spreadsheet"
                                type="button"
                                onClick={this._showSaveSpreadsheetModal}
                            />
                        }
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    render() {
        if (this.state.isLoading) {
            return (
                <Spinner
                    aStyles={styles.loadingSpinner}
                    text="Loading..."
                />
            );
        }

        const {
            showMore,
            plans,
            selectedPlans,
            selectedBasePlans,
            showCurrentPlansModal,
            showRenewalPlansModal,
            currentMedicalPlans,
            renewalMedicalPlans,
            currentDentalPlans,
            renewalDentalPlans,
            currentVisionPlans,
            renewalVisionPlans,
            showManualModal,
            manualPlanName,
            showAddReplacePlansModal,
            showSaveSpreadsheetModal,
        } = this.state;

        let count = 0;
        if (plans.length) {
            count = plans[0].recordCount;
        }

        const { census } = this.state;

        const eeCount = census.filter(x => x.relationship === "EE").length;

        const eeTier = census.filter(x => x.relationship === "EE" && x.medicalCoverageId === 1).length;
        const esTier = census.filter(x => x.relationship === "EE" && x.medicalCoverageId === 2).length;
        const ecTier = census.filter(x => x.relationship === "EE" && x.medicalCoverageId === 3).length;
        const efTier = census.filter(x => x.relationship === "EE" && x.medicalCoverageId === 4).length;

        let currentCompositeTotal = 0;
        let renewalCompositeTotal = 0;
        let currentAgeTotal = 0;
        let renewalAgeTotal = 0;
        if (census && census.length > 0) {
            for (let index = 0; index < census.length; index++) {
                //Composite
                if (census[index].currentMedicalCompositeRate) {
                    currentCompositeTotal = currentCompositeTotal + Number(census[index].currentMedicalCompositeRate);
                }
                if (census[index].renewalMedicalCompositeRate) {
                    renewalCompositeTotal = renewalCompositeTotal + Number(census[index].renewalMedicalCompositeRate);
                }
                //Age
                if (census[index].currentMedicalAgeRate) {
                    currentAgeTotal = currentAgeTotal + Number(census[index].currentMedicalAgeRate);
                }
                if (census[index].renewalMedicalAgeRate) {
                    renewalAgeTotal = renewalAgeTotal + Number(census[index].renewalMedicalAgeRate);
                }
            }
            //formats
            if (currentCompositeTotal > 0) {
                currentCompositeTotal = "$" + Intl.NumberFormat('en-US').format(currentCompositeTotal);
            }
            if (renewalCompositeTotal > 0) {
                renewalCompositeTotal = "$" + Intl.NumberFormat('en-US').format(renewalCompositeTotal);
            }
            if (currentAgeTotal > 0) {
                currentAgeTotal = "$" + Intl.NumberFormat('en-US').format(currentAgeTotal);
            }
            if (renewalAgeTotal > 0) {
                renewalAgeTotal = "$" + Intl.NumberFormat('en-US').format(renewalAgeTotal);
            }
        }

        const { planType, coverageType } = this.props;

        const isAgeRated = this.state.filter[filterInputNames.isAgeRated];

        //new selected plans for select All...  need to grab from props
        const selectedPlansList = planType === planTypes.newPlan ? this.props.selectedPlans[coverageType].map(p => p.id) : selectedPlans;

        const labels = planColumns[(planType === planTypes.newPlan ? "new" + ((isAgeRated && coverageType === "medical") ? "A" : "") : "") + coverageType + "ColumnLabels"];
        const values = planColumns[(planType === planTypes.newPlan ? "new" + ((isAgeRated && coverageType === "medical") ? "A" : "") : "") + coverageType + "ColumnValues"];

        //Can edit some manual or level funded rates...  <Infinite
        const isEditable = planType === planTypes.newPlan && (coverageType === coverageTypes.dental || (coverageType === coverageTypes.medical && !isAgeRated));

        //BASE plans selected?
        let currentMedicalBasePlan = null;
        let renewalMedicalBasePlan = null;
        //BASE Current
        if (currentMedicalPlans.length > 0) {
            //if only one plan chosen... that plan should be considered the base plan
            if (currentMedicalPlans.length === 1) {
                currentMedicalBasePlan = currentMedicalPlans[0];
            } else {
                for (let index = 0; index < currentMedicalPlans.length; index++) {
                    if (currentMedicalPlans[index].isBase === true) {
                        currentMedicalBasePlan = currentMedicalPlans[index];
                        break;
                    }
                    if (currentMedicalBasePlan) {
                        break;
                    }
                    if (selectedBasePlans.length > 0) {
                        for (let index2 = 0; index2 < selectedBasePlans.length; index2++) {
                            if (currentMedicalPlans[index].id === selectedBasePlans[index2]) {
                                currentMedicalBasePlan = currentMedicalPlans[index];
                                break;
                            }
                        }
                    }
                }
            }
        }
        //BASE Renewal
        if (renewalMedicalPlans.length > 0) {
            //if only one plan chosen... that plan should be considered the base plan
            if (renewalMedicalPlans.length === 1) {
                renewalMedicalBasePlan = renewalMedicalPlans[0];
            } else {
                for (let index = 0; index < renewalMedicalPlans.length; index++) {
                    if (renewalMedicalPlans[index].isBase === true) {
                        renewalMedicalBasePlan = renewalMedicalPlans[index];
                        break;
                    }
                    if (renewalMedicalBasePlan) {
                        break;
                    }
                    if (selectedBasePlans.length > 0) {
                        for (let index2 = 0; index2 < selectedBasePlans.length; index2++) {
                            if (renewalMedicalPlans[index].id === selectedBasePlans[index2]) {
                                renewalMedicalBasePlan = renewalMedicalPlans[index];
                                //set renewal for current?
                                if (currentMedicalBasePlan) {
                                    currentMedicalBasePlan.renewalMedicalPlanId = renewalMedicalBasePlan.id;
                                }
                                break;
                            }
                        }
                    }
                }
            }
        }

        //pre-set age rates if available
        if (census.length > 0) {
            //current
            if (currentMedicalBasePlan) {
                for (let index = 0; index < census.length; index++) {
                    const employee = census[index];
                    if (employee && employee.relationship === "EE" && (!employee.currentMedicalPlanId || employee.currentMedicalPlanId === currentMedicalBasePlan.id)) {
                        employee.currentMedicalPlanId = currentMedicalBasePlan.id;
                        if (isAgeRated && currentMedicalBasePlan.ageRates && employee.age && employee.medicalCoverageId < 5) {
                            let age = employee.age - 1;
                            let spot = age <= 14 ? 0 : age >= 64 ? 50 : age - 14;
                            if (!employee.currentMedicalAgeRate) {
                                employee.currentMedicalAgeRate = currentMedicalBasePlan.ageRates.split(',')[spot];
                            }
                            //search for spouse
                            let spouseCount = 0;
                            if (employee.spouseAge && (employee.medicalCoverage === "ES" || employee.medicalCoverage === "EF")) {
                                const spouse = census[index + 1];
                                if (spouse && spouse.relationship === "SP" && spouse.age && !spouse.currentMedicalAgeRate) {
                                    spouseCount = 1;
                                    age = spouse.age - 1;
                                    spot = age <= 14 ? 0 : age >= 65 ? 50 : age - 14;
                                    spouse.currentMedicalAgeRate = currentMedicalBasePlan.ageRates.split(',')[spot];
                                }
                            }
                            //search for children
                            if (employee.numberOfChildren > 0 && (employee.medicalCoverage === "EC" || employee.medicalCoverage === "EF")) {
                                //auto-populate three children under 21
                                const children = sortBy(census.slice(index + spouseCount + 1, index + spouseCount + 1 + employee.numberOfChildren + 1), c => c.age).reverse();
                                let auto = 0;
                                for (let i = 0; i <= employee.numberOfChildren; i++) {
                                    const child = children[i];
                                    if (child && child.relationship === "C" && child.age >= 0) {
                                        age = child.age - 1;
                                        if (age > 20 || (age <= 20 && auto < 3)) {
                                            if (age <= 20) { auto++; }
                                            spot = age <= 14 ? 0 : age - 14;
                                            if (!child.currentMedicalAgeRate) {
                                                child.currentMedicalAgeRate = currentMedicalBasePlan.ageRates.split(',')[spot];
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            //renewal
            if (renewalMedicalBasePlan) {
                for (let index = 0; index < census.length; index++) {
                    const employee = census[index];
                    if (employee && employee.relationship === "EE" && (!employee.renewalMedicalPlanId || employee.renewalMedicalPlanId === renewalMedicalBasePlan.id)) {
                        employee.renewalMedicalPlanId = renewalMedicalBasePlan.id;
                        if (isAgeRated && renewalMedicalBasePlan.ageRates && employee.age && employee.medicalCoverageId < 5) {
                            let age = employee.age;
                            let spot = age <= 14 ? 0 : age >= 64 ? 50 : age - 14;
                            if (!employee.renewalMedicalAgeRate) {
                                employee.renewalMedicalAgeRate = renewalMedicalBasePlan.ageRates.split(',')[spot];
                            }
                            //search for spouse
                            let spouseCount = 0;
                            if (employee.spouseAge && (employee.medicalCoverage === "ES" || employee.medicalCoverage === "EF")) {
                                const spouse = census[index + 1];
                                if (spouse && spouse.relationship === "SP" && spouse.age && !spouse.renewalMedicalAgeRate) {
                                    spouseCount = 1;
                                    age = spouse.age;
                                    spot = age <= 14 ? 0 : age >= 65 ? 50 : age - 14;
                                    spouse.renewalMedicalAgeRate = renewalMedicalBasePlan.ageRates.split(',')[spot];
                                }
                            }
                            //search for children
                            if (employee.numberOfChildren > 0 && (employee.medicalCoverage === "EC" || employee.medicalCoverage === "EF")) {
                                //auto-populate three children under 21
                                const children = sortBy(census.slice(index + spouseCount + 1, index + spouseCount + 1 + employee.numberOfChildren + 1), c => c.age).reverse();
                                let auto = 0;
                                for (let i = 0; i <= employee.numberOfChildren; i++) {
                                    const child = children[i];
                                    if (child && child.relationship === "C" && child.age >= 0) {
                                        age = child.age;
                                        if (age > 20 || (age <= 20 && auto < 3)) {
                                            if (age <= 20) { auto++; }
                                            spot = age <= 14 ? 0 : age - 14;
                                            if (!child.renewalMedicalAgeRate) {
                                                child.renewalMedicalAgeRate = renewalMedicalBasePlan.ageRates.split(',')[spot];
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return (
            <React.Fragment>
                <ToastContainer position="top-center" autoClose={3500} />
                <Modal
                    show={showAddReplacePlansModal}
                    header="Confirm"
                >
                    <div className={css(styles.modalFavoriteContent)}>
                        {this._renderFavoriteActionWarning()}
                        <div className={css(styles.modalFavoriteButtonContainer)}>
                            <ActionButton
                                aStyles={styles.modalButton}
                                label="Cancel"
                                invertColor={true}
                                type="button"
                                onClick={() => this._hideAddOrReplacePlansModal()}
                            />
                            <ActionButton
                                aStyles={styles.modalButton}
                                label="Apply"
                                type="button"
                                onClick={() => this._addFavoritePlans()}
                            />
                        </div>
                    </div>
                </Modal>
                <Modal
                    show={showCurrentPlansModal && coverageType === coverageTypes.medical}
                    header="Current Medical - Assign Plans and Add Rates"
                >
                    <div className={css(styles.modalContent)}>
                        {census && census.length > 0 ? (
                            <div>
                                <div className={css(styles.modalToggle)}>
                                    <span className={css(styles.modalLabel)}>Choose Rates:</span>
                                    <span>
                                        <Toggle
                                            aStyles={styles.toggle}
                                            defaultChecked={isAgeRated}
                                            onChange={this._isAgeRatedChanged}
                                            checkedLabel="Age"
                                            uncheckedLabel="Comp."
                                        />
                                    </span>
                                    <span className={css(styles.modalLabelTotal)}>Total: {isAgeRated ? currentAgeTotal : currentCompositeTotal}</span>
                                    <span className={css(styles.modalLabel)}>{eeCount} employees</span>
                                </div>
                                <div>
                                    {isAgeRated ? null : (
                                        <table className={css(styles.table)}>
                                            <thead>
                                                <tr>
                                                    <th className={css(styles.tableHeader, styles.scrollingTableCellRates)} style={{ width: "60%" }}>Plan Name</th>
                                                    <th className={css(styles.tableHeader, styles.scrollingTableCellRates)} style={{ width: "10%" }}>Employee ({eeTier})</th>
                                                    <th className={css(styles.tableHeader, styles.scrollingTableCellRates)} style={{ width: "10%" }}>EE+Spouse ({esTier})</th>
                                                    <th className={css(styles.tableHeader, styles.scrollingTableCellRates)} style={{ width: "10%" }}>EE+Child ({ecTier})</th>
                                                    <th className={css(styles.tableHeader, styles.scrollingTableCellRates)} style={{ width: "10%" }}>EE+Family ({efTier})</th>
                                                </tr>
                                            </thead>
                                            <tbody className={css(styles.scrollingTableBodyShort)}>
                                                {currentMedicalPlans.map(p => (
                                                    <tr key={p.id}>
                                                        <td className={css(styles.tableCell, styles.scrollingTableCellRates)}>{p.carrierAbbr} ({p.networkName}) {p.displayName} - {p.individualDeductibleIn} ded<br />
                                                            EE:{census.filter(x => x.medicalCoverage === "EE" && x.currentMedicalPlanId === p.id).length} ES:{census.filter(x => x.medicalCoverage === "ES" && x.currentMedicalPlanId === p.id).length} EC:{census.filter(x => x.medicalCoverage === "EC" && x.currentMedicalPlanId === p.id).length} EF:{census.filter(x => x.medicalCoverage === "EF" && x.currentMedicalPlanId === p.id).length}
                                                        </td>
                                                        <td className={css(styles.tableCell, styles.scrollingTableCellRates)}>
                                                            <TextInput
                                                                value={p.currentMedicalCompositeEERate ? p.currentMedicalCompositeEERate.toString() : ""}
                                                                placeholder="- Add Rate -"
                                                                onChange={e => this._setCurrentMedicalCompositeRate(e, p, "EE")}
                                                                hideLabel={true}
                                                                size={inputSizes.small}
                                                            />
                                                        </td>
                                                        <td className={css(styles.tableCell, styles.scrollingTableCellRates)}>
                                                            <TextInput
                                                                value={p.currentMedicalCompositeESRate ? p.currentMedicalCompositeESRate.toString() : ""}
                                                                placeholder="- Add Rate -"
                                                                onChange={e => this._setCurrentMedicalCompositeRate(e, p, "ES")}
                                                                hideLabel={true}
                                                                size={inputSizes.small}
                                                            />
                                                        </td>
                                                        <td className={css(styles.tableCell, styles.scrollingTableCellRates)}>
                                                            <TextInput
                                                                value={p.currentMedicalCompositeECRate ? p.currentMedicalCompositeECRate.toString() : ""}
                                                                placeholder="- Add Rate -"
                                                                onChange={e => this._setCurrentMedicalCompositeRate(e, p, "EC")}
                                                                hideLabel={true}
                                                                size={inputSizes.small}
                                                            />
                                                        </td>
                                                        <td className={css(styles.tableCell, styles.scrollingTableCellRates)}>
                                                            <TextInput
                                                                value={p.currentMedicalCompositeEFRate ? p.currentMedicalCompositeEFRate.toString() : ""}
                                                                placeholder="- Add Rate -"
                                                                onChange={e => this._setCurrentMedicalCompositeRate(e, p, "EF")}
                                                                hideLabel={true}
                                                                size={inputSizes.small}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                    <table className={css(styles.table)}>
                                        <thead>
                                            <tr>
                                                <th className={css(styles.tableHeader, isAgeRated ? styles.scrollingTableCellAgeRated : styles.scrollingTableCell)} style={{ width: "5%" }}>No.</th>
                                                <th className={css(styles.tableHeader, isAgeRated ? styles.scrollingTableCellAgeRated : styles.scrollingTableCell)} style={{ width: "5%" }}>Rel</th>
                                                <th className={css(styles.tableHeader, isAgeRated ? styles.scrollingTableCellAgeRated : styles.scrollingTableCell)} style={{ width: "18%" }}>Name</th>
                                                <th className={css(styles.tableHeader, isAgeRated ? styles.scrollingTableCellAgeRated : styles.scrollingTableCell)} style={{ width: "5%" }}>DOB</th>
                                                <th className={css(styles.tableHeader, isAgeRated ? styles.scrollingTableCellAgeRated : styles.scrollingTableCell)} style={{ width: "5%" }}>Age</th>
                                                <th className={css(styles.tableHeader, isAgeRated ? styles.scrollingTableCellAgeRated : styles.scrollingTableCell)} style={{ width: "5%" }}>Gen</th>
                                                <th className={css(styles.tableHeader, isAgeRated ? styles.scrollingTableCellAgeRated : styles.scrollingTableCell)} style={{ width: "5%" }}>H Zip</th>
                                                <th className={css(styles.tableHeader, isAgeRated ? styles.scrollingTableCellAgeRated : styles.scrollingTableCell)} style={{ width: "45%" }}>Choose Plan Assignment</th>
                                                {isAgeRated ? (
                                                    <th className={css(styles.tableHeader, styles.scrollingTableCellAgeRated)} style={{ width: "7%" }}>Rates</th>
                                                ) : null}
                                            </tr>
                                        </thead>
                                        <tbody className={css(styles.scrollingTableBody)}>
                                            {census.map((c, index) => (
                                                <tr key={c.id} className={css(styles.scrollingTableRow)}>
                                                    <td className={css(styles.tableCell, isAgeRated ? styles.scrollingTableCellAgeRated : styles.scrollingTableCell, (c.relationship === "EE" ? styles.greenBar : styles.blueBar))}>{c.sequenceNumber ? c.sequenceNumber : (index + 1).toString()}</td>
                                                    <td className={css(styles.tableCell, isAgeRated ? styles.scrollingTableCellAgeRated : styles.scrollingTableCell)}>{c.relationship}</td>
                                                    <td className={css(styles.tableCell, isAgeRated ? styles.scrollingTableCellAgeRated : styles.scrollingTableCell, styles.noWrap)}>{c.fullName}</td>
                                                    <td className={css(styles.tableCell, isAgeRated ? styles.scrollingTableCellAgeRated : styles.scrollingTableCell)}>{c.birthDateString}</td>
                                                    <td className={css(styles.tableCell, isAgeRated ? styles.scrollingTableCellAgeRated : styles.scrollingTableCell)}>{c.age - 1}</td>
                                                    <td className={css(styles.tableCell, isAgeRated ? styles.scrollingTableCellAgeRated : styles.scrollingTableCell)}>{c.gender}</td>
                                                    <td className={css(styles.tableCell, isAgeRated ? styles.scrollingTableCellAgeRated : styles.scrollingTableCell)}>{c.homePostalCode}</td>
                                                    <td className={css(styles.tableCell, isAgeRated ? styles.scrollingTableCellAgeRated : styles.scrollingTableCell)}>
                                                        {c.relationship === "EE" && c.medicalCoverageId < 5 ? (
                                                            <SmallDropdown
                                                                aStyles={styles.dropdown}
                                                                options={currentMedicalPlans.map(item => ({
                                                                    label: `${item.carrierAbbr} (${item.networkName}) ${item.displayName} - ${item.individualDeductibleIn} ded`,
                                                                    value: item.id.toString(),
                                                                }))}
                                                                placeholder="Select Plan"
                                                                value={c.currentMedicalPlanId && currentMedicalPlans.find(p => p.id === c.currentMedicalPlanId) ? c.currentMedicalPlanId.toString() : currentMedicalBasePlan ? currentMedicalBasePlan.id.toString() : ""}
                                                                onChange={e => this._setCurrentMedicalPlanId(e, index)}
                                                                hideLabel={true}
                                                                size={inputSizes.large}
                                                            />
                                                        ) : c.relationship === "EE" && c.medicalCoverageId === 5 ? (
                                                            <span className={css(styles.waiving)}>* Waiving</span>
                                                        ) : <span>&nbsp;</span>}
                                                    </td>
                                                    {isAgeRated && c.medicalCoverageId < 5 && c.age >= 0 ? (
                                                        <td className={css(styles.tableCell, styles.scrollingTableCellAgeRated, ((c.relationship === "SP" && (!c.medicalCoverage || c.medicalCoverage !== "SP")) || (c.relationship === "C" && (!c.medicalCoverage || c.medicalCoverage !== "C")) ? styles.scrollingTableCellAbnormal : ""))}>
                                                            <TextInput
                                                                value={c.currentMedicalAgeRate ? c.currentMedicalAgeRate.toString() : ""}
                                                                placeholder={c.relationship === "EE" ? "- Add EE -" : c.relationship === "SP" ? "- Add SP -" : "- Add CH -"}
                                                                onChange={e => this._setCurrentMedicalAgeRate(e, index, c.currentMedicalPlanId ? c.currentMedicalPlanId : currentMedicalBasePlan ? currentMedicalBasePlan.id : null)}
                                                                hideLabel={true}
                                                                size={inputSizes.small}
                                                            />
                                                        </td>
                                                    ) : isAgeRated && (c.medicalCoverageId === 5 || c.age === 0) ? (
                                                        <td className={css(styles.tableCell, styles.scrollingTableCellAgeRated)}>&nbsp;</td>
                                                    ) : null}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className={css(styles.modalButtonContainer)}>
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.cancelButtonText}
                            invertColor={true}
                            type="button"
                            onClick={() => this.setState({ showCurrentPlansModal: false })}
                        />
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.saveButtonText}
                            type="button"
                            onClick={this._saveCensusMapping}
                            isLoading={this.state.isSaving}
                        />
                    </div>
                </Modal>
                <Modal
                    show={showCurrentPlansModal && coverageType === coverageTypes.dental}
                    header="Current Dental - Assign Plans and Add Rates"
                >
                    <div className={css(styles.modalContent)}>
                        {census && census.length > 0 ? (
                            <div>
                                <div className={css(styles.modalToggle)}>
                                    <span className={css(styles.modalLabel)}>{eeCount} employees</span>
                                </div>
                                <div>
                                    <table className={css(styles.table)}>
                                        <thead>
                                            <tr>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCellRates)} style={{ width: "60%" }}>Plan Name</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCellRates)} style={{ width: "10%" }}>Employee</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCellRates)} style={{ width: "10%" }}>EE+Spouse</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCellRates)} style={{ width: "10%" }}>EE+Child</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCellRates)} style={{ width: "10%" }}>EE+Family</th>
                                            </tr>
                                        </thead>
                                        <tbody className={css(styles.scrollingTableBodyShort)}>
                                            {currentDentalPlans.map(p => (
                                                <tr key={p.id}>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCellRates)}>{p.carrierAbbr} ({p.networkName}) {p.displayName}</td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCellRates)}>
                                                        <TextInput
                                                            value={p.currentDentalCompositeEERate ? p.currentDentalCompositeEERate.toString() : ""}
                                                            placeholder="- Add Rate -"
                                                            onChange={e => this._setCurrentDentalCompositeRate(e, p, "EE")}
                                                            hideLabel={true}
                                                            size={inputSizes.small}
                                                        />
                                                    </td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCellRates)}>
                                                        <TextInput
                                                            value={p.currentDentalCompositeESRate ? p.currentDentalCompositeESRate.toString() : ""}
                                                            placeholder="- Add Rate -"
                                                            onChange={e => this._setCurrentDentalCompositeRate(e, p, "ES")}
                                                            hideLabel={true}
                                                            size={inputSizes.small}
                                                        />
                                                    </td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCellRates)}>
                                                        <TextInput
                                                            value={p.currentDentalCompositeECRate ? p.currentDentalCompositeECRate.toString() : ""}
                                                            placeholder="- Add Rate -"
                                                            onChange={e => this._setCurrentDentalCompositeRate(e, p, "EC")}
                                                            hideLabel={true}
                                                            size={inputSizes.small}
                                                        />
                                                    </td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCellRates)}>
                                                        <TextInput
                                                            value={p.currentDentalCompositeEFRate ? p.currentDentalCompositeEFRate.toString() : ""}
                                                            placeholder="- Add Rate -"
                                                            onChange={e => this._setCurrentDentalCompositeRate(e, p, "EF")}
                                                            hideLabel={true}
                                                            size={inputSizes.small}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <table className={css(styles.table)}>
                                        <thead>
                                            <tr>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCell)} style={{ width: "5%" }}>No.</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCell)} style={{ width: "5%" }}>Rel</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCell)} style={{ width: "20%" }}>Name</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCell)} style={{ width: "5%" }}>DOB</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCell)} style={{ width: "5%" }}>Age</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCell)} style={{ width: "5%" }}>Gen</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCell)} style={{ width: "5%" }}>H Zip</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCell)} style={{ width: "50%" }}>Choose Plan Assignment</th>
                                            </tr>
                                        </thead>
                                        <tbody className={css(styles.scrollingTableBody)}>
                                            {census.map((c, index) => (
                                                <tr key={c.id} className={css(styles.scrollingTableRow)}>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCell, (c.relationship === "EE" ? styles.greenBar : styles.blueBar))}>{c.sequenceNumber ? c.sequenceNumber : (index + 1).toString()}</td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCell)}>{c.relationship}</td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCell, styles.noWrap)}>{c.fullName}</td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCell)}>{c.birthDateString}</td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCell)}>{c.age - 1}</td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCell)}>{c.gender}</td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCell)}>{c.homePostalCode}</td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCell)}>
                                                        {c.relationship === "EE" && c.dentalCoverageId < 5 ? (
                                                            <SmallDropdown
                                                                aStyles={styles.dropdown}
                                                                options={currentDentalPlans.map(item => ({
                                                                    label: `${item.carrierAbbr} (${item.networkName}) ${item.displayName}`,
                                                                    value: item.id.toString(),
                                                                }))}
                                                                placeholder="Select Plan"
                                                                value={c.currentDentalPlanId && currentDentalPlans.find(p => p.id === c.currentDentalPlanId) ? c.currentDentalPlanId.toString() : currentDentalPlans.length === 1 ? currentDentalPlans[0].id.toString() : ""}
                                                                onChange={e => this._setCurrentDentalPlanId(e, index)}
                                                                hideLabel={true}
                                                                size={inputSizes.large}
                                                            />
                                                        ) : c.relationship === "EE" && c.dentalCoverageId === 5 ? (
                                                            <span className={css(styles.waiving)}>* Waiving</span>
                                                        ) : <span>&nbsp;</span>}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className={css(styles.modalButtonContainer)}>
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.cancelButtonText}
                            invertColor={true}
                            type="button"
                            onClick={() => this.setState({ showCurrentPlansModal: false })}
                        />
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.saveButtonText}
                            type="button"
                            onClick={this._saveCensusMapping}
                            isLoading={this.state.isSaving}
                        />
                    </div>
                </Modal>
                <Modal
                    show={showCurrentPlansModal && coverageType === coverageTypes.vision}
                    header="Current Vision - Assign Plans and Add Rates"
                >
                    <div className={css(styles.modalContent)}>
                        {census && census.length > 0 ? (
                            <div>
                                <div className={css(styles.modalToggle)}>
                                    <span className={css(styles.modalLabel)}>{eeCount} employees</span>
                                </div>
                                <div>
                                    <table className={css(styles.table)}>
                                        <thead>
                                            <tr>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCellRates)} style={{ width: "60%" }}>Plan Name</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCellRates)} style={{ width: "10%" }}>Employee</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCellRates)} style={{ width: "10%" }}>EE+Spouse</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCellRates)} style={{ width: "10%" }}>EE+Child</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCellRates)} style={{ width: "10%" }}>EE+Family</th>
                                            </tr>
                                        </thead>
                                        <tbody className={css(styles.scrollingTableBodyShort)}>
                                            {currentVisionPlans.map(p => (
                                                <tr key={p.id}>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCellRates)}>{p.carrierAbbr} ({p.networkName}) {p.displayName}</td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCellRates)}>
                                                        <TextInput
                                                            value={p.currentVisionCompositeEERate ? p.currentVisionCompositeEERate.toString() : ""}
                                                            placeholder="- Add Rate -"
                                                            onChange={e => this._setCurrentVisionCompositeRate(e, p, "EE")}
                                                            hideLabel={true}
                                                            size={inputSizes.small}
                                                        />
                                                    </td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCellRates)}>
                                                        <TextInput
                                                            value={p.currentVisionCompositeESRate ? p.currentVisionCompositeESRate.toString() : ""}
                                                            placeholder="- Add Rate -"
                                                            onChange={e => this._setCurrentVisionCompositeRate(e, p, "ES")}
                                                            hideLabel={true}
                                                            size={inputSizes.small}
                                                        />
                                                    </td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCellRates)}>
                                                        <TextInput
                                                            value={p.currentVisionCompositeECRate ? p.currentVisionCompositeECRate.toString() : ""}
                                                            placeholder="- Add Rate -"
                                                            onChange={e => this._setCurrentVisionCompositeRate(e, p, "EC")}
                                                            hideLabel={true}
                                                            size={inputSizes.small}
                                                        />
                                                    </td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCellRates)}>
                                                        <TextInput
                                                            value={p.currentVisionCompositeEFRate ? p.currentVisionCompositeEFRate.toString() : ""}
                                                            placeholder="- Add Rate -"
                                                            onChange={e => this._setCurrentVisionCompositeRate(e, p, "EF")}
                                                            hideLabel={true}
                                                            size={inputSizes.small}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <table className={css(styles.table)}>
                                        <thead>
                                            <tr>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCell)} style={{ width: "5%" }}>No.</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCell)} style={{ width: "5%" }}>Rel</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCell)} style={{ width: "20%" }}>Name</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCell)} style={{ width: "5%" }}>DOB</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCell)} style={{ width: "5%" }}>Age</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCell)} style={{ width: "5%" }}>Gen</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCell)} style={{ width: "5%" }}>H Zip</th>
                                                <th className={css(styles.tableHeader, styles.scrollingTableCell)} style={{ width: "50%" }}>Choose Plan Assignment</th>
                                            </tr>
                                        </thead>
                                        <tbody className={css(styles.scrollingTableBody)}>
                                            {census.map((c, index) => (
                                                <tr key={c.id} className={css(styles.scrollingTableRow)}>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCell, (c.relationship === "EE" ? styles.greenBar : styles.blueBar))}>{c.sequenceNumber ? c.sequenceNumber : (index + 1).toString()}</td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCell)}>{c.relationship}</td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCell, styles.noWrap)}>{c.fullName}</td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCell)}>{c.birthDateString}</td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCell)}>{c.age - 1}</td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCell)}>{c.gender}</td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCell)}>{c.homePostalCode}</td>
                                                    <td className={css(styles.tableCell, styles.scrollingTableCell)}>
                                                        {c.relationship === "EE" && c.visionCoverageId < 5 ? (
                                                            <SmallDropdown
                                                                aStyles={styles.dropdown}
                                                                options={currentVisionPlans.map(item => ({
                                                                    label: `${item.carrierAbbr} (${item.networkName}) ${item.displayName}`,
                                                                    value: item.id.toString(),
                                                                }))}
                                                                placeholder="Select Plan"
                                                                value={c.currentVisionPlanId && currentVisionPlans.find(p => p.id === c.currentVisionPlanId) ? c.currentVisionPlanId.toString() : currentVisionPlans.length === 1 ? currentVisionPlans[0].id.toString() : ""}
                                                                onChange={e => this._setCurrentVisionPlanId(e, index)}
                                                                hideLabel={true}
                                                                size={inputSizes.large}
                                                            />
                                                        ) : c.relationship === "EE" && c.visionCoverageId === 5 ? (
                                                            <span className={css(styles.waiving)}>* Waiving</span>
                                                        ) : <span>&nbsp;</span>}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className={css(styles.modalButtonContainer)}>
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.cancelButtonText}
                            invertColor={true}
                            type="button"
                            onClick={() => this.setState({ showCurrentPlansModal: false })}
                        />
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.saveButtonText}
                            type="button"
                            onClick={this._saveCensusMapping}
                            isLoading={this.state.isSaving}
                        />
                    </div>
                </Modal>
                <Modal
                    show={showRenewalPlansModal && coverageType === coverageTypes.medical}
                    header="Medical Renewal Plans - Mapping and Rates"
                >
                    <div className={css(styles.modalContent)}>
                        {currentMedicalPlans && renewalMedicalPlans ? (
                            <div>
                                <div className={css(styles.modalToggle)}>
                                    <span className={css(styles.modalLabel)}>Choose Rates:</span>
                                    <span>
                                        <Toggle
                                            aStyles={styles.toggle}
                                            defaultChecked={isAgeRated}
                                            onChange={this._isAgeRatedChanged}
                                            checkedLabel="Age"
                                            uncheckedLabel="Comp."
                                        />
                                    </span>
                                    <span className={css(styles.modalLabelTotal)}>Total: {isAgeRated ? renewalAgeTotal : renewalCompositeTotal}</span>
                                    <span className={css(styles.modalLabel)}>{eeCount} employees</span>
                                </div>
                                <div>
                                    <table className={css(styles.table)}>
                                        <thead>
                                            <tr>
                                                <th className={css(styles.tableHeader)} style={{ width: isAgeRated ? "40%" : "25%" }}>Current Plans</th>
                                                <th className={css(styles.tableHeader)} style={{ width: isAgeRated ? "60%" : "35%" }}>Select renewal plans</th>
                                                {isAgeRated ? null : (
                                                    <th className={css(styles.tableHeader)} colSpan={4} style={{ width: "40%" }}>Add composite rates (EE:{eeTier} ES:{esTier} EC:{ecTier} EF:{efTier})</th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentMedicalPlans.map((c, index) => (
                                                <tr key={c.id}>
                                                    <td className={css(styles.tableCell)}>{c.carrierAbbr} ({c.networkName}) {c.displayName} - {c.individualDeductibleIn} ded<br />
                                                        EE:{census.filter(x => x.medicalCoverage === "EE" && x.currentMedicalPlanId === c.id).length} ES:{census.filter(x => x.medicalCoverage === "ES" && x.currentMedicalPlanId === c.id).length} EC:{census.filter(x => x.medicalCoverage === "EC" && x.currentMedicalPlanId === c.id).length} EF:{census.filter(x => x.medicalCoverage === "EF" && x.currentMedicalPlanId === c.id).length}
                                                    </td>
                                                    <td className={css(styles.tableCell)}>
                                                        <SmallDropdown
                                                            aStyles={styles.dropdown}
                                                            options={renewalMedicalPlans.map(item => ({
                                                                label: `${item.carrierAbbr} (${item.networkName}) ${item.displayName} - ${item.individualDeductibleIn} ded`,
                                                                value: item.id.toString(),
                                                            }))}
                                                            placeholder="Select Plan"
                                                            value={c.renewalMedicalPlanId && renewalMedicalPlans.find(p => p.id === c.renewalMedicalPlanId) ? c.renewalMedicalPlanId.toString() : renewalMedicalBasePlan ? renewalMedicalBasePlan.id.toString() : ""}
                                                            onChange={e => this._setRenewalMedicalPlanId(e, c, index)}
                                                            hideLabel={true}
                                                            size={inputSizes.large}
                                                        />
                                                    </td>
                                                    {isAgeRated ? null : (
                                                        <td className={css(styles.tableCell)}>
                                                            <TextInput
                                                                value={c.renewalMedicalCompositeEERate ? c.renewalMedicalCompositeEERate.toString() : ""}
                                                                placeholder="- Add EE -"
                                                                onChange={e => this._setRenewalMedicalCompositeRate(e, c, index, "EE")}
                                                                hideLabel={true}
                                                                size={inputSizes.small}
                                                            />
                                                        </td>
                                                    )}
                                                    {isAgeRated ? null : (
                                                        <td className={css(styles.tableCell)}>
                                                            <TextInput
                                                                value={c.renewalMedicalCompositeESRate ? c.renewalMedicalCompositeESRate.toString() : ""}
                                                                placeholder="- Add ES -"
                                                                onChange={e => this._setRenewalMedicalCompositeRate(e, c, index, "ES")}
                                                                hideLabel={true}
                                                                size={inputSizes.small}
                                                            />
                                                        </td>
                                                    )}
                                                    {isAgeRated ? null : (
                                                        <td className={css(styles.tableCell)}>
                                                            <TextInput
                                                                value={c.renewalMedicalCompositeECRate ? c.renewalMedicalCompositeECRate.toString() : ""}
                                                                placeholder="- Add EC -"
                                                                onChange={e => this._setRenewalMedicalCompositeRate(e, c, index, "EC")}
                                                                hideLabel={true}
                                                                size={inputSizes.small}
                                                            />
                                                        </td>
                                                    )}
                                                    {isAgeRated ? null : (
                                                        <td className={css(styles.tableCell)}>
                                                            <TextInput
                                                                value={c.renewalMedicalCompositeEFRate ? c.renewalMedicalCompositeEFRate.toString() : ""}
                                                                placeholder="- Add EF -"
                                                                onChange={e => this._setRenewalMedicalCompositeRate(e, c, index, "EF")}
                                                                hideLabel={true}
                                                                size={inputSizes.small}
                                                            />
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {isAgeRated && census && census.length > 0 ? (
                                        <table className={css(styles.table)}>
                                            <thead>
                                                <tr>
                                                    <th className={css(styles.tableHeader, styles.scrollingTableCellAgeRated)} style={{ width: "5%" }}>No.</th>
                                                    <th className={css(styles.tableHeader, styles.scrollingTableCellAgeRated)} style={{ width: "5%" }}>Rel</th>
                                                    <th className={css(styles.tableHeader, styles.scrollingTableCellAgeRated)} style={{ width: "18%" }}>Name</th>
                                                    <th className={css(styles.tableHeader, styles.scrollingTableCellAgeRated)} style={{ width: "5%" }}>DOB</th>
                                                    <th className={css(styles.tableHeader, styles.scrollingTableCellAgeRated)} style={{ width: "5%" }}>Age</th>
                                                    <th className={css(styles.tableHeader, styles.scrollingTableCellAgeRated)} style={{ width: "5%" }}>Gen</th>
                                                    <th className={css(styles.tableHeader, styles.scrollingTableCellAgeRated)} style={{ width: "5%" }}>H Zip</th>
                                                    <th className={css(styles.tableHeader, styles.scrollingTableCellAgeRated)} style={{ width: "45%" }}>Renewal Plan Name</th>
                                                    <th className={css(styles.tableHeader, styles.scrollingTableCellAgeRated)} style={{ width: "7%" }}>Add Rates:</th>
                                                </tr>
                                            </thead>
                                            <tbody className={css(styles.scrollingTableBody)}>
                                                {census.map((c, index) => (
                                                    <tr key={c.id}>
                                                        <td className={css(styles.tableCell, styles.scrollingTableCellAgeRated, (c.relationship === "EE" ? styles.greenBar : styles.blueBar))}>{c.sequenceNumber ? c.sequenceNumber : (index + 1).toString()}</td>
                                                        <td className={css(styles.tableCell, styles.scrollingTableCellAgeRated)}>{c.relationship}</td>
                                                        <td className={css(styles.tableCell, styles.scrollingTableCellAgeRated, styles.noWrap)}>{c.fullName}</td>
                                                        <td className={css(styles.tableCell, styles.scrollingTableCellAgeRated)}>{c.birthDateString}</td>
                                                        <td className={css(styles.tableCell, styles.scrollingTableCellAgeRated)}>{c.age}</td>
                                                        <td className={css(styles.tableCell, styles.scrollingTableCellAgeRated)}>{c.gender}</td>
                                                        <td className={css(styles.tableCell, styles.scrollingTableCellAgeRated)}>{c.homePostalCode}</td>
                                                        <td className={css(styles.tableCell, styles.scrollingTableCellAgeRated)}>{c.renewalMedicalPlanId > 0 && renewalMedicalPlans.find(plan => plan.id === parseInt(c.renewalMedicalPlanId)) ? renewalMedicalPlans.find(plan => plan.id === parseInt(c.renewalMedicalPlanId)).networkName + " " + renewalMedicalPlans.find(plan => plan.id === parseInt(c.renewalMedicalPlanId)).displayName : null}</td>
                                                        <td className={css(styles.tableCell, styles.scrollingTableCellAgeRated, ((c.relationship === "SP" && (!c.medicalCoverage || c.medicalCoverage !== "SP")) || (c.relationship === "C" && (!c.medicalCoverage || c.medicalCoverage !== "C")) ? styles.scrollingTableCellAbnormal : ""))}>
                                                            {c.medicalCoverageId < 5 ? (
                                                                <TextInput
                                                                    value={c.renewalMedicalAgeRate ? c.renewalMedicalAgeRate.toString() : ""}
                                                                    placeholder={c.relationship === "EE" ? "- Add EE -" : c.relationship === "SP" ? "- Add SP -" : "- Add CH -"}
                                                                    onChange={e => this._setRenewalMedicalAgeRate(e, index, c.renewalMedicalPlanId ? c.renewalMedicalPlanId : renewalMedicalBasePlan ? renewalMedicalBasePlan.id : null)}
                                                                    hideLabel={true}
                                                                    size={inputSizes.small}
                                                                />
                                                            ) : c.relationship === "EE" && c.medicalCoverageId === 5 ? (
                                                                <span className={css(styles.waiving)}>* Waiving</span>
                                                            ) : <span>&nbsp;</span>}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className={css(styles.modalButtonContainer)}>
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.cancelButtonText}
                            invertColor={true}
                            type="button"
                            onClick={() => this.setState({ showRenewalPlansModal: false })}
                        />
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.saveButtonText}
                            type="button"
                            onClick={this._saveCensusMapping}
                            isLoading={this.state.isSaving}
                        />
                    </div>
                </Modal>
                <Modal
                    show={showRenewalPlansModal && coverageType === coverageTypes.dental}
                    header="Dental Renewal Plans - Mapping and Rates"
                >
                    <div className={css(styles.modalContent)}>
                        {currentDentalPlans && renewalDentalPlans ? (
                            <div>
                                <div className={css(styles.modalToggle)}>
                                    <span className={css(styles.modalLabel)}>{eeCount} employees</span>
                                </div>
                                <div>
                                    <table className={css(styles.table)}>
                                        <thead>
                                            <tr>
                                                <th className={css(styles.tableHeader)} style={{ width: "35%" }}>Current Plans</th>
                                                <th className={css(styles.tableHeader)} style={{ width: "40%" }}>Select renewal plans</th>
                                                <th className={css(styles.tableHeader)} colSpan={4} style={{ width: "25%" }}>Add composite rates</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentDentalPlans.map((c, index) => (
                                                <tr key={c.id}>
                                                    <td className={css(styles.tableCell)}>{c.carrierAbbr} ({c.networkName}) {c.displayName}</td>
                                                    <td className={css(styles.tableCell)}>
                                                        <SmallDropdown
                                                            aStyles={styles.dropdown}
                                                            options={renewalDentalPlans.map(item => ({
                                                                label: `${item.carrierAbbr} (${item.networkName}) ${item.displayName}`,
                                                                value: item.id.toString(),
                                                            }))}
                                                            placeholder="Select Plan"
                                                            value={c.renewalDentalPlanId && renewalDentalPlans.find(p => p.id === c.renewalDentalPlanId) ? c.renewalDentalPlanId.toString() : renewalDentalPlans.length === 1 ? renewalDentalPlans[0].id.toString() : ""}
                                                            onChange={e => this._setRenewalDentalPlanId(e, c, index)}
                                                            hideLabel={true}
                                                            size={inputSizes.large}
                                                        />
                                                    </td>
                                                    <td className={css(styles.tableCell)}>
                                                        <TextInput
                                                            value={c.renewalDentalCompositeEERate ? c.renewalDentalCompositeEERate.toString() : ""}
                                                            placeholder="- Add EE -"
                                                            onChange={e => this._setRenewalDentalCompositeRate(e, c, index, "EE")}
                                                            hideLabel={true}
                                                            size={inputSizes.small}
                                                        />
                                                    </td>
                                                    <td className={css(styles.tableCell)}>
                                                        <TextInput
                                                            value={c.renewalDentalCompositeESRate ? c.renewalDentalCompositeESRate.toString() : ""}
                                                            placeholder="- Add ES -"
                                                            onChange={e => this._setRenewalDentalCompositeRate(e, c, index, "ES")}
                                                            hideLabel={true}
                                                            size={inputSizes.small}
                                                        />
                                                    </td>
                                                    <td className={css(styles.tableCell)}>
                                                        <TextInput
                                                            value={c.renewalDentalCompositeECRate ? c.renewalDentalCompositeECRate.toString() : ""}
                                                            placeholder="- Add EC -"
                                                            onChange={e => this._setRenewalDentalCompositeRate(e, c, index, "EC")}
                                                            hideLabel={true}
                                                            size={inputSizes.small}
                                                        />
                                                    </td>
                                                    <td className={css(styles.tableCell)}>
                                                        <TextInput
                                                            value={c.renewalDentalCompositeEFRate ? c.renewalDentalCompositeEFRate.toString() : ""}
                                                            placeholder="- Add EF -"
                                                            onChange={e => this._setRenewalDentalCompositeRate(e, c, index, "EF")}
                                                            hideLabel={true}
                                                            size={inputSizes.small}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className={css(styles.modalButtonContainer)}>
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.cancelButtonText}
                            invertColor={true}
                            type="button"
                            onClick={() => this.setState({ showRenewalPlansModal: false })}
                        />
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.saveButtonText}
                            type="button"
                            onClick={this._saveCensusMapping}
                            isLoading={this.state.isSaving}
                        />
                    </div>
                </Modal>
                <Modal show={showRenewalPlansModal && coverageType === coverageTypes.vision} header="Vision Renewal Plans - Mapping and Rates">
                    <div className={css(styles.modalContent)}>
                        {currentVisionPlans && renewalVisionPlans ? (
                            <div>
                                <div className={css(styles.modalToggle)}>
                                    <span className={css(styles.modalLabel)}>{eeCount} employees</span>
                                </div>
                                <div>
                                    <table className={css(styles.table)}>
                                        <thead>
                                            <tr>
                                                <th className={css(styles.tableHeader)} style={{ width: "35%" }}>Current Plans</th>
                                                <th className={css(styles.tableHeader)} style={{ width: "40%" }}>Select renewal plans</th>
                                                <th className={css(styles.tableHeader)} colSpan={4} style={{ width: "25%" }}>Add composite rates</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentVisionPlans.map((c, index) => (
                                                <tr key={c.id}>
                                                    <td className={css(styles.tableCell)}>{c.carrierAbbr} ({c.networkName}) {c.displayName}</td>
                                                    <td className={css(styles.tableCell)}>
                                                        <SmallDropdown
                                                            aStyles={styles.dropdown}
                                                            options={renewalVisionPlans.map(item => ({
                                                                label: `${item.carrierAbbr} (${item.networkName}) ${item.displayName}`,
                                                                value: item.id.toString(),
                                                            }))}
                                                            placeholder="Select Plan"
                                                            value={c.renewalVisionPlanId && renewalVisionPlans.find(p => p.id === c.renewalVisionPlanId) ? c.renewalVisionPlanId.toString() : renewalVisionPlans.length === 1 ? renewalVisionPlans[0].id.toString() : ""}
                                                            onChange={e => this._setRenewalVisionPlanId(e, c, index)}
                                                            hideLabel={true}
                                                            size={inputSizes.large}
                                                        />
                                                    </td>
                                                    <td className={css(styles.tableCell)}>
                                                        <TextInput
                                                            value={c.renewalVisionCompositeEERate ? c.renewalVisionCompositeEERate.toString() : ""}
                                                            placeholder="- Add EE -"
                                                            onChange={e => this._setRenewalVisionCompositeRate(e, c, index, "EE")}
                                                            hideLabel={true}
                                                            size={inputSizes.small}
                                                        />
                                                    </td>
                                                    <td className={css(styles.tableCell)}>
                                                        <TextInput
                                                            value={c.renewalVisionCompositeESRate ? c.renewalVisionCompositeESRate.toString() : ""}
                                                            placeholder="- Add ES -"
                                                            onChange={e => this._setRenewalVisionCompositeRate(e, c, index, "ES")}
                                                            hideLabel={true}
                                                            size={inputSizes.small}
                                                        />
                                                    </td>
                                                    <td className={css(styles.tableCell)}>
                                                        <TextInput
                                                            value={c.renewalVisionCompositeECRate ? c.renewalVisionCompositeECRate.toString() : ""}
                                                            placeholder="- Add EC -"
                                                            onChange={e => this._setRenewalVisionCompositeRate(e, c, index, "EC")}
                                                            hideLabel={true}
                                                            size={inputSizes.small}
                                                        />
                                                    </td>
                                                    <td className={css(styles.tableCell)}>
                                                        <TextInput
                                                            value={c.renewalVisionCompositeEFRate ? c.renewalVisionCompositeEFRate.toString() : ""}
                                                            placeholder="- Add EF -"
                                                            onChange={e => this._setRenewalVisionCompositeRate(e, c, index, "EF")}
                                                            hideLabel={true}
                                                            size={inputSizes.small}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className={css(styles.modalButtonContainer)}>
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.cancelButtonText}
                            invertColor={true}
                            type="button"
                            onClick={() => this.setState({ showRenewalPlansModal: false })}
                        />
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.saveButtonText}
                            type="button"
                            onClick={this._saveCensusMapping}
                            isLoading={this.state.isSaving}
                        />
                    </div>
                </Modal>
                <Modal show={showManualModal} header="Input Manual Composite Rates">
                    <div className={css(styles.modalContentSmall)}>
                        <div className={css(styles.modalTitle)}>Plan: {manualPlanName}</div>
                        <table className={css(styles.table)}>
                            <thead>
                                <tr>
                                    <th className={css(styles.tableHeader)}>Employee</th>
                                    <th className={css(styles.tableHeader)}>EE + Spouse</th>
                                    <th className={css(styles.tableHeader)}>EE + Child</th>
                                    <th className={css(styles.tableHeader)}>EE + Family</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={css(styles.tableCell)}>
                                        <TextInput
                                            value={this.state.manualEE ? this.state.manualEE.toString() : ""}
                                            placeholder="- Add -"
                                            onChange={e => this._setManualRate(e, "EE")}
                                            hideLabel={true}
                                            size={inputSizes.small}
                                        />
                                    </td>
                                    <td className={css(styles.tableCell)}>
                                        <TextInput
                                            value={this.state.manualES ? this.state.manualES.toString() : ""}
                                            placeholder="- Add -"
                                            onChange={e => this._setManualRate(e, "ES")}
                                            hideLabel={true}
                                            size={inputSizes.small}
                                        />
                                    </td>
                                    <td className={css(styles.tableCell)}>
                                        <TextInput
                                            value={this.state.manualEC ? this.state.manualEC.toString() : ""}
                                            placeholder="- Add -"
                                            onChange={e => this._setManualRate(e, "EC")}
                                            hideLabel={true}
                                            size={inputSizes.small}
                                        />
                                    </td>
                                    <td className={css(styles.tableCell)}>
                                        <TextInput
                                            value={this.state.manualEF}
                                            placeholder="- Add -"
                                            onChange={e => this._setManualRate(e, "EF")}
                                            hideLabel={true}
                                            size={inputSizes.small}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={css(styles.modalButtonContainer)}>
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.cancelButtonText}
                            invertColor={true}
                            type="button"
                            onClick={() => this.setState({ showManualModal: false })}
                        />
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.applyRatesButtonText}
                            type="button"
                            onClick={this._saveManualCompositeRates}
                            isLoading={this.state.isSaving}
                            disabled={!this.state.manualEE || !this.state.manualES || !this.state.manualEC || !this.state.manualEF}
                        />
                    </div>
                </Modal>
                <Modal
                    show={showSaveSpreadsheetModal}
                    header="Save Spreadsheet"
                >
                    <div className={css(styles.modalContentSave)}>
                        <div className={css(styles.modalInstructions)}>
                            {strings.spreadsheetNameInstructions}
                        </div>
                        <TextInput
                            value={this.state.spreadsheetName}
                            placeholder={strings.spreadsheetNamePlaceholder}
                            onChange={this._setSpreadsheetName}
                            hideLabel={true}
                            size={inputSizes.small}
                            maxLength={30}
                        />
                    </div>
                    <div className={css(styles.modalButtonContainer2)}>
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.cancelButtonText}
                            invertColor={true}
                            type="button"
                            onClick={() => this.setState({ showSaveSpreadsheetModal: false })}
                        />
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.saveButtonText}
                            onClick={this._saveSpreadsheetClicked}
                            type="button"
                            isLoading={this.state.isSaving}
                        />
                    </div>
                </Modal>
                <div className={css(styles.planContainer)}>
                    {this._renderFilterControl()}

                    {count === 0 ? (
                        <div className={css(styles.noResults)}>No matching plans or no census elections for this line of coverage</div>
                    ) : (
                        <div>
                            <div className={css(styles.right)}>{parseInt(count).toLocaleString()}{" "}Plans</div>
                            <div className={css(styles.contain)} ref={this._planContainerRef}>
                                {!this.state.isUpdating ? (
                                    <Infinite
                                        results={plans}
                                        length={count}
                                        columnLabels={labels}
                                        columnValues={values}
                                        showChevron={false}
                                        checkBox={true}
                                        checkList={selectedPlansList}
                                        showMore={showMore}
                                        onClick={this._rowClicked}
                                        onScroll={this._onScroll}
                                        showBasePlan={planType !== planTypes.newPlan && coverageType === coverageTypes.medical ? true : false}
                                        onBasePlanClick={this._basePlanClicked}
                                        basePlanList={selectedBasePlans}
                                        isEditable={isEditable}
                                        onManualRatesClick={this._manualClicked}
                                        showAllChecked={planType === planTypes.newPlan}
                                        allChecked={this.state.allChecked}
                                        onAllClick={this._allClicked}
                                    />)
                                    : (
                                        <Spinner
                                            aStyles={styles.loadingSpinner}
                                            text="Updating..."
                                        />
                                    )}
                            </div>
                            {this._renderPlanButtons()}
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    selectedPlans: state.proposal.selectedPlans,
    selectedBasePlans: state.proposal.selectedBasePlans,
    carriers: state.proposal.carriers,
    planTypes: state.proposal.planTypes,
    networks: state.proposal.networks,
    minMaxFilters: state.proposal.minMaxFilters,
    planTypesD: state.proposal.planTypesD,
    coinsurancesD: state.proposal.coinsurancesD,
    deductiblesD: state.proposal.deductiblesD,
    perioEndosD: state.proposal.perioEndosD,
    annualMaximumsD: state.proposal.annualMaximumsD,
    orthoMaximumsD: state.proposal.orthoMaximumsD,
    reimbursementsD: state.proposal.reimbursementsD,
    examCopaysV: state.proposal.examCopaysV,
    fundingTypesV: state.proposal.fundingTypesV,
    materialCopaysV: state.proposal.materialCopaysV,
    frameAllowancesV: state.proposal.frameAllowancesV,
    contactLensAllowancesV: state.proposal.contactLensAllowancesV,
    frameFrequenciesV: state.proposal.frameFrequenciesV,
    favoritePlans: state.proposal.favoritePlans,
    favoritePlanMode: state.proposal.favoritePlanMode,
    favoritesApplied: state.proposal.favoritesApplied,
});

export default connect(
    mapStateToProps,
    { setQuote: proposalActions.setQuote, setSelectedPlans: proposalActions.setSelectedPlans, setSelectedBasePlans: proposalActions.setSelectedBasePlans, setFavoritesApplied: proposalActions.setFavoritesApplied },
)(Plans);
