import * as sessionActions from "../actions/sessionActions";
import Authenticated from "./templates/Authenticated";
import logger from "../lib/logger";
import PrivateRoute from "./router/PrivateRoute";
import PropTypes from "prop-types";
import PublicRoute from "./router/PublicRoute";
import React, { Component } from "react";
import ScrollToTop from "./router/ScrollToTop";
import Unauthenticated from "./templates/Unauthenticated";
import { BrowserRouter, Switch } from "react-router-dom";
import { connect } from "react-redux";

// eslint-disable-next-line  no-unused-vars
import DebugGrid from "./DebugGrid";

export class App extends Component {
    static propTypes = {
        loadSavedAuth: PropTypes.func.isRequired,
        loadStates: PropTypes.func,
    };

    state = {
        ready: false,
    };

    componentDidMount() {
        Promise.all([this.props.loadSavedAuth()])
            .catch(err => logger.warn(err))
            .finally(() => this.setState({ ready: true }));
    }

    render() {
        if (!this.state.ready) {
            // TODO: Show loading indicator
            return null;
        }

        return (
            <BrowserRouter>
                <ScrollToTop>
                    <Switch>
                        <PublicRoute path="/account" component={Unauthenticated} />
                        <PrivateRoute component={Authenticated} />
                    </Switch>

                    {/*<DebugGrid />*/}
                </ScrollToTop>
            </BrowserRouter>
        );
    }
}

export default connect(
    null,
    { loadSavedAuth: sessionActions.loadSavedAuth },
)(App);
