import * as routeHelpers from "../../lib/routeHelpers";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export function PrivateRoute(props) {
    const { component: Component, isAuthenticated, ...rest } = props;
    return (
        <Route
            {...rest}
            // eslint-disable-next-line react/jsx-no-bind
            render={innerProps =>
                isAuthenticated ? (
                    <Component {...innerProps} />
                ) : (
                    <Redirect
                        to={{
                            pathname: routeHelpers.login(),
                            state: {
                                from: innerProps.location,
                            },
                        }}
                    />
                )
            }
        />
    );
}

PrivateRoute.propTypes = {
    component: PropTypes.any.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    isAuthenticated: !!state.session.accessToken && !!state.session.me,
});

export default connect(mapStateToProps)(PrivateRoute);
