import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import Headline from "../text/Headline";
import InputGroup from "./InputGroup";
import TextInput from "../inputs/TextInput";
import TextArea from "../inputs/TextArea";
import Dropdown from "../inputs/Dropdown";
import Checkbox from "../inputs/Checkbox";
import ActionButton from "../ActionButton";
import * as color from "../../constants/color";
import * as util from "../../lib/util";
import * as dataApi from "../../lib/api/data";
import * as employerApi from "../../lib/api/employer";
import * as enrollmentApi from "../../lib/api/enrollment";
import * as enrollmentActions from "../../actions/enrollmentActions";

const styles = StyleSheet.create({
    companyInfoContainer: {
        padding: 24,
        color: color.darkestGray,
    },
    header: {
        fontSize: 20,
        fontWeight: 500,
    },
    instructions: {
        fontWeight: 300,
        fontSize: 20,
        marginTop: 12,
        marginBottom: 28,
    },
    inputInstructions: {
        fontSize: 14,
        paddingTop: 20,
        marginBottom: 6,
    },
    headline: {
        fontSize: 20,
        fontWeight: 500,
        marginBottom: 8,
    },
    checkboxContainer: {
        textAlign: "right",
    },
    checkLabel: {
        display: "inline-block",
        position: "relative",
        border: "none",
        marginTop: 8,
        marginBottom: 10,
        fontSize: 14,
        color: color.darkGray,
        "-webkit-appearance": "none",
        cursor: "pointer",
        ":first-child": {
            marginRight: 0,
        }
    },
    check: {
        position: "absolute",
        top: -2,
        left: 0,
        marginRight: 10,
    },
    labelText: {
        marginLeft: 28,
        fontSize: 14,
    },
    buttonContainer: {
        marginTop: 24,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    continueButton: {
        flexGrow: 1,
        flexBasis: 0,
    },
    sicCodeContainer: {
        zIndex: 9000,
    },
    info: {
        fontSize: 12,
        color: color.darkGray,
        fontWeight: "bold",
    },
});

const employerInputNames = {
    brokerName: "brokerName",
    brokerNPN: "brokerNPN",
    agencyTaxId: "agencyTaxId",
    isTest: "isTest",
    name: "name",
    doingBusinessAs: "doingBusinessAs",
    ein: "ein",
    sicCodeId: "sicCodeId",
    website: "website",
    entityType: "entityType",
    payrollCycleId: "payrollCycleId",
    phone: "phone",
    fax: "fax",
    enrollmentNotes: "enrollmentNotes",
};

const employerAddressInputNames = {
    street1: "street1",
    street2: "street2",
    city: "city",
    postalCode: "postalCode",
    stateId: "stateId",
    countyId: "countyId",
    hrPersonName: "hrPersonName",
    hrPersonPhone: "hrPersonPhone",
    hrPersonEmail: "hrPersonEmail",
};

const enrollmentPortalInputNames = {
    urlFirstChoice: "urlFirstChoice",
    urlSecondChoice: "urlSecondChoice",
};

const requiredFields = [
    employerInputNames.name,
    employerInputNames.ein,
];

const tooltip = {
    payrollCycle: "If you do not select a payroll cycle for the company from the payroll cycle drop-down list on the company's profile page then the system will default to a monthly payroll cycle for the whole company unless there is a payroll cycle selected on an employee's Compensation page.",
};

export class CompanyInfo extends Component {
    static propTypes = {
        onContinue: PropTypes.func.isRequired,
        enrollmentPortal: PropTypes.object.isRequired,
        employer: PropTypes.object.isRequired,
        updateEnrollmentPortal: PropTypes.func.isRequired,
        updateEmployer: PropTypes.func.isRequired,
        isEditable: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        const { employer, enrollmentPortal } = this.props;
        const { employerAddress, broker } = employer;

        this.state = {
            employerInputs: {
                [employerInputNames.brokerName]: broker.fullName ? broker.fullName : "",
                [employerInputNames.brokerNPN]: broker.nationalProducerNumber ? broker.nationalProducerNumber : "",
                [employerInputNames.agencyTaxId]: broker.federalTaxId ? broker.federalTaxId : "",
                [employerInputNames.isTest]: employer.isTest,
                [employerInputNames.name]: employer.name ? employer.name : "",
                [employerInputNames.doingBusinessAs]: employer.doingBusinessAs ? employer.doingBusinessAs : "",
                [employerInputNames.ein]: employer.ein ? employer.ein : "",
                [employerInputNames.sicCodeId]: employer.sicCodeId ? employer.sicCodeId : "",
                [employerInputNames.website]: employer.website ? employer.website : "",
                [employerInputNames.entityType]: employer.entityType ? employer.entityType.toString() : "",
                [employerInputNames.payrollCycleId]: employer.payrollCycleId ? employer.payrollCycleId.toString() : "",
                [employerInputNames.phone]: employer.phone ? employer.phone : "",
                [employerInputNames.fax]: employer.fax ? employer.fax : "",
                [employerInputNames.enrollmentNotes]: employer.enrollmentNotes ? employer.enrollmentNotes : "",
            },
            employerAddressInputs: {
                [employerAddressInputNames.street1]: employerAddress.street1 ? employerAddress.street1 : "",
                [employerAddressInputNames.street2]: employerAddress.street2 ? employerAddress.street2 : "",
                [employerAddressInputNames.city]: employerAddress.city ? employerAddress.city : "",
                [employerAddressInputNames.postalCode]: employerAddress.postalCode ? employerAddress.postalCode : "",
                [employerAddressInputNames.stateId]: employerAddress.stateId ? employerAddress.stateId.toString() : "",
                [employerAddressInputNames.countyId]: employerAddress.countyId ? employerAddress.countyId.toString() : "",
                [employerAddressInputNames.hrPersonName]: employerAddress.hrPersonName ? employerAddress.hrPersonName : "",
                [employerAddressInputNames.hrPersonPhone]: employerAddress.hrPersonPhone ? employerAddress.hrPersonPhone : "",
                [employerAddressInputNames.hrPersonEmail]: employerAddress.hrPersonEmail ? employerAddress.hrPersonEmail : "",
            },
            enrollmentPortalInputs: {
                [enrollmentPortalInputNames.urlFirstChoice]: enrollmentPortal.urlFirstChoice ? enrollmentPortal.urlFirstChoice : "",
                [enrollmentPortalInputNames.urlSecondChoice]: enrollmentPortal.urlSecondChoice ? enrollmentPortal.urlSecondChoice : "",
            },
            payrollCycles: [],
            entityTypes: [],
            states: [],
            allCounties: [],
            counties: [],
            errors: [],
            ready: false,
            isSaving: false,
        };
    }

    componentDidMount() {
        dataApi.getPayrollCycles()
            .then(response => {
                this.setState({
                    payrollCycles: response,
                });
            });

        dataApi.getEntityTypes()
            .then(response => {
                this.setState({
                    entityTypes: response,
                });
            });

        dataApi.getStates()
            .then(response => {
                this.setState({
                    states: response,
                });
            });

        dataApi.getCounties()
            .then(response => {
                let counties = [];
                const stateId = this.state.employerAddressInputs[employerAddressInputNames.stateId];

                if (stateId) {
                    counties = response.filter(c => c.stateId === parseInt(stateId));
                }

                this.setState({
                    allCounties: response,
                    counties,
                });
            });
    }

    _handleEmployerChanged = e => {
        const { checked, name, type, value } = e.target;
        let val = type === "checkbox" ? checked : value;

        if (e.target.getAttribute("data-type") === "phone") {
            val = util.maskPhoneNumber(value);
        }

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                employerInputs: {
                    ...prevState.employerInputs,
                    [name]: val,
                },
                errors,
            };
        });
    };

    _handleEmployerAddressChanged = e => {
        const { name } = e.target;
        let { value } = e.target;

        if (e.target.getAttribute("data-type") === "phone") {
            value = util.maskPhoneNumber(value);
        } else if (name === employerAddressInputNames.postalCode) {
            value = value.replace(/[^0-9]+/g, "");
            //maxlength of 5
            if (value && value.length === 6) {
                return;
            }
        }

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                employerAddressInputs: {
                    ...prevState.employerAddressInputs,
                    [name]: value,
                },
                errors,
            };
        });
    };

    _handleEnrollmentPortalChanged = e => {
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                enrollmentPortalInputs: {
                    ...prevState.enrollmentPortalInputs,
                    [name]: val,
                },
                errors,
            };
        });
    }

    _handleEmployerDropdownChanged = selectedItem => {
        const { name, value } = selectedItem;

        this.setState(prevState => {
            return {
                employerInputs: {
                    ...prevState.employerInputs,
                    [name]: value,
                },
            };
        });
    };

    _handleEmployerAddressDropdownChanged = selectedItem => {
        const { name, value } = selectedItem;
        if (name === employerAddressInputNames.stateId && value) {
            const counties = this.state.allCounties.filter(c => c.stateId === parseInt(value));
            let countyId = this.state.employerAddressInputs[employerAddressInputNames.countyId];
            if (countyId) {
                if (counties.filter(c => c.id === parseInt(countyId)).length === 0) {
                    countyId = "";
                }
            }

            this.setState({
                counties,
                countyId,
            });
        }

        this.setState(prevState => {
            return {
                employerAddressInputs: {
                    ...prevState.employerAddressInputs,
                    [name]: value,
                },
            };
        });
    };

    isValid = () => {
        const errors = {};
        const { employerInputs, employerAddressInputs, enrollmentPortalInputs } = this.state;

        requiredFields.forEach(key => {
            const isValid = employerInputs[key] || employerAddressInputs[key] || enrollmentPortalInputs[key];

            if (!isValid) {
                errors[key] = "required";
            }
        });

        //Broker NPN OR Tax ID
        if (!employerInputs["brokerNPN"] && !employerInputs["agencyTaxId"]) {
            errors[employerInputNames.brokerNPN] = "required";
            errors[employerInputNames.agencyTaxId] = "required";
        }

        //Validate Tax ID
        let taxID = '';
        if (employerInputs["agencyTaxId"]) {
            taxID = employerInputs["agencyTaxId"].replace(/[^0-9]+/g, "");
            //maxlength of 5
            if (!taxID || taxID.length !== 9) {
                errors[employerInputNames.agencyTaxId] = "Format should be 9 digits";
            }
        }
        if (employerInputs["ein"]) {
            taxID = employerInputs["ein"].replace(/[^0-9]+/g, "");
            //maxlength of 5
            if (!taxID || taxID.length !== 9) {
                errors[employerInputNames.ein] = "Format should be 9 digits";
            }
        }

        this.setState({
            errors
        });

        return !Object.keys(errors).length;
    };

    _submit = e => {
        e.preventDefault();

        if (!this.isValid()) {
            window.scrollTo(0, 0);
            return;
        }

        if (!this.props.isEditable) {
            this.props.onContinue();
            return;
        }

        let { enrollmentPortal, employer } = this.props;

        Object.keys(employerInputNames).forEach(key => {
            employer[key] = this.state.employerInputs[key];
        });

        if (!employer.employerAddress) {
            employer.employerAddress = {};
        }

        Object.keys(employerAddressInputNames).forEach(key => {
            employer.employerAddress[key] = this.state.employerAddressInputs[key];
        });

        Object.keys(enrollmentPortalInputNames).forEach(key => {
            enrollmentPortal[key] = this.state.enrollmentPortalInputs[key];
        });

        this.setState({
            isSaving: true,
        });

        Promise.all([
            employerApi.updateEmployer(employer),
            employerApi.saveEmployerAddress(employer.employerAddress),
            enrollmentApi.saveEnrollmentPortal(enrollmentPortal),
        ])
            .then(responses => {
                employer = responses[0];
                employer.employerAddress = responses[1];
                enrollmentPortal = responses[2];

                this.props.updateEmployer(employer);
                this.props.updateEnrollmentPortal(enrollmentPortal);

                this.setState({
                    isSaving: false,
                });

                this.props.onContinue();
            });
    };

    render() {
        const { isEditable } = this.props;

        return (
            <form onSubmit={this._submit}>
                <div className={css(styles.companyInfoContainer)}>
                    <InputGroup>
                        <Headline aStyles={styles.headline}>Broker Profile</Headline>
                        <TextInput
                            name={employerInputNames.brokerName}
                            placeholder="Broker Name"
                            validationMessage={this.state.errors[employerInputNames.brokerName]}
                            value={this.state.employerInputs[employerInputNames.brokerName]}
                            disabled={true}
                        />
                        <TextInput
                            name={employerInputNames.brokerNPN}
                            onChange={this._handleEmployerChanged}
                            placeholder="NPN"
                            validationMessage={this.state.errors[employerInputNames.brokerNPN] && !this.state.employerInputs[employerInputNames.agencyTaxId] ? this.state.errors[employerInputNames.brokerNPN] : this.state.employerInputs[employerInputNames.brokerNPN] || this.state.employerInputs[employerInputNames.agencyTaxId] ? "" : "*"}
                            value={this.state.employerInputs[employerInputNames.brokerNPN]}
                            disabled={!isEditable}
                        />
                        <TextInput
                            name={employerInputNames.agencyTaxId}
                            onChange={this._handleEmployerChanged}
                            placeholder="Agency Tax ID"
                            validationMessage={this.state.errors[employerInputNames.agencyTaxId] && this.state.employerInputs[employerInputNames.brokerNPN] ? this.state.errors[employerInputNames.agencyTaxId] : this.state.errors[employerInputNames.agencyTaxId] && !this.state.employerInputs[employerInputNames.brokerNPN] ? this.state.errors[employerInputNames.agencyTaxId] : this.state.employerInputs[employerInputNames.agencyTaxId] || this.state.employerInputs[employerInputNames.brokerNPN] ? "" : "*"}
                            value={this.state.employerInputs[employerInputNames.agencyTaxId]}
                            disabled={!isEditable}
                        />
                        <span className={css(styles.info)}>* NPN or Agency Tax ID required</span>
                    </InputGroup>
                    <InputGroup>
                        <Headline aStyles={styles.headline}>Employer Profile</Headline>
                        <div className={css(styles.checkboxContainer)}>
                            <label className={css(styles.checkLabel)}>
                                <Checkbox
                                    aStyles={styles.check}
                                    checked={this.state.employerInputs[employerInputNames.isTest]}
                                    name={employerInputNames.isTest}
                                    onChange={this._handleEmployerChanged}
                                    disabled={!isEditable}
                                />
                                <span className={css(styles.labelText)}>Test Group</span>
                            </label>
                        </div>
                        <TextInput
                            name={employerInputNames.name}
                            placeholder="Company Name"
                            validationMessage={this.state.errors[employerInputNames.name]}
                            value={this.state.employerInputs[employerInputNames.name]}
                            disabled={true}
                        />
                        <TextInput
                            name={employerInputNames.doingBusinessAs}
                            onChange={this._handleEmployerChanged}
                            placeholder="Doing Business As"
                            validationMessage={this.state.errors[employerInputNames.doingBusinessAs]}
                            value={this.state.employerInputs[employerInputNames.doingBusinessAs]}
                            disabled={!isEditable}
                        />
                        <TextInput
                            name={employerInputNames.ein}
                            onChange={this._handleEmployerChanged}
                            placeholder="Federal Tax ID"
                            validationMessage={this.state.errors[employerInputNames.ein] ? this.state.errors[employerInputNames.ein] : this.state.employerInputs[employerInputNames.ein] ? "" : "*"}
                            value={this.state.employerInputs[employerInputNames.ein]}
                            disabled={!isEditable}
                        />
                        <TextInput
                            name={employerInputNames.sicCodeId}
                            placeholder="SIC (Industry Code)"
                            value={this.props.employer.sicCode}
                            disabled={true}
                        />
                        <TextInput
                            name={employerInputNames.website}
                            onChange={this._handleEmployerChanged}
                            placeholder="Website"
                            validationMessage={this.state.errors[employerInputNames.website]}
                            value={this.state.employerInputs[employerInputNames.website]}
                            disabled={!isEditable}
                        />
                        <Dropdown
                            name={employerInputNames.entityType}
                            options={this.state.entityTypes.map(item => ({
                                label: item.name,
                                value: item.id.toString(),
                            }))}
                            onChange={this._handleEmployerDropdownChanged}
                            placeholder="Entity Type"
                            validationMessage={this.state.errors[employerInputNames.entityType]}
                            value={this.state.employerInputs[employerInputNames.entityType]}
                            disabled={!isEditable}
                        />
                        <Dropdown
                            name={employerInputNames.payrollCycleId}
                            options={this.state.payrollCycles.map(item => ({
                                label: item.name,
                                value: item.id.toString(),
                            }))}
                            onChange={this._handleEmployerDropdownChanged}
                            placeholder="Payroll Cycle"
                            validationMessage={this.state.errors[employerInputNames.payrollCycleId]}
                            value={this.state.employerInputs[employerInputNames.payrollCycleId]}
                            disabled={!isEditable}
                            tooltip={tooltip.payrollCycle}
                        />
                        {/*<div className={css(styles.inputInstructions)}>
                            The following URLs are your first and second preference for the name of the website where the HR administrator and employees will 
                            review/enroll in their benefits, etc. Please enter a second choice in case your first choice is already taken.
                        </div>
                        <TextInput
                            name={enrollmentPortalInputNames.urlFirstChoice}
                            onChange={this._handleEnrollmentPortalChanged}
                            placeholder="URL 1"
                            validationMessage={this.state.errors[enrollmentPortalInputNames.urlFirstChoice]}
                            value={this.state.enrollmentPortalInputs[enrollmentPortalInputNames.urlFirstChoice]}
                            prefixLabel="https://"
                            postfixLabel=".ubenefit.com"
                            disabled={!isEditable}
                        />
                        <TextInput
                            name={enrollmentPortalInputNames.urlSecondChoice}
                            onChange={this._handleEnrollmentPortalChanged}
                            placeholder="URL 2"
                            validationMessage={this.state.errors[enrollmentPortalInputNames.urlSecondChoice]}
                            value={this.state.enrollmentPortalInputs[enrollmentPortalInputNames.urlSecondChoice]}
                            prefixLabel="https://"
                            postfixLabel=".ubenefit.com"
                            disabled={!isEditable}
                        />*/}
                    </InputGroup>
                    <InputGroup>
                        <Headline aStyles={styles.headline}>Employer Location</Headline>
                        <TextInput
                            name={employerAddressInputNames.street1}
                            onChange={this._handleEmployerAddressChanged}
                            placeholder="Street Address"
                            validationMessage={this.state.errors[employerAddressInputNames.street1]}
                            value={this.state.employerAddressInputs[employerAddressInputNames.street1]}
                            disabled={!isEditable}
                        />
                        <TextInput
                            name={employerAddressInputNames.street2}
                            onChange={this._handleEmployerAddressChanged}
                            placeholder="Suite, Apt., etc (optional)"
                            validationMessage={this.state.errors[employerAddressInputNames.street2]}
                            value={this.state.employerAddressInputs[employerAddressInputNames.street2]}
                            disabled={!isEditable}
                        />
                        <TextInput
                            name={employerAddressInputNames.city}
                            onChange={this._handleEmployerAddressChanged}
                            placeholder="City"
                            validationMessage={this.state.errors[employerAddressInputNames.city]}
                            value={this.state.employerAddressInputs[employerAddressInputNames.city]}
                            disabled={!isEditable}
                        />
                        <Dropdown
                            name={employerAddressInputNames.stateId}
                            options={this.state.states.map(item => ({
                                label: item.longName,
                                value: item.id.toString(),
                            }))}
                            onChange={this._handleEmployerAddressDropdownChanged}
                            placeholder="State"
                            validationMessage={this.state.errors[employerAddressInputNames.stateId]}
                            value={this.state.employerAddressInputs[employerAddressInputNames.stateId]}
                            disabled={!isEditable}
                        />
                        <TextInput
                            name={employerAddressInputNames.postalCode}
                            onChange={this._handleEmployerAddressChanged}
                            placeholder="Zip Code"
                            validationMessage={this.state.errors[employerAddressInputNames.postalCode]}
                            value={this.state.employerAddressInputs[employerAddressInputNames.postalCode]}
                            disabled={!isEditable}
                        />
                        <Dropdown
                            name={employerAddressInputNames.countyId}
                            options={this.state.counties.map(item => ({
                                label: item.code + " - " + item.name,
                                value: item.id.toString(),
                            }))}
                            onChange={this._handleEmployerAddressDropdownChanged}
                            placeholder="County"
                            validationMessage={this.state.errors[employerAddressInputNames.countyId]}
                            value={this.state.employerAddressInputs[employerAddressInputNames.countyId]}
                            disabled={!isEditable}
                        />
                        <TextInput
                            name={employerAddressInputNames.hrPersonName}
                            onChange={this._handleEmployerAddressChanged}
                            placeholder="HR Person Name"
                            validationMessage={this.state.errors[employerAddressInputNames.hrPersonName]}
                            value={this.state.employerAddressInputs[employerAddressInputNames.hrPersonName]}
                            disabled={!isEditable}
                        />
                        <TextInput
                            name={employerAddressInputNames.hrPersonEmail}
                            onChange={this._handleEmployerAddressChanged}
                            placeholder="HR Person Email"
                            validationMessage={this.state.errors[employerAddressInputNames.hrPersonEmail]}
                            value={this.state.employerAddressInputs[employerAddressInputNames.hrPersonEmail]}
                            disabled={!isEditable}
                        />
                        <TextInput
                            name={employerAddressInputNames.hrPersonPhone}
                            onChange={this._handleEmployerAddressChanged}
                            placeholder="HR Person Phone Number"
                            validationMessage={this.state.errors[employerAddressInputNames.hrPersonPhone]}
                            data-type="phone"
                            value={this.state.employerAddressInputs[employerAddressInputNames.hrPersonPhone]}
                            disabled={!isEditable}
                        />
                    </InputGroup>
                    <InputGroup>
                        <Headline aStyles={styles.headline}>Employer Phone Number & Notes</Headline>
                        <TextInput
                            name={employerInputNames.phone}
                            onChange={this._handleEmployerChanged}
                            placeholder="Main Phone Number"
                            validationMessage={this.state.errors[employerInputNames.phone]}
                            data-type="phone"
                            value={this.state.employerInputs[employerInputNames.phone]}
                            disabled={!isEditable}
                        />
                        <TextInput
                            name={employerInputNames.fax}
                            onChange={this._handleEmployerChanged}
                            placeholder="Fax Number"
                            validationMessage={this.state.errors[employerInputNames.fax]}
                            data-type="phone"
                            value={this.state.employerInputs[employerInputNames.fax]}
                            disabled={!isEditable}
                        />
                        <TextArea
                            name={employerInputNames.enrollmentNotes}
                            onChange={this._handleEmployerChanged}
                            placeholder="Notes"
                            validationMessage={this.state.errors[employerInputNames.enrollmentNotes]}
                            value={this.state.employerInputs[employerInputNames.enrollmentNotes]}
                            disabled={!isEditable}
                        />
                    </InputGroup>
                    <InputGroup>
                        <div className={css(styles.buttonContainer)}>
                            <ActionButton
                                aStyles={styles.continueButton}
                                label="Continue"
                                type="submit"
                                isLoading={this.state.isSaving}
                                onClick={this._continueClicked}
                            />
                        </div>
                    </InputGroup>
                </div>
            </form>
        );
    }
}

const mapStateToProps = state => ({
    enrollmentPortal: state.enrollment.enrollmentPortal,
    employer: state.enrollment.employer,
    isEditable: state.enrollment.isEditable,
});

export default connect(
    mapStateToProps, {
    setEnrollment: enrollmentActions.setEnrollment,
    updateEmployer: enrollmentActions.updateEmployer,
    updateEnrollmentPortal: enrollmentActions.updateEnrollmentPortal,
}
)(CompanyInfo);