import * as color from "../../constants/color";
import * as texchangePropTypes from "../../texchangePropTypes";
import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    text: {
        fontSize: 13,
        letterSpacing: 0.18,
        color: color.darkRed,
        fontWeight: 500,
    },
});

export default function ErrorMessage(props) {
    const { aStyles, children, ...rest } = props;
    return (
        <span {...rest} className={css(styles.text, aStyles)}>
            {children}
        </span>
    );
}

ErrorMessage.propTypes = {
    aStyles: texchangePropTypes.aphroditeStyles,
    children: PropTypes.node,
};
