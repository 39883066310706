import * as actionTypes from "../constants/actionTypes";
import createReducer from "../lib/createReducer";

const defaultState = {
    byId: {},
};

function APP_SET_NEWS(state, action) {
    const { news } = action;
    const nextState = {
        ...state,
        byId: { ...state.byId },
    };

    news.forEach(x => {
        nextState.byId[x.id] = x;
    });

    return nextState;
}

export default createReducer(defaultState, {
    [actionTypes.APP_SET_NEWS]: APP_SET_NEWS,
});
