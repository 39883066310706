/* eslint-disable react/prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import * as color from "../../constants/color";
import "../../styles/slider.css";
import Slider from "rc-slider";

const styles = StyleSheet.create({
    label: {
        position: "absolute",
        fontSize: 14,
        marginLeft: -8,
        marginTop: 16,
        color: color.darkGray,
        backgroundColor: color.white
    }
});

const RangeControl = Slider.Range;
const Handle = Slider.Handle;

const handle = props => {
    const { value, dragging, index, ...restProps } = props;
    const formatted = parseInt(value).toLocaleString();
    return (
        <React.Fragment key={index}>
            <Handle value={value} {...restProps} />
            <div
                className={css(styles.label)}
                style={{
                    left: props.offset + "%",
                }}
            >
                ${formatted}
            </div>
        </React.Fragment>
    );
};

const handlePct = props => {
    const { value, dragging, index, ...restProps } = props;
    return (
        <React.Fragment key={index}>
            <Handle value={value} {...restProps} />
            <div
                className={css(styles.label)}
                style={{
                    left: props.offset + "%",
                }}
            >
                {value}%
            </div>
        </React.Fragment>
    );
};

export default class Range extends Component {
    static propTypes = {
        defaultValue: PropTypes.array,
        value: PropTypes.array,
        step: PropTypes.number,
        onChange: PropTypes.func,
        min: PropTypes.number,
        max: PropTypes.number,
        showAsPercent: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this._inputRef = React.createRef();
        this._popupRef = React.createRef();
        this.state = {
            value: [],
        };
    }

    componentDidMount() {
        this.setState({ value: this.props.value });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    _handleChange = value => {
        this.setState({ value });
    };

    _handleAfterChange = value => {
        this.props.onChange(value);
    };

    render() {
        return (
            <RangeControl
                defaultValue={this.props.defaultValue}
                value={this.state.value}
                step={this.props.step}
                onBeforeChange={this._handleBeforeChange}
                onChange={this._handleChange}
                onAfterChange={this._handleAfterChange}
                min={this.props.min}
                max={this.props.max}
                handle={this.props.showAsPercent ? handlePct : handle}
                allowCross={false}
            />
        );
    }
}