import * as actionTypes from "../constants/actionTypes";
import createReducer from "../lib/createReducer";

const defaultState = {
    byId: {},
};

function APP_SET_OFFICES(state, action) {
    const { offices } = action;
    const nextState = {
        ...state,
        byId: { ...state.byId },
    };

    offices.forEach(x => {
        nextState.byId[x.id] = x;
    });

    return nextState;
}

export default createReducer(defaultState, {
    [actionTypes.APP_SET_OFFICES]: APP_SET_OFFICES,
});
