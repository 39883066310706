import * as color from "../../constants/color";
import * as routeHelpers from "../../lib/routeHelpers";
import * as texchangePropTypes from "../../texchangePropTypes";
import PageTitle from "../text/PageTitle";
import TabTitle from "../text/TabTitle";
import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import AddButton from "../AddButton";
import ReQuoteButton from "../ReQuoteButton";
import SelectedPlans from "../proposal-builder/SelectedPlans";
import Tabs from "../Tabs";
import Toggle from "../inputs/Toggle";
import TextInput from "../inputs/TextInput";
import SmallDropdown from "../inputs/SmallDropdown";
import TextArea from "../inputs/TextArea";
import { connect } from "react-redux";
import * as censusApi from "../../lib/api/census";
import * as quotesApi from "../../lib/api/quotes";
import * as quoteProposalApi from "../../lib/api/quoteProposal";
import * as enrollmentApi from "../../lib/api/enrollment";
import * as inputSizes from "../../constants/inputSizes";
import BodyCopyHighlight from "../text/BodyCopyHighlight";
import Checkbox from "../inputs/Checkbox";
import ActionButton from "../ActionButton";
import Modal from "../Modal";
import alertImage from "../../images/alert.png";
import logger from "../../lib/logger";
import describeNetworkError from "../../lib/describeNetworkError";
import Enrollment from "../enrollment";
import Moment from 'react-moment';
import { ReactComponent as DownloadIcon } from "../../images/SVGs/Download.svg";
import { ReactComponent as ChevronDown } from "../../images/SVGs/chevron-down.svg";
import { ReactComponent as GarbageIcon } from "../../images/SVGs/garbage.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "../Spinner";
import downloadIcon from "../../images/SVGs/Download.svg";

const styles = StyleSheet.create({
    content: {
        paddingRight: 10,
        paddingBottom: 80,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
    },
    contentSection: {
        width: "100%",
        padding: 0,
        backgroundColor: color.white,
        borderTop: `8px solid ${color.mediumRed}`,
        marginBottom: 20,
    },
    header: {
        backgroundColor: color.white,
    },
    titleSection: {
        padding: "20px 12px 6px 0px",
        backgroundColor: color.white,
    },
    topRightHolder: {
        float: "right",
        cursor: "pointer",
        marginTop: 18,
        marginRight: 24,
    },
    tabContent: {
        backgroundColor: color.white,
    },
    contentPadding: {
        paddingLeft: 24,
        paddingRight: 24,
    },
    tabActivity: {
        fontSize: 12,
    },
    row: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        minWidth: "280px",
        marginBottom: 15,
    },
    column: {
        display: "flex",
        flexDirection: "column",
        flexBasis: "100%",
        flex: 1,
    },
    leftColSummary: {
        minWidth: "200px",
    },
    link: {
        cursor: "pointer",
        color: color.mediumYellow1,
    },
    grayBox: {
        width: 130,
        height: 82,
        padding: 10,
        textAlign: "center",
        backgroundColor: color.lightGray,
        borderRadius: 10,
        marginRight: 20,
    },
    grayBoxTitle: {
        fontSize: 16,
        whiteSpace: "nowrap",
    },
    grayBoxText: {
        fontSize: 32,
        fontWeight: "bold",
        marginTop: 8,
        color: color.mediumYellow1,
    },
    bolder: {
        fontWeight: "bolder",
    },
    notesBox: {
        width: "100%",
        backgroundColor: color.lightGray,
        borderRadius: 10,
        paddingBottom: 10,
    },
    notesTitleContainer: {
        width: "100%",
        padding: "16px 16px 0px 16px",
        fontSize: 14,
        fontWeight: 500,
        borderBottom: `2px solid ${color.mediumRed}`,
        display: "flex",
    },
    notesTitle: {
        padding: "0px 2px 8px 2px",
        marginRight: 15,
        borderBottom: `3px solid ${color.darkGray2}`,
        cursor: "pointer",
    },
    notesTitleActive: {
        padding: "0px 2px 8px 2px",
        marginRight: 15,
        borderBottom: `3px solid ${color.darkRed}`,
    },
    notesContainer: {
        clear: "both",
        borderTop: `1px solid ${color.darkGray2}`,
    },
    noteItem: {
        padding: "10px 18px",
        fontSize: 14,
        borderBottom: `1px solid ${color.darkGray2}`,
        clear: "both",
    },
    noteDate: {
        fontSize: 12,
        color: color.darkRed,
    },
    deleteButtonContainer: {
        width: "100%",
        marginTop: 150,
        textAlign: "right",
    },
    deleteButton: {
        padding: "4px 8px",
        color: color.white,
        backgroundColor: color.red,
        border: `1px solid ${color.darkGray2}`,
        borderRadius: 4,
        cursor: "pointer",
    },
    spacer: {
        display: "inline-block",
        width: "20px",
        color: color.lightOrange,
        textAlign: "center",
    },
    quoteChevron: {
        float: "right",
    },
    chevron: {
        pointerEvents: "none",
        transform: "rotate(-90deg)",
        display: "block",
        width: 12,
        height: 12,
    },
    table: {
        width: "100%",
        marginTop: 0,
        marginBottom: 10,
        borderCollapse: "collapse",
        fontSize: 14,
    },
    confirmRatestable: {
        width: "100%",
        marginTop: 12,
        marginBottom: 10,
        borderCollapse: "collapse",
        fontSize: 14,
    },
    tableFirstColumn: {
        paddingLeft: 26,
    },
    tableHeader: {
        color: color.mediumYellow1,
        fontWeight: 500,
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: 12,
        paddingBottom: 12,
        whiteSpace: "nowrap",
        borderBottom: `1px solid ${color.mediumGray}`,
        borderTop: `1px solid ${color.darkGray2}`,
    },
    tableRow: {
        color: color.tableText,
        padding: "12px 6px",
        borderBottom: `1px solid ${color.tableBorder}`,
    },
    greenBar: {
        borderLeft: `4px solid ${color.mediumRed}`,
        fontWeight: 500,
    },
    blueBar: {
        borderLeft: `4px solid ${color.borderBackgroundGray}`,
    },
    greenHeader: {
        color: color.mediumRed,
        fontSize: 18,
        fontWeight: 500,
        paddingLeft: 24,
        paddingBottom: 16,
        display: "block",
    },
    downloadDocument: {
        cursor: "pointer",
    },
    downloadCensus: {
        cursor: "pointer",
        marginLeft: 12,
        height: "21px",
    },
    opaque: {
        opacity: 0.3,
    },
    currentMedicalPlansContainer: {
        marginTop: 12,
    },
    summaryContainer: {
        marginTop: 12,
    },
    summaryColumn: {
        flex: 2,
    },
    proposalsContainer: {
        marginTop: 16,
    },
    quoteStatusBox: {
        fontSize: 16,
        width: "100%",
        backgroundColor: color.lightGray,
        borderRadius: 10,
        padding: 16,
        marginBottom: 16,
    },
    statusLabel: {
        color: color.darkestGray,
    },
    statusIncomplete: {
        color: color.darkRed,
        marginLeft: 6,
    },
    statusButtonContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: 16,
    },
    switchButton: {
        display: "flex",
        position: "relative",
        border: `1px solid ${color.mediumGray}`,
        width: 160,
        height: 40,
        textAlign: "center",
        lineHeight: "40px",
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "center",
    },
    statusCheckContainer: {
        border: `2px solid ${color.white}`,
        height: 24,
        width: 24,
        textAlign: "center",
        borderRadius: "50%",
        lineHeight: "19px",
        color: color.white,
        marginRight: 8,
    },
    whiteCheck: {
        display: "inline-block",
        transform: "rotate(45deg)",
        width: 7,
        height: 13,
        borderBottom: `2px solid ${color.white}`,
        borderRight: `2px solid ${color.white}`,

    },
    statusButtonActive: {
        backgroundColor: color.darkRed,
        color: color.white,
    },
    soldStatusButton: {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        borderRight: "none",
    },
    lostStatusButton: {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
    },
    planTable: {
        fontSize: 12,
    },
    instructions: {
        color: color.darkestGray,
        fontWeight: 300,
        fontSize: 20,
        marginLeft: 24,
    },
    confirmSoldButtonContainer: {
        marginTop: 12,
        paddingBottom: 24,
        marginLeft: "auto",
        marginRight: "auto",
        width: 180,
    },
    confirmSoldButton: {
        width: "100%",
    },
    newMedicalPlansContainer: {
        marginTop: 32,
        overflowX: "scroll",
    },
    metalCircle: {
        height: "20px",
        width: "20px",
        borderRadius: "50%",
        display: "inline-block",
    },
    max100: {
        maxWidth: 120,
        maxHeight: 38,
        overflow: "scroll",
    },
    modalContent: {
        width: 386,
    },
    modalInstructions: {
        color: color.darkestGray,
        fontSize: 16,
        marginBottom: 8,
        textAlign: "center",
        lineHeight: "24px",
    },
    modalButtonContainer: {
        marginTop: 24,
        display: "flex",
        justifyContent: "space-around",
    },
    modalButton: {
        width: 150,
    },
    alertIcon: {
        display: "block",
        marginBottom: 16,
        marginLeft: "auto",
        marginRight: "auto",
    },
    hover: {
        position: "relative",
        color: color.mediumYellow1,
    },
    __hover: {
        position: "absolute",
        top: -10,
        right: -5,
        padding: 8,
        width: "100%",
        height: 30,
        color: color.tableText,
        backgroundColor: color.white,
        border: `1px solid ${color.tableBorder}`,
        overflow: "hidden",
        opacity: 0,
        ":hover": {
            opacity: 1,
            top: -20,
            minWidth: 340,
            minHeight: 63,
            overflow: "auto",
        },
    },
    __nohover: {
        opacity: 1,
        ":hover": {
            opacity: 0,
        },
    },
    noResults: {
        backgroundColor: color.white,
        color: color.darkGray,
        padding: 20,
        marginBottom: 40,
    },
    modalToggle: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 5,
    },
    modalLabel: {
        fontSize: 13,
        // paddingTop: 30,
    },
    modalConfirmPlansContent: {
        width: 760,
        height: "55vh",
        overflow: "auto",
        // minWidth: "840px",
        // fontSize: 13,
        // padding: "0px !important",
    },
    tableCell: {
        padding: "4px 8px",
        ":nth-child(1)": {
            minWidth: 260,
        },
        ":nth-child(2)": {
            minWidth: 120,
        },
        ":nth-child(3)": {
            minWidth: 120,
        },
        ":nth-child(4)": {
            minWidth: 120,
        },
        ":nth-child(5)": {
            minWidth: 120,
        },
    },
    modalConfirmRatesButtonContainer: {
        marginTop: 24,
        width: "50%",
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    planDropdown: {
        marginTop: 12,
        marginBottom: 12,
    },
    ageRatesContainer: {
        display: "flex",
        flexFlow: "column wrap",
        fontSize: 14,
        height: 325,
    },
    ageRateItem: {
        display: "flex",
        padding: "2px 4px",
        borderBottom: `solid 1px ${color.lightGray2}`,
    },
    ageLabel: {
        padding: 2,
        backgroundColor: color.lightGray4,
    },
    rateLabel: {
        padding: "2px 4px",
    },
    ageRatesInstruction: {
        color: color.darkRed,
        fontSize: 19,
        marginTop: 12,
        marginBottom: 12,
    },
    ageRatesNotAvailable: {
        fontWeight: "bold",
        textAlign: "center",
        color: color.tableText,
        marginTop: 24,
    },
    addNote: {
        float: "right",
        cursor: "pointer",
        marginTop: 8,
        marginRight: 12,
        marginBottom: 8,
        backgroundColor: color.lightGray,
    },
    noteText: {
        paddingTop: 4,
        fontSize: 16,
    }
});

const strings = {
    title: "Find a Quote",
    searchPlaceholder: "Search by Group or Broker",
    addProposal: "New Proposal",
    reQuote: "Re Quote",
    planConfirmationInstructions: "Please select the plans you will be moving forward with then confirm.",
    confirmSoldButtonText: "Confirm Sold",
    enrollmentModalHeader: "Sold Quote Confirmation",
};

const inputNames = {
    search: "search",
    quoteStatus: "quoteStatus",
    note: "note",
};

export class QuoteDetailsScreen extends Component {
    static propTypes = {
        history: texchangePropTypes.history.isRequired,
        match: texchangePropTypes.match.isRequired,
        me: texchangePropTypes.me.isRequired,
    };

    state = {
        quoteDetails: {},
        inputs: {
            [inputNames.search]: "",
            [inputNames.quoteStatusId]: "",
            [inputNames.note]: "",
        },
        loading: true,
        errors: {},
        soldPlans: [],
        soldPlanContributions: [],
        soldPlansSelectAllChecked: false,
        soldDentalPlans: [],
        soldDentalPlanContributions: [],
        soldDentalPlansSelectAllChecked: false,
        soldVisionPlans: [],
        soldVisionPlanContributions: [],
        soldVisionPlansSelectAllChecked: false,
        showEnrollmentModal: false,
        enrollmentPortal: {},
        isSaving: false,
        currentTab: null,
        generatingOutput: false,
        notesActive: true,
        showConfirmRatesModal: false,
        planRates: [],
        planDentalRates: [],
        planVisionRates: [],
        isAgeRated: false,
        ageRates: [],
        selectedPlanId: null,
        selectedPlanAgeRates: [],
        notes: [],
        showNewNoteModal: false,
        allowNewProposals: false,
        generatingCensus: false,
    };

    componentDidMount() {
        this._getDetails();

        quotesApi.getNotes(this.props.match.params.id).then(result => {
            this.setState({
                notes: result,
            })
        });
    }

    _getDetails = () => {
        quotesApi.getQuoteDetails(this.props.match.params.id)
            .then(result => {
                if (result && result.quote) {
                    const { selectedMedicalPlans, selectedDentalPlans, selectedVisionPlans } = result;
                    const effective = new Date(result.quote.effectiveDate);
                    const oneMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 1));
                    //update state
                    this.setState(prevState => {
                        return {
                            inputs: {
                                ...prevState.inputs,
                                [inputNames.quoteStatusId]: result.quote.quoteStatusId,
                            },
                            quoteDetails: result,
                            loading: false,
                            soldPlans: selectedMedicalPlans ? selectedMedicalPlans.map(p => p.medicalPlanId) : [],
                            soldPlanContributions: selectedMedicalPlans,
                            soldDentalPlans: selectedDentalPlans ? selectedDentalPlans.map(p => p.dentalPlanId) : [],
                            soldDentalPlanContributions: selectedDentalPlans,
                            soldVisionPlans: selectedVisionPlans ? selectedVisionPlans.map(p => p.visionPlanId) : [],
                            soldVisionPlanContributions: selectedVisionPlans,
                            allowNewProposals: effective > oneMonthAgo,
                        };
                    });
                } else {
                    //redirect back to quote list
                    setTimeout(() => {
                        this.props.history.push(routeHelpers.quotes());
                    }, 0);
                }
            })
            .catch(err => {
                logger.warn(err);
                if (err && err.response && err.response.data && err.response.data.error) {
                    toast.error(err.response.data.error, { toastId: 1 });
                    //redirect back to quote list
                    setTimeout(() => {
                        this.props.history.push(routeHelpers.quotes());
                    }, 2500);
                }
            });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this._getDetails();
        }
    }

    _goToAddProposal = () => {
        //date check...  if effective more than 1 month ago then don't allow
        if (!this.props.me.isSysAdmin && !this.state.allowNewProposals) {
            toast.error("You cannot create new proposals for past effective dates.", { toastId: 1 });
            return;
        }
        //continue
        this.props.history.push(routeHelpers.editQuote(this.props.match.params.id));
    };

    _goToQuote = id => {
        this.props.history.push(routeHelpers.quoteDetail(id));
    };

    _goToReQuote = () => {
        this.props.history.push(routeHelpers.reQuote(this.props.match.params.id));
    };

    _goToGroup = id => {
        if (id > 0) {
            window.location.href = "/groups/detail/" + id;
        }
    };

    _goToBroker = id => {
        if (id > 0) {
            window.location.href = "/brokers/detail/" + id;
        }
    };

    _setQuoteStatus = quoteStatusId => {
        const { me } = this.props;
        if (!me.enrollmentPortalPermission) {
            toast.error("Your account does not have enrollment portal permission.", { toastId: 1 });
            return;
        }

        let newQuoteStatusId;
        if (this.state.inputs[inputNames.quoteStatusId] === quoteStatusId) {
            newQuoteStatusId = 1;
        } else {
            newQuoteStatusId = quoteStatusId;
        }

        quotesApi.updateQuoteStatus(this.state.quoteDetails.quote.id, newQuoteStatusId)
            .then(() => {
                this.setState(prevState => {
                    return {
                        inputs: {
                            ...prevState.inputs,
                            [inputNames.quoteStatusId]: newQuoteStatusId,
                        },
                    };
                });
            });
    };

    _renderStatus = () => {
        const quoteStatusId = this.state.inputs[inputNames.quoteStatusId];
        const isSold = quoteStatusId === 10;
        const isLost = quoteStatusId === 11;

        const statusCheck = (
            <div className={css(styles.statusCheckContainer)}>
                <div className={css(styles.whiteCheck)} />
            </div>
        );

        return (
            <div className={css(styles.quoteStatusBox)}>
                <span className={css(styles.statusLabel)}>Status:</span>
                {(!isSold && !isLost) &&
                    <span className={css(styles.statusIncomplete)}>Incomplete</span>
                }
                <div className={css(styles.statusButtonContainer)}>
                    <div
                        className={css(
                            styles.switchButton,
                            styles.soldStatusButton,
                            isSold ? styles.statusButtonActive : null
                        )}
                        onClick={() => this._setQuoteStatus(10)}
                    >
                        {isSold &&
                            <div>
                                {statusCheck}
                            </div>
                        }
                        <div>Sold</div>
                    </div>
                    <div
                        className={css(
                            styles.switchButton,
                            styles.lostStatusButton,
                            isLost ? styles.statusButtonActive : null
                        )}
                        onClick={() => this._setQuoteStatus(11)}
                    >
                        {isLost &&
                            <div>
                                {statusCheck}
                            </div>
                        }
                        <div>Lost</div>
                    </div>
                </div>
            </div>
        );
    };

    _deleteQuote = () => {
        if (!window.confirm("Are you sure you want to delete the quote?  This action cannot be undone.")) {
            return;
        }

        quotesApi.deleteQuote(this.state.quoteDetails.quote.id)
            .then(result => {
                if (result) {
                    toast.success("The quote has been deleted.  Redirecting...", { toastId: 1 });
                    //redirect back to quote list
                    setTimeout(() => {
                        this.props.history.push(routeHelpers.quotes());
                    }, 2000);
                } else {
                    toast.error("There was a problem trying to delete the quote.", { toastId: 1 });
                }
            });
    };

    _addNote = () => {
        this.setState({ showNewNoteModal: true });
    };

    _saveNote = () => {
        quotesApi.saveNote({
            note: this.state.inputs[inputNames.note],
            quoteId: this.props.match.params.id,
        })
            .then(response => {
                this.setState(prevState => {
                    return {
                        inputs: {
                            ...prevState.inputs,
                            [inputNames.note]: "",
                        },
                        showNewNoteModal: false,
                        notes: response,
                    };
                });
            });
    };

    _handleNoteTextChange = e => {
        const { name } = e.target;
        let { value } = e.target;

        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
            };
        });
    };

    _renderNotesAndQuotes = () => {
        const { quote } = this.state.quoteDetails;
        const { relatedQuotes } = this.state.quoteDetails.quote;
        const { notes } = this.state;
        return (
            <React.Fragment>
                <div className={css(styles.notesBox)}>
                    <div className={css(styles.notesTitleContainer)}>
                        <div className={css(this.state.notesActive ? styles.notesTitleActive : styles.notesTitle)} onClick={() => this.setState({ notesActive: true })}>Notes ({notes ? notes.length : 0})</div>
                        <div className={css(this.state.notesActive ? styles.notesTitle : styles.notesTitleActive)} onClick={() => this.setState({ notesActive: false })}>Related Quotes ({relatedQuotes ? relatedQuotes.length : 0})</div>
                    </div>
                    {this.state.notesActive &&
                        <React.Fragment>
                            <AddButton
                                aStyles={styles.addNote}
                                onClick={this._addNote}
                                label="New Note"
                            />
                            <div className={css(styles.notesContainer)}>
                                {notes.map(n => {
                                    return (
                                        <div key={n.id} className={css(styles.noteItem)}>
                                            <span className={css(styles.noteDate)}>
                                                <Moment format="MM/DD/YYYY">
                                                    {n.createDate}
                                                </Moment>
                                                &nbsp;-&nbsp;{n.writtenBy}
                                            </span>
                                            <div className={css(styles.noteText)}>{n.note}</div>
                                        </div>

                                    )
                                })}
                            </div>
                        </React.Fragment>
                    }
                    {!this.state.notesActive && relatedQuotes && relatedQuotes.map(n => {
                        return (
                            <div key={n.quoteId} className={css(styles.noteItem)} onClick={() => this._goToQuote(n.quoteId)}>
                                <span className={css(styles.spacer)}>{quote.id === n.quoteId ? "*" : ""}</span>
                                <span>{"Quote ID: "}{n.title}{" - "}{"Date Created: "}{n.createDateString}</span>
                                {quote.id !== n.quoteId &&
                                    <div className={css(styles.quoteChevron)}><ChevronDown className={css(styles.chevron)} /></div>
                                }
                            </div>
                        )
                    })}
                </div>
                <div className={css(styles.deleteButtonContainer)}>
                    <span className={css(styles.deleteButton)} onClick={this._deleteQuote}>Delete Quote</span>
                </div>
                <Modal
                    show={this.state.showNewNoteModal}
                    header="Add Note"
                >
                    <div className={css(styles.modalContent)}>
                        <div className={css(styles.modalInstructions)}>
                            Type your note here:
                        </div>
                        <TextArea
                            name={inputNames.note}
                            onChange={this._handleNoteTextChange}
                            placeholder="Enter text..."
                            required={true}
                            validationMessage={this.state.errors[inputNames.note]}
                            value={this.state.inputs[inputNames.note]}
                        />
                    </div>
                    <div className={css(styles.modalButtonContainer)}>
                        <ActionButton
                            aStyles={styles.modalButton}
                            label="Cancel"
                            invertColor={true}
                            type="button"
                            onClick={() => this.setState({ showNewNoteModal: false })}
                        />
                        <ActionButton
                            aStyles={styles.modalButton}
                            label="Save"
                            onClick={this._saveNote}
                            type="button"
                            isLoading={this.state.isSaving}
                        />
                    </div>
                </Modal>
            </React.Fragment>
        );
    };

    _renderSummary = () => {
        const { me } = this.props;
        const { quote } = this.state.quoteDetails;

        return (
            <div className={css(styles.tabContent, styles.contentPadding)}>
                <div className={css(styles.titleSection)}>
                    <div className={css(styles.topRightHolder)}>
                        <span className={css(styles.tabActivity)}>Latest Activity: {quote.createDateString}</span>
                    </div>
                    <TabTitle>Summary</TabTitle>
                </div>
                <div className={css(styles.row, styles.summaryContainer)}>
                    <div className={css(styles.column, styles.summaryColumn)}>
                        <div className={css(styles.row)}>
                            <div className={css(styles.grayBox)}>
                                <div className={css(styles.grayBoxTitle)}>EEs</div>
                                <div className={css(styles.grayBoxText)}>{quote.censusSize}</div>
                            </div>
                            <div className={css(styles.grayBox)}>
                                <div className={css(styles.grayBoxTitle)}>Selected Plans</div>
                                <div className={css(styles.grayBoxText)}>
                                    {quote.newMedicalPlans.length + quote.newDentalPlans.length + quote.newVisionPlans.length + quote.newLifePlans.length}
                                </div>
                            </div>
                            <div className={css(styles.grayBox)}>
                                <div className={css(styles.grayBoxTitle)}>Proposals</div>
                                <div className={css(styles.grayBoxText)}>{this.state.quoteDetails.quoteProposals.length}</div>
                            </div>
                        </div>
                        {/*<div className={css(styles.row)}>
                            <span className={css(styles.bolder)}>Description:</span><br />
                            <span>{quote.description}</span>
                        </div>*/}
                        <div className={css(styles.row)}>
                            <div className={css(styles.column)}>
                                <div className={css(styles.row)}>
                                    <div className={css(styles.column, styles.leftColSummary)}>
                                        <span className={css(styles.bolder)}>Quote ID:</span>
                                    </div>
                                    <div className={css(styles.column)}>
                                        <span>{quote.id}</span>
                                    </div>
                                </div>
                                <div className={css(styles.row)}>
                                    <div className={css(styles.column, styles.leftColSummary)}>
                                        <span className={css(styles.bolder)}>Quoted:</span>
                                    </div>
                                    <div className={css(styles.column)}>
                                        <span>{quote.createDateString} by {quote.createdBy}</span>
                                    </div>
                                </div>
                                <div className={css(styles.row)}>
                                    <div className={css(styles.column, styles.leftColSummary)}>
                                        <span className={css(styles.bolder)}>Group:</span>
                                    </div>
                                    <div className={css(styles.column, styles.link)} onClick={() => this._goToGroup(quote.employerId)}>
                                        <span>{quote.groupName}</span>
                                    </div>
                                </div>
                                <div className={css(styles.row)}>
                                    <div className={css(styles.column, styles.leftColSummary)}>
                                        <span className={css(styles.bolder)}>Zip:</span>
                                    </div>
                                    <div className={css(styles.column)}>
                                        <span>{quote.zip}</span>
                                    </div>
                                </div>
                                {quote.city &&
                                    <div className={css(styles.row)}>
                                        <div className={css(styles.column, styles.leftColSummary)}>
                                            <span className={css(styles.bolder)}>City:</span>
                                        </div>
                                        <div className={css(styles.column)}>
                                            <span>{quote.city}</span>
                                        </div>
                                    </div>
                                }
                                {quote.county &&
                                    <div className={css(styles.row)}>
                                        <div className={css(styles.column, styles.leftColSummary)}>
                                            <span className={css(styles.bolder)}>County:</span>
                                        </div>
                                        <div className={css(styles.column)}>
                                            <span>{quote.county}</span>
                                        </div>
                                    </div>
                                }
                                <div className={css(styles.row)}>
                                    <div className={css(styles.column, styles.leftColSummary)}>
                                        <span className={css(styles.bolder)}>State:</span>
                                    </div>
                                    <div className={css(styles.column)}>
                                        <span>{quote.state}</span>
                                    </div>
                                </div>
                                <div className={css(styles.row)}>
                                    <div className={css(styles.column, styles.leftColSummary)}>
                                        <span className={css(styles.bolder)}>Broker:</span>
                                    </div>
                                    <div className={css(styles.column, styles.link)} onClick={() => this._goToBroker(quote.brokerId)}>
                                        <span>{quote.brokerName}</span>
                                    </div>
                                </div>
                                {quote.salesRepName &&
                                    <div className={css(styles.row)}>
                                        <div className={css(styles.column, styles.leftColSummary)}>
                                            <span className={css(styles.bolder)}>Emerson Rogers Rep:</span>
                                        </div>
                                        <div className={css(styles.column)}>
                                            <span>{quote.salesRepName}</span>
                                        </div>
                                    </div>
                                }
                                <div className={css(styles.row)}>
                                    <div className={css(styles.column, styles.leftColSummary)}>
                                        <span className={css(styles.bolder)}>Effective Date:</span>
                                    </div>
                                    <div className={css(styles.column)}>
                                        <span>{quote.effectiveDateString}</span>
                                    </div>
                                </div>
                                <div className={css(styles.row)}>
                                    <div className={css(styles.column, styles.leftColSummary)}>
                                        <span className={css(styles.bolder)}>Total EEs on Census:</span>
                                    </div>
                                    <div className={css(styles.column)}>
                                        <span>{quote.censusSize}</span>
                                    </div>
                                </div>
                                {quote.totalNumberEmployees > 0 &&
                                    <div className={css(styles.row)}>
                                        <div className={css(styles.column, styles.leftColSummary)}>
                                            <span className={css(styles.bolder)}>Total EEs on Payroll:</span>
                                        </div>
                                        <div className={css(styles.column)}>
                                            <span>{quote.totalNumberEmployees}</span>
                                        </div>
                                    </div>
                                }
                                {quote.totalNumberEligibleEmployees > 0 &&
                                    <div className={css(styles.row)}>
                                        <div className={css(styles.column, styles.leftColSummary)}>
                                            <span className={css(styles.bolder)}>Total Eligible EEs:</span>
                                        </div>
                                        <div className={css(styles.column)}>
                                            <span>{quote.totalNumberEligibleEmployees}</span>
                                        </div>
                                    </div>
                                }
                                <div className={css(styles.row)}>
                                    <div className={css(styles.column, styles.leftColSummary)}>
                                        <span className={css(styles.bolder)}>ATNE or FTE:</span>
                                    </div>
                                    <div className={css(styles.column)}>
                                        <span>{quote.atne}</span>
                                    </div>
                                </div>
                                <div className={css(styles.row)}>
                                    <div className={css(styles.column, styles.leftColSummary)}>
                                        <span className={css(styles.bolder)}>SIC:</span>
                                    </div>
                                    <div className={css(styles.column)}>
                                        <span>{quote.groupSICCode}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={css(styles.column)}>
                                <div className={css(styles.row)}>
                                    <span className={css(styles.bolder)}>Current Medical Carrier:</span>
                                </div>
                                <div className={css(styles.row)}>
                                    <span>{quote.currentMedicalCarrier}</span>
                                </div>
                                <div className={css(styles.row)}>
                                    <span className={css(styles.bolder)}>Carriers Rated:</span>
                                </div>
                                {quote.carriers ? (
                                    quote.carriers.split(',').map(c => {
                                        return (
                                            <div className={css(styles.row)} key={c}>{c}</div>
                                        )
                                    })) : null}
                            </div>
                        </div>
                    </div>
                    <div className={css(styles.column)}>
                        {quote.newMedicalPlans && (quote.newMedicalPlans.length > 0 || quote.newDentalPlans.length > 0 || quote.newVisionPlans.length > 0) && me.enrollmentPortalPermission && this._renderStatus()}
                        {this._renderNotesAndQuotes()}
                    </div>
                </div>
            </div>
        );
    };

    _renderPlanInfo = () => {
        const { quote } = this.state.quoteDetails;
        return (
            <SelectedPlans
                quote={quote}
                readonly={true}
            />
        );
    };

    _getOutputs = proposalJson => {
        const quoteProposal = JSON.parse(proposalJson);
        const outputs = [];
        if (quoteProposal && quoteProposal.outputOptionInputs) {
            Object.keys(quoteProposal.outputOptionInputs).forEach(key => {
                if (quoteProposal.outputOptionInputs[key]) {
                    let outputText = key.replace(/([A-Z])/g, ' $1')
                        .replace(/^./, (str) => { return str.toUpperCase(); });

                    if (outputText.endsWith(" D")) {
                        outputText = "Dental " + outputText.substr(0, outputText.length - 2);
                    }
                    if (outputText.endsWith(" V")) {
                        outputText = "Vision " + outputText.substr(0, outputText.length - 2);
                    }
                    if (outputText.endsWith(" L")) {
                        outputText = "Life " + outputText.substr(0, outputText.length - 2);
                    }

                    outputs.push(outputText);
                }
            });
        }

        return outputs;
    };

    _getQuoteProposalOutput = (id, fileName) => {
        if (this.state.generatingOutput) {
            return;
        }
        this.setState({ generatingOutput: true });
        //submit to output generator
        quoteProposalApi.getQuoteProposalOutput(id, fileName)
            .then(response => {
                this.setState({ generatingOutput: false });
                if (response && response.startsWith("ERROR")) {
                    console.log("Output generation error: " + response);
                    if (response.indexOf("code 413") > -1) {
                        toast.error("There was an error encountered while generating your output.  Try reducing the number of 'Selected Plans' that you have saved with this proposal.", { toastId: 3 });
                    } else {
                        toast.error("There was an error encountered while generating your output.", { toastId: 3 });
                    }
                }
            });
    }

    _deleteProposal = (id) => {
        if (!window.confirm("Are you sure you want to delete the proposal?  This action cannot be undone.")) {
            return;
        }

        quoteProposalApi.deleteProposal(id)
            .then(result => {
                if (result) {
                    toast.success("The proposal has been deleted.  Reloading...", { toastId: 1 });
                    //reload this page
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                } else {
                    this.setState({ generatingOutput: false });
                    toast.error("There was a problem trying to delete the proposal.", { toastId: 1 });
                }
            });

    };

    _getCensusExtract = (id) => {
        if (this.state.generatingCensus) {
            return;
        }
        this.setState({ generatingCensus: true });
        //submit to output generator
        censusApi.getCensusExtract(id)
            .then(response => {
                this.setState({ generatingCensus: false });
                if (response && response.startsWith("ERROR")) {
                    console.log("Census generation error: " + response);
                    toast.error("There was an error encountered while generating your census.", { toastId: 3 });
                }
            });
    }

    _renderProposals = () => {
        const { quoteProposals, quote } = this.state.quoteDetails;

        return (
            <div className={css(styles.tabContent)}>
                <div className={css(styles.titleSection, styles.contentPadding)}>
                    <TabTitle>Proposals</TabTitle>
                </div>
                <div className={css(styles.row, styles.proposalsContainer)}>
                    {quoteProposals && quoteProposals.length > 0 ? (
                        <table className={css(styles.table)}>
                            <thead>
                                <tr>
                                    <td className={css(styles.tableHeader, styles.tableFirstColumn)}>Proposal Name</td>
                                    <td className={css(styles.tableHeader)}>Carriers</td>
                                    <td className={css(styles.tableHeader)}># of Plans</td>
                                    <td className={css(styles.tableHeader)}>Date Created</td>
                                    <td className={css(styles.tableHeader)}># of EEs</td>
                                    <td className={css(styles.tableHeader)}>Contributions</td>
                                    <td className={css(styles.tableHeader)}>List of Outputs</td>
                                    <td className={css(styles.tableHeader)}>Rate Type(s)</td>
                                    <td className={css(styles.tableHeader)}>Download</td>
                                    <td className={css(styles.tableHeader)}>Remove</td>
                                </tr>
                            </thead>
                            <tbody>
                                {quoteProposals.map(p => (
                                    <tr key={p.id}>
                                        <td className={css(styles.tableRow, styles.greenBar, styles.tableFirstColumn)}>{p.name}</td>
                                        <td className={css(styles.tableRow)}>
                                            {p.medicalCarriers &&
                                                <span>Medical: {p.medicalCarriers}<br /></span>
                                            }
                                            {p.dentalCarriers &&
                                                <span>Dental: {p.dentalCarriers}<br /></span>
                                            }
                                            {p.visionCarriers &&
                                                <span>Vision: {p.visionCarriers}<br /></span>
                                            }
                                            {p.lifeCarriers &&
                                                <span>Life: {p.lifeCarriers}<br /></span>
                                            }
                                        </td>
                                        <td className={css(styles.tableRow)}>
                                            {p.medicalPlanCount > 0 &&
                                                <span>Medical: {parseInt(quote.currentMedicalPlans.length) + parseInt(quote.renewalMedicalPlans.length) + p.medicalPlanCount}<br /></span>
                                            }
                                            {p.dentalPlanCount > 0 &&
                                                <span>Dental: {parseInt(quote.currentDentalPlans.length) + parseInt(quote.renewalDentalPlans.length) + p.dentalPlanCount}<br /></span>
                                            }
                                            {p.visionPlanCount > 0 &&
                                                <span>Vision: {parseInt(quote.currentVisionPlans.length) + parseInt(quote.renewalVisionPlans.length) + p.visionPlanCount}<br /></span>
                                            }
                                            {p.lifePlanCount > 0 &&
                                                <span>Life: {parseInt(quote.currentLifePlans.length) + parseInt(quote.renewalLifePlans.length) + p.lifePlanCount}<br /></span>
                                            }
                                        </td>
                                        <td className={css(styles.tableRow)}>{quote.createDateString}</td>
                                        <td className={css(styles.tableRow)}>{quote.census.filter(c => c.relationship === "EE").length}</td>
                                        <td className={css(styles.tableRow)}>
                                            {p.medicalPlanCount > 0 && p.employeeContribution && p.dependentContribution &&
                                                <span>Medical(EE/Dep): {p.employeeContribution}{"/"}{p.dependentContribution}<br /></span>
                                            }
                                            {p.medicalPlanCount > 0 && p.employeeContribution && !p.dependentContribution &&
                                                <span>Medical(EE/Dep): {p.employeeContribution}{"/0%"}<br /></span>
                                            }
                                            {p.medicalPlanCount > 0 && !p.employeeContribution && p.dependentContribution &&
                                                <span>Medical(EE/Dep): {"50%/"}{p.dependentContribution}<br /></span>
                                            }
                                            {p.medicalPlanCount > 0 && !p.employeeContribution && !p.dependentContribution &&
                                                <span>Medical(EE/Dep): {"50%/0%"}<br /></span>
                                            }
                                        </td>
                                        <td className={css(styles.tableRow)}>
                                            {this._getOutputs(p.outputs).map((item, index) => (
                                                <div key={index}>{item}</div>
                                            ))}
                                        </td>
                                        <td className={css(styles.tableRow)}>
                                            {(p.outputResults.indexOf("-Age") > -1 || p.outputs.indexOf("\"ageRated\":true") > -1) &&
                                                <span>Age<br /></span>
                                            }
                                            {(p.outputResults.indexOf("-Comp") > -1 || p.outputs.indexOf("\"compositeRated\":true") > -1) &&
                                                <span>Composite</span>
                                            }
                                        </td>
                                        <td className={css(styles.tableRow)}>
                                            <div className={css(styles.downloadDocument, this.state.generatingOutput ? styles.opaque : "")} onClick={() => this._getQuoteProposalOutput(p.id, quote.groupName.replace(/\s/g, '') + "_" + p.name.replace(/\s/g, '') + "_" + quote.id + "." + (JSON.parse(p.outputResults).quotePages.toString().indexOf("EmployeeWorksheet") > -1 ? "zip" : JSON.parse(p.outputs).outputs.fileType))}>
                                                <DownloadIcon />
                                            </div>
                                        </td>
                                        <td className={css(styles.tableRow)}>
                                            <div className={css(styles.downloadDocument, this.state.generatingOutput ? styles.opaque : "")} onClick={() => this._deleteProposal(p.id)}>
                                                <GarbageIcon />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>) : (<div className={css(styles.noResults)}>No Proposals Created</div>)}
                </div>
            </div>
        );
    }

    _renderCensus = () => {
        const { quote } = this.state.quoteDetails;
        const { me } = this.props;
        return (
            <div className={css(styles.tabContent)}>
                <div className={css(styles.titleSection, styles.contentPadding)}>
                    <div className={css(styles.topRightHolder)}>
                        <span className={css(styles.tabActivity)}>{quote.censusSize} employees</span>
                    </div>
                    <TabTitle>Census Information <BodyCopyHighlight>(as of {quote.createDateString})</BodyCopyHighlight>
                        {!me.isBroker &&
                            <a href="" onClick={() => this._getCensusExtract(quote.id)} target="_blank" rel="noopener noreferrer"><img className={css(styles.downloadCensus)} src={downloadIcon} alt="download" /></a>
                        }
                    </TabTitle>
                </div>
                <div className={css(styles.titleSection)}>
                </div>
                <div className={css(styles.row)}>
                    <table className={css(styles.table)}>
                        <thead>
                            <tr>
                                <td className={css(styles.tableHeader, styles.tableFirstColumn)}>No.</td>
                                <td className={css(styles.tableHeader)}>Rel</td>
                                <td className={css(styles.tableHeader)}>Name</td>
                                <td className={css(styles.tableHeader)}>DOB</td>
                                <td className={css(styles.tableHeader)}>Age</td>
                                <td className={css(styles.tableHeader)}>Gen</td>
                                <td className={css(styles.tableHeader)}>Med</td>
                                <td className={css(styles.tableHeader)}>Den</td>
                                <td className={css(styles.tableHeader)}>Vis</td>
                                <td className={css(styles.tableHeader)}>Lif</td>
                                <td className={css(styles.tableHeader)}>H Zip</td>
                                <td className={css(styles.tableHeader)}>State</td>
                                <td className={css(styles.tableHeader)}>W Zip</td>
                                <td className={css(styles.tableHeader)}>Title</td>
                                <td className={css(styles.tableHeader)}>Salary</td>
                                <td className={css(styles.tableHeader)}>DOH</td>
                                <td className={css(styles.tableHeader)}>Email</td>
                                <td className={css(styles.tableHeader)}>Tob</td>
                                <td className={css(styles.tableHeader)}>COB</td>
                            </tr>
                        </thead>
                        <tbody>
                            {quote.census ? (quote.census.map(c => {
                                return (
                                    <tr key={c.id} className={c.relationship === "EE" ? "" : "sub"}>
                                        <td className={css(styles.tableRow, styles.tableFirstColumn, (c.relationship === "EE" ? styles.greenBar : styles.blueBar))}>{c.sequenceNumber}</td>
                                        <td className={css(styles.tableRow)}>{c.relationship}</td>
                                        <td className={css(styles.tableRow)}>{c.fullName}</td>
                                        <td className={css(styles.tableRow)}>{c.birthDateString}</td>
                                        <td className={css(styles.tableRow)}>{c.age}</td>
                                        <td className={css(styles.tableRow)}>{c.gender}</td>
                                        <td className={css(styles.tableRow)}>{c.medicalCoverage}</td>
                                        <td className={css(styles.tableRow)}>{c.dentalCoverage}</td>
                                        <td className={css(styles.tableRow)}>{c.visionCoverage}</td>
                                        <td className={css(styles.tableRow)}>{c.lifeCoverage}</td>
                                        <td className={css(styles.tableRow)}>{c.homePostalCode}</td>
                                        <td className={css(styles.tableRow)}>{c.homeState}</td>
                                        <td className={css(styles.tableRow)}>{c.workPostalCode}</td>
                                        <td className={css(styles.tableRow)}>{c.occupation}</td>
                                        <td className={css(styles.tableRow)}>{c.salary}</td>
                                        <td className={css(styles.tableRow)}>{c.hireDateString}</td>
                                        <td className={css(styles.tableRow)}>{c.email}</td>
                                        <td className={css(styles.tableRow)}>{c.isTobacco ? "Yes" : "No"}</td>
                                        <td className={css(styles.tableRow)}>{c.onCobra ? "Yes" : "No"}</td>
                                    </tr>
                                )
                            })) : null}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    _handleSoldPlanChanged = planId => {
        const { soldPlans } = this.state;
        const { newMedicalPlans } = this.state.quoteDetails;
        const index = soldPlans.indexOf(planId);
        if (index > -1) {
            //remove
            soldPlans.splice(index, 1)
        } else {
            //check to make sure that you don't have multiple packages from UHC
            let add = true;
            if (soldPlans.length > 0) {
                //find this plan
                const thisPlan = newMedicalPlans.find(p => p.id === planId);
                if (thisPlan.carrierId === 1) {
                    const thisPkg = thisPlan.packageName;
                    soldPlans.forEach(s => {
                        const soldPkg = newMedicalPlans.find(p => p.id === s && p.carrierId === 1).packageName;
                        if (soldPkg !== thisPkg) {
                            toast.error("You cannot enroll in United Healthcare plans from multiple packages.", { toastId: 1 });
                            add = false;
                        }
                    });
                }
            }
            if (add) {
                soldPlans.push(planId);
            }
        }

        let { soldPlansSelectAllChecked } = this.state;

        if (soldPlans.length === newMedicalPlans.length) {
            soldPlansSelectAllChecked = true;
        } else if (soldPlansSelectAllChecked && soldPlans.length !== newMedicalPlans.length) {
            soldPlansSelectAllChecked = false;
        }

        this.setState({
            soldPlans,
            soldPlansSelectAllChecked
        });
    };

    _handleSoldPlansSelectAllCheckChanged = () => {
        let { soldPlansSelectAllChecked } = this.state;
        soldPlansSelectAllChecked = !soldPlansSelectAllChecked;

        const { newMedicalPlans } = this.state.quoteDetails;

        //check to make sure that you don't have multiple packages from UHC
        if (soldPlansSelectAllChecked) {
            const uhcPlans = newMedicalPlans.filter(p => p.carrierId === 1);
            if (uhcPlans.length > 0) {
                const packages = [...new Set(uhcPlans.map(p => p.packageName))];
                if (packages.length > 1) {
                    toast.error("You cannot enroll in United Healthcare plans from multiple packages.", { toastId: 1 });
                    return;
                }
            }
        }

        //update sold plans
        const soldPlans = soldPlansSelectAllChecked ? newMedicalPlans.map(p => p.id) : [];

        this.setState({
            soldPlansSelectAllChecked,
            soldPlans,
        });
    };

    _handleDentalSoldPlanChanged = planId => {
        const { soldDentalPlans } = this.state;
        const { newDentalPlans } = this.state.quoteDetails;
        const index = soldDentalPlans.indexOf(planId);
        if (index > -1) {
            //remove
            soldDentalPlans.splice(index, 1)
        } else {
            soldDentalPlans.push(planId);
        }

        let { soldDentalPlansSelectAllChecked } = this.state;

        if (soldDentalPlans.length === newDentalPlans.length) {
            soldDentalPlansSelectAllChecked = true;
        } else if (soldDentalPlansSelectAllChecked && soldDentalPlans.length !== newDentalPlans.length) {
            soldDentalPlansSelectAllChecked = false;
        }

        this.setState({
            soldDentalPlans,
            soldDentalPlansSelectAllChecked
        });
    };

    _handleDentalSoldPlansSelectAllCheckChanged = () => {
        let { soldDentalPlansSelectAllChecked } = this.state;
        soldDentalPlansSelectAllChecked = !soldDentalPlansSelectAllChecked;

        const { newDentalPlans } = this.state.quoteDetails;

        //update sold plans
        const soldDentalPlans = soldDentalPlansSelectAllChecked ? newDentalPlans.map(p => p.id) : [];

        this.setState({
            soldDentalPlansSelectAllChecked,
            soldDentalPlans,
        });
    };

    _handleVisionSoldPlanChanged = planId => {
        const { soldVisionPlans } = this.state;
        const { newVisionPlans } = this.state.quoteDetails;
        const index = soldVisionPlans.indexOf(planId);
        if (index > -1) {
            //remove
            soldVisionPlans.splice(index, 1)
        } else {
            soldVisionPlans.push(planId);
        }

        let { soldVisionPlansSelectAllChecked } = this.state;

        if (soldVisionPlans.length === newVisionPlans.length) {
            soldVisionPlansSelectAllChecked = true;
        } else if (soldVisionPlansSelectAllChecked && soldVisionPlans.length !== newVisionPlans.length) {
            soldVisionPlansSelectAllChecked = false;
        }

        this.setState({
            soldVisionPlans,
            soldVisionPlansSelectAllChecked
        });
    };

    _handleVisionSoldPlansSelectAllCheckChanged = () => {
        let { soldVisionPlansSelectAllChecked } = this.state;
        soldVisionPlansSelectAllChecked = !soldVisionPlansSelectAllChecked;

        const { newVisionPlans } = this.state.quoteDetails;

        //update sold plans
        const soldVisionPlans = soldVisionPlansSelectAllChecked ? newVisionPlans.map(p => p.id) : [];

        this.setState({
            soldVisionPlansSelectAllChecked,
            soldVisionPlans,
        });
    };

    _confirmSoldClicked = () => {
        const { soldPlans, soldDentalPlans, soldVisionPlans } = this.state;

        if ((!soldPlans || soldPlans.length === 0) && (!soldDentalPlans || soldDentalPlans.length === 0) && (!soldVisionPlans || soldVisionPlans.length === 0)) {
            toast.error("You must select at least one plan to proceed.", { toastId: 1 });
            return;
        }
        else {
            this.setState({
                showConfirmRatesModal: true,
            });

            if (soldPlans && soldPlans.length > 0) {
                quotesApi.getAgeRated(this.state.quoteDetails.quote.id, soldPlans)
                    .then(response => {
                        this.setState({
                            ageRates: response,
                        });
                    });
            }
        }
    };

    _enrollButtonClicked = () => {
        this.setState({
            showEnrollmentModal: false,
            currentTab: "Enrollment",
        });
    };

    _renderSoldPlans = () => {
        const { newMedicalPlans, newDentalPlans, newVisionPlans } = this.state.quoteDetails;
        const { soldPlans, soldDentalPlans, soldVisionPlans } = this.state;
        const Hover = ({ onHover, children }) => (
            <div className={css(styles.hover)}>
                <div className={css(styles.__nohover)}>{children}</div>
                <div className={css(styles.__hover)}>{onHover}</div>
            </div>
        )

        return (
            <div className={css(styles.tabContent)}>
                <div className={css(styles.titleSection, styles.contentPadding)}>
                    <TabTitle>Plan Confirmation</TabTitle>
                </div>
                <p className={css(styles.instructions)}>
                    {strings.planConfirmationInstructions}
                </p>
                {newMedicalPlans && newMedicalPlans.length > 0 &&
                    <div className={css(styles.newMedicalPlansContainer)}>
                        <BodyCopyHighlight aStyles={styles.greenHeader}>New Medical Plans</BodyCopyHighlight>
                        <div className={css(styles.row, styles.firstRow)}>
                            <table className={css(styles.table, styles.planTable)}>
                                <thead>
                                    <tr>
                                        <td className={css(styles.tableHeader)}>
                                            <Checkbox
                                                name={"soldMedicalPlansSelectAll"}
                                                checked={this.state.soldPlansSelectAllChecked}
                                                onChange={this._handleSoldPlansSelectAllCheckChanged}
                                            />
                                        </td>
                                        <td className={css(styles.tableHeader)}>Carrier</td>
                                        <td className={css(styles.tableHeader)}>Plan</td>
                                        <td className={css(styles.tableHeader)}>Network</td>
                                        <td className={css(styles.tableHeader)}>Type</td>
                                        <td className={css(styles.tableHeader)}>Mtl</td>
                                        <td className={css(styles.tableHeader)}>Monthly Premium</td>
                                        <td className={css(styles.tableHeader)}>EE</td>
                                        <td className={css(styles.tableHeader)}>ES</td>
                                        <td className={css(styles.tableHeader)}>EC</td>
                                        <td className={css(styles.tableHeader)}>EF</td>
                                        <td className={css(styles.tableHeader)}>Ded</td>
                                        <td className={css(styles.tableHeader)}>Coln</td>
                                        <td className={css(styles.tableHeader)}>OOP</td>
                                        <td className={css(styles.tableHeader)}>Copay</td>
                                        <td className={css(styles.tableHeader)}>Rx</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newMedicalPlans.map(p => {
                                        return (
                                            <tr key={p.id}>
                                                <td className={css(styles.tableRow, styles.greenBar)}>
                                                    <Checkbox
                                                        name={"soldMedicalPlansSelect_" + p.id}
                                                        checked={soldPlans.indexOf(p.id) > -1}
                                                        onChange={() => this._handleSoldPlanChanged(p.id)}
                                                    />
                                                </td>
                                                <td className={css(styles.tableRow)}>{p.carrierName}</td>
                                                <td className={css(styles.tableRow)}>{p.displayName}</td>
                                                <td className={css(styles.tableRow)}>{p.networkName}</td>
                                                <td className={css(styles.tableRow)}>{p.planTypeName}</td>
                                                <td className={css(styles.tableRow)}>
                                                    <span className={css(styles.metalCircle)} style={{ backgroundColor: ((p.metallicLevel === "Bronze" || p.metallicLevel === "Expanded Bronze") ? "#cd7f32" : p.metallicLevel === "Silver" ? "#c0c0c0" : p.metallicLevel === "Gold" ? "#ffd700" : p.metallicLevel === "Platinum" ? "#f7f7f7" : "#ffffff") }} />
                                                </td>
                                                <td className={css(styles.tableRow)}>{p.monthlyPremium}</td>
                                                <td className={css(styles.tableRow)}>{p.ee}</td>
                                                <td className={css(styles.tableRow)}>{p.es}</td>
                                                <td className={css(styles.tableRow)}>{p.ec}</td>
                                                <td className={css(styles.tableRow)}>{p.ef}</td>
                                                <td className={css(styles.tableRow)}>{p.deductible}</td>
                                                <td className={css(styles.tableRow)}>{p.coInsurance}</td>
                                                <td className={css(styles.tableRow)}>{p.oop}</td>
                                                <td className={css(styles.tableRow)}>{p.erCopay}</td>
                                                <td className={css(styles.tableRow)}>
                                                    <Hover onHover={<div>{p.rxCard}</div>}>
                                                        <div>Rx</div>
                                                    </Hover>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>}
                {newDentalPlans && newDentalPlans.length > 0 &&
                    <div className={css(styles.newMedicalPlansContainer)}>
                        <BodyCopyHighlight aStyles={styles.greenHeader}>New Dental Plans</BodyCopyHighlight>
                        <div className={css(styles.row, styles.firstRow)}>
                            <table className={css(styles.table, styles.planTable)}>
                                <thead>
                                    <tr>
                                        <td className={css(styles.tableHeader)}>
                                            <Checkbox
                                                name={"soldDentalPlansSelectAll"}
                                                checked={this.state.soldDentalPlansSelectAllChecked}
                                                onChange={this._handleDentalSoldPlansSelectAllCheckChanged}
                                            />
                                        </td>
                                        <td className={css(styles.tableHeader)}>Carrier</td>
                                        <td className={css(styles.tableHeader)}>Plan</td>
                                        <td className={css(styles.tableHeader)}>Network</td>
                                        <td className={css(styles.tableHeader)}>Type</td>
                                        <td className={css(styles.tableHeader)}>Monthly Premium</td>
                                        <td className={css(styles.tableHeader)}>EE</td>
                                        <td className={css(styles.tableHeader)}>ES</td>
                                        <td className={css(styles.tableHeader)}>EC</td>
                                        <td className={css(styles.tableHeader)}>EF</td>
                                        <td className={css(styles.tableHeader)}>Ded</td>
                                        <td className={css(styles.tableHeader)}>Coln</td>
                                        <td className={css(styles.tableHeader)}>Max</td>
                                        <td className={css(styles.tableHeader)}>Funding</td>
                                        <td className={css(styles.tableHeader)}>Per/End</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newDentalPlans.map(p => {
                                        return (
                                            <tr key={p.id}>
                                                <td className={css(styles.tableRow, styles.greenBar)}>
                                                    <Checkbox
                                                        name={"soldDentalPlansSelect_" + p.id}
                                                        checked={soldDentalPlans.indexOf(p.id) > -1}
                                                        onChange={() => this._handleDentalSoldPlanChanged(p.id)}
                                                    />
                                                </td>
                                                <td className={css(styles.tableRow)}>{p.carrierName}</td>
                                                <td className={css(styles.tableRow)}>{p.displayName}</td>
                                                <td className={css(styles.tableRow)}>{p.networkName}</td>
                                                <td className={css(styles.tableRow)}>{p.planTypeName}</td>
                                                <td className={css(styles.tableRow)}>{p.monthlyPremium}</td>
                                                <td className={css(styles.tableRow)}>{p.ee}</td>
                                                <td className={css(styles.tableRow)}>{p.es}</td>
                                                <td className={css(styles.tableRow)}>{p.ec}</td>
                                                <td className={css(styles.tableRow)}>{p.ef}</td>
                                                <td className={css(styles.tableRow)}>{p.deductible}</td>
                                                <td className={css(styles.tableRow)}>{p.coInsurance}</td>
                                                <td className={css(styles.tableRow)}>{p.annualMaximum}</td>
                                                <td className={css(styles.tableRow)}>{p.fundingType}</td>
                                                <td className={css(styles.tableRow)}>{p.perioEndo}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>}
                {newVisionPlans && newVisionPlans.length > 0 &&
                    <div className={css(styles.newMedicalPlansContainer)}>
                        <BodyCopyHighlight aStyles={styles.greenHeader}>New Vision Plans</BodyCopyHighlight>
                        <div className={css(styles.row, styles.firstRow)}>
                            <table className={css(styles.table, styles.planTable)}>
                                <thead>
                                    <tr>
                                        <td className={css(styles.tableHeader)}>
                                            <Checkbox
                                                name={"soldVisionPlansSelectAll"}
                                                checked={this.state.soldVisionPlansSelectAllChecked}
                                                onChange={this._handleVisionSoldPlansSelectAllCheckChanged}
                                            />
                                        </td>
                                        <td className={css(styles.tableHeader)}>Carrier</td>
                                        <td className={css(styles.tableHeader)}>Plan</td>
                                        <td className={css(styles.tableHeader)}>Monthly Premium</td>
                                        <td className={css(styles.tableHeader)}>EE</td>
                                        <td className={css(styles.tableHeader)}>ES</td>
                                        <td className={css(styles.tableHeader)}>EC</td>
                                        <td className={css(styles.tableHeader)}>EF</td>
                                        <td className={css(styles.tableHeader)}>Exam</td>
                                        <td className={css(styles.tableHeader)}>Materials</td>
                                        <td className={css(styles.tableHeader)}>Frames</td>
                                        <td className={css(styles.tableHeader)}>Contacts</td>
                                        <td className={css(styles.tableHeader)}>Funding</td>
                                        <td className={css(styles.tableHeader)}>Frequency</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newVisionPlans.map(p => {
                                        return (
                                            <tr key={p.id}>
                                                <td className={css(styles.tableRow, styles.greenBar)}>
                                                    <Checkbox
                                                        name={"soldVisionPlansSelect_" + p.id}
                                                        checked={soldVisionPlans.indexOf(p.id) > -1}
                                                        onChange={() => this._handleVisionSoldPlanChanged(p.id)}
                                                    />
                                                </td>
                                                <td className={css(styles.tableRow)}>{p.carrierName}</td>
                                                <td className={css(styles.tableRow)}>{p.displayName}</td>
                                                <td className={css(styles.tableRow)}>{p.monthlyPremium}</td>
                                                <td className={css(styles.tableRow)}>{p.ee}</td>
                                                <td className={css(styles.tableRow)}>{p.es}</td>
                                                <td className={css(styles.tableRow)}>{p.ec}</td>
                                                <td className={css(styles.tableRow)}>{p.ef}</td>
                                                <td className={css(styles.tableRow)}>{p.examCopay}</td>
                                                <td className={css(styles.tableRow)}>{p.materialsCopay}</td>
                                                <td className={css(styles.tableRow)}>{p.framesAllowance}</td>
                                                <td className={css(styles.tableRow)}>{p.contactLensAllowance}</td>
                                                <td className={css(styles.tableRow)}>{p.fundingType}</td>
                                                <td className={css(styles.tableRow)}>{p.frequency}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>}
                <div className={css(styles.confirmSoldButtonContainer)}>
                    <ActionButton
                        aStyles={styles.confirmSoldButton}
                        label={strings.confirmSoldButtonText}
                        type="button"
                        onClick={this._confirmSoldClicked}
                        isLoading={this.state.isSaving}
                    />
                </div>

                <Modal
                    show={this.state.showEnrollmentModal}
                    header={strings.enrollmentModalHeader}
                >
                    <div className={css(styles.modalContent)}>
                        <div className={css(styles.modalInstructions)}>
                            <img src={alertImage} alt="alert" className={css(styles.alertIcon)} />
                            <div>You have confirmed your sold plans.</div>
                            <div>The enrollment tab is now accessible.</div>
                        </div>
                    </div>
                    <div className={css(styles.modalButtonContainer)}>
                        <ActionButton
                            aStyles={styles.modalButton}
                            label="Close"
                            invertColor={true}
                            type="button"
                            onClick={() => this.setState({ showEnrollmentModal: false })}
                        />
                        <ActionButton
                            aStyles={styles.modalButton}
                            label="Enroll"
                            onClick={this._enrollButtonClicked}
                            type="button"
                        />
                    </div>
                </Modal>
            </div>
        );
    };

    _isAgeRatedChanged = value => {
        this.setState({
            isAgeRated: value,
        })
    };

    _setCurrentMedicalCompositeRate = (e, p, rel) => {
        const val = this._checkNumber(e.target.value);
        const { planRates } = this.state;
        const { newMedicalPlans } = this.state.quoteDetails;

        let planRate = planRates.find(x => x.id === p.id);
        const plan = newMedicalPlans.find(x => x.id === p.id);

        if (!planRate) {
            planRate = {
                id: plan.id,
                ee: plan.ee,
                es: plan.es,
                ec: plan.ec,
                ef: plan.ef,
                eeEmployerContribution: plan.eeEmployerContribution,
                esEmployerContribution: plan.esEmployerContribution,
                ecEmployerContribution: plan.ecEmployerContribution,
                efEmployerContribution: plan.efEmployerContribution,
                ageEmployeeContribution: plan.ageEmployeeContribution,
                ageDependentContribution: plan.ageDependentContribution,
            };
            planRates.push(planRate);
        }

        if (rel === "EE") {
            planRate.ee = plan.ee = val;
        } else if (rel === "ES") {
            planRate.es = plan.es = val;
        } else if (rel === "EC") {
            planRate.ec = plan.ec = val;
        } else if (rel === "EF") {
            planRate.ef = plan.ef = val;
        }

        this.setState({
            planRates
        });
    }

    _setCurrentDentalCompositeRate = (e, p, rel) => {
        const val = this._checkNumber(e.target.value);
        const { planDentalRates } = this.state;
        const { newDentalPlans } = this.state.quoteDetails;

        let planRate = planDentalRates.find(x => x.id === p.id);
        const plan = newDentalPlans.find(x => x.id === p.id);

        if (!planRate) {
            planRate = {
                id: plan.id,
                ee: plan.ee,
                es: plan.es,
                ec: plan.ec,
                ef: plan.ef,
                eeEmployerContribution: plan.eeEmployerContribution,
                esEmployerContribution: plan.esEmployerContribution,
                ecEmployerContribution: plan.ecEmployerContribution,
                efEmployerContribution: plan.efEmployerContribution,
            };
            planDentalRates.push(planRate);
        }

        if (rel === "EE") {
            planRate.ee = plan.ee = val;
        } else if (rel === "ES") {
            planRate.es = plan.es = val;
        } else if (rel === "EC") {
            planRate.ec = plan.ec = val;
        } else if (rel === "EF") {
            planRate.ef = plan.ef = val;
        }

        this.setState({
            planDentalRates
        });
    }

    _setCurrentVisionCompositeRate = (e, p, rel) => {
        const val = this._checkNumber(e.target.value);
        const { planVisionRates } = this.state;
        const { newVisionPlans } = this.state.quoteDetails;

        let planRate = planVisionRates.find(x => x.id === p.id);
        const plan = newVisionPlans.find(x => x.id === p.id);

        if (!planRate) {
            planRate = {
                id: plan.id,
                ee: plan.ee,
                es: plan.es,
                ec: plan.ec,
                ef: plan.ef,
                eeEmployerContribution: plan.eeEmployerContribution,
                esEmployerContribution: plan.esEmployerContribution,
                ecEmployerContribution: plan.ecEmployerContribution,
                efEmployerContribution: plan.efEmployerContribution,
            };
            planVisionRates.push(planRate);
        }

        if (rel === "EE") {
            planRate.ee = plan.ee = val;
        } else if (rel === "ES") {
            planRate.es = plan.es = val;
        } else if (rel === "EC") {
            planRate.ec = plan.ec = val;
        } else if (rel === "EF") {
            planRate.ef = plan.ef = val;
        }

        this.setState({
            planVisionRates
        });
    }

    _setCurrentMedicalContribution = (e, p, rel) => {
        const val = this._checkNumber(e.target.value);
        const { planRates } = this.state;
        const { newMedicalPlans } = this.state.quoteDetails;

        let planRate = planRates.find(x => x.id === p.id);
        const plan = newMedicalPlans.find(x => x.id === p.id);

        if (!planRate) {
            planRate = {
                id: plan.id,
                ee: plan.ee,
                es: plan.es,
                ec: plan.ec,
                ef: plan.ef,
                eeEmployerContribution: plan.eeEmployerContribution,
                esEmployerContribution: plan.esEmployerContribution,
                ecEmployerContribution: plan.ecEmployerContribution,
                efEmployerContribution: plan.efEmployerContribution,
                ageEmployeeContribution: plan.ageEmployeeContribution,
                ageDependentContribution: plan.ageDependentContribution,
            };
            planRates.push(planRate);
        }

        if (rel === "EE") {
            planRate.eeEmployerContribution = plan.eeEmployerContribution = val;
        } else if (rel === "ES") {
            planRate.esEmployerContribution = plan.esEmployerContribution = val;
        } else if (rel === "EC") {
            planRate.ecEmployerContribution = plan.ecEmployerContribution = val;
        } else if (rel === "EF") {
            planRate.efEmployerContribution = plan.efEmployerContribution = val;
        } else if (rel === "Age_E") {
            planRate.ageEmployeeContribution = plan.ageEmployeeContribution = val;
        } else if (rel === "Age_D") {
            planRate.ageDependentContribution = plan.ageDependentContribution = val;
        }

        this.setState({
            planRates
        });
    }

    _setCurrentDentalContribution = (e, p, rel) => {
        const nbr = this._checkNumber(e.target.value);
        //allow dollar or percent
        let val = nbr;
        if (e.target.value.startsWith('$')) {
            val = '$' + val;
        } else if (e.target.value.endsWith('%')) {
            val = val + '%';
        }

        const { planDentalRates, soldDentalPlanContributions } = this.state;
        const { newDentalPlans } = this.state.quoteDetails;

        let planRate = planDentalRates.find(x => x.id === p.id);
        const plan = newDentalPlans.find(x => x.id === p.id);
        //previously saved
        const soldPlan = soldDentalPlanContributions ? soldDentalPlanContributions.find(x => x.dentalPlanId === p.id) : null;

        if (!planRate) {
            planRate = {
                id: plan.id,
                ee: plan.ee,
                es: plan.es,
                ec: plan.ec,
                ef: plan.ef,
                eeEmployerContribution: plan.eeEmployerContribution,
                esEmployerContribution: plan.esEmployerContribution,
                ecEmployerContribution: plan.ecEmployerContribution,
                efEmployerContribution: plan.efEmployerContribution,
            };
            planDentalRates.push(planRate);
        }

        if (rel === "EE") {
            planRate.eeEmployerContribution = plan.eeEmployerContribution = val;
            if (soldPlan) {
                soldPlan.eeEmployerContribution = val;
            }
        } else if (rel === "ES") {
            planRate.esEmployerContribution = plan.esEmployerContribution = val;
            if (soldPlan) {
                soldPlan.esEmployerContribution = val;
            }
        } else if (rel === "EC") {
            planRate.ecEmployerContribution = plan.ecEmployerContribution = val;
            if (soldPlan) {
                soldPlan.ecEmployerContribution = val;
            }
        } else if (rel === "EF") {
            planRate.efEmployerContribution = plan.efEmployerContribution = val;
            if (soldPlan) {
                soldPlan.efEmployerContribution = val;
            }
        }

        this.setState({
            planDentalRates
        });
    }

    _setCurrentVisionContribution = (e, p, rel) => {
        const nbr = this._checkNumber(e.target.value);
        //allow dollar or percent
        let val = nbr;
        if (e.target.value.startsWith('$')) {
            val = '$' + val;
        } else if (e.target.value.endsWith('%')) {
            val = val + '%';
        }

        const { planVisionRates, soldVisionPlanContributions } = this.state;
        const { newVisionPlans } = this.state.quoteDetails;
        //previously saved
        const soldPlan = soldVisionPlanContributions ? soldVisionPlanContributions.find(x => x.visionPlanId === p.id) : null;

        let planRate = planVisionRates.find(x => x.id === p.id);
        const plan = newVisionPlans.find(x => x.id === p.id);

        if (!planRate) {
            planRate = {
                id: plan.id,
                ee: plan.ee,
                es: plan.es,
                ec: plan.ec,
                ef: plan.ef,
                eeEmployerContribution: plan.eeEmployerContribution,
                esEmployerContribution: plan.esEmployerContribution,
                ecEmployerContribution: plan.ecEmployerContribution,
                efEmployerContribution: plan.efEmployerContribution,
            };
            planVisionRates.push(planRate);
        }

        if (rel === "EE") {
            planRate.eeEmployerContribution = plan.eeEmployerContribution = val;
            if (soldPlan) {
                soldPlan.eeEmployerContribution = val;
            }
        } else if (rel === "ES") {
            planRate.esEmployerContribution = plan.esEmployerContribution = val;
            if (soldPlan) {
                soldPlan.esEmployerContribution = val;
            }
        } else if (rel === "EC") {
            planRate.ecEmployerContribution = plan.ecEmployerContribution = val;
            if (soldPlan) {
                soldPlan.ecEmployerContribution = val;
            }
        } else if (rel === "EF") {
            planRate.efEmployerContribution = plan.efEmployerContribution = val;
            if (soldPlan) {
                soldPlan.efEmployerContribution = val;
            }
        }

        this.setState({
            planVisionRates
        });
    }

    _confirmSoldPlanRates = () => {
        const { planRates, soldPlans, soldPlanContributions, planDentalRates, soldDentalPlans, soldDentalPlanContributions, planVisionRates, soldVisionPlans, soldVisionPlanContributions } = this.state;
        const { newMedicalPlans, newDentalPlans, newVisionPlans } = this.state.quoteDetails;

        //determine rate type based on which rates and contributions were entered
        let rateType = "Composite"; //default
        if (planRates && planRates.length > 0) {
            if (planRates[0].ageEmployeeContribution !== "") {
                rateType = "Age";
            }
        }

        //update rates
        quotesApi.savePlanRates(this.state.quoteDetails.quote.id, planRates, planDentalRates, planVisionRates)
            .then(response => {
                this.setState({
                    showConfirmRatesModal: false,
                    isSaving: true,
                });

                const { enrollmentPortal } = this.state.quoteDetails;

                //create enrollment portal or update the enrollment plan data?
                if (!enrollmentPortal) {
                    const params = {
                        quoteId: this.state.quoteDetails.quote.id,
                        medicalPlanIds: this.state.soldPlans,
                        medicalContributions: planRates,
                        dentalPlanIds: this.state.soldDentalPlans,
                        dentalContributions: planDentalRates,
                        visionPlanIds: this.state.soldVisionPlans,
                        visionContributions: planVisionRates,
                        rateType: rateType,
                    };

                    enrollmentApi.createEnrollmentPortal(params)
                        .then(result => {
                            this.setState(prevState => {
                                return {
                                    quoteDetails: {
                                        ...prevState.quoteDetails,
                                        enrollmentPortal: result
                                    },
                                    showEnrollmentModal: true,
                                    isSaving: false,
                                };
                            });
                        })
                        .catch(err => {
                            logger.error(err);
                            const description = describeNetworkError(err);
                            toast.error(description.join("\n"), { toastId: 1 });

                            this.setState({ isSaving: false })
                        });
                } else {
                    let params = {
                        enrollmentPortalId: enrollmentPortal.id,
                        medicalPlanIds: this.state.soldPlans,
                        medicalContributions: planRates,
                        dentalPlanIds: this.state.soldDentalPlans,
                        dentalContributions: planDentalRates,
                        visionPlanIds: this.state.soldVisionPlans,
                        visionContributions: planVisionRates,
                    };

                    //save previously entered contributions
                    //medical
                    if (params.medicalPlanIds && params.medicalContributions.length === 0 && soldPlanContributions.length > 0) {
                        for (let i = 0; i < soldPlans.length; i++) {
                            const plan = newMedicalPlans.find(p => p.id === soldPlans[i]);
                            const planContributions = soldPlanContributions ? soldPlanContributions.find(p => p.medicalPlanId === soldPlans[i]) : null;
                            let contribution = {};
                            if (plan) {
                                contribution.id = plan.id;
                                contribution.ee = plan.ee ? plan.ee.replace(/\$/g, '') : "";
                                contribution.es = plan.es ? plan.es.replace(/\$/g, '') : "";
                                contribution.ec = plan.ec ? plan.ec.replace(/\$/g, '') : "";
                                contribution.ef = plan.ef ? plan.ef.replace(/\$/g, '') : "";
                                contribution.eeEmployerContribution = plan.eeEmployerContribution ? plan.eeEmployerContribution.replace(/\$/g, '') : planContributions && planContributions.eeEmployerContribution ? planContributions.eeEmployerContribution.replace(/\$/g, '') : "";
                                contribution.esEmployerContribution = plan.esEmployerContribution ? plan.esEmployerContribution.replace(/\$/g, '') : planContributions && planContributions.esEmployerContribution ? planContributions.esEmployerContribution.replace(/\$/g, '') : "";
                                contribution.ecEmployerContribution = plan.ecEmployerContribution ? plan.ecEmployerContribution.replace(/\$/g, '') : planContributions && planContributions.ecEmployerContribution ? planContributions.ecEmployerContribution.replace(/\$/g, '') : "";
                                contribution.efEmployerContribution = plan.efEmployerContribution ? plan.efEmployerContribution.replace(/\$/g, '') : planContributions && planContributions.efEmployerContribution ? planContributions.efEmployerContribution.replace(/\$/g, '') : "";
                                contribution.ageEmployeeContribution = plan.ageEmployeeContribution ? plan.ageEmployeeContribution.replace(/\$/g, '') : planContributions && planContributions.ageEmployeeContribution ? planContributions.ageEmployeeContribution.replace(/\$/g, '') : "";
                                contribution.ageDependentContribution = plan.ageDependentContribution ? plan.ageDependentContribution.replace(/\$/g, '') : planContributions && planContributions.ageDependentContribution ? planContributions.ageDependentContribution.replace(/\$/g, '') : "";
                                params.medicalContributions.push(contribution);
                            }
                        }
                    }
                    //dental
                    if (params.dentalPlanIds && params.dentalContributions.length === 0 && soldDentalPlanContributions.length > 0) {
                        for (let i = 0; i < soldDentalPlans.length; i++) {
                            const plan = newDentalPlans.find(p => p.id === soldDentalPlans[i]);
                            const planContributions = soldDentalPlanContributions ? soldDentalPlanContributions.find(p => p.dentalPlanId === soldDentalPlans[i]) : null;
                            let contribution = {};
                            if (plan) {
                                contribution.id = plan.id;
                                contribution.ee = plan.ee ? plan.ee.replace(/\$/g, '') : "";
                                contribution.es = plan.es ? plan.es.replace(/\$/g, '') : "";
                                contribution.ec = plan.ec ? plan.ec.replace(/\$/g, '') : "";
                                contribution.ef = plan.ef ? plan.ef.replace(/\$/g, '') : "";
                                contribution.eeEmployerContribution = plan.eeEmployerContribution ? plan.eeEmployerContribution : planContributions && planContributions.eeEmployerContribution ? planContributions.eeEmployerContribution : "";
                                contribution.esEmployerContribution = plan.esEmployerContribution ? plan.esEmployerContribution : planContributions && planContributions.esEmployerContribution ? planContributions.esEmployerContribution : "";
                                contribution.ecEmployerContribution = plan.ecEmployerContribution ? plan.ecEmployerContribution : planContributions && planContributions.ecEmployerContribution ? planContributions.ecEmployerContribution : "";
                                contribution.efEmployerContribution = plan.efEmployerContribution ? plan.efEmployerContribution : planContributions && planContributions.efEmployerContribution ? planContributions.efEmployerContribution : "";
                                params.dentalContributions.push(contribution);
                            }
                        }
                    }
                    //vision
                    if (params.visionPlanIds && params.visionContributions.length === 0 && soldVisionPlanContributions.length > 0) {
                        for (let i = 0; i < soldVisionPlans.length; i++) {
                            const plan = newVisionPlans.find(p => p.id === soldVisionPlans[i]);
                            const planContributions = soldVisionPlanContributions ? soldVisionPlanContributions.find(p => p.visionPlanId === soldVisionPlans[i]) : null;
                            let contribution = {};
                            if (plan) {
                                contribution.id = plan.id;
                                contribution.ee = plan.ee ? plan.ee.replace(/\$/g, '') : "";
                                contribution.es = plan.es ? plan.es.replace(/\$/g, '') : "";
                                contribution.ec = plan.ec ? plan.ec.replace(/\$/g, '') : "";
                                contribution.ef = plan.ef ? plan.ef.replace(/\$/g, '') : "";
                                contribution.eeEmployerContribution = plan.eeEmployerContribution ? plan.eeEmployerContribution : planContributions && planContributions.eeEmployerContribution ? planContributions.eeEmployerContribution : "";
                                contribution.esEmployerContribution = plan.esEmployerContribution ? plan.esEmployerContribution : planContributions && planContributions.esEmployerContribution ? planContributions.esEmployerContribution : "";
                                contribution.ecEmployerContribution = plan.ecEmployerContribution ? plan.ecEmployerContribution : planContributions && planContributions.ecEmployerContribution ? planContributions.ecEmployerContribution : "";
                                contribution.efEmployerContribution = plan.efEmployerContribution ? plan.efEmployerContribution : planContributions && planContributions.efEmployerContribution ? planContributions.efEmployerContribution : "";
                                params.visionContributions.push(contribution);
                            }
                        }
                    }

                    //update
                    enrollmentApi.updateSelectedPlans(params)
                        .then(() => {
                            this.setState({ isSaving: false })
                        })
                        .catch(err => {
                            logger.error(err);
                            const description = describeNetworkError(err);
                            toast.error(description.join("\n"), { toastId: 2 });

                            this.setState({ isSaving: false })
                        });
                }

                this._getDetails();
            });
    }

    _checkNumber = val => {
        let rpl = val.replace(/[^0-9.]+/g, "");
        if (parseFloat(rpl).toString()) {
            let split = rpl.split('.');
            if (split) {
                if (split.length === 2 && split[1].length > 2) {
                    return split[0] + '.' + split[1].substr(0, 2);
                } else if (split.length > 2) {
                    return split[0] + '.' + split[1];
                }
            }
            return rpl;
        } else {
            return null;
        }
    }

    _setSelectedPlan = e => {
        const { ageRates } = this.state;
        this.setState({
            selectedPlanId: e.value,
            selectedPlanAgeRates: ageRates.filter(a => a.medicalPlanId === parseInt(e.value)),
        });
    }

    _renderAgeRated = () => {
        const { soldPlans, selectedPlanAgeRates, selectedPlanId } = this.state;
        const { newMedicalPlans } = this.state.quoteDetails;

        const plans = [];
        let selectedPlan = {};

        for (let i = 0; i < soldPlans.length; i++) {
            const plan = newMedicalPlans.find(p => p.id === soldPlans[i]);
            if (parseInt(soldPlans[i]) === parseInt(selectedPlanId)) {
                selectedPlan = plan;
            }
            plans.push(plan);
        }

        return (
            <div>
                <SmallDropdown
                    aStyles={styles.planDropdown}
                    options={plans.map(item => ({
                        label: `${item.carrierAbbr} (${item.networkName}) ${item.displayName} - ${item.individualDeductibleIn} ded`,
                        value: item.id.toString(),
                    }))}
                    placeholder="Select Plan"
                    value={selectedPlanId}
                    onChange={e => this._setSelectedPlan(e)}
                    hideLabel={true}
                    size={inputSizes.large}
                />
                {selectedPlanAgeRates.length > 0 && selectedPlan &&
                    <div>
                        <table className={css(styles.confirmRatestable)}>
                            <tbody>
                                <tr>
                                    <td className={css(styles.tableCell)}>Employer Contribution (%)</td>
                                    <td className={css(styles.tableCell)}>
                                        <TextInput
                                            value={selectedPlan.ageEmployeeContribution ? parseInt(selectedPlan.ageEmployeeContribution.toString()).toString() : ""}
                                            placeholder="%"
                                            onChange={e => this._setCurrentMedicalContribution(e, selectedPlan, "Age_E")}
                                            hideLabel={true}
                                            size={inputSizes.small}
                                        />
                                    </td>
                                    <td className={css(styles.tableCell)}>Dependent Contribution</td>
                                    <td className={css(styles.tableCell)}>
                                        <TextInput
                                            value={selectedPlan.ageDependentContribution ? parseInt(selectedPlan.ageDependentContribution.toString()).toString() : ""}
                                            placeholder="%"
                                            onChange={e => this._setCurrentMedicalContribution(e, selectedPlan, "Age_D")}
                                            hideLabel={true}
                                            size={inputSizes.small}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
                <div className={css(styles.ageRatesInstruction)}>Please review the Age Rates with the carrier.<br />If they do not match, contact support and do not continue.</div>
                {selectedPlanAgeRates.length === 0 && selectedPlanId &&
                    <div className={css(styles.ageRatesNotAvailable)}>Age Rates for this plan are not available. Do not enroll this Group with Age Rates</div>
                }
                <div className={css(styles.ageRatesContainer)}>
                    {selectedPlanAgeRates.map((item, index) => (
                        <div key={index} className={css(styles.ageRateItem)}>
                            <div className={css(styles.ageLabel)}>{item.age === 14 ? "<15" : item.age === 64 ? "64+" : item.age}</div>
                            <div className={css(styles.rateLabel)}>${item.individualRate}</div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    _renderConfirmRatesModal = () => {
        const { isAgeRated, showConfirmRatesModal, soldPlans, soldPlanContributions, soldDentalPlans, soldDentalPlanContributions, soldVisionPlans, soldVisionPlanContributions } = this.state;
        const { newMedicalPlans, newDentalPlans, newVisionPlans } = this.state.quoteDetails;

        //Medical
        const medicalPlans = [];
        for (let i = 0; i < soldPlans.length; i++) {
            const plan = newMedicalPlans.find(p => p.id === soldPlans[i]);
            const planContributions = soldPlanContributions ? soldPlanContributions.find(p => p.medicalPlanId === soldPlans[i]) : null;
            if (plan) {
                plan.ee = plan.ee ? plan.ee.replace(/\$/g, '') : "";
                plan.es = plan.es ? plan.es.replace(/\$/g, '') : "";
                plan.ec = plan.ec ? plan.ec.replace(/\$/g, '') : "";
                plan.ef = plan.ef ? plan.ef.replace(/\$/g, '') : "";
                plan.eeEmployerContribution = plan.eeEmployerContribution ? plan.eeEmployerContribution.replace(/\$/g, '') : planContributions && planContributions.eeEmployerContribution ? planContributions.eeEmployerContribution.replace(/\$/g, '') : "";
                plan.esEmployerContribution = plan.esEmployerContribution ? plan.esEmployerContribution.replace(/\$/g, '') : planContributions && planContributions.esEmployerContribution ? planContributions.esEmployerContribution.replace(/\$/g, '') : "";
                plan.ecEmployerContribution = plan.ecEmployerContribution ? plan.ecEmployerContribution.replace(/\$/g, '') : planContributions && planContributions.ecEmployerContribution ? planContributions.ecEmployerContribution.replace(/\$/g, '') : "";
                plan.efEmployerContribution = plan.efEmployerContribution ? plan.efEmployerContribution.replace(/\$/g, '') : planContributions && planContributions.efEmployerContribution ? planContributions.efEmployerContribution.replace(/\$/g, '') : "";
                plan.ageEmployeeContribution = plan.ageEmployeeContribution ? plan.ageEmployeeContribution.replace(/\$/g, '') : planContributions && planContributions.ageEmployeeContribution ? planContributions.ageEmployeeContribution.replace(/\$/g, '') : "";
                plan.ageDependentContribution = plan.ageDependentContribution ? plan.ageDependentContribution.replace(/\$/g, '') : planContributions && planContributions.ageDependentContribution ? planContributions.ageDependentContribution.replace(/\$/g, '') : "";
                medicalPlans.push(plan);
            }
        }

        //Dental
        const dentalPlans = [];
        for (let i = 0; i < soldDentalPlans.length; i++) {
            const plan = newDentalPlans.find(p => p.id === soldDentalPlans[i]);
            const planContributions = soldDentalPlanContributions ? soldDentalPlanContributions.find(p => p.dentalPlanId === soldDentalPlans[i]) : null;
            if (plan) {
                plan.ee = plan.ee ? plan.ee.replace(/\$/g, '') : "";
                plan.es = plan.es ? plan.es.replace(/\$/g, '') : "";
                plan.ec = plan.ec ? plan.ec.replace(/\$/g, '') : "";
                plan.ef = plan.ef ? plan.ef.replace(/\$/g, '') : "";
                plan.eeEmployerContribution = plan.eeEmployerContribution ? plan.eeEmployerContribution.replace(/\$/g, '') : planContributions && planContributions.eeEmployerContribution ? planContributions.eeEmployerContribution.replace(/\$/g, '') : "";
                plan.esEmployerContribution = plan.esEmployerContribution ? plan.esEmployerContribution.replace(/\$/g, '') : planContributions && planContributions.esEmployerContribution ? planContributions.esEmployerContribution.replace(/\$/g, '') : "";
                plan.ecEmployerContribution = plan.ecEmployerContribution ? plan.ecEmployerContribution.replace(/\$/g, '') : planContributions && planContributions.ecEmployerContribution ? planContributions.ecEmployerContribution.replace(/\$/g, '') : "";
                plan.efEmployerContribution = plan.efEmployerContribution ? plan.efEmployerContribution.replace(/\$/g, '') : planContributions && planContributions.efEmployerContribution ? planContributions.efEmployerContribution.replace(/\$/g, '') : "";
                dentalPlans.push(plan);
            }
        }

        //Vision
        const visionPlans = [];
        for (let i = 0; i < soldVisionPlans.length; i++) {
            const plan = newVisionPlans.find(p => p.id === soldVisionPlans[i]);
            const planContributions = soldVisionPlanContributions ? soldVisionPlanContributions.find(p => p.visionPlanId === soldVisionPlans[i]) : null;
            if (plan) {
                plan.ee = plan.ee ? plan.ee.replace(/\$/g, '') : "";
                plan.es = plan.es ? plan.es.replace(/\$/g, '') : "";
                plan.ec = plan.ec ? plan.ec.replace(/\$/g, '') : "";
                plan.ef = plan.ef ? plan.ef.replace(/\$/g, '') : "";
                plan.eeEmployerContribution = plan.eeEmployerContribution ? plan.eeEmployerContribution.replace(/\$/g, '') : planContributions && planContributions.eeEmployerContribution ? planContributions.eeEmployerContribution.replace(/\$/g, '') : "";
                plan.esEmployerContribution = plan.esEmployerContribution ? plan.esEmployerContribution.replace(/\$/g, '') : planContributions && planContributions.esEmployerContribution ? planContributions.esEmployerContribution.replace(/\$/g, '') : "";
                plan.ecEmployerContribution = plan.ecEmployerContribution ? plan.ecEmployerContribution.replace(/\$/g, '') : planContributions && planContributions.ecEmployerContribution ? planContributions.ecEmployerContribution.replace(/\$/g, '') : "";
                plan.efEmployerContribution = plan.efEmployerContribution ? plan.efEmployerContribution.replace(/\$/g, '') : planContributions && planContributions.efEmployerContribution ? planContributions.efEmployerContribution.replace(/\$/g, '') : "";
                visionPlans.push(plan);
            }
        }

        return (
            <Modal
                show={showConfirmRatesModal}
                header="Confirm Rates"
            >
                <div className={css(styles.modalConfirmPlansContent)}>
                    <div>
                        <div className={css(styles.modalToggle)}>
                            <span className={css(styles.modalLabel)}>Choose Rates:</span>
                            <span>
                                <Toggle
                                    aStyles={styles.toggle}
                                    defaultChecked={isAgeRated}
                                    onChange={this._isAgeRatedChanged}
                                    checkedLabel="Age"
                                    uncheckedLabel="Comp."
                                />
                            </span>
                            {/*<span className={css(styles.modalLabel)}>{eeCount} employees</span>*/}
                        </div>
                        <div>
                            {isAgeRated ? this._renderAgeRated() : (
                                <table className={css(styles.confirmRatestable)}>
                                    {medicalPlans && medicalPlans.length > 0 &&
                                        <thead>
                                            <tr>
                                                <th className={css(styles.tableHeader)}>Medical Plan Name</th>
                                                <th className={css(styles.tableHeader)}>Employee</th>
                                                <th className={css(styles.tableHeader)}>EE+Spouse</th>
                                                <th className={css(styles.tableHeader)}>EE+Child</th>
                                                <th className={css(styles.tableHeader)}>EE+Family</th>
                                            </tr>
                                        </thead>
                                    }
                                    {medicalPlans.map(p => (
                                        <tbody key={p.id}>
                                            <tr>
                                                <td className={css(styles.tableCell)}>{p.displayName} ({p.networkName})</td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.ee ? p.ee.toString() : ""}
                                                        placeholder=""
                                                        onChange={e => this._setCurrentMedicalCompositeRate(e, p, "EE")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.es ? p.es.toString() : ""}
                                                        placeholder=""
                                                        onChange={e => this._setCurrentMedicalCompositeRate(e, p, "ES")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.ec ? p.ec.toString() : ""}
                                                        placeholder=""
                                                        onChange={e => this._setCurrentMedicalCompositeRate(e, p, "EC")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.ef ? p.ef.toString() : ""}
                                                        placeholder=""
                                                        onChange={e => this._setCurrentMedicalCompositeRate(e, p, "EF")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={css(styles.tableCell)}>Employer Contribution ($)</td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.eeEmployerContribution ? p.eeEmployerContribution.toString() : ""}
                                                        placeholder="$"
                                                        onChange={e => this._setCurrentMedicalContribution(e, p, "EE")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.esEmployerContribution ? p.esEmployerContribution.toString() : ""}
                                                        placeholder="$"
                                                        onChange={e => this._setCurrentMedicalContribution(e, p, "ES")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.ecEmployerContribution ? p.ecEmployerContribution.toString() : ""}
                                                        placeholder="$"
                                                        onChange={e => this._setCurrentMedicalContribution(e, p, "EC")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.efEmployerContribution ? p.efEmployerContribution.toString() : ""}
                                                        placeholder="$"
                                                        onChange={e => this._setCurrentMedicalContribution(e, p, "EF")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))}
                                    {dentalPlans && dentalPlans.length > 0 &&
                                        <thead>
                                            <tr>
                                                <th className={css(styles.tableHeader)}>Dental Plan Name</th>
                                                <th className={css(styles.tableHeader)}>Employee</th>
                                                <th className={css(styles.tableHeader)}>EE+Spouse</th>
                                                <th className={css(styles.tableHeader)}>EE+Child</th>
                                                <th className={css(styles.tableHeader)}>EE+Family</th>
                                            </tr>
                                        </thead>
                                    }
                                    {dentalPlans.map(p => (
                                        <tbody key={p.id}>
                                            <tr>
                                                <td className={css(styles.tableCell)}>{p.displayName} ({p.networkName})</td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.ee ? p.ee.toString() : ""}
                                                        placeholder=""
                                                        onChange={e => this._setCurrentDentalCompositeRate(e, p, "EE")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.es ? p.es.toString() : ""}
                                                        placeholder=""
                                                        onChange={e => this._setCurrentDentalCompositeRate(e, p, "ES")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.ec ? p.ec.toString() : ""}
                                                        placeholder=""
                                                        onChange={e => this._setCurrentDentalCompositeRate(e, p, "EC")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.ef ? p.ef.toString() : ""}
                                                        placeholder=""
                                                        onChange={e => this._setCurrentDentalCompositeRate(e, p, "EF")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={css(styles.tableCell)}>Employer Contribution ($ or %)</td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.eeEmployerContribution ? p.eeEmployerContribution.toString() : ""}
                                                        placeholder="$ or %"
                                                        onChange={e => this._setCurrentDentalContribution(e, p, "EE")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.esEmployerContribution ? p.esEmployerContribution.toString() : ""}
                                                        placeholder="$ or %"
                                                        onChange={e => this._setCurrentDentalContribution(e, p, "ES")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.ecEmployerContribution ? p.ecEmployerContribution.toString() : ""}
                                                        placeholder="$ or %"
                                                        onChange={e => this._setCurrentDentalContribution(e, p, "EC")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.efEmployerContribution ? p.efEmployerContribution.toString() : ""}
                                                        placeholder="$ or %"
                                                        onChange={e => this._setCurrentDentalContribution(e, p, "EF")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))}
                                    {visionPlans && visionPlans.length > 0 &&
                                        <thead>
                                            <tr>
                                                <th className={css(styles.tableHeader)}>Vision Plan Name</th>
                                                <th className={css(styles.tableHeader)}>Employee</th>
                                                <th className={css(styles.tableHeader)}>EE+Spouse</th>
                                                <th className={css(styles.tableHeader)}>EE+Child</th>
                                                <th className={css(styles.tableHeader)}>EE+Family</th>
                                            </tr>
                                        </thead>
                                    }
                                    {visionPlans.map(p => (
                                        <tbody key={p.id}>
                                            <tr>
                                                <td className={css(styles.tableCell)}>{p.displayName}</td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.ee ? p.ee.toString() : ""}
                                                        placeholder=""
                                                        onChange={e => this._setCurrentVisionCompositeRate(e, p, "EE")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.es ? p.es.toString() : ""}
                                                        placeholder=""
                                                        onChange={e => this._setCurrentVisionCompositeRate(e, p, "ES")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.ec ? p.ec.toString() : ""}
                                                        placeholder=""
                                                        onChange={e => this._setCurrentVisionCompositeRate(e, p, "EC")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.ef ? p.ef.toString() : ""}
                                                        placeholder=""
                                                        onChange={e => this._setCurrentVisionCompositeRate(e, p, "EF")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={css(styles.tableCell)}>Employer Contribution ($ or %)</td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.eeEmployerContribution ? p.eeEmployerContribution.toString() : ""}
                                                        placeholder="$ or %"
                                                        onChange={e => this._setCurrentVisionContribution(e, p, "EE")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.esEmployerContribution ? p.esEmployerContribution.toString() : ""}
                                                        placeholder="$ or %"
                                                        onChange={e => this._setCurrentVisionContribution(e, p, "ES")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.ecEmployerContribution ? p.ecEmployerContribution.toString() : ""}
                                                        placeholder="$ or %"
                                                        onChange={e => this._setCurrentVisionContribution(e, p, "EC")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                                <td className={css(styles.tableCell)}>
                                                    <TextInput
                                                        value={p.efEmployerContribution ? p.efEmployerContribution.toString() : ""}
                                                        placeholder="$ or %"
                                                        onChange={e => this._setCurrentVisionContribution(e, p, "EF")}
                                                        hideLabel={true}
                                                        size={inputSizes.small}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))}
                                </table>
                            )}
                        </div>
                    </div>
                </div>
                <div className={css(styles.modalConfirmRatesButtonContainer)}>
                    <ActionButton
                        aStyles={styles.modalButton}
                        label="Cancel"
                        invertColor={true}
                        type="button"
                        onClick={() => this.setState({ showConfirmRatesModal: false })}
                    />
                    <ActionButton
                        aStyles={styles.modalButton}
                        label="Save"
                        type="button"
                        onClick={this._confirmSoldPlanRates}
                        isLoading={this.state.isSaving}
                    />
                </div>
            </Modal>
        );
    }

    render() {
        if (this.state.loading) {
            return (
                <div className={css(styles.content)}>
                    <ToastContainer position="top-center" autoClose={2500} />
                    <div className={css(styles.contentSection)}>
                        <div className={css(styles.header)}>
                            <div className={css(styles.topRightHolder)}>
                                <Spinner text="Loading..." />
                            </div>
                            <PageTitle>Quote Details</PageTitle>
                        </div>
                    </div>
                </div>
            );
        }

        const { me } = this.props;
        const { quote } = this.state.quoteDetails;
        const isSold = this.state.inputs[inputNames.quoteStatusId] === 10;
        const { enrollmentPortal } = this.state.quoteDetails;

        return (
            <div className={css(styles.content)}>
                <ToastContainer position="top-center" autoClose={2500} />
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.header)}>
                        <div className={css(styles.topRightHolder)}>
                            {me.ratingEngineQuotePermission &&
                                <ReQuoteButton
                                    aStyles={styles.reQuote}
                                    onClick={this._goToReQuote}
                                    label={strings.reQuote}
                                />
                            }
                            {(!isSold && (me.isSysAdmin || this.state.allowNewProposals)) &&
                                <AddButton
                                    aStyles={styles.addProposal}
                                    onClick={this._goToAddProposal}
                                    label={strings.addProposal}
                                    includeBorder={true}
                                />}
                        </div>
                        <PageTitle>Quote: {quote.quoteNumber}{isSold ? " (locked)" : ""}</PageTitle>
                    </div>
                    <Tabs
                        history={this.props.history}
                        backUrl={routeHelpers.quotes()}
                        currentTab={this.state.currentTab}
                    >
                        <div label="Summary">
                            {this._renderSummary()}
                        </div>
                        <div label="Plan Info">
                            {this._renderPlanInfo()}
                        </div>
                        <div label="Proposals">
                            {this._renderProposals()}
                        </div>
                        <div label="Census">
                            {this._renderCensus()}
                        </div>
                        <div label="Sold Plans" disabled={!isSold || !me.enrollmentPortalPermission}>
                            {this._renderConfirmRatesModal()}
                            {this._renderSoldPlans()}
                        </div>
                        <div label="Enrollment" disabled={!this.state.quoteDetails.enrollmentPortal || !isSold || !me.enrollmentPortalPermission}>
                            {enrollmentPortal &&
                                <Enrollment
                                    enrollmentPortalId={enrollmentPortal.id}
                                />
                            }
                        </div>
                    </Tabs>
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = state => ({
    me: state.session.me,
});

export default connect(mapDispatchToProps)(QuoteDetailsScreen);
