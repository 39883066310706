import * as breakpoints from "../../constants/breakpoints";
import * as color from "../../constants/color";
import * as texchangePropTypes from "../../texchangePropTypes";
import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    title: {
        width: "100%",
        height: 62,
        borderBottom: `1px solid ${color.darkGray2}`,      
        padding: 12,  
        boxShadow: `0 1px 3px ${color.blackAlpha20}`,
    },
    left: {
        float: "left",
    },
    icon: {
        padding: 1,
        verticalAlign: "middle",
    },
    h1: {
        color: color.darkRed,
        fontSize: 24,
        fontWeight: "bold",
        letterSpacing: 0.3,
        lineHeight: "32px",
        margin: 0,
        paddingLeft: 8,

        [breakpoints.tabletAndDown]: {
            fontSize: 18,
            lineHeight: "24px",
        },
    },
});

export default function PageTitle(props) {
    const { aStyles, icon, children, ...rest } = props;
    return (
        <div className={css(styles.title)}>
            <div className={css(styles.left)}>
                {icon &&
                    <img className={css(styles.icon)} src={icon} alt="Rogers Benefit Group" />
                }
            </div>
            <div className={css(styles.left)}>
                <h1 {...rest} className={css(styles.h1, aStyles)}>
                    {children}
                </h1>
            </div>
        </div>
    );
}

PageTitle.propTypes = {
    aStyles: texchangePropTypes.aphroditeStyles,
    children: PropTypes.node,
    icon: PropTypes.string,
};
