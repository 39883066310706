import * as color from "../../constants/color";
import * as texchangePropTypes from "../../texchangePropTypes";
import PageTitle from "../text/PageTitle";
import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    content: {
        paddingRight: 10,
        paddingBottom: 80,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
    },
    contentSection: {
        width: "100%",
        padding: 0,
        backgroundColor: color.white,
        borderTop: `8px solid ${color.mediumRed}`,
        marginBottom: 20,
    },
    titleSection: {
        heigth: 64,
        backgroundColor: color.white,
    },
    terms: {
        fontSize: 18,
        padding: "10px 20px",
    },
});

export default class TermsScreen extends Component {
    static propTypes = {
        history: texchangePropTypes.history.isRequired,
    };

    componentDidMount() {
        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    render() {
        return (
            <div className={css(styles.content)}>
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.titleSection)}>
                        <PageTitle>Terms of Use</PageTitle>
                    </div>
                    <div className={css(styles.terms)}>
                        <p>Please read the following terms and conditions carefully. They govern your access and use of this website. By accessing or using this website, you agree to be bound by these terms and conditions and accept them in full, as they may be modified by Rogers Benefit Group from time to time and posted on this website.</p>
                        <p><b>No Warranties</b></p>
                        <p>Although Rogers Benefit Group attempts to provide accurate information, names, images, pictures, logos, icons, insurance plan and rate data, documents, and materials (collectively, the &quot; Contents&quot;) on the Website, it makes no representation, endorsement, or warranty that such Contents are accurate or suitable for any particular purpose.  The website and its contents are provided on an &quot;as is&quot; basis. Use of the website and its contents is at the user&apos;s sole risk.</p>
                        <p>The website and its contents are provided without any representations, endorsements, or warranties of any kind whatsoever, either express or implied, including, but not limited to, any warranties of title or accuracy and any implied warranties of merchantability, fitness for a particular purpose, or non-infringement, with the sole exception of warranties (if any) which cannot be expressly excluded under applicable law. as noted below, Rogers Benefit Group also makes no representations, endorsements, or warranties, either express or implied, with respect to any website operated by a third party.</p>
                        <p><b>Limitation of Liability</b></p>
                        <p>In no event will Rogers Benefit Group or its subsidiaries, affiliates, contractors, or their respective employees be liable for any damages, including, without limitation, indirect, incidental, special, consequential or punitive damages, whether under a contract, tort or any other theory of liability, arising in connection with any party&apos;s use of the website or in connection with any failure of performance, error, omission, interruption, defect, delay in operation or transmission, computer virus, line system failure, loss of data, or loss of use related to this website or any website operated by any third party or any contents of this website or any other website, even if Rogers Benefit Group is aware of the possibility of such damages.</p>
                        <p><b>Use of Website</b></p>
                        <p>Contents are included in this Website solely for the personal use of Website users. You may not modify, reproduce, transfer, resell, or republish any of the Contents of this Website without the prior written consent of Rogers Benefit Group, which may be withheld in its sole discretion.</p>
                        <p><b>Copyrights and Other Intellectual Property</b></p>
                        <p>Except where otherwise expressly noted or as noted below, all Contents of this Website, including reports, dashboards, graphics, icons and overall appearance of the Website, are the sole and exclusive property of Rogers Benefit Group Inc.</p>
                        <p><b>Modifications</b></p>
                        <p>Rogers Benefit Group may, at any time, make modifications, changes, and alterations to the Contents of this Website, including these Terms and Conditions, without prior notice. You are responsible for regularly reviewing these terms and conditions. Your continued use of this Website following any modifications, changes, or alterations shall constitute your acceptance of such modifications, changes, or alterations.</p>
                        <p><b>Waiver</b></p>
                        <p>No waiver by Rogers Benefit Group of any right under or term or provision of these Terms and Conditions will be deemed a waiver of any other right, term, or provision of these Terms and Conditions at that time or a waiver of that or any other right, term, or provision of these Terms and Conditions at any other time.</p>
                    </div>
                </div>
            </div>
        );
    }
}
