import instance from "./instance";

const responseData = response => response.data;

export function createEnrollmentPortal(params) {
    return instance
        .request({
            url: "/enrollment/createEnrollmentPortal",
            method: "POST",
            data: { ...params },
        })
        .then(responseData);
}

export function updateSelectedPlans(params) {
    return instance
        .request({
            url: "/enrollment/updateSelectedPlans",
            method: "POST",
            data: { ...params },
        })
        .then(responseData);
}

export function updateEnrollmentCensus(quote) {
    return instance
        .request({
            url: "/enrollment/updateEnrollmentCensus",
            method: "POST",
            data: { ...quote },
        })
        .then(responseData);
}

export function getEditEnrollment(enrollmentPortalId) {
    return instance
        .request({
            url: `/enrollment/getEditEnrollment/${enrollmentPortalId}`,
            method: "GET"
        })
        .then(responseData);
}

export function getInsuranceTypePlanOptions() {
    return instance
        .request({
            url: "/enrollment/getInsuranceTypePlanOptions",
            method: "GET",
        })
        .then(responseData);
}

export function getCarrierForms(enrollmentPortalId) {
    return instance
        .request({
            url: `/enrollment/getCarrierForms/${enrollmentPortalId}`,
            method: "GET",
        })
        .then(responseData);
}

export function getBillingInvoiceTimings() {
    return instance
        .request({
            url: "/enrollment/getBillingInvoiceTimings",
            method: "GET",
        })
        .then(responseData);
}


export function saveEnrollmentPortal(enrollmentPortal) {
    return instance
        .request({
            url: "/enrollment/saveEnrollmentPortal",
            method: "POST",
            data: { ...enrollmentPortal },
        })
        .then(responseData);
}

export function saveLinesOfCoverage(params) {
    return instance
        .request({
            url: "/enrollment/saveLinesOfCoverage",
            method: "POST",
            data: { ...params },
        })
        .then(responseData);
}

export function saveBillingContact(billingContact) {
    return instance
        .request({
            url: "/enrollment/saveBillingContact",
            method: "POST",
            data: { ...billingContact },
        })
        .then(responseData);
}

export function sendEnrollmentPortalDataToUBenefit(enrollmentPortalId) {
    return instance
        .request({
            url: `/enrollment/sendEnrollmentPortalDataToUBenefit`,
            method: "POST",
            data: enrollmentPortalId,
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}