import instance from "./instance";

const responseData = response => response.data;

export function getAll() {
    return instance
        .request({
            url: "/account/offices",
            method: "GET",
        })
        .then(responseData);
}

export function getMine() {
    return instance
        .request({
            url: "/office/offices",
            method: "GET",
        })
        .then(responseData);
}

export function getActiveRBG(id) {
    return instance
        .request({
            url: `/office/rbg-offices/${id}`,
            method: "GET",
        })
        .then(responseData);
}

export function getPersonnel(page, sortColumn, sortDirection) {
    return instance
        .request({
            url: "/office/get-personnel-page",
            method: "POST",
            data: {
                page,
                sortColumn,
                sortDirection
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function getPersonnelById(id) {
    return instance
        .request({
            url: `/office/get-personnel/${id}`,
            method: "GET",
        })
        .then(responseData);
}

export function savePersonnel(personnel) {
    return instance
        .request({
            url: "/office/save-personnel",
            method: "POST",
            data: { ...personnel },
        })
        .then(responseData);
}

export function savePersonnelOfficeAccess(user) {
    return instance
        .request({
            url: "/office/save-personnel-office-access",
            method: "POST",
            data: { ...user },
        })
        .then(responseData);
}

export function getSupportInfo() {
    return instance
        .request({
            url: "/office/get-support-info",
            method: "GET",
        })
        .then(responseData);
}
