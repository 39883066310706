import * as texchangePropTypes from "../texchangePropTypes";
import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, css } from "aphrodite";
import blueCircleIcon from "../images/blue-circle.png";
import plusIcon from "../images/SVGs/plusIcon.svg"
import * as color from "../constants/color";

const styles = StyleSheet.create({
    button: {
        cursor: "pointer",
        border: "none",
        outline: "none",
        lineHeight: "12px",
        marginLeft: 8,
        padding: "2px 3px 2px 6px",
    },
    whiteBG: {
        color: color.darkestGray,
        background: color.white,
    },
    blueBG: {
        color: color.white,
        background: color.blue1,
    },
    border: {
        border: `1px solid ${color.darkGray7}`,
        borderRadius: 4,
    },
    labelText: {        
        fontSize: 14,     
        float: "left",
        lineHeight: "22px",        
    },
    blueCircle: {        
        marginLeft: 10,
        float: "right",
    },
});

export default function AddButton(props) {
    const { aStyles, label, showAsBlue, includeBorder, ...rest } = props;
    return (        
        <button type="button" {...rest} className={css(styles.button, showAsBlue ? styles.blueBG : styles.whiteBG, (showAsBlue || includeBorder) ? styles.border : null, aStyles)}>
            <div className={css(styles.labelText)}>{label}</div>
            <img alt="" src={plusIcon} className={css(styles.blueCircle)} />
        </button>
    );
}

AddButton.propTypes = {
    aStyles: texchangePropTypes.aphroditeStyles,    
    label: PropTypes.string.isRequired,    
};