import * as breakpoints from "../../constants/breakpoints";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { GUTTER_WIDTH } from "./Container";
import { StyleSheet, css } from "aphrodite";

function smallClassName(span) {
    return `sm-${span}`;
}
function smallOffsetClassName(span) {
    return `sm-os-${span}`;
}
function smallPullClassName(span) {
    return `sm-pull-${span}`;
}
function smallPushClassName(span) {
    return `sm-push-${span}`;
}
function mediumClassName(span) {
    return `md-${span}`;
}
function mediumOffsetClassName(span) {
    return `md-os-${span}`;
}
function mediumPullClassName(span) {
    return `md-pull-${span}`;
}
function mediumPushClassName(span) {
    return `md-push-${span}`;
}
function largeClassName(span) {
    return `lg-${span}`;
}
function largeOffsetClassName(span) {
    return `lg-os-${span}`;
}
function largePullClassName(span) {
    return `lg-pull-${span}`;
}
function largePushClassName(span) {
    return `lg-push-${span}`;
}
function percentage(span) {
    return `${(span / 12) * 100}%`;
}

const definition = {
    column: {
        float: "left",
        position: "relative",
    },
};

for (let i = 1; i < 13; i++) {
    const percentWidth = percentage(i);

    definition[smallClassName(i)] = {
        [breakpoints.mobileOnly]: {
            width: percentWidth,
            paddingLeft: GUTTER_WIDTH / 2,
            paddingRight: GUTTER_WIDTH / 2,
        },
    };

    definition[mediumClassName(i)] = {
        [breakpoints.tabletOnly]: {
            width: percentWidth,
            paddingLeft: GUTTER_WIDTH / 2,
            paddingRight: GUTTER_WIDTH / 2,
        },
    };

    definition[largeClassName(i)] = {
        [breakpoints.desktopOnly]: {
            width: percentWidth,
            paddingLeft: GUTTER_WIDTH / 2,
            paddingRight: GUTTER_WIDTH / 2,
        },
    };

    definition[smallOffsetClassName(i)] = {
        [breakpoints.mobileOnly]: {
            marginLeft: percentWidth,
        },
    };

    definition[smallPullClassName(i)] = {
        [breakpoints.mobileOnly]: {
            right: percentWidth,
        },
    };

    definition[smallPushClassName(i)] = {
        [breakpoints.mobileOnly]: {
            left: percentWidth,
        },
    };

    definition[mediumOffsetClassName(i)] = {
        [breakpoints.tabletOnly]: {
            marginLeft: percentWidth,
        },
    };

    definition[mediumPullClassName(i)] = {
        [breakpoints.tabletOnly]: {
            right: percentWidth,
        },
    };

    definition[mediumPushClassName(i)] = {
        [breakpoints.tabletOnly]: {
            left: percentWidth,
        },
    };

    definition[largeOffsetClassName(i)] = {
        [breakpoints.desktopOnly]: {
            marginLeft: percentWidth,
        },
    };

    definition[largePullClassName(i)] = {
        [breakpoints.desktopOnly]: {
            right: percentWidth,
        },
    };

    definition[largePushClassName(i)] = {
        [breakpoints.desktopOnly]: {
            left: percentWidth,
        },
    };
}

const styles = StyleSheet.create(definition);

export default class Column extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        large: PropTypes.number.isRequired,
        largeOffset: PropTypes.number.isRequired,
        largePull: PropTypes.number.isRequired,
        largePush: PropTypes.number.isRequired,
        medium: PropTypes.number.isRequired,
        mediumOffset: PropTypes.number.isRequired,
        mediumPull: PropTypes.number.isRequired,
        mediumPush: PropTypes.number.isRequired,
        small: PropTypes.number.isRequired,
        smallOffset: PropTypes.number.isRequired,
        smallPull: PropTypes.number.isRequired,
        smallPush: PropTypes.number.isRequired,
    };

    static defaultProps = {
        large: 12,
        largeOffset: 0,
        largePull: 0,
        largePush: 0,
        medium: 12,
        mediumOffset: 0,
        mediumPull: 0,
        mediumPush: 0,
        small: 12,
        smallOffset: 0,
        smallPull: 0,
        smallPush: 0,
    };

    render() {
        const className = css(
            styles.column,
            styles[smallClassName(this.props.small)],
            styles[smallOffsetClassName(this.props.smallOffset)],
            styles[smallPullClassName(this.props.smallPull)],
            styles[smallPushClassName(this.props.smallPush)],
            styles[mediumClassName(this.props.medium)],
            styles[mediumOffsetClassName(this.props.mediumOffset)],
            styles[mediumPullClassName(this.props.mediumPull)],
            styles[mediumPushClassName(this.props.mediumPush)],
            styles[largeClassName(this.props.large)],
            styles[largeOffsetClassName(this.props.largeOffset)],
            styles[largePullClassName(this.props.largePull)],
            styles[largePushClassName(this.props.largePush)],
        );

        return <div className={className}>{this.props.children}</div>;
    }
}
