export function home() {
    return "/";
}

export function terms() {
    return "/terms";
}

export function userDashboard() {
    // TODO: This will eventually be "/", once the demo page is removed
    return "/dashboard";
}

export function account() {
    return "/myaccount";
}

export function brokers() {
    return "/brokers";
}

export function brokerDetail(id) {
    return `/brokers/detail/${id}`;
}

export function editBroker() {
    return "/brokers/edit";
}

export function agencyDetail(id) {
    return `/agencies/detail/${id}`;
}

export function editAgency() {
    return "/agencies/edit";
}

export function groups() {
    return "/groups";
}

export function editGroup() {
    return "/groups/edit";
}

export function groupDetail(id) {
    return `/groups/detail/${id}`;
}

export function quotes() {
    return "/quotes";
}

export function createQuote() {
    return "/quotes/edit";
}

export function editQuote(id) {
    return `/quotes/edit/${id}`;
}

export function reQuote(id) {
    return `/quotes/edit/${id}?r`;
}

export function quoteDetail(id) {
    return `/quotes/detail/${id}`;
}

export function login() {
    return "/account/login";
}

export function register() {
    return "/account/register";
}

export function forgotPassword() {
    return "/account/forgot-password";
}

export function resetPassword() {
    return "/account/reset-password";
}

export function carriers() {
    return "/carriers";
}

export function plans() {
    return "/plans";
}

export function planDetail(id) {
    return `/plans/detail/${id}`;
}

export function editPlan() {
    return "/plans/edit";
}

export function personnel() {
    return "/personnel";
}

export function personnelDetail(id) {
    return `/personnel/detail/${id}`;
}

export function editPersonnel() {
    return "/personnel/edit";
}

export function support() {
    return "/support";
}

export function favorites() {
    return "/favorites";
}