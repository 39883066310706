import * as color from "../../constants/color";
import * as routeHelpers from "../../lib/routeHelpers";
import * as searchInputTypes from "../../constants/searchInputTypes";
import { StyleSheet, css } from "aphrodite";
import Spinner from "../Spinner";
import TextInput from "../inputs/TextInput";
import PageTitle from "../text/PageTitle";
import TextArea from "../inputs/TextArea";
import Checkbox from "../inputs/Checkbox";
import React, { Component } from "react";
import AddButton from "../AddButton";
import ActionButton from "../ActionButton";
import Modal from "../Modal";
import { connect } from "react-redux";
import * as texchangePropTypes from "../../texchangePropTypes";
import logger from "../../lib/logger";
import InlineEdit from "../inputs/InlineEdit";
import Headline from "../text/Headline";
import { Container, Row, Column } from "../grid";
import SearchInput from "../inputs/SearchInput";
import Dropdown from "../inputs/Dropdown";
import SmallDropdown from "../inputs/SmallDropdown";
import DatePicker from "../inputs/DatePicker";
import Tabs from "../Tabs";
import Infinite from "../Infinite";
import * as employerApi from "../../lib/api/employer";
import * as dataApi from "../../lib/api/data";
import * as searchApi from "../../lib/api/search";
import * as util from "../../lib/util";
import segmentSizes from "../../constants/segmentSizes";
import format from "date-fns/format";
import sortBy from "lodash.sortby";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Moment from 'react-moment';
//import editIcon from "../../images/edit-content.png";

const styles = StyleSheet.create({
    content: {
        paddingRight: 10,
        paddingBottom: 80,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
    },
    contentSection: {
        width: "100%",
        padding: 0,
        backgroundColor: color.white,
        borderTop: `8px solid ${color.mediumRed}`,
        marginBottom: 0,
    },
    title: {
        fontSize: 20,
        fontWeight: 500,
        paddingBottom: 0,
        marginBottom: 16,
    },
    titleSection: {
        heigth: 64,
    },
    groupDetails: {
        marginBottom: 20,
    },
    generalInfo: {
        padding: 24,
        paddingBottom: 64,
        backgroundColor: color.white,
    },
    tabContentHeader: {
        padding: 24,
        backgroundColor: color.white,
    },
    tabContentHeaderText: {
        color: color.darkestGray,
        fontWeight: 500,
        fontSize: 20,
    },
    leftColumn: {
        paddingRight: 16,
    },
    centerColumn: {
        paddingRight: 16,
        paddingLeft: 16,
    },
    rightColumn: {
        paddingLeft: 16,
    },
    checkboxContainer: {
        textAlign: "right",
        marginTop: 12,
        marginBottom: 12,
    },
    checkLabel: {
        display: "inline-block",
        position: "relative",
        border: "none",
        marginTop: 8,
        marginBottom: 10,
        "-webkit-appearance": "none",
        cursor: "pointer",
        ":first-child": {
            marginRight: 24,
        }
    },
    check: {
        position: "absolute",
        top: -2,
        left: 0,
        marginRight: 10,
    },
    labelText: {
        marginLeft: 28,
        fontSize: 14,
        marginRight: 12,
    },
    container: {
        borderBottom: `1px solid ${color.darkGray6}`,
        paddingBottom: 12,
        marginBottom: 12,
        height: 60,
    },
    containerScroll: {
        borderBottom: `1px solid ${color.darkGray6}`,
        paddingBottom: 12,
        marginBottom: 12,
        height: 109,
        overflowX: "scroll",
    },
    headerLabel: {
        float: "left",
        fontSize: 14,
        fontWeight: 500,
        color: color.darkGray,
    },
    displayContainer: {
        clear: "both",
        color: color.darkestGray,
        fontSize: 16,
        paddingTop: 10,
    },
    displayRow: {
        marginTop: 6,
        marginBottom: 6,
        minHeight: 18,
    },
    link: {
        cursor: "pointer",
        color: color.mediumYellow1,
    },
    dropdownEdit: {
        paddingTop: 10,
    },
    asOf: {
        fontSize: 14,
        position: "relative",
        left: 6,
        top: -2,
    },
    quotesByYearHeader: {
        display: "flex",
    },
    quotesByYearText: {
        color: color.darkestGray,
        fontWeight: 500,
        fontSize: 20,
        marginRight: 10,
    },
    quotesByYearDropdown: {
        width: 110,
    },
    table: {
        width: "100%",
        marginTop: 5,
        marginBottom: 10,
        borderCollapse: "collapse",
    },
    tableFirstColumn: {
        paddingLeft: 16,
    },
    tableHeader: {
        fontSize: 12,
        color: color.mediumYellow1,
        fontWeight: 500,
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: 12,
        paddingBottom: 12,
        whiteSpace: "nowrap",
        borderBottom: `1px solid ${color.mediumGray}`,
        borderTop: `1px solid ${color.darkGray2}`,
        backgroundColor: color.white,
    },
    tableRow: {
        fontSize: 14,
        color: color.tableText,
        padding: "12px 6px",
        borderBottom: `1px solid ${color.tableBorder}`,
    },
    redBar: {
        borderLeft: `4px solid ${color.mediumRed}`,
    },
    blueBar: {
        borderLeft: `4px solid ${color.borderBackgroundGray}`,
    },
    noResults: {
        backgroundColor: color.white,
        color: color.darkGray,
        padding: 20,
        marginBottom: 40,
    },
    right: {
        float: "right",
        position: "relative",
        top: -38,
        right: 20,
        fontSize: 14,
        color: color.tableText,
    },
    addButton: {
        float: "right",
        cursor: "pointer",
        padding: 5,
        marginTop: 13,
        marginRight: 24,
        border: `1px solid ${color.darkGray7}`,
        borderRadius: 4,
    },
    modalContent: {
        width: 386,
    },
    modalInstructions: {
        color: color.darkestGray,
        fontSize: 16,
        marginBottom: 8,
    },
    modalButtonContainer: {
        marginTop: 24,
        display: "flex",
        justifyContent: "space-between",
    },
    modalButton: {
        width: 150,
    },
    icon: {
        cursor: "pointer",
    },
    loadingSpinner: {
        paddingTop: 48,
        paddingBottom: 48,
    },
});

const strings = {
    generalInformation: "General Information",
    salesAndService: "Sales & Service",
    details: "Details",
    isVirgin: "Virgin group",
    isTest: "Test group",
    groupNameLabel: "Group Name",
    groupNamePlaceholder: "Group Name",
    groupContactLabel: "Group Contact",
    addressLabel: "Address",
    contactNamePlaceholder: "Group Contact Name (First, Last)",
    phonePlaceholder: "Group Contact Phone #",
    statePlaceholder: "State",
    streetAddressPlaceholder: "Street Address",
    cityPlaceholder: "City",
    zipPlaceholder: "Zip",
    sicPlaceholder: "SIC",
    sicLabel: "SIC",
    segmentSizeLabel: "Segment Size",
    segmentSizePlaceholder: "Segment Size",
    payrollCycleLabel: "Pay Period",
    payrollCyclePlaceholder: "Pay Period Frequency",
    brokerLabel: "Broker",
    salesRepresentativeLabel: "Coded Sales Representative",
    specialSalesRepresentativeLabel: "Special Sales Representative",
    renewalRepresentativeLabel: "Renewal Representative",
    salesRepresentativePlaceholder: "Select Coded Sales Representative",
    specialSalesRepresentativePlaceholder: "Select Special Sales Representative",
    renewalRepresentativePlaceholder: "Select Renewal Representative",
    brokerNamePlaceholder: "Enter Broker Name",
    medicalRenewalDateLabel: "Renewal Date",
    medicalRenewalDatePlaceholder: "Enter Renewal Date",
    medicalEffectiveDateLabel: "Sold Plan Effective Date",
    medicalEffectiveDatePlaceholder: "Enter Sold Plan Effective Date",
    existingClientLabel: "Existing Client",
    cobraStatusLabel: "COBRA Status",
    medicalLabel: "Medical Carrier",
    dentalLabel: "Dental Carrier",
    visionLabel: "Vision Carrier",
    lifeLabel: "Life Carrier",
    quotesTabLabel: "Quotes",
    generalInfoTabLabel: "General Info",
    censusTabLabel: "Census",
    notesTabLabel: "Notes",
    groupHistoryTabLabel: "Group History",
    censusInformationText: "Census Information",
    quotesByYearText: "Quotes by year (Eff Date): ",
    medicalPriorCarrierPlaceholder: "Medical Carrier",
    dentalPriorCarrierPlaceholder: "Dental Carrier",
    visionPriorCarrierPlaceholder: "Vision Carrier",
    lifePriorCarrierPlaceholder: "Life Carrier",
    isExistingClientPlaceholder: "Existing Client",
    isCobraPlaceholder: "COBRA Status",
    alias: "Group Aliases",
    currentMedicalPlans: "Current Medical Plan(s)",
};

const inputNames = {
    isVirgin: "isVirgin",
    isTest: "isTest",
    name: "name",
    contactName: "contactName",
    phone: "phone",
    stateId: "stateId",
    street1: "street1",
    street2: "street2",
    city: "city",
    postalCode: "postalCode",
    sicCodeId: "sicCodeId",
    segmentSize: "segmentSize",
    salesRepresentativeId: "salesRepresentativeId",
    specialSalesRepresentativeId: "specialSalesRepresentativeId",
    renewalRepresentativeId: "renewalRepresentativeId",
    payrollCycleId: "payrollCycleId",
    brokerId: "brokerId",
    medicalRenewalDate: "medicalRenewalDate",
    medicalEffectiveDate: "medicalEffectiveDate",
    medicalPriorCarrier: "medicalPriorCarrier",
    dentalPriorCarrier: "dentalPriorCarrier",
    visionPriorCarrier: "visionPriorCarrier",
    lifePriorCarrier: "lifePriorCarrier",
    isExistingClient: "isExistingClient",
    isCobra: "isCobra",
    note: "note",
    alias: "alias",
};

const yesNoOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
];

export class GroupDetailScreen extends Component {
    static propTypes = {
        history: texchangePropTypes.history.isRequired,
        match: texchangePropTypes.match.isRequired,
        me: texchangePropTypes.me.isRequired,
    };

    state = {
        inputs: {
            [inputNames.name]: "",
            [inputNames.isVirgin]: false,
            [inputNames.isTest]: false,
            [inputNames.contactName]: "",
            [inputNames.phone]: "",
            [inputNames.street1]: "",
            [inputNames.street2]: "",
            [inputNames.city]: "",
            [inputNames.stateId]: "",
            [inputNames.postalCode]: "",
            [inputNames.sicCodeId]: "",
            [inputNames.segmentSize]: "",
            [inputNames.salesRepresentativeId]: null,
            [inputNames.specialSalesRepresentativeId]: null,
            [inputNames.renewalRepresentativeId]: null,
            [inputNames.payrollCycleId]: "",
            [inputNames.brokerId]: null,
            [inputNames.medicalRenewalDate]: null,
            [inputNames.medicalEffectiveDate]: null,
            [inputNames.medicalPriorCarrier]: "",
            [inputNames.dentalPriorCarrier]: "",
            [inputNames.visionPriorCarrier]: "",
            [inputNames.lifePriorCarrier]: "",
            [inputNames.isExistingClient]: false,
            [inputNames.isCobra]: false,
            [inputNames.note]: "",
            [inputNames.alias]: "",
        },
        currentTab: "",
        employer: {},
        states: [],
        users: [],
        payrollCycles: [],
        brokers: [],
        employerLoaded: false,
        stateText: "",
        broker: "",
        salesRepresentative: "",
        specialSalesRepresentative: "",
        renewalRepresentative: "",
        errors: [],
        years: [],
        selectedYear: "",
        columnLabels: ["Quote", "Eff. Date", "Status", "Quoted on", "Quoted By", "Sales Rep", "Broker", "Latest Proposal", "# of Props"],
        columnValues: ["quoteNumber", "effectiveDateString", "quoteStatus", "createDateString", "createdBy", "salesRepName", "brokerName", "latestProposal", "proposalCount"],
        quotes: [],
        notes: [],
    };

    componentDidMount() {
        this._mounted = true;

        const employerId = this.props.match.params.id;

        employerApi.getById(employerId)
            .then(response => {
                this._employerLoaded(response);
                Promise.all([dataApi.getStates(), dataApi.getPayrollCycles(), dataApi.getUsers(), employerApi.getNotes(employerId)])
                    .then(responses => {
                        if (responses && responses[0] && responses[1] && responses[2] && responses[3] && this._mounted) {
                            this.setState({
                                states: responses[0],
                                payrollCycles: responses[1],
                                users: responses[2],
                                notes: responses[3]
                            });
                        }
                    })
                    .catch(err => logger.log(err))
            })

        //years...
        let years = [];
        let dt = new Date();
        years.push((dt.getFullYear() + 1).toString());
        years.push(dt.getFullYear().toString());
        years.push((dt.getFullYear() - 1).toString());
        this.setState({
            years: years,
            selectedYear: dt.getFullYear().toString(),
        })
    }

    _getEmployer = id => {
        this.setState({ loading: true });

        employerApi.getById(id)
            .then(response => {
                this._employerLoaded(response);
            });
    }

    _employerLoaded = employer => {
        let { employerAddress } = employer;

        if (!employerAddress) {
            employerAddress = {};
            employerAddress.street1 = "";
            employerAddress.street2 = "";
            employerAddress.city = "";
            employerAddress.stateId = null;
            employerAddress.postalCode = "";
        }

        if (employer.salesRepresentativeId) {
            const user = this.state.users.find(p => p.id === parseInt(employer.salesRepresentativeId));
            if (user) {
                this.setState({ salesRepresentative: user.fullName });
            }
        }

        if (employer.specialSalesRepresentativeId) {
            const user = this.state.users.find(p => p.id === parseInt(employer.specialSalesRepresentativeId));
            if (user) {
                this.setState({ specialSalesRepresentative: user.fullName });
            }
        }

        if (employer.renewalRepresentativeId) {
            const user = this.state.users.find(p => p.id === parseInt(employer.renewalRepresentativeId));
            if (user) {
                this.setState({ renewalRepresentative: user.fullName });
            }
        }

        employer.employerAddress = employerAddress;

        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.name]: employer.name,
                    [inputNames.isVirgin]: employer.isVirgin,
                    [inputNames.isTest]: employer.isTest,
                    [inputNames.contactName]: employer.contactName ? employer.contactName : "",
                    [inputNames.phone]: employer.phone ? employer.phone : "",
                    [inputNames.street1]: employerAddress.street1 ? employerAddress.street1 : "",
                    [inputNames.street2]: employerAddress.street2 ? employerAddress.street2 : "",
                    [inputNames.city]: employerAddress.city ? employerAddress.city : "",
                    [inputNames.stateId]: employerAddress.stateId ? employerAddress.stateId : null,
                    [inputNames.postalCode]: employerAddress.postalCode ? employerAddress.postalCode : "",
                    [inputNames.sicCodeId]: employer.sicCodeId ? employer.sicCodeId : null,
                    [inputNames.segmentSize]: employer.segmentSize ? employer.segmentSize : "",
                    [inputNames.salesRepresentativeId]: employer.salesRepresentativeId ? employer.salesRepresentativeId : null,
                    [inputNames.specialSalesRepresentativeId]: employer.specialSalesRepresentativeId ? employer.specialSalesRepresentativeId : null,
                    [inputNames.renewalRepresentativeId]: employer.renewalRepresentativeId ? employer.renewalRepresentativeId : null,
                    [inputNames.payrollCycleId]: employer.payrollCycleId ? employer.payrollCycleId : null,
                    [inputNames.brokerId]: employer.brokerId ? employer.brokerId : null,
                    [inputNames.medicalRenewalDate]: employer.medicalRenewalDate ? new Date(employer.medicalRenewalDate) : null,
                    [inputNames.medicalEffectiveDate]: employer.medicalEffectiveDate ? new Date(employer.medicalEffectiveDate) : null,
                    [inputNames.medicalPriorCarrier]: employer.medicalPriorCarrier ? employer.medicalPriorCarrier : "",
                    [inputNames.dentalPriorCarrier]: employer.dentalPriorCarrier ? employer.dentalPriorCarrier : "",
                    [inputNames.visionPriorCarrier]: employer.visionPriorCarrier ? employer.visionPriorCarrier : "",
                    [inputNames.lifePriorCarrier]: employer.lifePriorCarrier ? employer.lifePriorCarrier : "",
                    [inputNames.isExistingClient]: employer.isExistingClient,
                    [inputNames.isCobra]: employer.isCobra,
                    [inputNames.alias]: employer.alias,
                },
                stateText: employerAddress.state,
                employer,
                quotes: employer.quotes,
                employerLoaded: true
            };
        });
    };

    _handleTextChange = e => {
        const { name } = e.target;
        let { value } = e.target;

        if (name === inputNames.phone) {
            value = util.maskPhoneNumber(value);
        } else if (name === inputNames.postalCode) {
            value = value.replace(/[^0-9]+/g, "");
            //maxlength of 5
            if (value && value.length === 6) {
                return;
            }
        }
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
                errors,
            };
        });
    };

    _handleSegmentSizeChange = value => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.segmentSize];

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.segmentSize]: value,
                },
                errors,
            };
        });
    };

    _handlePayrollCycleChange = (value, index) => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.payrollCycleId];

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.payrollCycleId]: value,
                },
                payrollCycle: this.state.payrollCycles[index].name,
                errors,
            };
        });
    };

    _handleStateChange = (value, index) => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.stateId];

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.stateId]: value,
                },
                stateText: this.state.states[index].name,
                errors,
            };
        });
    };

    _handleSalesRepresentativeChange = value => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.salesRepresentativeId];
            const user = this.state.users.find(p => p.id === parseInt(value));

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.salesRepresentativeId]: value,
                },
                salesRepresentative: user.fullName,
                errors,
            };
        });
    };

    _handleSpecialSalesRepresentativeChange = value => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.specialSalesRepresentativeId];
            const user = this.state.users.find(p => p.id === parseInt(value));

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.specialSalesRepresentativeId]: value,
                },
                specialSalesRepresentative: user.fullName,
                errors,
            };
        });
    };

    _handleRenewalRepresentativeChange = value => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.renewalRepresentativeId];

            const user = this.state.users.find(p => p.id === parseInt(value));

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.renewalRepresentativeId]: value,
                },
                renewalRepresentative: user.fullName,
                errors,
            };
        });
    };

    _brokerSelected = item => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.brokerId];

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.brokerId]: item.value,
                },
                broker: item.label,
                errors,
            };
        });
    };

    _goToBroker = id => {
        if (id > 0) {
            window.location.href = "/brokers/detail/" + id;
        }
    };

    _handleDateChange = (value, name) => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
                errors,
            };
        });
    };

    _sicCodeSelected = item => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.sicCodeId];

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.sicCodeId]: item.value,
                },
                sicCode: item.label,
                errors,
            };
        });
    }

    _handleInputChange = e => {
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: val,
                },
                errors,
            };
        }, () => {
            if (type === "checkbox") {
                this._handleOnSave();
            }
        });
    };

    _handleYesNoChanged = selectedItem => {
        const { name, value } = selectedItem;

        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
            };
        });
    };

    _changeYear = item => {
        const { employer } = this.state;
        const quotes = employer.quotes && employer.quotes.filter(x => x.effectiveDateString.indexOf(item.value.substr(-2)) > -1);

        this.setState({
            selectedYear: item.value,
            quotes: quotes,
        });
    }

    _handleRowClick = id => {
        this.props.history.push(routeHelpers.quoteDetail(id));
    };

    _sortQuotes = (page, col, dir) => {
        const { quotes } = this.state;
        if (dir === "asc") {
            this.setState({
                quotes: sortBy(quotes, col),
            });
        }
        else {
            this.setState({
                quotes: sortBy(quotes, col).reverse(),
            });
        }
    };

    _renderQuotes = () => {
        const { quotes } = this.state;

        return (
            <div className={css(styles.tabContent)}>
                <div className={css(styles.row)}>
                    <div className={css(styles.right)}>{quotes ? parseInt(quotes.length).toLocaleString() : "0"} quotes</div>
                    {quotes && quotes.length > 0 ? (
                        <Infinite
                            results={quotes}
                            length={quotes.length}
                            columnLabels={this.state.columnLabels}
                            columnValues={this.state.columnValues}
                            showChevron={true}
                            showMore={false}
                            onClick={this._handleRowClick}
                            onScroll={this._sortQuotes} />
                    ) : (
                        <div className={css(styles.noResults)}>{this.state.loading ? "Loading " : "No Matching "} quotes</div>
                    )}
                </div>
            </div>
        );
    };

    _renderCensus = () => {
        const { employer } = this.state;
        return (
            <div className={css(styles.tabContent)}>
                <div className={css(styles.row)}>
                    <table className={css(styles.table)}>
                        <thead>
                            <tr>
                                <td className={css(styles.tableHeader)}>No.</td>
                                <td className={css(styles.tableHeader)}>Rel</td>
                                <td className={css(styles.tableHeader)}>Name</td>
                                <td className={css(styles.tableHeader)}>DOB</td>
                                <td className={css(styles.tableHeader)}>Age</td>
                                <td className={css(styles.tableHeader)}>Gen</td>
                                <td className={css(styles.tableHeader)}>Med</td>
                                <td className={css(styles.tableHeader)}>Den</td>
                                <td className={css(styles.tableHeader)}>Vis</td>
                                <td className={css(styles.tableHeader)}>Lif</td>
                                <td className={css(styles.tableHeader)}>H Zip</td>
                                <td className={css(styles.tableHeader)}>W Zip</td>
                                <td className={css(styles.tableHeader)}>Title</td>
                                <td className={css(styles.tableHeader)}>Salary</td>
                                <td className={css(styles.tableHeader)}>DOH</td>
                                <td className={css(styles.tableHeader)}>Email</td>
                                <td className={css(styles.tableHeader)}>Tob</td>
                                <td className={css(styles.tableHeader)}>COB</td>
                            </tr>
                        </thead>
                        <tbody>
                            {employer.census ? (employer.census.map(c => {
                                return (
                                    <tr key={c.id} className={c.relationship === "EE" ? "" : "sub"}>
                                        <td className={css(styles.tableRow, (c.relationship === "EE" ? styles.redBar : styles.blueBar))}>{c.sequenceNumber}</td>
                                        <td className={css(styles.tableRow)}>{c.relationship}</td>
                                        <td className={css(styles.tableRow)}>{c.fullName}</td>
                                        <td className={css(styles.tableRow)}>{c.birthDateString}</td>
                                        <td className={css(styles.tableRow)}>{c.age}</td>
                                        <td className={css(styles.tableRow)}>{c.gender}</td>
                                        <td className={css(styles.tableRow)}>{c.medicalCoverage}</td>
                                        <td className={css(styles.tableRow)}>{c.dentalCoverage}</td>
                                        <td className={css(styles.tableRow)}>{c.visionCoverage}</td>
                                        <td className={css(styles.tableRow)}>{c.lifeCoverage}</td>
                                        <td className={css(styles.tableRow)}>{c.homePostalCode}</td>
                                        <td className={css(styles.tableRow)}>{c.workPostalCode}</td>
                                        <td className={css(styles.tableRow)}>{c.occupation}</td>
                                        <td className={css(styles.tableRow)}>{c.salary}</td>
                                        <td className={css(styles.tableRow)}>{c.hireDateString}</td>
                                        <td className={css(styles.tableRow)}>{c.email}</td>
                                        <td className={css(styles.tableRow)}>{c.isTobacco ? "Yes" : "No"}</td>
                                        <td className={css(styles.tableRow)}>{c.onCobra ? "Yes" : "No"}</td>
                                    </tr>
                                )
                            })) : null}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    _handleOnSave = () => {
        const employerSaved = new Promise(resolve => {
            const { employer } = this.state;

            Object.keys(inputNames).forEach(key => {
                employer[key] = this.state.inputs[key];
            });

            employerApi.saveEmployer(employer)
                .then((response => {
                    this._employerLoaded(response);
                    resolve(true);
                }))
                .catch(err => {
                    const { response } = err;
                    if (response && response.status === 400 && response.data.errors) {
                        const errors = [];
                        const validationErrors = response.data.errors;
                        let messages = "";
                        Object.keys(validationErrors).forEach(key => {
                            errors[key] = "required";
                            messages += validationErrors[key] + "\n";
                        });

                        this.setState({
                            errors,
                        });
                        toast.error(messages, { toastId: 1 });
                        resolve(false);
                    }
                });
        });

        return employerSaved;
    };

    _handleOnCancel = () => {
        const { employer } = this.state;

        this._employerLoaded(employer);
    };

    _renderGeneralInfo = () => {
        const { employer } = this.state;
        const { me } = this.props;

        return (
            <div className={css(styles.generalInfo)}>
                <ToastContainer position="top-center" autoClose={2500} />
                <Container>
                    <Row>
                        <Column large={4}>
                            <div className={css(styles.leftColumn)}>
                                <Headline aStyles={styles.title}>{strings.generalInformation}</Headline>
                                <div>
                                    <div className={css(styles.checkboxContainer)}>
                                        <label className={css(styles.checkLabel)}>
                                            <Checkbox
                                                aStyles={styles.check}
                                                checked={this.state.inputs[inputNames.isVirgin]}
                                                name={inputNames.isVirgin}
                                                onChange={this._handleInputChange}
                                                disabled={me.isBroker || employer.deleted}
                                            />
                                            <span className={css(styles.labelText)}>{strings.isVirgin}</span>
                                        </label>
                                        <label className={css(styles.checkLabel)}>
                                            <Checkbox
                                                aStyles={styles.check}
                                                checked={this.state.inputs[inputNames.isTest]}
                                                name={inputNames.isTest}
                                                onChange={this._handleInputChange}
                                                disabled={me.isBroker || employer.deleted}
                                            />
                                            <span className={css(styles.labelText)}>{strings.isTest}</span>
                                        </label>
                                    </div>
                                    <InlineEdit
                                        headerLabel={strings.groupNameLabel}
                                        onSave={this._handleOnSave}
                                        onCancel={this._handleOnCancel}
                                        editable={!me.isBroker && !employer.deleted}
                                    >
                                        <div edit>
                                            <TextInput
                                                name={inputNames.name}
                                                onChange={this._handleTextChange}
                                                placeholder={strings.groupNamePlaceholder}
                                                required={true}
                                                validationMessage={this.state.errors[inputNames.name]}
                                                value={this.state.inputs[inputNames.name]}
                                            />
                                        </div>
                                        <div display>
                                            <div className={css(styles.displayRow)}>
                                                {this.state.inputs[inputNames.name]}
                                            </div>
                                        </div>
                                    </InlineEdit>
                                    <InlineEdit
                                        headerLabel={strings.groupContactLabel}
                                        onSave={this._handleOnSave}
                                        onCancel={this._handleOnCancel}
                                        editable={!me.isBroker && !employer.deleted}
                                    >
                                        <div edit>
                                            <TextInput
                                                name={inputNames.contactName}
                                                onChange={this._handleTextChange}
                                                placeholder={strings.contactNamePlaceholder}
                                                value={this.state.inputs[inputNames.contactName]}
                                            />
                                            <TextInput
                                                name={inputNames.phone}
                                                onChange={this._handleTextChange}
                                                placeholder={strings.phonePlaceholder}
                                                value={this.state.inputs[inputNames.phone]}
                                            />
                                        </div>
                                        <div display>
                                            <div className={css(styles.displayRow)}>
                                                {this.state.inputs[inputNames.contactName]}
                                                &nbsp;/&nbsp;
                                                {this.state.inputs[inputNames.phone]}
                                            </div>
                                        </div>
                                    </InlineEdit>
                                    <InlineEdit
                                        headerLabel={strings.addressLabel}
                                        onSave={this._handleOnSave}
                                        onCancel={this._handleOnCancel}
                                        editable={!me.isBroker && !employer.deleted}
                                    >
                                        <div edit>
                                            <TextInput
                                                name={inputNames.street1}
                                                onChange={this._handleTextChange}
                                                placeholder={strings.streetAddressPlaceholder}
                                                required={true}
                                                validationMessage={this.state.errors[inputNames.street1]}
                                                value={this.state.inputs[inputNames.street1]}
                                            />
                                            <TextInput
                                                name={inputNames.street2}
                                                onChange={this._handleTextChange}
                                                placeholder={strings.streetAddressPlaceholder}
                                                value={this.state.inputs[inputNames.street2]}
                                            />
                                            <TextInput
                                                name={inputNames.city}
                                                onChange={this._handleTextChange}
                                                placeholder={strings.cityPlaceholder}
                                                required={true}
                                                validationMessage={this.state.errors[inputNames.city]}
                                                value={this.state.inputs[inputNames.city]}
                                            />
                                            <Dropdown
                                                options={Object.keys(this.state.states).map(id => ({
                                                    label: this.state.states[id].longName,
                                                    value: this.state.states[id].id,
                                                }))}
                                                onChange={this._handleStateChange}
                                                placeholder={strings.statePlaceholder}
                                                value={this.state.inputs[inputNames.stateId]}
                                                validationMessage={this.state.errors[inputNames.stateId]}
                                                hideLabel={true}
                                            />
                                            <TextInput
                                                name={inputNames.postalCode}
                                                onChange={this._handleTextChange}
                                                placeholder={strings.zipPlaceholder}
                                                required={true}
                                                validationMessage={this.state.errors[inputNames.postalCode]}
                                                value={this.state.inputs[inputNames.postalCode]}
                                            />
                                        </div>
                                        <div display>
                                            <div className={css(styles.displayRow)}>
                                                {this.state.inputs[inputNames.street1]}&nbsp;{this.state.inputs[inputNames.street2]}
                                            </div>
                                            <div className={css(styles.displayRow)}>
                                                {this.state.inputs[inputNames.city]},&nbsp;{this.state.stateText}&nbsp;{this.state.inputs[inputNames.postalCode]}
                                            </div>
                                        </div>
                                    </InlineEdit>
                                    <InlineEdit
                                        headerLabel={strings.sicLabel}
                                        onSave={this._handleOnSave}
                                        onCancel={this._handleOnCancel}
                                        editable={!me.isBroker && !employer.deleted}
                                    >
                                        <div edit>
                                            <SearchInput
                                                search={searchApi.searchSICCodes}
                                                onItemSelected={this._sicCodeSelected}
                                                placeholder={strings.sicPlaceholder}
                                                validationMessage={this.state.errors[inputNames.sicCodeId]}
                                                required={true}
                                                value={employer.sicCode}
                                                type={searchInputTypes.textNoIcon}
                                            />
                                        </div>
                                        <div display>
                                            <div className={css(styles.displayRow)}>
                                                {employer.sicCode}
                                            </div>
                                        </div>
                                    </InlineEdit>
                                    <InlineEdit
                                        headerLabel={strings.segmentSizeLabel}
                                        onSave={this._handleOnSave}
                                        onCancel={this._handleOnCancel}
                                        editable={!me.isBroker && !employer.deleted}
                                    >
                                        <div edit>
                                            <Dropdown
                                                options={segmentSizes.map(seg => ({
                                                    label: seg.value,
                                                    value: seg.value,
                                                }))}
                                                onChange={this._handleSegmentSizeChange}
                                                placeholder={strings.segmentSizePlaceholder}
                                                value={this.state.inputs[inputNames.segmentSize]}
                                                validationMessage={this.state.errors[inputNames.segmentSize]}
                                            />
                                        </div>
                                        <div display>
                                            <div className={css(styles.displayRow)}>
                                                {this.state.inputs[inputNames.segmentSize]}
                                            </div>
                                        </div>
                                    </InlineEdit>
                                    <InlineEdit
                                        headerLabel={strings.payrollCycleLabel}
                                        onSave={this._handleOnSave}
                                        onCancel={this._handleOnCancel}
                                        editable={!employer.deleted}
                                    >
                                        <div edit>
                                            <div className={css(styles.dropdownEdit)}>
                                                <Dropdown
                                                    options={Object.keys(this.state.payrollCycles).map(id => ({
                                                        label: this.state.payrollCycles[id].name,
                                                        value: this.state.payrollCycles[id].id,
                                                    }))}
                                                    onChange={this._handlePayrollCycleChange}
                                                    placeholder={strings.payrollCyclePlaceholder}
                                                    value={this.state.inputs[inputNames.payrollCycleId]}
                                                    validationMessage={this.state.errors[inputNames.payrollCycleId]}
                                                />
                                            </div>
                                        </div>
                                        <div display>
                                            <div className={css(styles.displayRow)}>
                                                {employer.payrollCycle}
                                            </div>
                                        </div>
                                    </InlineEdit>
                                    <div className={css(styles.container)}>
                                        <div className={css(styles.headerLabel)}>
                                            {strings.alias}
                                        </div>
                                        <div className={css(styles.displayContainer)}>
                                            {this.state.inputs[inputNames.alias]}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Column>
                        <Column large={4}>
                            <div className={css(styles.centerColumn)}>
                                <Headline aStyles={styles.title}>{strings.salesAndService}</Headline>
                                <InlineEdit
                                    headerLabel={strings.brokerLabel}
                                    onSave={this._handleOnSave}
                                    onCancel={this._handleOnCancel}
                                    editable={!me.isBroker && !employer.deleted}
                                >
                                    <div edit>
                                        <SearchInput
                                            search={searchApi.searchBrokers}
                                            onItemSelected={this._brokerSelected}
                                            placeholder={strings.brokerNamePlaceholder}
                                            aStyles={styles.brokerSearch}
                                            type={searchInputTypes.text}
                                            value={employer.brokerName}
                                            validationMessage={this.state.errors[inputNames.brokerId]}
                                            hideLabel={true}
                                        />
                                    </div>
                                    <div display>
                                        <div className={css(styles.displayRow, styles.link)} onClick={() => this._goToBroker(employer.broker ? employer.broker.id : 0)}>
                                            {employer.brokerName}
                                        </div>
                                        {employer.broker &&
                                            <React.Fragment>
                                                <div className={css(styles.displayRow)}>
                                                    Phone: {employer.broker.phone}
                                                </div>
                                                <div className={css(styles.displayRow)}>
                                                    Email: {employer.broker.emailAddress}
                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>
                                </InlineEdit>
                                <InlineEdit
                                    headerLabel={strings.salesRepresentativeLabel}
                                    onSave={this._handleOnSave}
                                    onCancel={this._handleOnCancel}
                                    editable={!me.isBroker && !employer.deleted}
                                >
                                    <div edit>
                                        <div className={css(styles.dropdownEdit)}>
                                            <Dropdown
                                                options={Object.keys(this.state.users).map(id => ({
                                                    label: this.state.users[id].lastName + ", " + this.state.users[id].firstName,
                                                    value: this.state.users[id].id,
                                                }))}
                                                onChange={this._handleSalesRepresentativeChange}
                                                placeholder={strings.salesRepresentativePlaceholder}
                                                value={this.state.inputs[inputNames.salesRepresentativeId]}
                                                hideLabel={true}
                                                validationMessage={this.state.errors[inputNames.salesRepresentativeId]}
                                            />
                                        </div>
                                    </div>
                                    <div display>
                                        <div className={css(styles.displayRow)}>
                                            {this.state.salesRepresentative}
                                        </div>
                                        {employer.salesRepresentative &&
                                            <div className={css(styles.displayRow)}>
                                                Email: {employer.salesRepresentative.email}
                                            </div>
                                        }
                                    </div>
                                </InlineEdit>
                                <InlineEdit
                                    headerLabel={strings.specialSalesRepresentativeLabel}
                                    onSave={this._handleOnSave}
                                    onCancel={this._handleOnCancel}
                                    editable={!me.isBroker && !employer.deleted}
                                >
                                    <div edit>
                                        <div className={css(styles.dropdownEdit)}>
                                            <Dropdown
                                                options={Object.keys(this.state.users).map(id => ({
                                                    label: this.state.users[id].lastName + ", " + this.state.users[id].firstName,
                                                    value: this.state.users[id].id,
                                                }))}
                                                onChange={this._handleSpecialSalesRepresentativeChange}
                                                placeholder={strings.specialSalesRepresentativePlaceholder}
                                                value={this.state.inputs[inputNames.specialSalesRepresentativeId]}
                                                hideLabel={true}
                                                validationMessage={this.state.errors[inputNames.specialSalesRepresentativeId]}
                                            />
                                        </div>
                                    </div>
                                    <div display>
                                        <div className={css(styles.displayRow)}>
                                            {this.state.specialSalesRepresentative}
                                        </div>
                                        {employer.specialSalesRepresentative &&
                                            <div className={css(styles.displayRow)}>
                                                Email: {employer.specialSalesRepresentative.email}
                                            </div>
                                        }
                                    </div>
                                </InlineEdit>
                                <InlineEdit
                                    headerLabel={strings.renewalRepresentativeLabel}
                                    onSave={this._handleOnSave}
                                    onCancel={this._handleOnCancel}
                                    editable={!me.isBroker && !employer.deleted}
                                >
                                    <div edit>
                                        <div className={css(styles.dropdownEdit)}>
                                            <Dropdown
                                                options={Object.keys(this.state.users).map(id => ({
                                                    label: this.state.users[id].lastName + ", " + this.state.users[id].firstName,
                                                    value: this.state.users[id].id,
                                                }))}
                                                onChange={this._handleRenewalRepresentativeChange}
                                                placeholder={strings.renewalRepresentativePlaceholder}
                                                value={this.state.inputs[inputNames.renewalRepresentativeId]}
                                                hideLabel={true}
                                                validationMessage={this.state.errors[inputNames.renewalRepresentativeId]}
                                            />
                                        </div>
                                    </div>
                                    <div display>
                                        <div className={css(styles.displayRow)}>
                                            {this.state.renewalRepresentative}
                                        </div>
                                        {employer.renewalRepresentative &&
                                            <div className={css(styles.displayRow)}>
                                                Email: {employer.renewalRepresentative.email}
                                            </div>
                                        }
                                    </div>
                                </InlineEdit>
                            </div>
                        </Column>
                        <Column large={4}>
                            <div className={css(styles.rightColumn)}>
                                <Headline aStyles={styles.title}>{strings.details}</Headline>
                                {employer.currentMedicalPlans &&
                                    <div className={css(styles.containerScroll)}>
                                        <div className={css(styles.headerLabel)}>
                                            {strings.currentMedicalPlans}
                                        </div>
                                        <div className={css(styles.displayContainer)}>
                                            {employer.currentMedicalPlans}
                                        </div>
                                    </div>
                                }
                                <InlineEdit
                                    headerLabel={strings.medicalRenewalDateLabel}
                                    onSave={this._handleOnSave}
                                    onCancel={this._handleOnCancel}
                                    editable={!me.isBroker && !employer.deleted}
                                >
                                    <div edit>
                                        <DatePicker
                                            name={inputNames.medicalRenewalDate}
                                            onChange={this._handleDateChange}
                                            placeholder={strings.medicalRenewalDatePlaceholder}
                                            value={this.state.inputs[inputNames.medicalRenewalDate]}
                                        />
                                    </div>
                                    <div display>
                                        <div className={css(styles.displayRow)}>
                                            {employer.medicalRenewalDate ? format(employer.medicalRenewalDate, "MM/DD/YYYY") : null}
                                        </div>
                                    </div>
                                </InlineEdit>
                                <InlineEdit
                                    headerLabel={strings.medicalEffectiveDateLabel}
                                    onSave={this._handleOnSave}
                                    onCancel={this._handleOnCancel}
                                    editable={!me.isBroker && !employer.deleted}
                                >
                                    <div edit>
                                        <DatePicker
                                            name={inputNames.medicalEffectiveDate}
                                            onChange={this._handleDateChange}
                                            placeholder={strings.medicalEffectiveDatePlaceholder}
                                            value={this.state.inputs[inputNames.medicalEffectiveDate]}
                                        />
                                    </div>
                                    <div display>
                                        <div className={css(styles.displayRow)}>
                                            {employer.medicalEffectiveDate ? format(employer.medicalEffectiveDate, "MM/DD/YYYY") : null}
                                        </div>
                                    </div>
                                </InlineEdit>
                                <InlineEdit
                                    headerLabel={strings.existingClientLabel}
                                    onSave={this._handleOnSave}
                                    onCancel={this._handleOnCancel}
                                    editable={!me.isBroker && !employer.deleted}
                                >
                                    <div edit>
                                        <Dropdown
                                            name={inputNames.isExistingClient}
                                            options={yesNoOptions}
                                            onChange={this._handleYesNoChanged}
                                            placeholder={strings.isExistingClientPlaceholder}
                                            value={this.state.inputs[inputNames.isExistingClient]}
                                        />
                                    </div>
                                    <div display>
                                        <div className={css(styles.displayRow)}>
                                            {employer.isExistingClient ? "Yes" : "No"}
                                        </div>
                                    </div>
                                </InlineEdit>
                                <InlineEdit
                                    headerLabel={strings.cobraStatusLabel}
                                    onSave={this._handleOnSave}
                                    onCancel={this._handleOnCancel}
                                    editable={!me.isBroker && !employer.deleted}
                                >
                                    <div edit>
                                        <Dropdown
                                            name={inputNames.isCobra}
                                            options={yesNoOptions}
                                            onChange={this._handleYesNoChanged}
                                            placeholder={strings.isCobraPlaceholder}
                                            value={this.state.inputs[inputNames.isCobra]}
                                        />
                                    </div>
                                    <div display>
                                        <div className={css(styles.displayRow)}>
                                            {employer.isCobra ? "Yes" : "No"}
                                        </div>
                                    </div>
                                </InlineEdit>
                                <InlineEdit
                                    headerLabel={strings.medicalLabel}
                                    onSave={this._handleOnSave}
                                    onCancel={this._handleOnCancel}
                                    editable={!me.isBroker && !employer.deleted}
                                >
                                    <div edit>
                                        <TextInput
                                            name={inputNames.medicalPriorCarrier}
                                            onChange={this._handleTextChange}
                                            placeholder={strings.medicalPriorCarrierPlaceholder}
                                            value={this.state.inputs[inputNames.medicalPriorCarrier]}
                                        />
                                    </div>
                                    <div display>
                                        <div className={css(styles.displayRow)}>
                                            {employer.medicalPriorCarrier}
                                        </div>
                                    </div>
                                </InlineEdit>
                                <InlineEdit
                                    headerLabel={strings.dentalLabel}
                                    onSave={this._handleOnSave}
                                    onCancel={this._handleOnCancel}
                                    editable={!me.isBroker && !employer.deleted}
                                >
                                    <div edit>
                                        <TextInput
                                            name={inputNames.dentalPriorCarrier}
                                            onChange={this._handleTextChange}
                                            placeholder={strings.dentalPriorCarrierPlaceholder}
                                            value={this.state.inputs[inputNames.dentalPriorCarrier]}
                                        />
                                    </div>
                                    <div display>
                                        <div className={css(styles.displayRow)}>
                                            {employer.dentalPriorCarrier}
                                        </div>
                                    </div>
                                </InlineEdit>
                                <InlineEdit
                                    headerLabel={strings.visionLabel}
                                    onSave={this._handleOnSave}
                                    onCancel={this._handleOnCancel}
                                    editable={!me.isBroker && !employer.deleted}
                                >
                                    <div edit>
                                        <TextInput
                                            name={inputNames.visionPriorCarrier}
                                            onChange={this._handleTextChange}
                                            placeholder={strings.visionPriorCarrierPlaceholder}
                                            value={this.state.inputs[inputNames.visionPriorCarrier]}
                                        />
                                    </div>
                                    <div display>
                                        <div className={css(styles.displayRow)}>
                                            {employer.visionPriorCarrier}
                                        </div>
                                    </div>
                                </InlineEdit>
                                <InlineEdit
                                    headerLabel={strings.lifeLabel}
                                    onSave={this._handleOnSave}
                                    onCancel={this._handleOnCancel}
                                    editable={!me.isBroker && !employer.deleted}
                                >
                                    <div edit>
                                        <TextInput
                                            name={inputNames.lifePriorCarrier}
                                            onChange={this._handleTextChange}
                                            placeholder={strings.lifePriorCarrierPlaceholder}
                                            value={this.state.inputs[inputNames.lifePriorCarrier]}
                                        />
                                    </div>
                                    <div display>
                                        <div className={css(styles.displayRow)}>
                                            {employer.lifePriorCarrier}
                                        </div>
                                    </div>
                                </InlineEdit>
                            </div>
                        </Column>
                    </Row>
                </Container>
            </div>
        );
    };

    _addNote = () => {
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.note]: '',
                },
                showNewNoteModal: true,
            };
        });
    };

    _addQuote = () => {
        window.location.href = "/quotes/edit?g=" + this.props.match.params.id;
    };

    _saveNote = () => {
        employerApi.saveNote({
            note: this.state.inputs[inputNames.note],
            employerId: this.props.match.params.id,
        })
            .then(response => {
                this.setState({
                    showNewNoteModal: false,
                    notes: response,
                });
            });
    };

    _handleTabChanged = currentTab => {
        this.setState({ currentTab });
    };

    _renderNotes = () => {
        return (
            <div className={css(styles.tabContent)}>
                <div className={css(styles.row)}>
                    <table className={css(styles.table)}>
                        <thead>
                            <tr>
                                {/*<td className={css(styles.tableHeader)}>Last Updated</td>*/}
                                <td className={css(styles.tableHeader)}>Date Created</td>
                                <td className={css(styles.tableHeader)}>Written By</td>
                                <td className={css(styles.tableHeader)}>Note</td>
                                {/*<td className={css(styles.tableHeader)}>Edit</td>*/}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.notes.map(n => {
                                return (
                                    <tr key={n.id}>
                                        {/*
                                        <td className={css(styles.tableRow)}>
                                            {n.modifyDate &&
                                                <Moment format="MM/DD/YYYY">
                                                    {n.modifyDate}
                                                </Moment>   
                                            }
                                        </td>
                                        */}
                                        <td className={css(styles.tableRow)}>
                                            <Moment format="MM/DD/YYYY">
                                                {n.createDate}
                                            </Moment>
                                        </td>
                                        <td className={css(styles.tableRow)}>{n.writtenBy}</td>
                                        <td className={css(styles.tableRow)}>{n.note}</td>
                                        {/*<
                                        <td className={css(styles.tableRow)}>
                                            <img src={editIcon} alt="edit" className={css(styles.icon)} />
                                        </td>
                                    */}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    _renderHistory = () => {
        const { employer } = this.state;

        const currentMedicalPlans = [];
        if (employer.currentMedicalPlansFull) {
            const splitPlans = employer.currentMedicalPlansFull.split('|$$|');
            if (splitPlans.length > 0) {
                splitPlans.forEach(plan => {
                    const splitPlan = plan.split('|**|');
                    if (splitPlan.length === 11) {
                        const planMap = {
                            id: splitPlan[0],
                            year: splitPlan[1],
                            carrier: splitPlan[2],
                            displayName: splitPlan[3],
                            deductible: splitPlan[4],
                            coinsurance: splitPlan[5],
                            oop: splitPlan[6],
                            ee: splitPlan[7],
                            es: splitPlan[8],
                            ec: splitPlan[9],
                            ef: splitPlan[10]
                        };
                        currentMedicalPlans.push(planMap);
                    }
                });
            }
        }
        //list
        return (
            <div className={css(styles.tabContent)}>
                <div className={css(styles.row)}>
                    <table className={css(styles.table)}>
                        <thead>
                            <tr>
                                <td className={css(styles.tableHeader)} rowSpan={2}>Year</td>
                                <td className={css(styles.tableHeader)} rowSpan={2}>Carrier</td>
                                <td className={css(styles.tableHeader)} rowSpan={2}>Plan</td>
                                <td className={css(styles.tableHeader)} rowSpan={2}>Ded</td>
                                <td className={css(styles.tableHeader)} rowSpan={2}>Coins</td>
                                <td className={css(styles.tableHeader)} rowSpan={2}>OOP</td>
                                <td className={css(styles.tableHeader)} colSpan={4}>Tier Rates</td>
                            </tr>
                            <tr>
                                <td className={css(styles.tableHeader)}>EE</td>
                                <td className={css(styles.tableHeader)}>ES</td>
                                <td className={css(styles.tableHeader)}>EC</td>
                                <td className={css(styles.tableHeader)}>EF</td>
                            </tr>
                        </thead>
                        <tbody>
                            {currentMedicalPlans && currentMedicalPlans.length > 0 ? currentMedicalPlans.map(plan => {
                                return (
                                    <tr key={plan.id}>
                                        <td className={css(styles.tableRow)}>{plan.year}</td>
                                        <td className={css(styles.tableRow)}>{plan.carrier}</td>
                                        <td className={css(styles.tableRow)}>{plan.displayName}</td>
                                        <td className={css(styles.tableRow)}>{plan.deductible}</td>
                                        <td className={css(styles.tableRow)}>{plan.coinsurance}</td>
                                        <td className={css(styles.tableRow)}>{plan.oop}</td>
                                        <td className={css(styles.tableRow)}>{plan.ee}</td>
                                        <td className={css(styles.tableRow)}>{plan.es}</td>
                                        <td className={css(styles.tableRow)}>{plan.ec}</td>
                                        <td className={css(styles.tableRow)}>{plan.ef}</td>
                                    </tr>
                                )
                            }) : (
                                <tr>
                                    <td className={css(styles.tableRow)} colSpan={10}>No Current Plans Specified.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    render() {
        const { me } = this.props;
        const { employer, years, selectedYear, employerLoaded } = this.state;

        if (!employerLoaded) {
            return (
                <div className={css(styles.content)}>
                    <div className={css(styles.contentSection)}>
                        <Spinner
                            aStyles={styles.loadingSpinner}
                            text="Loading Group..."
                        />
                    </div>
                </div>
            );
        }

        return (
            <div className={css(styles.content)}>
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.titleSection)}>
                        {me.ratingEngineQuotePermission && (this.state.currentTab === "" || this.state.currentTab === "General Info") &&
                            <AddButton
                                aStyles={styles.addButton}
                                onClick={this._addQuote}
                                label="Quote this Group"
                            />
                        }
                        {this.state.currentTab === "Notes" &&
                            <AddButton
                                aStyles={styles.addButton}
                                onClick={this._addNote}
                                label="New Note"
                            />
                        }
                        <PageTitle>Group: {employer.name} {employer.deleted ? "(DELETED)" : ""}</PageTitle>
                    </div>
                </div>
                <div className={css(styles.groupDetails)}>
                    <Tabs history={this.props.history} backUrl={routeHelpers.groups()} tabChanged={this._handleTabChanged}>
                        <div label={strings.generalInfoTabLabel}>
                            {this._renderGeneralInfo()}
                        </div>
                        <div label={strings.quotesTabLabel}>
                            <div className={css(styles.tabContentHeader)}>
                                <div className={css(styles.quotesByYearHeader)}>
                                    <div className={css(styles.quotesByYearText)}>
                                        {strings.quotesByYearText}
                                    </div>
                                    <div className={css(styles.quotesByYearDropdown)}>
                                        <SmallDropdown
                                            aStyles={styles.dropdown}
                                            options={years.map(year => ({
                                                label: year,
                                                value: year
                                            }))}
                                            value={selectedYear}
                                            placeholder={""}
                                            onChange={this._changeYear}
                                            hideLabel={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            {this._renderQuotes()}
                        </div>
                        <div label={strings.groupHistoryTabLabel}>
                            <div className={css(styles.tabContentHeader)}>
                                <div className={css(styles.tabContentHeaderText)}>
                                    {strings.groupHistoryTabLabel}
                                </div>
                            </div>
                            {this._renderHistory()}
                        </div>
                        <div label={strings.censusTabLabel}>
                            <div className={css(styles.tabContentHeader)}>
                                <div className={css(styles.tabContentHeaderText)}>
                                    {strings.censusInformationText}
                                    <span className={css(styles.asOf)}>
                                        (as of {employer.latestCensusDate})
                                    </span>
                                </div>
                            </div>
                            {this._renderCensus()}
                        </div>
                        <div label={strings.notesTabLabel}>
                            <div className={css(styles.tabContentHeader)}>
                                <div className={css(styles.tabContentHeaderText)}>
                                    {strings.notesTabLabel}
                                </div>
                            </div>
                            {this._renderNotes()}
                        </div>
                    </Tabs>
                </div>
                <Modal
                    show={this.state.showNewNoteModal}
                    header="Add Note"
                >
                    <div className={css(styles.modalContent)}>
                        <div className={css(styles.modalInstructions)}>
                            Type your note here:
                        </div>
                        <TextArea
                            name={inputNames.note}
                            onChange={this._handleTextChange}
                            placeholder="Enter text..."
                            required={true}
                            validationMessage={this.state.errors[inputNames.note]}
                            value={this.state.inputs[inputNames.note]}
                        />
                    </div>
                    <div className={css(styles.modalButtonContainer)}>
                        <ActionButton
                            aStyles={styles.modalButton}
                            label="Cancel"
                            invertColor={true}
                            type="button"
                            onClick={() => this.setState({ showNewNoteModal: false })}
                        />
                        <ActionButton
                            aStyles={styles.modalButton}
                            label="Save"
                            onClick={this._saveNote}
                            type="button"
                            isLoading={this.state.isSaving}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}
const mapDispatchToProps = state => ({
    me: state.session.me,
});

export default connect(mapDispatchToProps)(GroupDetailScreen);
