import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import sortBy from "lodash.sortby";
import Modal from "../Modal";
import Dropdown from "../inputs/Dropdown";
import NestedDropdown from "../inputs/NestedDropdown";
import TextInput from "../inputs/TextInput";
import Toggle from "../inputs/Toggle";
import ActionButton from "../ActionButton";
import * as texchangePropTypes from "../../texchangePropTypes";
import coverageTypes from "../../constants/coverageTypes";
import * as color from "../../constants/color";
import * as util from "../../lib/util";
import * as inputSizes from "../../constants/inputSizes";
import format from "date-fns/format";

const styles = StyleSheet.create({
    container: {
        display: "flex",
        flexWrap: "wrap",
        paddingTop: 16,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 60,
    },
    multiPlanDropdownContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
    multiPlanDropdown: {
        marginTop: 0,
        minWidth: 240,
        display: "inline-flex",
    },
    titleContainer: {
        width: "100%",
        borderBottom: `2px solid ${color.mediumRed}`,
        margin: "16px 0",
        padding: "4px 0",
        display: "flex",
        justifyContent: "space-between",
    },
    title: {
        fontSize: 20,
        color: color.darkestGray,
        fontWeight: 800,
    },
    buttonContainer: {
        height: 60,
        margin: "12px auto",
        textAlign: "center",
        verticalAlign: "top",
        width: "100%",
    },
    customPlanOptionContainer: {
        height: 80,
        margin: "12px auto 24px auto",
        textAlign: "center",
    },
    saveButton: {
        minWidth: 200,
        marginLeft: 20,
    },
    toggle: {
        display: "inline-flex",
        verticalAlign: "top",
        marginTop: 16,
        fontSize: 15,
    },
    plansContainer: {
        display: "flex",
        width: "70%",
        flexWrap: "wrap",
        marginRight: 32,
        paddingBottom: 24,
    },
    currentPlansContainer: {
        flexBasis: "100%",
        flex: 1,
        marginRight: 12,
        maxWidth: "50%",
    },
    newPlansContainer: {
        flexBasis: "100%",
        flex: 1,
        marginLeft: 16,
    },
    savedMultiPlanOptionsContainer: {
        flexBasis: "100%",
        flex: 1,
        borderRadius: 4,
        border: `1px solid ${color.darkGray2}`,
        padding: "24px",
        marginTop: 24,
        marginBottom: 138,
        maxWidth: "50%",
    },
    plansHeader: {
        fontSize: 14,
        color: color.darkestGray,
        fontWeight: 500,
        marginBottom: 12,
    },
    noPlansContainer: {
        backgroundColor: color.lightGray,
        color: color.darkGray,
        fontSize: 18,
        textAlign: "center",
        padding: "50px",
        lineHeight: "22px",
    },
    savedMultiPlanOptionsHeader: {
        paddingBottom: 4,
        borderBottom: `2px solid ${color.mediumRed}`,
        marginBottom: 12,
    },
    arrowContainer: {
        marginTop: 20,
    },
    arrowWrapper: {
        height: 50,
        marginTop: 8,
        marginBottom: 8,
        paddingBottom: 24,
    },
    arrow: {
        backgroundColor: color.mediumRed,
        height: 2,
        width: 18,
        position: "relative",
        cursor: "pointer",
        top: 24,
        ":before": {
            content: "''",
            background: color.mediumRed,
            position: "absolute",
            height: 2,
            width: 10,
            right: 0,
            bottom: -4,
            transform: "rotate(-45deg)",
        },
        ":after": {
            content: "''",
            background: color.mediumRed,
            position: "absolute",
            height: 2,
            width: 10,
            right: 0,
            top: -4,
            transform: "rotate(45deg)",
        }
    },
    currentPlans: {
        color: color.mediumYellow1,
        cursor: "pointer",
    },
    currentPlanItemContainer: {

    },
    currentPlanItem: {
        borderRadius: 4,
        height: 50,
        backgroundColor: color.lightGray,
        padding: "8px 16px",
        marginTop: 8,
        marginBottom: 8,
        display: "flex",
    },
    currentPlanItemText: {
        margin: "auto 0px",
        color: color.darkestGray,
        fontSize: 14,
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxHeight: 32,
    },
    newPlanItemContainer: {

    },
    newPlanItem: {
        height: 50,
        marginTop: 8,
        marginBottom: 8,
    },
    newPlanDropdown: {
        top: -8,
        height: 50,
    },
    planAssignmentDropdown: {
        height: 50,
    },
    customizeContainer: {
        width: "100%",
        marginBottom: 30,
    },
    censusTable: {
        width: "100%",
        marginTop: 10,
        marginBottom: 20,
        borderCollapse: "collapse",
    },
    censusTableHeader: {
        fontSize: 13,
        color: color.mediumYellow1,
        padding: 10,
        fontWeight: 500,
        textAlign: "left",
        whiteSpace: "nowrap",
        borderBottom: `1px solid ${color.mediumGray}`,
    },
    censusTableCell: {
        fontSize: 14,
        color: color.tableText,
        padding: "8px 8px",
        borderBottom: `1px solid ${color.tableBorder}`,

        lineHeight: "16px",
        ":nth-child(1)": {
            fontWeight: 500,
        },
        ":nth-child(3)": {
            fontWeight: 500,
        },
        ":nth-child(7)": {
            maxWidth: 350,
        },
    },
    censusTableCellCenter: {
        fontSize: 15,
        color: color.mediumYellow1,
        textAlign: "center",
    },
    censusDropdownTableInfo: {
        color: color.darkGray,
    },
    modalContent: {
        width: 386,
    },
    modalInstructions: {
        color: color.darkestGray,
        fontSize: 16,
        marginBottom: 8,
    },
    modalButtonContainer: {
        marginTop: 24,
        display: "flex",
        justifyContent: "space-between",
    },
    modalButton: {
        width: 150,
    },
    baseLabel: {
        color: color.mediumRed,
        marginLeft: 6,
    },
    multiPlanOptionItem: {
        paddingTop: 4,
        paddingBottom: 4,
        fontSize: 15,
        cursor: "pointer",
    },
    optionDate: {
        color: color.darkestGray,
    },
    savedOptionsCount: {
        float: "right",
        color: color.darkestGray,
        fontSize: 14,
    },
    optionName: {
        color: color.mediumYellow1,
        fontWeight: 500,
        marginLeft: 6,
    },
    noSavedOptions: {
        color: color.darkGray,
        fontSize: 16,
        lineHeight: "21px",
    },
    redText: {
        color: color.red,
    }
});

const strings = {
    required: "Please fill out this field",
    multiPlanOptionPlaceholder: "Select Multi Plan Option",
    savedMultiPlanOptionsHeaderText: "Saved Multi-plan Options",
    currentPlansHeaderText: "Create Multi Option Spreadsheet by Mapping Current to New Plans",
    newPlansHeaderText: "Create Multi Option Spreadsheet from Scratch",
    newPlanOptionPlaceholder: "Select Plan",
    saveMultiPlanButtonText: "Save Multi-plan option",
    saveCustomizationButtonText: "Save Customization",
    multiPlanOptionNameInstructions: "Name this option:",
    multiPlanOptionNamePlaceholder: "Enter option name...",
    saveButtonText: "Save",
    cancelButtonText: "Cancel",
    saveMultiPlanOptionModalHeaderText: "Save Multi-plan Option",
    dateFormat: "MM/DD/YYYY",
};

const inputNames = {
    multiPlanOptionName: "multiPlanOptionName",
};

export class MultiPlanSpreadsheet extends Component {
    static propTypes = {
        quote: texchangePropTypes.quote.isRequired,
        selectedPlans: PropTypes.object.isRequired,
        spreadsheets: PropTypes.object.isRequired,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        setSubStep: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            inputs: {
                [inputNames.multiPlanOptionName]: "",
                [inputNames.rateType]: (props.quote && (props.quote.areaId === 7 || props.quote.areaId === 23 || props.quote.areaId === 32)) ? "Age" : "Composite",
            },
            selectedMultiPlanOptionName: null,
            showSaveModal: false,
            customPlanOptions: this._getInitialCustomPlanOptions(),
            currentPlans: this._getCurrentPlans(),
            planMapping: this._getInitialPlanMappings(),
            errors: [],
            basePlanId: null,
            basePlanName: "",
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.quote.currentMedicalPlans.length !== prevProps.quote.currentMedicalPlans.length) {
            const currentPlans = this._getCurrentPlans();
            const { planMapping } = this.state;

            this._setPlanMapping(currentPlans, planMapping);

            this.setState({
                currentPlans,
                planMapping,
            });
        }
    }

    _getPlanOptionItem = item => {
        return {
            value: {
                id: item.id,
                displayName: item.displayName,
                carrierId: item.carrierId,
                carrierAbbr: item.carrierAbbr,
                packageName: item.packageName,
                networkName: item.networkName
            },
            label: `${item.displayName} - ${item.individualDeductibleIn} ded`,
        };
    };

    _getPlanList = () => {
        const { selectedPlans } = this.props;

        let newPlans = [];
        if (selectedPlans && selectedPlans.medical && selectedPlans.medical.length > 0) {
            const isAgeRated = this.state.inputs[inputNames.rateType] === "Age";

            if (isAgeRated) {
                newPlans = selectedPlans.medical.filter(p => p.ageMonthlyPremium !== "$0.00").map(this._getPlanOptionItem);
            } else {
                newPlans = selectedPlans.medical.filter(p => p.monthlyPremium !== "$0.00").map(this._getPlanOptionItem);
            }
        }

        //filter by carrier and network/package
        let planList = [];
        if (newPlans.length > 0) {
            const grouped = newPlans.reduce((rv, x) => {
                const network = x.value["carrierAbbr"] === "UHC" && x.value["packageName"] ? " (" + x.value["packageName"] + ")" : x.value["networkName"] ? " (" + x.value["networkName"] + ")" : " (No Network)";
                (rv[x.value["carrierAbbr"] + network] = rv[x.value["carrierAbbr"] + network] || []).push(x);
                return rv;
            }, {});

            Object.keys(grouped).forEach(key => {
                planList.push({
                    label: key,
                    options: sortBy(grouped[key], p => p.label),
                });
            });
            //sort
            planList = sortBy(planList, p => p.label);
        }
        //return
        return planList;
    };


    _setPlanMapping = (currentPlans, planMapping) => {
        currentPlans.forEach(currentPlan => {
            const plan = planMapping.find(p => p.currentPlanId === currentPlan.id);
            if (!plan) {
                planMapping.push({
                    currentPlanId: currentPlan.id,
                    newPlanId: null,
                    newPlanName: "Select Plan",
                });
            }
        });

        for (let i = 0; i < planMapping.length; i++) {
            if (currentPlans.findIndex(cp => cp.id === planMapping[i].currentPlanId) === -1) {
                planMapping.splice(i, 1);
                i--;
            }
        }

        this.setState({
            planMapping,
        });
    };

    _selectedMultiPlanOptionChanged = value => {
        const multiPlanOption = this.props.spreadsheets.multiPlan.find(item => item.name === value);
        let customPlanOptions, planMapping;
        const currentPlans = this._getCurrentPlans();

        if (multiPlanOption) {
            customPlanOptions = multiPlanOption.customPlanOptions;
            planMapping = multiPlanOption.planMapping;
        } else {
            customPlanOptions = this._getInitialCustomPlanOptions();
            planMapping = this._getInitialPlanMappings();
        }

        this._setPlanMapping(currentPlans, planMapping);

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.multiPlanOptionName];

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.multiPlanOptionName]: value,
                },
                customPlanOptions,
                planMapping,
                selectedMultiPlanOptionName: value,
            };
        });
    };

    _loadSavedMultiPlanOption = option => {
        const currentPlans = this._getCurrentPlans();
        const { planMapping } = option;

        this._setPlanMapping(currentPlans, planMapping);

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.multiPlanOptionName];

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.multiPlanOptionName]: option.name,
                    [inputNames.rateType]: option.rateType,
                },
                customPlanOptions: option.customPlanOptions,
                selectedMultiPlanOptionName: option.name,
            };
        });
    }

    _rateTypeChanged = value => {
        let rateType;
        if (value) {
            rateType = "Age";
        } else {
            rateType = "Composite";
        }

        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.rateType]: rateType,
                },
            };
        });
    };

    _planMappingChanged = e => {
        const index = parseInt(e.name);

        const { planMapping, customPlanOptions } = this.state;
        planMapping[index].newPlanId = e.value.value.id;
        planMapping[index].newPlanName = e.value.value.displayName;

        //update each census member's default mapping
        const enrolling = this.props.quote.census.filter(item => item.relationship === "EE" && item.medicalCoverageId < 5);
        for (let i = 0; i < enrolling.length; i++) {
            if (enrolling[i].currentMedicalPlanId && enrolling[i].currentMedicalPlanId === planMapping[index].currentPlanId) {
                customPlanOptions[i].planId = e.value.value.id;
                customPlanOptions[i].planName = e.value.value.displayName;
            }
        }

        this.setState({
            planMapping,
            customPlanOptions
        });
    };

    _customPlanBaseIdChanged = e => {
        const enrolling = this.props.quote.census.filter(item => item.relationship === "EE" && item.medicalCoverageId < 5);
        const { customPlanOptions } = this.state;

        for (let i = 0; i < enrolling.length; i++) {
            if (!customPlanOptions[i].planId) {
                customPlanOptions[i].planId = e.value.value.id;
                customPlanOptions[i].planName = e.value.value.displayName;
            }
        }

        this.setState({
            customPlanOptions,
            basePlanId: e.value.value.id,
            basePlanName: e.value.value.displayName
        });
    };

    _customPlanMappingChanged = e => {
        const { customPlanOptions } = this.state;

        const index = parseInt(e.name);

        customPlanOptions[index].planId = e.value.value.id;
        customPlanOptions[index].planName = e.value.value.displayName;
        this.setState({ customPlanOptions });
    }

    _goToCurrentPlans = () => {
        this.props.setSubStep(1);
    };

    _getInitialCustomPlanOptions = () => {
        const enrolling = this.props.quote.census.filter(item => item.relationship === "EE" && item.medicalCoverageId < 5);
        const customPlanOptions = [];

        for (let i = 0; i < enrolling.length; i++) {
            customPlanOptions.push({
                censusId: enrolling[i].id,
                planId: null,
            });
        }

        return customPlanOptions;
    }

    _getCurrentPlans = () => {
        return this.props.quote[`current${util.capitalize(coverageTypes.medical)}Plans`];
    }

    _getInitialPlanMappings = () => {
        const currentPlans = this._getCurrentPlans();
        const planMapping = [];

        for (let i = 0; i < currentPlans.length; i++) {
            planMapping.push({
                currentPlanId: currentPlans[i].id,
                newPlanId: null,
                newPlanName: "Select Plan",
            });
        }

        return planMapping;
    };

    _saveMultiPlan = () => {
        if (this.state.showSaveModal) {
            const multiPlanOptionName = this.state.inputs[inputNames.multiPlanOptionName];
            if (!multiPlanOptionName) {
                const errors = {};
                errors[inputNames.multiPlanOptionName] = strings.required;
                this.setState({ errors });
            } else {
                const spreadsheet = {
                    name: multiPlanOptionName,
                    date: new Date(),
                    basePlanId: this.state.basePlanId,
                    customPlanOptions: this.state.customPlanOptions,
                    planMapping: this.state.planMapping,
                    rateType: this.state.inputs[inputNames.rateType],
                };

                this.props.onSave(spreadsheet);

                this.setState({
                    showSaveModal: false,
                    selectedMultiPlanOptionName: null,
                    customPlanOptions: this._getInitialCustomPlanOptions(),
                    currentPlans: this._getCurrentPlans(),
                    planMapping: this._getInitialPlanMappings(),
                    basePlanId: null,
                    basePlanName: "",
                });
            }
        } else {
            this.setState({ showSaveModal: true });
        }
    };

    _handleTextChange = e => {
        const { name } = e.target;
        const { value } = e.target;

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
                errors,
            };
        });
    };

    render() {
        const { selectedPlans } = this.props;
        const { currentPlans, planMapping, customPlanOptions } = this.state;
        const isAgeRated = this.state.inputs[inputNames.rateType] === "Age";
        const multiPlanOptions = this.props.spreadsheets.multiPlan;
        const defaultPlanId = planMapping && planMapping[0] && planMapping[0].newPlanId ? planMapping[0].newPlanId.toString() : "";
        const enrolling = this.props.quote.census.filter(c => c.relationship === "EE" && c.medicalCoverageId < 5);

        const toggleAgeLabel = selectedPlans && selectedPlans.medical ? "Age (" + selectedPlans.medical.filter(p => p.ageMonthlyPremium !== "$0.00").length + ")" : "Age (0)";
        const toggleCompLabel = selectedPlans && selectedPlans.medical ? "Comp (" + selectedPlans.medical.filter(p => p.monthlyPremium !== "$0.00").length + ")" : "Comp (0)";

        return (
            <div className={css(styles.container)}>
                <React.Fragment>
                    <div className={css(styles.plansContainer)}>
                        {/*<div className={css(styles.multiPlanDropdownContainer)}>
                            <Dropdown
                                aStyles={styles.multiPlanDropdown}
                                options={multiPlanOptions.map(item => ({
                                    label: item.name,
                                    value: item.name,
                                }))}
                                placeholder={strings.multiPlanOptionPlaceholder}
                                value={this.state.selectedMultiPlanOptionName}
                                onChange={this._selectedMultiPlanOptionChanged}                        
                                hideLabel={true}
                                size={inputSizes.small}
                                allowEmpty={true}
                            />
                            <Toggle
                                aStyles={styles.toggle}
                                checked={isAgeRated}
                                onChange={this._rateTypeChanged}
                                checkedLabel="Age"
                                uncheckedLabel="Comp."
                            />
                        </div>*/}
                        <div className={css(styles.titleContainer)}>
                            <div className={css(styles.title)}>
                                {strings.currentPlansHeaderText}
                            </div>
                        </div>
                        <div className={css(styles.currentPlansContainer)}>
                            <div className={css(styles.plansHeader)}>
                                Current Plans
                            </div>
                            {currentPlans.length > 0 ? (
                                <div>
                                    {currentPlans.map((plan, index) => (
                                        <div
                                            key={index}
                                            className={css(styles.currentPlanItem)}
                                        >
                                            <div className={css(styles.currentPlanItemText)}>
                                                {plan.networkName} {plan.displayName}
                                                {plan.isBase ? (
                                                    <span className={css(styles.baseLabel)}>
                                                        (Base Plan)
                                                    </span>
                                                ) : (
                                                    null
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className={css(styles.noPlansContainer)}>
                                    You have no plans selected.<br />
                                    Go to <span className={css(styles.currentPlans)} onClick={this._goToCurrentPlans}>Current Plans</span> to
                                    make selections.
                                </div>
                            )}
                        </div>
                        <div className={css(styles.arrowContainer)}>
                            {currentPlans.map((item, index) => (
                                <div className={css(styles.arrowWrapper)} key={index}>
                                    <div className={css(styles.arrow)} />
                                </div>
                            ))}
                        </div>
                        <div className={css(styles.newPlansContainer)}>
                            <div className={css(styles.plansHeader)}>
                                New Plans
                            </div>
                            {planMapping.length > 0 && selectedPlans.medical &&
                                <div>
                                    {planMapping.map((plan, index) => (
                                        <div
                                            key={plan.currentPlanId}
                                            className={css(styles.newPlanItem)}
                                        >
                                            <NestedDropdown
                                                aStyles={styles.newPlanDropdown}
                                                options={this._getPlanList()}
                                                value={planMapping[index].newPlanId ? planMapping[index].newPlanId.toString() : ""}
                                                onSelect={this._planMappingChanged}
                                                name={index.toString()}
                                                placeholder={planMapping[index].newPlanName}
                                                openLeft={index > 1}
                                            />
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                        <div className={css(styles.buttonContainer)}>
                            <Toggle
                                aStyles={styles.toggle}
                                checked={isAgeRated}
                                onChange={this._rateTypeChanged}
                                checkedLabel={toggleAgeLabel}
                                uncheckedLabel={toggleCompLabel}
                            />
                            <ActionButton
                                aStyles={styles.saveButton}
                                label={strings.saveMultiPlanButtonText}
                                onClick={this._saveMultiPlan}
                                type="button"
                                isLoading={this.state.isSaving}
                            />
                        </div>
                        <div className={css(styles.titleContainer)}>
                            <div className={css(styles.title)}>
                                {strings.newPlansHeaderText}
                            </div>
                        </div>
                        <div className={css(styles.customizeContainer)}>
                            <table className={css(styles.censusTable)}>
                                <thead>
                                    <tr>
                                        <th className={css(styles.censusTableHeader)} style={{ width: "5%" }}>No.</th>
                                        <th className={css(styles.censusTableHeader)} style={{ width: "30%" }}>Name</th>
                                        <th className={css(styles.censusTableHeader)} style={{ width: "10%" }}>DOB</th>
                                        <th className={css(styles.censusTableHeader)} style={{ width: "10%" }}>Gen</th>
                                        <th className={css(styles.censusTableHeader)} style={{ width: "10%" }}>Medical</th>
                                        <th className={css(styles.censusTableHeader)} style={{ width: "40%" }}>Choose Plan Assignment <span className={css(styles.censusDropdownTableInfo)}>(maximum of 10 unique plans)</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedPlans.medical && selectedPlans.medical.length > 0 ? (
                                        <tr>
                                            <td className={css(styles.censusTableCell, styles.censusTableCellCenter)} colSpan={5}>Select a Base Plan <span className={css(styles.censusDropdownTableInfo)}>(this will auto-fill any empty plan assignments below)</span></td>
                                            <td className={css(styles.censusTableCell)}>
                                                <NestedDropdown
                                                    aStyles={styles.planAssignmentDropdown}
                                                    options={this._getPlanList()}
                                                    value={this.state.basePlanId ? this.state.basePlanId.toString() : ""}
                                                    onSelect={this._customPlanBaseIdChanged}
                                                    name="basePlanId"
                                                    placeholder={this.state.basePlanId ? this.state.basePlanName : strings.newPlanOptionPlaceholder}
                                                    openLeft={false}
                                                />
                                            </td>
                                        </tr>
                                    ) : null}
                                    {enrolling.map((item, index) => (
                                        <tr key={index}>
                                            <td className={css(styles.censusTableCell)}>{item.sequenceNumber}</td>
                                            <td className={css(styles.censusTableCell)}>{item.fullName}</td>
                                            <td className={css(styles.censusTableCell)}>{item.birthDateString}</td>
                                            <td className={css(styles.censusTableCell)}>{item.gender}</td>
                                            <td className={css(styles.censusTableCell)}>{item.medicalCoverage}</td>
                                            <td className={css(styles.censusTableCell)}>
                                                {selectedPlans.medical && selectedPlans.medical.length > 0 ? (
                                                    <NestedDropdown
                                                        aStyles={styles.planAssignmentDropdown}
                                                        options={this._getPlanList()}
                                                        value={customPlanOptions[index].planId ? customPlanOptions[index].planId.toString() : defaultPlanId}
                                                        onSelect={this._customPlanMappingChanged}
                                                        name={index.toString()}
                                                        placeholder={customPlanOptions[index].planId ? customPlanOptions[index].planName : strings.newPlanOptionPlaceholder}
                                                        openLeft={false}
                                                    />
                                                ) : (
                                                    <span className={css(styles.redText)}>You have no New plans selected</span>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className={css(styles.buttonContainer)}>
                            <Toggle
                                aStyles={styles.toggle}
                                checked={isAgeRated}
                                onChange={this._rateTypeChanged}
                                checkedLabel={toggleAgeLabel}
                                uncheckedLabel={toggleCompLabel}
                            />
                            <ActionButton
                                aStyles={styles.saveButton}
                                label={strings.saveMultiPlanButtonText}
                                onClick={this._saveMultiPlan}
                                type="button"
                                isLoading={this.state.isSaving}
                            />
                        </div>
                    </div>
                    <div className={css(styles.savedMultiPlanOptionsContainer)}>
                        <div className={css(styles.savedMultiPlanOptionsHeader)}>
                            {strings.savedMultiPlanOptionsHeaderText}
                            <span className={css(styles.savedOptionsCount)}>
                                ({multiPlanOptions.length})
                            </span>
                        </div>
                        {multiPlanOptions.length > 0 ? (
                            <React.Fragment>
                                {multiPlanOptions.map((option, index) => (
                                    <div
                                        key={index}
                                        className={css(styles.multiPlanOptionItem)}
                                        onClick={() => this._loadSavedMultiPlanOption(option)}
                                    >
                                        {option.date &&
                                            <span className={css(styles.optionDate)}>
                                                {format(option.date, strings.dateFormat)}
                                            </span>
                                        }
                                        <span className={css(styles.optionName)}>
                                            {option.name}
                                        </span>
                                    </div>
                                ))}
                            </React.Fragment>
                        ) : (
                            <div className={css(styles.noSavedOptions)}>
                                You haven’t saved any multi-plan options yet.
                            </div>
                        )}
                    </div>
                </React.Fragment>
                <Modal
                    show={this.state.showSaveModal}
                    header={strings.saveMultiPlanOptionModalHeaderText}
                >
                    <div className={css(styles.modalContent)}>
                        <div className={css(styles.modalInstructions)}>
                            {strings.multiPlanOptionNameInstructions}
                        </div>
                        <TextInput
                            name={inputNames.multiPlanOptionName}
                            onChange={this._handleTextChange}
                            placeholder={strings.multiPlanOptionNamePlaceholder}
                            required={true}
                            validationMessage={this.state.errors[inputNames.multiPlanOptionName]}
                            value={this.state.inputs[inputNames.multiPlanOptionName]}
                            size={inputSizes.small}
                            hideLabel={true}
                        />
                    </div>
                    <div className={css(styles.modalButtonContainer)}>
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.cancelButtonText}
                            invertColor={true}
                            type="button"
                            onClick={() => this.setState({ showSaveModal: false })}
                        />
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.saveButtonText}
                            onClick={this._saveMultiPlan}
                            type="button"
                            isLoading={this.state.isSaving}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapDispatchToProps = state => ({
    quote: state.proposal.quote,
    selectedPlans: state.proposal.selectedPlans,
    spreadsheets: state.proposal.spreadsheets,
});

export default connect(mapDispatchToProps)(MultiPlanSpreadsheet);
