import * as breakpoints from "../../constants/breakpoints";
import * as color from "../../constants/color";
import * as texchangePropTypes from "../../texchangePropTypes";
import ForgotPasswordForm from "../forms/ForgotPasswordForm";
import er_rating_hub from "../../images/rating-hub-logo.svg";
import pic1 from "../../images/rating-hub-preview.jpg";
import pic3 from "../../images/2231710289_request_appointment.jpg";
import LoginForm from "../forms/LoginForm";
import React from "react";
import RegisterForm from "../forms/RegisterForm";
import ResetPasswordForm from "../forms/ResetPasswordForm";
import SBCForm from "../forms/SBCForm";
import { Container, Row, Column } from "../grid";
import { Route } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import "../../styles/rating-hub-login.css"

const styles = StyleSheet.create({
    logo: {
        [breakpoints.tabletAndDown]: {
            marginTop: 30,
            marginBottom: 10,
        },
        [breakpoints.desktopOnly]: {
            marginTop: 20,
            marginBottom: 20,
            marginLeft: 5,
        },
        verticalAlign: "middle",
        fill: color.darkRed,
    },
    title: {
        verticalAlign: "middle",
        paddingLeft: 30,
        width: "120px"
    },
    header: {
        [breakpoints.tabletAndDown]: {
            textAlign: "center",
        },
        [breakpoints.desktopOnly]: {
            textAlign: "left",
            marginLeft: 20,
        },
        borderBottom: `1px solid ${color.darkGray2}`,
    },
    registerSection: {
        [breakpoints.tabletAndDown]: {
            marginTop: 40,
            marginBottom: 40,
        },

        [breakpoints.desktopOnly]: {
            marginTop: 50,
        },
    },
    registerContainer: {
        [breakpoints.tabletAndDown]: {
            display: "inline-block",
            marginLeft: 6,
        },
    },
});

const strings = {
    loginPrompt: "Already have an account?",
    signIn: "Sign In",
    rbgQuoting: "Quoting",
    headerTitle: "Emerson Rogers Rating Hub",
};

export default function Unauthenticated(props) {
    const registerPath = `${props.match.path}/register`;

    return (
        <Container>
            <Row>
                <div className="container-logo">
                    <img src={er_rating_hub} alt={strings.headerTitle} />
                </div>
                <section>
                    <div className="h-login">
                        <div className="container-login h-login-grid split-grid">
                            <div className="h-login-intro">
                                <h1 className="serif">Innovative Quoting & Proposal Technology</h1>
                                <p>Emerson Rogers’ Rating Hub provides brokers with blazing fast quotes and effortless proposals to win and renew small group accounts.  You can go from census to proposal in a few minutes. </p>
                            </div>
                            <div className="h-login-box shadow rounded--4">
                                <Column large={12} largeOffset={1}>
                                    <Route path={`${props.match.path}/login`} exact component={LoginForm} />
                                    <Route path={registerPath} exact component={RegisterForm} />
                                    <Route path={`${props.match.path}/forgot-password`} exact component={ForgotPasswordForm} />
                                    <Route path={`${props.match.path}/reset-password`} exact component={ResetPasswordForm} />
                                    <Route path={`${props.match.path}/get-sbc/:id`} exact component={SBCForm} />
                                </Column>
                            </div>
                        </div>
                    </div>
                </section>
            </Row>
            <Row>
                <section className="m-key-features">
                    <div className="container-login m-kf-grid split-grid">
                        <div className="m-kf-content">
                            <h5 className="sans-serif">Key Features</h5>
                            <ul className="checkmark checkmark--rouge">
                                <li>Available across 35+ states with 40,000+ medical plans </li>
                                <li>Quote level funded plans alongside ACA plans</li>
                                <li>Client ready, private labeled proposals</li>
                                <li>Fast multi-plan option spreadsheets</li>
                                <li>Broker dashboard with key data</li>
                            </ul>
                        </div>
                        <div className="m-kf-image">
                            <img src={pic1} alt="rating hub preview" />
                        </div>
                    </div>
                </section>
            </Row>
            <Row>
                <section className="m-reuqest-appointment module module--pb-md">
                    <div className="container-login m-ra-grid split-grid">
                        <div className="m-ra-image">
                            <img src={pic3} alt="rating hub preview" />
                        </div>
                        <div className="m-ra-content">
                            <h5 className="sans-serif">Request an Account</h5>
                            <p>Contact your <a href="https://www.emersonrogers.com/national" target="_blank">local Emerson Rogers office</a> to request setup and training for you and your staff. </p>
                        </div>
                    </div>
                </section>
            </Row>
            <Row>
                <section className="module module--p-lg background--rouge background--curve-lines-3">
                    <div className="container-login">
                        <div className="ui--light">
                            <div className="m-ae-grid">
                                <div className="m-ae-content">
                                    <h3 className="serif">About Emerson Rogers</h3>
                                    <p className="mb-0">Emerson Rogers is a true wholesale general agent, meaning brokers are our only clients.  Our mission is to differentiate you, our broker, from your
                                        competition, through the introduction of new and innovative programs while providing the tools, knowledge, and support necessary to expand
                                        and protect your business. For more information visit <a href="https://www.emersonrogers.com" target="_blank">www.emersonrogers.com</a>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Row>
            <Row>
                <section className="module module--p-xs">
                    <div className="container-login">
                        <p className="disclaimer" style={{ marginBottom: 0 }}>© {new Date().getFullYear()} Emerson Rogers, LLC. All Rights Reserved. CA Insurance License #0C94240.</p>
                    </div>
                </section>
            </Row>
        </Container>
    );
}

Unauthenticated.propTypes = {
    match: texchangePropTypes.match.isRequired
};
