import * as breakpoints from "../../constants/breakpoints";
import * as routeHelpers from "../../lib/routeHelpers";
import * as sessionActions from "../../actions/sessionActions";
import describeNetworkError from "../../lib/describeNetworkError";
import logger from "../../lib/logger";
import PropTypes from "prop-types";
import React, { Component } from "react";
import SmallLink from "../text/SmallLink";
import TextInput from "../inputs/TextInput";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/login.css'
import "../../styles/rating-hub-login.css"

const styles = StyleSheet.create({
    loginForm: {
        maxWidth: "500px",
        marginBottom: "28px",
    },
    title: {
        textTransform: "uppercase",
        textDecoration: "none",
        color: "#660000",
        fontSize: 24,
        fontWeight: "bold",
        [breakpoints.tabletAndDown]: {
            textAlign: "center",
            marginTop: 14,
            marginBottom: 0,
        },
        [breakpoints.desktopOnly]: {
            marginTop: 28,
            marginBottom: 0,
        },
    },
    buttonContainer: {
        marginRight: '100px',
        display: 'flex',
        [breakpoints.desktopOnly]: {
            textAlign: "right",
        },
        width: "30%",
    },
    actionButton: {
        marginTop: 24,

        [breakpoints.tabletAndDown]: {
            width: "100%",
        },
        [breakpoints.desktopOnly]: {
            minWidth: 184,
            width: "100%",
        },
    },
    register: {
        marginTop: 12,
        float: "right",
    },
    forgotPassword: {
        marginTop: 12,
        textAlign: "right",
    },
});

const strings = {
    title: "Sign In",
    emailPlaceholder: "Email Address",
    passwordPlaceholder: "Type Password",
    verificationPlaceholder: "Enter Verification Code",
    submitButton: "Sign In",
    loading: "Loading",
    register: "Request Account",
    forgotPassword: "Forgot Password?",
    invalidLogin: "Incorrect username or password",
};

const inputNames = {
    username: "username",
    password: "password",
    verification: "verification",
};

export class LoginForm extends Component {
    static propTypes = {
        signIn: PropTypes.func.isRequired,
    };

    state = {
        inputs: {
            [inputNames.username]: "",
            [inputNames.password]: "",
            [inputNames.verification]: "",
        },
        loading: false,
        errors: {},
        requiresVerification: false,
    };

    componentDidMount() {
        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    _handleTextChange = e => {
        let { name, value } = e.target;
        //max length of verification
        if (name === 'verification' && value.length > 6) {
            value = value.substr(0, 6);
        }
        //update
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
            };
        });
    };

    _login = e => {
        e.preventDefault();
        if (this.state.loading) {
            return;
        }

        this.setState({
            loading: true,
            errors: {},
        });

        let code = "";
        if (this.state.inputs[inputNames.verification]) {
            code = this.state.inputs[inputNames.verification].replace(/[^0-9]+/g, "");
            if (!code || code.length !== 6) {
                let errors = {};
                errors[inputNames.verification] = "Must be 6 digits";
                this.setState({
                    loading: false,
                    errors: errors,
                });
                return;
            }
        }

        this.props
            .signIn(this.state.inputs[inputNames.username], this.state.inputs[inputNames.password], code)
            .then(response => {
                if (!response) {
                    let errors = {};
                    errors[inputNames.password] = [strings.invalidLogin];
                    this.setState({
                        loading: false,
                        errors: errors,
                    });
                }
            })
            .catch(err => {
                logger.warn(err);
                if (!this._mounted) {
                    return;
                }

                let errors = {};
                if (err && err.response) {
                    const { response } = err;
                    if (
                        response &&
                        response.data &&
                        response.data.errors &&
                        Object.keys(response.data.errors).length > 0
                    ) {
                        // Server provided validation messages
                        errors = response.data.errors;
                    } else if (response && (response.status === 400 || response.data.error === "Invalid LoginId")) {
                        // Generic login error
                        errors[inputNames.password] = [strings.invalidLogin];
                    } else if (response && response.data.error === "Too Many Failures") {
                        // Generic login error
                        errors[inputNames.password] = [strings.invalidLogin];
                        // Show the message to the user about too many failures
                        toast.error("You have attempted to login too many times. Your account is locked. Contact your local office to unlock your account.", { toastId: 2 });
                    } else if (response && response.data.error === "Requires Verification") {
                        // Show the message to the user to check their email and enter the verification code
                        toast.success("Your account requires Two Step Verification.  A 6 digit code was sent to you.  Please enter it here to proceed.", { toastId: 2 });
                        this.setState(prevState => {
                            return {
                                inputs: {
                                    ...prevState.inputs,
                                    [inputNames.verification]: "",
                                },
                                requiresVerification: true,
                            };
                        });
                    } else {
                        // 500 or something else unexpected
                        const description = describeNetworkError(err);
                        toast.error(description.join("\n"), { toastId: 1 });
                    }
                }

                this.setState({
                    loading: false,
                    errors: errors,
                });
            });
    };

    render() {
        return (


            <form onSubmit={this._login} className={css(styles.loginForm)}>
                <ToastContainer position="top-center" autoClose={3500} />
                <div className={css(styles.register)}>
                    {/*<SmallLink to={routeHelpers.register()}>
                        {strings.register}
                    </SmallLink>*/}
                </div>

                <div className={css(styles.title)}>{strings.title}</div>
                <TextInput
                    name={inputNames.username}
                    onChange={this._handleTextChange}
                    placeholder={strings.emailPlaceholder}
                    autoComplete="email"
                    required={true}
                    type="email"
                    validationMessage={this.state.errors[inputNames.username]}
                    value={this.state.inputs[inputNames.username]}
                />

                <TextInput
                    name={inputNames.password}
                    onChange={this._handleTextChange}
                    placeholder={strings.passwordPlaceholder}
                    autoComplete="current-password"
                    required={true}
                    type="password"
                    validationMessage={this.state.errors[inputNames.password]}
                    value={this.state.inputs[inputNames.password]}
                />

                {this.state.requiresVerification &&
                    <TextInput
                        name={inputNames.verification}
                        onChange={this._handleTextChange}
                        placeholder={strings.verificationPlaceholder}
                        required={true}
                        type="number"
                        validationMessage={this.state.errors[inputNames.verification]}
                        value={this.state.inputs[inputNames.verification]}
                    />
                }

                <div className={css(styles.forgotPassword)}>
                    <SmallLink to={routeHelpers.forgotPassword()}>
                        <a className="h-login-forgot-password">Forgot Password?</a>
                    </SmallLink>
                </div>

                <div className={css(styles.buttonContainer)}>
                    <button
                        aStyles={styles.actionButton}
                        className="h-login-submit theme-btn theme-btn--rouge"
                        disabled={this.state.loading}
                        label={this.state.loading ? strings.loading : strings.submitButton}
                        isLoading={this.state.loading}
                        type="submit"
                    >
                        Sign In
                    </button>
                </div>
            </form>

        );
    }
}

export default connect(
    null,
    { signIn: sessionActions.signIn },
)(LoginForm);
