import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import * as color from "../../constants/color";
import ActionButton from "../ActionButton";
import Spinner from "../Spinner";
import EnrollmentSteps from "./EnrollmentSteps";
import CompanyInfo from "./CompanyInfo";
import Census from "./Census";
import LinesOfCoverage from "./LinesOfCoverage";
import BillingContact from "./BillingContact";
import CarrierForms from "./CarrierForms";
import * as enrollmentApi from "../../lib/api/enrollment";
import * as enrollmentActions from "../../actions/enrollmentActions";

const styles = StyleSheet.create({
	enrollmentContainer: {
		paddingTop: 24,
		paddingBottom: 24,
		color: color.darkestGray,
	},
	header: {		
		fontSize: 20,
		fontWeight: 500,
	},
	headerContent: {
		paddingLeft: 24,
		paddingRight: 24,
	},
	instructions: {        
        fontWeight: 300,
        fontSize: 20,
        marginTop: 12,
        marginBottom: 12,        
    },
    loadingSpinner: {
		paddingTop: 24,
		paddingBottom: 24,
	},
	success: {
		fontSize: 24,
		fontWeight: 500,
		color: color.darkRed,
		textAlign: "center",
	},
    button: {
        cursor: "pointer",
        outline: "none",
        lineHeight: "12px",
        marginLeft: 8,
        color: color.white,
        background: color.darkRed,
        padding: "2px 3px 2px 6px",
        borderRadius: 4,
    },
    labelText: {        
        fontSize: 14,     
        float: "left",
        lineHeight: "22px",        
    },
});

const strings = {
	header: "Enrollment",
	instructions: "Please fill out the form below:",
};

export class Enrollment extends Component {
	static propTypes = {
        enrollmentPortalId: PropTypes.number.isRequired,        
        setEnrollment: PropTypes.func.isRequired,        
    };    

	state = {
		step: 0,
		isLoading: true,
	};

	componentDidMount() {		
		enrollmentApi.getEditEnrollment(this.props.enrollmentPortalId)
			.then(result => {
				this.props.setEnrollment(result);
				this.setState({
					isLoading: false,
				})
			});
	}

	_handleContinueClicked = () => {
		let { step } = this.state
		step++;

		//update
		this.setState({
			step,
		});
		//scroll to top of page
		window.scrollTo(0,0);
	};

	_handleCancelClicked = () => {
		let { step } = this.state;
		if (step > 0) {
			step--;
		}

		this.setState({
			step,
		});
	}

	_stepClicked = step => {
		this.setState({
			step,
		});
	}

	render() {		
		if (this.state.isLoading) {
            return (
				<Spinner 
					aStyles={styles.loadingSpinner} 
					text="Loading..." 	            	    
				/>
			);
		}

		const { step } = this.state;

		return (			
			<div className={css(styles.enrollmentContainer)}>
				<div className={css(styles.headerContent)}>
					<div className={css(styles.header)}>
						{strings.header}
					</div>
					<div className={css(styles.instructions)}>
						{strings.instructions}
					</div>
				</div>
				<EnrollmentSteps 
					step={step}
					stepClicked={this._stepClicked}
				/>
				{step === 0 &&
					<CompanyInfo
						onContinue={this._handleContinueClicked}
						onCancel={this._handleCancelClicked}
					/>
				}
				{step === 1 &&
					<Census
						onContinue={this._handleContinueClicked}
						onCancel={this._handleCancelClicked}
					/>
				}
				{step === 2 &&
					<LinesOfCoverage
						onContinue={this._handleContinueClicked}
						onCancel={this._handleCancelClicked}
					/>
				}
				{step === 3 &&
					<CarrierForms
						onContinue={this._handleContinueClicked}
						onCancel={this._handleCancelClicked}
					/>
				}
				{step === 4 &&
					<BillingContact
						onContinue={this._handleContinueClicked}
						onCancel={this._handleCancelClicked}
					/>
				}
				{step === 5 &&
					<div className={css(styles.success)}>
						<div className={css(styles.instructions)}>
							<p>SUCCESS!</p>
							<p>We have sent your quote to UBenefit and your portal is being setup.</p>
							<p>It will be ready in a couple of minutes.</p>
							<p>Please login to your UBenefit Broker portal to complete the group setup process! An Employer profile, Company Census &amp; the selected Medical plans will be available in your portal.</p>
							<div className={css(styles.buttonContainer)}>
								<ActionButton
									aStyles={styles.continueButton}
									label="Go to UBenefit"
									type="button"  
									href="https://www.ubenefit.net/"
								/>
							</div>
						</div>
					</div>
				}
			</div>
		);
	}
}

export default connect(
    null,
    { setEnrollment: enrollmentActions.setEnrollment },
)(Enrollment);
