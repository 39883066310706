import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    inputGroupContainer: {
        width: 440,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 24,
    }
});

export default function InputGroup(props) {
    const { children, ...rest } = props;
    return (
        <div {...rest} className={css(styles.inputGroupContainer)}>
            {children}
        </div>
    );
}

InputGroup.propTypes = {
    children: PropTypes.node,
};
