import instance from "./instance";

const responseData = response => response.data;

function removeChars(term) {
    term = term.replace(/[^0-9a-zA-Z\-_.,&'\s]+/g, "");
    if (term.length === 0) { term = "*"; }
    return term;
}

export function searchEmployersAndBrokers(term) {
    return instance
        .request({
            url: `/search/search-employers-brokers/${removeChars(term)}`,
            method: "GET",
        })
        .then(responseData);
}

export function searchEmployersBrokersAndAgencies(term) {
    return instance
        .request({
            url: `/search/search-employers-brokers-agencies/${removeChars(term)}`,
            method: "GET",
        })
        .then(responseData);
}

export function searchEmployersBrokersAndQuotes(term) {
    return instance
        .request({
            url: `/search/search-employers-brokers-quotes/${removeChars(term)}`,
            method: "GET",
        })
        .then(responseData);
}

export function searchBrokers(term, agencyId) {
    if (agencyId) {
        return instance
            .request({
                url: `/search/search-brokers/${removeChars(term)}/${agencyId}`,
                method: "GET",
            })
            .then(responseData);
    } else {
        return instance
            .request({
                url: `/search/search-brokers/${removeChars(term)}/`,
                method: "GET",
            })
            .then(responseData);
    }
}

export function searchAgencies(term) {
    return instance
        .request({
            url: `/search/search-agencies/${removeChars(term)}`,
            method: "GET",
        })
        .then(responseData);
}

export function searchEmployers(term) {
    return instance
        .request({
            url: `/search/search-employers/${removeChars(term)}`,
            method: "GET",
        })
        .then(responseData);
}

export function searchSICCodes(term) {
    return instance
        .request({
            url: `/search/search-sic-codes/${removeChars(term)}`,
            method: "GET",
        })
        .then(responseData);
}

export function searchPlans(term) {
    return instance
        .request({
            url: `/search/search-plans/${removeChars(term)}`,
            method: "GET",
        })
        .then(responseData);
}

export function searchCounties(term) {
    return instance
        .request({
            url: `/search/search-counties/${removeChars(term)}`,
            method: "GET",
        })
        .then(responseData);
}

export function searchPersonnel(term) {
    return instance
        .request({
            url: `/search/search-personnel/${removeChars(term)}`,
            method: "GET",
        })
        .then(responseData);
}
