import * as color from "../../constants/color";
import * as routeHelpers from "../../lib/routeHelpers";
import SearchInput from "../inputs/SearchInput";
import TextInput from "../inputs/TextInput";
import PageTitle from "../text/PageTitle";
import Dropdown from "../inputs/Dropdown";
import logger from "../../lib/logger";
import * as texchangePropTypes from "../../texchangePropTypes";
import describeNetworkError from "../../lib/describeNetworkError";
import Headline from "../text/Headline";
import ActionButton from "../ActionButton";
import React, { Component } from "react";
import { Container, Row, Column } from "../grid";
import { StyleSheet, css } from "aphrodite";
import * as dataApi from "../../lib/api/data";
import * as agenciesApi from "../../lib/api/agencies";
import * as searchApi from "../../lib/api/search";
import * as util from "../../lib/util";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = StyleSheet.create({
    content: {
        paddingRight: 10,        
        paddingBottom: 80,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
    },
    agencyForm: {
        padding: "0px 80px 0px 80px",
    },
    contentSection: {
        width: "100%",
        padding: 0,
        backgroundColor: color.white,
        borderTop: `8px solid ${color.mediumRed}`,
        marginBottom: 20,
    },
    title: {
        fontSize: 20,
        fontWeight: 500,        
        paddingBottom: 0,
        marginBottom: 4,
    },
    titleWithMargin: {
        fontSize: 20,
        fontWeight: 500,        
        paddingBottom: 0,
        marginTop: 26,
        marginBottom: 14,
    },
    titleSection: {        
        heigth: 64,
    },
    instructions: {
        color: color.darkestGray,
        fontWeight: 300,
        fontSize: 20,
        marginLeft: 24,
    },
    instructionsSmall: {
        color: color.darkestGray,
        fontWeight: 300,
        fontSize: 15,
        padding: "10px 0px",
    },
    dropdown: {
        marginTop: 10,
        marginBottom: 10,
    },
    checkboxContainer: {        
        textAlign: "right",
        marginTop: 12,
    },
    checkLabel: {
        display: "inline-block",
        position: "relative",
        border: "none",
        marginTop: 8,
        marginBottom: 10,
        "-webkit-appearance": "none",
        cursor: "pointer",
        ":first-child": {
            marginRight: 24,
        }
    },
    check: {
        position: "absolute",
        top: -2,
        left: 0,
        marginRight: 10,
    },
    labelText: {
        marginLeft: 28,
        fontSize: 14,
        marginRight: 12,
    },    
    buttonContainer: {
        height: 80,
        width: "100%",
        margin: "60px auto 0px auto",
    },
    buttons: {
        width: "100%",
        textAlign: "center",
    },    
    agencySearch: {
        zIndex: 7000,
    }
});

const strings = {
    title: "Agencies",
    instructions: "Add an agency to the system",    
    instructionsLookup: "Please type the agency name, so we can check our database for existing records",    
    instructionsNameConfirm: "is not in our system.  Would you like to add this agency?",    
    addAgency: "Add an Agency",
    agency: "Agency",
    namePlaceholder: "Agency Name",
    phonePlaceholder: "Phone number",
    streetAddressPlaceholder: "Street Address",
    cityPlaceholder: "City",
    statePlaceholder: "State",
    zipPlaceholder: "Zip",
    continueButton: "Continue",
    submitButton: "Submit",
    cancelButton: "Cancel",
    required: "Please fill out this field",
};

const inputNames = {
    name: "name",
    phone: "phone",
    street1: "street1",
    street2: "street2",
    city: "city",    
    stateId: "stateId",
    postalCode: "postalCode",
};

export default class AgencyForm extends Component {
    constructor(props) {
        super(props);

        this._searchRef = React.createRef();
    }

    static propTypes = {
        history: texchangePropTypes.history.isRequired
    };

    state = {
        inputs: {
            [inputNames.name]: "",
            [inputNames.phone]: "",
            [inputNames.street1]: "",
            [inputNames.street2]: "",
            [inputNames.city]: "",
            [inputNames.stateId]: "",
            [inputNames.postalCode]: "",
        },
        loading: true,
        errors: {},
        states: [],
        didLookup: false,
        didNameConfirm: false,
    };

    componentDidMount() {        
        Promise.all([ dataApi.getStates() ])
            .then(responses => {
                this.setState({
                    loading: false,
                    states: responses[0],
                });
            })
            .catch(err => logger.log(err))
            .finally(() => this.setState({ ready: true }));
    }
     
    _handleTextChange = e => {
        const { name } = e.target;
        let { value } = e.target;

        if (name === inputNames.phone) {
            value = util.maskPhoneNumber(value);
        } else if (name === inputNames.postalCode) {
            value = value.replace(/[^0-9]+/g,"");
            //maxlength of 5
            if (value && value.length === 6) {
                return;
            }
        }

        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
            };
        });
    };

    _handleStateChange = e => {        
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.stateId];
            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.stateId]: e.value,
                },                
                errors,
            };
        });
    };

    _searchResultSelected = (item) => {
        if (item.type === "Agency") {
            this.props.history.push(routeHelpers.agencyDetail(item.value));    
        }
    };

    _goToBrokers = () => {
        this.props.history.push(routeHelpers.brokers());
    };

    _validForm = () => {
        const errors = {};
        const { inputs } = this.state;

        Object.keys(inputs).forEach(stateKey => {
            if (stateKey === inputNames.street2) {
                return;
            }

            if (inputs[stateKey] === null || inputs[stateKey] === "") {
                errors[stateKey] = strings.required;
            }
        });

        this.setState({ errors });

        return !Object.keys(errors).length;
    };

    _submit = e => {
        e.preventDefault();
        if (this.state.loading) {
            return;
        }

        //if you finished the search...
        if (!this.state.didLookup) {
            const agencyName = this._searchRef.current.state.value;
            const regName = /^\s*$/;
            if (!agencyName || agencyName.length === 0 || regName.test(agencyName)) {
                toast.warn("Please enter name", { toastId: 1 });
                return;
            }
            //passed
            this.setState(prevState => {
                return {
                    didLookup: true,
                    inputs: {
                        ...prevState.inputs,
                        [inputNames.name]: agencyName,
                    },                
                };
            });
            return;
        }

        //if you are confirming the name...
        if (!this.state.didNameConfirm) {
            this.setState({
                didNameConfirm: true,
            });
            return;
        }

        //else...  try to save...
        if (!this._validForm()) {
            return;
        }

        this.setState({ isSaving: true });

        const { inputs } = this.state;
        agenciesApi
            .saveAgency(inputs)
            .then(() => {
                toast.success("Agency saved", { toastId: 2 });
                setTimeout(() => {
                    this._goToBrokers();
                }, 2500);
            })
            .catch(err => {
                logger.warn(err);
                let errors = {};
                if (err && err.response) {
                    const { response } = err;
                    if (
                        response &&
                        response.data &&
                        response.data.errors &&
                        Object.keys(response.data.errors).length > 0
                    ) {
                        // Server provided validation messages
                        errors = response.data.errors;
                    } else {
                        // 500 or something else unexpected
                        const description = describeNetworkError(err);
                        toast.error(description.join("\n"), { toastId: 3 });
                    }
                }

                this.setState({ errors });
            })
            .finally(() => this.setState({ isSaving: false }));
    };
    
    render() {  
        if (this.state.loading) {
            return null;
        }
        
        return (
            <div className={css(styles.content)}>
                <ToastContainer position="top-center" autoClose={2500} />
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.titleSection)}>                        
                        <PageTitle>{strings.title}</PageTitle>                        
                    </div>
                    <p className={css(styles.instructions)}>
                        {strings.instructions}
                    </p>
                    <div className={css(styles.agencyForm)}>
                        <form onSubmit={this._submit}>
                            <Container>
                                <Row>
                                    <Column large={6} largeOffset={3}>
                                        <Headline aStyles={styles.title}>{strings.addAgency}</Headline>
                                        {!this.state.didLookup ? (
                                            <div>
                                                <div className={css(styles.instructionsSmall)}>{strings.instructionsLookup}</div>
                                                <SearchInput
                                                    ref={this._searchRef}
                                                    aStyles={styles.searchBox}                                
                                                    search={searchApi.searchAgencies}
                                                    onItemSelected={this._searchResultSelected}
                                                    placeholder="Enter name"
                                                />
                                            </div>
                                        ) : !this.state.didNameConfirm ? (
                                            <div>
                                                <div className={css(styles.instructionsSmall)}>
                                                    <span className={css(styles.title)}>{this.state.inputs[inputNames.name]}</span>
                                                    <br />
                                                    {strings.instructionsNameConfirm}
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <TextInput
                                                    name={inputNames.name}
                                                    onChange={this._handleTextChange}
                                                    placeholder={strings.namePlaceholder}
                                                    required={true}
                                                    validationMessage={this.state.errors[inputNames.name]}
                                                    value={this.state.inputs[inputNames.name]}
                                                    disabled={true}
                                                />
                                                <TextInput
                                                    name={inputNames.phone}
                                                    onChange={this._handleTextChange}
                                                    placeholder={strings.phonePlaceholder}
                                                    required={true}
                                                    validationMessage={this.state.errors[inputNames.phone]}
                                                    value={this.state.inputs[inputNames.phone]}
                                                />
                                                <TextInput
                                                    name={inputNames.street1}
                                                    onChange={this._handleTextChange}
                                                    placeholder={strings.streetAddressPlaceholder}
                                                    required={true}                                            
                                                    validationMessage={this.state.errors[inputNames.street1]}
                                                    value={this.state.inputs[inputNames.street1]}
                                                />
                                                <TextInput
                                                    name={inputNames.street2}
                                                    onChange={this._handleTextChange}
                                                    placeholder={strings.streetAddressPlaceholder}                                                                                                
                                                    value={this.state.inputs[inputNames.street2]}
                                                    required={false}
                                                />
                                                <TextInput
                                                    name={inputNames.city}
                                                    onChange={this._handleTextChange}
                                                    placeholder={strings.cityPlaceholder}
                                                    required={true}                                            
                                                    validationMessage={this.state.errors[inputNames.city]}
                                                    value={this.state.inputs[inputNames.city]}
                                                />
                                                <Dropdown
                                                    name={inputNames.stateId}
                                                    aStyles={styles.dropdown}
                                                    options={Object.keys(this.state.states).map(id => ({
                                                        label: this.state.states[id].longName,
                                                        value: this.state.states[id].id.toString(),
                                                    }))}
                                                    onChange={this._handleStateChange}
                                                    placeholder={strings.statePlaceholder}
                                                    required={true}
                                                    validationMessage={this.state.errors[inputNames.stateId]}
                                                    value={this.state.inputs[inputNames.stateId]}
                                                    hideLabel={true}
                                                />
                                                <TextInput
                                                    name={inputNames.postalCode}
                                                    onChange={this._handleTextChange}
                                                    placeholder={strings.zipPlaceholder}
                                                    required={true}                                            
                                                    validationMessage={this.state.errors[inputNames.postalCode]}
                                                    value={this.state.inputs[inputNames.postalCode]}                                                
                                                />
                                            </div>
                                        )}
                                    </Column>
                                </Row>
                                <Row>
                                    <div className={css(styles.buttonContainer)}>
                                        <Column large={3} largeOffset={3}>
                                            <ActionButton
                                                aStyles={styles.buttons}
                                                disabled={this.state.loading}
                                                label={strings.cancelButton}
                                                invertColor={true}
                                                type="button"
                                                onClick={this._goToBrokers}                                            
                                            />
                                        </Column>
                                        <Column large={3}>
                                            <ActionButton                                    
                                                aStyles={styles.buttons}
                                                disabled={this.state.loading}
                                                label={!this.state.didNameConfirm ? strings.continueButton : strings.submitButton}
                                                type="submit"
                                                isLoading={this.state.isSaving}                                                
                                            />
                                        </Column>
                                    </div>
                                </Row>
                            </Container>
                        </form>
                    </div>
                </div>                
            </div>
        );
    }
}