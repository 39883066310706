import * as color from "../constants/color";
import * as texchangePropTypes from "../texchangePropTypes";
import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    loadingContainer: {        
        display: "flex",
        justifyContent: "center",
        alignItems: "center",        
    },
    loading: {
        width: 24,
        height: 24,   
        marginRight: 6,     
        content: "''",
        backgroundClip: "padding-box",
        borderRadius: 24,
        border: `3px solid ${color.mediumYellow1}`,        
        borderTopColor: color.white,
        animation: "animation-rotate 750ms linear infinite",
    },
});

export default function Spinner(props) {
    const { aStyles, text } = props;
    return (
        <div className={css(styles.loadingContainer, aStyles)}>
            <div className={css(styles.loading)} />
            <div>{text}</div>
        </div>
    );
}

Spinner.propTypes = {
    aStyles: texchangePropTypes.aphroditeStyles,
    text: PropTypes.string,
};
