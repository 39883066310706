export function formatPhoneNumber(phoneNumber) {
    const digits = ("" + phoneNumber).replace(/\D/g, "");
    const m = digits.match(/^(\d{3})(\d{3})(\d{4})$/);
    return !m ? null : m[1] + "-" + m[2] + "-" + m[3];
}

export function maskPhoneNumber(input) {
    const pattern = "999-999-9999";
    if (typeof input === "undefined" || input === null) {
        return "";
    }

    let result = input;
    for (let i = 0; i < pattern.length && i < result.length; i++) {
        if (isNaN(parseInt(pattern[i], 10))) {
            if (result[i] !== pattern[i]) {
                result = result.slice(0, i).concat(pattern[i], result.slice(i));
            }
        } else {
            while (result.length > i && isNaN(parseInt(result[i], 10))) {
                result = result.slice(0, i).concat(result.slice(i + 1));
            }
        }
    }
    result = result.slice(0, pattern.length);

    return result;
}

export function capitalize(text) {
    if (typeof text !== "string") {
        return "";
    } else {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }  
}

export function maskDate(input) {
    if (typeof input === "undefined" || input === null) {
        return "";
    }

    //insert 0s if needed
    if (input.length === 2 && input.endsWith("/")) {
        input = "0" + input;
    }
    else if (input.length === 2 && !input.endsWith("/")) {
        input = input + "/";
    }
    else if (input.length === 5 && input.endsWith("/")) {
        input = input.slice(0,3) + "0" + input.slice(3);
    }
    else if (input.length === 5 && !input.endsWith("/")) {
        input = input + "/";
    }

    let result = input;
    let passed = true;
    for (let i = 0; i < result.length; i++) {
        if (i === 2 || i === 5) {
            //skip the "/" chars
        } else {
            //check if the character is a number
            if (isNaN(parseInt(result[i]))) {
                passed = false;
                break;
            }
        }
    }
    //passed?
    if (passed) {
        return result;
    } else {
        return "";
    }
}
