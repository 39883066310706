import flatten from "lodash.flatten";

const strings = {
    noNetwork: "We were unable to reach the internet. Please verify your connection and try again.",
    serverError: "An unexpected error has occurred. Please wait a minute and try again.",
    genericValidation: "An error has occurred. Please double check the form and try again.",
};

export default function describeNetworkError(err) {
    if (!err.response) {
        return [strings.noNetwork];
    }

    if (err.response.status === 400) {
        if (err.response.data && err.response.data.errors) {
            const strs = Object.values(err.response.data.errors);
            const flattened = flatten(strs);
            if (flattened.length > 0) {
                return flattened;
            }
        }

        return [strings.genericValidation];
    }

    if (err.response.status === 500 && err.response.data && err.response.data.error) {
        return ["An error has occurred. " + err.response.data.error];
    }

    return [strings.serverError];
}
