import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";
import logger from "./logger";
import React from "react";

const dev = process.env.NODE_ENV === "development";

const bugsnagClient = bugsnag({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    appVersion: dev ? "dev" : process.env.REACT_APP_GIT_REVISION,
    beforeSend: report => {
        if (dev) {
            logger.log(`Bugsnag caught error: ${report.errorMessage}`);
            return false;
        }
    },
});

bugsnagClient.use(bugsnagReact, React);
export default bugsnagClient;
