import instance from "./instance";

const responseData = response => response.data;

export function uploadCensus(quoteId, fileData, census) {
    if (fileData) {
        const formData = new FormData();
        formData.append("quoteId", quoteId);
        formData.append("file", fileData);

        return instance
            .request({
                url: "/census/upload-census",
                method: "POST",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then(responseData);
    } else {
        return instance
            .request({
                url: "/census/upload-manual-census",
                method: "POST",
                data: { census },
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then(responseData);
    }
}

export function copyCensus(fromId, toQuoteId) {
    return instance
        .request({
            url: "/census/copy-census",
            method: "POST",
            data: {
                fromId,
                toQuoteId
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function copyCensusU(fromId, toQuoteId) {
    return instance
        .request({
            url: "/census/copy-census-ubenefit",
            method: "POST",
            data: {
                fromId,
                toQuoteId
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function saveCensusMapping(census) {
    return instance
        .request({
            url: "/census/save-census-mapping",
            method: "POST",
            data: { census },
            headers: {
                "Content-Type": "application/json",
            },
        });
}

export function getCensusByQuoteId(quoteId) {
    return instance
        .request({
            url: `/census/getCensusByQuoteId/${quoteId}`,
            method: "GET"
        })
        .then(responseData);
}

export function getCensusExtract(id) {
    return instance
        .request({
            url: `/census/get-census-extract/${id}`,
            method: "POST",
            responseType: "blob",
        })
        .then((response) => {
            if (response.data && response.data.size > 0) {
                if (response.data.type.startsWith("application")) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'RatingHub_Census_For_Quote_' + id + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                }
                else if (response.data.type === "text/plain") {
                    return response.data.text();
                }
            }
            else {
                return "ERROR";
            }
        });
}