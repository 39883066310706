import parse from "date-fns/parse";

export function safeParseDate(str) {
    return str ? parse(str) : null;
}

export function to12HourTime(time24Hour, showMinutes, showAmPm) {
    const arr = time24Hour.split(":");
    const hour = parseInt(arr[0]);
    const minutes = arr[1];
    const hour12 = hour > 12 ? hour - 12 : hour;

    let str = hour12.toString();
    if (showMinutes) {
        str += `:${minutes} `;
    }

    if (showAmPm) {
        str += hour >= 12 ? "pm" : "am";
    }

    return str;
}
