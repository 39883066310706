import * as account from "../../lib/api/account";
import * as breakpoints from "../../constants/breakpoints";
import * as routeHelpers from "../../lib/routeHelpers";
import ActionButton from "../ActionButton";
import describeNetworkError from "../../lib/describeNetworkError";
import Dropdown from "../inputs/Dropdown";
import Headline from "../text/Headline";
import logger from "../../lib/logger";
import React, { Component } from "react";
import SmallLink from "../text/SmallLink";
import TextInput from "../inputs/TextInput";
import { StyleSheet, css } from "aphrodite";
import BodyCopyHighlight from "../text/BodyCopyHighlight";
import Checkbox from "../inputs/Checkbox";
import * as officesApi from "../../lib/api/offices";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = StyleSheet.create({
    loginForm: {
        maxWidth: "500px",
    },
    title: {
        [breakpoints.tabletAndDown]: {
            textAlign: "center",
            marginTop: 14,
            marginBottom: 16,
        },
        [breakpoints.desktopOnly]: {
            marginTop: 128,
            marginBottom: 24,
        },
    },
    buttonContainer: {
        [breakpoints.desktopOnly]: {
            textAlign: "right",
        },
    },
    actionButton: {
        marginTop: 24,

        [breakpoints.tabletAndDown]: {
            width: "100%",
        },
        [breakpoints.desktopOnly]: {
            minWidth: 184,
            width: "100%",
        },
    },
    checkLabel: {
        display: "block",
        position: "relative",
        border: "none",
        marginTop: 8,
        marginBottom: 16,
        "-webkit-appearance": "none",
        textAlign: "right",
    },
    check: {
        position: "absolute",
        top: 0,
        right: 10,
    },
    checkHeadline: {
        fontSize: 15,
        paddingRight: 43,
    },
    spinner: {
        [breakpoints.tabletAndDown]: {
            marginTop: 100,
        },
        [breakpoints.desktopOnly]: {
            marginTop: 140,
        },
    },
    returnButton: {
        marginTop: 32,
        width: "100%",
    },
    emailAddress: {
        fontWeight: 600,
        marginTop: 4,
    }
});

const strings = {
    title: "Request Account",
    subTitle: "Please fill out all the fields below.",
    loginPrompt: "Already have an account?",
    signIn: "Sign In",
    namePlaceholder: "Broker First and Last Name",
    emailPlaceholder: "Email Address",
    officePlaceholder: "Select your Office",
    companyNamePlaceholder: "Agency Name",
    companyZipPlaceholder: "Agency Zip Code",
    cellPhonePlaceholder: "Cell Phone Number",
    submitButton: "Submit",
    checkHeadline: "Check box if Sole Proprietor",
    //submitted
    submittedPageTitle: "Your registration has been successfully submitted!",
    instructions: "Once we have verified your account you will receive an acceptance email with instructions at:",
    returnButton: "Return to Sign In",
};

const inputNames = {
    name: "name",
    email: "email",
    companyName: "companyName",
    companyZip: "companyZip",
    cellPhone: "cellPhone",
    soleProprietor: "soleProprietor",
};

export default class RegisterForm extends Component {
    state = {
        offices: [],
        errors: {},
        loading: false,
        selectedOfficeId: null,
        inputs: {
            [inputNames.name]: "",
            [inputNames.email]: "",
            [inputNames.companyName]: "",
            [inputNames.companyZip]: "",
            [inputNames.cellPhone]: "",
            [inputNames.soleProprietor]: false,
        },
        submitted: false,
    };

    componentDidMount() {
        officesApi.getAll().then(result => {
            this.setState({
                offices: result,
            });
        });
        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    _setOffice = officeId => {
        this.setState({ selectedOfficeId: officeId });
    };

    _handleTextChange = e => {
        const { name, value } = e.target;
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
            };
        });
    };

    _handleInputChange = e => {
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: val,
                },
                errors,
                dirty: true,
            };
        });
    };

    _register = e => {
        e.preventDefault();
        if (this.state.loading) {
            return;
        }

        const name = this.state.inputs[inputNames.name];
        const email = this.state.inputs[inputNames.email];
        const officeId = this.state.selectedOfficeId;
        const companyName = this.state.inputs[inputNames.companyName];
        const companyZip = this.state.inputs[inputNames.companyZip];
        const cellPhone = this.state.inputs[inputNames.cellPhone];
        const soleProprietor = this.state.inputs[inputNames.soleProprietor];

        this.setState({
            loading: true,
            errors: {},
        });

        account
            .register(name, email, officeId, companyName, companyZip, cellPhone, soleProprietor)
            .then(() => {
                this.setState({ loading: false, submitted: true });
            })
            .catch(err => {
                logger.warn(err);
                if (!this._mounted) {
                    return;
                }

                let errors = {};
                if (err && err.response) {
                    const { response } = err;
                    if (
                        response &&
                        response.data &&
                        response.data.errors &&
                        Object.keys(response.data.errors).length > 0
                    ) {
                        // Server provided validation messages
                        errors = response.data.errors;
                    } else {
                        // 500 or something else unexpected
                        const description = describeNetworkError(err);
                        toast.error(description.join("\n"), { toastId: 1 });
                    }
                }

                this.setState({
                    loading: false,
                    errors: errors,
                });
            });
    };

    _renderFormContent = () => {
        return (
            <React.Fragment>
                <TextInput
                    name={inputNames.name}
                    onChange={this._handleTextChange}
                    placeholder={strings.namePlaceholder}
                    required={true}
                    validationMessage={this.state.errors[inputNames.name]}
                    value={this.state.inputs[inputNames.name]}
                />

                <label className={css(styles.checkLabel)}>
                    <Checkbox
                        checked={this.state.inputs[inputNames.soleProprietor]}
                        aStyles={styles.check}
                        name={inputNames.soleProprietor}
                        onChange={this._handleInputChange}
                    />
                    <BodyCopyHighlight aStyles={styles.checkHeadline}>
                        {strings.checkHeadline}
                    </BodyCopyHighlight>
                </label>

                <Dropdown
                    options={Object.keys(this.state.offices).map(id => ({
                        label: this.state.offices[id].name,
                        value: this.state.offices[id].id.toString(),
                    }))}
                    onChange={this._setOffice}
                    placeholder={strings.officePlaceholder}
                    value={this.state.selectedOfficeId}
                />

                <TextInput
                    name={inputNames.companyName}
                    onChange={this._handleTextChange}
                    placeholder={strings.companyNamePlaceholder}
                    required={true}
                    validationMessage={this.state.errors[inputNames.companyName]}
                    value={this.state.inputs[inputNames.companyName]}
                />

                <TextInput
                    name={inputNames.companyZip}
                    onChange={this._handleTextChange}
                    placeholder={strings.companyZipPlaceholder}
                    type="postal-code"
                    required={true}
                    validationMessage={this.state.errors[inputNames.companyZip]}
                    value={this.state.inputs[inputNames.companyZip]}
                />

                <TextInput
                    name={inputNames.cellPhone}
                    onChange={this._handleTextChange}
                    placeholder={strings.cellPhonePlaceholder}
                    type="tel"
                    required={true}
                    validationMessage={this.state.errors[inputNames.cellPhone]}
                    value={this.state.inputs[inputNames.cellPhone]}
                />

                <TextInput
                    name={inputNames.email}
                    onChange={this._handleTextChange}
                    placeholder={strings.emailPlaceholder}
                    type="email"
                    required={true}
                    validationMessage={this.state.errors[inputNames.email]}
                    value={this.state.inputs[inputNames.email]}
                />

                <div className={css(styles.buttonContainer)}>
                    <ActionButton
                        aStyles={styles.actionButton}
                        disabled={this.state.loading}
                        label={strings.submitButton}
                        type="submit"
                    />
                </div>
            </React.Fragment>
        );
    };

    render() {
        return (
            !this.state.submitted ? (
                <form className={css(styles.loginForm)} onSubmit={this._register}>
                    <ToastContainer position="top-center" autoClose={2500} />
                    <Headline aStyles={styles.title}>{strings.title}</Headline>
                    <div>{strings.subTitle}<br />{strings.loginPrompt}{" "}
                        <SmallLink to={routeHelpers.login()}>
                            {strings.signIn}
                        </SmallLink>
                    </div>
                    {this._renderFormContent()}
                </form>
            ) : (
                <div className={css(styles.loginForm)}>
                    <Headline aStyles={styles.title}>{strings.submittedPageTitle}</Headline>
                    <div>{strings.instructions}</div>
                    <div className={css(styles.emailAddress)}>{this.state.inputs[inputNames.email]}</div>
                    <ActionButton
                        aStyles={styles.returnButton}
                        disabled={this.state.loading}
                        label={strings.returnButton}
                        href={routeHelpers.login()}
                    />
                </div>
            )
        );
    }
}