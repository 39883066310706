const carriers = [{
        id: 37,
        name: "Aetna",
    }, {
        id: 16,
        name: "All Savers",
    }, {
        id: 40,
        name: "Florida Blue"
    }, {
        id: 3,
        name: "Humana",
    }, {
        id: 1,
        name: "United Healthcare"
    },   
];

export default carriers;