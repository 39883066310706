import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import InputGroup from "./InputGroup";
import ActionButton from "../ActionButton";
import Headline from "../text/Headline";
import TextInput from "../inputs/TextInput";
import Dropdown from "../inputs/Dropdown";
import * as enrollmentApi from "../../lib/api/enrollment";
import * as enrollmentActions from "../../actions/enrollmentActions";

const styles = StyleSheet.create({
    buttonContainer: {
        marginTop: 24,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    headline: {
        fontSize: 20,
        fontWeight: 500,
        marginBottom: 8,
        paddingBottom: 0,
    },
    backButton: {
        flexGrow: 1,
        marginRight: 16,
        flexBasis: 0,
    },
    continueButton: {
        flexGrow: 1,
        marginLeft: 16,
        flexBasis: 0,
    },
    instructions: {
        fontSize: 14,
    },
});

const inputNames = {
    qualifyingEventEmails: "qualifyingEventEmails",
    newHireRehireEnrollmentEmails: "newHireRehireEnrollmentEmails",
    planEligibilityChangesEmails: "planEligibilityChangesEmails",
    employeeOnboardingEmails: "employeeOnboardingEmails",
}

export class CarrierForms extends Component {
    static propTypes = {
        onContinue: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        enrollmentPortal: PropTypes.object.isRequired,
        isEditable: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        const { enrollmentPortal } = this.props;

        this.state = {
            inputs: {
                [inputNames.qualifyingEventEmails]: enrollmentPortal.qualifyingEventEmails ? enrollmentPortal.qualifyingEventEmails : "",
                [inputNames.newHireRehireEnrollmentEmails]: enrollmentPortal.newHireRehireEnrollmentEmails ? enrollmentPortal.newHireRehireEnrollmentEmails : "",
                [inputNames.planEligibilityChangesEmails]: enrollmentPortal.planEligibilityChangesEmails ? enrollmentPortal.planEligibilityChangesEmails : "",
                [inputNames.employeeOnboardingEmails]: enrollmentPortal.employeeOnboardingEmails ? enrollmentPortal.employeeOnboardingEmails : "",
            },
            carrierForms: {
                medicalCarrierForms: [],
                dentalCarrierForms: [],
                visionCarrierForms: [],
            },
            isSaving: false,
            errors: [],
        };
    }

    componentDidMount() {
        const { enrollmentPortal } = this.props;

        enrollmentApi.getCarrierForms(enrollmentPortal.id)
            .then(result => {
                this.setState({
                    carrierForms: result,
                });
            });
    }

    _handleTextChanged = e => {
        const { name, value } = e.target;

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
                errors,
            };
        });
    };

    _handleDropdownChanged = selectedItem => {
        const { name, value } = selectedItem;

        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
            };
        });
    };

    _submit = e => {
        e.preventDefault();

        if (!this.props.isEditable) {
            this.props.onContinue();
            return;
        }

        const { enrollmentPortal } = this.props;

        Object.keys(inputNames).forEach(key => {
            enrollmentPortal[key] = this.state.inputs[key];
        });

        this.setState({
            isSaving: true,
        });

        enrollmentApi.saveEnrollmentPortal(enrollmentPortal)
            .then(response => {
                this.props.updateEnrollmentPortal(enrollmentPortal);
                this.props.onContinue();
            })
            .finally(() => {
                this.setState({
                    isSaving: true,
                });
            });
    };

    render() {
        const { isEditable } = this.props;
        return (
            <form onSubmit={this._submit}>
                <InputGroup>
                    <Headline aStyles={styles.headline}>Notification Recipient Emails</Headline>
                    <div className={css(styles.instructions)}>
                        <p>
                            An email notification will be sent from UBenefit
                            (do_not_reply@ubenefit.net) when these changes occur in the UBenefit Portal.
                        </p>
                        <p>
                            Separate email addresses with a semi-colon ( ; )
                        </p>
                    </div>
                    <TextInput
                        name={inputNames.qualifyingEventEmails}
                        onChange={this._handleTextChanged}
                        placeholder="Qualifying Event"
                        validationMessage={this.state.errors[inputNames.qualifyingEventEmails]}
                        value={this.state.inputs[inputNames.qualifyingEventEmails]}
                        disabled={!isEditable}
                    />
                    <TextInput
                        name={inputNames.newHireRehireEnrollmentEmails}
                        onChange={this._handleTextChanged}
                        placeholder="New Hire/Rehire Enrollment"
                        validationMessage={this.state.errors[inputNames.newHireRehireEnrollmentEmails]}
                        value={this.state.inputs[inputNames.newHireRehireEnrollmentEmails]}
                        disabled={!isEditable}
                    />
                    <TextInput
                        name={inputNames.planEligibilityChangesEmails}
                        onChange={this._handleTextChanged}
                        placeholder="Plan Eligibility Changes"
                        validationMessage={this.state.errors[inputNames.planEligibilityChangesEmails]}
                        value={this.state.inputs[inputNames.planEligibilityChangesEmails]}
                        disabled={!isEditable}
                    />
                    <TextInput
                        name={inputNames.employeeOnboardingEmails}
                        onChange={this._handleTextChanged}
                        placeholder="Employee Onboarding"
                        validationMessage={this.state.errors[inputNames.employeeOnboardingEmails]}
                        value={this.state.inputs[inputNames.employeeOnboardingEmails]}
                        disabled={!isEditable}
                    />
                </InputGroup>
                <InputGroup>
                    <div className={css(styles.buttonContainer)}>
                        <ActionButton
                            aStyles={styles.backButton}
                            label="Back"
                            invertColor={true}
                            type="button"
                            onClick={this.props.onCancel}
                        />
                        <ActionButton
                            aStyles={styles.continueButton}
                            label="Continue"
                            type="submit"
                            isLoading={this.state.isSaving}
                        />
                    </div>
                </InputGroup>
            </form>
        );
    }
}

const mapStateToProps = state => ({
    enrollmentPortal: state.enrollment.enrollmentPortal,
    isEditable: state.enrollment.isEditable,
});

export default connect(
    mapStateToProps, {
    updateEnrollmentPortal: enrollmentActions.updateEnrollmentPortal,
}
)(CarrierForms);
