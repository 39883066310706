import * as color from "../constants/color";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    stepsLabelContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    steps: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    stepNumberContainer: {
        border: `2px solid ${color.mediumRed}`,
        height: 34,
        width: 34,
        textAlign: "center",
        borderRadius: 20,
        lineHeight: "30px",
    },
    stepNumberContainerGray: {
        border: `2px solid ${color.lightGray2}`,
        height: 34,
        width: 34,
        textAlign: "center",
        borderRadius: 20,
        lineHeight: "30px",
    },
    stepNumber: {
        fontSize: 16,
        fontWeight: 500,
        textAlign: "center",
        color: color.mediumRed,
        verticalAlign: "middle",
        height: 34,
    },
    separator: {
        backgroundColor: color.lightGray2,
        height: 3,
        width: 72,
    },
    stepLabel: {
        color: color.darkGray,
        fontSize: 13,
        marginBottom: 10,
        width: 52,
        textAlign: "center",
    },
    stepCenterLabel: {
        marginLeft: 56,
        marginRight: 56,
    },
    greenCheck: {
        display: "inline-block",
        transform: "rotate(45deg)",
        width: 7,
        height: 15,
        borderBottom: `2px solid ${color.mediumRed}`,
        borderRight: `2px solid ${color.mediumRed}`,
    },
    headerSectionLabel: {
        textAlign: "center",
        color: color.darkestGray,
        fontSize: 26,
        marginTop: 10,
        paddingBottom: 15,
    },
    subStepLabel: {
        textAlign: "left",
        color: color.darkestGray,
        fontWeight: 300,
        fontSize: 20,
        marginTop: 6,
        marginLeft: 24,
        marginBottom: 4,
        height: 23,
    },
    bottomBorder: {
        paddingBottom: 15,
        borderBottom: `5px solid ${color.mainBG}`,
    },
});

const strings = {
    groupStep: "Group",
    censusStep: "Census",
    proposalStep: "Proposal",
    groupHeader: "Find or Create a Group",
    censusHeader: "Census",
    proposalHeader: "Proposal Builder",
    proposalSubHeader0: "",
    proposalSubHeader1: "Make your current plan selections.",
    proposalSubHeader2: "Make your renewal plan selections.",
    proposalSubHeader3: "Make your new plan selections. If you are quoting new plans from your current Carrier, please verify the rates from the actual renewal.",
    proposalSubHeader4: "Create single or multi-plan spreadsheets.",
    proposalSubHeader5: "Choose your output options or load a proposal."
}

const stepHeaders = [
    strings.groupHeader,
    strings.censusHeader,
    strings.proposalHeader
];

const proposalSubStepHeaders = [
    strings.proposalSubHeader0,
    strings.proposalSubHeader1,
    strings.proposalSubHeader2,
    strings.proposalSubHeader3,
    strings.proposalSubHeader4,
    strings.proposalSubHeader5
];

export default class QuoteSteps extends Component {
    static propTypes = {
        step: PropTypes.number.isRequired,
        subStep: PropTypes.number,
    };

    componentDidMount() {

    }

    _renderStepItem = stepNumber => {
        const { step } = this.props;

        if (step > stepNumber) {
            return (
                <div className={css(styles.greenCheck)} />
            );
        } else {
            return (
                <div className={css(styles.stepNumber)}>{stepNumber + 1}</div>
            );
        }
    }

    render() {
        const { step } = this.props;
        return (
            <div className={css(step < 2 ? styles.bottomBorder : null)}>
                <div className={css(styles.headerSectionLabel)}>
                    {stepHeaders[this.props.step]}
                </div>
                <div className={css(styles.stepsLabelContainer)}>
                    <div className={css(styles.stepLabel)}>
                        {strings.groupStep}
                    </div>
                    <div className={css(styles.stepLabel, styles.stepCenterLabel)}>
                        {strings.censusStep}
                    </div>
                    <div className={css(styles.stepLabel)}>
                        {strings.proposalStep}
                    </div>
                </div>
                <div className={css(styles.steps)}>
                    <div className={css(step === 0 ? styles.stepNumberContainer : styles.stepNumberContainerGray)}>
                        {this._renderStepItem(0)}
                    </div>
                    <div className={css(styles.separator)} />
                    <div className={css(step === 1 ? styles.stepNumberContainer : styles.stepNumberContainerGray)}>
                        {this._renderStepItem(1)}
                    </div>
                    <div className={css(styles.separator)} />
                    <div className={css(step === 2 ? styles.stepNumberContainer : styles.stepNumberContainerGray)}>
                        {this._renderStepItem(2)}
                    </div>
                </div>
                {step === 2 &&
                    <div className={css(styles.subStepLabel)}>
                        {proposalSubStepHeaders[this.props.subStep]}
                    </div>
                }
            </div>
        );
    }
}                    