import { StyleSheet, css } from "aphrodite";
import * as color from "../../../constants/color";
import * as texchangePropTypes from "../../../texchangePropTypes";
import BodyCopyHighlight from "../../text/BodyCopyHighlight";
import PropTypes from "prop-types";
import React, { Component } from "react";

const styles = StyleSheet.create({
    option: {
        cursor: "pointer",
        border: "none",
        borderBottom: `1px solid ${color.lightGray2}`,
        alignSelf: "stretch",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: color.transparent,
        textAlign: "left",
        paddingLeft: 16,
        paddingRight: 16,
        outline: 0,
        minHeight: 34,
        ":hover": {
            backgroundColor: color.mediumRed,
            color: color.white,
        },
        ":hover > span": {
            color: color.white,
        }
    },
    disabled: {
        cursor: "not-allowed",
        ":hover": {
            backgroundColor: color.transparent,
        },
    },
    disabledText: {
        color: color.darkestBlueAlpha40,
    },
    text: {
        lineHeight: "34px",
        fontSize: 14,
        fontWeight: 400,
        maxWidth: "100%",
        color: color.darkestGray,
        ":hover": {
            color: color.white,
        },
    },
});

export default class DropdownRow extends Component {
    static propTypes = {
        aStyles: texchangePropTypes.aphroditeStyles,
        index: PropTypes.number.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
        label: PropTypes.string.isRequired,
        extraLabel: PropTypes.string,
        disabled: PropTypes.bool,
        onSelect: PropTypes.func.isRequired,
    };

    _onSelect = () => this.props.onSelect(this.props.value, this.props.index);

    render() {
        return (
            <button
                className={css(
                    styles.option,
                    this.props.disabled ? styles.disabled : null,
                    this.props.aStyles,
                )}
                type="button"
                onClick={this._onSelect}
                disabled={this.props.disabled}
            >
                <BodyCopyHighlight
                    aStyles={[styles.text, this.props.disabled ? styles.disabledText : null]}
                >
                    {this.props.label}
                </BodyCopyHighlight>
                {this.props.extraLabel ? (
                    <BodyCopyHighlight
                        aStyles={[styles.text, this.props.disabled ? styles.disabledText : null]}
                    >
                        {this.props.extraLabel}
                    </BodyCopyHighlight>
                ) : null}
            </button>
        );
    }
}
