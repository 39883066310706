import * as account from "../lib/api/account";
import * as actionTypes from "../constants/actionTypes";
import * as dates from "../lib/dates";
import * as storage from "../lib/storage";
import isValid from "date-fns/is_valid";
import logger from "../lib/logger";

const storageKey = "auth";

function serializeAuth(data) {
    return JSON.stringify({
        accessToken: data.accessToken,
        expireDate: data.expireDate,
        refreshToken: data.refreshToken,
        scopes: data.scopes,
    });
}

function deserializeAuth(str) {
    const temp = JSON.parse(str);
    return {
        accessToken: temp.accessToken,
        expireDate: dates.safeParseDate(temp.expireDate),
        refreshToken: temp.refreshToken,
        scopes: temp.scopes,
    };
}

function isValidAuth(auth) {
    return (
        !!auth &&
        !!auth.accessToken &&
        !!auth.expireDate &&
        isValid(auth.expireDate) &&
        !!auth.refreshToken
    );
}

function transformApiData(data) {
    return {
        accessToken: data.accessToken,
        expireDate: new Date(Date.now() + data.expiresIn * 1000),
        refreshToken: data.refreshToken,
        scopes: data.roles,
    };
}

function signInImmediate(accessToken, expireDate, refreshToken, scopes) {
    return {
        type: actionTypes.SESSION_SIGN_IN,
        accessToken,
        expireDate,
        refreshToken,
        scopes,
    };
}

function signOutImmediate() {
    return {
        type: actionTypes.SESSION_SIGN_OUT,
    };
}

export function storeAndSignIn(apiAuth) {
    return dispatch => {
        const transformed = transformApiData(apiAuth);
        const value = serializeAuth(transformed);
        storage.setItem(storageKey, value);

        return dispatch(
            signInImmediate(
                transformed.accessToken,
                transformed.expireDate,
                transformed.refreshToken,
                transformed.scopes,
            ),
        );
    };
}

export function updateMe(me) {
    return {
        type: actionTypes.SESSION_UPDATE_ME,
        me,
    };
}

function setMe(me) {    
    return {
        type: actionTypes.SESSION_SET_ME,
        me,        
    };
}

export function loadProfile() {
    return dispatch => {
        return account.me().then(data => {            
            dispatch(setMe(data));
        });
    };
}

export function signOut() {
    return (dispatch, getState) => {
        const refreshToken = getState().session.refreshToken;
        storage.removeItem(storageKey);
        dispatch(signOutImmediate());
        if (refreshToken) {
            return account.logout(refreshToken).catch(err => logger.warn(err));
        }
    };
}

export function signIn(email, password, code) {
    return dispatch => {
        return account.login(email, password, code).then(data => {
            if (data) {
                dispatch(storeAndSignIn(data));
                return dispatch(loadProfile());
            }
        });
    };
}

export function loadSavedAuth() {
    return async dispatch => {
        const authStr = storage.getItem(storageKey);
        if (!authStr) {
            return;
        }

        const auth = deserializeAuth(authStr);
        if (isValidAuth(auth)) {
            dispatch(
                signInImmediate(auth.accessToken, auth.expireDate, auth.refreshToken, auth.scopes),
            );
        } else {
            storage.removeItem(storageKey);
        }

        await dispatch(loadProfile());
    };
}
