import * as color from "../constants/color";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import React, { Component } from 'react';
import Tab from './Tab';
import * as texchangePropTypes from "../texchangePropTypes";
import backCircle from "../images/SVGs/BackCircle.svg";

const styles = StyleSheet.create({
    tabs: {
        backgroundColor: color.white,
        paddingTop: 10,
    },
    tabList: {
        borderBottom: `2px solid ${color.mediumRed}`,
        marginBottom: 0,
        paddingLeft: 0,
        listStyleType: "none",
    },
    backButtonContainer: {
        float: "left",
    },
    backButtonImage: {
        display: "block",
    },
    backButton: {
        position: "relative",
        top: 4,
        cursor: "pointer",
        border: "none",
        backgroundColor: "transparent",
        display: "block",
        marginLeft: 12,
        marginRight: 16,
        outline: "none",
    },
    hide: {
        display: "none",
    }
});

export default class Tabs extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Array).isRequired,
        history: texchangePropTypes.history,
        backUrl: PropTypes.string,
        isVisible: PropTypes.bool,
        tabChanged: PropTypes.func,
        currentTab: PropTypes.string,
    };

    static defaultProps = {
        isVisible: true,
    };

    constructor(props) {
        super(props);
        let activeTab = null;
        this.props.children.forEach(item => {
            if (activeTab === null && item.props && item.props.label) {
                activeTab = item.props.label;
            }
        });
        this.state = {
            activeTab: activeTab,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentTab !== this.props.currentTab && this.props.currentTab !== this.state.activeTab && this.props.currentTab) {
            this.setState({
                activeTab: this.props.currentTab,
            })
        }
    }
    _onClickTabItem = tab => {
        const { tabChanged } = this.props;
        if (tabChanged) {
            tabChanged(tab);
        }
        
        this.setState({ activeTab: tab });
    };

    _onBackButtonClick = () => {
        this.props.history.push(this.props.backUrl);
    };
   
    render() {
        const {
            props: {
                children,
            },
            state: {
                activeTab,
            }
        } = this;

        let validTabs = [];
        children.forEach(item => {
            if (item.props && item.props.label && item.props.label !== ".") {
                validTabs.push(item);
            }
        });
        
        const { backUrl, history } = this.props;        
        return ( 
            <div className={css(
                styles.tabs,
                this.props.isVisible ? null : styles.hide
            )}>
                <ol className={css(styles.tabList)}>
                    {(backUrl && history) &&
                        <li className={css(styles.backButtonContainer)}>
                            <button className={css(styles.backButton)} type="button" onClick={this._onBackButtonClick}>
                                <img src={backCircle} className={css(styles.backButtonImage)} alt="back" />
                            </button>
                        </li>
                    }
                    
                    {validTabs.map((child, index) => {
                        const { label, disabled, count } = child.props;

                        return (
                            <Tab
                                activeTab={activeTab}
                                key={label}
                                label={label}
                                data-index={index}
                                onClick={this._onClickTabItem}
                                disabled={disabled}
                                count={count}
                            />
                        );
                    })}
                </ol>
                <div>
                    {validTabs.map(child => {
                        return (
                            <div className={css(child.props.label !== activeTab ? styles.hide : null)} key={child.props.label}>
                                {child.props.children}
                            </div>
                        )
                                                
                    })}
                </div>
            </div>
        );
    }
}