import * as color from "../../constants/color";
import * as routeHelpers from "../../lib/routeHelpers";
import * as texchangePropTypes from "../../texchangePropTypes";
import SearchInput from "../inputs/SearchInput";
import PageTitle from "../text/PageTitle";
import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import Tabs from "../Tabs";
import Infinite from "../Infinite";
import * as agenciesApi from "../../lib/api/agencies";
import * as brokersApi from "../../lib/api/brokers";
import * as searchApi from "../../lib/api/search";


const styles = StyleSheet.create({
    content: {
        paddingRight: 10,
        paddingBottom: 80,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
    },
    contentSection: {
        width: "100%",
        padding: 0,
        backgroundColor: color.white,
        borderTop: `8px solid ${color.mediumRed}`,
        marginBottom: 20,
    },
    titleSection: {
        heigth: 64,
        backgroundColor: color.white,
    },
    searchBox: {
        maxWidth: 480,
        textAlign: "center",
        margin: "30px auto 30px auto",
    },
    right: {
        float: "right",
        position: "relative",
        top: -38,
        right: 20,
        fontSize: 14,
        color: color.tableText,
    },
    addBroker: {
        float: "right",
        cursor: "pointer",
        marginTop: 8,
        marginRight: 24,
    },
    addBrokerText: {
        display: "inline-block",
        fontSize: 14,
    },
    blueCircle: {
        paddingTop: 6,
        marginLeft: 12,
        position: "relative",
        top: 4,
    },
    noResults: {
        backgroundColor: color.white,
        color: color.darkGray,
        padding: 20,
        marginBottom: 40,
    },
});

const strings = {
    title: "Brokers & Agencies",
    searchPlaceholder: "Search by Broker, Agency or Group",
    addBroker: "New Broker",
    addAgency: "New Agency",
};

const inputNames = {
    search: "search",
};

export default class BrokersScreen extends Component {
    static propTypes = {
        history: texchangePropTypes.history.isRequired,
    };

    state = {
        allBrokers: [],
        allBrokersCount: 0,
        allBrokersMore: false,
        brokerColumnLabels: ["Broker Name", "Type", "Agency", "Phone #", "Email", "Groups", "Last Login", "Rating Hub", "Other Offices"],
        brokerColumnValues: ["fullName", "brokerType", "agencyName", "phone", "emailAddress", "groupCount", "lastLogin", "ratingEnginePermission", "otherOffices"],
        allAgencies: [],
        allAgenciesCount: 0,
        allAgenciesMore: false,
        agencyColumnLabels: ["Agency", "City", "Phone #", "# of brokers/users", "Groups"],
        agencyColumnValues: ["name", "city", "phone", "brokerCount", "groupCount"],
        inputs: {
            [inputNames.search]: "",
        },
        loading: false,
        errors: {},
    };

    componentDidMount() {
        this.setState({ loading: true });
        Promise.all([brokersApi.getPaged(1, null, null), agenciesApi.getPaged(1, null, null)])
            .then(responses => {
                if (responses && responses[0] && responses[1]) {
                    this.setState({
                        loading: false,
                        allBrokers: responses[0],
                        allBrokersCount: responses[0][0] ? responses[0][0].recordCount : 0,
                        allBrokersMore: responses[0][0] ? responses[0][0].recordCount > 100 : false,
                        allAgencies: responses[1],
                        allAgenciesCount: responses[1][0] ? responses[1][0].recordCount : 0,
                        allAgenciesMore: responses[1][0] ? responses[1][0].recordCount > 100 : false,
                    });
                }
            });
    }

    _handleTextChange = e => {
        const { name, value } = e.target;
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
            };
        });
    };

    _searchResultSelected = (item) => {
        if (item.type === "Employer") {
            this.props.history.push(routeHelpers.groupDetail(item.value));
        } else if (item.type === "Broker") {
            this.props.history.push(routeHelpers.brokerDetail(item.value));
        } else if (item.type === "Agency") {
            this.props.history.push(routeHelpers.agencyDetail(item.value));
        }
    };

    _handleBrokerRowClick = id => {
        this.props.history.push(routeHelpers.brokerDetail(id));
    };

    _handleAgencyRowClick = id => {
        this.props.history.push(routeHelpers.agencyDetail(id));
    };

    _allBrokersScroll = (page, col, dir) => {
        if (((page - 1) * 100) < this.state.allBrokersCount) {
            brokersApi.getPaged(page, col, dir).then(result => {
                let joined = {};
                if (page === 1) {
                    joined = result;
                } else {
                    joined = this.state.allBrokers.concat(result);
                }
                this.setState({
                    allBrokers: joined,
                });
            });
        }
        else {
            this.setState({
                allBrokersMore: false,
            });
        }
    };

    _allAgenciesScroll = (page, col, dir) => {
        if (((page - 1) * 100) < this.state.allAgenciesCount) {
            agenciesApi.getPaged(page, col, dir).then(result => {
                let joined = {};
                if (page === 1) {
                    joined = result;
                } else {
                    joined = this.state.allAgencies.concat(result);
                }
                this.setState({
                    allAgencies: joined,
                });
            });
        }
        else {
            this.setState({
                allAgenciesMore: false,
            });
        }
    };

    renderTable = (what) => {
        let brokers = what ? this.state.allBrokers : this.state.allAgencies;
        let count = what ? this.state.allBrokersCount : this.state.allAgenciesCount;
        let countTitle = what ? "Brokers" : "Agencies";
        let more = what ? this.state.allBrokersMore : this.state.allAgenciesMore;
        let fnScroll = what ? this._allBrokersScroll : this._allAgenciesScroll;
        let columnLabels = what ? this.state.brokerColumnLabels : this.state.agencyColumnLabels;
        let columnValues = what ? this.state.brokerColumnValues : this.state.agencyColumnValues;
        let handleRowClick = what ? this._handleBrokerRowClick : this._handleAgencyRowClick;
        return (
            <div>
                <div className={css(styles.right)}>{parseInt(count).toLocaleString()}{" "}{countTitle}</div>
                {brokers.length > 0 ? (
                    <Infinite
                        results={brokers}
                        length={brokers.length}
                        columnLabels={columnLabels}
                        columnValues={columnValues}
                        showChevron={true}
                        showMore={more}
                        onClick={handleRowClick}
                        onScroll={fnScroll} />
                ) : (
                    <div className={css(styles.noResults)}>{this.state.loading ? "Loading " : "No Matching "}{countTitle}</div>
                )}
            </div>
        );
    }

    /*_goToAddBroker = () => {
        this.props.history.push(routeHelpers.editBroker());
    };

    _goToAddAgency = () => {
        this.props.history.push(routeHelpers.editAgency());
    };*/

    render() {
        return (
            <div className={css(styles.content)}>
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.titleSection)}>
                        {/*<div className={css(styles.addBroker)} onClick={this._goToAddAgency}>
                            <span className={css(styles.addBrokerText)}>{strings.addAgency}</span>
                            <img alt="" src={blueCircleIcon} className={css(styles.blueCircle)} />
                        </div>
                        <div className={css(styles.addBroker)} onClick={this._goToAddBroker}>
                            <span className={css(styles.addBrokerText)}>{strings.addBroker}</span>
                            <img alt="" src={blueCircleIcon} className={css(styles.blueCircle)} />
                        </div>*/}
                        <PageTitle>{strings.title}</PageTitle>
                    </div>
                    <div className={css(styles.brokers)}>
                        <div>
                            <SearchInput
                                aStyles={styles.searchBox}
                                search={searchApi.searchEmployersBrokersAndAgencies}
                                onItemSelected={this._searchResultSelected}
                                placeholder={strings.searchPlaceholder}
                                hideLabel={true}
                            />
                        </div>
                        <Tabs>
                            <div label="Brokers">
                                {this.renderTable(1)}
                            </div>
                            <div label="Agencies">
                                {this.renderTable(0)}
                            </div>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}
