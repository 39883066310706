import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import * as texchangePropTypes from "../../texchangePropTypes";
import * as medicalPlansApi from "../../lib/api/medicalPlans";

const styles = StyleSheet.create({
    sbcForm: {
        maxWidth: "500px",
    },
    message: {
        fontSize: 20,
        fontWeight: 500,
        padding: 40,
    },

});

export default class SBCForm extends Component {
    static propTypes = {
        match: texchangePropTypes.match.isRequired,
    };

    state = {
        loading: false,
        message: 'Loading...',
    };
    
    componentDidMount() {
        this.setState({ loading: true });
        medicalPlansApi.getSBC(this.props.match.params.id).then(result => {
            this.setState({ loading: false });
            if (result && result.success) {
                this.setState({ message: 'Redirecting...' });
                setTimeout(() => {
                    window.location.href = result.url;
                }, 1500);
            } else {
                this.setState({ message: 'SBC not available.' });
            }
        });
        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    render() {
        return (
            <form className={css(styles.sbcForm)}>
                <div className={css(styles.message)}>{this.state.message}</div>
            </form>
        );
    }
}
