import { matchPath } from "react-router";
import { NavLink } from "react-router-dom";
import { StyleSheet, css } from "aphrodite/no-important";
import * as breakpoints from "../constants/breakpoints";
import * as color from "../constants/color";
import * as texchangePropTypes from "../texchangePropTypes";
import * as routeHelpers from "../lib/routeHelpers";
import * as windowSize from "../lib/windowSize";
import * as zIndexes from "../constants/zIndexes";
import CloseIcon from "./CloseIcon";
import Overlay from "./Overlay";
import PropTypes from "prop-types";
import React, { Component } from "react";

import { ReactComponent as AccountIcon } from "../images/nav-account.svg";
import { ReactComponent as BrokersIcon } from "../images/nav-brokers.svg";
import { ReactComponent as DashboardIcon } from "../images/nav-dashboard.svg";
import { ReactComponent as CarriersIcon } from "../images/nav-carriers.svg";
import { ReactComponent as GroupsIcon } from "../images/nav-groups.svg";
import { ReactComponent as PersonnelIcon } from "../images/nav-personnel.svg";
import { ReactComponent as QuotesIcon } from "../images/nav-quotes.svg";
import { ReactComponent as SupportIcon } from "../images/nav-support.svg";
import { ReactComponent as PlansIcon } from "../images/nav-plans.svg";
import { ReactComponent as FavoritesIcon } from "../images/nav-favorite.svg";

const DESKTOP_NAV_WIDTH = 105;
const styles = StyleSheet.create({
    overlay: {
        zIndex: 1,
        backgroundColor: color.whiteAlpha90,
    },
    closeButton: {
        position: "absolute",
        top: 0,
        right: 0,
        pointerEvents: "none",
        margin: 16,
    },
    navigationContainer: {
        backgroundColor: color.white,

        [breakpoints.desktopOnly]: {
            width: DESKTOP_NAV_WIDTH,
        },
        [breakpoints.tabletAndDown]: {
            pointerEvents: "none",
            position: "absolute",
            top: 0,
            bottom: 0,
            height: "100vh",
            width: "100vw",
            right: 0,
            left: 0,
        },
    },
    contents: {
        pointerEvents: "auto",
        zIndex: zIndexes.TAB_NAVIGATOR,
        position: "fixed",
        top: 100,
        bottom: 0,
        width: 100,
        backgroundColor: color.white,
    },
    mobileOpen: {
        [breakpoints.tabletAndDown]: {
            transform: "translate(0)",
            pointerEvents: "auto",
        },
    },
    links: {
        zIndex: 3,
        position: "relative",
    },
    linksContainerHover: {
        [breakpoints.desktopOnly]: {
            transform: `translate(0px)`,
        },
    },
    navItem: {
        display: "block",
        textDecoration: "none",
        borderBottom: `solid 1px ${color.darkGray2}`,
        [breakpoints.desktopOnly]: {
            height: 65,
            width: "100%",
        },
        [breakpoints.tabletAndDown]: {
            height: 32,
            width: 48,
        },
    },
    activeIcon: {
        [breakpoints.desktopOnly]: {
            height: 65,
            width: 96,
        },
        [breakpoints.tabletAndDown]: {
            height: 32,
            width: 48,
        },
    },
    profileButton: {
        color: color.darkRedAlpha80,
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.26,
        textAlign: "left",
        paddingLeft: 24,
        paddingTop: 16,
        paddingBottom: 16,
        border: "none",
        backgroundColor: color.transparent,
        marginBottom: 16,
    },
    label: {
        color: color.darkGray,
        fontSize: 12,
        textDecoration: "none",
        textAlign: "center",
        position: "relative",
        top: 16,
    },
    icon: {
        fill: color.darkGray7,
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        position: "relative",
        top: 12,
    },
    hover: {
        backgroundColor: color.mediumRed,
        fill: color.white,
        color: color.white,
    },
    active: {
        backgroundColor: color.darkRed,
        color: color.white,
        fill: color.white,
    },
});

const navLinks = [
    {
        to: routeHelpers.userDashboard(),
        label: "Dashboard",
    },
    {
        to: routeHelpers.account(),
        label: "Account",
    },
    {
        to: routeHelpers.brokers(),
        label: "Brokers",
        permission: "Normal",
    },
    {
        to: routeHelpers.quotes(),
        label: "Quotes",
    },
    {
        to: routeHelpers.groups(),
        label: "Groups",
    },
    {
        to: routeHelpers.carriers(),
        label: "Carriers",
        permission: "State",
    },
    {
        to: routeHelpers.plans(),
        label: "Plans",
    },
    {
        to: routeHelpers.favorites(),
        label: "Favorites",
    },
    {
        to: routeHelpers.personnel(),
        label: "Personnel",
        permission: "Office",
    },
    {
        to: routeHelpers.support(),
        label: "Support",
    },
];

const strings = {
    closeAriaLabel: "Close Navigation",
};

export default class TabNavigation extends Component {
    static propTypes = {
        aStyles: texchangePropTypes.aphroditeStyles,
        location: texchangePropTypes.location.isRequired,
        me: texchangePropTypes.me.isRequired,
        mobileOpen: PropTypes.bool,
        onMobileClose: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            index: -1,
            hover: false,
            isMobile: windowSize.isTabletAndDown(),
            innerHeight: window.innerHeight,
            hoverIndex: -1,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        for (let i = 0; i < navLinks.length; i++) {
            const active = matchPath(nextProps.location.pathname, {
                path: navLinks[i].to,
                exact: true,
            });

            if (active) {
                return {
                    index: i,
                };
            }
        }

        if (prevState.index >= 0) {
            return { index: -1 };
        }

        return null;
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this._resizeWindow);
    }

    _resizeWindow = () => {
        if (this.state.isMobile !== windowSize.isTabletAndDown()) {
            this.setState({
                isMobile: windowSize.isTabletAndDown(),
                innerHeight: window.innerHeight,
            });
        }
    };

    _navigateToProfile = () => {
        // TODO: close mobile navigation and navigate to profile screen
    };

    _onMouseEnter = () => {
        if (!this.state.isMobile) {
            this.setState({ hover: true });
        }
    };
    _onMouseLeave = () => {
        if (!this.state.isMobile) {
            this.setState({ hover: false });
        }
    };

    _onSwitchTabs = index => this.setState({ index });

    _renderIcon = (label, active, hover) => {
        const iconStyles = [styles.icon];
        if (active) {
            iconStyles.push(styles.active);
        }
        else if (hover) {
            iconStyles.push(styles.hover);
        }

        switch (label) {
            case "Dashboard":
                return <DashboardIcon className={css(iconStyles)} />;
            case "Account":
                return <AccountIcon className={css(iconStyles)} />;
            case "Brokers":
                return <BrokersIcon className={css(iconStyles)} />;
            case "Quotes":
                return <QuotesIcon className={css(iconStyles)} />;
            case "Groups":
                return <GroupsIcon className={css(iconStyles)} />;
            case "Carriers":
                return <CarriersIcon className={css(iconStyles)} />;
            case "Plans":
                return <PlansIcon className={css(iconStyles)} />;
            case "Favorites":
                return <FavoritesIcon className={css(iconStyles)} />;
            case "Personnel":
                return <PersonnelIcon className={css(iconStyles)} />;
            case "Support":
                return <SupportIcon className={css(iconStyles)} />;
            default:
                return null;
        }
    }

    _onMouseEnterNavItem = index => {
        this.setState({ hoverIndex: index });
    };

    _onMouseLeaveNavItem = () => {
        this.setState({ hoverIndex: -1 });
    }

    _renderNavLink = (link, index) => {
        const { me, location } = this.props;

        //permission?
        if (link.permission) {
            if (link.permission === "State" && !(me.isStateAdmin || me.isSysAdmin)) {
                return;
            } else if (link.permission === "Office" && !(me.isOfficeAdmin || me.isSysAdmin)) {
                return;
            } else if (link.permission === "Normal" && !(me.isOfficeAdmin || me.isSysAdmin || me.isNormalUser || me.isMultiOffice)) {
                return;
            }
        }

        const pathname = location.pathname === "/" ? "/dashboard" : location.pathname;
        const active = matchPath(pathname, {
            path: link.to,
            exact: false,
        });

        const hover = this.state.hoverIndex === index;

        return (
            <NavLink
                key={link.label}
                exact={true}
                className={css(
                    styles.navItem,
                    hover ? styles.hover : null,
                    active ? styles.active : null,
                )}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => this._onSwitchTabs(index)}
                to={link.to}
                // eslint-disable-next-line react/jsx-no-bind
                onMouseEnter={() => this._onMouseEnterNavItem(index)}
                onMouseLeave={this._onMouseLeaveNavItem}
            >
                {this._renderIcon(link.label, active, hover)}

                <div className={css(
                    styles.label,
                    hover ? styles.hover : null,
                    active ? styles.active : null
                )}
                >
                    {link.label}
                </div>
            </NavLink>
        );
    };

    render() {
        return (
            <div
                className={css(styles.navigationContainer, this.props.aStyles)}
                style={
                    this.state.isMobile
                        ? { height: this.state.innerHeight }
                        : null //{ height: window.innerHeight }
                }
            >
                {this.state.isMobile ? (
                    <Overlay
                        open={this.props.mobileOpen}
                        aStyles={styles.overlay}
                        onClick={this.props.onMobileClose}
                    >
                        <CloseIcon fill={color.mediumBlue} className={css(styles.closeButton)} />
                    </Overlay>
                ) : null}
                <div
                    className={css(
                        styles.contents,
                        this.props.mobileOpen ? styles.mobileOpen : null,
                        this.props.aStyles,
                    )}
                    onMouseEnter={this._onMouseEnter}
                    onMouseLeave={this._onMouseLeave}
                >
                    <div
                        className={css(
                            styles.links,
                            this.state.hover ? styles.linksContainerHover : null,
                        )}
                    >
                        {navLinks.map((x, index) => this._renderNavLink(x, index))}
                    </div>
                    {this.state.isMobile ? (
                        <button
                            type="button"
                            onClick={this._navigateToProfile}
                            className={css(styles.profileButton)}
                        >
                            {strings.profileLabel}
                        </button>
                    ) : null}
                </div>
            </div>
        );
    }
}
