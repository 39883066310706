import PropTypes from "prop-types";
import React, { Component } from "react";
import { GUTTER_WIDTH } from "./Container";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    row: {
        marginLeft: -(GUTTER_WIDTH / 2),
        //marginRight: -(GUTTER_WIDTH / 2),

        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
    },
});

export default class Row extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    render() {
        return <div className={css(styles.row)}>{this.props.children}</div>;
    }
}
