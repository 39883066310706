import * as color from "../../constants/color";
import * as routeHelpers from "../../lib/routeHelpers";
import TextInput from "../inputs/TextInput";
import PageTitle from "../text/PageTitle";
import Checkbox from "../inputs/Checkbox";
import logger from "../../lib/logger";
import describeNetworkError from "../../lib/describeNetworkError";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as texchangePropTypes from "../../texchangePropTypes";
import InlineEdit from "../inputs/InlineEdit";
import Headline from "../text/Headline";
import { Container, Row, Column } from "../grid";
import { StyleSheet, css } from "aphrodite";
import Tabs from "../Tabs";
import ActionButton from "../ActionButton";
import * as officesApi from "../../lib/api/offices";
import * as accountApi from "../../lib/api/account";
import * as util from "../../lib/util";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = StyleSheet.create({
    content: {
        paddingRight: 10,
        paddingBottom: 80,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
    },
    contentSection: {
        width: "100%",
        padding: 0,
        backgroundColor: color.white,
        borderTop: `8px solid ${color.mediumRed}`,
        marginBottom: 0,
    },
    title: {
        fontSize: 20,
        fontWeight: 500,
        paddingBottom: 0,
        marginBottom: 16,
    },
    titleWithHeight: {
        fontSize: 20,
        fontWeight: 500,
        paddingTop: 23,
        marginBottom: 11,
    },
    titleSection: {
        heigth: 64,
    },
    personnelDetails: {
        marginBottom: 20,
    },
    generalInfo: {
        padding: 24,
        paddingBottom: 64,
        backgroundColor: color.white,
    },
    leftColumn: {
        paddingRight: 16,
    },
    rightColumn: {
        paddingLeft: 16,
    },
    checkLabel: {
        display: "block",
        position: "relative",
        border: "none",
        marginTop: 8,
        marginBottom: 16,
        "-webkit-appearance": "none",
        textAlign: "left",
    },
    check: {
        position: "absolute",
        top: 0,
        right: 20,
    },
    displayRow: {
        marginTop: 6,
        marginBottom: 6,
        minHeight: 18,
    },
    item: {
        width: "100%",
        height: 60,
        display: "block",
        paddingTop: 10,
        paddingBottom: 12,
        marginBottom: 12,
        borderBottom: `1px solid ${color.darkGray6}`,
    },
    container: {
        borderBottom: `1px solid ${color.darkGray6}`,
        paddingBottom: 12,
        marginBottom: 12,
        height: 60,
    },
    headerLabel: {
        float: "left",
        fontSize: 14,
        fontWeight: 500,
        color: color.darkGray,
    },
    displayContainer: {
        clear: "both",
        color: color.darkestGray,
        fontSize: 16,
        paddingTop: 10,
    },
    resetPassword: {
        color: color.mediumYellow1,
        float: "right",
        cursor: "pointer",
    },
    deactivateButton: {
        width: 200,
    },
});

const strings = {
    title: "Personnel Detail",
    generalInformation: "General Information",
    generalInfoTabLabel: "Account",
    permissions: "Permissions",
    nameLabel: "Name",
    namePlaceholder: "Name",
    emailLabel: "Email",
    emailPlaceholder: "Email",
    phoneLabel: "Phone #",
    phonePlaceholder: "Phone #",
    jobTitleLabel: "Job Title",
    jobTitlePlaceholder: "Job Title",
    salesForceIdLabel: "Sales Force ID",
    rolesLabel: "Rating Engine Role(s)",
    ratingEnginePermission: "Allow Rating Engine Access",
    enrollmentPortalPermission: "Allow Enrollment Access",
    passwordLabel: "Password",
    officesLabel: "Multi-Office Access",
    deactivateLabel: "Deactivate Account",
};

const inputNames = {
    name: "name",
    email: "email",
    phone: "phone",
    jobTitle: "jobTitle",
    salesForceId: "salesForceId",
    ratingEnginePermission: "ratingEnginePermission",
    enrollmentPortalPermission: "enrollmentPortalPermission",
    password: "password",
};

export class PersonnelDetailScreen extends Component {
    static propTypes = {
        history: texchangePropTypes.history.isRequired,
        match: texchangePropTypes.match.isRequired,
        me: texchangePropTypes.me.isRequired,
    };

    state = {
        inputs: {
            [inputNames.name]: "",
            [inputNames.email]: "",
            [inputNames.phone]: "",
            [inputNames.jobTitle]: "",
            [inputNames.salesForceId]: "",
            [inputNames.ratingEnginePermission]: true,
            [inputNames.enrollmentPortalPermission]: false,
            [inputNames.password]: "",
        },
        employee: {},
        offices: [],
        officesManaged: [],
        savingOffices: false,
        loading: false,
        errors: {},
    };

    componentDidMount() {
        this._mounted = true;
        this.setState({ loading: true });
        Promise.all([officesApi.getPersonnelById(this.props.match.params.id), officesApi.getActiveRBG(this.props.match.params.id)])
            .then(responses => {
                //employee loaded
                if (this._mounted && responses[0]) {
                    this._employeeLoaded(responses[0]);
                    //multi office access?
                    if (responses[1]) {
                        this.setState({
                            offices: responses[1],
                            officesManaged: responses[0].officesManaged.split(','),
                        });
                    }
                }
            })
            .catch(err => logger.log(err))
            .finally(() => this.setState({ loading: false }));
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    _employeeLoaded = employee => {
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.name]: employee.fullName,
                    [inputNames.email]: employee.email,
                    [inputNames.phone]: employee.phone ? employee.phone : "",
                    [inputNames.jobTitle]: employee.jobTitle ? employee.jobTitle : "",
                    [inputNames.salesForceId]: employee.salesForceId ? employee.salesForceId : "",
                    [inputNames.ratingEnginePermission]: employee.ratingEnginePermission ? true : false,
                    [inputNames.enrollmentPortalPermission]: employee.enrollmentPortalPermission ? true : false,
                    [inputNames.password]: employee.password,
                },
                employee,
            };
        });
    };

    _handleTextChange = e => {
        const { name } = e.target;
        let { value } = e.target;

        if (name === inputNames.phone) {
            value = util.maskPhoneNumber(value);
        } else if (name === inputNames.postalCode) {
            value = value.replace(/[^0-9]+/g, "");
            //maxlength of 5
            if (value && value.length === 6) {
                return;
            }
        }
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
                errors,
            };
        });
    };

    _handleInputChange = e => {
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: val,
                },
                errors,
            };
        }, () => {
            if (type === "checkbox") {
                this._handleOnSave();
            }
        });
    };

    _handleMultiOfficeChange = e => {
        const { checked, value } = e.target;
        const { employee, officesManaged } = this.state;

        const copyOfficesManaged = [...officesManaged];
        //turning on or off?
        if (checked) {
            //check to see if this office is included.
            if (copyOfficesManaged.indexOf(value.toString()) === -1) {
                //add it
                copyOfficesManaged.push(value.toString());
            }
        } else {
            const index = copyOfficesManaged.indexOf(value.toString());
            //check to see if this office is included.
            if (index !== -1) {
                //remove it
                copyOfficesManaged.splice(index, 1);
            }
        }
        //save the new list to object
        employee.officesManaged = copyOfficesManaged.join();
        //save
        this.setState({ savingOffices: true });
        officesApi.savePersonnelOfficeAccess(employee)
            .then(response => {
                this.setState({ savingOffices: false });
                if (response) {
                    //update state
                    this.setState({ officesManaged: copyOfficesManaged });
                } else {
                    toast.error("Unable to update the office access for this user.", { toastId: 1 });
                }
            });
    };

    _handleOnSave = () => {
        const employeeSaved = new Promise(resolve => {
            const { employee } = this.state;

            Object.keys(inputNames).forEach(key => {
                employee[key] = this.state.inputs[key];
            });

            officesApi.savePersonnel(employee)
                .then((response => {
                    this._employeeLoaded(response);
                    resolve(true);
                }))
                .catch(err => {
                    const { response } = err;
                    if (response && response.status === 400 && response.data.errors) {
                        const errors = [];
                        const validationErrors = response.data.errors;
                        let messages = "";
                        Object.keys(validationErrors).forEach(key => {
                            errors[key] = "required";
                            messages += validationErrors[key] + "\n";
                        });

                        this.setState({
                            errors,
                        });
                        toast.error(messages, { toastId: 1 });
                        resolve(false);
                    }
                });
        });

        toast.info("Employee update coming soon.", { toastId: 1 });

        return employeeSaved;
    };

    _handleOnCancel = () => {
        const { employee } = this.state;

        this._employeeLoaded(employee);
    };

    _handleResetPassword = () => {
        const { employee } = this.state;

        toast.info("Employee update coming soon. Password reset email would be sent to " + employee.email, { toastId: 1 });

        accountApi.forgotPassword(employee.email)
            .then(() => {
                toast.success("Password reset email sent to " + employee.email, { toastId: 1 });
            })
            .catch(err => {
                logger.warn(err);
                if (!this._mounted) {
                    return;
                }

                let errors = {};
                if (err && err.response) {
                    const { response } = err;
                    if (
                        response &&
                        response.data &&
                        response.data.errors &&
                        Object.keys(response.data.errors).length > 0
                    ) {
                        // Server provided validation messages
                        errors = response.data.errors;
                    }
                    else if (response && response.status === 400) {
                        errors[inputNames.email] = [strings.emailNotFound];
                    }
                    else {
                        // 500 or something else unexpected
                        const description = describeNetworkError(err);
                        toast.error(description.join("\n"), { toastId: 1 });
                    }
                }

                this.setState({
                    loading: false,
                    errors: errors,
                });
            });
    }

    _deactivateAccount = () => {
        const { employee } = this.state;

        if (window.confirm("Please confirm that you wish to deactivate this account.  The account will disappear from this office and will need to be manually re-activated if necessary.")) {
            accountApi.deactivateAccount(employee.id)
                .then(result => {
                    if (result) {
                        toast.success("Account has been deactivated.  Redirecting...", { toastId: 1 });
                        setTimeout(() => {
                            window.location.href = "/personnel";
                        }, 2000);
                    } else {
                        toast.error("Account was not able to be deactivated.  Please check with Administration.", { toastId: 1 });
                        return;
                    }
                });
        }
    };

    _renderGeneralInfo = () => {
        const { me } = this.props;
        const { employee, offices, officesManaged, savingOffices } = this.state;

        return (
            <div className={css(styles.generalInfo)}>
                <ToastContainer position="top-center" autoClose={2500} />
                <Container>
                    <Row>
                        <Column large={6}>
                            <div className={css(styles.leftColumn)}>
                                <Headline aStyles={styles.title}>{strings.generalInformation}</Headline>
                                <div>
                                    {/*<InlineEdit
                                        headerLabel={strings.nameLabel}
                                        onSave={this._handleOnSave}
                                        onCancel={this._handleOnCancel}
                                    >
                                        <div edit>
                                            <TextInput
                                                name={inputNames.name}
                                                onChange={this._handleTextChange}
                                                placeholder={strings.namePlaceholder}
                                                required={true}
                                                validationMessage={this.state.errors[inputNames.name]}
                                                value={this.state.inputs[inputNames.name]}
                                            />
                                        </div>
                                        <div display>
                                            <div className={css(styles.displayRow)}>
                                                {this.state.inputs[inputNames.name]}
                                            </div>
                                        </div>
                                    </InlineEdit>*/}
                                    <div className={css(styles.container)}>
                                        <div className={css(styles.headerLabel)}>
                                            {strings.namePlaceholder}
                                        </div>
                                        <div className={css(styles.displayContainer)}>
                                            {this.state.inputs[inputNames.name]}
                                        </div>
                                    </div>
                                    {/*<InlineEdit
                                        headerLabel={strings.jobTitleLabel}
                                        onSave={this._handleOnSave}
                                        onCancel={this._handleOnCancel}
                                    >
                                        <div edit>
                                            <TextInput
                                                name={inputNames.jobTitle}
                                                onChange={this._handleTextChange}
                                                placeholder={strings.jobTitlePlaceholder}
                                                required={true}
                                                validationMessage={this.state.errors[inputNames.jobTitle]}
                                                value={this.state.inputs[inputNames.jobTitle]}
                                            />
                                        </div>
                                        <div display>
                                            <div className={css(styles.displayRow)}>
                                                {this.state.inputs[inputNames.jobTitle]}
                                            </div>
                                        </div>
                                    </InlineEdit>*/}
                                    <div className={css(styles.container)}>
                                        <div className={css(styles.headerLabel)}>
                                            {strings.jobTitlePlaceholder}
                                        </div>
                                        <div className={css(styles.displayContainer)}>
                                            {this.state.inputs[inputNames.jobTitle]}
                                        </div>
                                    </div>
                                    {/*<InlineEdit
                                        headerLabel={strings.phoneLabel}
                                        onSave={this._handleOnSave}
                                        onCancel={this._handleOnCancel}
                                    >
                                        <div edit>
                                            <TextInput
                                                name={inputNames.phone}
                                                onChange={this._handleTextChange}
                                                placeholder={strings.phonePlaceholder}
                                                required={true}
                                                validationMessage={this.state.errors[inputNames.phone]}
                                                value={this.state.inputs[inputNames.phone]}
                                            />
                                        </div>
                                        <div display>
                                            <div className={css(styles.displayRow)}>
                                                {this.state.inputs[inputNames.phone]}
                                            </div>
                                        </div>
                                    </InlineEdit>*/}
                                    <div className={css(styles.container)}>
                                        <div className={css(styles.headerLabel)}>
                                            {strings.phonePlaceholder}
                                        </div>
                                        <div className={css(styles.displayContainer)}>
                                            {this.state.inputs[inputNames.phone]}
                                        </div>
                                    </div>
                                    {/*<InlineEdit
                                        headerLabel={strings.emailLabel}
                                        onSave={this._handleOnSave}
                                        onCancel={this._handleOnCancel}
                                    >
                                        <div edit>
                                            <TextInput
                                                name={inputNames.email}
                                                onChange={this._handleTextChange}
                                                placeholder={strings.emailPlaceholder}
                                                required={true}
                                                validationMessage={this.state.errors[inputNames.email]}
                                                value={this.state.inputs[inputNames.email]}
                                            />
                                        </div>
                                        <div display>
                                            <div className={css(styles.displayRow)}>
                                                {this.state.inputs[inputNames.email]}
                                            </div>
                                        </div>
                                    </InlineEdit>*/}
                                    <div className={css(styles.container)}>
                                        <div className={css(styles.headerLabel)}>
                                            {strings.emailPlaceholder}
                                        </div>
                                        <div className={css(styles.displayContainer)}>
                                            {this.state.inputs[inputNames.email]}
                                        </div>
                                    </div>
                                    <div className={css(styles.container)}>
                                        <div className={css(styles.headerLabel)}>
                                            {strings.salesForceIdLabel}
                                        </div>
                                        <div className={css(styles.displayContainer)}>
                                            {this.state.inputs[inputNames.salesForceId]}
                                        </div>
                                    </div>
                                    {(me.isSysAdmin || (me.isMultiOffice && me.email === "clarke.dipiazza@emersonrogers.com")) && employee.roles && employee.roles.indexOf("SysAdmin") === -1 &&
                                        <div className={css(styles.container)}>
                                            <ActionButton
                                                aStyles={styles.deactivateButton}
                                                label={strings.deactivateLabel}
                                                onClick={this._deactivateAccount}
                                                type="button"
                                                isLoading={this.state.loading}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </Column>
                        <Column large={6}>
                            <div className={css(styles.rightColumn)}>
                                <Headline aStyles={styles.title}>{strings.permissions}</Headline>
                                <div>
                                    <div className={css(styles.container)}>
                                        <div className={css(styles.headerLabel)}>
                                            {strings.rolesLabel}
                                        </div>
                                        <div className={css(styles.displayContainer)}>
                                            {employee.roles}
                                        </div>
                                    </div>
                                    {/*<span className={css(styles.item)}>
                                        <label className={css(styles.checkLabel)}>
                                            <Checkbox
                                                aStyles={styles.check}
                                                checked={this.state.inputs[inputNames.ratingEnginePermission]}
                                                name={inputNames.ratingEnginePermission}
                                                onChange={this._handleInputChange}
                                                disabled={true}
                                            />
                                            {strings.ratingEnginePermission}
                                        </label>
                                    </span>
                                    <span className={css(styles.item)}>
                                        <label className={css(styles.checkLabel)}>
                                            <Checkbox
                                                aStyles={styles.check}
                                                checked={this.state.inputs[inputNames.enrollmentPortalPermission]}
                                                name={inputNames.enrollmentPortalPermission}
                                                onChange={this._handleInputChange}
                                                disabled={true}
                                            />
                                            {strings.enrollmentPortalPermission}
                                        </label>
                                    </span>*/}
                                    <div className={css(styles.container)}>
                                        <div className={css(styles.passwordLabel)}>
                                            {strings.passwordLabel}
                                        </div>
                                        <div className={css(styles.displayContainer)}>
                                            ************
                                            <span className={css(styles.resetPassword)} onClick={this._handleResetPassword}>Reset Password</span>
                                        </div>
                                    </div>
                                    {offices && offices.length > 0 &&
                                        <Headline aStyles={styles.titleWithHeight}>{strings.officesLabel}</Headline>
                                    }
                                    {offices && offices.length > 0 &&
                                        offices.map((office, index) => (
                                            <span key={index} className={css(styles.item)}>
                                                <label className={css(styles.checkLabel)}>
                                                    <Checkbox
                                                        aStyles={styles.check}
                                                        checked={officesManaged.indexOf(office.id.toString()) > -1}
                                                        name={'office' + office.id}
                                                        value={office.id}
                                                        onChange={this._handleMultiOfficeChange}
                                                        disabled={savingOffices}
                                                    />
                                                </label>
                                                {office.name}
                                            </span>
                                        ))
                                    }
                                </div>
                            </div>
                        </Column>
                    </Row>
                </Container>
            </div>
        );
    };

    render() {
        if (this.state.loading) {
            return null;
        }

        const { employee } = this.state;

        return (
            <div className={css(styles.content)}>
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.titleSection)}>
                        <PageTitle>{strings.title}{": "}{employee.fullName}</PageTitle>
                    </div>
                </div>
                <div className={css(styles.personnelDetails)}>
                    <Tabs history={this.props.history} backUrl={routeHelpers.personnel()}>
                        <div label={strings.generalInfoTabLabel}>
                            {this._renderGeneralInfo()}
                        </div>
                        <div label=".">
                        </div>
                    </Tabs>
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = state => ({
    me: state.session.me,
});

export default connect(mapDispatchToProps)(PersonnelDetailScreen);

