import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import InputGroup from "./InputGroup";
import ActionButton from "../ActionButton";
import TextInput from "../inputs/TextInput";
import Headline from "../text/Headline";
import Dropdown from "../inputs/Dropdown";
import * as util from "../../lib/util";
import * as color from "../../constants/color";
import * as enrollmentApi from "../../lib/api/enrollment";
import * as enrollmentActions from "../../actions/enrollmentActions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = StyleSheet.create({
    buttonContainer: {
        marginTop: 24,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    backButton: {
        flexGrow: 1,
        marginRight: 16,
        flexBasis: 0,
    },
    continueButton: {
        flexGrow: 1,
        marginLeft: 16,
        flexBasis: 0,
    },
    headerInstructions: {
        fontSize: 16,
        marginBottom: 24,
        lineHeight: "21px",
    },
    instructions: {
        fontSize: 14,
        marginTop: 12,
    },
    downloadLink: {
        color: color.mediumYellow1,
        fontWeight: 500,
        marginLeft: 4,
        marginRight: 4,
        textDecoration: "none",
    },
    headline: {
        fontSize: 20,
        fontWeight: 500,
        marginBottom: 8,
        paddingBottom: 0,
    },
    downloadIcon: {
        float: "right",
    },
});

const inputNames = {
	company: "company",
	name: "name",
	title: "title",
	phone: "phone",
	email: "email",
	billingContactInvoiceTimingId: "billingContactInvoiceTimingId",
};

export class BillingContact extends Component {
	static propTypes = {        
        onContinue: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        billingContact: PropTypes.object.isRequired,
        updateBillingContact: PropTypes.func.isRequired,
        enrollmentPortal: PropTypes.object.isRequired,
        isEditable: PropTypes.bool,
    };

    constructor(props) {
		super(props);

		const { billingContact } = this.props;

		this.state = {
			inputs: {
				[inputNames.company]: billingContact.company ? billingContact.company : "",
				[inputNames.name]: billingContact.name ? billingContact.name : "",
				[inputNames.title]: billingContact.title ? billingContact.title : "",
				[inputNames.phone]: billingContact.phone ? billingContact.phone : "",
				[inputNames.email]: billingContact.email ? billingContact.email : "",
				[inputNames.billingContactInvoiceTimingId]: billingContact.billingContactInvoiceTimingId ? billingContact.billingContactInvoiceTimingId : "",
			},
			isSaving: false,
			errors: [],
			billingInvoiceTimings: [],
		};
	}

	componentDidMount() {
		enrollmentApi.getBillingInvoiceTimings()
			.then(result => {
				this.setState({
					billingInvoiceTimings: result,
				});
			});
	}

	_handleTextChanged = e => {
        const { name } = e.target;        
        let { value } = e.target;

        if (e.target.getAttribute("data-type") === "phone") {
            value = util.maskPhoneNumber(value);
        }
        
        this.setState(prevState => {
			const errors = { ...prevState.errors };
            delete errors[name];

            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
                errors,
            };
        });
    };	

    _handleDropdownChanged = selectedItem => {
        const { name, value } = selectedItem;        
                        
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
            };
        });
    };

	_submit = e => {
		e.preventDefault();

        if (!this.props.isEditable) {
            this.onContinue();
            return;
        }
        
        const { billingContact } = this.props;
        
        Object.keys(inputNames).forEach(key => {
            billingContact[key] = this.state.inputs[key];
        });

        this.setState({
            isSaving: true,
        });

        enrollmentApi.saveBillingContact(billingContact)
            .then(response => {
                this.props.updateBillingContact(response);
                enrollmentApi.sendEnrollmentPortalDataToUBenefit(this.props.enrollmentPortal.id)
                    .then(response => {
                        this.setState({
                            isSaving: false,
                        });

                        if (response.success) {
                            this.props.onContinue();    
                        } else {
                            if (response.message) {
                                toast.error(response.message, { toastId: 1 });
                            } else {
                                toast.info("An error has occurred", { toastId: 2 });
                            }
                        }                        
                    });
            });        		
	};

	render() {
        const { isEditable } = this.props;

		return (
			<form onSubmit={this._submit}>
                <ToastContainer position="top-center" autoClose={2500} />
				<InputGroup>
					<p className={css(styles.headerInstructions)}>
						The employer and broker need to complete this 
						<a 
							className={css(styles.downloadLink)}
							href="/files/UBenefit_New_Employer_Sign_Up_Checklist.pdf" 
							target="_blank"
						>
							checklist
						</a>				
						and return to UBenefit/RBG.                        
					</p>
					<Headline aStyles={styles.headline}>Billing and Contact Information</Headline>
					<TextInput
                        name={inputNames.company}
                        placeholder="Company"                            
                        validationMessage={this.state.errors[inputNames.company]}
                        value={this.state.inputs[inputNames.company]}
                        disabled={true}
                    />
                    <TextInput
                        name={inputNames.name}
                        onChange={this._handleTextChanged}
                        placeholder="Name"                            
                        validationMessage={this.state.errors[inputNames.name]}
                        value={this.state.inputs[inputNames.name]}
                        disabled={!isEditable}
                    />
                    <TextInput
                        name={inputNames.title}
                        onChange={this._handleTextChanged}
                        placeholder="Title"                            
                        validationMessage={this.state.errors[inputNames.title]}
                        value={this.state.inputs[inputNames.title]}
                        disabled={!isEditable}
                    />
                    <TextInput
                        name={inputNames.phone}
                        onChange={this._handleTextChanged}
                        placeholder="Phone Number"                            
                        validationMessage={this.state.errors[inputNames.phone]}
                        value={this.state.inputs[inputNames.phone]}
                        data-type="phone"
                        disabled={!isEditable}
                    />
                    <div className={css(styles.instructions)}>Separate email addresses with a semicolon (;)</div>
                    <TextInput
                        name={inputNames.email}
                        onChange={this._handleTextChanged}
                        placeholder="Email Addresses"                            
                        validationMessage={this.state.errors[inputNames.email]}
                        value={this.state.inputs[inputNames.email]}
                        disabled={!isEditable}
                    />
                    <div className={css(styles.instructions)}>Please select how often you would like your invoice:</div>
                    <Dropdown
						name={inputNames.billingContactInvoiceTimingId}
                        options={this.state.billingInvoiceTimings.map(item => ({
                            label: item.name,
                            value: item.id,
                        }))}
                        onChange={this._handleDropdownChanged}
                        placeholder="Select frequency"
                        validationMessage={this.state.errors[inputNames.billingContactInvoiceTimingId]}
                        value={this.state.inputs[inputNames.billingContactInvoiceTimingId]}                        
                        disabled={!isEditable}
                    />
				</InputGroup>
				<InputGroup>
					<div className={css(styles.buttonContainer)}>
						<ActionButton
							aStyles={styles.backButton}
							label="Back"
							invertColor={true}
							type="button"                        
							onClick={this.props.onCancel}
						/>
                        {isEditable &&
                            <ActionButton                                    
                                aStyles={styles.continueButton}                        
                                label="Submit To UBenefit"                        
                                type="submit"                                        
                                isLoading={this.state.isSaving}                         
                            />
                        }
						
					</div>
				</InputGroup>
			</form>
		);
	}
}

const mapStateToProps = state => ({
    billingContact: state.enrollment.billingContact,
    enrollmentPortal: state.enrollment.enrollmentPortal,
    isEditable: state.enrollment.isEditable,
});

export default connect(
	mapStateToProps, {
    	updateBillingContact: enrollmentActions.updateBillingContact,
	}
)(BillingContact);
