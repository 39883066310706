import * as color from "../constants/color";
import PropTypes from "prop-types";
import Checkbox from "./inputs/Checkbox";
import { StyleSheet, css } from "aphrodite";
import React, { Component } from 'react';
import { ReactComponent as ChevronDown } from "../images/SVGs/chevron-down.svg";
import favoriteIcon from "../images/SVGs/favorite.svg";
import favoriteFilledIcon from "../images/SVGs/favorite-filled.svg";
import downloadIcon from "../images/SVGs/Download.svg";

const styles = StyleSheet.create({
    tblInf: {
        width: "100%",
        marginTop: 10,
        marginBottom: 20,
        borderCollapse: "collapse",
    },
    tblInfHeader: {
        fontSize: 16,
        color: color.darkYellow1,
        backgroundColor: color.white,
        padding: "10px 6px",
        fontWeight: 500,
        textAlign: "left",
        whiteSpace: "nowrap",
        cursor: "pointer",
        borderBottom: `1px solid ${color.mediumGray}`,
    },
    tblInfCell: {
        fontSize: 13,
        height: 40,
        color: color.tableText,
        padding: "7px",
        borderBottom: `1px solid ${color.tableBorder}`,
        lineHeight: "12px",
        minWidth: 40,
        maxWidth: 200,
        ":nth-child(2)": {
            wordBreak: "break-word",
            minWidth: 120,
            maxWidth: 205,
            fontWeight: 500,
        },
        ":nth-child(3)": {
            wordBreak: "break-word",
            minWidth: 65,
            maxWidth: 240,
        },
        ":nth-child(4)": {
            wordBreak: "break-word",
            minWidth: 70,
            maxWidth: 215,
        },
        ":nth-child(5)": {
            wordBreak: "break-word",
            minWidth: 65,
            maxWidth: 210,
        },
        ":nth-child(15)": {
            maxWidth: 240,
        },
        ":nth-child(19)": {
            wordBreak: "break-word",
            minWidth: 100,
            maxWidth: 240,
        },
    },
    smallText: {
        fontSize: 11,
        fontStyle: "italic",
        color: color.darkRed,
        lineHeight: "10px",
        ":before": {
            content: "''",
            display: "block",
        }
    },
    maxWidth: {
        maxWidth: "110px",
        wordBreak: "break-word",
        overflowX: "hidden",
    },
    showMore: {
        textAlign: "center",
        fontStyle: "italic",
        color: color.darkestGray,
        fontSize: 13,
        padding: 12,
    },
    right: {
        float: "right",
        position: "relative",
        top: -38,
        right: 20,
        fontSize: 13,
        color: color.tableText,
    },
    greenBar: {
        borderLeft: `4px solid ${color.mediumRed}`,
        whiteSpace: "nowrap",
    },
    chevronRight: {
        pointerEvents: "none",
        transform: "rotate(-90deg)",
        display: "block",
        width: 10,
        height: 10,
    },
    chevron: {
        pointerEvents: "none",
        width: 10,
        height: 10,
        marginLeft: 6,
    },
    chevronUp: {
        transform: "rotate(-180deg)",
    },
    chevronDown: {
    },
    metalCircle: {
        height: "15px",
        width: "15px",
        borderRadius: "50%",
        display: "inline-block",
        textAlign: "center",
        fontSize: 14,
        fontWeight: "bold",
        paddingTop: "2px",
    },
    manual: {
        height: 24,
        width: 24,
        padding: "7px 4px",
        whiteSpace: "nowrap",
        color: color.mediumRed,
        textDecorationLine:'underline',
        textDecorationColor:color.mediumRed
    },
    editRate: {
        color: color.mediumRed,
        textDecoration: "underline",
    },
    hover: {
        position: "relative",
        color: color.mediumYellow1,
    },
    __hover: {
        position: "absolute",
        top: -10,
        right: -5,
        padding: 8,
        width: "100%",
        height: 30,
        color: color.tableText,
        backgroundColor: color.white,
        border: `1px solid ${color.tableBorder}`,
        overflow: "hidden",
        opacity: 0,
        ":hover": {
            opacity: 1,
            top: -20,
            minWidth: 340,
            minHeight: 63,
            overflow: "auto",
        },
    },
    __nohover: {
        opacity: 1,
        ":hover": {
            opacity: 0,
        },
    },
    downloadLink: {
        height: 19,
    },
    favoriteIcon: {
        height: 24,
        cursor: "pointer",
    },
});

export default class Infinite extends Component {
    static propTypes = {
        results: PropTypes.arrayOf(PropTypes.object).isRequired,
        columnLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
        columnValues: PropTypes.arrayOf(PropTypes.string).isRequired,
        showChevron: PropTypes.bool.isRequired,
        showMore: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
        onScroll: PropTypes.func.isRequired,
        length: PropTypes.number,
        checkBox: PropTypes.bool,
        checkList: PropTypes.arrayOf(PropTypes.number),
        showBasePlan: PropTypes.bool,
        onBasePlanClick: PropTypes.func,
        basePlanList: PropTypes.arrayOf(PropTypes.number),
        isEditable: PropTypes.bool,
        onManualRatesClick: PropTypes.func,
        showAllChecked: PropTypes.bool,
        allChecked: PropTypes.bool,
        onAllClick: PropTypes.func,
        onFavoriteClick: PropTypes.func,
        showFavoriteAll: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            top: 0,
            page: 1,
            sortColumn: "",
            sortDirection: "desc",
        };

        this._tableRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("scroll", this._onScroll, false);
        //console.log(this.props.results);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this._onScroll, false);
    }

    _onScroll = () => {
        //every 40 pixels of scroll...  check to see if we need next page
        if (window.scrollY > (this.state.top + 40)) {
            const thisTable = this._tableRef.current;
            const isHidden = thisTable.offsetParent === null;
            if (this.isBottom(thisTable) && !isHidden) {
                //console.log("table bottom reached");
                //reset top
                this.setState({
                    top: window.scrollY,
                    page: this.state.page + 1,
                }, () => {
                    //console.log("scroll detected and page = " + this.state.page);
                    this.props.onScroll(this.state.page, this.state.sortColumn, this.state.sortDirection);
                });
            }
        }
    }

    _handleSort = newCol => {
        //same as sortColumn?
        if (newCol === this.state.sortColumn) {
            //only handle direction
            this.setState({
                top: 0,
                page: 1,
                sortDirection: this.state.sortDirection === "asc" ? "desc" : "asc",
            }, () => {
                //update the page back to 1 and hit onScroll...
                this.props.onScroll(this.state.page, this.state.sortColumn, this.state.sortDirection);
            });
        } else {
            //handle new column and reset to descending
            this.setState({
                top: 0,
                page: 1,
                sortColumn: newCol,
                sortDirection: "desc",
            }, () => {
                //update the page back to 1 and hit onScroll...
                this.props.onScroll(this.state.page, this.state.sortColumn, this.state.sortDirection);
            });
        }
    }

    _handleRowClick = id => {
        this.props.onClick(id);
    };

    _handleCheckChanged = id => {
        this.props.onClick(id);
    };

    _handleBasePlanChanged = id => {
        this.props.onBasePlanClick(id);
    };

    _handleManualRatesClick = (id, name) => {
        this.props.onManualRatesClick(id, name);
    };

    _handleAllCheckChanged = id => {
        this.props.onAllClick(id);
    };

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    };

    render() {
        const { results, columnLabels, columnValues, showChevron, showMore, checkBox, checkList, showBasePlan, basePlanList, isEditable, showAllChecked, allChecked, onFavoriteClick, showFavoriteAll } = this.props;
        const Hover = ({ onHover, children }) => (
            <div className={css(styles.hover)}>
                <div className={css(styles.__nohover)}>{children}</div>
                <div className={css(styles.__hover)}>{onHover}</div>
            </div>
        )

        let maxWidth = false;
        if (columnValues.length > 14) {
            maxWidth = true;
        }

        return (
            <table id="clickableTable" className={css(styles.tblInf)} ref={this._tableRef}>
                <thead>
                    <tr>
                        {showAllChecked ? (
                            <th className={css(styles.tblInfHeader)}>
                                <Checkbox
                                    name={"InfiniteSelectAll"}
                                    aStyles={styles.check}
                                    checked={allChecked}
                                    onChange={this._handleAllCheckChanged}
                                />
                            </th>
                        ) : checkBox ? (
                            <th className={css(styles.tblInfHeader)}>&nbsp;</th>
                        ) : null}
                        {columnLabels.map((l, i) => (
                            <th key={i}
                                className={css(styles.tblInfHeader)}
                                onClick={() => this._handleSort(columnValues[i])}
                            >{l}{" "}
                                <ChevronDown
                                    className={css(
                                        styles.chevron,
                                        this.state.sortDirection === "asc" ? styles.chevronUp : styles.chevronDown
                                    )}
                                    style={{ display: this.state.sortColumn === columnValues[i] ? "inline-block" : "none" }}
                                    alt="sort"
                                />
                            </th>
                        ))}
                        {showChevron ? (
                            <th className={css(styles.tblInfHeader)}>&nbsp;</th>
                        ) : null}
                        {showBasePlan ? (
                            <th className={css(styles.tblInfHeader)}>Base</th>
                        ) : null}
                        {onFavoriteClick ? (
                            <th className={css(styles.tblInfHeader)}>
                                {showFavoriteAll &&
                                    <img className={css(styles.favoriteIcon)} src={favoriteIcon} alt="favorite" onClick={e => onFavoriteClick(e, "all")} />
                                }
                            </th>
                        ) : null}
                    </tr>
                </thead>
                <tbody>
                    {results.map((r, index) => (
                        <tr key={r.id + "-" + index} onClick={!checkBox ? () => this._handleRowClick(r.id) : null}>
                            {checkBox ? (
                                <td className={css(styles.tblInfCell, styles.greenBar)}>
                                    <Checkbox
                                        name={"InfiniteSelect_" + index}
                                        aStyles={styles.check}
                                        checked={checkList.indexOf(r.id) > -1}
                                        onChange={() => this._handleCheckChanged(r.id)}
                                    />
                                </td>
                            ) : null}
                            {columnValues.map((v, i) => (
                                <td key={v + i} className={css(styles.tblInfCell, (i === 0 && !checkBox ? styles.greenBar : ""), ((maxWidth && (i < 4 || i === 13)) ? styles.maxWidth : ""))}>
                                    {v === "metallicLevel" ? (
                                        <span className={css(styles.metalCircle)} style={{ backgroundColor: (r[v] === "Gold" ? "#ffd700" : r[v] === "Silver" ? "#c0c0c0" : (r[v] === "Bronze" || r[v] === "Exp. Bronze" || r[v] === "Expanded Bronze") ? "#cd7f32" : r[v] === "Platinum" ? "#f7f7f7" : r[v]), border: (r[v] === "Platinum" ? "solid 1px #cccccc" : "") }}>{(r[v] && r[v].indexOf("Exp") > -1) ? "+" : ""}</span>
                                    ) : (v === "displayName" && r["packages"]) ? (
                                        <div>{r[v]}<span className={css(styles.smallText)}>{r["packages"]}</span></div>
                                    ) : (isEditable && v === "monthlyPremium" && !r[v] && !r["quoted"] && (!r["ageMonthlyPremium"] || r["ageMonthlyPremium"] === "$0.00")) ? (
                                        <div className={css(styles.manual)} onClick={() => this._handleManualRatesClick(r.id, r.displayName)}>Click to input rates for this plan.</div>
                                    ) : (isEditable && (v === "monthlyPremium" || v === "ee" || v === "es" || v === "ec" || v === "ef") && r[v] && !r["quoted"] && (!r["ageMonthlyPremium"] || r["ageMonthlyPremium"] === "$0.00")) ? (
                                        <div className={css(styles.editRate)} onClick={() => this._handleManualRatesClick(r.id, r.displayName)}>{r[v]}</div>
                                    ) : (isEditable && v === "monthlyPremium" && !r[v] && r["ageMonthlyPremium"]) ? (
                                        <div className={css(styles.manual)}>Plan does not have composite rates.</div>
                                    ) : (v === "rxCard") ? (
                                        <Hover onHover={<div>{r[v]}</div>}>
                                            <div>Rx</div>
                                        </Hover>
                                    ) : (v === "summaryPDFName" && r[v]) ? (
                                        <a href={r[v]} target="_blank" rel="noopener noreferrer"><img className={css(styles.downloadLink)} src={downloadIcon} alt="download" /></a>
                                    ) : (typeof r[v] === "boolean" && r[v]) ? (
                                        <span>Yes</span>
                                    ) : (typeof r[v] === "boolean" && !r[v]) ? (
                                        <span>No</span>
                                    ) : r[v]}
                                </td>
                            ))}
                            {showChevron ? (
                                <td className={css(styles.tblInfCell)}>
                                    <ChevronDown
                                        className={css(styles.chevronRight)}
                                        alt="details"
                                    />
                                </td>
                            ) : null}
                            {showBasePlan ? (
                                <td className={css(styles.tblInfCell)}>
                                    <Checkbox
                                        name={"InfiniteSelectBase_" + index}
                                        aStyles={styles.check}
                                        disabled={checkList.indexOf(r.id) === -1}
                                        checked={basePlanList.indexOf(r.id) > -1}
                                        onChange={() => this._handleBasePlanChanged(r.id)}
                                        showAsGreen={true}
                                    />
                                </td>
                            ) : null}
                            {onFavoriteClick ? (
                                <td className={css(styles.tblInfCell)}>
                                    {r.favoriteCount === 0 ? (
                                        <img className={css(styles.favoriteIcon)} src={favoriteIcon} alt="favorite" onClick={e => onFavoriteClick(e, r.id)} />
                                    ) : (
                                        <img className={css(styles.favoriteIcon)} src={favoriteFilledIcon} alt="favorite" onClick={e => onFavoriteClick(e, r.id)} />
                                    )}
                                </td>
                            ) : null}
                        </tr>
                    ))}
                    {showMore ? (
                        <tr><td className={css(styles.tblInfCell, styles.showMore)} colSpan={99}>Loading More...</td></tr>
                    ) : null}
                </tbody>
            </table>
        );
    }
}