import * as color from "../constants/color";
import * as texchangePropTypes from "../texchangePropTypes";
import * as zIndexes from "../constants/zIndexes";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite/no-important";

const styles = StyleSheet.create({
    overlay: {
        zIndex: zIndexes.OVERLAY,
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
        pointerEvents: "none",
        border: "none",
        opacity: 0,
        transition: "opacity 0.5s",
        backgroundColor: color.white,
    },
    overlayOpen: {
        opacity: 0.8,
        pointerEvents: "auto",
    },
    drawerOpen: {
        overflow: "hidden",
    },
});

export default class Overlay extends Component {
    static propTypes = {
        aStyles: texchangePropTypes.aphroditeStyles,
        aStylesOpen: texchangePropTypes.aphroditeStyles,
        open: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
        children: PropTypes.node,
    };

    componentDidMount() {
        if (this.props.open) {
            document.body.classList.add(css(styles.drawerOpen));
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.open !== prevProps.open) {
            if (this.props.open) {
                document.body.classList.add(css(styles.drawerOpen));
            } else {
                document.body.classList.remove(css(styles.drawerOpen));
            }
        }
    }

    componentWillUnmount() {
        if (this.props.open) {
            document.body.classList.remove(css(styles.drawerOpen));
        }
    }

    render() {
        return (
            <button
                type="button"
                className={css(
                    styles.overlay,
                    this.props.aStyles,
                    this.props.open ? [styles.overlayOpen, this.props.aStylesOpen] : null,
                )}
                onClick={this.props.onClick}
            >
                {this.props.children}
            </button>
        );
    }
}
