import * as color from "../../constants/color";
import * as routeHelpers from "../../lib/routeHelpers";
import * as texchangePropTypes from "../../texchangePropTypes";
import PageTitle from "../text/PageTitle";
import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { ReactComponent as ChevronDown } from "../../images/SVGs/chevron-down.svg";
import Tabs from "../Tabs";
import * as agenciesApi from "../../lib/api/agencies";
import * as brokersApi from "../../lib/api/brokers";
import FileBrowser from "../inputs/FileBrowser";
import Dropzone from 'react-dropzone'
import { ToastContainer, toast } from 'react-toastify';

const styles = StyleSheet.create({
    content: {
        paddingRight: 10,        
        paddingBottom: 80,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
    },
    contentSection: {
        width: "100%",
        padding: 0,
        backgroundColor: color.white,
        borderTop: `8px solid ${color.mediumRed}`,
        marginBottom: 20,
    },
    titleSection: {        
        heigth: 64,
        backgroundColor: color.white,
    },
    topRightHolder: {
        float: "right",
        cursor: "pointer",
        marginTop: 8,
        marginRight: 24,
    },
    topRightText: {        
        display: "inline-block",
        fontSize: 14,     
    },
    blueCircle: {
        paddingTop: 6,
        marginLeft: 12,
        position: "relative",
        top: 4,
    },
    link: {
        color: color.mediumBlue,
        paddingRight: 20,
    },
    tabContent: {
        padding: 15,
        backgroundColor: color.white,
    },
    tabActivity: {
        fontSize: 12,
    },
    row: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        marginBottom: 20,
    },
    column: {
        display: "flex",
        flexDirection: "column",
        flexBasis: "100%",
        flex: 1,
        marginLeft: 15,
        marginRight: 15,
    },
    bolder: {
        fontWeight: "bolder",
    },
    heading: {
        width: "100%",
        fontSize: 17,
        borderBottom: `2px solid ${color.mediumRed}`,
        padding: 5,
        marginLeft: 5,
        marginRight: 5,
    },
    right: {
        float: "right",
    },
    item: {
        width: "100%",
        display: "block",
        padding: 5,
        marginLeft: 5,
        marginRight: 5,
    },
    active: {
        color: color.darkGray,
    },
    smaller: {
        fontSize: 12,
        color: color.darkGray,
    },
    lineBreak: {
        borderBottom: `1px solid ${color.mediumGray}`,
    },
    broker: {
        width: "100%",
        marginRight: 5,
    },
    chevron: {
        pointerEvents: "none",
        transform: "rotate(-90deg)",
        display: "block",
        width: 12,
        height: 12,
    },
    chevronHolder: {
        float: "right",
        marginTop: 25,
    },
    logoContainer: {
        backgroundColor: color.lightGray,
        border: `1px solid ${color.darkGray4}`,
        borderRadius: 4,
        padding: "24px 18px",
        margin: "10px",
        fontSize: 20,
        color: color.darkestGray,        
        textAlign: "center",        
    },
    brokerLogo: {
        display: "block",
        maxHeight: 300,
        maxWidth: 620,
        margin: "0px 10px",
    },
    uploadLogo: {
        width: "100%",
        textAlign: "left",
        margin: "10px",
    },
    deleteLogo: {
        cursor: "pointer",
        color: color.mediumYellow1,
    },
    divider: {
        color: color.darkGray,
        paddingLeft: 12,
        paddingRight: 12,
    }
});

/*const strings = {
    addAgency: "New Agency",
};*/

export default class AgencyDetailScreen extends Component {
    static propTypes = {
        history: texchangePropTypes.history.isRequired,
        match: texchangePropTypes.match.isRequired,
    };    

    state = {
        agency: {},
        loading: false,
        errors: {},
    };

    componentDidMount() {
        agenciesApi.getById(this.props.match.params.id).then(result => {
            this.setState({
                agency: result,
            });
        });
    }

    _handleBrokerRowClick = id => {
        this.props.history.push(routeHelpers.brokerDetail(id));
    };

    /*_goToAddAgency = () => {
        this.props.history.push(routeHelpers.editAgency());
    };*/

    _logoSelected = droppedFiles => {
        //check for xls
        var file = droppedFiles[0];
        const nm = file.name.toLowerCase();
        if (!nm.endsWith(".png") && !nm.endsWith(".jpg") && !nm.endsWith(".jpeg") && !nm.endsWith(".gif"))
        {
            toast.error("Please use a valid Image file (.png, .jpg, .jpeg, .gif)", { toastId: 1 });
            return;
        }
        //continue
        const { agency } = this.state;
        brokersApi.uploadLogo(agency.brokers[0].id, file)
            .then(response => {
                this.setState({ agency: response });
            });                
    }

    _deleteLogo = () => {
        if (!window.confirm("Are you sure you want to delete the logo for this agency?")) {
            return;
        }

        const { agency } = this.state;
        brokersApi.deleteLogo(agency.brokers[0].id)
            .then((response) => {
                this.setState({ agency: response });
            });
    }

    renderGeneralInfo = () => {
        const { agency } = this.state;
        let logoUrl = "";
        if (agency.brokers && agency.brokers.length > 0) {
            logoUrl = process.env.REACT_APP_API_ROOT + "/logo/getBrokerLogo/" + agency.brokers[0].id + "?" + new Date().getTime();
        }

        return (
            <div className={css(styles.tabContent)}>
                <div className={css(styles.row)}>
                    <div className={css(styles.column)}>
                        <div className={css(styles.row)}>
                            <div className={css(styles.heading)}>Locations</div>
                            <span className={css(styles.item, styles.active)}>{agency.city ? agency.city : "na"} (Primary)</span>
                        </div>
                        <div className={css(styles.row)}>
                            <div className={css(styles.heading)}>Agency Logo</div>
                            {agency.brokers && agency.brokers.length > 0 && !agency.brokers[0].hasLogo &&
                                <Dropzone onDrop={this._logoSelected}>
                                    {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className={css(styles.logoContainer)}>
                                            Drop your logo here or <FileBrowser onSelect={this._logoSelected} disableClick={true} /> for image.
                                        </div>
                                    </div>
                                    )}
                                </Dropzone>
                            }
                            {agency.brokers && agency.brokers.length > 0 && agency.brokers[0].hasLogo &&
                                <React.Fragment>
                                    <div className={css(styles.uploadLogo)}>
                                        <span className={css(styles.deleteLogo)} onClick={this._deleteLogo}>Delete</span>
                                        <span className={css(styles.divider)}>|</span>
                                        <FileBrowser onSelect={this._logoSelected} />
                                    </div>
                                    <img 
                                        className={css(styles.brokerLogo)}
                                        src={logoUrl}
                                        alt="Logo"
                                    />
                                </React.Fragment>
                            }
                        </div>
                    </div>
                    <div className={css(styles.column)}>
                        <div className={css(styles.row)}>
                            <div className={css(styles.heading)}>{agency.city ? agency.city : "na"}</div>
                            <span className={css(styles.item, styles.smaller)}>Address</span>
                            <span className={css(styles.item)}>{agency.street1}</span>
                            {agency.street2 && 
                                <span className={css(styles.item)}>{agency.street2}</span>
                            }
                            <span className={css(styles.item)}>{agency.city}{" "}{agency.state}</span>
                            <span className={css(styles.item, styles.lineBreak)}>{agency.postalCode}</span>
                            <span className={css(styles.item, styles.smaller)}>Phone #</span>
                            <span className={css(styles.item, styles.lineBreak)}>{agency.phone}</span>
                            <span className={css(styles.item, styles.smaller)}>Contact Person</span>
                            <span className={css(styles.item, styles.lineBreak)}>{"na"}</span>
                        </div>
                    </div>
                    <div className={css(styles.column)}>
                        <div className={css(styles.row)}>
                            <div className={css(styles.heading)}>
                                {agency.brokers && 
                                    <div className={css(styles.right)}>
                                        <span className={css(styles.topRightText)}>{agency.brokers.length === 1 ? "1 Broker" : agency.brokers.length + " Brokers"}</span>
                                    </div>
                                }
                                Brokers
                            </div>
                            {agency.brokers && agency.brokers.map(b => {
                                return (
                                    <div key={b.id} className={css(styles.broker)} onClick={() => this._handleBrokerRowClick(b.id)}>
                                        <div className={css(styles.chevronHolder)}>
                                            <ChevronDown className={css(styles.chevron)} />   
                                        </div>                                                     
                                        <span className={css(styles.item, styles.bolder)}>{b.fullName}</span>
                                        <span className={css(styles.item)}>{b.phone}</span>
                                        <span className={css(styles.item)}>{b.emailAddress}</span>
                                        <span className={css(styles.item, styles.lineBreak)}>{b.brokerType}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { agency } = this.state;
        return (
            <div className={css(styles.content)}>
                <ToastContainer position="top-center" autoClose={2500} />
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.titleSection)}>
                        {/*<div className={css(styles.topRightHolder)} onClick={this._goToAddAgency}>
                            <span className={css(styles.topRightText)}>{strings.addAgency}</span>
                            <img alt="" src={blueCircleIcon} className={css(styles.blueCircle)} />
                        </div>*/}
                        <PageTitle>Agency: {agency.name} {agency.deleted ? "(DELETED)" : ""}</PageTitle>
                    </div>
                    <Tabs history={this.props.history} backUrl={routeHelpers.brokers()}>
                        <div label="General Info">
                            {this.renderGeneralInfo()}
                        </div>
                        <div label=".">
                        </div>
                    </Tabs>
                </div>
            </div>
        );
    }
}
