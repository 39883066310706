import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import * as color from "../../constants/color";

const styles = StyleSheet.create({
	container: {
		marginTop: 4,
	},
	list: {
		listStyleType: "none",
		margin: 0,
		padding: 0,
		marginRight: 12,
	},
	item: {
		cursor: "pointer",
		fontSize: 14,
		width: 48,		
		display: "block",
		float: "left",
		paddingTop: 12,
		paddingBottom: 12,
		textAlign: "center",
		borderRadius: 5,
		margin: 2,
		":nth-child(4n+1)": {
			clear: "both",
		},
		":hover": {
			backgroundColor: color.lightYellow1,
		},
	},
	active: {
		backgroundColor: color.mediumYellow1,
		color: color.white,
		":hover": {
			backgroundColor: color.mediumYellow1,			
		},
	},	
});

const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

export default class MonthInput extends Component {
	static propTypes = {        
		value: PropTypes.number,
        onChange: PropTypes.func.isRequired,
        
    };

    state = {
		selectedMonth: null,
    };

    _monthClicked = e => {
		const { target } = e;
		let index = parseInt(target.getAttribute("data-id"));

		if (index === this.props.value) {
			index = null;
		}

		this.props.onChange(index);
    };

    render() {
		return (
			<div className={css(styles.container)}>
				<ul className={css(styles.list)}>
					{months.map((month, index) => {
						return (
							<li 
								key={index} 
								data-id={index + 1}
								className={css(
									styles.item,
									this.props.value === index + 1 ? styles.active : null
								)}				
								onClick={this._monthClicked}
							>
								{months[index]}
							</li>								
						)
					})}	
				</ul>									
			</div>
        );
    }
}