import * as color from "../../constants/color";
import * as routeHelpers from "../../lib/routeHelpers";
import * as texchangePropTypes from "../../texchangePropTypes";
import SearchInput from "../inputs/SearchInput";
import PageTitle from "../text/PageTitle";
import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import blueCircleIcon from "../../images/blue-circle.png";
import Infinite from "../Infinite";
import * as officesApi from "../../lib/api/offices";
import * as searchApi from "../../lib/api/search";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = StyleSheet.create({
    content: {
        paddingRight: 10,
        paddingBottom: 80,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
    },
    contentSection: {
        width: "100%",
        padding: 0,
        backgroundColor: color.white,
        borderTop: `8px solid ${color.mediumRed}`,
        marginBottom: 20,
    },
    titleSection: {
        heigth: 64,
        backgroundColor: color.white,
    },
    searchBox: {
        maxWidth: 480,
        textAlign: "center",
        margin: "30px auto 30px auto",
    },
    right: {
        float: "right",
        position: "relative",
        top: -38,
        right: 20,
        fontSize: 14,
        color: color.tableText,
    },
    addPersonnel: {
        float: "right",
        cursor: "pointer",
        marginTop: 8,
        marginRight: 24,
    },
    addPersonnelText: {
        display: "inline-block",
        fontSize: 14,
    },
    blueCircle: {
        paddingTop: 6,
        marginLeft: 12,
        position: "relative",
        top: 4,
    },
    noResults: {
        backgroundColor: color.white,
        color: color.darkGray,
        padding: 20,
        marginBottom: 40,
    },
});

const strings = {
    title: "Personnel",
    searchPlaceholder: "Search by First or Last Name",
    addPersonnel: "Add Employee",
};

const inputNames = {
    search: "search",
};

export default class PersonnelScreen extends Component {
    static propTypes = {
        history: texchangePropTypes.history.isRequired,
    };

    state = {
        allPersonnel: [],
        allPersonnelCount: 0,
        allPersonnelMore: false,
        personnelColumnLabels: ["Name", "Job Title", "Phone #", "Email", "Rating Engine Role(s)", "SalesForceID"],
        personnelColumnValues: ["fullName", "jobTitle", "phone", "email", "roles", "salesForceId"],
        inputs: {
            [inputNames.search]: "",
        },
        loading: false,
    };

    componentDidMount() {
        this._mounted = true;
        this.setState({ loading: true });
        officesApi.getPersonnel(1, null, null).then(result => {
            this.setState({ loading: false });
            if (result && result[0] && this._mounted) {
                this.setState({
                    allPersonnel: result,
                    allPersonnelCount: result[0].recordCount,
                    allPersonnelMore: result[0].recordCount > 100,
                });
            }
        });
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    _searchResultSelected = (item) => {
        if (item.type === "Personnel") {
            this.props.history.push(routeHelpers.personnelDetail(item.value));
        }
    };

    _handlePersonnelRowClick = id => {
        this.props.history.push(routeHelpers.personnelDetail(id));
    };

    _allPersonnelScroll = (page, col, dir) => {
        if (((page - 1) * 100) < this.state.allPersonnelCount) {
            officesApi.getPersonnel(page, col, dir).then(result => {
                let joined = {};
                if (page === 1) {
                    joined = result;
                } else {
                    joined = this.state.allPersonnel.concat(result);
                }
                this.setState({
                    allPersonnel: joined,
                });
            });
        }
        else {
            this.setState({
                allPersonnelMore: false,
            });
        }
    };

    renderPersonnel = () => {
        let personnel = this.state.allPersonnel;
        let count = this.state.allPersonnelCount;
        let more = this.state.allPersonnelMore;
        let fnScroll = this._allPersonnelScroll;
        let columnLabels = this.state.personnelColumnLabels;
        let columnValues = this.state.personnelColumnValues;
        let handleRowClick = this._handlePersonnelRowClick;
        return (
            <div className={css(styles.contentSection)}>
                <div className={css(styles.right)}>{count}{" Employees"}</div>
                {personnel.length > 0 ? (
                    <Infinite
                        results={personnel}
                        length={personnel.length}
                        columnLabels={columnLabels}
                        columnValues={columnValues}
                        showChevron={true}
                        showMore={more}
                        onClick={handleRowClick}
                        onScroll={fnScroll} />
                ) : (
                    <div className={css(styles.noResults)}>{this.state.loading ? "Loading " : "No Matching Employees"}</div>
                )}
            </div>
        );
    }

    _goToAddPersonnel = () => {
        toast.info("Add Employee Coming Soon", { toastId: 1 });
        //this.props.history.push(routeHelpers.editPersonnel());
    };

    render() {
        return (
            <div className={css(styles.content)}>
                <ToastContainer position="top-center" autoClose={2500} />
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.titleSection)}>
                        {<div className={css(styles.addPersonnel)} onClick={this._goToAddPersonnel}>
                            <span className={css(styles.addPersonnelText)}>{strings.addPersonnel}</span>
                            <img alt="" src={blueCircleIcon} className={css(styles.blueCircle)} />
                        </div>}
                        <PageTitle>{strings.title}</PageTitle>
                    </div>
                    <div className={css(styles.personnel)}>
                        <div>
                            <SearchInput
                                aStyles={styles.searchBox}
                                search={searchApi.searchPersonnel}
                                onItemSelected={this._searchResultSelected}
                                placeholder={strings.searchPlaceholder}
                                hideLabel={true}
                            />
                        </div>
                        {this.renderPersonnel()}
                    </div>
                </div>
            </div>
        );
    }
}
