import * as actionTypes from "../constants/actionTypes";
import createReducer from "../lib/createReducer";

const defaultState = {
    accessToken: null,
    expireDate: null,
    refreshToken: null,
    scopes: [],
    me: null,
};

function SESSION_SIGN_IN(state, action) {
    const { accessToken, expireDate, refreshToken, scopes } = action;

    return {
        ...state,
        accessToken,
        expireDate,
        refreshToken,
        scopes,
    };
}

function SESSION_SIGN_OUT() {
    return defaultState;
}

function SESSION_SET_ME(state, action) {
    return {
        ...state,
        me: action.me,
    };
}

function SESSION_UPDATE_ME(state, action) {
    return {
        ...state,
        me: action.me,
    };
}

export default createReducer(defaultState, {
    [actionTypes.SESSION_SIGN_IN]: SESSION_SIGN_IN,
    [actionTypes.SESSION_SIGN_OUT]: SESSION_SIGN_OUT,
    [actionTypes.SESSION_SET_ME]: SESSION_SET_ME,
    [actionTypes.SESSION_UPDATE_ME]: SESSION_UPDATE_ME,
});