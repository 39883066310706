import * as breakpoints from "../constants/breakpoints";
import * as color from "../constants/color";
import * as routeHelpers from "../lib/routeHelpers";
import * as zIndexes from "../constants/zIndexes";
import React from "react";
import SmallLink from "./text/SmallLink";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    content: {
        width: "100%",
        backgroundColor: color.lightGray3,
        position: "relative",
        zIndex: zIndexes.FOOTER,
    },
    links: {
        textAlign: "center",
        padding: 25,
    },
    linkContainer: {
        textAlign: "left",
        marginLeft: 25,
        marginRight: 25,
        height: 30,
        /*height: 130, --removed 100px height from footer*/
        [breakpoints.mobileOnly]: {
            display: "block",
        },
        [breakpoints.tabletAndUp]: {
            display: "inline-block",
        },
    },
    linkHeader: {
        display: "block",
        fontSize: 14,
        fontWeight: 500,
    },
    link: {
        color: color.black,
        display: "block",
        paddingTop: 8,
        paddingBottom: 8,
        fontSize: 12,
        fontWeight: 300,
    },
    darkRedRBG: {
        backgroundColor: color.darkRed,
        color: color.white,
        fontSize: 14,
        padding: 14,
    }
});

const strings = {
    linkHeader: "",
    link: "",
    copyright: `Copyright © ${new Date().getFullYear()} Emerson Rogers`,
};

export default function Footer() {
    // TODO: Link to privacy policy, and contact us screens
    // TODO: Link to instagram, linked in
    const home = routeHelpers.home();
    const terms = routeHelpers.terms();

    return (
        <div className={css(styles.content)}>
            <div className={css(styles.links)}>
                <div className={css(styles.linkContainer)}>
                    <span className={css(styles.linkHeader)}>{strings.linkHeader}</span>
                    <SmallLink to={terms} aStyles={styles.link}>
                        Terms of Use
                    </SmallLink>
                    <SmallLink to={home} aStyles={styles.link}>
                        {strings.link}
                    </SmallLink>
                    <SmallLink to={home} aStyles={styles.link}>
                        {strings.link}
                    </SmallLink>
                    <SmallLink to={home} aStyles={styles.link}>
                        {strings.link}
                    </SmallLink>
                </div>
                <div className={css(styles.linkContainer)}>
                    <span className={css(styles.linkHeader)}>{strings.linkHeader}</span>
                    <SmallLink to={home} aStyles={styles.link}>
                        {strings.link}
                    </SmallLink>
                    <SmallLink to={home} aStyles={styles.link}>
                        {strings.link}
                    </SmallLink>
                    <SmallLink to={home} aStyles={styles.link}>
                        {strings.link}
                    </SmallLink>
                    <SmallLink to={home} aStyles={styles.link}>
                        {strings.link}
                    </SmallLink>
                </div>
                <div className={css(styles.linkContainer)}>
                    <span className={css(styles.linkHeader)}>{strings.linkHeader}</span>
                    <SmallLink to={home} aStyles={styles.link}>
                        {strings.link}
                    </SmallLink>
                    <SmallLink to={home} aStyles={styles.link}>
                        {strings.link}
                    </SmallLink>
                    <SmallLink to={home} aStyles={styles.link}>
                        {strings.link}
                    </SmallLink>
                    <SmallLink to={home} aStyles={styles.link}>
                        {strings.link}
                    </SmallLink>
                </div>
                <div className={css(styles.linkContainer)}>
                    <span className={css(styles.linkHeader)}>{strings.linkHeader}</span>
                    <SmallLink to={home} aStyles={styles.link}>
                        {strings.link}
                    </SmallLink>
                    <SmallLink to={home} aStyles={styles.link}>
                        {strings.link}
                    </SmallLink>
                    <SmallLink to={home} aStyles={styles.link}>
                        {strings.link}
                    </SmallLink>
                    <SmallLink to={home} aStyles={styles.link}>
                        {strings.link}
                    </SmallLink>
                </div>
            </div>
            <div className={css(styles.darkRedRBG)}>
                {strings.copyright}
            </div>
        </div>
    );
}
