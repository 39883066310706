import * as breakpoints from "../../constants/breakpoints";
import * as color from "../../constants/color";
import * as texchangePropTypes from "../../texchangePropTypes";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    text: {
        color: color.mediumYellow1,
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.22,
        textDecoration: "none",

        [breakpoints.desktopOnly]: {
            ":hover": {
                textDecoration: "underline",
            },
        },
    },
});

export default function SmallLink(props) {
    const { aStyles, children, ...rest } = props;
    return (
        <Link {...rest} className={css(styles.text, aStyles)}>
            {children}
        </Link>
    );
}

SmallLink.propTypes = {
    aStyles: texchangePropTypes.aphroditeStyles,
    children: PropTypes.node,
};
