import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from "react-accessible-accordion";
import Infinite from "../Infinite";
import Modal from "../Modal";
import ActionButton from "../ActionButton";
import * as color from "../../constants/color";
import * as texchangePropTypes from "../../texchangePropTypes";
import * as proposalActions from "../../actions/proposalActions";
import * as planColumns from "../../constants/planColumns";
import * as util from "../../lib/util";
import * as quoteApi from "../../lib/api/quotes";
import garbageIconUrl from "../../images/SVGs/garbage.svg";
import { ReactComponent as AlertErrorIcon } from "../../images/alert-error.svg";

import "../../styles/accordion.css";

const styles = StyleSheet.create({
	header: {
		backgroundColor: color.mediumGray2,
		padding: "18px 24px",
		fontSize: 14,
		borderBottom: `1px solid ${color.darkGray2}`,
	},
	clear: {
		clear: "both",
	},
	removeAllPlans: {
		color: color.mediumYellow1,
		float: "right",
		cursor: "pointer",
		marginRight: 32,
	},
	planCount: {
		color: color.darkGray,
		float: "right",
	},
	coverageHeader: {
		display: "flex",
	},
	removeButton: {
		cursor: "pointer",
	},
	coverageLabel: {
		color: color.darkestGray,
		fontSize: 16,
		fontWeight: 500,
		marginLeft: 32,
	},
	carrierHeader: {
		display: "flex",
	},
	carrierLabel: {
		color: color.darkestGray,
		fontSize: 14,
		fontWeight: 500,
		marginLeft: 42,
	},
	networkHeader: {
		display: "flex",
	},
	networkLabel: {
		color: color.darkestGray,
		fontSize: 14,
		marginLeft: 56,
	},
	modalContent: {
		width: 386,
	},
	modalInstructions: {
		color: color.darkestGray,
		fontSize: 16,
		marginBottom: 8,
		paddingLeft: 22,
		paddingRight: 22,
	},
	modalButtonContainer: {
		marginTop: 24,
		display: "flex",
		justifyContent: "space-between",
		width: 340,
		marginLeft: "auto",
		marginRight: "auto",
	},
	modalButton: {
		width: 150,
	},
	alertIcon: {
		display: "block",
		margin: "0px auto 20px auto",
	},
});

export class SelectedPlans extends Component {
	static propTypes = {
		quote: texchangePropTypes.quote.isRequired,
		readonly: PropTypes.bool,
		selectedPlans: PropTypes.object.isRequired,
		setQuote: PropTypes.func.isRequired,
		setSelectedPlans: PropTypes.func.isRequired,
	};

	state = {
		showRemoveModal: false,
		removeModalHeaderText: "",
		removeModalText: "",
		removeLob: "",
		removePlanType: "",
		removeType: "",
		removeValue: "",
		isSaving: false,
		showRemoveAllModal: false,
	};

	_removeAllPlans = (e) => {
		if (e) {
			e.stopPropagation();
		}

		if (!this.state.showRemoveAllModal) {
			this.setState({
				showRemoveAllModal: true
			});
		} else {
			const { quote } = this.props;
			let promises = [];

			//Medical
			quote.currentMedicalPlans.forEach(item => {
				promises.push(quoteApi.saveCurrentRenewal(quote.id, item.id.toString(), "medical", false, true, null));
			});
			quote.renewalMedicalPlans.forEach(item => {
				promises.push(quoteApi.saveCurrentRenewal(quote.id, item.id.toString(), "medical", false, false, null));
			});
			//Dental
			quote.currentDentalPlans.forEach(item => {
				promises.push(quoteApi.saveCurrentRenewal(quote.id, item.id.toString(), "dental", false, true, null));
			});
			quote.renewalDentalPlans.forEach(item => {
				promises.push(quoteApi.saveCurrentRenewal(quote.id, item.id.toString(), "dental", false, false, null));
			});
			//Vision
			quote.currentVisionPlans.forEach(item => {
				promises.push(quoteApi.saveCurrentRenewal(quote.id, item.id.toString(), "vision", false, true, null));
			});
			quote.renewalVisionPlans.forEach(item => {
				promises.push(quoteApi.saveCurrentRenewal(quote.id, item.id.toString(), "vision", false, false, null));
			});
			//Life
			quote.currentLifePlans.forEach(item => {
				promises.push(quoteApi.saveCurrentRenewal(quote.id, item.id.toString(), "life", false, true, null));
			});
			quote.renewalLifePlans.forEach(item => {
				promises.push(quoteApi.saveCurrentRenewal(quote.id, item.id.toString(), "life", false, false, null));
			});

			this.setState({ isSaving: true });

			Promise.all(promises)
				.then(() => {
					quoteApi.getById(quote.id)
						.then(response => {
							this.props.setQuote(response);
							this.props.setSelectedPlans({ medical: [], dental: [], vision: [], life: [] }, [], null);
							this.setState({
								showRemoveAllModal: false,
								isSaving: false,
							});
						});
				});
		}
	};

	_removeClicked = (e, removeLob, removePlanType, removeType, removeValue, planCount) => {
		if (e) {
			e.stopPropagation();
		}

		if (!this.state.showRemoveModal) {
			let removeModalText = "";
			if (removeType === "coverage") {
				removeModalText = `You are about to remove <b>${removeLob}</b> coverage and <b>${planCount}</b> plans from your selections.`;
			} else if (removeType === "carrier") {
				removeModalText = `You are about to remove the carrier <b>${removeValue}</b> and <b>${planCount}</b> plans from your selections.`;
			} else if (removeType === "network") {
				removeModalText = `You are about to remove the network <b>${removeValue}</b> and <b>${planCount}</b> plans from your selections.`;
			}

			this.setState({
				showRemoveModal: true,
				removeLob,
				removePlanType,
				removeType,
				removeValue,
				removeModalHeaderText: `Remove ${util.capitalize(removeType)}`,
				removeModalText,
			});
		} else {
			if (removePlanType === "Current") {
				this._removeCurrentPlanByType(removeLob, removeType);
			} else if (removePlanType === "Renewal") {
				this._removeRenewalPlanByType(removeLob, removeType);
			} else {
				this._removeNewPlanByType(removeLob, removeType, removeValue);
			}
		}
	};

	_removeCurrentPlanByType = (lob, type) => {
		const { quote } = this.props;
		if (type === "coverage") {
			let promises = [];
			quote.currentMedicalPlans.forEach(item => {
				promises.push(quoteApi.saveCurrentRenewal(quote.id, item.id.toString(), lob, false, true, null));
			});
			this.setState({ isSaving: true });

			Promise.all(promises)
				.then(() => {
					quoteApi.getById(quote.id)
						.then(response => {
							this.props.setQuote(response);
							this.setState({
								showRemoveModal: false,
								isSaving: false,
							});
						});
				});
		}
	};

	_removeRenewalPlanByType = (lob, type) => {
		const { quote } = this.props;
		if (type === "coverage") {
			let promises = [];
			quote.renewalMedicalPlans.forEach(item => {
				promises.push(quoteApi.saveCurrentRenewal(quote.id, item.id.toString(), lob, false, false, null));
			});

			this.setState({ isSaving: true });

			Promise.all(promises)
				.then(() => {
					quoteApi.getById(quote.id)
						.then(response => {
							this.props.setQuote(response);
							this.setState({
								showRemoveModal: false,
								isSaving: false,
							});
						});
				});
		}
	};

	_removeNewPlanByType = (lob, type, value) => {
		const { selectedPlans } = this.props;
		let selectedNewPlans = [];
		if (type === "coverage") {
			selectedNewPlans = [];
		} else if (type === "carrier" && lob === "medical" && selectedPlans.medical) {
			selectedNewPlans = Object.assign([], selectedPlans.medical.filter(sp => sp.carrierName !== value));
		} else if (type === "carrier" && lob === "dental" && selectedPlans.dental) {
			selectedNewPlans = Object.assign([], selectedPlans.dental.filter(sp => sp.carrierName !== value));
		} else if (type === "carrier" && lob === "vision" && selectedPlans.vision) {
			selectedNewPlans = Object.assign([], selectedPlans.vision.filter(sp => sp.carrierName !== value));
		} else if (type === "carrier" && lob === "life" && selectedPlans.life) {
			selectedNewPlans = Object.assign([], selectedPlans.life.filter(sp => sp.carrierName !== value));
		} else if (type === "network" && lob === "medical" && selectedPlans.medical) {
			selectedNewPlans = Object.assign([], selectedPlans.medical.filter(sp => sp.networkName !== value));
		} else if (type === "network" && lob === "dental" && selectedPlans.dental) {
			selectedNewPlans = Object.assign([], selectedPlans.dental.filter(sp => sp.networkName !== value));
		} else if (type === "network" && lob === "vision" && selectedPlans.vision) {
			selectedNewPlans = Object.assign([], selectedPlans.vision.filter(sp => sp.networkName !== value));
		}

		//reset
		this.props.setSelectedPlans(selectedPlans, selectedNewPlans, lob);
		this.setState({
			showRemoveModal: false,
		});
	};

	_removeNewMedicalPlan = id => {
		this._removeNewPlan(id, "medical");
	};

	_removeNewDentalPlan = id => {
		this._removeNewPlan(id, "dental");
	};

	_removeNewVisionPlan = id => {
		this._removeNewPlan(id, "vision");
	};

	_removeNewLifePlan = id => {
		this._removeNewPlan(id, "life");
	};

	_removeNewPlan = (id, lob) => {
		const { selectedPlans } = this.props;
		let selectedNewPlans = [];
		if (lob === "medical" && selectedPlans.medical) {
			selectedNewPlans = Object.assign([], selectedPlans.medical);
		} else if (lob === "dental" && selectedPlans.dental) {
			selectedNewPlans = Object.assign([], selectedPlans.dental);
		} else if (lob === "vision" && selectedPlans.vision) {
			selectedNewPlans = Object.assign([], selectedPlans.vision);
		} else if (lob === "life" && selectedPlans.life) {
			selectedNewPlans = Object.assign([], selectedPlans.life);
		}
		const selectedPlan = selectedNewPlans.find(p => p.id === id);
		if (selectedPlan) {
			const index = selectedNewPlans.indexOf(selectedPlan);
			if (index > -1) {
				selectedNewPlans.splice(index, 1);
			}
			this.props.setSelectedPlans(selectedPlans, selectedNewPlans, lob);
		}
	};

	_removeCurrentMedicalPlan = id => {
		this._removeCurrentPlan(id, "medical");
	};

	_removeCurrentDentalPlan = id => {
		this._removeCurrentPlan(id, "dental");
	};

	_removeCurrentVisionPlan = id => {
		this._removeCurrentPlan(id, "vision");
	};

	_removeCurrentLifePlan = id => {
		this._removeCurrentPlan(id, "life");
	};

	_removeCurrentPlan = (id, lob) => {
		const { quote } = this.props;
		quoteApi.saveCurrentRenewal(quote.id, id.toString(), lob, false, true, null)
			.then(() => {
				quoteApi.getById(quote.id)
					.then(response => {
						this.props.setQuote(response);
					});
			});
	};

	_removeRenewalMedicalPlan = id => {
		this._removeRenewalPlan(id, "medical");
	};

	_removeRenewalDentalPlan = id => {
		this._removeRenewalPlan(id, "dental");
	};

	_removeRenewalVisionPlan = id => {
		this._removeRenewalPlan(id, "vision");
	};

	_removeRenewalLifePlan = id => {
		this._removeRenewalPlan(id, "life");
	};

	_removeRenewalPlan = (id, lob) => {
		const { quote } = this.props;
		quoteApi.saveCurrentRenewal(quote.id, id.toString(), lob, false, false, null)
			.then(() => {
				quoteApi.getById(quote.id)
					.then(response => {
						this.props.setQuote(response);
					});
			});
	};

	_renderCurrentOrRenewalPlans = planType => {
		const { quote } = this.props;
		const { readonly } = this.props;

		//Medical
		const plansM = planType === "Current" ? quote.currentMedicalPlans : quote.renewalMedicalPlans;
		const labelsM = planColumns["medicalColumnLabels"];
		const valuesM = planColumns["medicalColumnValues"];
		const selectedPlansM = quote[`${planType.toLowerCase()}MedicalPlans`].map(p => {
			return p.id;
		});
		const handleRemovePlanM = planType === "Current" ? this._removeCurrentMedicalPlan : this._removeRenewalMedicalPlan;

		//Dental
		const plansD = planType === "Current" ? quote.currentDentalPlans : quote.renewalDentalPlans;
		const labelsD = planColumns["dentalColumnLabels"];
		const valuesD = planColumns["dentalColumnValues"];
		const selectedPlansD = quote[`${planType.toLowerCase()}DentalPlans`].map(p => {
			return p.id;
		});
		const handleRemovePlanD = planType === "Current" ? this._removeCurrentDentalPlan : this._removeRenewalDentalPlan;

		//Vision
		const plansV = planType === "Current" ? quote.currentVisionPlans : quote.renewalVisionPlans;
		const labelsV = planColumns["visionColumnLabels"];
		const valuesV = planColumns["visionColumnValues"];
		const selectedPlansV = quote[`${planType.toLowerCase()}VisionPlans`].map(p => {
			return p.id;
		});
		const handleRemovePlanV = planType === "Current" ? this._removeCurrentVisionPlan : this._removeRenewalVisionPlan;

		//Life
		const plansL = planType === "Current" ? quote.currentLifePlans : quote.renewalLifePlans;
		const labelsL = planColumns["lifeColumnLabels"];
		const valuesL = planColumns["lifeColumnValues"];
		const selectedPlansL = quote[`${planType.toLowerCase()}LifePlans`].map(p => {
			return p.id;
		});
		const handleRemovePlanL = planType === "Current" ? this._removeCurrentLifePlan : this._removeRenewalLifePlan;

		return (
			<AccordionItem>
				<AccordionItemHeading className="plan-type-header">
					<AccordionItemButton>{planType} Plans ({selectedPlansM.length + selectedPlansD.length + selectedPlansV.length + selectedPlansL.length})</AccordionItemButton>
				</AccordionItemHeading>
				<AccordionItemPanel className="plan-type-panel">
					<Accordion
						allowMultipleExpanded={true}
						allowZeroExpanded={true}
					>
						{selectedPlansM.length > 0 &&
							<AccordionItem>
								<AccordionItemHeading className="coverage-header">
									<AccordionItemButton>
										<div className={css(styles.coverageHeader)}>
											{!readonly &&
												<img src={garbageIconUrl} className={css(styles.removeButton)} onClick={e => this._removeClicked(e, "medical", planType, "coverage", "medical", selectedPlansM.length)} alt="remove"></img>
											}
											<div className={css(styles.coverageLabel)}>Medical ({selectedPlansM.length})</div>
										</div>
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel className="plans">
									<Infinite
										results={plansM}
										length={plansM.length}
										columnLabels={labelsM}
										columnValues={valuesM}
										showChevron={false}
										checkBox={!readonly}
										checkList={selectedPlansM}
										showMore={false}
										onClick={handleRemovePlanM}
										onScroll={() => { }}
									/>
								</AccordionItemPanel>
							</AccordionItem>
						}
						{selectedPlansD.length > 0 &&
							<AccordionItem>
								<AccordionItemHeading className="coverage-header">
									<AccordionItemButton>
										<div className={css(styles.coverageHeader)}>
											{!readonly &&
												<img src={garbageIconUrl} className={css(styles.removeButton)} onClick={e => this._removeClicked(e, "dental", planType, "coverage", "dental", selectedPlansD.length)} alt="remove"></img>
											}
											<div className={css(styles.coverageLabel)}>Dental ({selectedPlansD.length})</div>
										</div>
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel className="plans">
									<Infinite
										results={plansD}
										length={plansD.length}
										columnLabels={labelsD}
										columnValues={valuesD}
										showChevron={false}
										checkBox={!readonly}
										checkList={selectedPlansD}
										showMore={false}
										onClick={handleRemovePlanD}
										onScroll={() => { }}
									/>
								</AccordionItemPanel>
							</AccordionItem>
						}
						{selectedPlansV.length > 0 &&
							<AccordionItem>
								<AccordionItemHeading className="coverage-header">
									<AccordionItemButton>
										<div className={css(styles.coverageHeader)}>
											{!readonly &&
												<img src={garbageIconUrl} className={css(styles.removeButton)} onClick={e => this._removeClicked(e, "vision", planType, "coverage", "vision", selectedPlansV.length)} alt="remove"></img>
											}
											<div className={css(styles.coverageLabel)}>Vision ({selectedPlansV.length})</div>
										</div>
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel className="plans">
									<Infinite
										results={plansV}
										length={plansV.length}
										columnLabels={labelsV}
										columnValues={valuesV}
										showChevron={false}
										checkBox={!readonly}
										checkList={selectedPlansV}
										showMore={false}
										onClick={handleRemovePlanV}
										onScroll={() => { }}
									/>
								</AccordionItemPanel>
							</AccordionItem>
						}
						{/*{selectedPlansL.length > 0 &&
							<AccordionItem>
								<AccordionItemHeading className="coverage-header">					                					    							                	
									<AccordionItemButton>
										<div className={css(styles.coverageHeader)}>
											{!readonly &&
												<img src={garbageIconUrl} className={css(styles.removeButton)} onClick={e => this._removeClicked(e, "life", planType, "coverage", "life", selectedPlansL.length)} alt="remove"></img>
											}
											<div className={css(styles.coverageLabel)}>Life ({selectedPlansL.length})</div>
										</div>			                    	
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel className="plans">
									<Infinite 
										results={plansL} 
										length={plansL.length}
										columnLabels={labelsL}
										columnValues={valuesL}
										showChevron={false}
										checkBox={!readonly}
										checkList={selectedPlansL}
										showMore={false}
										onClick={handleRemovePlanL}
										onScroll={() => {}}
									/>
								</AccordionItemPanel>
							</AccordionItem>
						*/}
					</Accordion>
				</AccordionItemPanel>
			</AccordionItem>
		);
	};

	_renderNewPlans = () => {
		let { selectedPlans } = this.props;
		const { readonly } = this.props;
		if (readonly) {
			selectedPlans.medical = this.props.quote.newMedicalPlans;
			selectedPlans.dental = this.props.quote.newDentalPlans;
			selectedPlans.vision = this.props.quote.newVisionPlans;
			selectedPlans.life = this.props.quote.newLifePlans;
		}
		//const carriers = Array.from(new Set(selectedPlans.medical.map(sp => sp.carrierName))).sort();

		let groupBy = (array, key) => {
			return array.reduce((rv, x) => {
				(rv[x[key]] = rv[x[key]] || []).push(x);
				return rv;
			}, {});
		};

		//Medical
		let groupedPlansM = [];
		let planCountByCarrierM = {};
		let selectedPlanIdsM = [];
		const labelsM = planColumns["medicalColumnLabels"];
		const valuesM = planColumns["medicalColumnValues"];
		if (selectedPlans.medical) {
			groupedPlansM = groupBy(selectedPlans.medical, "carrierName");
			Object.keys(groupedPlansM).forEach(key => {
				groupedPlansM[key] = groupBy(groupedPlansM[key], "networkName");
				planCountByCarrierM[key] = selectedPlans.medical.filter(sp => sp.carrierName === key).length;
			});
			selectedPlanIdsM = selectedPlans.medical.map(sp => sp.id);
		}

		//Dental
		let groupedPlansD = [];
		let planCountByCarrierD = {};
		let selectedPlanIdsD = [];
		const labelsD = planColumns["dentalColumnLabels"];
		const valuesD = planColumns["dentalColumnValues"];
		if (selectedPlans.dental) {
			groupedPlansD = groupBy(selectedPlans.dental, "carrierName");
			Object.keys(groupedPlansD).forEach(key => {
				groupedPlansD[key] = groupBy(groupedPlansD[key], "networkName");
				planCountByCarrierD[key] = selectedPlans.dental.filter(sp => sp.carrierName === key).length;
			});
			selectedPlanIdsD = selectedPlans.dental.map(sp => sp.id);
		}

		//Vision
		let groupedPlansV = [];
		let planCountByCarrierV = {};
		let selectedPlanIdsV = [];
		const labelsV = planColumns["visionColumnLabels"];
		const valuesV = planColumns["visionColumnValues"];
		if (selectedPlans.vision) {
			groupedPlansV = groupBy(selectedPlans.vision, "carrierName");
			Object.keys(groupedPlansV).forEach(key => {
				groupedPlansV[key] = groupBy(groupedPlansV[key], "networkName");
				planCountByCarrierV[key] = selectedPlans.vision.filter(sp => sp.carrierName === key).length;
			});
			selectedPlanIdsV = selectedPlans.vision.map(sp => sp.id);
		}

		//Life
		let groupedPlansL = [];
		let planCountByCarrierL = {};
		let selectedPlanIdsL = [];
		const labelsL = planColumns["lifeColumnLabels"];
		const valuesL = planColumns["lifeColumnValues"];
		if (selectedPlans.life) {
			groupedPlansL = groupBy(selectedPlans.life, "carrierName");
			Object.keys(groupedPlansL).forEach(key => {
				planCountByCarrierL[key] = selectedPlans.life.filter(sp => sp.carrierName === key).length;
			});
			selectedPlanIdsL = selectedPlans.life.map(sp => sp.id);
		}

		return (
			<AccordionItem>
				<AccordionItemHeading className="plan-type-header">
					<AccordionItemButton>New Plans ({selectedPlanIdsM.length + selectedPlanIdsD.length + selectedPlanIdsV.length + selectedPlanIdsL.length})</AccordionItemButton>
				</AccordionItemHeading>
				<AccordionItemPanel className="plan-type-panel">
					<Accordion
						allowMultipleExpanded={true}
						allowZeroExpanded={true}
					>
						{selectedPlanIdsM.length > 0 &&
							<AccordionItem>
								<AccordionItemHeading className="coverage-header">
									<AccordionItemButton>
										<div className={css(styles.coverageHeader)}>
											{!readonly &&
												<img src={garbageIconUrl} className={css(styles.removeButton)} onClick={e => this._removeClicked(e, "medical", "new", "coverage", "medical", selectedPlanIdsM.length)} alt="remove"></img>
											}
											<div className={css(styles.coverageLabel)}>Medical ({selectedPlanIdsM.length})</div>
										</div>
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel className="plan-type-panel">
									{Object.keys(groupedPlansM).map((carrierName, carrierIndex) => (
										<Accordion
											allowMultipleExpanded={true}
											allowZeroExpanded={true}
											key={carrierIndex}
										>
											<AccordionItem>
												<AccordionItemHeading className="carrier-header">
													<AccordionItemButton>
														<div className={css(styles.carrierHeader)}>
															{!readonly &&
																<img src={garbageIconUrl} className={css(styles.removeButton)} onClick={e => this._removeClicked(e, "medical", "new", "carrier", carrierName, planCountByCarrierM[carrierName])} alt="remove"></img>
															}
															<div className={css(styles.carrierLabel)}>{carrierName} ({planCountByCarrierM[carrierName]})</div>
														</div>
													</AccordionItemButton>
												</AccordionItemHeading>
												<AccordionItemPanel className="carrier-panel">
													{Object.keys(groupedPlansM[carrierName]).map((networkName, networkIndex) => (
														<Accordion
															allowMultipleExpanded={true}
															allowZeroExpanded={true}
															key={networkIndex}
														>
															<AccordionItem>
																<AccordionItemHeading className="network-header">
																	<AccordionItemButton>
																		<div className={css(styles.networkHeader)}>
																			{!readonly &&
																				<img src={garbageIconUrl} className={css(styles.removeButton)} onClick={e => this._removeClicked(e, "medical", "new", "network", networkName, groupedPlansM[carrierName][networkName].length)} alt="remove"></img>
																			}
																			<div className={css(styles.networkLabel)}>{networkName} ({groupedPlansM[carrierName][networkName].length})</div>
																		</div>
																	</AccordionItemButton>
																</AccordionItemHeading>
																<AccordionItemPanel className="plans">
																	<Infinite
																		results={groupedPlansM[carrierName][networkName]}
																		length={groupedPlansM[carrierName][networkName].length}
																		columnLabels={labelsM}
																		columnValues={valuesM}
																		showChevron={false}
																		checkBox={!readonly}
																		checkList={selectedPlanIdsM}
																		showMore={false}
																		onClick={this._removeNewMedicalPlan}
																		onScroll={() => { }}
																	/>
																</AccordionItemPanel>
															</AccordionItem>
														</Accordion>
													))}
												</AccordionItemPanel>
											</AccordionItem>
										</Accordion>
									))}
								</AccordionItemPanel>
							</AccordionItem>
						}
						{selectedPlanIdsD.length > 0 &&
							<AccordionItem>
								<AccordionItemHeading className="coverage-header">
									<AccordionItemButton>
										<div className={css(styles.coverageHeader)}>
											{!readonly &&
												<img src={garbageIconUrl} className={css(styles.removeButton)} onClick={e => this._removeClicked(e, "dental", "new", "coverage", "dental", selectedPlanIdsD.length)} alt="remove"></img>
											}
											<div className={css(styles.coverageLabel)}>Dental ({selectedPlanIdsD.length})</div>
										</div>
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel className="plan-type-panel">
									{Object.keys(groupedPlansD).map((carrierName, carrierIndex) => (
										<Accordion
											allowMultipleExpanded={true}
											allowZeroExpanded={true}
											key={carrierIndex}
										>
											<AccordionItem>
												<AccordionItemHeading className="carrier-header">
													<AccordionItemButton>
														<div className={css(styles.carrierHeader)}>
															{!readonly &&
																<img src={garbageIconUrl} className={css(styles.removeButton)} onClick={e => this._removeClicked(e, "dental", "new", "carrier", carrierName, planCountByCarrierD[carrierName])} alt="remove"></img>
															}
															<div className={css(styles.carrierLabel)}>{carrierName} ({planCountByCarrierD[carrierName]})</div>
														</div>
													</AccordionItemButton>
												</AccordionItemHeading>
												<AccordionItemPanel className="carrier-panel">
													{Object.keys(groupedPlansD[carrierName]).map((networkName, networkIndex) => (
														<Accordion
															allowMultipleExpanded={true}
															allowZeroExpanded={true}
															key={networkIndex}
														>
															<AccordionItem>
																<AccordionItemHeading className="network-header">
																	<AccordionItemButton>
																		<div className={css(styles.networkHeader)}>
																			{!readonly &&
																				<img src={garbageIconUrl} className={css(styles.removeButton)} onClick={e => this._removeClicked(e, "dental", "new", "network", networkName, groupedPlansD[carrierName][networkName].length)} alt="remove"></img>
																			}
																			<div className={css(styles.networkLabel)}>{networkName} ({groupedPlansD[carrierName][networkName].length})</div>
																		</div>
																	</AccordionItemButton>
																</AccordionItemHeading>
																<AccordionItemPanel className="plans">
																	<Infinite
																		results={groupedPlansD[carrierName][networkName]}
																		length={groupedPlansD[carrierName][networkName].length}
																		columnLabels={labelsD}
																		columnValues={valuesD}
																		showChevron={false}
																		checkBox={!readonly}
																		checkList={selectedPlanIdsD}
																		showMore={false}
																		onClick={this._removeNewDentalPlan}
																		onScroll={() => { }}
																	/>
																</AccordionItemPanel>
															</AccordionItem>
														</Accordion>
													))}
												</AccordionItemPanel>
											</AccordionItem>
										</Accordion>
									))}
								</AccordionItemPanel>
							</AccordionItem>
						}
						{selectedPlanIdsV.length > 0 &&
							<AccordionItem>
								<AccordionItemHeading className="coverage-header">
									<AccordionItemButton>
										<div className={css(styles.coverageHeader)}>
											{!readonly &&
												<img src={garbageIconUrl} className={css(styles.removeButton)} onClick={e => this._removeClicked(e, "vision", "new", "coverage", "vision", selectedPlanIdsV.length)} alt="remove"></img>
											}
											<div className={css(styles.coverageLabel)}>Vision ({selectedPlanIdsV.length})</div>
										</div>
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel className="plan-type-panel">
									{Object.keys(groupedPlansV).map((carrierName, carrierIndex) => (
										<Accordion
											allowMultipleExpanded={true}
											allowZeroExpanded={true}
											key={carrierIndex}
										>
											<AccordionItem>
												<AccordionItemHeading className="carrier-header">
													<AccordionItemButton>
														<div className={css(styles.carrierHeader)}>
															{!readonly &&
																<img src={garbageIconUrl} className={css(styles.removeButton)} onClick={e => this._removeClicked(e, "vision", "new", "carrier", carrierName, planCountByCarrierV[carrierName])} alt="remove"></img>
															}
															<div className={css(styles.carrierLabel)}>{carrierName} ({planCountByCarrierV[carrierName]})</div>
														</div>
													</AccordionItemButton>
												</AccordionItemHeading>
												<AccordionItemPanel className="carrier-panel">
													{Object.keys(groupedPlansV[carrierName]).map((networkName, networkIndex) => (
														<Accordion
															allowMultipleExpanded={true}
															allowZeroExpanded={true}
															key={networkIndex}
														>
															<AccordionItem>
																<AccordionItemHeading className="network-header">
																	<AccordionItemButton>
																		<div className={css(styles.networkHeader)}>
																			{!readonly &&
																				<img src={garbageIconUrl} className={css(styles.removeButton)} onClick={e => this._removeClicked(e, "vision", "new", "network", networkName, groupedPlansV[carrierName][networkName].length)} alt="remove"></img>
																			}
																			<div className={css(styles.networkLabel)}>{networkName} ({groupedPlansV[carrierName][networkName].length})</div>
																		</div>
																	</AccordionItemButton>
																</AccordionItemHeading>
																<AccordionItemPanel className="plans">
																	<Infinite
																		results={groupedPlansV[carrierName][networkName]}
																		length={groupedPlansV[carrierName][networkName].length}
																		columnLabels={labelsV}
																		columnValues={valuesV}
																		showChevron={false}
																		checkBox={!readonly}
																		checkList={selectedPlanIdsV}
																		showMore={false}
																		onClick={this._removeNewVisionPlan}
																		onScroll={() => { }}
																	/>
																</AccordionItemPanel>
															</AccordionItem>
														</Accordion>
													))}
												</AccordionItemPanel>
											</AccordionItem>
										</Accordion>
									))}
								</AccordionItemPanel>
							</AccordionItem>
						}
						{selectedPlanIdsL.length > 0 &&
							<AccordionItem>
								<AccordionItemHeading className="coverage-header">
									<AccordionItemButton>
										<div className={css(styles.coverageHeader)}>
											{!readonly &&
												<img src={garbageIconUrl} className={css(styles.removeButton)} onClick={e => this._removeClicked(e, "life", "new", "coverage", "life", selectedPlanIdsL.length)} alt="remove"></img>
											}
											<div className={css(styles.coverageLabel)}>Life ({selectedPlanIdsL.length})</div>
										</div>
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel className="plan-type-panel">
									{Object.keys(groupedPlansL).map((carrierName, carrierIndex) => (
										<Accordion
											allowMultipleExpanded={true}
											allowZeroExpanded={true}
											key={carrierIndex}
										>
											<AccordionItem>
												<AccordionItemHeading className="carrier-header">
													<AccordionItemButton>
														<div className={css(styles.carrierHeader)}>
															{!readonly &&
																<img src={garbageIconUrl} className={css(styles.removeButton)} onClick={e => this._removeClicked(e, "life", "new", "carrier", carrierName, planCountByCarrierL[carrierName])} alt="remove"></img>
															}
															<div className={css(styles.carrierLabel)}>{carrierName} ({planCountByCarrierL[carrierName]})</div>
														</div>
													</AccordionItemButton>
												</AccordionItemHeading>
												<AccordionItemPanel className="plans">
													<Infinite
														results={groupedPlansL[carrierName]}
														length={groupedPlansL[carrierName].length}
														columnLabels={labelsL}
														columnValues={valuesL}
														showChevron={false}
														checkBox={!readonly}
														checkList={selectedPlanIdsL}
														showMore={false}
														onClick={this._removeNewLifePlan}
														onScroll={() => { }}
													/>
												</AccordionItemPanel>
											</AccordionItem>
										</Accordion>
									))}
								</AccordionItemPanel>
							</AccordionItem>
						}
					</Accordion>
				</AccordionItemPanel>
			</AccordionItem>
		);
	};

	render() {
		const { quote, selectedPlans } = this.props;
		const totalPlanCount = quote.currentMedicalPlans.length + quote.renewalMedicalPlans.length + (selectedPlans.medical ? selectedPlans.medical.length : 0) +
			quote.currentDentalPlans.length + quote.renewalDentalPlans.length + (selectedPlans.dental ? selectedPlans.dental.length : 0) +
			quote.currentVisionPlans.length + quote.renewalVisionPlans.length + (selectedPlans.vision ? selectedPlans.vision.length : 0) +
			quote.currentLifePlans.length + quote.renewalLifePlans.length + (selectedPlans.life ? selectedPlans.life.length : 0);
		return (
			<div>
				<div className={css(styles.header)}>
					<div className={css(styles.planCount)}>{totalPlanCount} Plans</div>
					<div className={css(styles.removeAllPlans)} onClick={() => this._removeAllPlans()}>
						Clear All Plans
					</div>
					<div className={css(styles.clear)} />
				</div>
				<div>
					<Accordion
						allowMultipleExpanded={true}
						allowZeroExpanded={true}
					>
						{this._renderCurrentOrRenewalPlans("Current")}
						{this._renderCurrentOrRenewalPlans("Renewal")}
						{this._renderNewPlans()}
					</Accordion>
				</div>
				<Modal
					show={this.state.showRemoveModal}
					header={this.state.removeModalHeaderText}
				>
					<div className={css(styles.modalContent)}>
						<AlertErrorIcon className={css(styles.alertIcon)} />
						<div className={css(styles.modalInstructions)}>
							<div dangerouslySetInnerHTML={{ __html: this.state.removeModalText }} />
						</div>
					</div>
					<div className={css(styles.modalButtonContainer)}>
						<ActionButton
							aStyles={styles.modalButton}
							label="Cancel"
							invertColor={true}
							type="button"
							onClick={() => this.setState({ showRemoveModal: false })}
						/>
						<ActionButton
							aStyles={styles.modalButton}
							label="Proceed"
							onClick={e => this._removeClicked(e, this.state.removeLob, this.state.removePlanType, this.state.removeType, this.state.removeValue)}
							type="button"
							isLoading={this.state.isSaving}
						/>
					</div>
				</Modal>
				<Modal
					show={this.state.showRemoveAllModal}
					header="Remove All Plans"
				>
					<div className={css(styles.modalContent)}>
						<AlertErrorIcon className={css(styles.alertIcon)} />
						<div className={css(styles.modalInstructions)}>
							You are about to remove <b>{totalPlanCount}</b> plans from your selections.
						</div>
					</div>
					<div className={css(styles.modalButtonContainer)}>
						<ActionButton
							aStyles={styles.modalButton}
							label="Cancel"
							invertColor={true}
							type="button"
							onClick={() => this.setState({ showRemoveAllModal: false })}
						/>
						<ActionButton
							aStyles={styles.modalButton}
							label="Proceed"
							onClick={e => this._removeAllPlans(e)}
							type="button"
							isLoading={this.state.isSaving}
						/>
					</div>
				</Modal>
			</div>
		);
	}
}

const mapDispatchToProps = state => ({
	selectedPlans: state.proposal.selectedPlans,
});

export default connect(
	mapDispatchToProps, {
	setQuote: proposalActions.setQuote,
	setSelectedPlans: proposalActions.setSelectedPlans
},
)(SelectedPlans);