import instance from "./instance";

const basicAuthHeader = `Basic ${process.env.REACT_APP_BASIC_AUTH}`;
const responseData = response => response.data;

export function login(username, password, verification) {
    return instance
        .request({
            url: "/account/request-token",
            method: "POST",
            data: {
                username,
                password,
                verification,
            },
            headers: {
                Authorization: basicAuthHeader,
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function register(name, email, officeId, companyName, companyZip, cellPhone, soleProprietor) {
    return instance
        .request({
            url: "/account/register",
            method: "POST",
            data: {
                name,
                email,
                officeId,
                companyName,
                companyZip,
                cellPhone,
                soleProprietor,
            },
            headers: {
                Authorization: basicAuthHeader,
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function logout() {
    // TODO: Make API logout call
    return Promise.resolve();
}

export function me() {
    return instance
        .request({
            url: "/account/me",
            method: "GET",
        })
        .then(response => {
            return response.data;
        });
}

export function updateMe(
    name,
    companyName,
    title,
    officeId,
    avatar,
    website,
    linkedIn,
    twitter,
    inMemberDirectory,
    newPassword,
) {
    return instance
        .request({
            url: "/account/me",
            method: "POST",
            data: {
                name,
                companyName,
                title,
                officeId,
                avatar,
                inMemberDirectory,
                website,
                linkedIn,
                twitter,
                newPassword,
            },
        })
        .then(responseData);
}

export function forgotPassword(email, brokerId) {
    return instance
        .request({
            url: "/account/forgot-password",
            method: "POST",
            data: {
                email,
                brokerId,
            },
            headers: {
                Authorization: basicAuthHeader,
            },
        })
        .then(responseData);
}

export function resetPassword(id, password, code) {
    return instance
        .request({
            url: "/account/reset-password",
            method: "POST",
            data: {
                id,
                code,
                password,
            },
            headers: {
                Authorization: basicAuthHeader,
            },
        })
        .then(responseData);
}

export function updatePassword(password, newPassword) {
    return instance
        .request({
            url: "/account/update-password",
            method: "POST",
            data: {
                id: "ZZZ",
                code: "YYY",
                password,
                newPassword,
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function updatePhone(id, phone, verification) {
    return instance
        .request({
            url: "/account/update-phone",
            method: "POST",
            data: {
                id,
                phone,
                verification,
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function deactivateAccount(id) {
    return instance
        .request({
            url: `/account/deactivate-account/${id}`,
            method: "GET",
        })
        .then(responseData);
}
