import * as color from "../../constants/color";
import * as routeHelpers from "../../lib/routeHelpers";
import SearchInput from "../inputs/SearchInput";
import PageTitle from "../text/PageTitle";
import Checkbox from "../inputs/Checkbox";
import AddButton from "../AddButton";
import React, { Component } from "react";
import * as texchangePropTypes from "../../texchangePropTypes";
import MonthInput from "../inputs/MonthInput";
import { StyleSheet, css } from "aphrodite";
import Tabs from "../Tabs";
import Infinite from "../Infinite";
import * as employerApi from "../../lib/api/employer";
import * as searchApi from "../../lib/api/search";
import * as dataApi from "../../lib/api/data";
import * as officesApi from "../../lib/api/offices";

const styles = StyleSheet.create({
    content: {
        paddingRight: 10,
        paddingBottom: 80,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
    },
    contentSection: {
        width: "100%",
        padding: 0,
        backgroundColor: color.white,
        borderTop: `8px solid ${color.mediumRed}`,
        marginBottom: 20,
    },
    titleSection: {
        heigth: 64,
    },
    searchBox: {
        maxWidth: 480,
        textAlign: "center",
        margin: "30px auto 30px auto",
    },
    filterContainer: {
        padding: "20px 22px 10px 22px",
        display: "flex",
        justifyContent: "space-between",
        color: color.darkestGray,
    },
    filterHeader: {
        fontSize: 16,
        paddingBottom: 6,
    },
    checkLabel: {
        display: "block",
        position: "relative",
        border: "none",
        marginTop: 10,
        marginBottom: 10,
        "-webkit-appearance": "none",
        cursor: "pointer",
    },
    check: {
        position: "absolute",
        top: -3,
        left: 0,
        marginRight: 10,
    },
    labelText: {
        marginLeft: 30,
        fontSize: 14,
    },
    renewalMonth: {
        borderBottom: `1px solid ${color.darkGray4}`,
        marginRight: 12,
    },
    checkboxListContainer: {
        height: 136,
        overflowY: "auto",
        overflowX: "hidden",
        paddingRight: 8,
    },
    statusColumn: {
        float: "left",
    },
    renewalMonthColumn: {
        marginRight: 12,
        minWidth: 220,
        maxWidth: 220,
    },
    segmentSizeColumn: {},
    salesRepColumn: {
        paddingRight: 12,
    },
    locationColumn: {
        paddingRight: 12,
    },
    redBar: {
        borderLeft: `4px solid ${color.mediumRed}`,
        height: "100%",
        padding: "12px 22px",
    },
    addGroup: {
        float: "right",
        cursor: "pointer",
        marginTop: 18,
        marginRight: 24,
    },
    groups: {
        position: "relative",
        top: 10,
    },
    right: {
        float: "right",
        position: "relative",
        top: -5,
        right: 20,
        fontSize: 14,
        color: color.tableText,
    },
    noResults: {
        backgroundColor: color.white,
        color: color.darkGray,
        padding: 20,
        marginBottom: 40,
    },
});

const strings = {
    title: "Find a Group",
    searchPlaceholder: "Search by Group or Broker",
    status: "Status",
    renewalMonth: "Renewal Month",
    segmentSize: "Segment Size",
    salesRep: "Sales Rep",
    rbgLocation: "Location",
    inForce: "In force",
    notInForce: "Not in force",
    segmentSize1to50: "1-50",
    segmentSize2to99: "2-99 (CA 1-100)",
    segmentSize51to99: "51-99",
    segmentSize100Plus: "100+ (CA 101+)",
    addGroup: "Add Group",
};

const inputNames = {
    search: "search",
    inForce: "inForce",
    notInForce: "notInForce",
    segmentSize1to50: "segmentSize1to50",
    segmentSize2to99: "segmentSize2to99",
    segmentSize51to99: "segmentSize51to99",
    segmentSize100Plus: "segmentSize100Plus",
    selectedSalesRepIds: "selectedSalesRepIds",
    selectedOfficeIds: "selectedOfficeIds",
    selectedMonth: "selectedMonth",
};

export default class GroupsScreen extends Component {
    static propTypes = {
        location: texchangePropTypes.location.isRequired,
        history: texchangePropTypes.history.isRequired,
    };

    state = {
        inputs: {
            [inputNames.search]: "",
            [inputNames.inForce]: false,
            [inputNames.notInForce]: false,
            [inputNames.segmentSize1to50]: false,
            [inputNames.segmentSize2to99]: false,
            [inputNames.segmentSize51to99]: false,
            [inputNames.segmentSize100Plus]: false,
            [inputNames.selectedSalesRepIds]: [],
            [inputNames.selectedOfficeIds]: [],
            [inputNames.selectedMonth]: null,
        },
        users: [],
        loading: false,
        errors: {},

        allEmployers: [],
        allEmployersCount: 0,
        allEmployersMore: false,
        recentEmployers: [],
        recentEmployersCount: 0,
        recentEmployersMore: false,
        offices: [],
        searchResults: [],
        columnLabels: ["Group", "SIC", "Renewal Date", "Sales Rep", "Special Rep", "Broker", "Status", "Size"],
        columnValues: ["name", "sicCode", "renewalMonth", "salesRepresentativeName", "specialSalesRepresentativeName", "brokerName", "status", "segmentSize"],
    };

    componentDidMount() {
        this._mounted = true;
        this.setState({ loading: true });
        //Check if there are any params
        let renewalMonth = null;
        if (this.props.location && this.props.location.search) {
            const params = new URLSearchParams(this.props.location.search);
            let ren = params.get("ren");
            if (!Number.isInteger(ren)) {
                ren = ren === "JAN" ? "1" : ren === "FEB" ? "2" : ren === "MAR" ? "3" : ren === "APR" ? "4" : ren === "MAY" ? "5" : ren === "JUN" ? "6" : ren === "JUL" ? "7" : ren === "AUG" ? "8" : ren === "SEP" ? "9" : ren === "OCT" ? "10" : ren === "NOV" ? "11" : "12";
            }
            renewalMonth = parseInt(ren);
            this.setState(prevState => {
                return {
                    inputs: {
                        ...prevState.inputs,
                        [inputNames.selectedMonth]: renewalMonth,
                    },
                };
            });
        }
        //load
        Promise.all([employerApi.getPaged(1, null, null, 0, null, renewalMonth), employerApi.getPaged(1, null, null, 1, null, renewalMonth), dataApi.getSalesReps(), officesApi.getMine()])
            .then(responses => {
                if (responses && responses[0] && responses[1] && responses[2] && responses[3] && this._mounted) {
                    this.setState({
                        loading: false,
                        allEmployers: responses[0],
                        allEmployersCount: responses[0][0] ? responses[0][0].recordCount : 0,
                        allEmployersMore: responses[0][0] ? responses[0][0].recordCount > 100 : false,
                        recentEmployers: responses[1],
                        recentEmployersCount: responses[1][0] ? responses[1][0].recordCount : 0,
                        recentEmployersMore: responses[1][0] ? responses[1][0].recordCount > 100 : false,
                        users: responses[2],
                        offices: responses[3]
                    });
                }
            });
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    _allEmployersScroll = (page, col, dir) => {
        if (((page - 1) * 100) < this.state.allEmployersCount) {
            const { inputs } = this.state;
            let filterStatuses = "";
            if (inputs.inForce) {
                if (!inputs.notInForce) {
                    filterStatuses = "1";
                }
            } else {
                if (inputs.notInForce) {
                    filterStatuses = "0";
                }
            }
            const segArray = [];
            if (inputs.segmentSize1to50) {
                segArray.push("1-50");
            }
            if (inputs.segmentSize2to99) {
                segArray.push("2-99");
                segArray.push("1-100");
                segArray.push("2-99 (CA 1-100)");
            }
            if (inputs.segmentSize51to99) {
                segArray.push("51-99");
            }
            if (inputs.segmentSize100Plus) {
                segArray.push("100+");
                segArray.push("101+");
                segArray.push("100+ (CA 101+)");
            }
            const filterSegments = segArray.join(",");
            const filterSalesRepIds = inputs.selectedSalesRepIds.length > 0 ? inputs.selectedSalesRepIds.join() : null;
            //get results
            employerApi.getPaged(page, col, dir, 0, filterStatuses, inputs.selectedMonth, filterSegments, filterSalesRepIds)
                .then(result => {
                    let joined = {};
                    if (page === 1) {
                        joined = result;
                    } else {
                        joined = this.state.allEmployers.concat(result);
                    }
                    this.setState({
                        allEmployers: joined,
                        allEmployersMore: result[0].recordCount > (page * 100),
                    });
                });
        }
        else {
            this.setState({
                allEmployersMore: false,
            });
        }
    };

    _recentEmployersScroll = (page, col, dir) => {
        if (((page - 1) * 100) < this.state.recentEmployersCount) {
            employerApi.getPaged(page, col, dir, 1).then(result => {
                let joined = {};
                if (page === 1) {
                    joined = result;
                } else {
                    joined = this.state.recentEmployers.concat(result);
                }
                this.setState({
                    recentEmployers: joined,
                    recentEmployersMore: result[0].recordCount > (page * 100),
                });
            });
        }
        else {
            this.setState({
                recentEmployersMore: false,
            });
        }
    };

    _searchResultSelected = (item) => {
        if (item && item.type) {
            if (item.type === "Employer") {
                this.props.history.push(routeHelpers.groupDetail(item.value));
            }
            else if (item.type === "Broker") {
                this.props.history.push(routeHelpers.brokerDetail(item.value));
            }
        }
    };

    _filterEmployers = () => {
        const { inputs } = this.state;
        let filterStatuses = "";
        if (inputs.inForce) {
            if (!inputs.notInForce) {
                filterStatuses = "1";
            }
        } else {
            if (inputs.notInForce) {
                filterStatuses = "0";
            }
        }
        const segArray = [];
        if (inputs.segmentSize1to50) {
            segArray.push("1-50");
        }
        if (inputs.segmentSize2to99) {
            segArray.push("2-99");
            segArray.push("1-100");
            segArray.push("2-99 (CA 1-100)");
        }
        if (inputs.segmentSize51to99) {
            segArray.push("51-99");
        }
        if (inputs.segmentSize100Plus) {
            segArray.push("100+");
            segArray.push("101+");
            segArray.push("100+ (CA 101+)");
        }
        const filterSegments = segArray.join(",");
        const filterSalesRepIds = inputs.selectedSalesRepIds.length > 0 ? inputs.selectedSalesRepIds.join() : null;
        //get results
        employerApi.getPaged(1, null, null, 0, filterStatuses, inputs.selectedMonth, filterSegments, filterSalesRepIds)
            .then(employers => {
                this.setState({
                    isLoading: false,
                    allEmployers: employers,
                    allEmployersCount: employers[0] ? employers[0].recordCount : 0,
                    allEmployersMore: employers[0] ? employers[0].recordCount > 100 : false,
                });
            });
    };

    _handleInputChange = e => {
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;

        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: val,
                },
            };
        }, () => {
            this._filterEmployers();
        });
    };

    _handleMonthChange = month => {
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.selectedMonth]: month,
                },
            };
        }, () => {
            this._filterEmployers();
        });
    };

    _setSelectedSalesReps = e => {
        const { checked, value } = e.target;

        const selectedSalesRepIds = this.state.inputs[inputNames.selectedSalesRepIds];
        const id = parseInt(value);
        if (checked) {
            selectedSalesRepIds.push(id);
        } else {
            const index = selectedSalesRepIds.indexOf(id);
            if (index !== -1) {
                selectedSalesRepIds.splice(index, 1)
            }
        }

        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    selectedSalesRepIds,
                },
            };
        }, () => {
            this._filterEmployers();
        });
    };

    _handleRowClick = id => {
        this.props.history.push(routeHelpers.groupDetail(id));
    };

    _goToAddGroup = () => {
        //this.props.history.push(routeHelpers.editGroup());
    };

    renderTable = (what) => {
        let employers = what ? this.state.allEmployers : this.state.recentEmployers;
        let count = what ? this.state.allEmployersCount : this.state.recentEmployersCount;
        let more = what ? this.state.allEmployersMore : this.state.recentEmployersMore;
        let fnScroll = what ? this._allEmployersScroll : this._recentEmployersScroll;
        return (
            <div className={css(styles.groups)}>
                <div className={css(styles.right)}>{parseInt(count).toLocaleString()} groups</div>
                {employers.length > 0 ? (
                    <Infinite
                        results={employers}
                        length={employers.length}
                        columnLabels={this.state.columnLabels}
                        columnValues={this.state.columnValues}
                        showChevron={true}
                        showMore={more}
                        onClick={this._handleRowClick}
                        onScroll={fnScroll} />
                ) : (
                    <div className={css(styles.noResults)}>{this.state.loading ? "Loading " : "No Matching "} groups</div>
                )}
            </div>
        );
    }

    render() {
        return (
            <div className={css(styles.content)}>
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.titleSection)}>
                        {/*<AddButton 
                            aStyles={styles.addGroup} 
                            onClick={this._goToAddGroup} 
                            label={strings.addGroup} 
                        />*/}
                        <PageTitle>{strings.title}</PageTitle>
                    </div>
                    <div className={css(styles.groups)}>
                        <div>
                            <SearchInput
                                aStyles={styles.searchBox}
                                search={searchApi.searchEmployersAndBrokers}
                                onItemSelected={this._searchResultSelected}
                                placeholder={strings.searchPlaceholder}
                                hideLabel={true}
                            />
                        </div>
                        <Tabs>
                            <div label="All Groups">
                                <div className={css(styles.filterContainer)}>
                                    <div className={css(styles.statusColumn)}>
                                        <div className={css(styles.filterHeader)}>Status</div>
                                        <div>
                                            <label className={css(styles.checkLabel)}>
                                                <Checkbox
                                                    aStyles={styles.check}
                                                    checked={this.state.inputs[inputNames.inForce]}
                                                    name={inputNames.inForce}
                                                    onChange={this._handleInputChange}
                                                />
                                                <div className={css(styles.labelText)}>{strings.inForce}</div>
                                            </label>
                                        </div>
                                        <div>
                                            <label className={css(styles.checkLabel)}>
                                                <Checkbox
                                                    aStyles={styles.check}
                                                    checked={this.state.inputs[inputNames.notInForce]}
                                                    name={inputNames.notInForce}
                                                    onChange={this._handleInputChange}
                                                />
                                                <div className={css(styles.labelText)}>{strings.notInForce}</div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className={css(styles.renewalMonthColumn)}>
                                        <div className={css(styles.filterHeader, styles.renewalMonth)}>{strings.renewalMonth}</div>
                                        <MonthInput
                                            value={this.state.inputs[inputNames.selectedMonth]}
                                            onChange={this._handleMonthChange}
                                        />
                                    </div>
                                    <div className={css(styles.segmentSizeColumn)}>
                                        <div className={css(styles.filterHeader)}>{strings.segmentSize}</div>
                                        <div>
                                            <label className={css(styles.checkLabel)}>
                                                <Checkbox
                                                    aStyles={styles.check}
                                                    checked={this.state.inputs[inputNames.segmentSize1to50]}
                                                    name={inputNames.segmentSize1to50}
                                                    onChange={this._handleInputChange}
                                                />
                                                <div className={css(styles.labelText)}>{strings.segmentSize1to50}</div>
                                            </label>
                                        </div>
                                        <div>
                                            <label className={css(styles.checkLabel)}>
                                                <Checkbox
                                                    aStyles={styles.check}
                                                    checked={this.state.inputs[inputNames.segmentSize2to99]}
                                                    name={inputNames.segmentSize2to99}
                                                    onChange={this._handleInputChange}
                                                />
                                                <div className={css(styles.labelText)}>{strings.segmentSize2to99}</div>
                                            </label>
                                        </div>
                                        <div>
                                            <label className={css(styles.checkLabel)}>
                                                <Checkbox
                                                    aStyles={styles.check}
                                                    checked={this.state.inputs[inputNames.segmentSize51to99]}
                                                    name={inputNames.segmentSize51to99}
                                                    onChange={this._handleInputChange}
                                                />
                                                <div className={css(styles.labelText)}>{strings.segmentSize51to99}</div>
                                            </label>
                                        </div>
                                        <div>
                                            <label className={css(styles.checkLabel)}>
                                                <Checkbox
                                                    aStyles={styles.check}
                                                    checked={this.state.inputs[inputNames.segmentSize100Plus]}
                                                    name={inputNames.segmentSize100Plus}
                                                    onChange={this._handleInputChange}
                                                />
                                                <div className={css(styles.labelText)}>{strings.segmentSize100Plus}</div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className={css(styles.salesRepColumn)}>
                                        <div className={css(styles.filterHeader)}>{strings.salesRep}</div>
                                        <div className={css(styles.checkboxListContainer)}>
                                            {this.state.users.map(user => {
                                                return (
                                                    <div key={user.id}>
                                                        <label className={css(styles.checkLabel)}>
                                                            <Checkbox
                                                                aStyles={styles.check}
                                                                checked={!!this.state.inputs[inputNames.selectedSalesRepIds].includes(user.id)}
                                                                name={inputNames.selectedSalesRepIds}
                                                                onChange={this._setSelectedSalesReps}
                                                                value={user.id}
                                                            />
                                                            <div className={css(styles.labelText)}>{user.lastName + ", " + user.firstName}</div>
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                {this.renderTable(1)}
                            </div>
                            <div label="Recent">
                                {this.renderTable(0)}
                            </div>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}