import logger from "./logger";

export function getItem(key) {
    try {
        return localStorage.getItem(key);
    } catch (e) {
        if (process.env.NODE_ENV === "development") {
            logger.warn(e);
        }

        return null;
    }
}

export function setItem(key, value) {
    try {
        localStorage.setItem(key, value);
    } catch (e) {
        if (process.env.NODE_ENV === "development") {
            logger.warn(e);
        }
    }
}

export function removeItem(key) {
    try {
        localStorage.removeItem(key);
    } catch (e) {
        if (process.env.NODE_ENV === "development") {
            logger.warn(e);
        }
    }
}
