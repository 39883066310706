import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import * as texchangePropTypes from "../../texchangePropTypes";
import * as proposalActions from "../../actions/proposalActions";
import * as color from "../../constants/color";
import ManualSpreadsheet from "./ManualSpreadsheet";
import AutomaticSpreadsheet from "./AutomaticSpreadsheet";

const styles = StyleSheet.create({
	container: {

	},
	typeContainer: {
		display: "flex",
		justifyContent: "center",
	},
	typeOptionItem: {
		width: 200,
		height: 100,
		borderRadius: 8,
		backgroundColor: color.mediumYellow1,
		color: color.white,
		textAlign: "center",
		lineHeight: "100px",
		marginLeft: 20,
		marginRight: 20,
		cursor: "pointer",
		fontSize: 20,
	},
	header: {
		fontWeight: 300,
		fontSize: 24,
		marginTop: 40,
		marginBottom: 60,
		marginLeft: 30,
	},
	manualSpreadsheetContainer: {
		marginTop: 18,
		paddingTop: 8,
	},
});

const strings = {
	headerText: "Choose the type of spreadsheet you would like to configure:",
	automaticHeaderText: "Make selections below and make as many spreadsheets as you like.",
	manualOption: "Manual",
	automaticOption: "Automatic",
	saveButtonText: "Save",
	backButtonText: "Back",
	noSavedSpreadsheetsText: "You have no saved spreadsheets.",
};

export class Spreadsheets extends Component {
	static propTypes = {
		spreadsheetType: PropTypes.string,
		setSpreadsheetType: PropTypes.func.isRequired,
		quote: texchangePropTypes.quote.isRequired,
		setSubStep: PropTypes.func,
	};

	state = {
		isSaving: false,
		currentTab: null,
	};

	_renderOptions = () => {
		return (
			<div className={css(styles.container)}>
				<div className={css(styles.header)}>
					{strings.headerText}
				</div>

				<div className={css(styles.typeContainer)}>
					<div
						className={css(styles.typeOptionItem)}
						onClick={() => this.props.setSpreadsheetType(strings.manualOption)}
					>
						{strings.manualOption}
					</div>
					{
						<div
							className={css(styles.typeOptionItem)}
							onClick={() => this.props.setSpreadsheetType(strings.automaticOption)}
						>
							{strings.automaticOption}
						</div>
					}
				</div>
			</div>
		);
	};

	_handleTabChanged = currentTab => {
		this.setState({ currentTab });
	};

	_setSubStep = step => {
		this.props.setSubStep(step);
	}

	render() {
		const { spreadsheetType } = this.props;
		return (
			<div>
				{false && spreadsheetType === null ? (
					this._renderOptions()
				) : (
					<div>
						{true || spreadsheetType === strings.manualOption ? (
							<React.Fragment>
								<div className={css(styles.manualSpreadsheetContainer)}>
									<ManualSpreadsheet
										tabChanged={this._handleTabChanged}
										quoteId={this.props.quote.id}
										setSubStep={this._setSubStep}
									/>
								</div>
							</React.Fragment>
						) : (
							<AutomaticSpreadsheet />
						)}
					</div>
				)
				}
			</div>
		);
	}
}

const mapDispatchToProps = state => ({
	spreadsheetType: state.proposal.spreadsheetType,
	quote: state.proposal.quote,
});

export default connect(
	mapDispatchToProps, {
	setSpreadsheetType: proposalActions.setSpreadsheetType,
},
)(Spreadsheets);
