import * as color from "../../constants/color";
import * as searchInputTypes from "../../constants/searchInputTypes";
import * as breakpoints from "../../constants/breakpoints";
import * as texchangePropTypes from "../../texchangePropTypes";
import * as zIndexes from "../../constants/zIndexes";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import searchIcon from "../../images/search.png";
import searchStreamlineIcon from "../../images/SVGs/SearchStreamline.svg";
import ErrorMessage from "../text/ErrorMessage";

const VERTICAL_PADDING_DIFFERENCE = 12;
const ADDITIONAL_PADDING = 42;
const ITEM_HEIGHT = 53;

const SEARCH_WAIT_INTERVAL = 500;

const placeholder = {
    color: color.searchInput,
    fontWeight: 400,
};

const placeholderNoIcon = {
    color: color.darkGray5,
    fontSize: 16,
    fontWeight: 400,
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        outline: 0,
        zIndex: zIndexes.SEARCH,
    },
    input: {
        width: "100%",
        backgroundColor: color.white,
        border: "none",
        borderRadius: 6,
        color: color.searchInput,
        boxShadow: "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)",
        fontSize: 20,
        fontWeight: 400,
        lineHeight: "22px",
        outline: "none",
        padding: "22px 22px",
        margin: "10px 0px",

        "::-webkit-input-placeholder": placeholder,
        "::-moz-placeholder": placeholder,
        "::-ms-input-placeholder": placeholder,
        "::-placeholder": placeholder,

        ":focus": {
            boxShadow: "none",
            border: "solid",
            borderColor: color.lightOrange,
            borderWidth: 2,
        },

        [breakpoints.tabletAndDown]: {
            letterSpacing: 0.17,
        },

        [breakpoints.desktopOnly]: {

        },
    },
    inputText: {
        padding: "20px 16px",
    },
    inputTextNoIcon: {
        boxShadow: "none",
        border: "solid",
        borderWidth: 1,
        borderRadius: 4,
        borderColor: color.mediumGray,
        color: color.darkestGray,
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "22px",
        outline: "none",
        padding: "18px 16px",
        margin: "10px 0px",

        "::-webkit-input-placeholder": placeholderNoIcon,
        "::-moz-placeholder": placeholderNoIcon,
        "::-ms-input-placeholder": placeholderNoIcon,
        "::-placeholder": placeholderNoIcon,
    },
    hasValue: {
        paddingTop: 24,
        paddingBottom: 12,
    },
    title: {
        position: "absolute",
        top: 18,
        left: 12,
        fontSize: 13,
        color: color.darkGray,
    },
    disabled: {
        color: color.darkestBlueAlpha30,
    },
    withValidationError: {
        color: color.darkRed,
    },
    validationMessage: {
        position: "absolute",
        top: 16,
        right: 8,
        pointerEvents: "none",
    },
    icon: {
        position: "absolute",
        top: 31,
        right: 48,
    },
    iconText: {
        top: 28,
    },
    searchResultsContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        height: "100%",
    },
    borderContainer: {
        // transition: "height 0.3s",
        backgroundColor: color.white,
        borderTop: `1px solid ${color.lightGray2}`,
        position: "absolute",
        right: 0,
        left: 20,
        maxHeight: 485,
        overflow: "hidden",
        boxShadow: "0 4px 5px 0 rgba(0,0,0,0.14), 0 8px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2)",
        width: "calc(100% - 40px)",
    },
    borderContainerNoIcon: {
        // width: "auto",
    },
    resultItem: {
        cursor: "pointer",
        border: "none",
        alignSelf: "stretch",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: color.transparent,
        textAlign: "left",
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 16,
        paddingBottom: 16,
        outline: 0,
        borderBottom: `1px solid ${color.lightGray2}`,
        color: color.darkestGray,
        ":hover": {
            backgroundColor: color.mediumRed,
            color: color.white,
        },
        [breakpoints.desktopOnly]: {

        },
    },
    active: {
        backgroundColor: color.mediumRed,
        color: color.white,
    },
    resultItemText: {
        fontSize: 18,
        fontWeight: 400,
    },
});

export default class SearchInput extends Component {
    static propTypes = {
        aStyles: texchangePropTypes.aphroditeStyles,
        placeholder: PropTypes.string,
        hideLabel: PropTypes.bool,
        value: PropTypes.string,
        extraId: PropTypes.number,
        search: PropTypes.func.isRequired,
        onItemSelected: PropTypes.func.isRequired,
        type: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        validationMessage: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.string,
        ]),
        onTextChanged: PropTypes.func,
        tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    };

    static defaultProps = {
        type: "search",
    };

    state = {
        value: "",
        extraId: null,
        searchResults: [],
        activeOption: -1,
        open: true,
        popupHeight: null,
        inputHeight: null,
    };

    constructor(props) {
        super(props);

        this._inputRef = React.createRef();
        this._popupRef = React.createRef();
    }

    componentWillMount() {
        this._timer = null;
    }

    componentDidMount() {
        this._mounted = true;

        const { value } = this.props;
        if (value) {
            this.setState({ value });
        }

        setTimeout(() => {
            if (!this._mounted) {
                return;
            }

            this.setState({
                popupHeight: this._getPopupHeight(this._inputRef.current.clientHeight, 0),
                inputHeight: this._inputRef.current.clientHeight,
            });
        });

        window.addEventListener("click", this._onClick);
    }

    componentWillUnmount() {
        this._mounted = false;
        window.removeEventListener("click", this._onClick);
    }

    _hideResults = () => this.setState({ open: false, activeOption: -1, popupHeight: 0 });

    _onClick = e => {
        if (e && this._inputRef.current.contains(e.target)) {
            return;
        }

        this._hideResults();
    };

    _onSelect = resultItem => {
        if (resultItem && resultItem.label) {
            this.props.onItemSelected(resultItem);
            this.setState({ value: resultItem.label });
            this._hideResults();
        }
    };

    _renderResults = () => (
        <div
            ref={this._popupRef}
            className={css(styles.searchResultsContainer)}
            style={
                this.state.open
                    ? {
                        overflowY: "auto",
                    }
                    : null
            }
        >
            {this.state.searchResults.map(this._renderResultItem)}
        </div>
    );

    _runSearch = term => {
        const { extraId } = this.props;

        if (!term) {
            this.setState({ searchResults: [] });
            return;
        }

        this.props.search(term, extraId)
            .then(response => {
                const open = response.length > 0;
                this.setState({
                    popupHeight: this._getPopupHeight(this.state.inputHeight, response.length),
                    searchResults: response,
                    open,
                });
            });
    }

    _handleTextChange = e => {
        if (this._timer) {
            clearTimeout(this._timer);
        }

        const { value } = e.target;
        if (value !== this.state.value) {
            this.setState({ value, activeOption: -1 });
            this._timer = setTimeout(() => {
                this._runSearch(value.trim());
            }, SEARCH_WAIT_INTERVAL);
        }

        if (this.props.onTextChanged) {
            this.props.onTextChanged(value);
        }
    };

    _getPopupHeight = (inputHeight, length) => {
        if (!this._popupRef.current) {
            if (length === 0) {
                return ITEM_HEIGHT + VERTICAL_PADDING_DIFFERENCE;
            }
            else {
                return ((length * ITEM_HEIGHT) + VERTICAL_PADDING_DIFFERENCE) < 485 ? ((length * ITEM_HEIGHT) + VERTICAL_PADDING_DIFFERENCE) : 485;
            }
        }

        const dd = this._popupRef.current.getBoundingClientRect();
        const appHeight = document.body.classList.contains("no-scroll")
            ? window.innerHeight
            : document.body.clientHeight;

        const popupHeight = length * ITEM_HEIGHT;

        if (popupHeight + dd.top + ADDITIONAL_PADDING + VERTICAL_PADDING_DIFFERENCE > appHeight) {
            return appHeight - dd.top - inputHeight - 16;
        } else {
            return length === 1 ? popupHeight + VERTICAL_PADDING_DIFFERENCE : popupHeight + 1;
        }
    };

    _onKeyDown = e => {
        const { searchResults, activeOption } = this.state;

        if (e.keyCode === 13) { // Enter
            this.setState({
                activeOption: 0,
                open: false,
            });
            this._onSelect(searchResults[activeOption]);
        } else if (e.keyCode === 38) { // Up arrow
            if (activeOption > 0) {
                this.setState({ activeOption: activeOption - 1 });
            }
        } else if (e.keyCode === 40) { // Down arrow
            if (activeOption - 1 < searchResults.length) {
                this.setState({ activeOption: activeOption + 1 });
            }
        } else if (e.keyCode === 27) { // Esc
            this._hideResults();
        }
    };

    _renderResultItem = (resultItem, index) => (
        <button
            key={resultItem.value}
            className={css(
                styles.resultItem,
                index === this.state.activeOption ? styles.active : null
            )}
            type="button"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => this._onSelect(resultItem)}
        >
            <div className={css(styles.resultItemText)}>
                {resultItem.label}
            </div>
        </button>
    );

    render() {
        const { validationMessage, aStyles, placeholder, hideLabel, type, disabled } = this.props;

        return (
            <div className={css(styles.container, aStyles)}>
                {validationMessage ? (
                    <ErrorMessage aStyles={styles.validationMessage}>
                        {validationMessage}
                    </ErrorMessage>
                ) : null}

                <input
                    placeholder={placeholder}
                    className={css(
                        styles.input,
                        type === searchInputTypes.text ? styles.inputText : null,
                        type === searchInputTypes.textNoIcon ? styles.inputTextNoIcon : null,
                        disabled ? styles.disabled : null,
                        validationMessage && this.props.value === "" ? styles.withValidationError : null,
                        this.props.value !== "" && !hideLabel ? styles.hasValue : null
                    )}
                    onKeyDown={this._onKeyDown}
                    onChange={this._handleTextChange}
                    value={this.state.value}
                    ref={this._inputRef}
                    disabled={disabled}
                    type="search"
                    tabIndex={this.props.tabIndex}
                />

                {this.state.value !== "" && this.state.value !== null && !hideLabel ? (
                    <div className={css(styles.title)}>{placeholder}</div>
                ) : null}

                {this.props.type !== searchInputTypes.textNoIcon ? (
                    <img src={searchStreamlineIcon} alt="search" className={css(
                        styles.icon,
                        this.props.type === "text" ? styles.iconText : null,
                    )} />
                ) : null
                }
                {this.state.open ? (
                    <div
                        className={css(
                            styles.borderContainer,
                            this.props.type === searchInputTypes.textNoIcon ? styles.borderContainerNoIcon : null
                        )}
                        style={{
                            visibility: this.state.searchResults.length === 0 ? "hidden" : "visible",
                            top: this.state.open ? this.state.inputHeight + 10 : 0,
                            height: this.state.open
                                ? this.state.popupHeight : null
                        }}
                    >
                        {this._renderResults()}
                    </div>
                ) : null
                }
            </div>
        );
    }
}
