import { StyleSheet, css } from "aphrodite";
import * as color from "../../../constants/color";
import PropTypes from "prop-types";
import React, { Component } from "react";

const WIDTH_PADDING = 60;
const LEFT_PADDING = 12;
const ITEM_HEIGHT = 34;
const OPTION_SCROLL_LENGTH = 10;

const styles = StyleSheet.create({
    optionContainer: {
        cursor: "pointer",
        display: "block",
        backgroundColor: color.white,
        textAlign: "left",
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 2,
        paddingBottom: 2,
        outline: 0,
        border: "none",
        borderBottom: `1px solid ${color.lightGray2}`,
        minHeight: 34,
        color: color.darkestGray,
        ":hover": {
            backgroundColor: color.mediumRed,
            color: color.white,
        },
    },
    staticHeight: {
        height: 34,
    },
    option: {
        width: 370,
    },
    optionText: {
        lineHeight: "34px",
        fontSize: 14,
        fontWeight: 400,
        whiteSpace: "nowrap",
        overflow: "hidden",
        bckgroundColor: color.white,
        width: "100%",
    },
    active: {
        backgroundColor: color.mediumRed,
        color: color.white,
    },
    subOptionsContainer: {
        borderTop: `1px solid ${color.lightGray2}`,
        position: "relative",
        top: -34,
        boxShadow: "0 4px 5px 0 rgba(0,0,0,0.14), 0 8px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2)",
    },
    subOptionsContainerScroll: {
        overflowY: "auto",
        overflowX: "hidden",
    }
});

export default class NestedDropdownRow extends Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        value: PropTypes.string,
        onSelect: PropTypes.func.isRequired,
        option: PropTypes.shape({
            label: PropTypes.string.isRequired,
            options: PropTypes.array,
        }).isRequired,
        openLeft: PropTypes.bool.isRequired,
    };

    state = {
        activeOption: null,
        isOpen: false,
        optionWidth: null,
    };

    _getOptionWidth = () => {
        this.element = document.createElement('canvas');
        this.context = this.element.getContext("2d");
        this.context.font = "400 16px Roboto";

        const text = this.props.option.options.map(item => item.label).reduce((a, b) => {
            return a.length > b.length ? a : b;
        });

        return this.context.measureText(text).width + WIDTH_PADDING;
    };

    _setIsOpen = isOpen => {
        this.setState({
            optionWidth: this._getOptionWidth(),
            isOpen,
        });
    };

    _renderSubOptions = (option, index) => (
        <button
            key={index}
            className={css(
                styles.optionContainer,
                index === this.state.activeOption ? styles.active : null
            )}
            style={{ width: this.state.optionWidth }}
            onClick={() => this._onSelect(option)}
        >
            <div className={css(styles.optionText)}>
                {option.label}
            </div>
        </button>
    );

    _onSelect = option => {
        this.props.onSelect(option, this.props.index);
        this._setIsOpen(false);
    };

    render() {
        const { option, index, openLeft } = this.props;
        const showScroll = option.options.length > OPTION_SCROLL_LENGTH;
        return (
            <div
                key={index}
                className={css(
                    styles.option,
                    styles.optionContainer,
                    styles.staticHeight,
                    // index === this.state.activeOption ? styles.active : null
                )}
                onMouseEnter={() => this._setIsOpen(true)}
                onMouseLeave={() => this._setIsOpen(false)}
            >
                <div className={css(styles.optionText)}>
                    {option.label}
                </div>
                <div
                    className={css(
                        styles.subOptionsContainer,
                        showScroll ? styles.subOptionsContainerScroll : null,
                    )}
                    style={{
                        visibility: this.state.isOpen ? "visible" : "hidden",
                        width: this.state.optionWidth,
                        left: openLeft ? -(this.state.optionWidth + LEFT_PADDING) : 355,
                        maxHeight: showScroll ? OPTION_SCROLL_LENGTH * ITEM_HEIGHT : null,
                    }}
                >
                    {option.options.map(this._renderSubOptions)}
                </div>
            </div>
        );
    }
}
