import * as breakpoints from "../../constants/breakpoints";
import * as color from "../../constants/color";
import * as dimensions from "../../constants/dimensions";
import * as texchangePropTypes from "../../texchangePropTypes";
import * as sessionActions from "../../actions/sessionActions";
import * as routeHelpers from "../../lib/routeHelpers";
import * as zIndexes from "../../constants/zIndexes";
import { ReactComponent as Logo } from "../../images/logo.svg";
import er_rating_hub from "../../images/er_rating_hub.png";

import brokerHubLogo from "../../images/BrokerHubLogo.png";
import Footer from "../Footer";
import logger from "../../lib/logger";
import PropTypes from "prop-types";
import React, { Component } from "react";
import TabNavigation from "../TabNavigation";
import AddButton from "../AddButton";
import SmallDropdown from "../inputs/SmallDropdown";
import LinkButton from "../text/LinkButton";
import UserDashboardScreen from "../screens/UserDashboardScreen";
import AccountScreen from "../screens/AccountScreen";
import BrokersScreen from "../screens/BrokersScreen";
import BrokerDetailScreen from "../screens/BrokerDetailScreen";
import AgencyDetailScreen from "../screens/AgencyDetailScreen";
import AgencyForm from "../forms/AgencyForm";
import BrokerForm from "../forms/BrokerForm";
import QuotesScreen from "../screens/QuotesScreen";
import QuoteDetailsScreen from "../screens/QuoteDetailsScreen";
import QuoteEditScreen from "../screens/QuoteEditScreen";
import GroupsScreen from "../screens/GroupsScreen";
import GroupDetailScreen from "../screens/GroupDetailScreen";
import PlanEditScreen from "../screens/PlanEditScreen";
import GroupForm from "../forms/GroupForm";
import CarriersScreen from "../screens/CarriersScreen";
import PlansScreen from "../screens/PlansScreen";
import FavoritesScreen from "../screens/FavoritesScreen";
import PlanDetailScreen from "../screens/PlanDetailScreen";
import PersonnelScreen from "../screens/PersonnelScreen";
import PersonnelDetailScreen from "../screens/PersonnelDetailScreen";
import SupportScreen from "../screens/SupportScreen";
import TermsScreen from "../screens/TermsScreen";
import { connect } from "react-redux";
import { Container, Row, Column } from "../grid";
import { Route } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import * as officesApi from "../../lib/api/offices";
import ls from "local-storage";

const styles = StyleSheet.create({
    profileBar: {
        zIndex: zIndexes.HEADER,
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        backgroundColor: color.white,
        height: 100,
        padding: 20,
        borderBottom: `1px solid ${color.darkGray2}`,
        [breakpoints.tabletAndDown]: {
            display: "none",
        },
    },
    logo: {
        verticalAlign: "middle",
        fill: color.darkRed,
        ":hover": {
            cursor: "pointer",
        }
    },
    title: {
        verticalAlign: "middle",
    },
    header: {
        [breakpoints.tabletAndDown]: {
            textAlign: "center",
        },
        [breakpoints.desktopOnly]: {
            textAlign: "left",
            marginLeft: 5,
        },
    },
    inlineTable: {
        borderCollapse: "collapse",
        verticalAlign: "middle",
        marginTop: -20,
    },
    tblCell: {
        backgroundColor: color.white,
        textAlign: "left",
    },
    tblCellRight: {
        backgroundColor: color.white,
        textAlign: "right",
    },
    officeSelect: {
        textAlign: "center",
        paddingTop: 18,
        marginLeft: 10,
    },
    white: {
        backgroundColor: color.mainBG,
    },
    content: {
        width: "calc(100vw - 136px)",
        /*width: "calc(100vw - 288px)", --to get the gray sidebars back*/
        position: "relative",
        top: 100,
        left: 119,
        overflowX: "hidden",

        [breakpoints.desktopOnly]: {
            paddingTop: dimensions.authDesktopVPadding,
            paddingBottom: dimensions.authDesktopVPadding,
            // min height so footer is always at bottom of browser window
            minHeight: "calc(100vh - 128px)",
            /*minHeight: "calc(100vh - 227px)", --removed 100px height from footer*/
        },
    },
    footer: {
        backgroundColor: color.white,
    },
    signOutContainer: {
        float: "right",
    },
    welcome: {
        fontWeight: 500,
        fontSize: 14,
    },
    newQuote: {
        margin: "14px 15px 1px 15px",
        padding: "5px 12px",
    },
    dropdown: {
        width: 200,
        marginLeft: 10,
        zIndex: 10000,
    },
    censusLink: {
        fontSize: 14,
        color: color.mediumYellow1,
        textDecoration: "underline",
        margin: "5px 15px",
        float: "right",
    },
    brokerHubLink: {
        marginRight: 15,
    }
});

const strings = {
    rbgQuoting: "Quoting",
    headerTitle: "Emerson Rogers Rating Hub",
    signOutLabel: "Sign Out",
    newQuote: "New Quote",
    downloadTemplate: "Get Census Template",
    censusLink: "/files/census_import.xlsx",
    brokerHubTitle: "Go To Broker Hub",
    brokerHubLink: "https://broker.rbghub.com",
};

export class Authenticated extends Component {
    static propTypes = {
        location: texchangePropTypes.location.isRequired,
        me: texchangePropTypes.me.isRequired,
        updateMe: PropTypes.func.isRequired,
        signOut: PropTypes.func.isRequired,
        history: texchangePropTypes.history.isRequired,
    };

    state = {
        offices: [],
        officeId: null,
        hasOffices: false,
    };

    constructor(props) {
        super(props);
        this._checkedOffices = false;
    }

    componentDidMount() {
        const { me } = this.props;
        const path = this.props.history.location.pathname;
        //permission route checks
        if (!me.isSysAdmin) {
            //broker
            if (me.isBroker &&
                (path.startsWith("/agencies") || path.startsWith("/brokers") || path.startsWith("/groups/edit") || path.startsWith("/carriers") || path.startsWith("/plans/") || path.startsWith("/personnel"))) {
                //redirect back to dashboard
                window.location.href = "/";
            } //state admin
            else if ((me.isStateAdmin && !me.isOfficeAdmin && !me.isNormalUser && !me.isMultiOffice) &&
                (path.startsWith("/agencies") || path.startsWith("/brokers") || path.startsWith("/groups/edit") || path.startsWith("/personnel"))) {
                //redirect back to dashboard
                window.location.href = "/";
            } //office admin
            else if ((me.isOfficeAdmin && !me.isNormalUser && !me.isMultiOffice) &&
                (path.startsWith("/agencies/edit") || path.startsWith("/brokers/edit") || path.startsWith("/groups/edit") || path.startsWith("/plans/"))) {
                //redirect back to dashboard
                window.location.href = "/";
            } //normal user
            else if ((me.isNormalUser || me.isMultiOffice) &&
                (path.startsWith("/agencies/edit") || path.startsWith("/brokers/edit") || path.startsWith("/groups/edit") || path.startsWith("/carriers") || path.startsWith("/plans/") || path.startsWith("/personnel"))) {
                //redirect back to dashboard
                window.location.href = "/";
            }
        }
        //office selector
        const lastOfficeId = ls.get("SelectedOfficeId");
        if (!this._checkedOffices) {
            officesApi.getMine().then(result => {
                this._checkedOffices = true;
                if (result && result.length > 1) {
                    const ids = this._getIds(result);
                    //have a previously saved office?
                    if (lastOfficeId !== null) {
                        let setOfficeId = null;
                        if (ids.indexOf(lastOfficeId) > -1) {
                            setOfficeId = lastOfficeId;
                        }
                        else {
                            if (ids.indexOf(me.regionalOfficeId) > -1) {
                                setOfficeId = me.regionalOfficeId.toString();
                            }
                        }
                        if (setOfficeId !== null) {
                            this.setState({
                                officeId: setOfficeId,
                            });
                        }
                    }
                    this.setState({
                        offices: result,
                        hasOffices: true,
                    });
                }
                else {
                    this.setState({
                        offices: [],
                        hasOffices: false,
                    });
                }
            });
        }
    };

    _getIds = (input) => {
        let ids = [];
        for (let i = 0; i < input.length; i++) {
            ids.push(input[i]["id"]);
        }
        return ids;
    }

    _signOut = () => {
        // NOTE: don't need to do anything here, PrivateRoute should redirect
        ls.set("SelectedOfficeId", null);
        this.props.signOut().catch(err => logger.warn(err));
    };

    _newQuote = () => {
        const path = this.props.history.location.pathname;
        if (path && path.startsWith("/quotes/edit/")) {
            window.location.href = "/quotes/edit"; //new quote has same path...  without the quote id
        } else {
            this.props.history.push(routeHelpers.createQuote());
        }
    };

    _handleOfficeChange = (e) => {
        this.setState({
            officeId: e.value,
        });
        ls.set("SelectedOfficeId", e.value);
        //reload page
        const current = window.location.href.toLowerCase();
        if (current.indexOf("detail") > -1 || current.indexOf("edit") > -1) {
            //redirect back to dashboard
            window.location.href = "/";
        } else {
            window.location.reload();
        }
    };

    render() {
        if (!this._checkedOffices) {
            return null;
        }

        const { me } = this.props;
        return (
            <React.Fragment>
                <div className={css(styles.profileBar)}>
                    <Container>
                        <Row>
                            <Column large={5}>
                                <div className={css(styles.header)}>
                                    {/* <a href="/">
                                        <Logo className={css(styles.logo)} />
                                    </a> */}
                                    <span className={css(styles.title)}>
                                        <img style={{ width: 250, height: "auto" }} src={er_rating_hub} alt={strings.headerTitle} />
                                    </span>
                                </div>
                            </Column>
                            <Column large={5} largeOffset={2}>
                                <table className={css(styles.inlineTable, styles.signOutContainer)}>
                                    <tbody>
                                        <tr>
                                            <td className={css(styles.tblCell, styles.welcome)}>
                                                Welcome, {me.firstName}!&nbsp;&nbsp;|&nbsp;&nbsp;
                                                <LinkButton onClick={this._signOut}>
                                                    {strings.signOutLabel}
                                                </LinkButton>
                                            </td>
                                            <td className={css(styles.tblCell, styles.officeSelect)}>
                                                {this.state.hasOffices &&
                                                    <SmallDropdown
                                                        name="globalOfficeId"
                                                        aStyles={styles.dropdown}
                                                        className={css(styles.dropdown)}
                                                        options={Object.keys(this.state.offices).map(id => ({
                                                            label: this.state.offices[id].name,
                                                            value: this.state.offices[id].id.toString(),
                                                        }))}
                                                        onChange={this._handleOfficeChange}
                                                        placeholder="Select Office"
                                                        value={this.state.officeId}
                                                        noWrap={true}
                                                    />
                                                }&nbsp;
                                            </td>
                                            <td className={css(styles.tblCellRight)}>
                                                {me.ratingEngineQuotePermission &&
                                                    <AddButton
                                                        aStyles={styles.newQuote}
                                                        onClick={this._newQuote}
                                                        label={strings.newQuote}
                                                        showAsBlue={true}
                                                    />
                                                }
                                                <br />
                                                <a href={strings.censusLink} target="_top" className={css(styles.censusLink)}>
                                                    <b>{strings.downloadTemplate}</b>
                                                </a>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </Column>
                        </Row>
                    </Container>
                </div>

                <div className={css(styles.white)}>
                    <Container backgroundColor={color.borderBackgroundGray}>
                        <Row>
                            <Column large={12}>
                                <TabNavigation location={this.props.location} me={me} />
                                <div className={css(styles.content)}>
                                    <Route path="/" exact component={UserDashboardScreen} />
                                    <Route path="/dashboard" exact component={UserDashboardScreen} />
                                    <Route path="/myaccount" exact component={AccountScreen} />
                                    {(me.isSysAdmin || me.isOfficeAdmin || me.isNormalUser || me.isMultiOffice) &&
                                        <Route path="/brokers" exact component={BrokersScreen} />
                                    }
                                    {me.isSysAdmin &&
                                        <Route path="/brokers/edit" exact component={BrokerForm} />
                                    }
                                    {(me.isSysAdmin || me.isOfficeAdmin || me.isNormalUser || me.isMultiOffice) &&
                                        <Route path="/brokers/detail/:id" exact component={BrokerDetailScreen} />
                                    }
                                    {(me.isSysAdmin || me.isOfficeAdmin || me.isNormalUser || me.isMultiOffice) &&
                                        <Route path="/agencies/detail/:id" exact component={AgencyDetailScreen} />
                                    }
                                    {me.isSysAdmin &&
                                        <Route path="/agencies/edit" exact component={AgencyForm} />
                                    }
                                    <Route path="/quotes" exact component={() => (<QuotesScreen history={this.props.history} location={this.props.location} me={me} />)} />
                                    <Route path="/quotes/detail/:id" exact component={QuoteDetailsScreen} />
                                    <Route path="/quotes/edit/:id?" exact component={QuoteEditScreen} />
                                    <Route path="/groups" exact component={GroupsScreen} />
                                    {me.isSysAdmin &&
                                        <Route path="/groups/edit" exact component={GroupForm} />
                                    }
                                    <Route path="/groups/detail/:id" exact component={GroupDetailScreen} />
                                    {(me.isSysAdmin || me.isStateAdmin || me.isOfficeAdmin || me.isMultiOffice) &&
                                        <Route path="/carriers" exact component={CarriersScreen} />
                                    }
                                    <Route path="/plans" exact component={PlansScreen} />
                                    {(me.isSysAdmin || me.isStateAdmin) &&
                                        <Route path="/plans/detail/:id" exact component={PlanDetailScreen} />
                                    }
                                    {(me.isSysAdmin || me.isStateAdmin) &&
                                        <Route path="/plans/edit" exact component={PlanEditScreen} />
                                    }
                                    {(me.isSysAdmin || me.isOfficeAdmin) &&
                                        <Route path="/personnel" exact component={PersonnelScreen} />
                                    }
                                    {(me.isSysAdmin || me.isOfficeAdmin) &&
                                        <Route path="/personnel/detail/:id" exact component={PersonnelDetailScreen} me={me} />
                                    }
                                    <Route path="/favorites" exact component={FavoritesScreen} />
                                    <Route path="/support" exact component={SupportScreen} />
                                    <Route path="/terms" exact component={TermsScreen} />
                                </div>
                            </Column>
                        </Row>
                    </Container>
                </div>

                <div className={css(styles.footer)}>
                    <Footer />
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = state => ({
    me: state.session.me,
    officeId: state.session.officeId,
});

export default connect(
    mapDispatchToProps,
    { signOut: sessionActions.signOut, updateMe: sessionActions.updateMe },
)(Authenticated);
