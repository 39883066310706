import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import BodyCopyHighlight from "../../text/BodyCopyHighlight";
import { ReactComponent as ChevronDown } from "../../../images/SVGs/chevron-down.svg";
import { ReactComponent as ChevronUp } from "../../../images/SVGs/chevron-up.svg";
import SmallDropdownRow from "./SmallDropdownRow";
import * as inputSizes from "../../../constants/inputSizes";
import * as color from "../../../constants/color";
import * as texchangePropTypes from "../../../texchangePropTypes";

const VERTICAL_PADDING_DIFFERENCE = 12;
const ADDITIONAL_PADDING = 20;
const ITEM_HEIGHT = 34;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        outline: 0,
        backgroundColor: color.white,
    },
    inputWide: {
        minWidth: "95%",
    },
    openContainer: {
        borderTop: "solid",
        borderTopColor: color.lightOrange,
        borderWidth: 1,
        borderRadius: 4,
    },
    chevron: {
        zIndex: 9000,
        pointerEvents: "none",
        tint: color.mediumYellow1,
    },
    title: {
        position: "absolute",
        top: 6,
        left: 11,
        fontSize: 13,
        color: color.darkGray,
    },
    borderContainer: {
        transition: "height 0.3s",
        backgroundColor: color.white,
        borderTop: `1px solid ${color.lightGray2}`,
        position: "absolute",
        right: 0,
        left: 0,
        overflow: "hidden",
    },
    labelContainer: {
        cursor: "pointer",
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: "auto",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: 8,
        backgroundColor: color.transparent,
        textAlign: "left",
        outline: 0,
        borderRadius: 4,
        boxShadow: "0 1px 3px 0 rgba(0,0,0,0.4)",
        overflow: "hidden",
        borderBottom: `1px solid ${color.lightGray2}`,
    },
    optionsContainer: {
        borderTop: `1px solid ${color.lightGray2}`,
        boxShadow: "0 4px 5px 0 rgba(0,0,0,0.14), 0 8px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2)",
        position: "absolute",
        top: 35,
        left: 0,
        maxHeight: 385,
        minWidth: "100%",
        overflowY: "auto",
        backgroundColor: color.white,
    },
    text: {
        fontSize: 14,
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: "auto",
        fontWeight: 400,
        color: color.darkestGray,
    },
    invisible: {
        visibility: "hidden",
    },
});

const strings = {
    chevronDown: "Open select dropdown",
};

export default class SmallDropdown extends Component {
    static propTypes = {
        aStyles: texchangePropTypes.aphroditeStyles,
        placeholder: PropTypes.string.isRequired,
        value: PropTypes.string,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            }),
        ).isRequired,
        onChange: PropTypes.func.isRequired,
        noWrap: PropTypes.bool,
        size: PropTypes.string,
        tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    };

    static defaultProps = {
        openLeft: false,
    };

    constructor(props) {
        super(props);

        this._inputRef = React.createRef();
        this._popupRef = React.createRef();

        this.state = {
            isOpen: false,
            popupHeight: null,
            inputHeight: null,
            optionsFiltered: [],
            selectedIndex: 0,
            searchTerm: '',
        };
    }

    componentDidMount() {
        this._mounted = true;
        setTimeout(() => {
            if (!this._mounted) {
                return;
            }

            this.setState({
                optionsFiltered: this.props.options,
                selectedIndex: this.props.options.findIndex(o => o.value === this.props.value),
                popupHeight: this._getPopupHeight(this._inputRef.current.clientHeight, this.props.options.length),
                inputHeight: this._inputRef.current.clientHeight,
            });
        });
        window.addEventListener("click", this._onClick);
    }

    componentWillUnmount() {
        this._mounted = false;
        window.removeEventListener("click", this._onClick);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.options.length !== this.props.options.length) {
            setTimeout(() => {
                if (!this._mounted) {
                    return;
                }

                this.setState({
                    optionsFiltered: this.props.options,
                    selectedIndex: this.props.options.findIndex(o => o.value === this.props.value),
                    popupHeight: this._getPopupHeight(this.state.inputHeight, this.props.options.length),
                });
            });
        }
    }

    _onClick = e => {
        if (e && this._inputRef.current && this._inputRef.current.contains(e.target)) {
            //if you are selecting an option, this will be a DIV
            //if you are clicking on the main dropdown, this will be a DIV or a SPAN
            const className = e.target.className;
            if (e.target.nodeName === "DIV" && className.startsWith("optionText")) {
                this._setIsOpen(false);
            } else if ((e.target.nodeName === "DIV" || e.target.nodeName === "SPAN") && !className.startsWith("optionText")) {
                this._setIsOpen(this.state.isOpen);
            } else {
                this._setIsOpen(false);
            }
        }
        else if (this.state.isOpen) {
            this._setIsOpen(false);
        }
    };

    _onKeyUp = e => {
        const pressed = e.key;
        if (pressed === "Tab") {
            this._openDropdown();
        }
    };

    _onKeyDown = e => {
        const pressed = e.key;
        if (pressed === "Enter") {
            this._closeDropdown();
        } else if (pressed === "ArrowUp" || pressed === "ArrowDown") {
            e.preventDefault();
            let index = this.state.selectedIndex;
            if (pressed === "ArrowUp" && this.state.selectedIndex > 0) {
                index = index - 1;
            } else if (pressed === "ArrowDown" && this.state.optionsFiltered.length > (index + 1)) {
                index = index + 1;
            }
            //change
            this.props.onChange(this.state.optionsFiltered[index]);
            this.setState({ selectedIndex: index });
        } else {
            const textInput = this.state.searchTerm + pressed.replace(/[^0-9a-zA-Z]+/g, "");
            if (textInput && textInput.length >= 1) {
                let filtered = this.state.optionsFiltered.filter(o => o.label.toLowerCase().includes(textInput) && !o.label.toLowerCase().startsWith("all "));
                if (filtered && filtered.length === 1) {
                    this.props.onChange(filtered[0]);
                    this._closeDropdown();
                }
                else if (filtered && filtered.length > 1) {
                    //keep the "all" option
                    const first = this.props.options[0];
                    if (first.label.toLowerCase().startsWith("all ")) {
                        filtered.unshift(first);
                    }
                    //update the filtered list
                    this.setState({
                        optionsFiltered: filtered,
                        popupHeight: this._getPopupHeight(this.state.inputHeight, filtered.length),
                        searchTerm: textInput,
                    });
                }
            }
        }
    };

    _getValueLabel = () => {
        for (const option of this.props.options) {
            if (option.value === this.props.value) {
                return option.label;
            }
        }
    };

    _getPopupHeight = (inputHeight, length) => {
        if (!this._popupRef.current) {
            return null;
        }

        const dd = this._popupRef.current.getBoundingClientRect();
        const appHeight = document.body.classList.contains("no-scroll")
            ? window.innerHeight
            : document.body.clientHeight;

        let popupHeight
        if (length > 10) {
            popupHeight = (10 * ITEM_HEIGHT) - VERTICAL_PADDING_DIFFERENCE;
        } else {
            popupHeight = length * ITEM_HEIGHT;
        }

        if (popupHeight + dd.top + ADDITIONAL_PADDING + VERTICAL_PADDING_DIFFERENCE > appHeight) {
            return appHeight - dd.top - inputHeight - 16;
        } else {
            return popupHeight + 1;
        }
    };

    _renderOptions = () => {
        const options = Object.assign([], this.state.optionsFiltered);

        return (
            <div
                ref={this._popupRef}
                className={css(styles.optionsContainer)}
                style={
                    this.state.isOpen
                        ? {
                            overflowY: "visible",
                            zIndex: 10000,
                        }
                        : null
                }
            >
                {options.map(this._renderOption)}
            </div>
        );
    };

    _onChange = option => {
        this.props.onChange(option);
        this._setIsOpen(false);
    }

    _setIsOpen = isOpen => {
        this.setState({ isOpen });
    }

    _openDropdown = () => {
        this.setState({
            optionsFiltered: this.props.options,
            selectedIndex: this.props.options.findIndex(o => o.value === this.props.value),
            searchTerm: '',
            isOpen: true,
        });
    };

    _closeDropdown = () => this.setState({ isOpen: false });

    _renderOption = (option, index) => (
        <SmallDropdownRow
            key={index}
            index={index}
            onChange={this._onChange}
            option={option}
            value={this.props.value}
        />
    );

    render() {
        const isWide = this.props.size === inputSizes.large;

        return (
            <React.Fragment>
                <div
                    ref={this._inputRef}
                    className={css(
                        styles.container,
                        isWide ? styles.inputWide : null,
                        this.state.isOpen ? styles.openContainer : null,
                        this.props.aStyles,
                    )}
                    style={{
                        zIndex: this.state.open ? 10000 : null
                    }}
                    tabIndex={this.props.tabIndex}
                    onBlur={this._closeDropdown}
                    onKeyUp={this._onKeyUp}
                    onKeyDown={this._onKeyDown}
                >
                    <div
                        className={css(styles.labelContainer)}
                        onClick={this.state.isOpen ? this._closeDropdown : this._openDropdown}
                    >
                        <BodyCopyHighlight aStyles={[styles.text]}>
                            {this.props.value ? this._getValueLabel() : this.props.placeholder}
                        </BodyCopyHighlight>
                        {this.state.isOpen ? (
                            <ChevronUp alt={strings.chevronUp} className={css(styles.chevron)} />
                        ) : (
                            <ChevronDown alt={strings.chevronDown} className={css(styles.chevron)} />
                        )}
                    </div>
                    {false &&
                        <div
                            className={css(
                                styles.borderContainer,
                            )}
                            style={{
                                visibility: this.state.isOpen ? "visible" : "hidden",
                                top: this.state.isOpen ? this.state.inputHeight - 2 : 0,
                                paddingBottom: 0,
                                height: this.state.isOpen ? this.state.popupHeight : null,
                                overflowY: this.state.isOpen ? "auto" : null
                            }}
                        >
                        </div>
                    }
                    <div
                        style={{
                            display: this.state.isOpen ? "block" : "none",
                            whiteSpace: this.props.noWrap ? "nowrap" : "",
                        }}
                    >
                        {this._renderOptions()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}