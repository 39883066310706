import instance from "./instance";
import format from "date-fns/format";

const responseData = response => response.data;

export function getAll() {
    return instance
        .request({
            url: "/medicalPlan/get-medical-plans",
            method: "GET",
        })
        .then(responseData);
}

export function getPaged(page, sortColumn, sortDirection, quoteId, current, carrierIds, networks, planTypes, tierIds, deductibleRange, searchTerm, areaId, coinsuranceRange, packages, ratingAreas, all = false) {
    return instance
        .request({
            url: "/medicalPlan/get-medical-plans-page",
            method: "POST",
            data: {
                page,
                sortColumn,
                sortDirection,
                quoteId,
                current,
                carrierIds,
                networks,
                planTypes,
                tierIds,
                deductibleRange,
                coinsuranceRange,
                searchTerm,
                packages,
                ratingAreas,
                areaId,
                all,
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function getPagedForQuote(page, sortColumn, sortDirection, quoteId, carrierIds, networks, planTypes, tierIds, deductibleRange, coinsuranceRange, oopRange, employeePremiumRange, monthlyPremiumRange, searchTerm, packages, denVis1, denVis2, denVis3, denVis4, denVis5, denVis6, denVis7, denVis8, infertility, hsa, all = false) {
    return instance
        .request({
            url: "/medicalPlan/get-quote-medical-plans-page",
            method: "POST",
            data: {
                page,
                sortColumn,
                sortDirection,
                quoteId,
                carrierIds,
                networks,
                planTypes,
                tierIds,
                deductibleRange,
                coinsuranceRange,
                oopRange,
                employeePremiumRange,
                monthlyPremiumRange,
                searchTerm,
                packages,
                infertility,
                hsa,
                all,
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function getMedicalPlansDisplay(areaId, carrierId, year, planType, ratingAreas) {
    return instance
        .request({
            url: "/medicalPlan/get-medical-plans-display",
            method: "POST",
            data: {
                areaId,
                carrierId,
                year,
                planType,
                ratingAreas,
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function getMedicalPlanExtract(areaId, carrierId, year, planType, fileName) {
    return instance
        .request({
            url: "/medicalPlan/get-medical-plan-extract",
            method: "POST",
            data: {
                areaId,
                carrierId,
                year,
                planType
            },
            responseType: "blob",
        })
        .then((response) => {
            if (response.data && response.data.size > 0) {
                if (response.data.type.startsWith("application")) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                }
                else if (response.data.type === "text/plain") {
                    return response.data.text();
                }
            }
            else {
                return "ERROR";
            }
        });
}

export function uploadPlanExtract(fileData) {
    if (fileData) {
        const formData = new FormData();
        formData.append("file", fileData);

        return instance
            .request({
                url: "/medicalPlan/upload-extract",
                method: "POST",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then(responseData);
    }
}

export function getById(id) {
    return instance
        .request({
            url: `/medicalPlan/get-medical-plan/${id}`,
            method: "GET",
        })
        .then(responseData);
}

export function savePlanCustom(plan) {
    return instance
        .request({
            url: "/medicalPlan/save-custom",
            method: "POST",
            data: { ...plan },
        })
        .then(responseData);
}

export function saveMedicalPlan(plan) {
    return instance
        .request({
            url: "/medicalPlan/save-medical-plan",
            method: "POST",
            data: { ...plan },
        })
        .then(responseData);
}

export function getSBC(id) {
    return instance
        .request({
            url: `/sbc/getSBC/${id}`,
            method: "GET",
        })
        .then(responseData);
}

export function getAgeRates(selectedPlanIds, effectiveDate, postalCode, countyId, includeContribution, employeeContribution, dependentContribution, contributionInterval, baseMedicalPlanName, reverseSort) {
    return instance
        .request({
            url: "/medicalPlan/get-age-rates",
            method: "POST",
            data: {
                selectedPlanIds,
                effectiveDate,
                postalCode,
                countyId,
                includeContribution,
                employeeContribution,
                dependentContribution,
                contributionInterval,
                baseMedicalPlanName,
                reverseSort
            },
            responseType: "blob",
        })
        .then((response) => {
            if (response.data && response.data.size > 100) {
                if (response.data.type.startsWith("application")) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', format(effectiveDate, 'YYYYMMDD') + '_Zip' + postalCode + '_AgeRates.xlsx');
                    document.body.appendChild(link);
                    link.click();
                }
                else if (response.data.type === "text/plain") {
                    return response.data.text();
                }
            }
            else {
                return "ERROR: No rates returned for the parameters entered.  Please check the postal code (is it in the rating area?) and the effective date (quarter) requested.";
            }
        });
}

export function getSideBySides(selectedPlanIds) {
    return instance
        .request({
            url: "/medicalPlan/get-side-by-sides",
            method: "POST",
            data: {
                selectedPlanIds
            },
            responseType: "blob",
        })
        .then((response) => {
            if (response.data && response.data.size > 100) {
                if (response.data.type.startsWith("application")) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', format(new Date(), 'YYYYMMDD') + '_SideBySides.xlsx');
                    document.body.appendChild(link);
                    link.click();
                }
                else if (response.data.type === "text/plain") {
                    return response.data.text();
                }
            }
            else {
                return "ERROR: No plans returned for the parameters entered.";
            }
        });
}