import instance from "./instance";

const responseData = response => response.data;

export function getAll() {
    return instance
        .request({
            url: "/broker/get-brokers",
            method: "GET",
        })
        .then(responseData);
}

export function getPaged(page, sortColumn, sortDirection) {
    return instance
        .request({
            url: "/broker/get-brokers-page",
            method: "POST",
            data: {
                page,
                sortColumn,
                sortDirection
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function searchBrokers(term) {
    term = term.replace(/[^0-9a-zA-Z\s]+/g,"");
    if (term.length === 0) { term = "*"; }
    return instance
        .request({
            url: `/broker/search-brokers/${term}`,
            method: "GET",
        })
        .then(responseData);
}

export function getById(id) {
    return instance
        .request({
            url: `/broker/get-broker/${id}`,
            method: "GET",
        })
        .then(responseData);
}

export function saveBroker(broker) {
    return instance
        .request({
            url: "/broker/save-broker",
            method: "POST",
            data: { ...broker },
        })
        .then(responseData);
}


export function uploadLogo(brokerId, fileData) {
    const formData = new FormData();
    formData.append("brokerId", brokerId);
    formData.append("file", fileData);

    return instance
        .request({
            url: "/broker/uploadLogo",
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(responseData);
}

export function deleteLogo(id) {
     return instance
        .request({
            url: `/broker/deleteLogo/${id}`,
            method: "POST",            
        })
        .then(responseData);   
}

export function saveBrokerTeam(brokerId, brokerTeamMemberId) {
    return instance
        .request({
            url: "/broker/save-broker-team",
            method: "POST",
            data: { 
                brokerId,
                brokerTeamMemberId,
            },
        })
        .then(responseData);
}

export function getBrokerTeams(id) {
    return instance
        .request({
            url: `/broker/get-broker-teams/${id}`,
            method: "GET",
        })
        .then(responseData);
}

export function getBrokersByTeam(id) {
    return instance
        .request({
            url: `/broker/get-brokers-by-team/${id}`,
            method: "GET",
        })
        .then(responseData);    
}

export function deleteBrokerTeam(brokerId, brokerTeamMemberId) {
    return instance
        .request({
            url: "broker/delete-broker-team-member",
            method: "POST",
            data: { 
                brokerId,
                brokerTeamMemberId,
            },
        })
        .then(responseData);
}