import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import BodyCopyHighlight from "../../text/BodyCopyHighlight";
import { ReactComponent as ChevronDown } from "../../../images/SVGs/chevron-down.svg";
import NestedDropdownRow from "./NestedDropdownRow";
import * as color from "../../../constants/color";
import * as texchangePropTypes from "../../../texchangePropTypes";

const VERTICAL_PADDING_DIFFERENCE = 12;
const ADDITIONAL_PADDING = 20;
const ITEM_HEIGHT = 34;
const ZINDEX = 10000;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        outline: 0,
        maxWidth: 380,
    },
    chevron: {
        pointerEvents: "none",
        tint: color.mediumYellow1,
    },
    title: {
        position: "absolute",
        top: 6,
        left: 11,
        fontSize: 13,
        color: color.darkGray,
    },
    borderContainer: {
        transition: "height 0.3s",
        backgroundColor: color.white,
        borderTop: `1px solid ${color.lightGray2}`,
        position: "absolute",
        right: 0,
        left: 0,
        overflow: "hidden",
    },
    labelContainer: {
        cursor: "pointer",
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: "auto",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingTop: 14,
        paddingBottom: 12,
        paddingLeft: 16,
        paddingRight: 16,
        backgroundColor: color.white,
        textAlign: "left",
        outline: 0,
        border: `1px solid ${color.mediumGray}`,
        borderRadius: 4,
    },
    optionsContainer: {
        boxShadow: "0 4px 5px 0 rgba(0,0,0,0.14), 0 8px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2)",
        position: "absolute",
        top: 47,
        left: 1,
        borderTop: `1px solid ${color.lightGray2}`,
    },
    text: {
        fontSize: 14,
        height: 19,
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: "auto",
        fontWeight: 400,
        color: color.darkestGray,
    },
    active: {
        backgroundColor: color.mediumRed,
        color: color.white,
    },
    invisible: {
        visibility: "hidden",
    },
});

const strings = {
    chevronDown: "Open select dropdown",
};

export default class NestedDropdown extends Component {
    static propTypes = {
        aStyles: texchangePropTypes.aphroditeStyles,
        name: PropTypes.string,
        placeholder: PropTypes.string.isRequired,
        value: PropTypes.string,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                options: PropTypes.array,
            }),
        ).isRequired,
        onSelect: PropTypes.func.isRequired,
        openLeft: PropTypes.bool,
    };

    static defaultProps = {
        openLeft: false,
    };

    constructor(props) {
        super(props);

        this._inputRef = React.createRef();
        this._popupRef = React.createRef();

        this.state = {
            isOpen: false,
            popupHeight: null,
            inputHeight: null,
        };
    }

    componentDidMount() {
        this._mounted = true;
        setTimeout(() => {
            if (!this._mounted) {
                return;
            }

            this.setState({
                popupHeight: this._getPopupHeight(this._inputRef.current.clientHeight, this.props.options.length),
                inputHeight: this._inputRef.current.clientHeight,
            });
        });
        window.addEventListener("click", this._onClick);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.options.length !== this.props.options.length) {
            setTimeout(() => {
                if (!this._mounted) {
                    return;
                }

                this.setState({
                    popupHeight: this._getPopupHeight(this.state.inputHeight, this.props.options.length),
                });
            });
        }
    }

    _getPopupHeight = (inputHeight, length) => {
        if (!this._popupRef.current) {
            return null;
        }

        const dd = this._popupRef.current.getBoundingClientRect();
        const appHeight = document.body.classList.contains("no-scroll")
            ? window.innerHeight
            : document.body.clientHeight;

        const popupHeight = length * ITEM_HEIGHT;

        if (popupHeight + dd.top + ADDITIONAL_PADDING + VERTICAL_PADDING_DIFFERENCE > appHeight) {
            return appHeight - dd.top - inputHeight - 16;
        } else {
            return popupHeight + 1;
        }
    };

    _renderOptions = () => (
        <div
            ref={this._popupRef}
            className={css(styles.optionsContainer)}
            style={
                this.state.isOpen
                    ? {
                        overflowY: "visible",
                    }
                    : null
            }
        >
            {this.props.options.map(this._renderOption)}
        </div>
    );

    _onSelect = option => {
        this.props.onSelect({
            value: option,
            name: this.props.name,
        });
        this._setIsOpen(false);
    }

    _setIsOpen = isOpen => {
        this.setState({ isOpen });
    }

    _renderOption = (option, index) => (
        <NestedDropdownRow
            key={index}
            index={index}
            onSelect={this._onSelect}
            option={option}
            value={this.state.value}
            openLeft={this.props.openLeft}
        />
    );

    _getValueLabel = () => {
        for (const option of this.props.options) {
            for (const subOption of option.options) {
                if (subOption.value === this.props.value) {
                    return option.label;
                }
            }
        }

        return this.props.placeholder;
    };

    render() {
        return (
            <div>
                <div
                    ref={this._inputRef}
                    className={css(
                        styles.container,
                        this.props.aStyles,
                    )}
                    style={{
                        zIndex: this.state.isOpen ? ZINDEX : null
                    }}
                    onClick={() => this._setIsOpen(true)}
                    onMouseLeave={() => this._setIsOpen(false)}
                >
                    <div
                        className={css(styles.labelContainer)}
                        onClick={this.state.isOpen ? this._closeDropdown : this._openDropdown}
                    >
                        <BodyCopyHighlight aStyles={[styles.text]}>
                            {this.props.value ? this._getValueLabel() : this.props.placeholder}
                        </BodyCopyHighlight>
                        <ChevronDown alt={strings.chevronDown} className={css(styles.chevron)} />
                    </div>
                    <div
                        style={{
                            visibility: this.state.isOpen ? "visible" : "hidden",
                        }}
                    >
                        {this._renderOptions()}
                    </div>
                </div>
            </div>
        );
    }
}