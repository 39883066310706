import * as breakpoints from "../../constants/breakpoints";
import * as color from "../../constants/color";
import * as texchangePropTypes from "../../texchangePropTypes";
import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    title: {
        width: "100%",        
    },    
    h1: {
        color: color.darkestGray,
        fontSize: 20,        
        fontWeight: 500,        
        margin: 0,
        padding: 0,                

        [breakpoints.tabletAndDown]: {
            fontSize: 15,
            lineHeight: "20px",
        },
    },
});

export default function TabTitle(props) {
    const { aStyles, children, ...rest } = props;
    return (
        <div className={css(styles.title)}>            
            <h1 {...rest} className={css(styles.h1, aStyles)}>
                {children}
            </h1>            
        </div>
    );
}

TabTitle.propTypes = {
    aStyles: texchangePropTypes.aphroditeStyles,
    children: PropTypes.node,
};
