import * as reducers from "./reducers";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, combineReducers } from "redux";

const middleware = [thunk];
if (process.env.NODE_ENV === "development") {
    const createLogger = require("redux-logger").createLogger;
    const logger = createLogger({
        collapsed: true,
        duration: true,
    });

    middleware.push(logger);
}

const reducer = combineReducers(reducers);
const store = createStore(reducer, {}, applyMiddleware(...middleware));
export default store;
