import { Container, Row, Column } from "./grid";
import { StyleSheet, css } from "aphrodite";
import * as zIndexes from "../constants/zIndexes";
import React, { Component } from "react";

const styles = StyleSheet.create({
    container: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: zIndexes.DEBUG_GRID,
        pointerEvents: "none",
    },
    gridMarker: {
        backgroundColor: "#888",
        opacity: 0.3,
        textAlign: "center",
        height: "100vh",
        pointerEvents: "none",
    },
});

export default class DebugGrid extends Component {
    render() {
        return (
            <div className={css(styles.container)}>
                <Container>
                    <Row>
                        <Column large={1}>
                            <div className={css(styles.gridMarker)}>1</div>
                        </Column>

                        <Column large={1}>
                            <div className={css(styles.gridMarker)}>2</div>
                        </Column>

                        <Column large={1}>
                            <div className={css(styles.gridMarker)}>3</div>
                        </Column>

                        <Column large={1}>
                            <div className={css(styles.gridMarker)}>4</div>
                        </Column>

                        <Column large={1}>
                            <div className={css(styles.gridMarker)}>5</div>
                        </Column>

                        <Column large={1}>
                            <div className={css(styles.gridMarker)}>6</div>
                        </Column>

                        <Column large={1}>
                            <div className={css(styles.gridMarker)}>7</div>
                        </Column>

                        <Column large={1}>
                            <div className={css(styles.gridMarker)}>8</div>
                        </Column>

                        <Column large={1}>
                            <div className={css(styles.gridMarker)}>9</div>
                        </Column>

                        <Column large={1}>
                            <div className={css(styles.gridMarker)}>10</div>
                        </Column>

                        <Column large={1}>
                            <div className={css(styles.gridMarker)}>11</div>
                        </Column>

                        <Column large={1}>
                            <div className={css(styles.gridMarker)}>12</div>
                        </Column>
                    </Row>
                </Container>
            </div>
        );
    }
}
