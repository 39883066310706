import instance from "./instance";

const responseData = response => response.data;

export function getNews() {
    return instance
        .request({
            url: "/news/get-news",
            method: "GET",
        })
        .then(responseData);
}

export function getAlerts() {
    return instance
        .request({
            url: "/news/get-alerts",
            method: "GET",
        })
        .then(responseData);
}