import * as color from "../../constants/color";
import * as routeHelpers from "../../lib/routeHelpers";
import * as texchangePropTypes from "../../texchangePropTypes";
import PageTitle from "../text/PageTitle";
import TextInput from "../inputs/TextInput";
import Dropdown from "../inputs/Dropdown";
import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import ActionButton from "../ActionButton";
import * as medicalPlansApi from "../../lib/api/medicalPlans";
import backCircle from "../../images/SVGs/BackCircle.svg";
import logger from "../../lib/logger";
import describeNetworkError from "../../lib/describeNetworkError";
import uploadIcon from "../../images/SVGs/upload.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = StyleSheet.create({
    content: {
        paddingRight: 10,        
        paddingBottom: 80,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
        fontSize: 15,
    },
    contentSection: {
        width: "100%",
        padding: 0,
        backgroundColor: color.white,
        borderTop: `8px solid ${color.mediumRed}`,
        marginBottom: 20,
    },
    titleSection: {        
        heigth: 64,
        backgroundColor: color.white,
    },
    row: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        marginBottom: 20,
    },
    column: {
        display: "flex",
        flexDirection: "column",
        flexBasis: "100%",
        flex: 1,
        marginLeft: 15,
        marginRight: 15,
        color: color.tableText,
    },
    column2: {
        flex: 2,
    },
    column3: {
        flex: 3,
    },
    planCols: {
        marginLeft: 10,
        marginRight: 10,
        borderCollapse: "collapse",
    },
    colHeader: {
        width: "25%",
        textAlign: "center",
        fontSize: 20,
        fontWeight: 500,
        padding: 10,
        color: color.tableText,
        backgroundColor: color.mediumGray2,
    },
    spacerHeader: {
        backgroundColor: color.mediumGray2,
    },
    spacerCell: {
        padding: 8,
        backgroundColor: color.white,
    },
    canEdit: {
        padding: 8,
        backgroundColor: color.white,
        color: color.mediumBlue4,
        textAlign: "center",
        fontSize: 11,
    },
    planCellTop: {
        width: "25%",
        padding: 10,
        borderTop: `1px solid ${color.darkGray2}`,
        borderLeft: `1px solid ${color.darkGray2}`,
        borderRight: `1px solid ${color.darkGray2}`,
        //boxShadow: "3px 0px 3px 0px rgba(0,0,0,0.2), -3px 0px 3px 0px rgba(0,0,0,0.2)",
    },
    planCell: {
        padding: 10,
        borderLeft: `1px solid ${color.darkGray2}`,
        borderRight: `1px solid ${color.darkGray2}`,
    },
    planCellBottom: {
        padding: 10,
        borderLeft: `1px solid ${color.darkGray2}`,
        borderRight: `1px solid ${color.darkGray2}`,
        borderBottom: `1px solid ${color.darkGray2}`,
    },
    noPadding: {
        padding: "0 10px",
    },
    green: {
        color: color.darkRed,
    },
    backButtonContainer: {
        float: "left",
    },
    backButtonImage: {
        display: "block",
    },
    backButton: {
        position: "relative",
        top: 4,
        cursor: "pointer",
        border: "none",
        backgroundColor: "transparent",
        display: "block",
        marginLeft: 12,
        marginRight: 12,
        marginTop: 7,
        outline: "none",
    },
    white: {
        backgroundColor: color.white,
    },
    gray: {
        backgroundColor: color.lightGray,
    },
    lightBlue: {
        backgroundColor: color.lightBlue3,
    },
    mediumBlue: {
        backgroundColor: color.mediumBlue3,
    },
    headerInfo: {
        color: color.tableText,
        backgroundColor: color.lightGray,
        borderBottom: `4px solid ${color.mediumRed}`,
        marginTop: 3,
        paddingTop: 20,
        paddingBottom: 10,
        boxShadow: `inset 0 -3px 3px -3px ${color.blackAlpha20}`,
    },
    infoItems: {
        display: "block",
    },
    infoItem: {
        height: "25px",
        paddingRight: 10,
        fontSize: 16,
        fontWeight: 400,
        whiteSpace: "nowrap",
        backgroundColor: color.lightGray,
    },
    bold: {
        fontWeight: 500,
    },
    infoLinks: {
        display: "block",
        paddingTop: 40,
    },
    infoLink: {
        display: "block",
        whiteSpace: "nowrap",
        fontSize: 14,
    },
    linkTitle: {
        fontWeight: 500,
        padding: 8,
        backgroundColor: color.lightGray,
    },
    link: {
        color: color.mediumYellow1,
        padding: 8,
        backgroundColor: color.lightGray,
    },
    iconLink: {
        backgroundColor: color.lightGray,
    },
    uploadLink: {
        height: 24,
    },
    fineTune: {
        width: "100%",
        fontSize: 20,
        fontWeight: 300,
        color: color.tableText,
        paddingTop: 20,
        paddingLeft: 10,
    },
    planNameText: {
        color: color.darkestGray,
        fontSize: 20,
        fontWeight: 300,
    },
    buttonHolder: {
        width: "100%",
        textAlign: "center",
    },
    submitButton: {
        marginTop: 24,
        marginBottom: 48,
        width: "200px",
    },
});

const strings = {
    title: "Plan Detail",
    fineTune: "Fine tune the way your plan will display in different outputs.",
    canEdit: "(Blue fields can be edited)",
    notAvailable: "Data not available",
    wmDisplayNamePlaceholder: "Whole Market Display Name",
    ssDisplayNamePlaceholder: "Side by Side Display Name",
    wmPlanTypePlaceholder: "Whole Market Product Line",
    ssPlanTypePlaceholder: "Side by Side Market Product Line",
    wmTeleHealthPlaceholder: "Whole Market Telehealth",
    ssTeleHealthPlaceholder: "Side by Side Telehealth",
    wmWellnessCreditPlaceholder: "Whole Market Wellness Credit",
    ssWellnessCreditPlaceholder: "Side by Side Wellness Credit",
};

export default class PlanDetailScreen extends Component {
    static propTypes = {
        history: texchangePropTypes.history.isRequired,
        match: texchangePropTypes.match.isRequired,
    };    

    state = {
        plan: {},
        dirty: true,
        loading: false,
        isSaving: false,
        errors: {},
    };

    componentDidMount() {
        this._mounted = true;
        medicalPlansApi.getById(this.props.match.params.id).then(result => {
            if (result && this._mounted) {
                this.setState({
                    plan: result,
                });
            }
        });
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    _onBackButtonClick = () => {
        this.props.history.push(routeHelpers.plans());
    };

    _wmDisplayNameChanged = e => {
        this.setState({
			plan: {
				...this.state.plan,
				wmDisplayName: e.target.value,
			},
		});
	};

    _wmPlanTypeNameChanged = e => {
        this.setState({
			plan: {
				...this.state.plan,
				wmPlanTypeName: e.value,
			},
		});
	};

    _wmTeleHealthChanged = e => {
        this.setState({
			plan: {
				...this.state.plan,
				wmTeleHealth: e.target.value,
			},
		});
	};

    _wmWellnessCreditChanged = e => {
        this.setState({
			plan: {
				...this.state.plan,
				wmWellnessCredit: e.target.value,
			},
		});
	};

    _ssDisplayNameChanged = e => {
        this.setState({
			plan: {
				...this.state.plan,
				ssDisplayName: e.target.value,
			},
		});
    };
    
    _ssPlanTypeNameChanged = e => {
        this.setState({
			plan: {
				...this.state.plan,
				ssPlanTypeName: e.value,
			},
		});
	};

    _ssTeleHealthChanged = e => {
        this.setState({
			plan: {
				...this.state.plan,
				ssTeleHealth: e.target.value,
			},
		});
	};

    _ssWellnessCreditChanged = e => {
        this.setState({
			plan: {
				...this.state.plan,
				ssWellnessCredit: e.target.value,
			},
		});
    };

    _viewSOB = () => {
        toast.info("View SOB Coming Soon", { toastId: 1 });
    }

    _uploadSOB = () => {
        toast.info("Upload SOB Coming Soon", { toastId: 2 });
    }

    _viewSummary = () => {
        toast.info("View Summary Coming Soon", { toastId: 3 });
    }

    _uploadSummary = () => {
        toast.info("Upload Summary SOB Coming Soon", { toastId: 4 });
    }

    _submit = e => {
        e.preventDefault();
        if (this.state.loading) {
            return;
        }

        this.setState({ isSaving: true });

        const { plan } = this.state;
        medicalPlansApi
            .savePlanCustom(plan)
            .then(() => {
                toast.success("Plan Data Saved", { toastId: 5 });
                setTimeout(() => {
                    this._onBackButtonClick();
                }, 2500);
            })
            .catch(err => {
                logger.warn(err);
                let errors = {};
                if (err && err.response) {
                    const { response } = err;
                    if (
                        response &&
                        response.data &&
                        response.data.errors &&
                        Object.keys(response.data.errors).length > 0
                    ) {
                        // Server provided validation messages
                        errors = response.data.errors;
                    } else {
                        // 500 or something else unexpected
                        const description = describeNetworkError(err);
                        toast.error(description.join("\n"), { toastId: 6 });
                    }
                }

                this.setState({ errors });
            })
            .finally(() => this.setState({ isSaving: false }));
    };

    render() {
        let plan = this.state.plan;

        return (
            <div className={css(styles.content)}>
                <ToastContainer position="top-center" autoClose={2500} />
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.titleSection)}>
                        <div className={css(styles.backButtonContainer)}>
                            <button className={css(styles.backButton)} type="button" onClick={this._onBackButtonClick}>
                                <img src={backCircle} className={css(styles.backButtonImage)} alt="back" />
                            </button>
                        </div>
                        <PageTitle>{strings.title}<span className={css(styles.planNameText)}>&nbsp;/&nbsp;{this.state.plan.displayName}</span></PageTitle>
                    </div>
                    <div className={css(styles.headerInfo)}>
                        <div className={css(styles.row)}>
                            <div className={css(styles.column, styles.column2)}>
                                <span>
                                    {plan.carrierLogo && plan.carrierLogo.length > 0 ? (
                                        <img src={plan.carrierLogo} border={0} width={150} alt={plan.carrierName} />
                                    ) : null }
                                </span>
                            </div>
                            <div className={css(styles.column)}>
                                <span className={css(styles.infoItems)}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className={css(styles.infoItem, styles.bold)}>Carrier:</td>
                                                <td className={css(styles.infoItem)}>{plan.carrierName}</td>
                                            </tr>
                                            <tr>
                                                <td className={css(styles.infoItem, styles.bold)}>Coverage:</td>
                                                <td className={css(styles.infoItem)}>Medical</td>
                                            </tr>
                                            <tr>
                                                <td className={css(styles.infoItem, styles.bold)}>Year:</td>
                                                <td className={css(styles.infoItem)}>{plan.year}</td>
                                            </tr>
                                            <tr>
                                                <td className={css(styles.infoItem, styles.bold)}>Metal Tier:</td>
                                                <td className={css(styles.infoItem)}>{plan.metallicLevel}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </span>
                            </div>
                            <div className={css(styles.column, styles.column3)}>
                                <span>{" "}</span>
                            </div>
                            <div className={css(styles.column, styles.column2)}>
                                <span className={css(styles.infoLinks)}>
                                    <table className={css(styles.infoLink)}>
                                        <tbody>
                                            <tr>
                                                <td className={css(styles.linkTitle)}>Statement of Benefits:</td>
                                                <td className={css(styles.link)} onClick={this._viewSOB}>View</td>
                                                <td className={css(styles.link)} onClick={this._uploadSOB}>Upload</td>
                                                <td className={css(styles.iconLink)} onClick={this._uploadSOB}><img className={css(styles.uploadLink)} src={uploadIcon} alt="upload" /></td>
                                            </tr>
                                            <tr>
                                                <td className={css(styles.linkTitle)}>Plan Summary:</td>
                                                <td className={css(styles.link)} onClick={this._viewSummary}>View</td>
                                                <td className={css(styles.link)} onClick={this._uploadSummary}>Upload</td>
                                                <td className={css(styles.iconLink)} onClick={this._uploadSummary}><img className={css(styles.uploadLink)} src={uploadIcon} alt="upload" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={css(styles.row)}>
                        <div className={css(styles.column)}>
                            <div className={css(styles.fineTune)}>{strings.fineTune}</div>
                        </div>
                    </div>
                    <div className={css(styles.row)}>
                        <div className={css(styles.column)}>
                            <table className={css(styles.planCols)}>
                                <thead>
                                    <tr>
                                        <th className={css(styles.colHeader)}>Benefits</th>
                                        <th className={css(styles.spacerHeader)}>&nbsp;</th>
                                        <th className={css(styles.colHeader)}>Original Plan Data</th>
                                        <th className={css(styles.spacerHeader)}>&nbsp;</th>
                                        <th className={css(styles.colHeader, styles.green)}>Whole Market Output</th>
                                        <th className={css(styles.spacerHeader)}>&nbsp;</th>
                                        <th className={css(styles.colHeader, styles.green)}>Side by Side Output</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.canEdit)}>{strings.canEdit}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.canEdit)}>{strings.canEdit}</td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.planCellTop, styles.lightBlue)}>Plan Name</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCellTop, styles.white)}>{plan.displayName ? plan.displayName : strings.notAvailable}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCellTop, styles.noPadding, styles.white)}>
                                            <TextInput
                                                name="wmDisplayName"
                                                onChange={this._wmDisplayNameChanged}
                                                placeholder={strings.wmDisplayNamePlaceholder}
                                                value={plan.wmDisplayName ? plan.wmDisplayName : ""}
                                                hideLabel={true}
                                                showAsBlue={true}
                                            />
                                        </td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCellTop, styles.noPadding, styles.white)}>
                                            <TextInput
                                                name="ssDisplayName"
                                                onChange={this._ssDisplayNameChanged}
                                                placeholder={strings.ssDisplayNamePlaceholder}
                                                value={plan.ssDisplayName ? plan.ssDisplayName : ""}
                                                hideLabel={true}
                                                showAsBlue={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.planCell, styles.mediumBlue)}>Product Line</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.planTypeName ? plan.planTypeName : strings.notAvailable}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.noPadding, styles.gray)}>
                                            {plan.medicalPlanTypes && plan.medicalPlanTypes.length > 0 ? (
                                                <Dropdown
                                                    name="wmPlanTypeName"
                                                    aStyles={styles.dropdown}
                                                    options={Object.keys(plan.medicalPlanTypes).map(id => ({
                                                        label: plan.medicalPlanTypes[id],
                                                        value: plan.medicalPlanTypes[id],
                                                    }))}
                                                    onChange={this._wmPlanTypeNameChanged}
                                                    placeholder={strings.wmPlanTypePlaceholder}
                                                    value={plan.wmPlanTypeName ? plan.wmPlanTypeName : ""}
                                                    hideLabel={true}
                                                    showAsBlue={true}
                                                />
                                            ) : strings.notAvailable }
                                        </td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.noPadding, styles.gray)}>
                                            {plan.medicalPlanTypes && plan.medicalPlanTypes.length > 0 ? (
                                                <Dropdown
                                                    name="ssPlanTypeName"
                                                    aStyles={styles.dropdown}
                                                    options={Object.keys(plan.medicalPlanTypes).map(id => ({
                                                        label: plan.medicalPlanTypes[id],
                                                        value: plan.medicalPlanTypes[id],
                                                    }))}
                                                    onChange={this._ssPlanTypeNameChanged}
                                                    placeholder={strings.ssPlanTypePlaceholder}
                                                    value={plan.ssPlanTypeName ? plan.ssPlanTypeName : ""}
                                                    hideLabel={true}
                                                    showAsBlue={true}
                                                />
                                            ) : strings.notAvailable }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.planCell, styles.lightBlue)}>Telehealth</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.teleHealth ? plan.teleHealth : strings.notAvailable}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.noPadding, styles.white)}>
                                            <TextInput
                                                name="wmTeleHealth"
                                                onChange={this._wmTeleHealthChanged}
                                                placeholder={strings.wmTeleHealthPlaceholder}
                                                value={plan.wmTeleHealth ? plan.wmTeleHealth : ""}
                                                hideLabel={true}
                                                showAsBlue={true}
                                            />
                                        </td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.noPadding, styles.white)}>
                                            <TextInput
                                                name="ssTeleHealth"
                                                onChange={this._ssTeleHealthChanged}
                                                placeholder={strings.ssTeleHealthPlaceholder}
                                                value={plan.ssTeleHealth ? plan.ssTeleHealth : ""}
                                                hideLabel={true}
                                                showAsBlue={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.planCell, styles.mediumBlue)}>Wellness Credit</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.wellnessCredit ? plan.wellnessCredit : strings.notAvailable}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.noPadding, styles.gray)}>
                                            <TextInput
                                                name="wmWellnessCredit"
                                                onChange={this._wmWellnessCreditChanged}
                                                placeholder={strings.wmWellnessCreditPlaceholder}
                                                value={plan.wmWellnessCredit ? plan.wmWellnessCredit : ""}
                                                hideLabel={true}
                                                showAsBlue={true}
                                            />
                                        </td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.noPadding, styles.gray)}>
                                            <TextInput
                                                name="ssWellnessCredit"
                                                onChange={this._ssWellnessCreditChanged}
                                                placeholder={strings.ssWellnessCreditPlaceholder}
                                                value={plan.ssWellnessCredit ? plan.ssWellnessCredit : ""}
                                                hideLabel={true}
                                                showAsBlue={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.planCell, styles.lightBlue)}>Ind. Deductible (In/Out)</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.individualDeductibleIn}/{plan.individualDeductibleOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.individualDeductibleIn}/{plan.individualDeductibleOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.individualDeductibleIn}/{plan.individualDeductibleOut}</td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.planCell, styles.mediumBlue)}>Fam. Deductible (In/Out)</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.familyDeductibleIn}/{plan.familyDeductibleOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.familyDeductibleIn}/{plan.familyDeductibleOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.familyDeductibleIn}/{plan.familyDeductibleOut}</td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.planCell, styles.lightBlue)}>Coinsurance (In/Out)</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.coInsuranceIn}/{plan.coInsuranceOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.coInsuranceIn}/{plan.coInsuranceOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.coInsuranceIn}/{plan.coInsuranceOut}</td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.planCell, styles.mediumBlue)}>Ind. Out of Pocket Max (In/Out)</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.individualOOPIn}/{plan.individualOOPOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.individualOOPIn}/{plan.individualOOPOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.individualOOPIn}/{plan.individualOOPOut}</td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.planCell, styles.lightBlue)}>Fam. Out of Pocket Max (In/Out)</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.familyOOPIn}/{plan.familyOOPOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.familyOOPIn}/{plan.familyOOPOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.familyOOPIn}/{plan.familyOOPOut}</td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.planCell, styles.mediumBlue)}>Office Visit Copay (In/Out)</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.officeVisitCopayIn}/{plan.officeVisitCopayOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.officeVisitCopayIn}/{plan.officeVisitCopayOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.officeVisitCopayIn}/{plan.officeVisitCopayOut}</td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.planCell, styles.lightBlue)}>Specialist Visit Copay (In/Out)</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.specialistVisitCopayIn}/{plan.specialistVisitCopayOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.specialistVisitCopayIn}/{plan.specialistVisitCopayOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.specialistVisitCopayIn}/{plan.specialistVisitCopayOut}</td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.planCell, styles.mediumBlue)}>Rx Card (In/Out)</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.rxCardIn}/{plan.rxCardOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.rxCardIn}/{plan.rxCardOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.rxCardIn}/{plan.rxCardOut}</td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.planCell, styles.lightBlue)}>Mail Order (In/Out)</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.mailOrderIn}/{plan.mailOrderOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.mailOrderIn}/{plan.mailOrderOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.mailOrderIn}/{plan.mailOrderOut}</td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.planCell, styles.mediumBlue)}>Urgent Care (In/Out)</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.urgentCareIn}/{plan.urgentCareOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.urgentCareIn}/{plan.urgentCareOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.urgentCareIn}/{plan.urgentCareOut}</td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.planCell, styles.lightBlue)}>ER (In/Out)</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.erIn}/{plan.erOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.erIn}/{plan.erOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.erIn}/{plan.erOut}</td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.planCell, styles.mediumBlue)}>Lab/Xray (In/Out)</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.labXRayIn}/{plan.labXRayOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.labXRayIn}/{plan.labXRayOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.labXRayIn}/{plan.labXRayOut}</td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.planCell, styles.lightBlue)}>Advanced Imaging (In/Out)</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.advancedImagingIn}/{plan.advancedImagingOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.advancedImagingIn}/{plan.advancedImagingOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.advancedImagingIn}/{plan.advancedImagingOut}</td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.planCell, styles.mediumBlue)}>Inpatient Hospitalization (In/Out)</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.inpatientHospitalizationIn}/{plan.inpatientHospitalizationOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.inpatientHospitalizationIn}/{plan.inpatientHospitalizationOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.inpatientHospitalizationIn}/{plan.inpatientHospitalizationOut}</td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.planCell, styles.lightBlue)}>Outpatient Surgery (In/Out)</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.outpatientSurgeryIn}/{plan.outpatientSurgeryOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.outpatientSurgeryIn}/{plan.outpatientSurgeryOut}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.white)}>{plan.outpatientSurgeryIn}/{plan.outpatientSurgeryOut}</td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.planCell, styles.mediumBlue)}>Specialty Medication In</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.specialtyMedicationIn}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.specialtyMedicationIn}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCell, styles.gray)}>{plan.specialtyMedicationIn}</td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.planCellBottom, styles.lightBlue)}>Rx Card Description</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCellBottom, styles.white)}>{plan.rxCardDescription}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCellBottom, styles.white)}>{plan.rxCardDescription}</td>
                                        <td className={css(styles.spacerCell)}>&nbsp;</td>
                                        <td className={css(styles.planCellBottom, styles.white)}>{plan.rxCardDescription}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={css(styles.row)}>
                        <div className={css(styles.buttonHolder)}>
                            <ActionButton
                                aStyles={styles.submitButton}
                                label="Save Plan"
                                type="submit"
                                onClick={this._submit}
                                disabled={!this.state.dirty || this.state.loading}
                                isLoading={this.state.isSaving}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
