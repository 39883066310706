import * as actionTypes from "../constants/actionTypes";
import createReducer from "../lib/createReducer";

const defaultState = {
    isEditable: false,
    enrollmentPortal: {},
    employer: {},
    billingContact: {},
    medicalPlans: [],
    quote: {},
    insuranceTypePlanEnrollments: {},
    insuranceTypePlanEligibilities: {},
    insuranceTypePlanDetails: {},
};

function ENROLLMENT_SET(state, action) {
    const {
        enrollmentPortal,
        employer,
        billingContact,
        medicalPlans,
        dentalPlans,
        visionPlans,
        quote,
        insuranceTypePlanEnrollments,
        insuranceTypePlanEligibilities,
        insuranceTypePlanDetails,
    } = action;

    const isEditable =
        (enrollmentPortal !== null || typeof enrollmentPortal !== 'undefined') &&
        (enrollmentPortal.uBenefitSubmitDate === null ||
            typeof enrollmentPortal.uBenefitSubmitDate === 'undefined') &&
        enrollmentPortal.deleted === false;

    const nextState = {
        ...state,
        isEditable,
        enrollmentPortal,
        employer,
        billingContact,
        medicalPlans,
        dentalPlans,
        visionPlans,
        quote,
        insuranceTypePlanEnrollments,
        insuranceTypePlanEligibilities,
        insuranceTypePlanDetails,
    };

    return nextState;
}

function ENROLLMENT_UPDATE_EMPLOYER(state, action) {
    const { employer } = action;

    const nextState = {
        ...state,
        employer,
    };

    return nextState;
}

function ENROLLMENT_UPDATE_ENROLLMENT_PORTAL(state, action) {
    const { enrollmentPortal } = action;

    const nextState = {
        ...state,
        enrollmentPortal,
    };

    return nextState;
}

function ENROLLMENT_INSURANCE_TYPE_PLAN_ENROLLMENT_UPDATE(state, action) {
    const { insuranceTypePlanEnrollment } = action;

    let { insuranceTypePlanEnrollments } = state;
    insuranceTypePlanEnrollments = insuranceTypePlanEnrollments.map(item => item.id === insuranceTypePlanEnrollment.id ? item : insuranceTypePlanEnrollment);

    const nextState = {
        ...state,
        insuranceTypePlanEnrollments,
    };

    return nextState;
}

function ENROLLMENT_INSURANCE_TYPE_PLAN_ELIGIBILITY_UPDATE(state, action) {
    const { insuranceTypePlanEligibility } = action;

    let { insuranceTypePlanEligibilities } = state;
    insuranceTypePlanEligibilities = insuranceTypePlanEligibilities.map(item => item.id === insuranceTypePlanEligibility.id ? item : insuranceTypePlanEligibility);

    const nextState = {
        ...state,
        insuranceTypePlanEligibilities,
    };

    return nextState;
}

function ENROLLMENT_INSURANCE_TYPE_PLAN_DETAIL_UPDATE(state, action) {
    const { insuranceTypePlanDetail } = action;

    let { insuranceTypePlanDetails } = state;
    insuranceTypePlanDetails = insuranceTypePlanDetails.map(item => item.id === insuranceTypePlanDetail.id ? item : insuranceTypePlanDetail);

    const nextState = {
        ...state,
        insuranceTypePlanDetails,
    };

    return nextState;
}

function ENROLLMENT_UPDATE_BILLING_CONTACT(state, action) {
    const { billingContact } = action;

    const nextState = {
        ...state,
        billingContact,
    };

    return nextState;
}

function ENROLLMENT_UPDATE_INSURANCE_TYPE_PLAN_ENROLLMENTS(state, action) {
    const { insuranceTypePlanEnrollments } = action;

    const nextState = {
        ...state,
        insuranceTypePlanEnrollments,
    };

    return nextState;
}

function ENROLLMENT_UPDATE_INSURANCE_TYPE_PLAN_ELIGIBILITIES(state, action) {
    const { insuranceTypePlanEligibilities } = action;

    const nextState = {
        ...state,
        insuranceTypePlanEligibilities,
    };

    return nextState;
}

function ENROLLMENT_UPDATE_INSURANCE_TYPE_PLAN_DETAILS(state, action) {
    const { insuranceTypePlanDetails } = action;

    const nextState = {
        ...state,
        insuranceTypePlanDetails,
    };

    return nextState;
}

export default createReducer(defaultState, {
    [actionTypes.ENROLLMENT_SET]: ENROLLMENT_SET,
    [actionTypes.ENROLLMENT_UPDATE_EMPLOYER]: ENROLLMENT_UPDATE_EMPLOYER,
    [actionTypes.ENROLLMENT_UPDATE_ENROLLMENT_PORTAL]: ENROLLMENT_UPDATE_ENROLLMENT_PORTAL,
    [actionTypes.ENROLLMENT_INSURANCE_TYPE_PLAN_ENROLLMENT_UPDATE]: ENROLLMENT_INSURANCE_TYPE_PLAN_ENROLLMENT_UPDATE,
    [actionTypes.ENROLLMENT_INSURANCE_TYPE_PLAN_ELIGIBILITY_UPDATE]: ENROLLMENT_INSURANCE_TYPE_PLAN_ELIGIBILITY_UPDATE,
    [actionTypes.ENROLLMENT_INSURANCE_TYPE_PLAN_DETAIL_UPDATE]: ENROLLMENT_INSURANCE_TYPE_PLAN_DETAIL_UPDATE,
    [actionTypes.ENROLLMENT_UPDATE_BILLING_CONTACT]: ENROLLMENT_UPDATE_BILLING_CONTACT,
    [actionTypes.ENROLLMENT_UPDATE_INSURANCE_TYPE_PLAN_ENROLLMENTS]: ENROLLMENT_UPDATE_INSURANCE_TYPE_PLAN_ENROLLMENTS,
    [actionTypes.ENROLLMENT_UPDATE_INSURANCE_TYPE_PLAN_ELIGIBILITIES]: ENROLLMENT_UPDATE_INSURANCE_TYPE_PLAN_ELIGIBILITIES,
    [actionTypes.ENROLLMENT_UPDATE_INSURANCE_TYPE_PLAN_DETAILS]: ENROLLMENT_UPDATE_INSURANCE_TYPE_PLAN_DETAILS,

});
