import * as breakpoints from "../../constants/breakpoints";
import * as color from "../../constants/color";
import * as texchangePropTypes from "../../texchangePropTypes";
import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    h1: {
        color: color.darkestGray,
        fontSize: 26,
        fontWeight: 600,        
        lineHeight: "32px",
        margin: 0,
        paddingBottom: 4,
        borderBottom: `2px solid ${color.mediumRed}`,

        [breakpoints.tabletAndDown]: {
            fontSize: 18,
            lineHeight: "24px",
        },
    },
});

export default function Headline(props) {
    const { aStyles, children, ...rest } = props;
    return (
        <h1 {...rest} className={css(styles.h1, aStyles)}>
            {children}
        </h1>
    );
}

Headline.propTypes = {
    aStyles: texchangePropTypes.aphroditeStyles,
    children: PropTypes.node,
};
