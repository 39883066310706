import instance from "./instance";

const responseData = response => response.data;

export function getAll() {
    return instance
        .request({
            url: "/visionPlan/get-vision-plans",
            method: "GET",
        })
        .then(responseData);
}

export function getPaged(page, sortColumn, sortDirection, quoteId, current, carrierIds, networks, planTypes, tierIds, deductibleRange, searchTerm, areaId, fundingTypes, examCopays, frameAllowances, frameFrequencies, contactLensAllowances, materialCopays, all = false) {
    return instance
        .request({
            url: "/visionPlan/get-vision-plans-page",
            method: "POST",
            data: {
                page,
                sortColumn,
                sortDirection,
                quoteId,
                current,
                carrierIds,
                networks,
                searchTerm,
                fundingTypes,
                examCopays,
                frameAllowances,
                frameFrequencies,
                contactLensAllowances,
                materialCopays,
                all,
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function getPagedForQuote(page, sortColumn, sortDirection, quoteId, carrierIds, networks, planTypes, tierIds, deductibleRange, coinsuranceRange, oopRange, employeePremiumRange, monthlyPremiumRange, searchTerm, packages, fundingTypes, examCopays, materialCopays, frameAllowances, contactLensAllowances, frameFrequencies, retinalImaging, childPolycarbonate, medInfertility, medHSA, all = false) {
    return instance
        .request({
            url: "/visionPlan/get-quote-vision-plans-page",
            method: "POST",
            data: {
                page,
                sortColumn,
                sortDirection,
                quoteId,
                carrierIds,
                networks,
                employeePremiumRange,
                monthlyPremiumRange,
                searchTerm,
                fundingTypes,
                examCopays,
                materialCopays,
                frameAllowances,
                contactLensAllowances,
                frameFrequencies,
                retinalImaging,
                childPolycarbonate,
                all,
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function getVisionPlansDisplay(areaId, carrierId, year, planType) {
    return instance
        .request({
            url: "/visionPlan/get-vision-plans-display",
            method: "POST",
            data: {
                areaId,
                carrierId,
                year,
                planType,
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function getById(id) {
    return instance
        .request({
            url: `/visionPlan/get-vision-plan/${id}`,
            method: "GET",
        })
        .then(responseData);
}