import * as account from "../../lib/api/account";
import * as breakpoints from "../../constants/breakpoints";
import * as texchangePropTypes from "../../texchangePropTypes";
import * as routeHelpers from "../../lib/routeHelpers";
import ActionButton from "../ActionButton";
import describeNetworkError from "../../lib/describeNetworkError";
import Headline from "../text/Headline";
import logger from "../../lib/logger";
import qs from "query-string";
import React, { Component } from "react";
import TextInput from "../inputs/TextInput";
import { StyleSheet, css } from "aphrodite";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = StyleSheet.create({
    loginForm: {
        maxWidth: "500px",
    },
    title: {
        [breakpoints.tabletAndDown]: {
            textAlign: "center",
            marginTop: 14,
            marginBottom: 16,
        },
        [breakpoints.desktopOnly]: {
            marginTop: 128,
            marginBottom: 24,
        },
    },
    buttonContainer: {
        [breakpoints.desktopOnly]: {
            textAlign: "right",
        },
    },
    actionButton: {
        marginTop: 24,

        [breakpoints.tabletAndDown]: {
            width: "100%",
        },
        [breakpoints.desktopOnly]: {
            minWidth: 184,
        },
    },
});

const strings = {
    pageTitle: "Create A New Password",
    passwordPlaceholder: "Password",
    passwordConfirmPlaceholder: "Re-enter Password",
    submitButton: "Submit",
    passwordMustMatch: "Passwords must match",
    passwordLength: "Password must be at least 8 characters",
    passwordDigit: "Password must include a number (0-9)",
    passwordUpper: "Password must include an upper case",
    passwordLower: "Password must include a lower case",
    passwordNonAlpha: "Password must include a non alphanumeric",
    success: "Thank You. Your password has been successfully reset.",
};

const inputNames = {
    password: "password",
    passwordConfirm: "passwordConfirm",
};

export default class ResetPasswordForm extends Component {
    static propTypes = {
        history: texchangePropTypes.history.isRequired,
    };

    state = {
        errors: {},
        loading: false,

        inputs: {
            [inputNames.password]: "",
            [inputNames.passwordConfirm]: "",
        },
    };

    _handleTextChange = e => {        
        const { name, value } = e.target;
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
            };
        });
    };

    _submit = e => {
        e.preventDefault();
        if (this.state.loading) {
            return;
        }

        const password = this.state.inputs[inputNames.password];
        const passwordConfirm = this.state.inputs[inputNames.passwordConfirm];

        if (password !== passwordConfirm) {
            this.setState({
                errors: {
                    [inputNames.password]: [strings.passwordMustMatch],
                },
            });

            return;
        }
        //check for length, format
        /*
        options.Password.RequiredLength = 8;
        options.Password.RequireLowercase = true;
        options.Password.RequireUppercase = true;
        options.Password.RequireDigit = true;
        options.Password.RequireNonAlphanumeric = true;
        */

        //length
        if (password.length < 8) {
            this.setState({
                errors: {
                    [inputNames.password]: [strings.passwordLength],
                },
            });

            return;
        }
        //lower
        let regEx = new RegExp("^(.*[a-z].*)$","g");
        if (!regEx.test(password)) {
            this.setState({
                errors: {
                    [inputNames.password]: [strings.passwordLower],
                },
            });

            return;
        }
        //upper
        regEx = new RegExp("^(.*[A-Z].*)$","g");
        if (!regEx.test(password)) {
            this.setState({
                errors: {
                    [inputNames.password]: [strings.passwordUpper],
                },
            });

            return;
        }
        //digit
        regEx = new RegExp("^(.*[0-9].*)$","g");
        if (!regEx.test(password)) {
            this.setState({
                errors: {
                    [inputNames.password]: [strings.passwordDigit],
                },
            });

            return;
        }
        //non alphanumeric
        regEx = new RegExp("^(.*[\\W].*)$","g");
        if (!regEx.test(password)) {
            this.setState({
                errors: {
                    [inputNames.password]: [strings.passwordNonAlpha],
                },
            });

            return;
        }

        //passed
        this.setState({
            loading: true,
            errors: {},
        });

        const params = qs.parse(this.props.history.location.search);

        const code = params.code;
        const id = params.id;

        account
            .resetPassword(id, password, code)
            .then(response => {
                if (response.succeeded) {
                    toast.success(strings.success, { toastId: 1 });
                    setTimeout(() => {
                        this.props.history.push(routeHelpers.login());
                    }, 2500);
                }
                else {
                    let allErrors = "";
                    response.errors.map((err) => {
                        allErrors = allErrors + err.description + "\n";
                    });
                    toast.error(allErrors, { toastId: 2 });
                    this.setState({
                        loading: false,
                        errors: response.errors,
                    });
                }
            })
            .catch(err => {
                logger.warn(err);
                if (!this._mounted) {
                    return;
                }

                let errors = {};
                if (err && err.response) {
                    const { response } = err;
                    if (
                        response &&
                        response.data &&
                        response.data.errors &&
                        Object.keys(response.data.errors).length > 0
                    ) {
                        // Server provided validation messages
                        errors = response.data.errors;
                    } else {
                        // 500 or something else unexpected
                        const description = describeNetworkError(err);
                        toast.error(description.join("\n"), { toastId: 3 });
                    }
                }

                this.setState({
                    loading: false,
                    errors: errors,
                });
            });
    };

    render() {
        return (
            <form onSubmit={this._submit} className={css(styles.loginForm)}>
                <ToastContainer position="top-center" autoClose={2500} />
                <Headline aStyles={styles.title}>{strings.pageTitle}</Headline>
                <TextInput
                    name={inputNames.password}
                    onChange={this._handleTextChange}
                    placeholder={strings.passwordPlaceholder}
                    autoComplete="new-password"
                    required={true}
                    type="password"
                    validationMessage={this.state.errors[inputNames.password]}
                    value={this.state.inputs[inputNames.password]}
                />

                <TextInput
                    name={inputNames.passwordConfirm}
                    onChange={this._handleTextChange}
                    placeholder={strings.passwordConfirmPlaceholder}
                    autoComplete="new-password"
                    required={true}
                    type="password"
                    validationMessage={this.state.errors[inputNames.passwordConfirm]}
                    value={this.state.inputs[inputNames.passwordConfirm]}
                />

                <div className={css(styles.buttonContainer)}>
                    <ActionButton
                        aStyles={styles.actionButton}
                        disabled={this.state.loading}
                        label={strings.submitButton}
                        type="submit"
                    />
                </div>
            </form>
        );
    }
}
