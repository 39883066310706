import * as texchangePropTypes from "../texchangePropTypes";
import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, css } from "aphrodite";
import blueRecycleIcon from "../images/blue-recycle.png";
import * as color from "../constants/color";

const styles = StyleSheet.create({
    button: {
        cursor: "pointer",
        outline: "none",
        lineHeight: "12px",
        marginLeft: 8,
        color: color.darkestGray,
        background: color.white,
        padding: "2px 3px 2px 6px",
        border: `1px solid ${color.darkGray7}`,
        borderRadius: 4,
    },
    labelText: {        
        fontSize: 14,     
        float: "left",
        lineHeight: "22px",        
    },
    blueCircle: {        
        margin: "1px 2px 0px 10px",
        float: "right",
    },
});

export default function ReQuoteButton(props) {
    const { aStyles, label, ...rest } = props;
    return (        
        <button type="button" {...rest} className={css(styles.button, aStyles)}>
            <div className={css(styles.labelText)}>{label}</div>
            <img alt="" src={blueRecycleIcon} className={css(styles.blueCircle)} />
        </button>
    );
}

ReQuoteButton.propTypes = {
    aStyles: texchangePropTypes.aphroditeStyles,    
    label: PropTypes.string.isRequired,    
};
