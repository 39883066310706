import * as actionTypes from "../constants/actionTypes";

export const setEnrollment = data => {
    const {
        enrollmentPortal,
        employer,
        billingContact,
        medicalPlans,
        dentalPlans,
        visionPlans,
        quote,
        insuranceTypePlanEnrollments,
        insuranceTypePlanEligibilities,
        insuranceTypePlanDetails,
    } = data;

    return {
        type: actionTypes.ENROLLMENT_SET,
        enrollmentPortal,
        employer,
        billingContact,
        medicalPlans,
        dentalPlans,
        visionPlans,
        quote,
        insuranceTypePlanEnrollments,
        insuranceTypePlanEligibilities,
        insuranceTypePlanDetails,
    };
};

export const updateEnrollmentPortal = enrollmentPortal => {
    return {
        type: actionTypes.ENROLLMENT_UPDATE_ENROLLMENT_PORTAL,
        enrollmentPortal,
    };
};

export const updateEmployer = employer => {
    return {
        type: actionTypes.ENROLLMENT_UPDATE_EMPLOYER,
        employer,
    };
};

export const updateBillingContact = billingContact => {
    return {
        type: actionTypes.ENROLLMENT_UPDATE_BILLING_CONTACT,
        billingContact,
    };
};

export const updateInsuranceTypePlanEnrollment = insuranceTypePlanEnrollment => {
    return {
        type: actionTypes.ENROLLMENT_INSURANCE_TYPE_PLAN_ENROLLMENT_UPDATE,
        insuranceTypePlanEnrollment,
    };
};

export const updateInsuranceTypePlanEligibility = insuranceTypePlanEligibility => {
    return {
        type: actionTypes.ENROLLMENT_INSURANCE_TYPE_PLAN_ELIGIBILITY_UPDATE,
        insuranceTypePlanEligibility,
    };
};

export const updateInsuranceTypePlanDetail = insuranceTypePlanDetail => {
    return {
        type: actionTypes.ENROLLMENT_INSURANCE_TYPE_PLAN_DETAIL_UPDATE,
        insuranceTypePlanDetail,
    };
};

export const updateInsuranceTypePlanEnrollments = insuranceTypePlanEnrollments => {
    return {
        type: actionTypes.ENROLLMENT_UPDATE_INSURANCE_TYPE_PLAN_ENROLLMENTS,
        insuranceTypePlanEnrollments,
    };
};

export const updateInsuranceTypePlanEligibilities = insuranceTypePlanEligibilities => {
    return {
        type: actionTypes.ENROLLMENT_UPDATE_INSURANCE_TYPE_PLAN_ELIGIBILITIES,
        insuranceTypePlanEligibilities,
    };
};

export const updateInsuranceTypePlanDetails = insuranceTypePlanDetails => {
    return {
        type: actionTypes.ENROLLMENT_UPDATE_INSURANCE_TYPE_PLAN_DETAILS,
        insuranceTypePlanDetails,
    };
};