import * as color from "../../constants/color";
import * as texchangePropTypes from "../../texchangePropTypes";
import PageTitle from "../text/PageTitle";
import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import * as officesApi from "../../lib/api/offices";
import surfersPng from "../../images/supportImage.jpg";
import { ReactComponent as SupportIcon } from "../../images/nav-support.svg";
import { ReactComponent as ChevronDown } from "../../images/SVGs/chevron-down.svg";
import Modal from "../Modal";
import ActionButton from "../ActionButton";

const styles = StyleSheet.create({
    content: {
        paddingRight: 10,
        paddingBottom: 80,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
    },
    contentSection: {
        width: "100%",
        padding: 0,
        backgroundColor: color.white,
        borderTop: `8px solid ${color.mediumRed}`,
        marginBottom: 20,
    },
    titleSection: {
        heigth: 64,
        backgroundColor: color.white,
    },
    photoHolder: {
        positon: "relative",
        height: 350,
        overflow: "hidden",
    },
    photo: {
        width: "100%",
    },
    photoCallout: {
        position: "absolute",
        top: 340,
        left: 30,
        padding: 20,
        fontSize: 22,
        backgroundColor: color.borderBackgroundGray,
        opacity: 0.8,
        color: color.white,
    },
    table: {
        width: "100%",
        border: `1px solid ${color.darkGray}`,
        borderRadius: 4,
        borderCollapse: "separate",
        borderSpacing: 4,
        backgroundColor: color.lightGray4,
        fontSize: 18,
        textAlign: "left",
    },
    tableCell: {
        color: color.tableText,
        padding: "4px 8px",
        backgroundColor: color.lightGray5,
    },
    tableCellIcon: {
        backgroundColor: color.white,
        padding: 35,
    },
    tableCellLink: {
        color: color.darkRed,
        padding: "8px",
        backgroundColor: color.white,
    },
    chevron: {
        pointerEvents: "none",
        transform: "rotate(-90deg)",
        display: "in-line",
        width: 15,
        height: 15,
    },
    padTop: {
        paddingTop: 15,
    },
    padBottom: {
        paddingBottom: 15,
    },
    noWrap: {
        whiteSpace: "nowrap",
    },
    info: {
        display: "flex",
        alignItems: "top center",
        justifyContent: "center",
    },
    infoBox: {
        maxWidth: "650px",
        margin: 80,
    },
    icon: {
        fill: color.mediumRed,
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        position: "relative",
        width: 65,
        height: 54,
    },
    infoText: {
        padding: 0,
    },
    bold: {
        fontWeight: "bold",
    },
    modalContent: {
        width: 902,
        padding: 24,
    },
    modalButtonContainer: {
        marginTop: 24,
        display: "flex",
        justifyContent: "space-between",
    },
    modalButton: {
        width: 150,
    },
});

const strings = {
    title: "Support",
};

export default class SupportScreen extends Component {
    static propTypes = {
        history: texchangePropTypes.history.isRequired,
    };

    state = {
        loading: false,
        contact: "",
        phone: "",
        email: "",
        explanation: "",
        showModal: false,
        videoName: "",
        videoId: ""
    };

    componentDidMount() {
        this._mounted = true;
        this.setState({ loading: true });
        officesApi.getSupportInfo().then(result => {
            this.setState({ loading: false });
            if (result && this._mounted) {
                this.setState({
                    contact: result.supportContact,
                    phone: result.supportPhone,
                    email: result.supportEmail,
                    explanation: result.supportExplanation
                });
            }
        });
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    render() {
        const { contact, phone, email, explanation, loading, showModal, videoName, videoId } = this.state;

        const videoTitle = "Rating Hub - " + videoName
        const videoUrl = "https://www.youtube.com/embed/" + videoId + "?autoplay=1";

        return (
            <div className={css(styles.content)}>
                <Modal show={showModal && !loading} header={videoTitle}>
                    <div className={css(styles.modalContent)}>
                        <iframe width="854" height="480" src={videoUrl} frameborder="0" allow="autoplay; encrypted-media; fullscreen;" allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen"></iframe>
                    </div>
                    <div className={css(styles.modalButtonContainer)}>
                        <ActionButton
                            aStyles={styles.modalButton}
                            label="Close"
                            // invertColor={true}
                            type="button"
                            onClick={() => this.setState({ showModal: false })}
                        />
                    </div>
                </Modal>
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.titleSection)}>
                        <PageTitle>{strings.title}</PageTitle>
                    </div>
                    <div className={css(styles.photoHolder)}>
                        <div className={css(styles.photoCallout)}>Coming soon: FAQs, and more!</div>
                        <img src={surfersPng} alt="Support" className={css(styles.photo)} />
                    </div>
                    <div className={css(styles.info)}>
                        <div className={css(styles.infoBox)}>
                            <div className={css(styles.infoText)}>
                                <table className={css(styles.table)}>
                                    {(contact || phone || email) ? (
                                        <tbody>
                                            <tr>
                                                <td className={css(styles.tableCellIcon)} rowSpan="5"><SupportIcon className={css(styles.icon)} /></td>
                                            </tr>
                                            {contact ? (
                                                <tr>
                                                    <td className={css(styles.tableCell, styles.padTop, styles.noWrap)}>Support contact:</td>
                                                    <td className={css(styles.tableCell, styles.padTop, styles.bold)}>{contact}</td>
                                                </tr>
                                            ) : null}
                                            {phone ? (
                                                <tr>
                                                    <td className={css(styles.tableCell, contact ? "" : styles.padTop, styles.noWrap)}>Support phone:</td>
                                                    <td className={css(styles.tableCell, contact ? "" : styles.padTop, styles.bold)}>{phone}</td>
                                                </tr>
                                            ) : null}
                                            {email ? (
                                                <tr>
                                                    <td className={css(styles.tableCell, explanation ? "" : styles.padBottom, styles.noWrap)}>Support email:</td>
                                                    <td className={css(styles.tableCell, explanation ? "" : styles.padBottom, styles.bold)}>{email}</td>
                                                </tr>
                                            ) : null}
                                            {explanation ? (
                                                <tr>
                                                    <td className={css(styles.tableCell, styles.padBottom)}>&nbsp;</td>
                                                    <td className={css(styles.tableCell, styles.padBottom, styles.bold)}>{explanation}</td>
                                                </tr>
                                            ) : null}
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td className={css(styles.tableCellIcon)} rowSpan="2"><SupportIcon className={css(styles.icon)} /></td>
                                            </tr>
                                            <tr>
                                                <td className={css(styles.tableCell, styles.padTop, styles.padBottom)}>&nbsp;</td>
                                                <td className={css(styles.tableCell, styles.padTop, styles.padBottom)}><div dangerouslySetInnerHTML={{ __html: explanation }} /></td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </div>
                        {/*<div className={css(styles.infoBox)}>
                            <div className={css(styles.infoText)}>
                                <table className={css(styles.table)}>
                                    <tbody>
                                        <tr>
                                            <td className={css(styles.tableCell, styles.padTop, styles.noWrap)}>Support videos:</td>
                                        </tr>
                                        <tr>
                                            <td className={css(styles.tableCellLink, styles.bold)} onClick={() => this.setState({ showModal: true, videoName: "Introduction", videoId: "SUkGkxeP8ok" })}><ChevronDown className={css(styles.chevron)} /> A Quick Introduction</td>
                                        </tr>
                                        <tr>
                                            <td className={css(styles.tableCellLink, styles.bold)} onClick={() => this.setState({ showModal: true, videoName: "A Quick Cost Comparison", videoId: "cfAi0EZKPac" })}><ChevronDown className={css(styles.chevron)} /> A Quick Cost Comparison Quote</td>
                                        </tr>
                                        <tr>
                                            <td className={css(styles.tableCellLink, styles.bold)} onClick={() => this.setState({ showModal: true, videoName: "Favorites", videoId: "-pesDRbwHrs" })}><ChevronDown className={css(styles.chevron)} /> How to Use Favorites</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>
        );
    }
}
