import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import Tabs from "../Tabs";
import Plans from "./Plans";
import Dropdown from "../inputs/Dropdown";
import LinesOfCoverage from "./LinesOfCoverage";
import coverageTypes from "../../constants/coverageTypes";
import Modal from "../Modal";
import ActionButton from "../ActionButton";
import * as planTypes from "../../constants/planTypes";
import * as color from "../../constants/color";
import * as texchangePropTypes from "../../texchangePropTypes";
import * as proposalActions from "../../actions/proposalActions";
import * as favoriteApi from "../../lib/api/favorite";
import SelectedPlans from "./SelectedPlans";

const styles = StyleSheet.create({
    comingSoon: {
        backgroundColor: color.white,
        color: color.darkGray,
        padding: 20,
        marginBottom: 40,
    },
    left: {
        clear: "both",
        float: "left",
        position: "relative",
        top: 8,
        left: 8,
        marginTop: 10,
        fontSize: 18,
        color: color.darkRed,
    },
    right: {
        float: "right",
        position: "relative",
        top: 8,
        right: 20,
        marginTop: 10,
        fontSize: 14,
        color: color.darkestGray,
    },
    contain: {
        width: "100%",
        overflowX: "scroll",
    },
    favoriteListContainer: {
        position: "relative",
        left: "calc(100% - 350px)",
    },
    favoriteList: {
        position: "absolute",
        zIndex: 1000,
        width: 300,
    },
    modalContent: {
        width: 420,
        overflow: "auto",
        fontSize: 13,
        padding: "0px !important",
    },
    modalButtonContainer: {
        marginTop: 8,
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    modalButton: {
        width: 150,
    },
    cancelButtonContainer: {
        marginTop: 24,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    buttonContainer: {
        width: 164,
    },
});

export class NewPlans extends Component {
    static propTypes = {
        quote: texchangePropTypes.quote.isRequired,
        isVisible: PropTypes.bool.isRequired,
        selectedPlans: PropTypes.object.isRequired,
        onContinue: PropTypes.func,
        saveSinglePlanSpreadsheet: PropTypes.func,
        favoriteLists: PropTypes.array.isRequired,
        setFavoritePlanList: PropTypes.func.isRequired,
        setFavoritesApplied: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedCoverage: ["Medical"],
            selectionComplete: false,
            showAddReplacePlansModal: false,
            selectedFavoriteListId: "",
            currentTab: "",
        };
    }

    _closeModal = () => {
        this.setState({
            showAddReplacePlansModal: false,
            selectedFavoriteListId: "",
        });
    };

    componentDidMount() {
    }

    _handleCoverageSelected = selectedCoverage => {
        this.setState({
            selectedCoverage,
            selectionComplete: true,
        });
    };

    _handleFavoriteChanged = selectedItem => {
        const { value } = selectedItem;
        this.setState({
            selectedFavoriteListId: value,
            showAddReplacePlansModal: true,
        });
    };

    _addFavoritePlans = mode => {
        favoriteApi.getFavoritePlansByListId(this.state.selectedFavoriteListId)
            .then(response => {
                this.setState({
                    showAddReplacePlansModal: false,
                });
                this.props.setFavoritesApplied(false);
                // this.props.subStepChanged(3);
                this.props.setFavoritePlanList(response, mode);
            });
    };

    _handleTabChanged = currentTab => {
        this.setState({ currentTab });
    };

    render() {
        const { quote, isVisible, selectedPlans, favoriteLists } = this.props;
        const { selectedCoverage, showAddReplacePlansModal, selectedFavoriteListId, currentTab } = this.state;
        const showLinesOfCoverage = selectedCoverage.length === 0;

        const curMedCount = quote.currentMedicalPlans && quote.currentMedicalPlans.length > 0 ? quote.currentMedicalPlans.length : 0;
        const renMedCount = quote.renewalMedicalPlans && quote.renewalMedicalPlans.length > 0 ? quote.renewalMedicalPlans.length : 0;
        const newMedCount = selectedPlans.medical && selectedPlans.medical.length > 0 ? selectedPlans.medical.length : 0;
        const curDenCount = quote.currentDentalPlans && quote.currentDentalPlans.length > 0 ? quote.currentDentalPlans.length : 0;
        const renDenCount = quote.renewalDentalPlans && quote.renewalDentalPlans.length > 0 ? quote.renewalDentalPlans.length : 0;
        const newDenCount = selectedPlans.dental && selectedPlans.dental.length > 0 ? selectedPlans.dental.length : 0;
        const curVisCount = quote.currentVisionPlans && quote.currentVisionPlans.length > 0 ? quote.currentVisionPlans.length : 0;
        const renVisCount = quote.renewalVisionPlans && quote.renewalVisionPlans.length > 0 ? quote.renewalVisionPlans.length : 0;
        const newVisCount = selectedPlans.vision && selectedPlans.vision.length > 0 ? selectedPlans.vision.length : 0;
        const curLifCount = quote.currentLifePlans && quote.currentLifePlans.length > 0 ? quote.currentLifePlans.length : 0;
        const renLifCount = quote.renewalLifePlans && quote.renewalLifePlans.length > 0 ? quote.renewalLifePlans.length : 0;
        const newLifCount = selectedPlans.life && selectedPlans.life.length > 0 ? selectedPlans.life.length : 0;
        //count them all up
        const allCount = curMedCount + renMedCount + newMedCount +
            curDenCount + renDenCount + newDenCount +
            curVisCount + renVisCount + newVisCount +
            curLifCount + renLifCount + newLifCount;

        //census election counts
        const census = quote.census;
        const medCensusCount = census ? census.filter(x => x.relationship === "EE" && x.medicalCoverageId < 5).length : 0;
        const denCensusCount = census ? census.filter(x => x.relationship === "EE" && x.dentalCoverageId < 5).length : 0;
        const visCensusCount = census ? census.filter(x => x.relationship === "EE" && x.visionCoverageId < 5).length : 0;
        const lifCensusCount = census ? census.filter(x => x.relationship === "EE" && x.lifeCoverageId < 5).length : 0;

        return (
            <React.Fragment>
                {showLinesOfCoverage && isVisible &&
                    <div className={css(styles.coverageContainer)}>
                        <LinesOfCoverage
                            selectedCoverage={selectedCoverage}
                            coverageSelected={this._handleCoverageSelected}
                        />
                    </div>
                }
                {favoriteLists && favoriteLists.length > 0 && isVisible && currentTab !== 'Selected' && currentTab !== 'Multi-plan Options' &&
                    <div className={css(styles.favoriteListContainer)}>
                        <div className={css(styles.favoriteList)}>
                            <Dropdown
                                name="favoriteList"
                                options={favoriteLists.map(item => ({
                                    label: item.firstInitial + item.lastInitial + " - " + item.name,
                                    value: item.id.toString(),
                                }))}
                                onChange={this._handleFavoriteChanged}
                                placeholder="Add Available Favorites"
                                size="small"
                                hideLabel={true}
                                value={selectedFavoriteListId}
                            />
                        </div>
                    </div>
                }
                <Modal
                    show={showAddReplacePlansModal}
                    header="Add/Replace Plans"
                >
                    <div className={css(styles.modalContent)}>
                        <div className={css(styles.modalButtonContainer)}>
                            <div className={css(styles.buttonContainer)}>
                                <ActionButton
                                    aStyles={styles.modalButton}
                                    label="Add"
                                    invertColor={true}
                                    type="button"
                                    onClick={() => this._addFavoritePlans("add")}
                                />
                                <div className={css(styles.instructions)}>
                                    Add your favorite plans to any currently selected plans.
                                </div>
                            </div>
                            <div className={css(styles.buttonContainer)}>
                                <ActionButton
                                    aStyles={styles.modalButton}
                                    label="Replace"
                                    type="button"
                                    onClick={() => this._addFavoritePlans("replace")}
                                    isLoading={this.state.isSaving}
                                />
                                <div className={css(styles.instructions)}>
                                    Replace any currently selected plans with your favorite plans.
                                </div>
                            </div>
                        </div>
                        <div className={css(styles.cancelButtonContainer)}>
                            <ActionButton
                                aStyles={styles.modalButton}
                                label="Cancel"
                                invertColor={true}
                                type="button"
                                onClick={this._closeModal}
                                isLoading={this.state.isSaving}
                            />
                        </div>
                    </div>
                </Modal>
                <Tabs isVisible={isVisible && !showLinesOfCoverage} tabChanged={this._handleTabChanged}>
                    {medCensusCount > 0 &&
                        <div label="Medical" count={newMedCount}>
                            <Plans
                                quote={quote}
                                planType={planTypes.newPlan}
                                coverageType={coverageTypes.medical}
                                onContinue={this.props.onContinue}
                                saveSinglePlanSpreadsheet={this.props.saveSinglePlanSpreadsheet}
                            />
                        </div>
                    }
                    {denCensusCount > 0 &&
                        <div label="Dental" count={newDenCount}>
                            <Plans
                                quote={quote}
                                planType={planTypes.newPlan}
                                coverageType={coverageTypes.dental}
                                onContinue={this.props.onContinue}
                            />
                        </div>
                    }
                    {visCensusCount > 0 &&
                        <div label="Vision" count={newVisCount}>
                            <Plans
                                quote={quote}
                                planType={planTypes.newPlan}
                                coverageType={coverageTypes.vision}
                                onContinue={this.props.onContinue}
                            />
                        </div>
                    }
                    {lifCensusCount > 0 &&
                        <div label="Life" count={newLifCount}>
                            <Plans
                                quote={quote}
                                planType={planTypes.newPlan}
                                coverageType={coverageTypes.life}
                                onContinue={this.props.onContinue}
                            />
                        </div>
                    }
                    <div label="Selected" count={allCount}>
                        <SelectedPlans
                            quote={quote}
                        />
                    </div>
                </Tabs>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = state => ({
    favoriteLists: state.proposal.favoriteLists,
    selectedPlans: state.proposal.selectedPlans,
});

export default connect(
    mapDispatchToProps, {
    setFavoritePlanList: proposalActions.setFavoritePlanList,
    setFavoritesApplied: proposalActions.setFavoritesApplied,
},
)(NewPlans);