import { StyleSheet, css } from "aphrodite";
import * as color from "../../../constants/color";
import PropTypes from "prop-types";
import React, { Component } from "react";

const styles = StyleSheet.create({
    optionContainer: {
        cursor: "pointer",
        display: "block",
        backgroundColor: color.white,
        textAlign: "left",
        padding: "2px 16px",
        outline: 0,
        border: "none",
        borderBottom: `1px solid ${color.lightGray2}`,
        minHeight: 34,
        color: color.darkestGray,
        ":hover": {
            backgroundColor: color.mediumRed,
            color: color.white,
        },
    },
    optionText: {
        lineHeight: "34px",
        fontSize: 14,
        fontWeight: 400,
        whiteSpace: "noWrap",
        maxWidth: 525,
    },
    active: {
        backgroundColor: color.lightGray4,
    },
});
export default class SmallDropdownRow extends Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        option: PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,

        }).isRequired,
    };

    state = {
        isOpen: false,
        optionWidth: null,
    };

    componentDidMount() {

    }

    _onChange = option => {
        this.props.onChange(option, this.props.index);
    };

    render() {
        const { option, index, value } = this.props;

        return (
            <div
                key={index}
                className={css(
                    styles.optionContainer,
                    option.value === value ? styles.active : null
                )}
            >
                <div
                    className={css(styles.optionText)}
                    onClick={() => this._onChange(option)}
                >
                    {option.label}
                </div>
            </div>
        );
    }
}
