import * as color from "../../constants/color";
import * as routeHelpers from "../../lib/routeHelpers";
import * as texchangePropTypes from "../../texchangePropTypes";
import SearchInput from "../inputs/SearchInput";
import DatePicker from "../inputs/DatePicker";
import PageTitle from "../text/PageTitle";
import React, { Component } from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import blueCircleIcon from "../../images/blue-circle.png";
import Tabs from "../Tabs";
import Infinite from "../Infinite";
import Dropdown from "../inputs/Dropdown";
import TextInput from "../inputs/TextInput";
import Toggle from "../inputs/Toggle";
import FileBrowser from "../inputs/FileBrowser";
import * as inputSizes from "../../constants/inputSizes";
import * as medicalPlansApi from "../../lib/api/medicalPlans";
import * as dentalPlansApi from "../../lib/api/dentalPlans";
import * as visionPlansApi from "../../lib/api/visionPlans";
import * as lifePlansApi from "../../lib/api/lifePlans";
import * as searchApi from "../../lib/api/search";
import * as employerApi from "../../lib/api/employer";
import * as dataApi from "../../lib/api/data";
import editIcon from "../../images/SVGs/edit-field.svg";
import loadDoc from "../../images/SVGs/load-doc.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import sortBy from "lodash.sortby";
import Modal from "../Modal";
import ActionButton from "../ActionButton";
import Checkbox from "../inputs/Checkbox";

const styles = StyleSheet.create({
    content: {
        paddingRight: 10,
        paddingBottom: 80,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
    },
    contentSection: {
        width: "100%",
        padding: 0,
        backgroundColor: color.white,
        borderTop: `1px solid ${color.mediumRed}`,
        marginBottom: 20,
    },
    titleSection: {
        heigth: 64,
        backgroundColor: color.white,
    },
    productTitleSection: {
        width: "100%",
        paddingBottom: 10,
        borderBottom: `1px solid ${color.darkRed}`,
    },
    searchBox: {
        maxWidth: 480,
        textAlign: "center",
        margin: "30px auto 30px auto",
    },
    table: {
        width: "100%",
        marginBottom: 8,
        borderCollapse: "collapse",
    },
    tableCell: {
        padding: "0px 4px",
        backgroundColor: color.white,
    },
    tableCellInstruction: {
        marginTop: 8,
        color: color.mediumRed,
        backgroundColor: color.white,
        borderBottom: `1px solid ${color.darkRed}`,
    },
    row: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        marginBottom: 20,
    },
    column: {
        display: "flex",
        flexDirection: "column",
        flexBasis: "100%",
        flex: 1,
        marginLeft: 15,
        marginRight: 15,
    },
    left: {
        float: "left",
        position: "relative",
        top: 2,
        left: 2,
        fontSize: 14,
        color: color.tableText,
    },
    right: {
        float: "right",
        position: "relative",
        top: -38,
        right: 20,
        fontSize: 14,
        color: color.tableText,
    },
    addPlan: {
        float: "right",
        cursor: "pointer",
        marginTop: 8,
        marginRight: 24,
    },
    addPlanText: {
        display: "inline-block",
        fontSize: 14,
    },
    blueCircle: {
        paddingTop: 6,
        marginLeft: 12,
        position: "relative",
        top: 4,
    },
    editPlan: {
        float: "right",
        cursor: "pointer",
        marginTop: 8,
        marginRight: 8,
        marginBottom: 8,
    },
    editPlanLink: {
        paddingLeft: 16,
        marginBottom: 8,
        color: color.borderBackgroundGray,
    },
    filterText: {
        display: "inline-block",
        fontSize: 14,
        marginLeft: 16,
        lineHeight: "28px",
    },
    dropdown: {
        display: "inline-block",
        width: 160,
        marginLeft: 16,
    },
    smallDropdown: {
        display: "inline-block",
        width: 130,
        marginLeft: 16,
    },
    carrierDropdown: {
        display: "inline-block",
        width: 200,
        marginLeft: 16,
    },
    columnFilterContainer: {
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: color.lightGray4,
        borderTop: `1px solid ${color.lightGray2}`,
        borderBottom: `1px solid ${color.lightGray2}`,
    },
    columnFilterContainer2: {
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: color.lightGray3,
        borderBottom: `1px solid ${color.lightGray2}`,
    },
    instruction: {
        fontSize: 20,
        fontWeight: 300,
        color: color.darkGray,
        padding: "28px 15px",
    },
    oneThird: {
        flex: 1,
    },
    twoThirds: {
        flex: 2,
    },
    product: {
        width: "100%",
        padding: "0px 10px",
        marginBottom: 10,
        backgroundColor: color.lightGray,
        fontSize: 15,
        border: `1px solid ${color.darkGray4}`,
        borderRadius: 4,
    },
    productLine: {
        padding: "10px 0px",
    },
    productName: {
        fontWeight: 500,
        borderBottom: `1px solid ${color.darkGray4}`,
    },
    lightBlue: {
        backgroundColor: color.lightBlue6,
    },
    noResults: {
        backgroundColor: color.white,
        color: color.darkGray,
        padding: 20,
        marginBottom: 40,
    },
    editorWrapper: {
        border: `1px solid ${color.darkGray4}`,
        marginBottom: 10,
    },
    editorEditor: {
        backgroundColor: color.white,
        padding: 8,
        height: "250px",
    },
    editorToolbar: {
        backgroundColor: color.lightGray,
        borderBottom: `1px solid ${color.darkGray4}`,
        marginBottom: 0,
    },
    editorTextarea: {
        width: "100%",
        height: "125px",
        backgroundColor: color.lightGray,
        color: color.darkGray4,
    },
    displayHtml: {
        backgroundColor: color.white,
        border: `1px solid ${color.darkGray4}`,
        borderRadius: 4,
        padding: 8,
        width: "95%",
    },
    dropdownHolder: {
        display: "inline-block",
    },
    linkHolder: {
        paddingLeft: 6,
        verticalAlign: "middle",
        display: "inline-block",
    },
    resetButtonHolder: {
        display: "inline-block",
        paddingLeft: 15,
    },
    resetButton: {
        color: color.white,
        backgroundColor: color.blue1,
        height: 44,
        padding: "0 15px",
        border: `1px solid ${color.mediumGray}`,
        borderRadius: 4,
        cursor: "pointer",
        ":disabled": {
            cursor: "not-allowed",
            color: color.darkGray4,
            backgroundColor: color.lightGray,
        },
    },
    extractButton: {
        color: color.white,
        backgroundColor: color.blue1,
        height: 44,
        padding: "0 15px",
        border: `1px solid ${color.mediumGray}`,
        borderRadius: 4,
        cursor: "pointer",
        ":disabled": {
            cursor: "not-allowed",
            color: color.darkGray4,
            backgroundColor: color.lightGray,
        },
    },
    ratesButton: {
        color: color.white,
        backgroundColor: color.blue1,
        height: 44,
        padding: "0 15px",
        border: `1px solid ${color.mediumGray}`,
        borderRadius: 4,
        cursor: "pointer",
        ":disabled": {
            cursor: "not-allowed",
            color: color.darkGray4,
            backgroundColor: color.lightGray,
        },
    },
    modalContent: {
        width: 720,
        maxHeight: "70vh",
        overflowY: "auto",
    },
    modalButtonContainer: {
        marginTop: 38,
        display: "flex",
        justifyContent: "center",
    },
    modalButton: {
        width: 150,
        marginLeft: 16,
        marginRight: 16,
    },
    filtersHolder: {
        marginBottom: 16,
        overflowY: "hidden",
    },
    selectedPlansTitle: {
        marginBottom: 4,
        color: color.darkRed,
    },
    selectedPlansHolder: {
        height: 250,
        border: `1px solid ${color.mediumGray}`,
        borderRadius: 4,
        padding: 8,
        overflowX: "hidden",
        marginBottom: 16,
    },
    checkLabel: {
        display: "block",
        marginLeft: -34,
        paddingLeft: 34,
        position: "relative",
        border: "none",
        marginTop: 10,
        "-webkit-appearance": "none",
        textAlign: "left",
    },
    labelText: {
        marginLeft: 10,
        fontSize: 16,
    },
    toggle: {
        paddingTop: 8,
    },
    loadDoc: {
        pointerEvents: "auto",
        transform: "rotate(-45deg)",
        display: "block",
        width: 24,
    },
});

const strings = {
    title: "Plan Management",
    searchPlaceholder: "Search for a plan to update",
    addPlan: "Add Plan",
    addProduct: "Add Product Line",
    addDisclaimer: "Add Disclaimer",
    planTypeFilterLabel: "Plan Type",
    carrierFilterLabel: "Carriers",
    tierFilterLabel: "Metal Tier",
    deductibleFilterLabel: "Deductible",
    coninsuranceFilterLabel: "Co-Insurance",
    planNameFilterLabel: "Search for plan",
    effectiveDatePlaceholder: "Effective Date",
    zipPlaceholder: "Zip",
    countyPlaceholder: "County",
    empContribPlaceholder: "Emp (% or $)",
    depContribPlaceholder: "Dep (% or $)",
    contributionIntervalPlaceholder: "Select Contribution Interval",
    basePlanPlaceholder: "Select Base Plan",
};

const inputNames = {
    search: "search",
    postalCode: "postalCode",
    countyId: "countyId",
    effectiveDate: "effectiveDate",
    incContribution: "incContribution",
    empContribution: "empContribution",
    depContribution: "depContribution",
    contributionInterval: "contributionInterval",
    baseMedicalPlanName: "baseMedicalPlanName",
};

export class PlansScreen extends Component {
    static propTypes = {
        history: texchangePropTypes.history.isRequired,
        me: texchangePropTypes.me.isRequired,
    };

    state = {
        allPlans: [],
        allPlansCount: 0,
        allPlansMore: false,
        planColumnLabels: ["State", "Rating Areas", "Carrier", "Package", "Plan Name", "Year", "Effective", "Network", "Plan Type", "Market", "Q1Age/Cmp", "Q2Age/Cmp", "Q3Age/Cmp", "Q4Age/Cmp", "SBC"],
        planColumnValues: ["area", "ratingAreas", "carrierName", "packageName", "displayName", "year", "effectiveDateString", "networkName", "planTypeName", "market", "q1", "q2", "q3", "q4", "summaryPDFName"],
        planColumnLabelsD: ["State", "Carrier", "Plan Name", "Effective", "Deductible", "CoInsurance", "Annual Max", "Funding Type", "Perio/Endo", "Summary"],
        planColumnValuesD: ["area", "carrierName", "displayName", "effectiveDateString", "deductible", "coInsurance", "annualMaximum", "fundingType", "perioEndo", "summaryPDFName"],
        planColumnLabelsV: ["State", "Carrier", "Plan Name", "Effective", "Exam Copay", "Materials Copay", "Frequency", "Frames Allowance", "Contact Lens Allowance", "Funding Type", "Summary"],
        planColumnValuesV: ["area", "carrierName", "displayName", "effectiveDateString", "examCopay", "materialsCopay", "frequency", "framesAllowance", "contactLensAllowance", "fundingType", "summaryPDFName"],
        planColumnLabelsL: ["State", "Carrier", "Plan Name", "Coverage Amount", "Schedule", "Summary"],
        planColumnValuesL: ["area", "carrierName", "displayName", "coverageAmount", "schedule", "summaryPDFName"],
        inputs: {
            [inputNames.search]: "",
            [inputNames.postalCode]: "",
            [inputNames.countyId]: null,
            [inputNames.effectiveDate]: null,
            [inputNames.incContribution]: true,
            [inputNames.empContribution]: "50%",
            [inputNames.depContribution]: "0%",
            [inputNames.contributionInterval]: "Monthly",
            [inputNames.baseMedicalPlanName]: "",
        },
        selectedCarrier: "",
        selectedLineOfCoverage: "Medical",
        selectedYear: "2024",
        selectedPlanType: "",
        selectedFundingType: "",
        selectedRatingArea: "",
        selectedState: "",
        selectedProductState: {},
        selectedProductCarrier: {},
        selectedDisclaimerState: {},
        selectedDisclaimerCarrier: {},
        selectedDisclaimerLineOfCoverage: "Medical",
        loading: false,
        errors: {},
        carriers: [],
        linesOfCoverage: [
            { name: "Medical" },
            { name: "Dental" },
            { name: "Vision" },
            { name: "Life" },
        ],
        years: [
            { name: "2025" },
            { name: "2024" },
            { name: "2023" },
            { name: "2022" },
        ],
        planTypes: [],
        planTypesFiltered: [],
        planTypesFilter: [],
        fundingTypes: [
            { name: "Voluntary" },
            { name: "Employer Paid" },
        ],
        states: [],
        newProduct: {
            id: 0,
            carrierId: "",
            areaId: "",
            name: "",
            notes: "",
            isEdit: false,
        },
        disclaimers: [],
        disclaimersFiltered: [],
        newDisclaimer: {
            id: 0,
            carrierId: "",
            areaId: "",
            lineOfCoverage: "",
            notes: "",
            isEdit: false,
        },
        editorState: EditorState.createEmpty(),
        allChecked: false,
        selectedMedicalPlansAge: [],
        selectedMedicalPlansSBS: [],
        showModalAge: false,
        showModalSBS: false,
        counties: [],
        contributionIntervals: [
            { name: "Monthly" },
            { name: "Bi-Weekly" },
        ],
    };

    componentDidMount() {
        this._mounted = true;
        this.setState({ loading: true });
        //filters...  carriers, year, plantype
        Promise.all([dataApi.getCarriers(), dataApi.getAreas(), dataApi.getMedicalPlanTypes(), dataApi.getMedicalPlanTypesFilter(0), dataApi.getDisclaimers()])
            .then(responses => {
                if (responses && responses[0] && responses[1] && responses[2] && responses[3] && responses[4] && this._mounted) {
                    //get default state + carrier for this office
                    const carriers = responses[0];
                    const states = responses[1];
                    const selectedState = states[0];
                    const stMapped = this._getMappedState(selectedState.id.toString());
                    const filteredCarriers = carriers.length > 0 ? carriers.filter(c => c.area && c.area.indexOf(stMapped) > -1) : [];
                    const selectedCarrier = filteredCarriers[0];
                    //update state
                    this.setState({
                        carriers: carriers,
                        selectedCarrier: selectedCarrier.id.toString(),
                        selectedProductCarrier: selectedCarrier,
                        selectedDisclaimerCarrier: selectedCarrier,
                        states: states,
                        selectedState: selectedState.id.toString(),
                        selectedProductState: selectedState,
                        selectedDisclaimerState: selectedState,
                        planTypes: responses[2],
                        planTypesFiltered: responses[2].filter(pt => pt.carrierId === selectedCarrier.id.toString() && pt.areaId === selectedState.id.toString()),
                        planTypesFilter: responses[3],
                        disclaimers: responses[4],
                        disclaimersFiltered: responses[4].filter(pt => pt.carrierId === selectedCarrier.id.toString() && pt.areaId === selectedState.id.toString() && pt.lineOfCoverage === this.state.selectedDisclaimerLineOfCoverage),
                    }, () => {
                        this._getPlans();
                    });
                }
            });
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    _getPlans = () => {
        const { selectedLineOfCoverage, selectedState, selectedCarrier, selectedYear, selectedPlanType, selectedFundingType, selectedRatingArea } = this.state;

        this.setState({
            loading: true,
            allPlans: [],
            allPlansCount: 0,
            allPlansMore: false,
        });
        if (selectedLineOfCoverage === "Medical") {
            if (selectedState && selectedCarrier && selectedYear) {
                medicalPlansApi.getMedicalPlansDisplay(selectedState, selectedCarrier, selectedYear, selectedPlanType, selectedRatingArea).then(result => {
                    this.setState({ loading: false });
                    if (result) {
                        this.setState({
                            allPlans: result,
                            allPlansCount: result.length,
                            allPlansMore: false,
                        });
                    }
                });
            } else {
                this.setState({ loading: false });
            }
        } else if (selectedLineOfCoverage === "Dental") {
            dentalPlansApi.getDentalPlansDisplay(selectedState, selectedCarrier, selectedYear, selectedFundingType).then(result => {
                this.setState({ loading: false });
                if (result) {
                    this.setState({
                        allPlans: result,
                        allPlansCount: result.length,
                        allPlansMore: false,
                    });
                }
            });
        } else if (selectedLineOfCoverage === "Vision") {
            visionPlansApi.getVisionPlansDisplay(selectedState, selectedCarrier, selectedYear, selectedFundingType).then(result => {
                this.setState({ loading: false });
                if (result) {
                    this.setState({
                        allPlans: result,
                        allPlansCount: result.length,
                        allPlansMore: false,
                    });
                }
            });
        } else if (selectedLineOfCoverage === "Life") {
            lifePlansApi.getLifePlansDisplay(selectedState, selectedCarrier, selectedYear).then(result => {
                this.setState({ loading: false });
                if (result) {
                    this.setState({
                        allPlans: result,
                        allPlansCount: result.length,
                        allPlansMore: false,
                    });
                }
            });
        }
    };

    _rowClicked = id => {
        const { allPlans, selectedLineOfCoverage, selectedMedicalPlansAge, selectedMedicalPlansSBS } = this.state;

        if (allPlans && selectedLineOfCoverage === "Medical") {
            //add or remove
            const plan = allPlans.find(p => p.id === id);
            if (plan) {
                //Age Rates
                if (plan.q1 === "Yes" || plan.q2 === "Yes" || plan.q3 === "Yes" || plan.q4 === "Yes") {
                    if (selectedMedicalPlansAge.indexOf(plan) === -1) {
                        //add
                        selectedMedicalPlansAge.push(plan);
                    } else {
                        //remove
                        const idx = selectedMedicalPlansAge.indexOf(plan);
                        selectedMedicalPlansAge.splice(idx, 1);
                    }
                }
                //Side-By-Sides
                if (selectedMedicalPlansSBS.indexOf(plan) === -1) {
                    //add
                    selectedMedicalPlansSBS.push(plan);
                } else {
                    //remove
                    const idx = selectedMedicalPlansSBS.indexOf(plan);
                    selectedMedicalPlansSBS.splice(idx, 1);
                }
            }
        }

        this.setState({
            selectedMedicalPlansAge,
            selectedMedicalPlansSBS
        });
    };

    _deselectPlanAge = (e, plan) => {
        const { selectedMedicalPlansAge } = this.state;
        const idx = selectedMedicalPlansAge.indexOf(plan);
        selectedMedicalPlansAge.splice(idx, 1);
        this.setState({ selectedMedicalPlansAge });
    };

    _deselectPlanSBS = (e, plan) => {
        const { selectedMedicalPlansSBS } = this.state;
        const idx = selectedMedicalPlansSBS.indexOf(plan);
        selectedMedicalPlansSBS.splice(idx, 1);
        this.setState({ selectedMedicalPlansSBS });
    };

    _allClicked = () => {
        const { allChecked, allPlans, selectedLineOfCoverage, selectedMedicalPlansAge, selectedMedicalPlansSBS } = this.state;

        if (allPlans && selectedLineOfCoverage === "Medical") {
            //add or remove
            for (let i = 0; i < allPlans.length; i++) {
                if (!allChecked) {
                    //add
                    //Age Rates
                    if (selectedMedicalPlansAge.indexOf(allPlans[i]) === -1 && (allPlans[i].q1 === "Yes" || allPlans[i].q2 === "Yes" || allPlans[i].q3 === "Yes" || allPlans[i].q4 === "Yes")) {
                        selectedMedicalPlansAge.push(allPlans[i]);
                    }
                    //Side-By-Sides
                    if (selectedMedicalPlansSBS.indexOf(allPlans[i]) === -1) {
                        selectedMedicalPlansSBS.push(allPlans[i]);
                    }
                } else {
                    //remove
                    //Age Rates
                    if (selectedMedicalPlansAge.indexOf(allPlans[i]) > -1) {
                        const idx = selectedMedicalPlansAge.indexOf(allPlans[i]);
                        selectedMedicalPlansAge.splice(idx, 1);
                    }
                    //Side-By-Sides
                    if (selectedMedicalPlansSBS.indexOf(allPlans[i]) > -1) {
                        const idx = selectedMedicalPlansSBS.indexOf(allPlans[i]);
                        selectedMedicalPlansSBS.splice(idx, 1);
                    }
                }
            }
        }

        this.setState({
            selectedMedicalPlansAge,
            selectedMedicalPlansSBS,
            allChecked: !allChecked
        });
    };

    _getAgeRates = (selectedPlanIds, effectiveDate, postalCode, countyId, incContribution, empContribution, depContribution, contInterval, baseMedicalPlanName, reverseSort) => {
        this.setState({ loading: true });
        medicalPlansApi.getAgeRates(selectedPlanIds, effectiveDate, postalCode, countyId, incContribution, empContribution, depContribution, contInterval, baseMedicalPlanName, reverseSort).then(response => {
            this.setState({
                loading: false,
                showModalAge: false,
            });
            if (response && response.startsWith("ERROR")) {
                toast.error("There was an error encountered while generating your age rates. " + response, { toastId: 1 });
            }
        });
    };

    _getSideBySides = (selectedPlanIds) => {
        this.setState({ loading: true });
        medicalPlansApi.getSideBySides(selectedPlanIds).then(response => {
            this.setState({
                loading: false,
                showModalSBS: false,
            });
            if (response && response.startsWith("ERROR")) {
                toast.error("There was an error encountered while generating your side by sides. " + response, { toastId: 1 });
            }
        });
    };

    _getMedicalPlanExtract = () => {
        const { states, selectedState, carriers, selectedCarrier, selectedYear, selectedPlanType } = this.state;
        const stateName = states.find(x => x.id.toString() === selectedState).name;
        const carrierName = carriers.find(x => x.id.toString() === selectedCarrier).name;
        const fileName = stateName + '_' + carrierName + '_Plans_' + selectedYear + '.xlsx';

        this.setState({ loading: true });
        medicalPlansApi.getMedicalPlanExtract(selectedState, selectedCarrier, selectedYear, selectedPlanType, fileName).then(response => {
            this.setState({ loading: false });
            if (response && response.startsWith("ERROR")) {
                toast.error("There was an error encountered while generating your extract.", { toastId: 1 });
            }
        });
    };

    _uploadMedicalPlanExtract = file => {
        //check for xls
        if (!file.name.endsWith(".xlsx")) {
            toast.error("Please use only a valid Excel (.xlsx not .xls) extract", { toastId: 1 });
            return;
        } else {
            medicalPlansApi.uploadPlanExtract(file).then(response => {
                if (response && !response.success) {
                    toast.error("There was an error encountered while uploading your extract. " + response.message, { toastId: 1 });
                } else if (response && response.success) {
                    toast.success("Extract uploaded successfully. " + response.message, { toastId: 1 });
                }
            });
        }

        this.setState({ fileUpload: file });
    };

    _handleShowModalAge = () => {
        this.setState(prevState => {
            return {
                showModalAge: true,
                showModalSBS: false,
                counties: [],
                inputs: {
                    ...prevState.inputs,
                    [inputNames.countyId]: null,
                },
            }
        });
    };

    _handleShowModalSBS = () => {
        this.setState({
            showModalSBS: true,
            showModalAge: false,
        });
    };

    _handleCancelModal = () => {
        this.setState({
            showModalAge: false,
            showModalSBS: false,
        });
    };

    _handleEffectiveDateChanged = date => {
        if (date) {
            this.setState(prevState => {
                const errors = { ...prevState.errors };
                if (date) {
                    delete errors[inputNames.effectiveDate];
                }

                return {
                    inputs: {
                        ...prevState.inputs,
                        [inputNames.effectiveDate]: date,
                    },
                    errors,
                };
            });
        }
    };

    _handleSortChanged = value => {
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.reverseSort]: value,
                },
            };
        });
    };

    _getPlanTypesFilter = () => {
        const { planTypesFilter, selectedYear, selectedCarrier } = this.state;

        let options = [];
        Object.keys(planTypesFilter).forEach(key => {
            let split = planTypesFilter[key].split('|');
            let carrierId = split[0];
            let year = split[1];
            let planTypeName = split[3];
            if (year === selectedYear && carrierId === selectedCarrier) {
                options.push({ name: planTypeName });
            }
        });
        return options;
    };

    _getRatingAreasFilter = () => {
        const { selectedState } = this.state;
        let options = [];
        //each state has specific rating areas.
        let max = 1;
        if (selectedState === "14" || selectedState === "17" || selectedState === "20" || selectedState === "26" || selectedState === "27" || selectedState === "37") {
            max = 4;
        } else if (selectedState === "21" || selectedState === "31") {
            max = 5;
        } else if (selectedState === "22" || selectedState === "42" || selectedState === "45") {
            max = 6;
        } else if (selectedState === "5" || selectedState === "8" || selectedState === "18" || selectedState === "35" || selectedState === "36") {
            max = 7;
        } else if (selectedState === "9" || selectedState === "10" || selectedState === "24" || selectedState === "33") {
            max = 8;
        } else if (selectedState === "15" || selectedState === "19" || selectedState === "23") {
            max = 9;
        } else if (selectedState === "7" || selectedState === "25") {
            max = 10;
        } else if (selectedState === "29") {
            max = 11;
        } else if (selectedState === "13") {
            max = 12;
        } else if (selectedState === "2") {
            max = 13;
        } else if (selectedState === "6" || selectedState === "11" || selectedState === "16" || selectedState === "30") {
            max = 16;
        } else if (selectedState === "4" || selectedState === "28") {
            max = 17;
        } else if (selectedState === "32") {
            max = 19;
        } else if (selectedState === "1") {
            max = 27;
        } else if (selectedState === "12") {
            max = 46;
        } else if (selectedState === "3") {
            max = 67;
        }
        //add 'ALL' option
        options.push({ name: "All Rating Areas", id: -1 });
        //fill in the rest
        for (let i = 1; i <= max; i++) {
            options.push({ name: "Rating Area " + i, id: i });
        }
        //return
        return options;
    };

    _handleTextChange = e => {
        const { name, value } = e.target;

        //maxlength of 5 for postal
        if ((name === inputNames.postalCode && value.length === 6)) {
            return;
        }

        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
            };
        });
    };

    _handleCountyIdChanged = item => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.countyId];

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.countyId]: item.value,
                },
                errors,
            };
        });
    };

    _handleContributionChange = e => {
        const { checked, name } = e.target;

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: checked,
                },
                errors,
            };
        });
    };

    _handleContributionIntervalChanged = item => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.contributionInterval]: item.value,
                },
                errors,
            };
        });
    };

    _handleBasePlanChanged = item => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.baseMedicalPlanName]: item.value,
                },
                errors,
            };
        });
    };

    _handleGenerateAgeRates = () => {
        const { inputs, loading, selectedMedicalPlansAge, selectedRatingArea } = this.state;
        //already loading?
        if (loading) {
            return;
        }

        //validate
        if (!selectedMedicalPlansAge || selectedMedicalPlansAge.length === 0) {
            toast.error("There are no plans selected.", { toastId: 1 });
            return;
        }

        //get just the Plan Ids from the list
        const selectedMedicalPlanIds = selectedMedicalPlansAge.map(p => p.id);

        //get input values
        const effectiveDate = inputs[inputNames.effectiveDate];
        const postalCode = inputs[inputNames.postalCode];
        const countyId = inputs[inputNames.countyId];
        const incContribution = inputs[inputNames.incContribution];
        const empContribution = inputs[inputNames.empContribution];
        const depContribution = inputs[inputNames.depContribution];
        const contInterval = inputs[inputNames.contributionInterval];
        const baseMedicalPlanName = inputs[inputNames.baseMedicalPlanName];
        const reverseSort = inputs[inputNames.reverseSort];

        //validate
        if (!effectiveDate || !postalCode) {
            toast.error("Effective Date and Postal Code required.", { toastId: 1 });
            return;
        }

        //if a rating area has been selected
        const ratingArea = selectedRatingArea ? selectedRatingArea : "-1";

        //check for multi-county zip
        if (postalCode && !countyId) {
            employerApi.checkZip(postalCode, ratingArea).then(response => {
                if (response && response.success && response.message && response.message.startsWith("single")) {
                    const countyJSON = JSON.parse(response.message.split("|")[1]);
                    if (countyJSON && countyJSON[0]) {
                        //continue
                        this._getAgeRates(selectedMedicalPlanIds, effectiveDate, postalCode, countyJSON[0].id, incContribution, empContribution, depContribution, contInterval, baseMedicalPlanName, reverseSort);
                    }
                } else if (response && !response.success && response.message && response.message.startsWith("multiple")) {
                    const countiesJSON = JSON.parse(response.message.split("|")[1]);
                    //warn
                    toast.warning("The multi-county zip code that you entered requires you to choose a county before proceeding.", { toastId: 1 });
                    //set
                    this.setState({
                        counties: countiesJSON,
                    });
                } else if (response && !response.success && response.message) {
                    toast.warning(response.message, { toastId: 1 });
                }
            });
        } else {
            //continue
            this._getAgeRates(selectedMedicalPlanIds, effectiveDate, postalCode, countyId, incContribution, empContribution, depContribution, contInterval, baseMedicalPlanName, reverseSort);
        }
    };

    _handleGenerateSideBySides = () => {
        const { loading, selectedMedicalPlansSBS } = this.state;
        //already loading?
        if (loading) {
            return;
        }

        //validate
        if (!selectedMedicalPlansSBS || selectedMedicalPlansSBS.length === 0) {
            toast.error("There are no plans selected.", { toastId: 1 });
            return;
        }

        //get just the Plan Ids from the list
        const selectedMedicalPlanIds = selectedMedicalPlansSBS.map(p => p.id);

        //continue
        this._getSideBySides(selectedMedicalPlanIds);
    };

    _searchResultSelected = (item) => {
        if (item.type === "Plan") {
            this.props.history.push(routeHelpers.planDetail(item.value));
        }
    };

    _handleSort = (page, col, dir) => {
        const { allPlans } = this.state;
        if (dir === "asc") {
            this.setState({
                allPlans: sortBy(allPlans, col),
            });
        }
        else {
            this.setState({
                allPlans: sortBy(allPlans, col).reverse(),
            });
        }
    };

    _changeState = (item) => {
        this.setState(prevState => {
            return {
                selectedState: item,
                selectedCarrier: "",
                selectedPlanType: "",
                selectedFundingType: "",
                selectedRatingArea: "",
                allChecked: false,
                selectedMedicalPlansAge: [],
                selectedMedicalPlansSBS: [],
                showModalAge: false,
                showModalSBS: false,
                inputs: {
                    ...prevState.inputs,
                    [inputNames.baseMedicalPlanName]: "",
                },
                allPlans: [],
                allPlansCount: 0,
                allPlansMore: false,
            }
        });
    };

    _changeCarrier = (item) => {
        this.setState(prevState => {
            return {
                selectedCarrier: item,
                selectedPlanType: "",
                selectedFundingType: "",
                //selectedRatingArea: "",
                allChecked: false,
                showModalAge: false,
                showModalSBS: false,
                inputs: {
                    ...prevState.inputs,
                    [inputNames.baseMedicalPlanName]: "",
                }
            }
        }, () => {
            this._getPlans();
        });
    };

    _changeCarrier2 = (item) => {
        this.setState(prevState => {
            return {
                selectedCarrier: item,
                selectedPlanType: "",
                selectedFundingType: "",
                //selectedRatingArea: "",
                allChecked: false,
                inputs: {
                    ...prevState.inputs,
                    [inputNames.baseMedicalPlanName]: "",
                }
            }
        }, () => {
            this._getPlans();
        });
    };

    _changeLineOfCoverage = (item) => {
        this.setState(prevState => {
            return {
                selectedLineOfCoverage: item,
                selectedPlanType: "",
                selectedFundingType: "",
                selectedRatingArea: "",
                allChecked: false,
                selectedMedicalPlansAge: [],
                selectedMedicalPlansSBS: [],
                showModalAge: false,
                showModalSBS: false,
                inputs: {
                    ...prevState.inputs,
                    [inputNames.baseMedicalPlanName]: "",
                }
            }
        }, () => {
            this._getPlans();
        });
    };

    _changeYear = (item) => {
        this.setState(prevState => {
            return {
                selectedYear: item,
                selectedPlanType: "",
                selectedFundingType: "",
                selectedRatingArea: "",
                allChecked: false,
                selectedMedicalPlansAge: [],
                selectedMedicalPlansSBS: [],
                showModalAge: false,
                showModalSBS: false,
                inputs: {
                    ...prevState.inputs,
                    [inputNames.baseMedicalPlanName]: "",
                }
            }
        }, () => {
            this._getPlans();
        });
    };

    _changePlanType = (item) => {
        this.setState(prevState => {
            return {
                selectedPlanType: item,
                allChecked: false,
                showModalAge: false,
                showModalSBS: false,
                inputs: {
                    ...prevState.inputs,
                    [inputNames.baseMedicalPlanName]: "",
                }
            }
        }, () => {
            this._getPlans();
        });
    };

    _changeFundingType = (item) => {
        this.setState(prevState => {
            return {
                selectedFundingType: item,
                allChecked: false,
                showModalAge: false,
                showModalSBS: false,
                inputs: {
                    ...prevState.inputs,
                    [inputNames.baseMedicalPlanName]: "",
                }
            }
        }, () => {
            this._getPlans();
        });
    };

    _changeRatingArea = (item) => {
        this.setState(prevState => {
            return {
                selectedRatingArea: item,
                allChecked: false,
                showModalAge: false,
                showModalSBS: false,
                inputs: {
                    ...prevState.inputs,
                    [inputNames.baseMedicalPlanName]: "",
                }
            }
        }, () => {
            this._getPlans();
        });
    };

    _clearFilters = () => {
        const { states, carriers, years } = this.state;
        this.setState({
            selectedLineOfCoverage: "Medical",
            selectedCarrier: carriers[0].id.toString(),
            selectedYear: years[0].name,
            selectedPlanType: "",
            selectedFundingType: "",
            selectedRatingArea: "",
            selectedState: states[0].id.toString(),
            allChecked: false,
            selectedMedicalPlansAge: [],
            selectedMedicalPlansSBS: [],
            showModalAge: false,
            showModalSBS: false,
            inputs: {
                [inputNames.effectiveDate]: null,
                [inputNames.incContribution]: true,
                [inputNames.empContribution]: "50%",
                [inputNames.depContribution]: "0%",
                [inputNames.contributionInterval]: "Monthly",
                [inputNames.baseMedicalPlanName]: "",
                [inputNames.reverseSort]: false,
            }
        }, () => {
            this._getPlans();
        });
    };

    _changeProductState = (item) => {
        this.setState({
            selectedProductState: this.state.states.find(carrier => carrier.id.toString() === item),
            selectedProductCarrier: "",
            planTypesFiltered: this.state.selectedProductCarrier ? this.state.planTypes.filter(pt => pt.carrierId === this.state.selectedProductCarrier.id.toString() && pt.areaId === item) : [],
        });
    };

    _changeProductCarrier = (item) => {
        this.setState({
            selectedProductCarrier: this.state.carriers.find(carrier => carrier.id.toString() === item),
            planTypesFiltered: this.state.selectedProductState ? this.state.planTypes.filter(pt => pt.carrierId === item && pt.areaId === this.state.selectedProductState.id.toString()) : [],
        });
    };

    _addProduct = () => {
        this.setState({
            newProduct: {
                id: 0,
                carrierId: this.state.selectedProductCarrier.id.toString(),
                areaId: this.state.selectedProductState.id.toString(),
                name: "",
                notes: "",
                isEdit: true,
                isNew: true,
            },
            planTypesFiltered: this.state.planTypesFiltered.map(
                type => type.id > 0 ?
                    { ...type, isEdit: false } :
                    type
            ),
        });
    };

    _editProduct = (e, pt) => {
        this.setState({
            planTypesFiltered: this.state.planTypesFiltered.map(
                type => type.id === pt.id ?
                    { ...type, isEdit: true } :
                    { ...type, isEdit: false }
            ),
            newProduct: {
                isEdit: false,
            },
        });
    };

    _nameChanged = (e, pt) => {
        pt.originalName = pt.originalName ? pt.originalName : pt.name;
        const { value } = e.target;
        if (pt.isNew) {
            pt.name = value;
            this.setState({
                newProduct: pt,
            });
        } else {
            this.setState({
                planTypesFiltered: this.state.planTypesFiltered.map(
                    type => type.id === pt.id ?
                        { ...type, name: value } :
                        type,
                ),
            });
        }
    };

    _notesChanged = (e, pt) => {
        pt.originalNotes = pt.originalNotes ? pt.originalNotes : pt.notes;
        const { value } = e.target;
        if (pt.isNew) {
            pt.notes = value;
            this.setState({
                newProduct: pt,
            });
        } else {
            this.setState({
                planTypesFiltered: this.state.planTypesFiltered.map(
                    type => type.id === pt.id ?
                        { ...type, notes: value } :
                        type,
                ),
            });
        }
    };

    _applyEditProduct = pt => {
        dataApi.saveMedicalPlanType(pt)
            .then(result => {
                if (!result) {
                    toast.error("failed to save product line.", { toastId: 1 });
                    return;
                }
                dataApi.getMedicalPlanTypes()
                    .then(response => {
                        this.setState({
                            planTypes: response,
                            planTypesFiltered: response.filter(pt => pt.carrierId === this.state.selectedProductCarrier.id.toString() && pt.areaId === this.state.selectedProductState.id.toString()),
                            newProduct: {
                                isEdit: false,
                            },
                        });
                    });
            });
    };

    _cancelEditProduct = pt => {
        this.setState({
            planTypesFiltered: this.state.planTypesFiltered.map(
                type => type.id === pt.id ?
                    { ...type, name: type.originalName ? type.originalName : type.name, notes: type.originalNotes ? type.originalNotes : type.notes, isEdit: false, } :
                    type,
            ),
            newProduct: {
                isEdit: false,
            },
        });
    };

    _deleteProduct = pt => {
        if (window.confirm("Please confirm that you wish to delete this product line.")) {
            dataApi.deleteMedicalPlanType(parseInt(pt.id))
                .then(result => {
                    if (!result) {
                        toast.error("failed to delete product line.", { toastId: 2 });
                        return;
                    }
                    dataApi.getMedicalPlanTypes()
                        .then(response => {
                            this.setState({
                                planTypes: response,
                                planTypesFiltered: response.filter(pt => pt.carrierId === this.state.selectedProductCarrier.id.toString() && pt.areaId === this.state.selectedProductState.id.toString()),
                                newProduct: {
                                    isEdit: false,
                                },
                            });
                        });
                });
        }
    };

    _changeDisclaimerState = (item) => {
        this.setState({
            selectedDisclaimerState: this.state.states.find(carrier => carrier.id.toString() === item),
            selectedDisclaimerCarrier: "",
            disclaimersFiltered: [], //this.state.disclaimers.filter(pt => pt.carrierId === this.state.selectedDisclaimerCarrier.id.toString() && pt.areaId === item && pt.lineOfCoverage === this.state.selectedDisclaimerLineOfCoverage),
        });
    };

    _changeDisclaimerCarrier = (item) => {
        this.setState({
            selectedDisclaimerCarrier: this.state.carriers.find(carrier => carrier.id.toString() === item),
            disclaimersFiltered: this.state.disclaimers.filter(pt => pt.carrierId === item && pt.areaId === this.state.selectedDisclaimerState.id.toString() && pt.lineOfCoverage === this.state.selectedDisclaimerLineOfCoverage),
            newDisclaimer: {
                isEdit: false,
                isNew: false,
            },
        });
    };

    _changeDisclaimerLineOfCoverage = (item) => {
        this.setState({
            selectedDisclaimerLineOfCoverage: item,
            disclaimersFiltered: this.state.disclaimers.filter(pt => pt.carrierId === this.state.selectedDisclaimerCarrier.id.toString() && pt.areaId === this.state.selectedDisclaimerState.id.toString() && pt.lineOfCoverage === item),
        });
    };

    _addDisclaimer = () => {
        this.setState({
            newDisclaimer: {
                id: 0,
                carrierId: this.state.selectedDisclaimerCarrier.id.toString(),
                areaId: this.state.selectedDisclaimerState.id.toString(),
                lineOfCoverage: this.state.selectedDisclaimerLineOfCoverage,
                notes: "",
                isEdit: true,
                isNew: true,
            },
            disclaimersFiltered: this.state.disclaimersFiltered.map(
                type => type.id > 0 ?
                    { ...type, isEdit: false } :
                    type
            ),
            editorState: EditorState.createEmpty(),
        });
    };

    _editDisclaimer = (e, pt) => {
        this.setState({
            disclaimersFiltered: this.state.disclaimersFiltered.map(
                type => type.id === pt.id ?
                    { ...type, isEdit: true } :
                    { ...type, isEdit: false }
            ),
            newDisclaimer: {
                isEdit: false,
            },
            editorState: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(pt.notes))),
        });
    };

    _applyEditDisclaimer = pt => {
        const notes = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        if (notes.length === 8) {
            toast.error("cannot save empty disclaimer.", { toastId: 3 });
            return;
        } else {
            pt.notes = notes;
            dataApi.saveDisclaimer(pt)
                .then(result => {
                    if (!result) {
                        toast.error("failed to save disclaimer.", { toastId: 4 });
                        return;
                    }
                    dataApi.getDisclaimers()
                        .then(response => {
                            this.setState({
                                disclaimers: response,
                                disclaimersFiltered: response.filter(pt => pt.carrierId === this.state.selectedDisclaimerCarrier.id.toString() && pt.areaId === this.state.selectedDisclaimerState.id.toString() && pt.lineOfCoverage === this.state.selectedDisclaimerLineOfCoverage),
                                newDisclaimer: {
                                    isEdit: false,
                                },
                            });
                        });
                });
        }
    };

    _cancelEditDisclaimer = pt => {
        this.setState({
            disclaimersFiltered: this.state.disclaimersFiltered.map(
                type => type.id === pt.id ?
                    { ...type, notes: type.originalNotes ? type.originalNotes : type.notes, isEdit: false, } :
                    type,
            ),
            newDisclaimer: {
                isEdit: false,
            },
        });
    };

    _deleteDisclaimer = pt => {
        if (window.confirm("Please confirm that you wish to delete this disclaimer.")) {
            dataApi.deleteDisclaimer(parseInt(pt.id))
                .then(result => {
                    if (!result) {
                        toast.error("failed to delete disclaimer.", { toastId: 5 });
                        return;
                    }
                    dataApi.getDisclaimers()
                        .then(response => {
                            this.setState({
                                disclaimers: response,
                                disclaimersFiltered: response.filter(pt => pt.carrierId === this.state.selectedDisclaimerCarrier.id.toString() && pt.areaId === this.state.selectedDisclaimerState.id.toString() && pt.lineOfCoverage === this.state.selectedDisclaimerLineOfCoverage),
                                newDisclaimer: {
                                    isEdit: false,
                                },
                            });
                        });
                });
        }
    };

    renderPlans = () => {
        //const { states, carriers, linesOfCoverage, years, planTypesFilter, selectedState, selectedCarrier, selectedLineOfCoverage, selectedYear } = this.state;
        const { loading, linesOfCoverage, states, carriers, years, fundingTypes, selectedLineOfCoverage, selectedState, selectedCarrier, selectedYear, selectedPlanType, selectedFundingType, selectedRatingArea, planColumnLabels, planColumnLabelsD, planColumnLabelsV, planColumnLabelsL, planColumnValues, planColumnValuesD, planColumnValuesV, planColumnValuesL, allChecked, selectedMedicalPlansAge, selectedMedicalPlansSBS } = this.state;
        const { me } = this.props;

        //Each carrier has a list of which states they are available in (.area)
        const stMapped = this._getMappedState(selectedState.toString());

        //filter carriers to selected state
        const filteredCarriers = carriers.length > 0 ? carriers.filter(c => c.area && c.area.indexOf(stMapped) > -1) : [];

        const plans = this.state.allPlans;
        const count = this.state.allPlansCount;
        const countTitle = "Plans";
        const more = this.state.allPlansMore;
        const fnSort = this._handleSort;
        const columnLabels = selectedLineOfCoverage === "Life" ? planColumnLabelsL : selectedLineOfCoverage === "Vision" ? planColumnLabelsV : selectedLineOfCoverage === "Dental" ? planColumnLabelsD : planColumnLabels;
        const columnValues = selectedLineOfCoverage === "Life" ? planColumnValuesL : selectedLineOfCoverage === "Vision" ? planColumnValuesV : selectedLineOfCoverage === "Dental" ? planColumnValuesD : planColumnValues;
        const planTypesFilter = this._getPlanTypesFilter();
        const ratingAreasFilter = this._getRatingAreasFilter();
        const ratingAreasLink = "https://www.cms.gov/cciio/programs-and-initiatives/health-insurance-market-reforms/" + stMapped + "-gra";
        const selectedMedicalPlanIds = (selectedMedicalPlansAge || selectedMedicalPlansSBS) ? selectedMedicalPlansAge.concat(selectedMedicalPlansSBS).map(p => p.id) : [];

        return (
            <div className={css(styles.contentSection)}>
                <div className={css(styles.columnFilterContainer)}>
                    <div className={css(styles.filterText)}>Filter By:</div>
                    <div className={css(styles.dropdownHolder)}>
                        <Dropdown
                            aStyles={styles.smallDropdown}
                            options={years.map(item => ({
                                label: item.name,
                                value: item.name
                            }))}
                            value={selectedYear ? selectedYear : ""}
                            placeholder="Year"
                            onChange={this._changeYear}
                            hideLabel={true}
                            size={inputSizes.small}
                        />
                    </div>
                    <div className={css(styles.dropdownHolder)}>
                        <Dropdown
                            aStyles={styles.smallDropdown}
                            options={(linesOfCoverage.map(item => ({
                                label: item.name,
                                value: item.name
                            })))}
                            value={selectedLineOfCoverage ? selectedLineOfCoverage : ""}
                            placeholder="Line of Coverage"
                            onChange={this._changeLineOfCoverage}
                            hideLabel={true}
                            size={inputSizes.small}
                        />
                    </div>
                    <div className={css(styles.dropdownHolder)}>
                        <Dropdown
                            aStyles={styles.dropdown}
                            options={states.map(item => ({
                                label: item.name,
                                value: item.id.toString()
                            }))}
                            value={selectedState ? selectedState : ""}
                            placeholder="State"
                            onChange={this._changeState}
                            hideLabel={true}
                            size={inputSizes.small}
                        />
                    </div>
                    {selectedLineOfCoverage === "Medical" && ratingAreasFilter.length > 0 &&
                        <div className={css(styles.dropdownHolder)}>
                            <Dropdown
                                aStyles={styles.dropdown}
                                options={ratingAreasFilter.map(item => ({
                                    label: item.name,
                                    value: item.id
                                }))}
                                value={selectedRatingArea ? selectedRatingArea : ""}
                                placeholder="Rating Area"
                                onChange={this._changeRatingArea}
                                hideLabel={true}
                                size={inputSizes.small}
                            />
                        </div>}
                    {selectedLineOfCoverage === "Medical" && ratingAreasFilter.length > 0 &&
                        <div className={css(styles.linkHolder)}>
                            <a href={ratingAreasLink} target="_blank" rel="noopener noreferrer"><img className={css(styles.loadDoc)} alt="Review Rating Areas" src={loadDoc} /></a>
                        </div>}
                    <div className={css(styles.dropdownHolder)}>
                        <Dropdown
                            aStyles={styles.carrierDropdown}
                            options={filteredCarriers.map(item => ({
                                label: item.name,
                                value: item.id.toString()
                            }))}
                            value={selectedCarrier ? selectedCarrier : ""}
                            placeholder="Carrier"
                            onChange={this._changeCarrier}
                            hideLabel={true}
                            size={inputSizes.small}
                        />
                    </div>
                    {selectedLineOfCoverage === "Medical" && planTypesFilter.length > 0 &&
                        <div className={css(styles.dropdownHolder)}>
                            <Dropdown
                                aStyles={styles.dropdown}
                                options={planTypesFilter.map(item => ({
                                    label: item.name,
                                    value: item.name
                                }))}
                                value={selectedPlanType ? selectedPlanType : ""}
                                placeholder="Plan Type"
                                onChange={this._changePlanType}
                                hideLabel={true}
                                size={inputSizes.small}
                            />
                        </div>}
                    {(selectedLineOfCoverage === "Dental" || selectedLineOfCoverage === "Vision") &&
                        <div className={css(styles.dropdownHolder)}>
                            <Dropdown
                                aStyles={styles.dropdown}
                                options={fundingTypes.map(item => ({
                                    label: item.name,
                                    value: item.name
                                }))}
                                value={selectedFundingType ? selectedFundingType : ""}
                                placeholder="Funding Type"
                                onChange={this._changeFundingType}
                                hideLabel={true}
                                size={inputSizes.small}
                            />
                        </div>}
                    <div className={css(styles.resetButtonHolder)}>
                        <button type="button" className={css(styles.resetButton)} onClick={this._clearFilters} disabled={loading}>Reset</button>
                    </div>
                    {selectedLineOfCoverage === "Medical" && (me.isSysAdmin || me.isStateAdmin) ? (
                        <div className={css(styles.resetButtonHolder)}>
                            <button type="button" className={css(styles.extractButton)} onClick={this._getMedicalPlanExtract} disabled={loading}>Generate Plan Extract</button>
                        </div>
                    ) : null}
                    {selectedLineOfCoverage === "Medical" && (me.isSysAdmin || me.isStateAdmin) && !loading ? (
                        <div className={css(styles.resetButtonHolder)}>
                            To Upload an updated Extract, click <FileBrowser onSelect={this._uploadMedicalPlanExtract} disableClick={loading} />
                        </div>
                    ) : null}
                </div>
                <div className={css(styles.right)}>{parseInt(count).toLocaleString()}{" "}{countTitle}</div>
                {selectedLineOfCoverage === "Medical" && ((selectedMedicalPlansAge && selectedMedicalPlansAge.length > 0) || (selectedMedicalPlansSBS && selectedMedicalPlansSBS.length > 0)) ? (
                    <div className={css(styles.columnFilterContainer2)}>
                        <div className={css(styles.filterText)}>Selection Specific Outputs:</div>
                        {selectedMedicalPlansAge && selectedMedicalPlansAge.length > 0 &&
                            <div className={css(styles.resetButtonHolder)}>
                                <button type="button" className={css(styles.ratesButton)} onClick={this._handleShowModalAge} disabled={loading}>Generate Age Rates{" ("}{selectedMedicalPlansAge.length}{")"}</button>
                            </div>
                        }
                        {selectedMedicalPlansSBS && selectedMedicalPlansSBS.length > 0 &&
                            <div className={css(styles.resetButtonHolder)}>
                                <button type="button" className={css(styles.ratesButton)} onClick={this._handleShowModalSBS} disabled={loading}>Generate Side-By-Sides{" ("}{selectedMedicalPlansSBS.length}{")"}</button>
                            </div>
                        }
                    </div>
                ) : null}
                {plans.length > 0 ? (
                    <Infinite
                        results={plans}
                        length={plans.length}
                        columnLabels={columnLabels}
                        columnValues={columnValues}
                        showChevron={false}
                        checkBox={selectedLineOfCoverage === "Medical"}
                        checkList={selectedMedicalPlanIds}
                        showMore={more}
                        onClick={this._rowClicked}
                        onScroll={fnSort}
                        showAllChecked={selectedLineOfCoverage === "Medical"}
                        allChecked={allChecked}
                        onAllClick={this._allClicked}
                    />
                ) : (
                    <div className={css(styles.noResults)}>{loading ? "Loading " : "No Matching "}{countTitle}</div>
                )}
            </div>
        );
    }

    renderProducts = () => {
        const { carriers, states, planTypesFiltered, selectedProductState, selectedProductCarrier, newProduct } = this.state;

        //Each carrier has a list of which states they are available in (.area)
        const stMapped = this._getMappedState(selectedProductState.id);

        //filter carriers to selected state
        const filteredCarriers = carriers.length > 0 ? carriers.filter(c => c.area && c.area.indexOf(stMapped) > -1) : [];

        return (
            <div className={css(styles.contentSection)}>
                <div className={css(styles.instruction)}>Choose carrier details to view/edit product lines.</div>
                <div className={css(styles.row)}>
                    <div className={css(styles.column, styles.oneThird)}>
                        <div className={css(styles.row)}>
                            <Dropdown
                                aStyles={styles.dropdown}
                                options={states.map(item => ({
                                    label: item.name,
                                    value: item.id.toString()
                                }))}
                                value={selectedProductState.id ? selectedProductState.id.toString() : ""}
                                placeholder="State"
                                label="State"
                                onChange={this._changeProductState}
                                hideLabel={false}
                            />
                        </div>
                        <div className={css(styles.row)}>
                            <Dropdown
                                aStyles={styles.dropdown}
                                options={filteredCarriers.map(item => ({
                                    label: item.name,
                                    value: item.id.toString()
                                }))}
                                value={selectedProductCarrier.id ? selectedProductCarrier.id.toString() : ""}
                                placeholder="Carrier"
                                label="Carrier"
                                onChange={this._changeProductCarrier}
                                hideLabel={false}
                            />
                        </div>
                    </div>
                    <div className={css(styles.column, styles.twoThirds)}>
                        <div className={css(styles.row)}>
                            <div className={css(styles.productTitleSection)}>
                                <div className={css(styles.addPlan)} onClick={this._addProduct}>
                                    <span className={css(styles.addPlanText)}>{strings.addProduct}</span>
                                    <img alt="" src={blueCircleIcon} className={css(styles.blueCircle)} />
                                </div>
                                {selectedProductCarrier.logoPath ? (
                                    <img src={selectedProductCarrier.logoPath} border={0} width={200} alt="carrier" />
                                ) : null}
                            </div>
                        </div>
                        {newProduct.isEdit ? (
                            <div className={css(styles.product, styles.lightBlue)}>
                                <div className={css(styles.productLine, styles.productName)}>
                                    <TextInput
                                        name={newProduct.name}
                                        onChange={e => this._nameChanged(e, newProduct)}
                                        placeholder="Name"
                                        required={true}
                                        value={newProduct.name}
                                    />
                                </div>
                                <div className={css(styles.productLine)}>
                                    <TextInput
                                        name={newProduct.notes}
                                        onChange={e => this._notesChanged(e, newProduct)}
                                        placeholder="Notes"
                                        required={true}
                                        value={newProduct.notes}
                                    />
                                </div>
                                <div className={css(styles.editPlan)}>
                                    <span className={css(styles.editPlanLink)} onClick={() => this._applyEditProduct(newProduct)}>Save</span>
                                    <span className={css(styles.editPlanLink)} onClick={() => this._cancelEditProduct(newProduct)}>Cancel</span>
                                </div>
                            </div>
                        ) : null}
                        {planTypesFiltered.length > 0 ? (planTypesFiltered.map(pt => {
                            return (
                                <div key={pt.id} className={css(styles.product, pt.isEdit ? styles.lightBlue : "")}>
                                    {!pt.isEdit ? (
                                        <div className={css(styles.editPlan)} onClick={e => this._editProduct(e, pt)}>
                                            <img alt="" src={editIcon} />
                                        </div>
                                    ) : null}
                                    <div className={css(styles.productLine, styles.productName)}>
                                        {pt.isEdit ? (
                                            <TextInput
                                                name={pt.name}
                                                onChange={e => this._nameChanged(e, pt)}
                                                placeholder="Name"
                                                required={true}
                                                value={pt.name}
                                            />
                                        ) : (
                                            pt.name
                                        )}
                                    </div>
                                    <div className={css(styles.productLine)}>
                                        {pt.isEdit ? (
                                            <TextInput
                                                name={pt.notes}
                                                onChange={e => this._notesChanged(e, pt)}
                                                placeholder="Notes"
                                                required={true}
                                                value={pt.notes}
                                            />
                                        ) : (
                                            <span>NOTES: {pt.notes}</span>
                                        )}
                                    </div>
                                    {pt.isEdit ? (
                                        <div className={css(styles.editPlan)}>
                                            <span className={css(styles.editPlanLink)} onClick={() => this._applyEditProduct(pt)}>Apply</span>
                                            <span className={css(styles.editPlanLink)} onClick={() => this._cancelEditProduct(pt)}>Cancel</span>
                                            <span className={css(styles.editPlanLink)} onClick={() => this._deleteProduct(pt)}>Delete</span>
                                        </div>
                                    ) : null}
                                </div>
                                /*<div key={pt.id} className={css(styles.product)} onClick={() => this._handlePlanTypeEditClick(pt.id)}>
                                    <div className={css(styles.productLine, styles.productName)}>{pt.name}</div>
                                    <div className={css(styles.productLine)}>{pt.notes}</div>
                            </div>*/
                            )
                        })) : (
                            <div className={css(styles.noResults)}>No Matching Product Lines</div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    _getMappedState = (st) => {
        switch (st) {
            case "1":
                return "TX";
            case "2":
                return "IL";
            case "3":
                return "FL";
            case "4":
                return "IN";
            case "5":
                return "AZ";
            case "6":
                return "WI";
            case "7":
                return "MO";
            case "8":
                return "IA";
            case "9":
                return "KY";
            case "10":
                return "TN";
            case "11":
                return "NC";
            case "12":
                return "SC";
            case "13":
                return "VA";
            case "14":
                return "MD";
            case "15":
                return "PA";
            case "16":
                return "GA";
            case "17":
                return "NE";
            case "18":
                return "KS";
            case "19":
                return "CO";
            case "20":
                return "NV";
            case "21":
                return "NM";
            case "22":
                return "UT";
            case "23":
                return "MN";
            case "24":
                return "LA";
            case "25":
                return "MS";
            case "26":
                return "ND";
            case "27":
                return "SD";
            case "28":
                return "OH";
            case "29":
                return "WV";
            case "30":
                return "MI";
            case "31":
                return "OK";
            case "32":
                return "CA";
            case "33":
                return "CT";
            case "34":
                return "AK";
            case "35":
                return "AR";
            case "36":
                return "MA";
            case "37":
                return "ME";
            case "38":
                return "NH";
            case "39":
                return "NY";
            case "40":
                return "RI";
            case "41":
                return "VT";
            case "42":
                return "NJ";
            case "43":
                return "DE";
            case "44":
                return "DC";
            case "45":
                return "ID";
        }
        //shouldn't get here
        return "ZZ";
    };

    renderDisclaimers = () => {
        const { carriers, states, linesOfCoverage, disclaimersFiltered, selectedDisclaimerState, selectedDisclaimerCarrier, selectedDisclaimerLineOfCoverage, newDisclaimer, editorState } = this.state;

        //Each carrier has a list of which states they are available in (.area)
        const stMapped = this._getMappedState(selectedDisclaimerState.id);

        //filter carriers to selected state
        const filteredCarriers = carriers.length > 0 ? carriers.filter(c => c.area && c.area.indexOf(stMapped) > -1) : [];

        return (
            <div className={css(styles.contentSection)}>
                <div className={css(styles.instruction)}>Choose carrier details to view/edit disclaimers.</div>
                <div className={css(styles.row)}>
                    <div className={css(styles.column, styles.oneThird)}>
                        <div className={css(styles.row)}>
                            <Dropdown
                                aStyles={styles.dropdown}
                                options={states.map(item => ({
                                    label: item.name,
                                    value: item.id.toString()
                                }))}
                                value={selectedDisclaimerState.id ? selectedDisclaimerState.id.toString() : ""}
                                placeholder="State"
                                label="State"
                                onChange={this._changeDisclaimerState}
                                hideLabel={false}
                            />
                        </div>
                        <div className={css(styles.row)}>
                            <Dropdown
                                aStyles={styles.dropdown}
                                options={filteredCarriers.map(item => ({
                                    label: item.name,
                                    value: item.id.toString()
                                }))}
                                value={selectedDisclaimerCarrier.id ? selectedDisclaimerCarrier.id.toString() : ""}
                                placeholder="Carrier"
                                label="Carrier"
                                onChange={this._changeDisclaimerCarrier}
                                hideLabel={false}
                            />
                        </div>
                        <div className={css(styles.row)}>
                            <Dropdown
                                aStyles={styles.dropdown}
                                options={linesOfCoverage.map(item => ({
                                    label: item.name,
                                    value: item.name
                                }))}
                                value={selectedDisclaimerLineOfCoverage}
                                placeholder="Line Of Coverage"
                                label="Line of Coverage"
                                onChange={this._changeDisclaimerLineOfCoverage}
                                hideLabel={false}
                            />
                        </div>
                    </div>
                    <div className={css(styles.column, styles.twoThirds)}>
                        <div className={css(styles.row)}>
                            <div className={css(styles.productTitleSection)}>
                                <div className={css(styles.addPlan)} onClick={this._addDisclaimer}>
                                    <span className={css(styles.addPlanText)}>{strings.addDisclaimer}</span>
                                    <img alt="" src={blueCircleIcon} className={css(styles.blueCircle)} />
                                </div>
                                {selectedDisclaimerCarrier.logoPath ? (
                                    <img src={selectedDisclaimerCarrier.logoPath} border={0} width={200} alt="carrier" />
                                ) : null}
                            </div>
                        </div>
                        {newDisclaimer.isEdit ? (
                            <div className={css(styles.product, styles.lightBlue)}>
                                <div className={css(styles.productLine)}>
                                    <Editor
                                        editorState={editorState}
                                        wrapperClassName={css(styles.editorWrapper)}
                                        editorClassName={css(styles.editorEditor)}
                                        toolbarClassName={css(styles.editorToolbar)}
                                        onEditorStateChange={this.onEditorStateChange}
                                        toolbar={{ options: ['inline', 'fontFamily', 'fontSize', 'list', 'textAlign', 'remove', 'history'] }}
                                    />
                                    <textarea
                                        className={css(styles.editorTextarea)}
                                        disabled
                                        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                                    />
                                    {/*<TextInput
                                        name={newDisclaimer.notes}
                                        onChange={e => this._disclaimerChanged(e, newDisclaimer)}
                                        placeholder="Disclaimer"
                                        required={true}
                                        value={newDisclaimer.notes}
                                    />*/}
                                </div>
                                <div className={css(styles.editPlan)}>
                                    <span className={css(styles.editPlanLink)} onClick={() => this._applyEditDisclaimer(newDisclaimer)}>Save</span>
                                    <span className={css(styles.editPlanLink)} onClick={() => this._cancelEditDisclaimer(newDisclaimer)}>Cancel</span>
                                </div>
                            </div>
                        ) : null}
                        {disclaimersFiltered.length > 0 ? (disclaimersFiltered.map(pt => {
                            return (
                                <div key={pt.id} className={css(styles.product, pt.isEdit ? styles.lightBlue : "")}>
                                    {!pt.isEdit ? (
                                        <div className={css(styles.editPlan)} onClick={e => this._editDisclaimer(e, pt)}>
                                            <img alt="" src={editIcon} />
                                        </div>
                                    ) : null}
                                    {pt.isEdit ? (
                                        <div className={css(styles.productLine)}>
                                            <Editor
                                                editorState={editorState}
                                                wrapperClassName={css(styles.editorWrapper)}
                                                editorClassName={css(styles.editorEditor)}
                                                toolbarClassName={css(styles.editorToolbar)}
                                                onEditorStateChange={this.onEditorStateChange}
                                                toolbar={{ options: ['inline', 'fontFamily', 'fontSize', 'list', 'textAlign', 'remove', 'history'] }}
                                            />
                                            <textarea
                                                className={css(styles.editorTextarea)}
                                                disabled
                                                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                                            />
                                            {/*<TextInput
                                                name={pt.notes}
                                                onChange={e => this._disclaimerChanged(e, pt)}
                                                placeholder="Notes"
                                                required={true}
                                                value={pt.notes}
                                            />*/}
                                        </div>
                                    ) : (
                                        <div className={css(styles.productLine)}>
                                            <div
                                                className={css(styles.displayHtml)}
                                                dangerouslySetInnerHTML={{ __html: pt.notes }}
                                            />
                                        </div>
                                    )}
                                    {pt.isEdit ? (
                                        <div className={css(styles.editPlan)}>
                                            <span className={css(styles.editPlanLink)} onClick={() => this._applyEditDisclaimer(pt)}>Apply</span>
                                            <span className={css(styles.editPlanLink)} onClick={() => this._cancelEditDisclaimer(pt)}>Cancel</span>
                                            <span className={css(styles.editPlanLink)} onClick={() => this._deleteDisclaimer(pt)}>Delete</span>
                                        </div>
                                    ) : null}
                                </div>
                            )
                        })) : (
                            <div className={css(styles.noResults)}>No Matching Disclaimers</div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { showModalAge, showModalSBS, selectedLineOfCoverage, allPlans, selectedMedicalPlansAge, selectedMedicalPlansSBS, inputs, counties, contributionIntervals, carriers, selectedCarrier } = this.state;
        const { me } = this.props;
        return (
            <div className={css(styles.content)}>
                <ToastContainer position="top-center" autoClose={4000} />
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.titleSection)}>
                        {/*<div className={css(styles.addPlan)} onClick={this._goToAddPlan}>
                            <span className={css(styles.addPlanText)}>{strings.addPlan}</span>
                            <img alt="" src={blueCircleIcon} className={css(styles.blueCircle)} />
                        </div>*/}
                        <PageTitle>{strings.title}</PageTitle>
                    </div>
                    <div className={css(styles.plans)}>
                        {/*<div>
                            <SearchInput
                                aStyles={styles.searchBox}
                                search={searchApi.searchPlans}
                                onItemSelected={this._searchResultSelected}
                                placeholder={strings.searchPlaceholder}
                                hideLabel={true}
                            />
                        </div>*/}
                        {(me.isSysAdmin || me.isStateAdmin) ? (
                            <Tabs>
                                <div label="Plans">
                                    {this.renderPlans()}
                                </div>
                                <div label="Product Lines">
                                    {this.renderProducts()}
                                </div>
                                <div label="Disclaimers">
                                    {this.renderDisclaimers()}
                                </div>
                            </Tabs>
                        ) : (
                            <div label="Plans">
                                {this.renderPlans()}
                            </div>
                        )}
                    </div>
                </div>
                <Modal
                    show={showModalAge && selectedLineOfCoverage === "Medical"}
                    header="Age Rate Parameters"
                >
                    <div className={css(styles.modalContent)}>
                        <div className={css(styles.filtersHolder)}>
                            <table className={css(styles.table)}>
                                <tbody>
                                    <tr>
                                        <td className={css(styles.tableCellInstruction)} colSpan="6"><i>required</i></td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.tableCell)} colSpan="3" style={{ width: "50%" }}>
                                            <DatePicker
                                                tabIndex="1"
                                                name={inputNames.effectiveDate}
                                                onChange={this._handleEffectiveDateChanged}
                                                placeholder={strings.effectiveDatePlaceholder}
                                                validationMessage={this.state.errors[inputNames.effectiveDate]}
                                                value={inputs[inputNames.effectiveDate]}
                                            />
                                        </td>
                                        <td className={css(styles.tableCell)} colSpan="3" style={{ width: "50%" }}>
                                            <TextInput
                                                tabIndex="2"
                                                name={inputNames.postalCode}
                                                onChange={this._handleTextChange}
                                                placeholder={strings.zipPlaceholder}
                                                validationMessage={this.state.errors[inputNames.postalCode]}
                                                value={inputs[inputNames.postalCode]}
                                            />
                                            {counties.length > 1 &&
                                                <Dropdown
                                                    tabIndex="3"
                                                    name={inputNames.countyId}
                                                    options={counties.map(item => ({
                                                        label: item.name,
                                                        value: item.id.toString(),
                                                    }))}
                                                    onChange={this._handleCountyIdChanged}
                                                    placeholder={strings.countyPlaceholder}
                                                    validationMessage={this.state.errors[inputNames.countyId]}
                                                    value={inputs[inputNames.countyId]}
                                                />
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.tableCellInstruction)} colSpan="6"><i>optional</i></td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.tableCell)} colSpan="2" style={{ width: "33%" }}>
                                            <label className={css(styles.checkLabel)}>
                                                <Checkbox
                                                    tabIndex="4"
                                                    checked={inputs[inputNames.incContribution]}
                                                    aStyles={styles.check}
                                                    name={inputNames.incContribution}
                                                    onChange={this._handleContributionChange}
                                                />
                                                <span className={css(styles.labelText)}>Include Contributions</span>
                                            </label>
                                        </td>
                                        <td className={css(styles.tableCell)} style={{ width: "16%" }}>
                                            <TextInput
                                                tabIndex="5"
                                                name={inputNames.empContribution}
                                                onChange={this._handleTextChange}
                                                placeholder={strings.empContribPlaceholder}
                                                value={inputs[inputNames.empContribution]}
                                            />
                                        </td>
                                        <td className={css(styles.tableCell)} style={{ width: "16%" }}>
                                            <TextInput
                                                tabIndex="6"
                                                name={inputNames.depContribution}
                                                onChange={this._handleTextChange}
                                                placeholder={strings.depContribPlaceholder}
                                                value={inputs[inputNames.depContribution]}
                                            />
                                        </td>
                                        <td className={css(styles.tableCell)} colSpan="2" style={{ width: "33%" }}>
                                            <Dropdown
                                                tabIndex="7"
                                                name={inputNames.contributionInterval}
                                                options={contributionIntervals.map(item => ({
                                                    label: item.name,
                                                    value: item.name,
                                                }))}
                                                onChange={this._handleContributionIntervalChanged}
                                                placeholder={strings.contributionIntervalPlaceholder}
                                                value={inputs[inputNames.contributionInterval]}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.tableCell)} colSpan="4" style={{ width: "75%" }}>
                                            <Dropdown
                                                tabIndex="8"
                                                name={inputNames.baseMedicalPlanName}
                                                options={allPlans.map(item => ({
                                                    label: item.displayName,
                                                    value: item.displayName,
                                                }))}
                                                onChange={this._handleBasePlanChanged}
                                                placeholder={strings.basePlanPlaceholder}
                                                value={inputs[inputNames.baseMedicalPlanName]}
                                            />
                                        </td>
                                        <td className={css(styles.tableCell)} colSpan="2" style={{ width: "25%" }}>
                                            <Dropdown
                                                tabIndex="8"
                                                options={carriers.map(item => ({
                                                    label: item.name,
                                                    value: item.id.toString()
                                                }))}
                                                onChange={this._changeCarrier2}
                                                placeholder="Change Base Plan Carrier"
                                                value={selectedCarrier ? selectedCarrier : ""}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={css(styles.selectedPlansTitle)}><b>Selected Plans ({selectedMedicalPlansAge.length}):</b> <i>- template supports a maximum of 125 plans</i></div>
                        <div className={css(styles.selectedPlansHolder)}>
                            <table className={css(styles.table)}>
                                <tbody>
                                    {selectedMedicalPlansAge.map(plan => (
                                        <tr key={plan.id}>
                                            <td className={css(styles.tableCell)}>
                                                <Checkbox
                                                    tabIndex="9"
                                                    checked={true}
                                                    aStyles={styles.check}
                                                    name={"selected_" + plan.id}
                                                    onChange={e => this._deselectPlanAge(e, plan)}
                                                />
                                            </td>
                                            <td className={css(styles.tableCell)}>{plan.carrierName}</td>
                                            <td className={css(styles.tableCell)}>{plan.displayName}</td>
                                            <td className={css(styles.tableCell)}>{plan.year}</td>
                                            <td className={css(styles.tableCell)}>{plan.planTypeName}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className={css(styles.filtersHolder)}>
                            <table className={css(styles.table)}>
                                <tbody>
                                    <tr>
                                        <td className={css(styles.tableCellInstruction)}><i>Plans sorted by age rate:</i></td>
                                    </tr>
                                    <tr>
                                        <td className={css(styles.tableCell, styles.toggle)}>
                                            <Toggle
                                                defaultChecked={inputs[inputNames.reverseSort]}
                                                onChange={this._handleSortChanged}
                                                checkedLabel="Highest to Lowest"
                                                uncheckedLabel="Lowest to Highest"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={css(styles.modalButtonContainer)}>
                            <ActionButton
                                tabIndex="999"
                                aStyles={styles.modalButton}
                                label="Cancel"
                                // invertColor={true}
                                type="button"
                                onClick={this._handleCancelModal}
                            />
                            <ActionButton
                                tabIndex="1000"
                                aStyles={styles.modalButton}
                                label="Generate"
                                onClick={this._handleGenerateAgeRates}
                                type="button"
                                isLoading={this.state.loading}
                            />
                        </div>
                    </div>
                </Modal>
                <Modal
                    show={showModalSBS && selectedLineOfCoverage === "Medical"}
                    header="Side By Side Benefits"
                >
                    <div className={css(styles.modalContent)}>
                        <div className={css(styles.selectedPlansTitle)}><b>Selected Plans ({selectedMedicalPlansSBS.length}):</b> <i>- template supports a maximum of 125 plans</i></div>
                        <div className={css(styles.selectedPlansHolder)}>
                            <table className={css(styles.table)}>
                                <tbody>
                                    {selectedMedicalPlansSBS.map(plan => (
                                        <tr key={plan.id}>
                                            <td className={css(styles.tableCell)}>
                                                <Checkbox
                                                    tabIndex="9"
                                                    checked={true}
                                                    aStyles={styles.check}
                                                    name={"selected_" + plan.id}
                                                    onChange={e => this._deselectPlanSBS(e, plan)}
                                                />
                                            </td>
                                            <td className={css(styles.tableCell)}>{plan.carrierName}</td>
                                            <td className={css(styles.tableCell)}>{plan.displayName}</td>
                                            <td className={css(styles.tableCell)}>{plan.year}</td>
                                            <td className={css(styles.tableCell)}>{plan.planTypeName}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className={css(styles.modalButtonContainer)}>
                            <ActionButton
                                tabIndex="999"
                                aStyles={styles.modalButton}
                                label="Cancel"
                                // invertColor={true}
                                type="button"
                                onClick={this._handleCancelModal}
                            />
                            <ActionButton
                                tabIndex="1000"
                                aStyles={styles.modalButton}
                                label="Generate"
                                onClick={this._handleGenerateSideBySides}
                                type="button"
                                isLoading={this.state.loading}
                            />
                        </div>
                    </div>
                </Modal>

            </div>
        );
    }
}
const mapDispatchToProps = state => ({
    me: state.session.me,
});

export default connect(mapDispatchToProps)(PlansScreen);
