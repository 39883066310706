import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import * as color from "../../constants/color";

const styles = StyleSheet.create({
    enrollmentStepsContainer: {
        padding: 24,
        color: color.darkestGray,
        display: "flex",
        justifyContent: "center",
    },
    stepItem: {
        display: "flex",
        alignItems: "center",
        marginRight: 8,
        marginLeft: 8,
        cursor: "pointer",
    },
    stepLabel: {
        fontSize: 12,
        marginLeft: 6,
        maxWidth: 100,
    },
    whiteCheck: {
        display: "inline-block",
        transform: "rotate(45deg)",
        width: 7,
        height: 13,
        borderBottom: `2px solid ${color.white}`,
        borderRight: `2px solid ${color.white}`,

    },
    stepNumberContainer: {
        border: `2px solid ${color.mediumRed}`,
        height: 24,
        width: 24,
        textAlign: "center",
        borderRadius: "50%",
        lineHeight: "22px",
        color: color.mediumRed,
    },
    stepNumber: {
        fontSize: 16,
        fontWeight: 500,
        textAlign: "center",
        verticalAlign: "middle",
        height: 24,
    },
    completedStep: {
        backgroundColor: color.mediumRed,
    },
    activeStep: {
        backgroundColor: color.mediumRed,
        color: color.white,
    },
});

const steps = [
    "Company Info",
    "Census",
    "Lines Of Coverage",
    "Notifications",
    "Billing Contact",
];

export default class EnrollmentSteps extends Component {
    static propTypes = {
        step: PropTypes.number.isRequired,
        stepClicked: PropTypes.func.isRequired,
    };

    _renderStepItem = stepNumber => {
        const { step } = this.props;

        if (step > stepNumber) {
            return (
                <div className={css(styles.stepNumberContainer, styles.completedStep)}>
                    <div className={css(styles.whiteCheck)} />
                </div>
            );
        } else {
            return (
                <div className={css(
                    styles.stepNumberContainer,
                    step === stepNumber ? styles.activeStep : null,
                )}>
                    <div className={css(styles.stepNumber)}>{stepNumber + 1}</div>
                </div>
            );
        }
    };

    _goToStep = index => {
        this.props.stepClicked(index);
    }

    render() {
        return (
            <div className={css(styles.enrollmentStepsContainer)}>
                {steps.map((stepName, index) => (
                    <div className={css(styles.stepItem)} key={index} onClick={e => this._goToStep(index)}>
                        {this._renderStepItem(index)}
                        <div className={css(styles.stepLabel)}>
                            {stepName}
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}