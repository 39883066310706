import * as actionTypes from "../constants/actionTypes";

export function setSpreadsheetType(spreadsheetType) {
    return {
        type: actionTypes.PROPOSAL_SET_SPREADSHEET_TYPE,
        spreadsheetType,
    };
}

export function setQuote(quote) {
    return {
        type: actionTypes.PROPOSAL_SET_QUOTE,
        quote,
    };
}

export function setSelectedPlans(selectedPlans, selectedNewPlanIds, coverageType) {
    return {
        type: actionTypes.PROPOSAL_SET_SELECTED_PLANS,
        selectedPlans,
        selectedNewPlanIds,
        coverageType,
    };
}

export function setFavoritesApplied(favoritesApplied) {
    return {
        type: actionTypes.PROPOSAL_SET_FAVORITES_APPLIED,
        favoritesApplied,
    };
}

export function setSelectedBasePlans(selectedPlans) {
    return {
        type: actionTypes.PROPOSAL_SET_SELECTED_BASE_PLANS,
        selectedPlans,
    };
}

export function setSpreadsheets(spreadsheets) {
    return {
        type: actionTypes.PROPOSAL_SET_SPREADSHEETS,
        spreadsheets,
    };
}

export function setCarriers(carriers) {
    return {
        type: actionTypes.PROPOSAL_SET_CARRIERS,
        carriers,
    };
}

export function setPlanTypes(planTypes) {
    return {
        type: actionTypes.PROPOSAL_SET_PLANTYPES,
        planTypes,
    };
}

export function setNetworks(networks) {
    return {
        type: actionTypes.PROPOSAL_SET_NETWORKS,
        networks,
    };
}

export function setMinMaxFilters(minMaxFilters) {
    return {
        type: actionTypes.PROPOSAL_SET_MIN_MAX_FILTERS,
        minMaxFilters,
    }
}

export function setPlanTypesD(planTypesD) {
    return {
        type: actionTypes.PROPOSAL_SET_PLANTYPESD,
        planTypesD,
    };
}

export function setCoinsurancesD(coinsurancesD) {
    return {
        type: actionTypes.PROPOSAL_SET_COINSURANCESD,
        coinsurancesD,
    };
}

export function setDeductiblesD(deductiblesD) {
    return {
        type: actionTypes.PROPOSAL_SET_DEDUCTIBLESD,
        deductiblesD,
    };
}

export function setPerioEndosD(perioEndosD) {
    return {
        type: actionTypes.PROPOSAL_SET_PERIOENDOSD,
        perioEndosD,
    };
}

export function setAnnualMaximumsD(annualMaximumsD) {
    return {
        type: actionTypes.PROPOSAL_SET_ANNUALMAXIMUMSD,
        annualMaximumsD,
    };
}

export function setOrthoMaximumsD(orthoMaximumsD) {
    return {
        type: actionTypes.PROPOSAL_SET_ORTHOMAXIMUMSD,
        orthoMaximumsD,
    };
}

export function setReimbursementsD(reimbursementsD) {
    return {
        type: actionTypes.PROPOSAL_SET_REIMBURSEMENTSD,
        reimbursementsD,
    };
}

export function setExamCopaysV(examCopaysV) {
    return {
        type: actionTypes.PROPOSAL_SET_EXAMCOPAYSV,
        examCopaysV,
    };
}

export function setFundingTypesV(fundingTypesV) {
    return {
        type: actionTypes.PROPOSAL_SET_FUNDINGTYPESV,
        fundingTypesV,
    };
}

export function setMaterialCopaysV(materialCopaysV) {
    return {
        type: actionTypes.PROPOSAL_SET_MATERIALCOPAYSV,
        materialCopaysV,
    };
}

export function setFrameAllowancesV(frameAllowancesV) {
    return {
        type: actionTypes.PROPOSAL_SET_FRAMEALLOWANCESV,
        frameAllowancesV,
    };
}

export function setContactLensAllowancesV(contactLensAllowancesV) {
    return {
        type: actionTypes.PROPOSAL_SET_CONTACTLENSALLOWANCESV,
        contactLensAllowancesV,
    };
}

export function setFrameFrequenciesV(frameFrequenciesV) {
    return {
        type: actionTypes.PROPOSAL_SET_FRAMEFREQUENCIESV,
        frameFrequenciesV,
    };
}

export function setFavoriteLists(favoriteLists) {
    return {
        type: actionTypes.PROPOSAL_SET_FAVORITE_LISTS,
        favoriteLists,
    };
}

export function setFavoritePlanList(favoritePlans, favoritePlanMode) {
    return {
        type: actionTypes.PROPOSAL_SET_FAVORITE_PLAN_LIST,
        favoritePlans,
        favoritePlanMode,
    };
}
