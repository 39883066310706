import * as color from "../../constants/color";
import * as texchangePropTypes from "../../texchangePropTypes";
import { Container, Row, Column } from "../grid";
import PageTitle from "../text/PageTitle";
import Headline from "../text/Headline";
import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { ToastContainer, toast } from 'react-toastify';
import InlineEdit from "../inputs/InlineEdit";
import TextInput from "../inputs/TextInput";
import TextArea from "../inputs/TextArea";
import ActionButton from "../ActionButton";
import FileBrowser from "../inputs/FileBrowser";
import describeNetworkError from "../../lib/describeNetworkError";
import Modal from "../Modal";
import logger from "../../lib/logger";
import * as util from "../../lib/util";
import { ReactComponent as EditIcon } from "../../images/SVGs/edit-field.svg";
import * as accountApi from "../../lib/api/account";
import * as brokersApi from "../../lib/api/brokers";

const styles = StyleSheet.create({
    content: {
        paddingRight: 10,        
        paddingBottom: 80,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
    },
    contentSection: {
        width: "100%",
        padding: 0,
        backgroundColor: color.white,
        borderTop: `8px solid ${color.mediumRed}`,
        paddingBottom: 20,
    },
    columns: {
        padding: 15,
        backgroundColor: color.white,
        paddingLeft: 60,        
        paddingRight: 60,
    },
    title: {
        fontSize: 20,
        fontWeight: 500,        
        paddingBottom: 0,
        marginBottom: 16,
    },
    disclaimerTitle: {
        fontSize: 20,
        fontWeight: 500,        
        paddingBottom: 0,
        marginBottom: 16,  
        marginTop: 36,
    },    
    titleSection: {
        heigth: 64,
    },
    leftColumn: {
        paddingRight: 24,
    },    
    centerColumn: {      
        paddingRight: 16,
        paddingLeft: 16,
    },
    rightColumn: {
          paddingLeft: 24,
    },
    container: {
        borderBottom: `1px solid ${color.darkGray6}`,
        paddingBottom: 12,
        marginBottom: 12,        
        height: 60,
    },
    displayRow: {
        marginTop: 6,
        marginBottom: 6,
        minHeight: 18,
    },
    headerLabel: {
        float: "left",
        fontSize: 14,
        fontWeight: 500,
        color: color.darkGray,
    },
    displayContainer: {
        clear: "both",
        color: color.darkestGray,
        fontSize: 16,
        paddingTop: 10,
    },
    editIcon: {
        float: "right",
        width: 18,
        height: 18,
        fill: color.darkBlue2,
    },
    modalContent: {
        width: 386,
    },
    modalInstructions: {
        color: color.darkestGray,
        fontSize: 14,
        marginBottom: 8,
        textAlign: "left",
        lineHeight: "18px",
    },
    modalButtonContainer: {
        marginTop: 24,
        display: "flex",
        justifyContent: "space-around",
    },
    modalButton: {
        width: 150,
    },
    disclaimerDisplayRow: {
        paddingTop: 6,
    },
    disclaimerDisplay: {
        backgroundColor: color.lightGray,
        border: `1px solid ${color.darkGray4}`,
        borderRadius: 4,
        padding: "24px 18px",
        fontSize: 14,
        color: color.darkestGray,        
    },
    logoContainer: {
        backgroundColor: color.lightGray,
        border: `1px solid ${color.darkGray4}`,
        borderRadius: 4,
        padding: "24px 18px",
        fontSize: 20,
        color: color.darkestGray,        
        textAlign: "center",        
    },
    brokerLogo: {
        display: "block",
        maxHeight: 300,
        maxWidth: 620,
    },
    uploadLogo: {
        textAlign: "right",
        marginBottom: 8,
    },
    deleteLogo: {
        cursor: "pointer",
        color: color.mediumYellow1,
    },
    divider: {
        color: color.darkGray,
        paddingLeft: 12,
        paddingRight: 12,
    }
});

const strings = {
    title: "My Account",
    profile: "Profile",
    name: "Name",
    email: "Email",
    password: "Password",   
    requiresMFA: "Requires MFA", 
    phone: "Phone (Needed for SMS)",
    license: "License #",
    npn: "NPN #",
    agencyName: "Agency Name",
    agencyAddress: "Agency Address",
    passwordEmpty: "Password fields cannot be empty",
    passwordMustNotMatch: "New password must not match the current",
    passwordLength: "Password must be at least 8 characters",
    passwordDigit: "Password must include a number (0-9)",
    passwordUpper: "Password must include an upper case",
    passwordLower: "Password must include a lower case",
    passwordNonAlpha: "Password must include a non alphanumeric",
    success: "Your password has been successfully reset.",
};

const inputNames = {
    search: "search",
    currentPassword: "currentPassword",
    password: "password",
    phone: "phone",
    verification: "verification",
    nationalProducerNumber: "nationalProducerNumber",
    license: "license",
    disclaimer: "disclaimer",
};

export default class UserDashboardScreen extends Component {
    static propTypes = {
        history: texchangePropTypes.history.isRequired,
    };

    state = {
        errors: {},
        loading: false,
        inputs: {
            [inputNames.search]: "",
            [inputNames.currentPassword]: "",
            [inputNames.password]: "",
            [inputNames.phone]: "",
            [inputNames.verification]: "",
            [inputNames.nationalProducerNumber] : "",
            [inputNames.license] : "",
            [inputNames.disclaimer] : "",
        },
        broker: {},
        states: [],
        account: [],
        currentPassword: "",
        password: "",
        showPasswordModal: false,
        showVerifyPhone: false,
    };

    componentDidMount() {
        accountApi.me().then(result => {
            //update state
            this.setState(prevState => {
                return {
                    inputs: {
                        ...prevState.inputs,
                        [inputNames.phone]: result.phone,
                    },
                    account: result,
                };
            });
            if (result.isBroker) {
                brokersApi.getById(result.brokerId).then(result => {
                    this._brokerLoaded(result);
                });
            }            
        });
    }

    _handleTextChange = e => {        
        const { name, value } = e.target;
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
            };
        });
    };

    _updatePassword = e => {
        e.preventDefault();
        if (this.state.loading) {
            return;
        }

        const currentPassword = this.state.inputs[inputNames.currentPassword];
        const password = this.state.inputs[inputNames.password];

        //empty
        if (currentPassword.length < 1) {
            this.setState({
                errors: {
                    [inputNames.currentPassword]: [strings.passwordEmpty],
                },
            });

            return;
        }
        if (password.length < 1) {
            this.setState({
                errors: {
                    [inputNames.password]: [strings.passwordEmpty],
                },
            });
    
            return;
        }

        //NOT equal
        if (currentPassword === password) {
            this.setState({
                errors: {
                    [inputNames.password]: [strings.passwordMustNotMatch],
                },
            });

            return;
        }

        //check for length, format
        /*
        options.Password.RequiredLength = 8;
        options.Password.RequireLowercase = true;
        options.Password.RequireUppercase = true;
        options.Password.RequireDigit = true;
        options.Password.RequireNonAlphanumeric = true;
        */

        //length
        if (currentPassword.length < 8) {
            this.setState({
                errors: {
                    [inputNames.currentPassword]: [strings.passwordLength],
                },
            });

            return;
        }
        if (password.length < 8) {
            this.setState({
                errors: {
                    [inputNames.password]: [strings.passwordLength],
                },
            });

            return;
        }
        //lower
        let regEx = new RegExp("^(.*[a-z].*)$","g");
        if (!regEx.test(password)) {
            this.setState({
                errors: {
                    [inputNames.password]: [strings.passwordLower],
                },
            });

            return;
        }
        //upper
        regEx = new RegExp("^(.*[A-Z].*)$","g");
        if (!regEx.test(password)) {
            this.setState({
                errors: {
                    [inputNames.password]: [strings.passwordUpper],
                },
            });

            return;
        }
        //digit
        regEx = new RegExp("^(.*[0-9].*)$","g");
        if (!regEx.test(password)) {
            this.setState({
                errors: {
                    [inputNames.password]: [strings.passwordDigit],
                },
            });

            return;
        }
        //non alphanumeric
        regEx = new RegExp("^(.*[\\W].*)$","g");
        if (!regEx.test(password)) {
            this.setState({
                errors: {
                    [inputNames.password]: [strings.passwordNonAlpha],
                },
            });

            return;
        }

        //passed
        this.setState({
            loading: true,
            errors: {},
        });

        accountApi
            .updatePassword(currentPassword, password)
            .then(response => {
                if (response === "succeeded") {
                    toast.success(strings.success, { toastId: 1 });
                    this.setState({
                        showPasswordModal: false,
                    });
                }
                else {
                    toast.error(response, { toastId: 2 });
                    this.setState({
                        loading: false,
                    });
                }
            })
            .catch(err => {
                logger.warn(err);
                if (!this._mounted) {
                    return;
                }

                let errors = {};
                if (err && err.response) {
                    const { response } = err;
                    if (
                        response &&
                        response.data &&
                        response.data.errors &&
                        Object.keys(response.data.errors).length > 0
                    ) {
                        // Server provided validation messages
                        errors = response.data.errors;
                    } else {
                        // 500 or something else unexpected
                        const description = describeNetworkError(err);
                        toast.error(description.join("\n"), { toastId: 3 });
                    }
                }

                this.setState({
                    loading: false,
                    errors: errors,
                });
            });
    };

    _updatePhone = () => {
        const accountSaved = new Promise(resolve => {
            const { account } = this.state;

            let phone = this.state.inputs[inputNames.phone];
            phone = phone.replace(/[^0-9]+/g,"");
            //needs to be 10 digits
            if (!phone || phone.length !== 10) {
                this.setState({
                    errors: {
                        [inputNames.phone]: "Must be 10 digits",
                    },
                });
                return;
            }

            let code = this.state.inputs[inputNames.verification];
            code = code.replace(/[^0-9]+/g,"");
            //needs to be 6 digits
            if (code && code.length !== 6) {
                this.setState({
                    errors: {
                        [inputNames.code]: "Must be 6 digits",
                    },
                });
                return;
            }

            accountApi.updatePhone(account.id, phone, code)
                .then((response => {
                    if (response === "succeeded") {
                        toast.success("Phone number verified", { toastId: 1 });
                        account.phoneVerified = true;
                        this.setState({ showVerifyPhone: false });
                        resolve(true);
                    }
                    else if (response === "verification") {
                        toast.success("A verification code has been sent to this number.  Please enter the code on this form to verify ownership of this numnber.", { toastId: 2 });
                        this.setState({ showVerifyPhone: true });
                        resolve(false);
                    }
                    else {
                        toast.success("Failed to update phone number.", { toastId: 3 });
                        resolve(true);
                    }
                }))
                .catch(err => {         
                    const { response } = err;
                    if (response && response.status === 400 && response.data.errors) {
                        const errors = [];
                        const validationErrors = response.data.errors;
                        let messages = "";
                        Object.keys(validationErrors).forEach(key => {
                            errors[key] = "required";
                            messages += validationErrors[key] + "\n";
                        });

                        this.setState({
                            errors,
                        });
                        toast.error(messages, { toastId: 1 });
                        resolve(false);
                    }
                });
        });

        return accountSaved;
    };

    _handleTextChange = e => {
        const { name } = e.target;
        let { value } = e.target;
        
        if (name === inputNames.phone) {
            value = util.maskPhoneNumber(value);
        }         
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
                errors,
            };
        });
    };

    _handleOnSave = () => {
        const brokerSaved = new Promise(resolve => {
            const { broker } = this.state;

            Object.keys(inputNames).forEach(key => {
                broker[key] = this.state.inputs[key];
            });   

            brokersApi.saveBroker(broker)
                .then((response => {                
                    this._brokerLoaded(response);
                    resolve(true);
                }))
                .catch(err => {         
                    const { response } = err;
                    if (response && response.status === 400 && response.data.errors) {
                        const errors = [];
                        const validationErrors = response.data.errors;
                        let messages = "";
                        Object.keys(validationErrors).forEach(key => {
                            errors[key] = "required";
                            messages += validationErrors[key] + "\n";
                        });

                        this.setState({
                            errors,
                        });
                        toast.error(messages, { toastId: 1 });
                        resolve(false);
                    }
                });
        });

        return brokerSaved;
    };

    _handleOnCancel = () => {
        const { broker } = this.state;
        this._brokerLoaded(broker);
    };

    _brokerLoaded = broker => {
        const { account } = this.state;
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,                    
                    [inputNames.license]: broker.license,
                    [inputNames.nationalProducerNumber]: broker.nationalProducerNumber,
                    [inputNames.phone]: account.phone ? account.phone : broker.phone,
                    [inputNames.disclaimer]: broker.disclaimer,
                },
                broker,
            };
        });
    }

    _logoSelected = file => {
        brokersApi.uploadLogo(this.state.broker.id, file)
            .then(response => {
                this.setState({ broker: response });
            });                
    }

    _deleteLogo = () => {
        brokersApi.deleteLogo(this.state.broker.id)
            .then((response) => {
                this.setState({ broker: response });
            });
    }

    render() {
        const { account, loading, broker, showVerifyPhone, showPasswordModal } = this.state;
        const logoUrl = process.env.REACT_APP_API_ROOT + "/logo/getBrokerLogo/" + this.state.broker.id + "?" + new Date().getTime();
        
        return (
            <div className={css(styles.content)}>
                <ToastContainer position="top-center" autoClose={2500} />
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.titleSection)}>
                        <PageTitle>{strings.title}</PageTitle>
                    </div>
                </div>
                <div className={css(styles.columns)}>
                    <Container>
                        <Row>
                            <Column large={6}>
                                <div className={css(styles.leftColumn)}>
                                    <Headline aStyles={styles.title}>{strings.profile}</Headline>
                                    <div className={css(styles.container)}>
                                        <div className={css(styles.headerLabel)}>
                                            {strings.name}
                                        </div>
                                        <div className={css(styles.displayContainer)}>
                                            {account.fullName}
                                        </div>
                                    </div>
                                    <div className={css(styles.container)}>
                                        <div className={css(styles.headerLabel)}>
                                            {strings.email}
                                        </div>
                                        <div className={css(styles.displayContainer)}>
                                            {account.email}
                                        </div>
                                    </div>
                                    <div className={css(styles.container)}>
                                        <div className={css(styles.headerLabel)}>
                                            {strings.requiresMFA}
                                        </div>
                                        <div className={css(styles.displayContainer)}>
                                            {account.requiresMFA ? "Yes" : "No"}
                                        </div>
                                    </div>
                                    <InlineEdit 
                                        headerLabel={strings.phone}
                                        onSave={this._updatePhone}
                                        onCancel={this._handleOnCancel}
                                    >
                                        <div edit>
                                            <TextInput
                                                name={inputNames.phone}
                                                onChange={this._handleTextChange}
                                                placeholder="Phone"
                                                validationMessage={this.state.errors[inputNames.phone]}
                                                value={this.state.inputs[inputNames.phone]}
                                            />
                                        </div>
                                        <div display>
                                            <div className={css(styles.displayRow)}>
                                                {this.state.inputs[inputNames.phone]}{" "}{account.phoneVerified ? " (verified)" : ""}
                                            </div>
                                        </div>
                                    </InlineEdit>
                                    {showVerifyPhone &&
                                        <TextInput
                                            name={inputNames.verification}
                                            onChange={this._handleTextChange}
                                            placeholder="Verification Code"
                                            validationMessage={this.state.errors[inputNames.verification]}
                                            value={this.state.inputs[inputNames.verification]}
                                        />
                                    }
                                    {account.isBroker && account.brokerId > 0 ? (                                        
                                        <React.Fragment>
                                            {broker.brokerAgency ? (
                                                <React.Fragment>
                                                    <div className={css(styles.container)}>
                                                        <div className={css(styles.headerLabel)}>
                                                            {strings.agencyName}
                                                        </div>
                                                        <div className={css(styles.displayContainer)}>
                                                            {broker.brokerAgency.name}
                                                        </div>
                                                    </div>
                                                    <div className={css(styles.container)}>
                                                        <div className={css(styles.headerLabel)}>
                                                            {strings.agencyAddress}
                                                        </div>
                                                        <div className={css(styles.displayContainer)}>
                                                            {broker.brokerAgency.street1}{broker.brokerAgency.city}, {broker.brokerAgency.state}, {broker.brokerAgency.postalCode}
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            ) : null}
                                            <InlineEdit 
                                                headerLabel="License #"
                                                onSave={this._handleOnSave}
                                                onCancel={this._handleOnCancel}
                                            >
                                                <div edit>
                                                    <TextInput
                                                        name={inputNames.license}
                                                        onChange={this._handleTextChange}
                                                        placeholder="License #"
                                                        validationMessage={this.state.errors[inputNames.license]}
                                                        value={this.state.inputs[inputNames.license]}
                                                    />
                                                </div>
                                                <div display>
                                                    <div className={css(styles.displayRow)}>
                                                        {this.state.inputs[inputNames.license]}
                                                    </div>
                                                </div>
                                            </InlineEdit>
                                            <InlineEdit 
                                                headerLabel="NPN #"
                                                onSave={this._handleOnSave}
                                                onCancel={this._handleOnCancel}
                                            >
                                                <div edit>
                                                    <TextInput
                                                        name={inputNames.nationalProducerNumber}
                                                        onChange={this._handleTextChange}
                                                        placeholder="NPN #"
                                                        validationMessage={this.state.errors[inputNames.nationalProducerNumber]}
                                                        value={this.state.inputs[inputNames.nationalProducerNumber]}
                                                    />
                                                </div>
                                                <div display>
                                                    <div className={css(styles.displayRow)}>
                                                        {this.state.inputs[inputNames.nationalProducerNumber]}
                                                    </div>
                                                </div>
                                            </InlineEdit>                                
                                        </React.Fragment>
                                    ) : null}
                                    <div className={css(styles.container)}>
                                        <div className={css(styles.headerLabel)}>
                                            {strings.password}
                                        </div>
                                        <div className={css(styles.displayContainer)} onClick={() => this.setState({ showPasswordModal: true})}>
                                            <EditIcon className={css(styles.editIcon)} />
                                            **********
                                        </div>
                                    </div>
                                </div>
                            </Column>
                            <Column large={6}>
                                <React.Fragment>
                                    {account.isBroker && account.brokerId > 0 ? (
                                        <div className={css(styles.rightColumn)}>         
                                            <Headline aStyles={styles.title}>My Logo</Headline>                                        
                                                {!this.state.broker.hasLogo ? (
                                                    <div className={css(styles.container)}>
                                                        <div className={css(styles.displayContainer)}>
                                                            <div className={css(styles.logoContainer)}>
                                                                Drop your logo here or <FileBrowser onSelect={this._logoSelected} /> for image.
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <React.Fragment>
                                                        <div className={css(styles.uploadLogo)}>
                                                            <span className={css(styles.deleteLogo)} onClick={this._deleteLogo}>Delete</span>
                                                            <span className={css(styles.divider)}>|</span>
                                                            <FileBrowser onSelect={this._logoSelected} />
                                                        </div>
                                                        <img 
                                                            className={css(styles.brokerLogo)}
                                                            src={logoUrl}
                                                            alt="Logo"
                                                        />
                                                    </React.Fragment>
                                                )}
                                            <Headline aStyles={styles.disclaimerTitle}>Disclaimer</Headline>
                                            <InlineEdit 
                                                    headerLabel=""
                                                    onSave={this._handleOnSave}
                                                    onCancel={this._handleOnCancel}
                                                >
                                                    <div edit>
                                                        <TextArea 
                                                            name={inputNames.disclaimer}
                                                            onChange={this._handleTextChange}
                                                            placeholder="Type or paste your disclaimer here..."
                                                            validationMessage={this.state.errors[inputNames.disclaimer]}
                                                            value={this.state.inputs[inputNames.disclaimer]}
                                                       />                                                                       
                                                    </div>
                                                    <div display>
                                                        <div className={css(styles.displayRow, styles.disclaimerDisplayRow)}>
                                                            {this.state.inputs[inputNames.disclaimer] ? (                                                            
                                                                <div className={css(styles.disclaimerDisplay)}>
                                                                    {this.state.inputs[inputNames.disclaimer]}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </InlineEdit>
                                        </div>
                                    ) : null}
                                </React.Fragment>
                            </Column>
                        </Row>
                    </Container>
                </div>

                <Modal 
                    show={showPasswordModal}
                    header="Reset Password"
                >
                    <div className={css(styles.modalContent)}>
                        <div>
                            <TextInput
                                name={inputNames.currentPassword}
                                onChange={this._handleTextChange}
                                placeholder="Current Password"
                                required={true}
                                type="password"
                                validationMessage={this.state.errors[inputNames.currentPassword]}
                                value={this.state.inputs[inputNames.currentPassword]}
                            />
                        </div>
                        <div>
                            <TextInput
                                name={inputNames.password}
                                onChange={this._handleTextChange}
                                placeholder="New Password"
                                required={true}
                                type="password"
                                validationMessage={this.state.errors[inputNames.password]}
                                value={this.state.inputs[inputNames.password]}
                            />
                        </div>
                        <div className={css(styles.modalInstructions)}>
                            <ul>
                                <li>Use 8 or more characters</li>
                                <li>Use upper and lowercase letters (e.g. A a)</li>
                                <li>Use a number (e.g. 1234)</li>
                                <li>Use a symbol (e.g. #@$!)</li>
                            </ul>
                        </div>                        
                    </div>
                    <div className={css(styles.modalButtonContainer)}>
                        <ActionButton
                            aStyles={styles.modalButton}
                            label="Close"
                            // invertColor={true}
                            type="button"                            
                            onClick={() => this.setState({ showPasswordModal: false})}
                        />                        
                        <ActionButton                                    
                            aStyles={styles.modalButton}                        
                            label="Save"
                            onClick={this._updatePassword}
                            disabled={loading}
                            type="button"                            
                        />
                    </div>
                </Modal>                                  
            </div>
        );
    }
}
