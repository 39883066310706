import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import * as color from "../../constants/color";
import * as proposalActions from "../../actions/proposalActions";
import Checkbox from "../inputs/Checkbox";
import ActionButton from "../ActionButton";

const styles = StyleSheet.create({
    typeHeader: {
        fontWeight: 300,
        fontSize: 20,
        marginTop: 40,
        marginBottom: 60,
        marginLeft: 30,
    },
    automaticSpeadsheetContainer: {
        display: "flex",
        justifyContent: "space-around",
    },
    automaticOptionContainer: {
        width: "20%",
        borderRadius: 8,
        backgroundColor: color.lightGray,
        padding: "12px 24px",
    },
    automaticOptionText: {
        fontSize: 16,
        fontWeight: 500,
        color: color.darkestGray,
    },
    savedSpreadsheetsContainer: {
        width: "25%",
        borderRadius: 4,
        border: `1px solid ${color.darkGray2}`,
        padding: "12px 24px",
    },
    savedSpreadsheetsHeader: {
        paddingBottom: 6,
        borderBottom: `2px solid ${color.mediumRed}`,
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 24,
    },
    checkboxContainer: {
        marginTop: 18,
    },
    checkLabel: {
        display: "block",
        position: "relative",
        border: "none",
        marginTop: 18,
        marginBottom: 18,
        "-webkit-appearance": "none",
        cursor: "pointer",
    },
    check: {
        position: "absolute",
        top: -2,
        left: 0,
        marginRight: 10,
    },
    labelText: {
        marginLeft: 30,
        fontSize: 16,
    },
    buttonContainer: {
        height: 80,
        margin: "24px auto 0px auto",
        textAlign: "center",
        marginTop: 60,
    },
    saveButton: {
        width: 200,
    },
    backButon: {
        marginRight: 32,
        width: 200,
    },
    noSavedSpreadsheets: {
        color: color.darkGray,
        fontSize: 16,
    },
});

const strings = {
	automaticHeaderText: "Make selections below and make as many spreadsheets as you like.",	
	saveButtonText: "Save",
	backButtonText: "Back",
	noSavedSpreadsheetsText: "You have no saved spreadsheets.",
};

const automaticOptions = {
	benefits: [
		"Basic",
		"Full"
	],
	include: [
		"Employee Rate",
		"Current",
		"Renewals",	
	],
	sortBy: [
		"Carrier",
		"Metal Tier",
		"Plan Type",
		"Premium",	
	]
};

const inputNames = {
    benefits: "benefits",
    include: "include",
    sortBy: "sortBy",
};

export class AutomaticSpreadsheet extends Component {
	static propTypes = {
        spreadsheetType: PropTypes.string,
        setSpreadsheetType: PropTypes.func.isRequired,
    };

	state = {
        inputs: {
            [inputNames.benefits]: [],
            [inputNames.include]: [],
            [inputNames.sortBy]: [],
        }
    };

	_handleSpreadsheetOptionChanged = e => {
		const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;
        const currentOptions = this.state.inputs[name];

        if (val) {
			currentOptions.push(value);
        } else {
			const index = currentOptions.indexOf(value);
			if (index > -1) {
				currentOptions.splice(index, 1)
			} 
        }        
                
        this.setState(prevState => {            
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: currentOptions,
                },                    
            };
        });
    };

    _renderCheckboxes = (optionType) => {
		const options = automaticOptions[optionType];
		return (
			<div className={css(styles.checkboxContainer)}>
				{options.map((value, index) => (
					<div key={index}>
						<label className={css(styles.checkLabel)}>
							<Checkbox
								aStyles={styles.check}
								checked={this.state.inputs[optionType].includes(value)}                                                
								name={optionType}
								value={value}
								onChange={this._handleSpreadsheetOptionChanged}
							/>
							<span className={css(styles.labelText)}>{value}</span>
						</label>
					</div>
				))}				
			</div>
		); 
    };

    _backClicked = () => {
		this.props.setSpreadsheetType(null);
    };

    _saveClicked = () => {

    };

	render() {
		return (
			<div className={css(styles.container)}>
				<div className={css(styles.typeHeader)}>
					{strings.automaticHeaderText}
				</div>
				<div className={css(styles.automaticSpeadsheetContainer)}>
					<div className={css(styles.automaticOptionContainer)}>
						<div className={css(styles.automaticOptionText)}>
							Choose Benefits:
						</div>
						{this._renderCheckboxes(inputNames.benefits)}
					</div>
					<div className={css(styles.automaticOptionContainer)}>
						<div className={css(styles.automaticOptionText)}>
							Include:
						</div>
						{this._renderCheckboxes(inputNames.include)}
					</div>
					<div className={css(styles.automaticOptionContainer)}>
						<div className={css(styles.automaticOptionText)}>
							Sort By:
						</div>
						{this._renderCheckboxes(inputNames.sortBy)}
					</div>
					<div className={css(styles.savedSpreadsheetsContainer)}>
						<div className={css(styles.savedSpreadsheetsHeader)}>
							Saved Spreadsheets
						</div>
						<div className={css(styles.noSavedSpreadsheets)}>
							{strings.noSavedSpreadsheetsText}
						</div>
					</div>
				</div>
				<div className={css(styles.buttonContainer)}>                    
					<ActionButton                                    
						aStyles={styles.backButon}                        
						label={strings.backButtonText}
						invertColor={true}
						type="button"
						onClick={this._backClicked}
					/>                    
					<ActionButton                                    
						aStyles={styles.saveButton}                        
						label={strings.saveButtonText}
						onClick={this._saveClicked}
						type="button"                                        
						isLoading={this.state.isSaving}
					/>
				</div>                        
			</div>
		);
	}
}

export default connect(
    null,
    { setSpreadsheetType: proposalActions.setSpreadsheetType },
)(AutomaticSpreadsheet);
