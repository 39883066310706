import * as breakpoints from "../../constants/breakpoints";
import * as color from "../../constants/color";
import * as texchangePropTypes from "../../texchangePropTypes";
import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    text: {
        color: color.mediumYellow1,
        border: "none",
        outline: "none",
        fontSize: 14,
        fontWeight: 400,        
        textDecoration: "none",
        cursor: "pointer",
        padding: 0,
        background: color.white,

        [breakpoints.desktopOnly]: {
            ":hover": {
                textDecoration: "underline",
            },
        },
    },
});

export default function LinkButton(props) {
    const { aStyles, children, ...rest } = props;
    return (
        <button type="button" {...rest} className={css(styles.text, aStyles)}>
            {children}
        </button>
    );
}

LinkButton.propTypes = {
    aStyles: texchangePropTypes.aphroditeStyles,
    children: PropTypes.node,
};
