import * as color from "../../constants/color";
import PageTitle from "../text/PageTitle";
import React, { Component } from "react";
import { Container, Row, Column } from "../grid";
import Modal from "../Modal";
import ActionButton from "../ActionButton";
import * as texchangePropTypes from "../../texchangePropTypes";
import { StyleSheet, css } from "aphrodite";
import surfersPng from "../../images/surfers.png";
import * as dataApi from "../../lib/api/data";

const styles = StyleSheet.create({
    content: {
        paddingRight: 10,
        paddingBottom: 80,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
    },
    contentSection: {
        width: "100%",
        padding: 0,
        backgroundColor: color.white,
        borderTop: `8px solid ${color.mediumRed}`,
        marginBottom: 100,
    },
    titleSection: {
        heigth: 64,
    },
    carrierSection: {
        padding: "40px 100px",
        fontSize: 20,
    },
    photoHolder: {
        positon: "relative",
        height: 350,
        overflow: "hidden",
    },
    photo: {
        width: "100%",
    },
    photoCallout: {
        position: "absolute",
        top: 340,
        left: 30,
        padding: 20,
        fontSize: 22,
        backgroundColor: color.borderBackgroundGray,
        opacity: 0.8,
        color: color.white,
        width: 460,
    },
    columns: {
        padding: 15,
        backgroundColor: color.white,
    },
    infoBox: {
        position: "relative",
        width: "80%",
        height: 130,
        padding: 20,
        margin: "40px 30px",
        textAlign: "center",
        border: `1px solid ${color.tableBorder}`,
        borderRadius: 4,
        boxShadow: "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)",
    },
    largeGroup: {
        backgroundColor: color.lightBlue,
        border: `3px solid ${color.mediumRed}`,
    },
    carrierLogo: {
        maxWidth: 180,
        maxHeight: 70,
    },
    name: {
        position: "absolute",
        bottom: 15,
        left: 20,
        fontSize: 12,
        color: color.tableText,
    },
    area: {
        position: "absolute",
        bottom: 15,
        right: 20,
        fontSize: 12,
        color: color.tableText,
    },
    modalContent: {
        width: 720,
    },
    modalInstructions: {
        color: color.darkestGray,
        fontSize: 14,
        marginBottom: 8,
        textAlign: "left",
        lineHeight: "18px",
    },
    modalButtonContainer: {
        marginTop: 24,
        display: "flex",
        justifyContent: "space-around",
    },
    modalButton: {
        width: 150,
    },
    textarea: {
        height: "220px",
        width: "100%",
        marginTop: "0px",
        border: "none",
        backgroundColor: color.white,
        color: color.tableText,
        overflowX: "scroll",
    },
});

const strings = {
    title: "Carriers",
};

export default class CarriersScreen extends Component {
    static propTypes = {
        history: texchangePropTypes.history.isRequired,
    };

    state = {
        loading: false,
        carriers: [],
        showModal: false,
        currentName: "",
        currentQuoteingMethod: "",
        currentSiteUrl: "",
        currentDisclaimers: "",
    };

    componentDidMount() {
        this._mounted = true;
        this.setState({ loading: true });
        Promise.all([ dataApi.getAllCarriers(), dataApi.getAreas() ])
            .then(responses => {
                if (responses && responses[0] && responses[1] && this._mounted) {
                    this.setState({
                        loading: false,
                        carriers: responses[0],
                        states: responses[1],
                    });
                }
            });
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    _showModal = (name, quotingMethod, siteUrl, disclaimers) => {
        this.setState({
            showModal: true,
            currentName: name,
            currentQuotingMethod: quotingMethod,
            currentSiteUrl: siteUrl,
            currentDisclaimers: disclaimers
        });
    }

    render() { 
        const { carriers, showModal, currentName, currentQuotingMethod, currentSiteUrl, currentDisclaimers } = this.state;

        return (
            <div className={css(styles.content)}>
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.titleSection)}>
                        <PageTitle>{strings.title}</PageTitle>                        
                    </div>
                    <div className={css(styles.photoHolder)}>
                        <div className={css(styles.photoCallout)}>Established relationships with the most recognized names in health insurance</div>
                        <img src={surfersPng} alt="Carriers" className={css(styles.photo)} />
                    </div>
                    <div className={css(styles.carrierSection)}>
                        <b>Rogers Benefit Group</b> represents many of the largest insurers, serving as a valued partner in connecting all corners of the insurance marketplace.
                    </div>
                    <div className={css(styles.columns)}>
                        <Container>
                            <Row>
                                {carriers.map(c => (
                                    <Column key={c.id+c.area} large={3}>
                                        <div className={css(styles.infoBox, c.isLargeGroup ? styles.largeGroup : "")} onClick={() => this._showModal(c.name, c.quotingMethod, c.siteUrl, c.disclaimers)}>
                                            <img src={c.logoPath} className={css(styles.carrierLogo)} alt={c.name} />
                                            <span className={css(styles.name)}>{c.name}</span>
                                            <span className={css(styles.area)}>{"* "}{c.area}</span>
                                        </div>
                                    </Column>
                                ))}
                            </Row>
                        </Container>
                    </div>
                    <Modal 
                        show={showModal}
                        header="Carrier Info"
                    >
                        <div className={css(styles.modalContent)}>
                            <div>
                                <p><b>Carrier: </b>{currentName}</p>
                                <p><b>Quoting Method: </b>{currentQuotingMethod}</p>
                                <p><b>Site Url: </b>{currentSiteUrl}</p>
                                <p><b>Disclaimers: </b></p>
                                <div
                                    className={css(styles.textarea)}
                                    dangerouslySetInnerHTML={{__html: currentDisclaimers}}
                                />
                            </div>
                        </div>
                        <div className={css(styles.modalButtonContainer)}>
                            <ActionButton
                                aStyles={styles.modalButton}
                                label="Close"
                                // invertColor={true}
                                type="button"                            
                                onClick={() => this.setState({ showModal: false})}
                            />
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}