import instance from "./instance";

const responseData = response => response.data;

export function getAll() {
    return instance
        .request({
            url: "/lifePlan/get-life-plans",
            method: "GET",
        })
        .then(responseData);
}

export function getPaged(page, sortColumn, sortDirection, quoteId, current, carrierIds, networks, planTypes, tierIds, deductibleRange, searchTerm, areaId) {
    return instance
        .request({
            url: "/lifePlan/get-life-plans-page",
            method: "POST",
            data: {
                page,
                sortColumn,
                sortDirection,
                quoteId,
                current,
                carrierIds
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function getPagedForQuote(page, sortColumn, sortDirection, quoteId, carrierIds, networks, planTypes, tierIds, deductibleRange, coinsuranceRange, oopRange, employeePremiumRange, monthlyPremiumRange, searchTerm, packages, denVis1, denVis2, denVis3, denVis4, denVis5, denVis6, denVis7, denVis8, medInfertility, medHSA, all = false) {
    return instance
        .request({
            url: "/lifePlan/get-quote-life-plans-page",
            method: "POST",
            data: {
                page,
                sortColumn,
                sortDirection,
                quoteId,
                carrierIds,
                monthlyPremiumRange,
                searchTerm,
                all,
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function getLifePlansDisplay(areaId, carrierId, year) {
    return instance
        .request({
            url: "/lifePlan/get-life-plans-display",
            method: "POST",
            data: {
                areaId,
                carrierId,
                year,
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function getById(id) {
    return instance
        .request({
            url: `/lifePlan/get-life-plan/${id}`,
            method: "GET",
        })
        .then(responseData);
}