import instance from "./instance";

const responseData = response => response.data;

export function getSpreadsheetByQuoteId(quoteId) {
    return instance
        .request({
            url: `/quoteProposal/getSpreadsheet/${quoteId}`,
            method: "GET",
        })
        .then(responseData);
}

export function saveSpreadsheet(quoteId, spreadsheet) {
    return instance
        .request({
            url: "/quoteProposal/saveSpreadsheet",
            method: "POST",
            data: {
                quoteId,
                spreadsheets: JSON.stringify(spreadsheet),
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function saveQuoteProposal(quoteProposal) {
    return instance
        .request({
            url: "/quoteProposal/saveQuoteProposal",
            method: "POST",
            data: { ...quoteProposal },
            headers: {
                "Content-Type": "application/json",
            },
            responseType: "blob",
        })
        .then((response) => {
            if (response.data && response.data.size > 0) {
                if (response.data.type.startsWith("application")) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', quoteProposal.fileName);
                    document.body.appendChild(link);
                    link.click();
                }
                else if (response.data.type === "text/plain") {
                    return response.data.text();
                }
            }
            else {
                return "ERROR";
            }
        })
        .catch(err => {
            return "ERROR: " + err.message;
        });
}

export function getQuoteProposalOutput(id, fileName) {
    return instance
        .request({
            url: `/quoteProposal/getQuoteProposalOutput/${id}`,
            method: "POST",
            responseType: "blob",
        })
        .then((response) => {
            if (response.data && response.data.size > 0) {
                if (response.data.type.startsWith("application")) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                }
                else if (response.data.type === "text/plain") {
                    return response.data.text();
                }
            }
            else {
                return "ERROR";
            }
        })
        .catch(err => {
            return "ERROR: " + err.message;
        });
}

export function deleteProposal(proposalId) {
    return instance
        .request({
            url: `/quoteProposal/delete-proposal/${proposalId}`,
            method: "POST",
        })
        .then(responseData);
}

export function getQuoteProposalsByQuoteId(quoteId) {
    return instance
        .request({
            url: `/quoteProposal/getQuoteProposalsByQuoteId/${quoteId}`,
            method: "GET",
        })
        .then(responseData);
}
