import PropTypes from "prop-types";

export const aphroditeStyles = PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
]);

export const history = PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
});

export const match = PropTypes.shape({
    isExact: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
});

export const location = PropTypes.shape({
    hash: PropTypes.string.isRequired,
    key: PropTypes.string,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    state: PropTypes.object,
});

export const image = PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    mimeType: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    scale: PropTypes.number.isRequired,
});

export const me = PropTypes.shape({
    areaId: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    regionalOfficeId: PropTypes.number.isRequired,
});

export const office = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
});

export const quote = PropTypes.shape({
    id: PropTypes.number,
    groupName: PropTypes.string,
});

export const state = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    longName: PropTypes.string.isRequired,
});