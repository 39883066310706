import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import TextInput from "../inputs/TextInput";
import Checkbox from "../inputs/Checkbox";
import Infinite from "../Infinite";
import FavoritePlanList from "./FavoritePlanList";
import * as dataApi from "../../lib/api/data";
import * as visionPlansApi from "../../lib/api/visionPlans";
import * as favoriteApi from "../../lib/api/favorite";
import * as inputSizes from "../../constants/inputSizes";
import * as color from "../../constants/color";

const styles = StyleSheet.create({
    content: {

    },
    filterContainer: {
        display: "flex",
        padding: "28px 24px"
    },
    inlineTable: {
        borderCollapse: "collapse",
        verticalAlign: "middle",
        marginLeft: 10,
    },
    searchInput: {
        width: 400,
    },
    tblCell: {
        backgroundColor: color.lightGray4,
        textAlign: "left",
        ":first-child": {
            width: 400,
        },
        ":nth-child(2)": {
            paddingLeft: 32,
            minWidth: 160,
        }
    },
    filterLabel: {
        fontWeight: 500,
        fontSize: 13,
        color: color.darkestGray,
        whiteSpace: "nowrap",
    },
    checkboxFilter: {
        marginBottom: 10,
        marginRight: 64,
        maxHeight: "184px",
        overflowY: "auto",
        paddingRight: 15,
        width: "100%",
    },
    planTypeCarriers: {
        marginLeft: 6,
        fontSize: 11,
        color: color.mediumGray,
    },
    checkLabel: {
        display: "block",
        position: "relative",
        border: "none",
        marginTop: 16,
        marginBottom: 16,
        "-webkit-appearance": "none",
        cursor: "pointer",
    },
    labelText: {
        marginLeft: 30,
        fontSize: 13,
        whiteSpace: "nowrap",
    },
    checkboxContainer: {
        marginTop: 16,
    },
    check: {
        position: "absolute",
        top: -2,
        left: 0,
        marginRight: 10,
    },
    containerFull: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        marginLeft: 0,
        justifyContent: "space-evenly",
        alignItems: "left",
    },
    filterControlColumn: {
        minWidth: "125px",
        maxWidth: "175px",
        padding: 0,
    },
    filterControlColumnWide: {
        minWidth: "210px",
        maxWidth: "300px",
        padding: 0,
    },
    rangeControlContainer: {
        width: "20%",
        flexDirection: "column",
    },
    rangeControlColumn: {
        width: "100%",
        paddingLeft: 64,
        paddingRight: 64,
        marginBottom: 42,
    },
    rangeControl: {
        marginTop: 18,
        maxWidth: "350px",
    },
    metalCircle: {
        height: "14px",
        width: "14px",
        borderRadius: "50%",
        display: "inline-block",
        marginLeft: "-4px",
        marginRight: "4px",
    },
    right: {
        float: "right",
        position: "relative",
        top: -38,
        right: 20,
        fontSize: 14,
        color: color.tableText,
    },
    inlineFilterContainer: {
        paddingTop: 1,
        paddingBottom: 1,
        backgroundColor: color.lightGray4,
        borderTop: `1px solid ${color.lightGray2}`,
        borderBottom: `1px solid ${color.lightGray2}`,
    },
    noResults: {
        textAlign: "center",
        color: color.darkGray,
        marginTop: 16,
    },
});

const strings = {
    planTypeFilterLabel: "Plan Type",
    carrierFilterLabel: "Carriers",
    tierFilterLabel: "Metal Tier",
    deductibleFilterLabel: "Deductible",
    coninsuranceFilterLabel: "Co-Insurance",
    examCopayFilterLabel: "Exam Copay",
    materialCopayFilterLabel: "Materials Copay",
    frameAllowanceFilterLabel: "Frame Allowance",
    contactLensAllowanceFilterLabel: "Contact Lens Allowance",
    frameFrequencyFilterLabel: "Frequency",
    fundingTypeFilterLabel: "Funding Type",
    planNameFilterLabel: "Search for plan",
};

const filterInputNames = {
    coinsuranceRange: "coinsuranceRange",
    deductibleRange: "deductibleRange",
    carriers: "carriers",
    fundingTypes: "fundingTypes",
    examCopays: "examCopays",
    frameAllowances: "frameAllowances",
    frameFrequencies: "frameFrequencies",
    contactLensAllowances: "contactLensAllowances",
    materialCopays: "materialCopays",
    planName: "planName",
};

export class VisionPlans extends Component {
    static propTypes = {
        refresh: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            plans: [],
            planTypes: [],
            planTypesFilter: [],
            planTypesFiltered: [],
            plansMore: false,
            plansCount: 0,
            carriers: [],
            states: [],
            planColumnLabels: ["Carrier", "Plan", "Exam", "Materials", "Frames", "Contacts", "Funding", "Frequency", "Notes"],
            planColumnValues: ["carrierName", "displayName", "examCopay", "materialsCopay", "framesAllowance", "contactLensAllowance", "fundingType", "frequency", "notes"],
            fundingTypes: [],
            examCopays: [],
            frameAllowances: [],
            frameFrequencies: [],
            contactLensAllowances: [],
            materialCopays: [],
            favoriteClickParams: {
                pageX: 0,
                pageY: 0,
                show: false,
                planId: "",
                planType: "Vision",
            },
            filter: {
                state: "",
                carriers: [],
                fundingTypes: [],
                examCopays: [],
                frameAllowances: [],
                frameFrequencies: [],
                contactLensAllowances: [],
                materialCopays: [],
                year: new Date().getFullYear().toString(),
                planName: "",
            },
            tiers: [
                { id: 3, name: "Bronze" },
                { id: 2, name: "Silver" },
                { id: 1, name: "Gold" },
                { id: 4, name: "Platinum" },
            ],
            coinsuranceMin: 0,
            coinsuranceMax: 100,
            deductibleMin: 0,
            deductibleMax: 100000,
            favoriteList: [],
        };
    }

    componentDidMount() {
        document.body.addEventListener("click", this._handleBodyClicked);

        this._mounted = true;
        this.setState({ loading: true });

        Promise.all([
            favoriteApi.getList(),
            dataApi.getAreas(),
            dataApi.getVisionFundingTypesFilter(),
            dataApi.getVisionExamCopaysFilter(),
            dataApi.getVisionFrameAllowancesFilter(),
            dataApi.getVisionFrameFrequenciesFilter(),
            dataApi.getVisionContactLensAllowancesFilter(),
            dataApi.getVisionMaterialCopaysFilter(),
        ])
            .then(responses => {
                dataApi.getCarriers(this.state.filter.year, responses[1][0].id).then(result => {
                    this.setState(prevState => {
                        return {
                            favoriteList: responses[0],
                            carriers: result.filter(c => c.supportsVision === true),
                            states: responses[1],
                            fundingTypes: responses[2],
                            examCopays: responses[3],
                            frameAllowances: responses[4],
                            frameFrequencies: responses[5],
                            contactLensAllowances: responses[6],
                            materialCopays: responses[7],
                            filter: {
                                ...prevState.filter,
                                state: responses[1][0].id.toString(),
                            }
                        }
                    }, () => {
                        this._getPlans();
                    });
                });
            });
    }

    componentWillUnmount() {
        this._mounted = false;
        document.body.removeEventListener("click", this._handleBodyClicked);
    }

    componentDidUpdate(prevProps) {
        const { refresh } = this.props;
        if (prevProps.refresh !== refresh) {
            this._getPlans();
        }
    }

    _getFilterParameters = () => {
        const { filter } = this.state;
        const filterCarriers = filter[filterInputNames.carriers].length > 0 ? filter[filterInputNames.carriers].join() : null;
        const filterFundingTypes = filter[filterInputNames.fundingTypes].length > 0 ? filter[filterInputNames.fundingTypes].join() : null;
        const filterExamCopays = filter[filterInputNames.examCopays].length > 0 ? filter[filterInputNames.examCopays].join() : null;
        const filterFrameAllowances = filter[filterInputNames.frameAllowances].length > 0 ? filter[filterInputNames.frameAllowances].join() : null;
        const filterFrameFrequencies = filter[filterInputNames.frameFrequencies].length > 0 ? filter[filterInputNames.frameFrequencies].join() : null;
        const filterContactLensAllowances = filter[filterInputNames.contactLensAllowances].length > 0 ? filter[filterInputNames.contactLensAllowances].join() : null;
        const filterMaterialCopays = filter[filterInputNames.materialCopays].length > 0 ? filter[filterInputNames.materialCopays].join() : null;

        return {
            filterCarriers,
            filterFundingTypes,
            filterExamCopays,
            filterFrameAllowances,
            filterFrameFrequencies,
            filterContactLensAllowances,
            filterMaterialCopays,
        };
    };

    _handleBodyClicked = e => {
        if (e.target && e.target.className && typeof e.target.className.includes === "function" && e.target.className.includes("modalBackground")) {
            this.setState({
                favoriteClickParams: {
                    show: false,
                }
            });
        }
    }

    _getPlans = () => {
        this.setState({ loading: true });

        const {
            filterCarriers,
            filterFundingTypes,
            filterExamCopays,
            filterFrameAllowances,
            filterFrameFrequencies,
            filterContactLensAllowances,
            filterMaterialCopays,
        } = this._getFilterParameters();

        visionPlansApi.getPaged(1, null, null, null, this.state.filter.year, filterCarriers, null, null, null, null, this.state.filter[filterInputNames.planName], this.state.filter.state, filterFundingTypes, filterExamCopays, filterFrameAllowances, filterFrameFrequencies, filterContactLensAllowances, filterMaterialCopays).then(result => {
            this.setState({ loading: false });
            if (result && result[0]) {
                this.setState({
                    plans: result,
                    plansCount: result[0].recordCount,
                    plansMore: result[0].recordCount > 100,
                });
            } else {
                this.setState({
                    plans: [],
                    plansCount: 0,
                    plansMore: false,
                });
            }
        });
    };

    _coinsuranceRangeChanged = value => {
        this.setState(prevState => {
            return {
                filter: {
                    ...prevState.filter,
                    [filterInputNames.coinsuranceRange]: value,
                },
            };
        }, () => {
            this._getPlans();
        });
    };

    _deductibleRangeChanged = value => {
        this.setState(prevState => {
            return {
                filter: {
                    ...prevState.filter,
                    [filterInputNames.deductibleRange]: value,
                },
            };
        }, () => {
            this._getPlans();
        });
    };

    _changeState = item => {
        this.setState(prevState => {
            return {
                filter: {
                    ...prevState.filter,
                    state: item.toString(),
                }
            }
        }, () => {
            this._getPlans();
        });
    };

    _handleFilterTextChanged = e => {
        const { name } = e.target;
        let { value } = e.target;

        //save values to quote
        if (name === "employeeContribution" || name === "dependentContribution") {
            const { quote } = this.props;
            const { filter } = this.state;

            if (isNaN(parseInt(value))) { value = 0; }
            else { value = parseInt(value); }

            if (filter[filterInputNames.isPercentageContribution]) {
                if (value < 0) { value = 0; }
                else if (value > 100) { value = 100; }
                quote[name] = value + "%";
            } else {
                quote[name] = "$" + value;
            }
        }

        this.setState(prevState => {
            return {
                filter: {
                    ...prevState.filter,
                    [name]: value,
                },
            };
        }, () => {
            if (name === filterInputNames.planName && (value.length === 0 || value.length > 2)) {
                setTimeout(() => {
                    this._getPlans();
                }, 750);
            }
        });
    }

    _handleFilterCheckboxChanged = e => {
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;
        const currentOptions = this.state.filter[name];

        if (val && name === "packages") {
            //make sure parent is included
            const carrierOptions = this.state.filter.carriers;
            const parentCarrier = this.state.carriers.find(p => p.packages && p.packages.indexOf(value) > -1);
            if (parentCarrier && parentCarrier.id && !carrierOptions.includes(parentCarrier.id.toString())) {
                carrierOptions.push(parentCarrier.id.toString());
                this.setState(prevState => {
                    return {
                        filter: {
                            ...prevState.filter,
                            carriers: carrierOptions,
                        },
                    };
                });
            }
        }
        if (!val && name === "carriers") {
            //make sure children are removed
            // let packageOptions = this.state.filter.packages;
            // const thisCarrier = this.state.carriers.find(p => p.id.toString() === value && p.packages);
            // if (thisCarrier && packageOptions.some(r => thisCarrier.packages.split(',').indexOf(r))) {
            //     const thisPackages = thisCarrier.packages.split(',');
            //     packageOptions = packageOptions.filter((pkg) => !thisPackages.includes(pkg));
            //     this.setState(prevState => {
            //         return {
            //             filter: {
            //                 ...prevState.filter,
            //                 packages: packageOptions,
            //             },
            //         };
            //     });
            // }
        }

        if (val) {
            currentOptions.push(value);
        } else {
            const index = currentOptions.indexOf(value);
            if (index > -1) {
                currentOptions.splice(index, 1)
            }
        }

        this.setState(prevState => {
            return {
                filter: {
                    ...prevState.filter,
                    [name]: currentOptions,
                },
            };
        }, () => {
            this._getPlans();
        });
    };

    _plansScroll = (page, col, dir) => {
        const {
            filterCarriers,
            filterFundingTypes,
            filterExamCopays,
            filterFrameAllowances,
            filterFrameFrequencies,
            filterContactLensAllowances,
            filterMaterialCopays,
        } = this._getFilterParameters();

        if (((page - 1) * 100) < this.state.plansCount) {
            visionPlansApi.getPaged(page, null, null, null, this.state.filter.year, filterCarriers, null, null, null, null, null, this.state.filter.state, filterFundingTypes, filterExamCopays, filterFrameAllowances, filterFrameFrequencies, filterContactLensAllowances, filterMaterialCopays).then(result => {
                let joined = {};
                if (page === 1) {
                    joined = result;
                } else {
                    joined = this.state.plans.concat(result);
                }
                this.setState({
                    plans: joined,
                });
            });
        } else {
            this.setState({
                plansMore: false,
            });
        }
    };

    _handleRowClick = id => {

    };

    _handleFavoriteClick = (e, id) => {
        this.setState({
            favoriteClickParams: {
                pageX: e.screenX,
                pageY: e.screenY,
                show: true,
                planId: id,
                planType: "Vision"
            }
        });
    };

    _handleFavoriteSaved = (name, ids) => {
        this._saveFavoritePlan(this.state.favoriteClickParams.planId, ids);
    };

    _handleFavoriteCanceled = () => {
        this.setState({
            favoriteClickParams: {
                show: false,
            }
        });
    };

    _saveFavoritePlan = (planId, favoriteListIds) => {
        if (planId === "all") {
            const {
                filterCarriers,
                filterFundingTypes,
                filterExamCopays,
                filterFrameAllowances,
                filterFrameFrequencies,
                filterContactLensAllowances,
                filterMaterialCopays,
            } = this._getFilterParameters();

            visionPlansApi.getPaged(1, null, null, null, this.state.filter.year, filterCarriers, null, null, null, null, this.state.filter[filterInputNames.planName], this.state.filter.state, filterFundingTypes, filterExamCopays, filterFrameAllowances, filterFrameFrequencies, filterContactLensAllowances, filterMaterialCopays, true).then(result => {
                const planIds = result.map(item => item.id);
                favoriteApi.addPlans("vision", planIds, favoriteListIds)
                    .then(() => {
                        this._getPlans();

                        this.setState(prevState => {
                            return {
                                favoriteClickParams: {
                                    ...prevState.favoriteClickParams,
                                    show: false,
                                },
                            };
                        });
                    });
            });
        } else {
            favoriteApi.addPlan("vision", planId, favoriteListIds)
                .then(() => {
                    this._getPlans();

                    this.setState(prevState => {
                        return {
                            favoriteClickParams: {
                                ...prevState.favoriteClickParams,
                                show: false,
                            },
                        };
                    });
                });
        }
    }

    renderPlans = () => {
        const { plansMore, plans } = this.state;
        return (
            <div className={css(styles.contentSection)}>
                {plans.length > 0 ? (
                    <Infinite
                        results={plans}
                        length={plans.length}
                        columnLabels={this.state.planColumnLabels}
                        columnValues={this.state.planColumnValues}
                        showChevron={false}
                        showMore={plansMore}
                        onClick={this._handleRowClick}
                        onFavoriteClick={this._handleFavoriteClick}
                        showFavoriteAll={true}
                        onScroll={this._plansScroll} />
                ) : (
                    <div className={css(styles.noResults)}>{this.state.loading ? "Loading " : "No Matching "} Plans</div>
                )}
            </div>
        );
    }

    _renderCheckboxes = (options, optionType) => {
        if (!options) {
            return null;
        }

        if (optionType === "planTypes") {
            options = [];
            const { planTypesFilter } = this.state;
            const selectedCarriers = this.state.filter["carriers"];

            if (planTypesFilter && planTypesFilter.length > 0) {
                Object.keys(planTypesFilter).forEach(key => {
                    let split = planTypesFilter[key].split('|');
                    if (selectedCarriers && selectedCarriers.length > 0) {
                        if (selectedCarriers.indexOf(split[0]) > -1 && options.indexOf(split[2]) === -1) {
                            options.push(split[2]);
                        }
                    } else {
                        if (options.indexOf(split[2]) === -1) {
                            options.push(split[2]);
                        }
                    }
                });
            }
        }

        return (
            <div className={css(styles.checkboxContainer)}>
                {options.map((option, index) => (
                    <div key={index}>
                        <label className={css(styles.checkLabel)}>
                            <Checkbox
                                aStyles={styles.check}
                                checked={this.state.filter[optionType].includes(option.id ? option.id.toString() : option)}
                                name={optionType}
                                value={option.id ? option.id : option}
                                onChange={this._handleFilterCheckboxChanged}
                            />
                            <span className={css(styles.labelText)}>
                                {optionType === "tiers" &&
                                    <span className={css(styles.metalCircle)} style={{ backgroundColor: (option.name === "Gold" ? "#ffd700" : option.name === "Silver" ? "#c0c0c0" : (option.name === "Bronze" || option.name === "Exp. Bronze") ? "#cd7f32" : option.name === "Platinum" ? "#f7f7f7" : option.name), border: (option.name === "Platinum" ? "solid 1px #cccccc" : "") }} />
                                }
                                {option.name ? option.name : option}
                            </span>
                        </label>
                    </div>
                ))}
            </div>
        );
    };


    renderFilters = () => {
        const { states, carriers, planTypes } = this.state;
        const selectedState = this.state.filter.state;
        return (
            <div>
                <div className={css(styles.filterContainer)}>
                    <div className={css(styles.containerFull)}>
                        <div className={css(styles.filterControlColumnWide)}>
                            <div className={css(styles.checkboxFilter, styles.carrierFilter)}>
                                <div className={css(styles.filterLabel)}>
                                    {strings.carrierFilterLabel}
                                </div>
                                {this._renderCheckboxes(this.state.carriers, "carriers")}
                            </div>
                            {this.state.fundingTypes.length > 0 &&
                                <div className={css(styles.checkboxFilterNoHeight)}>
                                    <div className={css(styles.filterLabel)}>
                                        {strings.fundingTypeFilterLabel}
                                    </div>
                                    {this._renderCheckboxes(this.state.fundingTypes, "fundingTypes")}
                                </div>
                            }
                        </div>
                        <div className={css(styles.filterControlColumn)}>
                            {this.state.examCopays.length > 0 &&
                                <div className={css(styles.checkboxFilterNoHeight)}>
                                    <div className={css(styles.filterLabel)}>
                                        {strings.examCopayFilterLabel}
                                    </div>
                                    {this._renderCheckboxes(this.state.examCopays, "examCopays")}
                                </div>
                            }
                        </div>
                        <div className={css(styles.filterControlColumn)}>
                            {this.state.frameAllowances.length > 0 &&
                                <div className={css(styles.checkboxFilterNoHeight)}>
                                    <div className={css(styles.filterLabel)}>
                                        {strings.frameAllowanceFilterLabel}
                                    </div>
                                    {this._renderCheckboxes(this.state.frameAllowances, "frameAllowances")}
                                </div>
                            }
                        </div>
                        <div className={css(styles.filterControlColumn)}>
                            {this.state.frameFrequencies.length > 0 &&
                                <div className={css(styles.checkboxFilterNoHeight)}>
                                    <div className={css(styles.filterLabel)}>
                                        {strings.frameFrequencyFilterLabel}
                                    </div>
                                    {this._renderCheckboxes(this.state.frameFrequencies, "frameFrequencies")}
                                </div>
                            }
                        </div>
                        <div className={css(styles.filterControlColumn)}>
                            {this.state.contactLensAllowances.length > 0 &&
                                <div className={css(styles.checkboxFilterNoHeight)}>
                                    <div className={css(styles.filterLabel)}>
                                        {strings.contactLensAllowanceFilterLabel}
                                    </div>
                                    {this._renderCheckboxes(this.state.contactLensAllowances, "contactLensAllowances")}
                                </div>
                            }
                        </div>
                        <div className={css(styles.filterControlColumn)}>
                            {this.state.materialCopays.length > 0 &&
                                <div className={css(styles.checkboxFilterNoHeight)}>
                                    <div className={css(styles.filterLabel)}>
                                        {strings.materialCopayFilterLabel}
                                    </div>
                                    {this._renderCheckboxes(this.state.materialCopays, "materialCopays")}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={css(styles.inlineFilterContainer)}>
                    <table className={css(styles.inlineTable)}>
                        <tbody>
                            <tr>
                                <td className={css(styles.tblCell)}>
                                    <TextInput
                                        name={filterInputNames.planName}
                                        onChange={this._handleFilterTextChanged}
                                        placeholder={strings.planNameFilterLabel}
                                        value={this.state.filter[filterInputNames.planName]}
                                        hideLabel={true}
                                        size={inputSizes.small}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className={css(styles.right)}>{parseInt(this.state.plansCount).toLocaleString()}{" "} Plans</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className={css(styles.content)}>
                {this.renderFilters()}
                {this.renderPlans()}

                <FavoritePlanList
                    favoriteList={this.state.favoriteList}
                    params={this.state.favoriteClickParams}
                    onSave={this._handleFavoriteSaved}
                    onCancel={this._handleFavoriteCanceled}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({});

export default connect(
    mapStateToProps, {

}
)(VisionPlans);