import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as texchangePropTypes from "../../texchangePropTypes";
import { StyleSheet, css } from "aphrodite";
import sortBy from "lodash.sortby";
import Dropdown from "../inputs/Dropdown";
import NestedDropdown from "../inputs/NestedDropdown";
import * as color from "../../constants/color";
import Modal from "../Modal";
import ActionButton from "../ActionButton";
import TextInput from "../inputs/TextInput";
import Toggle from "../inputs/Toggle";
import backCircle from "../../images/SVGs/BackCircle.svg";
import * as inputSizes from "../../constants/inputSizes";
import * as medicalPlansApi from "../../lib/api/medicalPlans";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = StyleSheet.create({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    benefitsContainer: {
        color: color.darkestGray,
        width: "16%",
        flex: 1,
        padding: 8,
        boxShadow: "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2)",
    },
    benefitsDropdown: {
        display: "block",
    },
    plansContainer: {
        display: "flex",
        flexShrink: 0,
        width: "84%",
        marginLeft: 0,
        padding: 4,
        paddingTop: 35,
        overflowX: "auto",
    },
    scrollButtons: {
        position: "absolute",
        top: 408,
        left: "calc(50% - 86px)",
        width: 100,
        height: 24,
        whiteSpace: "nowrap",
    },
    scrollButton: {
        position: "relative",
        cursor: "pointer",
        border: "none",
        backgroundColor: "transparent",
        display: "inline-block",
        margin: "0px 30px",
        height: 24,
    },
    rotate: {
        transform: "rotate(180deg)",
    },
    benefitsColumn: {
        backgroundColor: color.white,
        border: `1px solid ${color.darkGray2}`,
        paddingTop: 4,
        paddingBottom: 4,
        marginTop: 18,
    },
    benefitsHeader: {
        padding: 4,
    },
    benefitsInfo: {
        fontSize: 14,
        fontWeight: 500,
        color: color.darkestGray,
        paddingLeft: 4,
        paddingBottom: 8,
        marginTop: 4,
        marginRight: 8,
    },
    benefitsPadding: {
        paddingTop: 10,
    },
    benefitsRightAlign: {
        float: "right",
    },
    planColumn: {
        width: "25%",
        minWidth: 320,
        flex: 1,
        paddingLeft: 8,
        paddingRight: 8,
    },
    planDropdown: {
        marginBottom: 18,
    },
    addBenefit: {
        display: "block",
        width: "calc(100% - 8px)",
        lineHeight: "22px",
        outline: 0,
        backgroundColor: color.lightGray,
        border: `1px solid ${color.darkGray2}`,
        borderRadius: 4,
        padding: "10px 12px",
        margin: "0px auto",
        textAlign: "left",
        fontSize: 14,
        cursor: "pointer",
    },
    addBenefitIcon: {
        float: "right",
    },
    item: {
        fontSize: 13,
        padding: "6px 8px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        backgroundColor: color.white,
        ":nth-child(even)": {
            backgroundColor: color.lightGray5,
        },
    },
    rateAndContributions: {
        fontSize: 13,
        padding: "6px 8px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    premium: {
        fontWeight: 500,
        fontSize: 14,
    },
    contributions: {
        borderTop: `1px solid ${color.mediumGray}`,
        backgroundColor: color.white,
    },
    rates: {
        borderTop: `1px solid ${color.lightGray2}`,
        backgroundColor: color.white,
    },
    label: {
        fontSize: 14,
        fontWeight: 500,
        marginRight: 8,
    },
    benefitsHeaderRow: {
        height: 257,
    },
    planHeaderRow: {
        height: 184,
    },
    planDetails: {
        height: "calc(100% - 68px)",
    },
    emptyPlan: {
        color: color.mediumYellow1,
        textAlign: "center",
        borderRadius: 4,
        height: "100%",
        border: `1px dashed ${color.red}`,
        backgroundColor: color.lightGray,
        ":hover": {
            cursor: "pointer",
            backgroundColor: color.white,
        },
    },
    emptyPlanCR: {
        color: color.mediumYellow1,
        textAlign: "center",
        borderRadius: 4,
        height: "100%",
        border: `1px dashed ${color.red}`,
        backgroundColor: color.lightGray,
        ":hover": {
            cursor: "pointer",
            backgroundColor: color.white,
        },
    },
    emptyPlanPlus: {
        marginTop: 100,
        fontSize: 50,
        fontWeight: 100,
    },
    emptyPlanText: {
        fontSize: 22,
        marginTop: 12,
    },
    carrierLogoContainer: {
        width: 192,
        height: 60,
        margin: "0px auto",
        marginTop: 0,
        marginBottom: 12,
        position: "relative",
    },
    carrierLogo: {
        maxHeight: "100%",
        maxWidth: "100%",
        width: "auto",
        height: "auto",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
    },
    planDetailsContainer: {
        color: color.darkestGray,
        paddingTop: 4,
        paddingBottom: 4,
        borderRadius: 4,
        border: `1px solid ${color.white}`,
        boxShadow: "0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.2)",
    },
    planTypeName: {
        marginBottom: 8,
        float: "left",
        fontSize: 13,
        marginLeft: 15,
    },
    planMetallicLevel: {
        marginBottom: 8,
        float: "right",
        fontSize: 13,
        marginRight: 15,
    },
    planHeader: {
        clear: "both",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontWeight: 500,
        fontSize: 14,
        paddingLeft: 15,
        paddingRight: 15,
        lineHeight: "17px",
    },
    typeIndicator: {
        paddingLeft: 8,
        fontSize: 15,
        fontWeight: 700,
        color: color.darkRed,
        float: "left",
    },
    removePlan: {
        cursor: "pointer",
        position: "relative",
        left: "calc(100% - 28px)",
        top: 2,
        width: 26,
        height: 26,
        opacity: 0.4,
        ":hover": {
            // opacity: 1
        },
        ":before": {
            position: "absolute",
            left: 10,
            content: "''",
            height: 20,
            width: 2,
            backgroundColor: color.black,
            transform: "rotate(45deg)",
        },
        ":after": {
            position: "absolute",
            left: 10,
            content: "''",
            height: 20,
            width: 2,
            backgroundColor: color.black,
            transform: "rotate(-45deg)",
        }
    },
    planItem: {
        fontSize: 13,
        padding: "6px 16px",
        minHeight: 26,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        backgroundColor: color.white,
        ":nth-child(even)": {
            backgroundColor: color.lightGray,
        },
    },
    planRateType: {
        fontSize: 13,
        borderTop: `1px solid ${color.lightGray2}`,
        backgroundColor: color.white,
        height: 32,
        lineHeight: "18px",
    },
    buttonContainer: {
        height: 80,
        margin: "24px auto 24px auto",
        textAlign: "center",
        marginTop: 60,
        width: "100%",
    },
    saveButton: {
        minWidth: 200,
    },
    backButon: {
        marginRight: 32,
        width: 180,
    },
    noPlansContainer: {
        position: "absolute",
        left: 320,
        display: "flex",
        marginTop: -84,
        fontSize: 15,
        padding: 12,
        color: color.darkRed,
    },
    loadSavedSpreadsheetContainer: {
        position: "absolute",
        right: 0,
        display: "flex",
        marginTop: -68,
        marginRight: 24,
    },
    loadSpreadsheetDropdown: {
        marginRight: 20,
        minWidth: 200,
        top: -10,
    },
    saveSpreadsheetButton: {
        display: "inline-block",
        height: 40,
        fontSize: 14,
    },
    modalContent: {
        width: 386,
    },
    modalInstructions: {
        color: color.darkestGray,
        fontSize: 16,
        marginBottom: 8,
    },
    modalButtonContainer: {
        marginTop: 24,
        display: "flex",
        justifyContent: "space-between",
    },
    modalButton: {
        width: 150,
    },
    toggle: {
        float: "right",
        marginRight: 12,
        fontSize: 13,
    },
    hide: {
        display: "none",
    },
});

const benefits = [
    "Deductible Type",
    "Ind. Deductible (In/Out)",
    "Fam. Deductible (In/Out)",
    "Coins - Carrier (In)",
    "Ind. OOP Max (In/Out)",
    "Fam. OOP Max (In/Out)",
    "PCP CoPay (In)",
    "Specialist Copay (In)",
    "Telehealth (In)",
    "Lab and X-ray (In)",
    "Advanced Imaging (In)",
    "Rx Deductible (Ind/Fam)",
    "Rx Drug Card (In)",
    "Specialty Med (In)",
    "Mail Order (In)",
    "Urgent Care (In)",
    "ER (In/Out)",
    "Inpatient Hosp. (In)",
    "Outpatient Surgery (In)",
];

const ageRatedPremiums = [
    "Total Monthly Premium",
    "Total Annual Premium",
    "Employee Premium",
    "Dependent Premium",
    "Difference from Current",
    "Difference from Renewal",
];

const compositePremiums = [
    "Total Monthly Premium",
    "Total Annual Premium",
    "Employee",
    "Employee + Spouse",
    "Employee + Child",
    "Employee + Family",
    "Difference from Current",
    "Difference from Renewal",
];

const benefitsOptions = [
    "Basic Benefits",
];

const strings = {
    required: "Please fill out this field",
    addBenefit: "Add Benefit",
    emptyPlanText: "Add New Plan",
    currentEmptyPlanText: "Add Optional Current Plan",
    renewalEmptyPlanText: "Add Optional Renewal Plan",
    plansPlaceholder: "Select Plan",
    currentPlansPlaceholder: "Select Current Plan",
    renewalPlansPlaceholder: "Select Renewal Plan",
    benefitPlaceholder: "Select Benefits",
    spreadsheetsPlaceholder: "Load Spreadsheet",
    saveSpreadsheetButtonText: "Save Spreadsheet",
    saveButtonText: "Save",
    cancelButtonText: "Cancel",
    spreadsheetNameInstructions: "Please name your spreadsheet:",
    spreadsheetNamePlaceholder: "Enter spreadsheet name. (max length 30)",
    plansRequiredMessage: "Please select a plan",
    noPlansAvailable: "You must select at least one plan in the Current, Renewal or New plans sections in order to add them to a spreadsheet.",
};

const inputNames = {
    spreadsheetName: "spreadsheetName",
    rateType: "rateType"
};

export class SinglePlanSpreadsheet extends Component {
    static propTypes = {
        quote: texchangePropTypes.quote.isRequired,
        selectedPlans: PropTypes.object.isRequired,
        spreadsheets: PropTypes.object.isRequired,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        const plansByType = this._getPlansByCoverageType();

        this.state = {
            benefitsOption: benefitsOptions[0],
            showSaveModal: false,
            plans: Array.apply(null, Array(24)).map(() => {
                return {};
            }),
            allPlans: plansByType.currentPlans.concat(plansByType.renewalPlans).concat(plansByType.newPlans),
            inputs: {
                [inputNames.spreadsheetName]: "",
                [inputNames.rateType]: (props.quote && (props.quote.areaId === 7 || props.quote.areaId === 23 || props.quote.areaId === 32)) ? "Age" : "Composite",
            },
            errors: [],
            selectedSpreadsheetName: null,
            scrollIndex: 1,
        };
    }

    _getPlansByCoverageType = () => {
        const { quote, selectedPlans } = this.props;

        let currentPlans = quote[`currentMedicalPlans`];
        /*
        Object.keys(coverageTypes).forEach(coverageType => {
            currentPlans = currentPlans.concat(quote[`current${util.capitalize(coverageTypes[coverageType])}Plans`]);
        });
        */

        let renewalPlans = quote[`renewalMedicalPlans`];
        /*
        Object.keys(coverageTypes).forEach(coverageType => {
            renewalPlans = renewalPlans.concat(quote[`renewal${util.capitalize(coverageTypes[coverageType])}Plans`]);
        });
        */

        let newPlans = [];
        if (selectedPlans.medical && selectedPlans.medical.length > 0) {
            newPlans = selectedPlans.medical;
        }
        /*else {
           Object.keys(coverageTypes).forEach(coverageType => {
               newPlans = newPlans.concat(quote[`new${util.capitalize(coverageTypes[coverageType])}Plans`]);
           });
        }*/

        return {
            currentPlans,
            renewalPlans,
            newPlans,
        };
    };

    _getPlanOptionItem = (item, type) => {
        return {
            value: item,
            type: type,
            label: `${item.displayName} - ${item.individualDeductibleIn} ded`,
        };
    };

    _getPlanList = (index) => {
        const plansByType = this._getPlansByCoverageType();
        const planList = [];

        if (index === 0) {
            //current
            const currentPlans = plansByType.currentPlans.map(item => this._getPlanOptionItem(item, "current"));
            if (currentPlans.length > 0) {
                planList.push({
                    label: "Current Plans",
                    options: currentPlans,
                });
            }
        }
        else if (index === 1) {
            //renewal
            const renewalPlans = plansByType.renewalPlans.map(item => this._getPlanOptionItem(item, "renewal"));
            if (renewalPlans.length > 0) {
                planList.push({
                    label: "Renewal Plans",
                    options: renewalPlans,
                });
            }
        }
        else {
            //current
            const currentPlans = plansByType.currentPlans.map(item => this._getPlanOptionItem(item, "current"));
            if (currentPlans.length > 0) {
                planList.push({
                    label: "Current Plans",
                    options: currentPlans,
                });
            }
            //renewal
            const renewalPlans = plansByType.renewalPlans.map(item => this._getPlanOptionItem(item, "renewal"));
            if (renewalPlans.length > 0) {
                planList.push({
                    label: "Renewal Plans",
                    options: renewalPlans,
                });
            }
            //new
            const newPlans = plansByType.newPlans.map(item => this._getPlanOptionItem(item, "new"));
            if (newPlans.length > 0) {
                const grouped = newPlans.reduce((rv, x) => {
                    const network = x.value["carrierAbbr"] === "UHC" && x.value["packageName"] ? " (" + x.value["packageName"] + ")" : x.value["networkName"] ? " (" + x.value["networkName"] + ")" : " (No Network)";
                    (rv[x.value["carrierAbbr"] + network] = rv[x.value["carrierAbbr"] + network] || []).push(x);
                    return rv;
                }, {});

                Object.keys(grouped).forEach(key => {
                    planList.push({
                        label: key,
                        options: sortBy(grouped[key], p => p.label),
                    });
                });
            }
        }
        //return
        return planList;
    };

    _selectedPlanChanged = e => {
        const index = parseInt(e.name);
        const { plans } = this.state;
        if (e.value.label.indexOf("Add all") === -1) {
            //move current/renewal/new to the stored value
            e.value.value.type = e.value.type;
            plans[index] = e.value.value;
        }

        //update state and then move to the next spot
        this.setState({
            plans
        }, () => {
            if (index > 3) {
                this._shiftRight(index);
            }
        });
    };

    _removePlan = index => {
        const { plans } = this.state;
        plans[index] = {};
        this.setState({ plans });
    };

    _getSelectedPlans = () => {
        const { plans } = this.state;
        const selectedPlans = [];

        plans.forEach(p => {
            let plan = {
                id: p.id,
                type: p.type ? p.type : p.id ? "new" : undefined,
            };

            selectedPlans.push(plan);
        });

        return selectedPlans;
    };

    _saveClicked = () => {
        const { plans } = this.state;
        if (this.state.showSaveModal) {
            const spreadsheetName = this.state.inputs[inputNames.spreadsheetName];
            if (!spreadsheetName) {
                const errors = {};
                errors[inputNames.spreadsheetName] = strings.required;
                this.setState({ errors });
            } else {
                const spreadsheet = {
                    name: spreadsheetName,
                    rateType: this.state.inputs[inputNames.rateType],
                    plans: this._getSelectedPlans(),
                }

                this.props.onSave(spreadsheet);

                this.setState({
                    showSaveModal: false,
                    selectedSpreadsheetName: null,
                    plans: Array.apply(null, Array(24)).map(() => {
                        return {};
                    }),
                }, () => {
                    toast.success("Save Successful", { toastId: 1 });
                    document.getElementById("scroller").scrollTo({ left: 0, behavior: "smooth" });
                });
            }
        } else {
            if (plans.filter(p => p.id).length === 0) {
                toast.warn(strings.plansRequiredMessage, { toastId: 1 });
            } else {
                this.setState({ showSaveModal: true });
            }
        }
    };

    _loadSpreadsheetChanged = value => {
        const { spreadsheets } = this.props;
        const { allPlans } = this.state;
        const spreadsheet = spreadsheets.singlePlan.find(s => s.name === value);
        const plansByType = this._getPlansByCoverageType();
        const missingPlan = { "id": "-1", "displayName": "#MISSING REFERENCE#", "carrierName": "#PLEASE RE-SELECT#" };

        if (spreadsheet) {
            const plans = Array.apply(null, Array(24)).map(() => {
                return {};
            });

            spreadsheet.plans.forEach((planItem, index) => {
                if (planItem.id) {
                    let plan = null;
                    if (!planItem.type || planItem.type === "new") {
                        plan = plansByType.newPlans.find(p => p.id === planItem.id);
                    } else if (planItem.type === "current") {
                        plan = plansByType.currentPlans.find(p => p.id === planItem.id);
                    } else if (planItem.type === "renewal") {
                        plan = plansByType.renewalPlans.find(p => p.id === planItem.id);
                    }
                    if (plan) {
                        plans[index] = plan;
                    } else {
                        let plan = allPlans.find(p => p.id === planItem.id);
                        if (plan) {
                            plans[index] = plan;
                        } else {
                            //attempt to load the plan failed... no longer selected or no longer available at all
                            plans[index] = missingPlan;
                            medicalPlansApi.getById(planItem.id).then(result => {
                                if (result) {
                                    toast.warn("Unable to load the plan for spot " + (index + 1) + ": '" + result.displayName + "' from '" + result.carrierName + ".'  Please return to the Current/Renewal/New Plans tab and make sure this plan is selected.", { toastId: index });
                                } else {
                                    toast.warn("Unable to load the plan for spot " + (index + 1) + " from available plans for this quote.  Please select a replacement for this spot.", { toastId: index });
                                }
                            });
                        }
                    }
                }
            });

            this.setState(prevState => {
                return {
                    inputs: {
                        ...prevState.inputs,
                        [inputNames.spreadsheetName]: spreadsheet.name,
                        [inputNames.rateType]: spreadsheet.rateType,
                    },
                    plans,
                    errors: [],
                    selectedSpreadsheetName: value,
                };
            });
        }
    };

    _rateTypeChanged = value => {
        let rateType;
        if (value) {
            rateType = "Age";
        } else {
            rateType = "Composite";
        }

        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.rateType]: rateType,
                },
            };
        });
    };

    _handleTextChange = e => {
        const { name } = e.target;
        const { value } = e.target;

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
                errors,
            };
        });
    };

    _shiftRight = index => {
        if (index > 22) {
            return;
        }
        //else
        if (index > 1) {
            document.getElementById("scroller").scrollTo({ left: 320 * (index - 1), behavior: "smooth" });
        } else {
            document.getElementById("scroller").scrollTo({ left: 0, behavior: "smooth" });
            index = 1;
        }
        this.setState({ scrollIndex: index });
    };

    _renderSpreadsheetsDropdown = () => {
        const { singlePlan } = this.props.spreadsheets;
        return (
            <div className={css(styles.loadSavedSpreadsheetContainer)}>
                {singlePlan && singlePlan.length > 0 &&
                    <Dropdown
                        aStyles={styles.loadSpreadsheetDropdown}
                        options={singlePlan.map(item => ({
                            label: item.name,
                            value: item.name,
                        }))}
                        value={this.state.selectedSpreadsheetName}
                        onChange={this._loadSpreadsheetChanged}
                        placeholder={strings.spreadsheetsPlaceholder}
                        hideLabel={true}
                        size="small"
                    />
                }
                <ActionButton
                    aStyles={styles.saveSpreadsheetButton}
                    label={strings.saveSpreadsheetButtonText}
                    type="button"
                    onClick={this._saveClicked}
                />
            </div>
        );
    };

    _renderPlan = (plan, index, currentPlanEERate, currentPlanAgeTotal, renewalPlanEERate, renewalPlanAgeTotal) => (
        <div className={css(styles.planDetailsContainer)} onClick={() => this._shiftRight(index)}>
            <div className={css(styles.planHeaderRow)}>
                <div className={css(styles.typeIndicator)}>{plan.type === "new" ? "" : plan.type}</div>
                <div
                    className={css(styles.removePlan)}
                    onClick={() => this._removePlan(index)}
                />
                <div className={css(styles.carrierLogoContainer)}>
                    {plan.carrierLogo &&
                        <img className={css(styles.carrierLogo)} src={plan.carrierLogo} alt="carrier-logo" />
                    }
                </div>
                <div className={css(styles.planTypeName)}>{plan.planTypeName}</div>
                <div className={css(styles.planMetallicLevel)}>{plan.metallicLevel}</div>
                <div className={css(styles.planHeader)} title={plan.networkName}>{plan.networkName}</div>
                <div className={css(styles.planHeader)} title={plan.displayName}>{plan.displayName}</div>
                <div className={css(styles.planHeader)}>{plan.carrierName}</div>
            </div>
            <div className={css(styles.planItem)}>{plan.embeddedDeductible}</div>
            <div className={css(styles.planItem)}>{plan.individualDeductibleIn === plan.individualDeductibleOut ? plan.individualDeductibleIn : plan.individualDeductibleIn + "/" + plan.individualDeductibleOut}</div>
            <div className={css(styles.planItem)}>{plan.familyDeductibleIn === plan.familyDeductibleOut ? plan.familyDeductibleIn : plan.familyDeductibleIn + "/" + plan.familyDeductibleOut}</div>
            <div className={css(styles.planItem)}>{plan.coInsuranceIn && plan.coInsuranceIn.indexOf("%") > -1 ? plan.coInsuranceIn : plan.coInsuranceIn ? (plan.coInsuranceIn * 100).toString() + "%" : null}</div>
            <div className={css(styles.planItem)}>{plan.individualOOPIn === plan.individualOOPOut ? plan.individualOOPIn : plan.individualOOPIn + "/" + plan.individualOOPOut}</div>
            <div className={css(styles.planItem)}>{plan.familyOOPIn === plan.familyOOPOut ? plan.familyOOPIn : plan.familyOOPIn + "/" + plan.familyOOPOut}</div>
            <div className={css(styles.planItem)}>{plan.officeVisitCopayIn}</div>
            <div className={css(styles.planItem)}>{plan.specialistVisitCopayIn}</div>
            <div className={css(styles.planItem)}>{plan.teleHealth}</div>
            <div className={css(styles.planItem)}>{plan.labXRayIn}</div>
            <div className={css(styles.planItem)}>{plan.advancedImagingIn}</div>
            <div className={css(styles.planItem)}>{plan.individualDrugDeductibleIn === plan.familyDrugDeductibleIn ? plan.individualDrugDeductibleIn : plan.individualDrugDeductibleIn + "/" + plan.familyDrugDeductibleIn}</div>
            <div className={css(styles.planItem)}>{plan.rxCardIn}</div>
            <div className={css(styles.planItem)}>{plan.specialtyMedicationIn}</div>
            <div className={css(styles.planItem)}>{plan.mailOrderIn}</div>
            <div className={css(styles.planItem)}>{plan.urgentCareIn}</div>
            <div className={css(styles.planItem)}>{plan.erIn === plan.erOut ? plan.erIn : plan.erIn + "/" + plan.erOut}</div>
            <div className={css(styles.planItem)}>{plan.inpatientHospitalizationIn}</div>
            <div className={css(styles.planItem)}>{plan.outpatientSurgeryIn}</div>
            {this.state.inputs[inputNames.rateType] === "Age" ? (
                <React.Fragment>
                    <div className={css(styles.planItem, styles.premium)}>{plan.ageMonthlyPremium ? plan.ageMonthlyPremium.toString() : "n/a"}</div>
                    <div className={css(styles.planItem, styles.premium)}>{plan.ageAnnualPremium ? plan.ageAnnualPremium.toString() : "n/a"}</div>
                    <div className={css(styles.planItem, styles.premium)}>{plan.ageEmployeePremium ? plan.ageEmployeePremium.toString() : "n/a"}</div>
                    <div className={css(styles.planItem, styles.premium)}>{plan.ageDependentPremium ? plan.ageDependentPremium.toString() : "n/a"}</div>
                    <div className={css(styles.planItem, styles.premium)}>{currentPlanAgeTotal && currentPlanAgeTotal > 0 && plan.ageMonthlyPremium && Number(plan.ageMonthlyPremium.replace(/[^0-9.-]+/g, '')) > 0 ? (Number(plan.ageMonthlyPremium.replace(/[^0-9.-]+/g, '')) > currentPlanAgeTotal ? "+" : "") + (100 * ((Number(plan.ageMonthlyPremium.replace(/[^0-9.-]+/g, '')) - currentPlanAgeTotal) / currentPlanAgeTotal)).toFixed(0) + "%" : "n/a"}</div>
                    <div className={css(styles.planItem, styles.premium)}>{renewalPlanAgeTotal && renewalPlanAgeTotal > 0 && plan.ageMonthlyPremium && Number(plan.ageMonthlyPremium.replace(/[^0-9.-]+/g, '')) > 0 ? (Number(plan.ageMonthlyPremium.replace(/[^0-9.-]+/g, '')) > renewalPlanAgeTotal ? "+" : "") + (100 * ((Number(plan.ageMonthlyPremium.replace(/[^0-9.-]+/g, '')) - renewalPlanAgeTotal) / renewalPlanAgeTotal)).toFixed(0) + "%" : "n/a"}</div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div className={css(styles.planItem, styles.premium)}>{plan.monthlyPremium ? plan.monthlyPremium.toString() : "n/a"}</div>
                    <div className={css(styles.planItem, styles.premium)}>{plan.annualPremium ? plan.annualPremium.toString() : "n/a"}</div>
                    <div className={css(styles.planItem, styles.premium)}>{plan.ee ? plan.ee.toString() : "n/a"}</div>
                    <div className={css(styles.planItem, styles.premium)}>{plan.es ? plan.es.toString() : "n/a"}</div>
                    <div className={css(styles.planItem, styles.premium)}>{plan.ec ? plan.ec.toString() : "n/a"}</div>
                    <div className={css(styles.planItem, styles.premium)}>{plan.ef ? plan.ef.toString() : "n/a"}</div>
                    <div className={css(styles.planItem, styles.premium)}>{currentPlanEERate && currentPlanEERate > 0 && plan.ee && Number(plan.ee.replace(/[^0-9.-]+/g, '')) > 0 ? (Number(plan.ee.replace(/[^0-9.-]+/g, '')) > currentPlanEERate ? "+" : "") + (100 * ((Number(plan.ee.replace(/[^0-9.-]+/g, '')) - currentPlanEERate) / currentPlanEERate)).toFixed(0) + "%" : "n/a"}</div>
                    <div className={css(styles.planItem, styles.premium)}>{renewalPlanEERate && renewalPlanEERate > 0 && plan.ee && Number(plan.ee.replace(/[^0-9.-]+/g, '')) > 0 ? (Number(plan.ee.replace(/[^0-9.-]+/g, '')) > renewalPlanEERate ? "+" : "") + (100 * ((Number(plan.ee.replace(/[^0-9.-]+/g, '')) - renewalPlanEERate) / renewalPlanEERate)).toFixed(0) + "%" : "n/a"}</div>
                </React.Fragment>
            )}
        </div>
    );

    render() {
        const { quote } = this.props;
        const { allPlans, plans, showSaveModal, scrollIndex } = this.state;
        const isAgeRated = this.state.inputs[inputNames.rateType] === "Age";
        const premiums = isAgeRated ? ageRatedPremiums : compositePremiums;
        const employeeContribution = quote.employeeContribution ? quote.employeeContribution : "50%";
        const dependentContribution = quote.dependentContribution ? quote.dependentContribution : "0%";
        const currentPlanEERate = plans && plans[0].id && plans[0].ee ? Number(plans[0].ee.replace(/[^0-9.-]+/g, '')) : 0;
        const currentPlanAgeTotal = plans && plans[0].id && plans[0].ageMonthlyPremiumALT ? parseFloat(plans[0].ageMonthlyPremiumALT) : 0;
        const renewalPlanEERate = plans && plans[1].id && plans[1].ee ? Number(plans[1].ee.replace(/[^0-9.-]+/g, '')) : 0;
        const renewalPlanAgeTotal = plans && plans[1].id && plans[1].ageMonthlyPremiumALT ? parseFloat(plans[1].ageMonthlyPremiumALT) : 0;

        return (
            <React.Fragment>
                <ToastContainer position="top-center" autoClose={3500} />
                <Modal
                    show={showSaveModal}
                    header="Save Spreadsheet"
                >
                    <div className={css(styles.modalContent)}>
                        <div className={css(styles.modalInstructions)}>
                            {strings.spreadsheetNameInstructions}
                        </div>
                        <TextInput
                            name={inputNames.spreadsheetName}
                            onChange={this._handleTextChange}
                            placeholder={strings.spreadsheetNamePlaceholder}
                            required={true}
                            validationMessage={this.state.errors[inputNames.spreadsheetName]}
                            value={this.state.inputs[inputNames.spreadsheetName]}
                            size={inputSizes.small}
                            hideLabel={true}
                            maxLength={30}
                        />
                    </div>
                    <div className={css(styles.modalButtonContainer)}>
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.cancelButtonText}
                            invertColor={true}
                            type="button"
                            onClick={() => this.setState({ showSaveModal: false })}
                        />
                        <ActionButton
                            aStyles={styles.modalButton}
                            label={strings.saveButtonText}
                            onClick={this._saveClicked}
                            type="button"
                            isLoading={this.state.isSaving}
                        />
                    </div>
                </Modal>
                {allPlans.length === 0 && quote.newMedicalPlans.length === 0 &&
                    <div className={css(styles.noPlansContainer)}>{strings.noPlansAvailable}</div>
                }
                {this._renderSpreadsheetsDropdown()}
                <div className={css(styles.container)}>
                    <div className={css(styles.benefitsContainer)}>
                        <div className={css(styles.benefitsColumn)}>
                            <div className={css(styles.benefitsHeaderRow)}>
                                <div className={css(styles.benefitsHeader)}>
                                    <div className={css(styles.benefitsInfo)}>Select Plans to show % differential</div>
                                    <NestedDropdown
                                        aStyles={styles.planDropdown}
                                        options={this._getPlanList(0)}
                                        value={plans[0].id ? plans[0].id.toString() : null}
                                        onSelect={this._selectedPlanChanged}
                                        name={"0"}
                                        placeholder={plans[0].id ? "Cur=" + plans[0].displayName.substring(0, 27) : strings.currentPlansPlaceholder}
                                        openLeft={false}
                                    />
                                    <NestedDropdown
                                        aStyles={styles.planDropdown}
                                        options={this._getPlanList(1)}
                                        value={plans[1].id ? plans[1].id.toString() : null}
                                        onSelect={this._selectedPlanChanged}
                                        name={"1"}
                                        placeholder={plans[1].id ? "Ren=" + plans[1].displayName.substring(0, 27) : strings.renewalPlansPlaceholder}
                                        openLeft={false}
                                    />
                                    <span className={css(styles.benefitsInfo)}>Rates:</span>
                                    <Toggle
                                        aStyles={styles.toggle}
                                        checked={isAgeRated}
                                        onChange={this._rateTypeChanged}
                                        checkedLabel="Age"
                                        uncheckedLabel="Comp."
                                    />
                                    <div className={css(styles.benefitsInfo, styles.benefitsPadding)}>Contributions: <span className={css(styles.benefitsRightAlign)}>EE {employeeContribution}, DEP {dependentContribution}</span></div>
                                </div>
                            </div>
                            {benefits.map(value => (
                                <div
                                    key={value}
                                    className={css(styles.item)}
                                >
                                    {value}
                                </div>
                            ))}
                            {premiums.map(value => (
                                <div
                                    key={value}
                                    className={css(styles.item, styles.premium)}
                                >
                                    {value}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={css(styles.plansContainer)} id="scroller">
                        <div className={css(styles.scrollButtons)}>
                            <img src={backCircle} className={css(styles.scrollButton)} alt="left" onClick={() => this._shiftRight(scrollIndex - 1)} />
                            <img src={backCircle} className={css(styles.scrollButton, styles.rotate)} alt="right" onClick={() => this._shiftRight(scrollIndex + 1)} />
                        </div>
                        {plans.map((planItem, index) => (
                            <div className={css(styles.planColumn, index < 2 ? styles.hide : "")} key={index}>
                                <div className={css(styles.planDetails)}>
                                    <NestedDropdown
                                        aStyles={styles.planDropdown}
                                        options={this._getPlanList(index)}
                                        value={plans[index].id ? plans[index].id.toString() : null}
                                        onSelect={this._selectedPlanChanged}
                                        name={index.toString()}
                                        placeholder={strings.plansPlaceholder}
                                        openLeft={index < 23 ? false : true}
                                    />
                                    {planItem.id ? (
                                        this._renderPlan(planItem, index, currentPlanEERate, currentPlanAgeTotal, renewalPlanEERate, renewalPlanAgeTotal)
                                    ) : (
                                        <div className={css(styles.emptyPlan)}>
                                            <div className={css(styles.emptyPlanPlus)}>+</div>
                                            <div className={css(styles.emptyPlanText)}>
                                                {strings.emptyPlanText}{" "}{index - 1}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={css(styles.buttonContainer)}>
                        {false &&
                            <ActionButton
                                aStyles={styles.backButon}
                                label="Back"
                                invertColor={true}
                                type="button"
                                onClick={this.props.onCancel}
                            />
                        }

                        <ActionButton
                            aStyles={styles.saveButton}
                            label="Save Spreadsheet"
                            onClick={this._saveClicked}
                            type="button"
                            isLoading={this.state.isSaving}
                        />

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = state => ({
    quote: state.proposal.quote,
    selectedPlans: state.proposal.selectedPlans,
    spreadsheets: state.proposal.spreadsheets,
});

export default connect(
    mapDispatchToProps,
    null,
)(SinglePlanSpreadsheet);
