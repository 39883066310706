import PropTypes from "prop-types";
import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import * as color from "../../constants/color";

const styles = StyleSheet.create({
    container: {
        display: "flex",
        justifyContent: "center",
        borderRadius: 4,
        border: `1px solid ${color.mediumGray}`,
        paddingTop: 12,
        paddingBottom: 12,
    },
    item: {
        marginLeft: 18,
        marginRight: 18,
    },
    itemWrapper: {
        borderRadius: "50%",
        padding: 4,
        border: `2px solid ${color.white}`,
    },
    active: {
        border: `2px solid ${color.darkBlue2}`,        
    },
    dot: {
        height: 38,
        width: 38,
        borderRadius: "50%",
        cursor: "pointer",
        borderWidth: 1,
        borderStyle: "solid",
        color: "#000000",
    },
    itemLabel: {
        color: color.darkGray,
        fontSize: 14,
        textAlign: "center",
        marginTop: 6,
    }
});

const options = [
    { text: "Bronze", value: 3 },
    { text: "Silver", value: 2 },
    { text: "Gold", value: 1 },
    { text: "Platinum", value: 4 }    
];

export default class MetallicLevel extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        value: PropTypes.number,
    };

    _getFillColor = text => {
        if (text === "Bronze" || text === "Expanded Bronze") {
            return "#cd7f32";
        } else if (text === "Silver") {
            return "#c0c0c0";
        } else if (text === "Gold") {
            return "#ffd700";
        } else if (text === "Platinum") {
            return "#f7f7f7";
        }
        return "";
    }

    _getBorderColor = text => {
        if (text === "Bronze") {
            return "#C3905D";
        } else if (text === "Silver") {
            return "#B8BEC3";
        } else if (text === "Gold") {
            return "#CFB31D";
        } else if (text === "Platinum") {
            return "#B1B1B1";
        }
        return "";
    }

    _handleClick = color => {
        this.props.onChange(color);
    }

    render() {        
        return (
            <div
                className={css(styles.container)}
            >
                {options.map(opt => (
                    <div className={css(styles.item)} onClick={e => this._handleClick(opt.value)} key={opt.value}>
                        <div className={css(styles.itemWrapper, opt.value === this.props.value ? styles.active : null)}>
                            <div className={css(styles.dot)} style={{ backgroundColor: this._getFillColor(opt.text), borderColor: this._getBorderColor(opt.text)}}>{opt.text === "Bronze" ? "+" : ""}{opt.text}</div>
                        </div>
                        <div className={css(styles.itemLabel)}>{opt.text}</div>
                    </div>
                ))}
            </div>
        );
    }
}