import * as sessionActions from "../../actions/sessionActions";
import axios from "axios";
import qs from "query-string";
import store from "../../store";
import { createInterceptor } from "axios-oauth";
import ls from "local-storage";

let instance = axios.create({
    baseURL: process.env.REACT_APP_API_ROOT,
});

const refreshInstance = axios.create({
    baseURL: process.env.REACT_APP_API_ROOT,
});

if (process.env.NODE_ENV === "development") {
    const { createInterceptor: createLogInterceptor } = require("axios-logger");
    const axiosLogger = createLogInterceptor();

    instance.interceptors.request.use(axiosLogger.request);
    instance.interceptors.response.use(axiosLogger.response, axiosLogger.failure);

    refreshInstance.interceptors.request.use(axiosLogger.request);
    refreshInstance.interceptors.response.use(axiosLogger.response, axiosLogger.failure);
}

if (process.env.REACT_APP_MOCK_API === "true") {
    const apiMock = require("./apiMock");
    apiMock.setMockResponses(instance);
}

const oauthInterceptor = createInterceptor({
    axiosInstance: refreshInstance,
    getAuthData: () => {
        const state = store.getState();
        return {
            accessToken: state.session.accessToken,
            expireDate: state.session.expireDate,
            refreshToken: state.session.refreshToken,
        };
    },
    getRefreshConfig: refreshToken => ({
        url: "/account/refresh",
        method: "POST",
        data: qs.stringify({
            grant_type: "refresh_token",
            refresh_token: refreshToken,
        }),
        headers: {
            Authorization: `Basic ${process.env.REACT_APP_BASIC_AUTH}`,
            "Content-Type": "application/x-www-form-urlencoded",
        },
    }),
    onSignIn: response => {
        store.dispatch(sessionActions.storeAndSignIn(response.data));
    },
    onSignOut: () => {
        store.dispatch(sessionActions.signOut());
    },
    verbose: process.env.NODE_ENV === "development",
});

instance.interceptors.request.use(oauthInterceptor.request);
instance.interceptors.response.use(null, oauthInterceptor.failure);

instance.interceptors.request.use(config => {
    //defaults for all calls
    config.headers["Cache-Control"] = "No-Cache";
    config.headers["Rbghubcustomheader"] = "9@eDvb5HgRCI74";
    //previously selected office
    const selectedOfficeId = ls.get("SelectedOfficeId");
    if (selectedOfficeId) {
        config.headers.SelectedOfficeId = selectedOfficeId;
    }
    return config;
});

export default instance;
