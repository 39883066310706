import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Tabs from "../Tabs";
import Plans from "./Plans";
import coverageTypes from "../../constants/coverageTypes";
import * as planTypes from "../../constants/planTypes";
import * as texchangePropTypes from "../../texchangePropTypes";
import SelectedPlans from "./SelectedPlans";

export class RenewalPlans extends Component {
    static propTypes = {
        quote: texchangePropTypes.quote.isRequired,
        isVisible: PropTypes.bool.isRequired,
        selectedPlans: PropTypes.object.isRequired,
        onContinue: PropTypes.func,
    };

	render() {
        const { quote, isVisible, selectedPlans } = this.props;

        const curMedCount = quote.currentMedicalPlans && quote.currentMedicalPlans.length > 0 ? quote.currentMedicalPlans.length : null;
        const renMedCount = quote.renewalMedicalPlans && quote.renewalMedicalPlans.length > 0 ? quote.renewalMedicalPlans.length : null;
        const newMedCount = selectedPlans.medical && selectedPlans.medical.length > 0 ? selectedPlans.medical.length : null;
        const curDenCount = quote.currentDentalPlans && quote.currentDentalPlans.length > 0 ? quote.currentDentalPlans.length : null;
        const renDenCount = quote.renewalDentalPlans && quote.renewalDentalPlans.length > 0 ? quote.renewalDentalPlans.length : null;
        const newDenCount = selectedPlans.dental && selectedPlans.dental.length > 0 ? selectedPlans.dental.length : null;
        const curVisCount = quote.currentVisionPlans && quote.currentVisionPlans.length > 0 ? quote.currentVisionPlans.length : null;
        const renVisCount = quote.renewalVisionPlans && quote.renewalVisionPlans.length > 0 ? quote.renewalVisionPlans.length : null;
        const newVisCount = selectedPlans.vision && selectedPlans.vision.length > 0 ? selectedPlans.vision.length : null;
        const curLifCount = quote.currentLifePlans && quote.currentLifePlans.length > 0 ? quote.currentLifePlans.length : null;
        const renLifCount = quote.renewalLifePlans && quote.renewalLifePlans.length > 0 ? quote.renewalLifePlans.length : null;
        const newLifCount = selectedPlans.life && selectedPlans.life.length > 0 ? selectedPlans.life.length : null;
        //count them all up
        const allCount = curMedCount + renMedCount + newMedCount + 
                         curDenCount + renDenCount + newDenCount +
                         curVisCount + renVisCount + newVisCount +
                         curLifCount + renLifCount + newLifCount;

        //census election counts
        const census = quote.census;
        const medCensusCount = census ? census.filter(x => x.relationship === "EE" && x.medicalCoverageId < 5).length : 0;
        const denCensusCount = census ? census.filter(x => x.relationship === "EE" && x.dentalCoverageId < 5).length : 0;
        const visCensusCount = census ? census.filter(x => x.relationship === "EE" && x.visionCoverageId < 5).length : 0;
        const lifCensusCount = census ? census.filter(x => x.relationship === "EE" && x.lifeCoverageId < 5).length : 0;

        return (
            <Tabs isVisible={isVisible}>
                {medCensusCount > 0 &&
                    <div label="Medical" count={renMedCount}>
                        <Plans
                            quote={quote}
                            planType={planTypes.renewalPlan}
                            coverageType={coverageTypes.medical}
                            onContinue={this.props.onContinue}
                        />
                    </div>
                }
                {denCensusCount > 0 &&
                    <div label="Dental" count={renDenCount}>
                        <Plans
                            quote={quote}
                            planType={planTypes.renewalPlan}
                            coverageType={coverageTypes.dental}
                            onContinue={this.props.onContinue}
                        />
                    </div>
                }
                {visCensusCount > 0 &&
                    <div label="Vision" count={renVisCount}>
                        <Plans
                            quote={quote}
                            planType={planTypes.renewalPlan}
                            coverageType={coverageTypes.vision}
                            onContinue={this.props.onContinue}
                        />
                    </div>
                }
                {/*lifCensusCount > 0 &&
                    <div label="Life" count={renLifCount}>
                        <Plans
                            quote={quote}
                            planType={planTypes.renewalPlan}
                            coverageType={coverageTypes.life}
                            onContinue={this.props.onContinue}
                        />
                    </div>
                */}
                <div label="Selected" count={allCount}>
                    <SelectedPlans
                        quote={quote}
                    />
                </div>
            </Tabs>
        );
    }
}

const mapDispatchToProps = state => ({
    selectedPlans: state.proposal.selectedPlans,
});

export default connect(
    mapDispatchToProps,
)(RenewalPlans);