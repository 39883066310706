import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import * as zIndexes from "../constants/zIndexes";
import * as texchangePropTypes from "../texchangePropTypes";
import * as color from "../constants/color";

const styles = StyleSheet.create({
    modalBackground: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: zIndexes.OVERLAY,
        background: "rgba(0, 0, 0, 0.4)",
    },
    modalContainer: {
        position: "fixed",
        background: "white",
        height: "auto",
        maxHeight: "85%",
        top: "50%",
        left: "50%",
        transform: "translate(-50% , -50%)",
        boxShadow: "0 5px 5px -3px rgba(0,0,0,0.2), 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12)",
    },
    headerContainer: {
        backgroundColor: color.mediumRed,
        color: color.white,
        fontSize: 20,
        fontWeight: 500,
        textAlign: "center",
        borderBottom: `1px solid ${color.darkRed}`,
        paddingTop: 14,
        paddingBottom: 14,
    },
    modalContent: {
        margin: "0px auto",
        padding: "30px 30px",
    },
});

export default class Modal extends PureComponent {
	static propTypes = {
        show: PropTypes.bool,
        children: PropTypes.node,
        header: PropTypes.string,
        aStyles: texchangePropTypes.aphroditeStyles,
        contentContainerStyles: texchangePropTypes.aphroditeStyles,
    };
	
	render() {
		const { show, children, header, aStyles, contentContainerStyles } = this.props;
		if (!show) {
			return null;
		}

		return (
			<div className={css(styles.modalBackground)}>
				<div className={css(styles.modalContainer, aStyles)}>
					{header && 
						<div className={css(styles.headerContainer)}>
							{header}
						</div>
					}
					<div className={css(styles.modalContent, contentContainerStyles)}>
						{children}
					</div>					
				</div>
			</div>
		);
	}
}

