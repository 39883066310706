import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import TextInput from "../inputs/TextInput";
import Dropdown from "../inputs/Dropdown";
import Checkbox from "../inputs/Checkbox";
import Range from "../inputs/Range";
import Infinite from "../Infinite";
import FavoritePlanList from "./FavoritePlanList";
import * as dataApi from "../../lib/api/data";
import * as medicalPlansApi from "../../lib/api/medicalPlans";
import * as favoriteApi from "../../lib/api/favorite";
import * as inputSizes from "../../constants/inputSizes";
import * as color from "../../constants/color";

const styles = StyleSheet.create({
    content: {

    },
    filterContainer: {
        display: "flex",
        padding: "28px 24px"
    },
    inlineTable: {
        borderCollapse: "collapse",
        verticalAlign: "middle",
        marginLeft: 10,
    },
    searchInput: {
        width: 400,
    },
    tblCell: {
        backgroundColor: color.lightGray4,
        textAlign: "left",
        ":first-child": {
            width: 400,
        },
        ":nth-child(2)": {
            paddingLeft: 32,
            minWidth: 160,
        }
    },
    filterLabel: {
        fontWeight: 500,
        fontSize: 13,
        color: color.darkestGray,
        whiteSpace: "nowrap",
    },
    checkboxFilter: {
        marginBottom: 10,
        marginRight: 64,
        maxHeight: "210px",
        overflowY: "auto",
        paddingRight: 15,
    },
    planTypeCarriers: {
        marginLeft: 6,
        fontSize: 11,
        color: color.mediumGray,
    },
    checkLabel: {
        display: "block",
        position: "relative",
        border: "none",
        marginTop: 16,
        marginBottom: 16,
        "-webkit-appearance": "none",
        cursor: "pointer",
    },
    labelText: {
        marginLeft: 30,
        fontSize: 13,
        whiteSpace: "nowrap",
    },
    checkboxContainer: {
        marginTop: 16,
    },
    check: {
        position: "absolute",
        top: -2,
        left: 0,
        marginRight: 10,
    },
    pkgLabel: {
        marginLeft: 8,
    },
    pkgText: {
        fontStyle: "italic",
        color: color.darkRed,
    },
    rangeControlContainer: {
        width: "20%",
        flexDirection: "column",
    },
    rangeControlColumn: {
        width: "100%",
        paddingLeft: 64,
        paddingRight: 64,
        marginBottom: 42,
    },
    rangeControl: {
        marginTop: 18,
        maxWidth: "350px",
    },
    metalCircle: {
        height: "14px",
        width: "14px",
        borderRadius: "50%",
        display: "inline-block",
        marginLeft: "-4px",
        marginRight: "4px",
        textAlign: "center",
        fontSize: 13,
        fontWeight: "bold",
    },
    right: {
        float: "right",
        position: "relative",
        top: -38,
        right: 20,
        fontSize: 14,
        color: color.tableText,
    },
    inlineFilterContainer: {
        paddingTop: 1,
        paddingBottom: 1,
        backgroundColor: color.lightGray4,
        borderTop: `1px solid ${color.lightGray2}`,
        borderBottom: `1px solid ${color.lightGray2}`,
    },
    dropdownHolder: {
        marginRight: 24,
    },
    noResults: {
        textAlign: "center",
        color: color.darkGray,
        marginTop: 16,
    },
});

const strings = {
    planTypeFilterLabel: "Plan Type",
    carrierFilterLabel: "Carriers",
    tierFilterLabel: "Metal Tier",
    deductibleFilterLabel: "Deductible",
    coninsuranceFilterLabel: "Co-Insurance",
    planNameFilterLabel: "Search for plan",
};

const filterInputNames = {
    coinsuranceRange: "coinsuranceRange",
    deductibleRange: "deductibleRange",
    carriers: "carriers",
    networks: "networks",
    planTypes: "planTypes",
    tiers: "tiers",
    planName: "planName",
    packages: "packages",
};

let plansLoaded = false;
const dt = new Date();
const yr = dt.getMonth() < 10 ? dt.getFullYear().toString() : (dt.getFullYear() + 1).toString();

export class MedicalPlans extends Component {
    static propTypes = {
        refresh: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            plans: [],
            planTypes: [],
            planTypesFilter: [],
            planTypesFiltered: [],
            plansMore: false,
            plansCount: 0,
            carriers: [],
            states: [],
            planColumnLabels: ["Carrier", "Plan", "Network", "Type", "Mtl", "Ded", "CoIns", "OOP", "Copay", "Rx", "SBC"],
            planColumnValues: ["carrierName", "displayName", "networkName", "planTypeName", "metallicLevel", "deductible", "coInsurance", "oop", "copay", "rxCard", "summaryPDFName"],
            favoriteClickParams: {
                pageX: 0,
                pageY: 0,
                show: false,
                planId: "",
                planType: "Medical",
            },
            filter: {
                state: "",
                carriers: [],
                tiers: [],
                planTypes: [],
                networks: [],
                year: yr,
                planName: "",
                packages: [],
                ratingArea: "",
            },
            tiers: [
                { id: 3, name: "Bronze" },
                { id: 5, name: "Exp. Bronze" },
                { id: 2, name: "Silver" },
                { id: 1, name: "Gold" },
                { id: 4, name: "Platinum" },
            ],
            coinsuranceMin: 0,
            coinsuranceMax: 100,
            deductibleMin: 0,
            deductibleMax: 100000,
        };
    }

    componentDidMount() {
        document.body.addEventListener("click", this._handleBodyClicked);

        this._mounted = true;
        this.setState({ loading: true });

        Promise.all([dataApi.getAreas(), dataApi.getMedicalPlanTypes(), dataApi.getMedicalPlanTypesFilter(0)])
            .then(responses => {
                if (responses && responses[0] && responses[1] && responses[2] && this._mounted) {
                    const selectedState = responses[0][0];
                    let selectedRatingArea = "";
                    //if CA, then auto-select Rating Area 1 to remove showing ALL of the duplicate plans for all 19 rating areas
                    if (selectedState.id === "32") {
                        selectedRatingArea = "1";
                    }

                    dataApi.getCarriers(this.state.filter.year, selectedState.id).then(result => {
                        this.setState(prevState => {
                            return {
                                carriers: result,
                                states: responses[0],
                                planTypes: responses[1],
                                planTypesFiltered: responses[1].filter(pt => pt.carrierId === result[0].id.toString() && pt.areaId === selectedState.id.toString()),
                                planTypesFilter: responses[2],
                                filter: {
                                    ...prevState.filter,
                                    state: selectedState.id.toString(),
                                    ratingArea: selectedRatingArea,
                                },
                            }
                        }, () => {
                            this._getPlans();
                        });
                    });
                }
            });
    }

    componentWillUnmount() {
        this._mounted = false;
        document.body.removeEventListener("click", this._handleBodyClicked);
    }

    componentDidUpdate(prevProps) {
        const { refresh } = this.props;
        if (prevProps.refresh !== refresh) {
            this._getPlans();
        }
    }

    _handleBodyClicked = e => {
        if (e.target && e.target.className && typeof e.target.className.includes === "function" && e.target.className.includes("modalBackground")) {
            this.setState(prevState => {
                return {
                    favoriteClickParams: {
                        ...prevState.favoriteClickParams,
                        show: false,
                    },
                };
            });
        }
    }

    _getPlans = () => {
        this.setState({ loading: true });

        const {
            filterCarriers,
            filterNetworks,
            filterPlanTypes,
            filterTiers,
            filterDeductibles,
            filterCoinsurance,
            filterPackages,
        } = this._getFilterParameters();

        medicalPlansApi.getPaged(1, null, null, null, this.state.filter.year, filterCarriers, filterNetworks, filterPlanTypes, filterTiers, filterDeductibles, this.state.filter[filterInputNames.planName], this.state.filter.state, filterCoinsurance, filterPackages, this.state.filter.ratingArea).then(result => {
            if (!plansLoaded) {
                if (result && result[0]) {
                    this.setState({
                        deductibleMax: result[0].maxDeductible,
                    });
                }
                plansLoaded = true;
            }

            if (result && result[0]) {
                this.setState({
                    plans: result,
                    plansCount: result[0].recordCount,
                    plansMore: result[0].recordCount > 100,
                });
            } else {
                this.setState({
                    plans: [],
                    plansCount: 0,
                    plansMore: false,
                });
            }

            this.setState({ loading: false });
        });
    };

    _coinsuranceRangeChanged = value => {
        this.setState(prevState => {
            return {
                filter: {
                    ...prevState.filter,
                    [filterInputNames.coinsuranceRange]: value,
                },
            };
        }, () => {
            this._getPlans();
        });
    };

    _deductibleRangeChanged = value => {
        this.setState(prevState => {
            return {
                filter: {
                    ...prevState.filter,
                    [filterInputNames.deductibleRange]: value,
                },
            };
        }, () => {
            this._getPlans();
        });
    };

    _changeState = item => {
        this.setState(prevState => {
            return {
                filter: {
                    ...prevState.filter,
                    state: item.toString(),
                    carriers: [],
                    packages: [],
                    ratingArea: item.toString() === "CA" ? "1" : "",
                    tiers: [],
                    planTypes: [],
                    networks: [],
                    planName: "",
                },
            }
        }, () => {
            dataApi.getCarriers(this.state.filter.year, item).then(result => {
                this.setState({
                    carriers: result,
                });
            });
            plansLoaded = false;
            this._getPlans();
        });
    };

    _changeYear = item => {
        this.setState(prevState => {
            return {
                filter: {
                    ...prevState.filter,
                    year: item.toString(),
                    carriers: [],
                    packages: [],
                    tiers: [],
                    planTypes: [],
                    networks: [],
                    planName: "",
                },
            }
        }, () => {
            dataApi.getCarriers(this.state.filter.year, this.state.filter.state).then(result => {
                this.setState({
                    carriers: result,
                });
            });
            plansLoaded = false;
            this._getPlans();
        });
    };

    _handleFilterTextChanged = e => {
        const { name } = e.target;
        let { value } = e.target;

        //save values to quote
        if (name === "employeeContribution" || name === "dependentContribution") {
            const { filter } = this.state;

            if (isNaN(parseInt(value))) { value = 0; }
            else { value = parseInt(value); }

            if (filter[filterInputNames.isPercentageContribution]) {
                if (value < 0) { value = 0; }
                else if (value > 100) { value = 100; }
            }
        }

        this.setState(prevState => {
            return {
                filter: {
                    ...prevState.filter,
                    [name]: value,
                },
            };
        }, () => {
            if (name === filterInputNames.planName && (value.length === 0 || value.length > 2)) {
                setTimeout(() => {
                    this._getPlans();
                }, 750);
            }
        });
    }

    _handleFilterCheckboxChanged = e => {
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;
        const currentOptions = this.state.filter[name];

        if (val && name === "packages") {
            //make sure parent is included
            const carrierOptions = this.state.filter.carriers;
            const parentCarrier = this.state.carriers.find(p => p.packages && p.packages.indexOf(value) > -1);
            if (parentCarrier && parentCarrier.id && !carrierOptions.includes(parentCarrier.id.toString())) {
                carrierOptions.push(parentCarrier.id.toString());
                this.setState(prevState => {
                    return {
                        filter: {
                            ...prevState.filter,
                            carriers: carrierOptions,
                        },
                    };
                });
            }
        }
        if (!val && name === "carriers") {
            //make sure children Packages are removed
            let packageOptions = this.state.filter.packages;
            const thisCarrier = this.state.carriers.find(p => p.id.toString() === value && p.packages);
            if (thisCarrier && (packageOptions.length === 1 && thisCarrier.packages.split(',').indexOf(packageOptions[0]) > -1) || (packageOptions.length > 1 && packageOptions.some(r => thisCarrier.packages.split(',').indexOf(r)))) {
                const thisPackages = thisCarrier.packages.split(',');
                packageOptions = packageOptions.filter((pkg) => !thisPackages.includes(pkg));
                this.setState(prevState => {
                    return {
                        filter: {
                            ...prevState.filter,
                            packages: packageOptions,
                        },
                    };
                });
            }
            //reset PlanTypes to empty
            let planTypeOptions = this.state.filter["planTypes"];
            if (thisCarrier && planTypeOptions.length > 0) {
                this.setState(prevState => {
                    return {
                        filter: {
                            ...prevState.filter,
                            ["planTypes"]: [],
                        },
                    };
                });
            }
        }

        if (val) {
            currentOptions.push(value);
        } else {
            const index = currentOptions.indexOf(value);
            if (index > -1) {
                currentOptions.splice(index, 1)
            }
        }

        this.setState(prevState => {
            return {
                filter: {
                    ...prevState.filter,
                    [name]: currentOptions,
                },
            };
        }, () => {
            this._getPlans();
        });
    };

    _getFilterParameters = () => {
        const { filter } = this.state;
        const filterCarriers = filter[filterInputNames.carriers].length > 0 ? filter[filterInputNames.carriers].join() : null;
        const filterNetworks = filter[filterInputNames.networks].length > 0 ? filter[filterInputNames.networks].join() : null;
        const filterPlanTypes = filter[filterInputNames.planTypes].length > 0 ? filter[filterInputNames.planTypes].join() : null;
        const filterTiers = filter[filterInputNames.tiers].length > 0 ? filter[filterInputNames.tiers].join() : null;
        const filterDeductibles = filter[filterInputNames.deductibleRange] && filter[filterInputNames.deductibleRange].length === 2 ? filter[filterInputNames.deductibleRange].join() : null;
        const filterCoinsurance = filter[filterInputNames.coinsuranceRange] && filter[filterInputNames.coinsuranceRange].length === 2 ? filter[filterInputNames.coinsuranceRange].join() : null;
        const filterPackages = filter[filterInputNames.packages].length > 0 ? filter[filterInputNames.packages].join() : null;

        return {
            filterCarriers,
            filterNetworks,
            filterPlanTypes,
            filterTiers,
            filterDeductibles,
            filterCoinsurance,
            filterPackages,
        };
    };

    _plansScroll = (page, col, dir) => {
        const {
            filterCarriers,
            filterNetworks,
            filterPlanTypes,
            filterTiers,
            filterDeductibles,
            filterCoinsurance,
            filterPackages,
        } = this._getFilterParameters();

        if (((page - 1) * 100) < this.state.plansCount) {
            medicalPlansApi.getPaged(page, null, null, null, this.state.filter.year, filterCarriers, filterNetworks, filterPlanTypes, filterTiers, filterDeductibles, null, this.state.filter.state, filterCoinsurance, filterPackages, this.state.filter.ratingArea).then(result => {
                let joined = {};
                if (page === 1) {
                    joined = result;
                } else {
                    joined = this.state.plans.concat(result);
                }
                this.setState({
                    plans: joined,
                });
            });
        } else {
            this.setState({
                plansMore: false,
            });
        }
    };

    _handleRowClick = id => {

    };

    _handleFavoriteClick = (e, id) => {
        this.setState({
            favoriteClickParams: {
                pageX: e.screenX,
                pageY: e.screenY,
                show: true,
                planId: id,
                planType: "Medical"
            }
        });
    };

    _handleFavoriteSaved = (name, ids) => {
        this._saveFavoritePlan(this.state.favoriteClickParams.planId, ids);
    };

    _handleFavoriteCanceled = () => {
        this.setState(prevState => {
            return {
                favoriteClickParams: {
                    ...prevState.favoriteClickParams,
                    show: false,
                },
            };
        });
    };

    _saveFavoritePlan = (planId, favoriteListIds) => {
        if (planId === "all") {
            const {
                filterCarriers,
                filterNetworks,
                filterPlanTypes,
                filterTiers,
                filterDeductibles,
                filterCoinsurance,
                filterPackages,
            } = this._getFilterParameters();

            medicalPlansApi.getPaged(1, null, null, null, this.state.filter.year, filterCarriers, filterNetworks, filterPlanTypes, filterTiers, filterDeductibles, this.state.filter[filterInputNames.planName], this.state.filter.state, filterCoinsurance, filterPackages, this.state.filter.ratingArea, true).then(result => {
                const planIds = result.map(item => item.id);
                favoriteApi.addPlans("medical", planIds, favoriteListIds)
                    .then(() => {
                        this._getPlans();

                        this.setState(prevState => {
                            return {
                                favoriteClickParams: {
                                    ...prevState.favoriteClickParams,
                                    show: false,
                                },
                            };
                        });
                    });
            });
        } else {
            favoriteApi.addPlan("medical", planId, favoriteListIds)
                .then(() => {
                    this._getPlans();

                    this.setState(prevState => {
                        return {
                            favoriteClickParams: {
                                ...prevState.favoriteClickParams,
                                show: false,
                            },
                        };
                    });
                });
        }
    }

    renderPlans = () => {
        const { plansMore, plans } = this.state;
        return (
            <div className={css(styles.contentSection)}>
                {plans.length > 0 ? (
                    <Infinite
                        results={plans}
                        length={plans.length}
                        columnLabels={this.state.planColumnLabels}
                        columnValues={this.state.planColumnValues}
                        showChevron={false}
                        showMore={plansMore}
                        onClick={this._handleRowClick}
                        onFavoriteClick={this._handleFavoriteClick}
                        showFavoriteAll={true}
                        onScroll={this._plansScroll} />
                ) : (
                    <div className={css(styles.noResults)}>{this.state.loading ? "Loading " : "No Matching "} Plans</div>
                )}
            </div>
        );
    }

    renderCheckboxes = (options, optionType) => {
        if (!options) {
            return null;
        }

        if (optionType === "planTypes") {
            options = [];
            const { carriers, planTypesFilter } = this.state;
            const selectedCarriers = this.state.filter["carriers"];
            const yr = this.state.filter["year"];
            const st = this.state.filter["state"];

            if (planTypesFilter && planTypesFilter.length > 0) {
                Object.keys(planTypesFilter).forEach(key => {
                    let split = planTypesFilter[key].split('|');
                    if (selectedCarriers && selectedCarriers.length > 0) {
                        if (selectedCarriers.indexOf(split[0]) > -1 && split[1] === yr && split[4] === st && options.indexOf(split[3]) === -1) {
                            options.push(split[3]);
                        }
                    } else {
                        if (split[1] === yr && split[4] === st && options.indexOf(split[3]) === -1 && carriers.some(c => c.id.toString() === split[0].toString())) {
                            options.push(split[3]);
                        }
                    }
                });
            }
        }

        return (
            <div className={css(styles.checkboxContainer)}>
                {options.map((option, index) => (
                    <div key={index}>
                        <label className={css(styles.checkLabel)}>
                            <Checkbox
                                aStyles={styles.check}
                                checked={this.state.filter[optionType].includes(option.id ? option.id.toString() : option)}
                                name={optionType}
                                value={option.id ? option.id : option}
                                onChange={this._handleFilterCheckboxChanged}
                            />
                            <span className={css(styles.labelText)}>
                                {optionType === "tiers" &&
                                    <span className={css(styles.metalCircle)} style={{ backgroundColor: (option.name === "Gold" ? "#ffd700" : option.name === "Silver" ? "#c0c0c0" : (option.name === "Bronze" || option.name === "Exp. Bronze") ? "#cd7f32" : option.name === "Platinum" ? "#f7f7f7" : option.name), border: (option.name === "Platinum" ? "solid 1px #cccccc" : "") }}>{option.name === "Exp. Bronze" ? "+" : ""}</span>
                                }
                                {option.name ? option.name : option}
                            </span>
                        </label>
                        {optionType === "carriers" && option.packages ? (
                            option.packages.split(',').map((pkg, idx) => (
                                <div key={idx}>
                                    <label className={css(styles.checkLabel, styles.pkgLabel)}>
                                        <Checkbox
                                            aStyles={styles.check}
                                            checked={this.state.filter["packages"].includes(pkg)}
                                            name="packages"
                                            value={pkg}
                                            onChange={this._handleFilterCheckboxChanged}
                                        />
                                        <span className={css(styles.labelText, styles.pkgText)}>
                                            {pkg}
                                        </span>
                                    </label>
                                </div>
                            ))
                        ) : null}
                    </div>
                ))}
            </div>
        );
    };


    renderFilters = () => {
        const { states, carriers, planTypes } = this.state;
        const selectedState = this.state.filter.state;
        const selectedYear = this.state.filter.year;
        const currentYear = new Date().getFullYear();
        const years = [currentYear, currentYear + 1];

        return (
            <div>
                <div className={css(styles.filterContainer)}>
                    <div className={css(styles.checkboxFilter)}>
                        <div className={css(styles.filterLabel)}>
                            {strings.carrierFilterLabel}
                        </div>
                        {this.renderCheckboxes(carriers, "carriers")}
                    </div>
                    <div className={css(styles.checkboxFilter)}>
                        <div className={css(styles.filterLabel)}>
                            {strings.planTypeFilterLabel}
                            {this.state.filter.selectedCarriers && this.state.filter.selectedCarriers.length > 0 ?
                                (<span className={css(styles.planTypeCarriers)}>Selected Carrier(s)</span>) :
                                (<span className={css(styles.planTypeCarriers)}>All Carriers</span>)
                            }
                        </div>
                        {this.renderCheckboxes(planTypes, "planTypes")}
                    </div>
                    <div className={css(styles.checkboxFilter)}>
                        <div className={css(styles.filterLabel)}>
                            {strings.tierFilterLabel}
                        </div>
                        {this.renderCheckboxes(this.state.tiers, "tiers")}
                    </div>
                    <div className={css(styles.rangeControlContainer)}>
                        <div className={css(styles.rangeControlColumn)}>
                            <div className={css(styles.filterLabel)}>
                                {strings.deductibleFilterLabel}
                            </div>
                            <div className={css(styles.rangeControl)}>
                                <Range
                                    defaultValue={[this.state.deductibleMin, this.state.deductibleMax]}
                                    value={this.state.filter[filterInputNames.deductibleRange] && this.state.filter[filterInputNames.deductibleRange].length === 2 ? this.state.filter[filterInputNames.deductibleRange] : [this.state.deductibleMin, this.state.deductibleMax]}
                                    step={10}
                                    min={this.state.deductibleMin}
                                    max={this.state.deductibleMax}
                                    onChange={this._deductibleRangeChanged}
                                />
                            </div>
                        </div>
                        <div className={css(styles.rangeControlColumn)}>
                            <div className={css(styles.filterLabel)}>
                                {strings.coninsuranceFilterLabel}
                            </div>
                            <div className={css(styles.rangeControl)}>
                                <Range
                                    defaultValue={[this.state.coinsuranceMin, this.state.coinsuranceMax]}
                                    value={this.state.filter[filterInputNames.coinsuranceRange] && this.state.filter[filterInputNames.coinsuranceRange].length === 2 ? this.state.filter[filterInputNames.coinsuranceRange] : [this.state.coinsuranceMin, this.state.coinsuranceMax]}
                                    step={10}
                                    min={this.state.coinsuranceMin}
                                    max={this.state.coinsuranceMax}
                                    onChange={this._coinsuranceRangeChanged}
                                    showAsPercent={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={css(styles.inlineFilterContainer)}>
                    <table className={css(styles.inlineTable)}>
                        <tbody>
                            <tr>
                                <td className={css(styles.tblCell)}>
                                    <TextInput
                                        aStyles={styles.searchInput}
                                        name={filterInputNames.planName}
                                        onChange={this._handleFilterTextChanged}
                                        placeholder={strings.planNameFilterLabel}
                                        value={this.state.filter[filterInputNames.planName]}
                                        hideLabel={true}
                                        size={inputSizes.small}
                                    />
                                </td>
                                <td className={css(styles.tblCell)}>
                                    <div className={css(styles.dropdownHolder)}>
                                        <Dropdown
                                            aStyles={styles.dropdown}
                                            options={states.map(item => ({
                                                label: item.name,
                                                value: item.id.toString()
                                            }))}
                                            value={selectedState ? selectedState : ""}
                                            placeholder="State"
                                            onChange={this._changeState}
                                            hideLabel={true}
                                            size={inputSizes.small}
                                        />
                                    </div>
                                </td>
                                <td className={css(styles.tblCell)}>
                                    <div className={css(styles.dropdownHolder)}>
                                        <Dropdown
                                            aStyles={styles.dropdown}
                                            options={years.map(item => ({
                                                label: item.toString(),
                                                value: item.toString()
                                            }))}
                                            value={selectedYear}
                                            placeholder="Year"
                                            onChange={this._changeYear}
                                            hideLabel={true}
                                            size={inputSizes.small}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className={css(styles.right)}>{parseInt(this.state.plansCount).toLocaleString()}{" "} Plans</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className={css(styles.content)}>
                {this.renderFilters()}
                {this.renderPlans()}

                <FavoritePlanList
                    params={this.state.favoriteClickParams}
                    onSave={this._handleFavoriteSaved}
                    onCancel={this._handleFavoriteCanceled}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({});

export default connect(
    mapStateToProps, {

}
)(MedicalPlans);