import * as color from "../../constants/color";
import * as searchInputTypes from "../../constants/searchInputTypes";
import * as routeHelpers from "../../lib/routeHelpers";
import TextInput from "../inputs/TextInput";
import PageTitle from "../text/PageTitle";
import Checkbox from "../inputs/Checkbox";
import Dropdown from "../inputs/Dropdown";
import logger from "../../lib/logger";
import * as texchangePropTypes from "../../texchangePropTypes";
import describeNetworkError from "../../lib/describeNetworkError";
import Headline from "../text/Headline";
import ActionButton from "../ActionButton";
import React, { Component } from "react";
import { Container, Row, Column } from "../grid";
import { StyleSheet, css } from "aphrodite";
import SearchInput from "../inputs/SearchInput";
import * as dataApi from "../../lib/api/data";
import * as searchApi from "../../lib/api/search";
import * as employerApi from "../../lib/api/employer";
import * as util from "../../lib/util";
import segmentSizes from "../../constants/segmentSizes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = StyleSheet.create({
    content: {
        paddingRight: 10,        
        paddingBottom: 80,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
    },
    groupForm: {
        padding: "0px 80px 0px 80px",
    },
    contentSection: {
        width: "100%",
        padding: 0,
        backgroundColor: color.white,
        borderTop: `8px solid ${color.mediumRed}`,
        marginBottom: 20,
    },
    title: {
        fontSize: 20,
        fontWeight: 500,        
        paddingBottom: 0,
        marginBottom: 4,
    },
    titleWithMargin: {
        fontSize: 20,
        fontWeight: 500,        
        paddingBottom: 0,
        marginTop: 26,
        marginBottom: 14,
    },
    titleSection: {        
        heigth: 64,
    },    
    instructions: {
        color: color.darkestGray,
        fontWeight: 300,
        fontSize: 20,
        marginLeft: 24,
    },    
    checkboxContainer: {        
        textAlign: "right",
        marginTop: 12,
    },
    checkLabel: {
        display: "inline-block",
        position: "relative",
        border: "none",
        marginTop: 8,
        marginBottom: 10,
        "-webkit-appearance": "none",
        cursor: "pointer",
        ":first-child": {
            marginRight: 24,
        }
    },
    check: {
        position: "absolute",
        top: -2,
        left: 0,
        marginRight: 10,
    },
    labelText: {
        marginLeft: 28,
        fontSize: 14,
        marginRight: 12,
    },    
    buttonContainer: {        
        height: 80,
        width: "60%",
        margin: "60px auto 0px auto",
    },
    buttons: {
        width: "100%",
        textAlign: "center",
    },    
    leftColumn: {
        marginRight: 24,
    },
    rightColumn: {
        marginLeft: 24,
    },
    brokerSearch: {
        zIndex: 7000,
    }
});

const strings = {
    title: "Add a New Group",
    instructions: "Please enter the new group information below",    
    generalInformation: "General Information",
    broker: "Broker",
    virginGroup: "Virgin group",
    testGroup: "Test group",
    namePlaceholder: "Group Name",
    contactNamePlaceholder: "Group Contact Name (First, Last)",
    phonePlaceholder: "Group Contact Phone #",
    streetAddressPlaceholder: "Street Address",
    streetAddress2Placeholder: "Street Address 2",
    statePlaceholder: "State",
    cityPlaceholder: "City",
    zipPlaceholder: "Zip",
    sicPlaceholder: "SIC",
    segmentSizePlaceholder: "Segment Size",
    brokerNamePlaceholder: "Enter Broker Name",
    salesRepresentative: "Coded Sales Representative",
    renewalRepresentative: "Renewal Representative",
    salesRepresentativePlaceholder: "Select Coded Sales Representative",
    renewalRepresentativePlaceholder: "Select Renewal Representative",
    payPeriodPlaceholder: "Select Pay Period",
    payPeriods: "Pay Periods",
    submitButton: "Save",
    cancelButton: "Cancel",
    required: "Please fill out this field",    
};

const inputNames = {
    virginGroup: "isVirgin",
    testGroup: "isTest",    
    name: "name",
    contactName: "contactName",
    phone: "phone",
    stateId: "stateId",
    street1: "street1",
    street2: "street2",
    city: "city",    
    postalCode: "postalCode",
    sicCodeId: "sicCodeId",    
    segmentSize: "segmentSize",    
    salesRepresentativeId: "salesRepresentativeId",
    renewalRepresentativeId: "renewalRepresentativeId",
    payrollCycleId: "payrollCycleId",
    brokerId: "brokerId",
};

export default class GroupForm extends Component {
    static propTypes = {
        history: texchangePropTypes.history.isRequired
    };

    state = {
        inputs: {
            [inputNames.virginGroup]: false,
            [inputNames.testGroup]: false,
            [inputNames.name]: "",
            [inputNames.contactName]: "",
            [inputNames.phone]: "",
            [inputNames.street1]: "",
            [inputNames.street2]: "",
            [inputNames.city]: "",
            [inputNames.stateId]: "",
            [inputNames.postalCode]: "",
            [inputNames.sicCodeId]: "",
            [inputNames.segmentSize]: "",            
            [inputNames.salesRepresentativeId]: null,
            [inputNames.renewalRepresentativeId]: null,
            [inputNames.payrollCycleId]: "",
            [inputNames.brokerId]: null,
        },
        loading: true,
        errors: {},
        states: [],
        users: [],
        payrollCycles: [],
        brokers: [],
    };

    componentDidMount() {
        Promise.all([ dataApi.getStates(), dataApi.getPayrollCycles(), dataApi.getUsers() ])
            .then(responses => {
                this.setState({
                    loading: false,
                    states: responses[0],
                    payrollCycles: responses[1],
                    users: responses[2],
                });
            })
            .catch(err => logger.log(err))
            .finally(() => this.setState({ ready: true }));
    }
     
    _handleTextChange = e => {
        const { name } = e.target;
        let { value } = e.target;

        if (name === inputNames.phone) {
            value = util.maskPhoneNumber(value);
        } else if (name === inputNames.postalCode) {
            value = value.replace(/[^0-9]+/g,"");
            //maxlength of 5
            if (value && value.length === 6) {
                return;
            }
        }

        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
            };
        });
    };

    _handleInputChange = e => {        
        const { checked, name, type, value } = e.target;
        const val = type === "checkbox" ? checked : value;

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: val,
                },
                errors,                
            };
        });
    };

    _brokerSelected = item => {                        
        this.setState(prevState => {            
            const errors = { ...prevState.errors };
            delete errors[inputNames.brokerId];

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.brokerId]: item.value,
                },                
                errors,
            };
        });
    };

    _sicCodeSelected = item => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.sicCodeId];

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.sicCodeId]: item.value,
                },                
                errors,
            };
        });
    }

    _goToGroups = () => {        
        this.props.history.push(routeHelpers.groups());
    };

    _handleStateChange = value => {        
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.stateId];
            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.stateId]: value,
                },                
                errors,
            };
        });        
    };

    _handleSalesRepresentativeChange = value => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.salesRepresentativeId];

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.salesRepresentativeId]: value,
                },                
                errors,
            };
        });
    };

    _handleRenewalRepresentativeChange = value => {
       this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.renewalRepresentativeId];

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.renewalRepresentativeId]: value,
                },                
                errors,
            };
        });
    };

    _handlePayPeriodChange = value => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.payrollCycleId];

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.payrollCycleId]: value,
                },                
                errors,
            };
        });       
    };

    _handleSegmentSizeChange = value => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[inputNames.segmentSize];

            return {
                inputs: {
                    ...prevState.inputs,
                    [inputNames.segmentSize]: value,
                },                
                errors,
            };
        });       
    };

    _validForm = () => {
        const errors = {};
        const { inputs } = this.state;

        Object.keys(inputs).forEach(stateKey => {
            if (stateKey === inputNames.street2 || stateKey === inputNames.phone || stateKey === inputNames.contactName) {
                return;
            }

            if (inputs[stateKey] === null || inputs[stateKey] === "") {
                errors[stateKey] = strings.required;
            }
        });

        this.setState({ errors });

        return !Object.keys(errors).length;
    };

    _submit = e => {
        e.preventDefault();
        if (this.state.loading) {
            return;
        }

        if (!this._validForm()) {
            return;
        }

        this.setState({ isSaving: true });

        const { inputs } = this.state;
        employerApi
            .saveEmployer(inputs)
            .then(() => {
                toast.success("Group added", { toastId: 1 });
                setTimeout(() => {
                    this._goToGroups();
                }, 2500);
            })
            .catch(err => {
                logger.warn(err);
                let errors = {};
                if (err && err.response) {
                    const { response } = err;
                    if (
                        response &&
                        response.data &&
                        response.data.errors &&
                        Object.keys(response.data.errors).length > 0
                    ) {
                        // Server provided validation messages
                        errors = response.data.errors;
                    } else {
                        // 500 or something else unexpected
                        const description = describeNetworkError(err);
                        toast.error(description.join("\n"), { toastId: 2 });
                    }
                }

                this.setState({ errors });
            })
            .finally(() => this.setState({ isSaving: false }));
    };
    
    
    render() {  
        if (this.state.loading) {
            return null;
        }
        
        return (
            <div className={css(styles.content)}>
                <ToastContainer position="top-center" autoClose={2500} />
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.titleSection)}>                        
                        <PageTitle>{strings.title}</PageTitle>                        
                    </div>
                    <p className={css(styles.instructions)}>
                        {strings.instructions}
                    </p>
                    <div className={css(styles.groupForm)}>
                        <form onSubmit={this._submit}>
                            <Container>
                                <Row>
                                    <Column large={6}>
                                        <div className={css(styles.leftColumn)}>
                                            <Headline aStyles={styles.title}>{strings.generalInformation}</Headline>
                                            <div className={css(styles.checkboxContainer)}>
                                                <label className={css(styles.checkLabel)}>
                                                    <Checkbox
                                                        aStyles={styles.check}
                                                        checked={this.state.inputs[inputNames.virginGroup]}                                                        
                                                        name={inputNames.virginGroup}
                                                        onChange={this._handleInputChange}
                                                    />
                                                    <span className={css(styles.labelText)}>{strings.virginGroup}</span>
                                                </label>
                                                <label className={css(styles.checkLabel)}>
                                                    <Checkbox
                                                        aStyles={styles.check}
                                                        checked={this.state.inputs[inputNames.testGroup]}                                                        
                                                        name={inputNames.testGroup}
                                                        onChange={this._handleInputChange}
                                                    />
                                                    <span className={css(styles.labelText)}>{strings.testGroup}</span>
                                                </label>
                                            </div>
                                            <TextInput
                                                name={inputNames.name}
                                                onChange={this._handleTextChange}
                                                placeholder={strings.namePlaceholder}
                                                required={true}
                                                validationMessage={this.state.errors[inputNames.name]}
                                                value={this.state.inputs[inputNames.name]}
                                            />
                                            <TextInput
                                                name={inputNames.contactName}
                                                onChange={this._handleTextChange}
                                                placeholder={strings.contactNamePlaceholder}
                                                value={this.state.inputs[inputNames.contactName]}
                                            />
                                            <TextInput
                                                name={inputNames.phone}
                                                onChange={this._handleTextChange}
                                                placeholder={strings.phonePlaceholder}
                                                value={this.state.inputs[inputNames.phone]}
                                            />
                                            <TextInput
                                                name={inputNames.street1}
                                                onChange={this._handleTextChange}
                                                placeholder={strings.streetAddressPlaceholder}
                                                required={true}                                            
                                                validationMessage={this.state.errors[inputNames.street1]}
                                                value={this.state.inputs[inputNames.street1]}
                                            />
                                            <TextInput
                                                name={inputNames.street2}
                                                onChange={this._handleTextChange}
                                                placeholder={strings.streetAddress2Placeholder}                                                                                                
                                                value={this.state.inputs[inputNames.street2]}
                                            />
                                            <TextInput
                                                name={inputNames.city}
                                                onChange={this._handleTextChange}
                                                placeholder={strings.cityPlaceholder}
                                                required={true}                                            
                                                validationMessage={this.state.errors[inputNames.city]}
                                                value={this.state.inputs[inputNames.city]}
                                            />
                                            <Dropdown
                                                options={Object.keys(this.state.states).map(id => ({
                                                    label: this.state.states[id].longName,
                                                    value: this.state.states[id].id.toString(),
                                                }))}
                                                onChange={this._handleStateChange}
                                                placeholder={strings.statePlaceholder}
                                                validationMessage={this.state.errors[inputNames.stateId]}
                                                value={this.state.inputs[inputNames.stateId]}
                                            />
                                            <TextInput
                                                name={inputNames.postalCode}
                                                onChange={this._handleTextChange}
                                                placeholder={strings.zipPlaceholder}
                                                required={true}                                            
                                                validationMessage={this.state.errors[inputNames.postalCode]}
                                                value={this.state.inputs[inputNames.postalCode]}                                                
                                            />
                                            <SearchInput
                                                search={searchApi.searchSICCodes}
                                                onItemSelected={this._sicCodeSelected}
                                                placeholder={strings.sicPlaceholder}
                                                validationMessage={this.state.errors[inputNames.sicCodeId]}
                                                required={true}
                                                type={searchInputTypes.textNoIcon}
                                            />
                                            <Dropdown
                                                options={segmentSizes.map(seg => ({
                                                    label: seg.value,
                                                    value: seg.value,
                                                }))}
                                                onChange={this._handleSegmentSizeChange}
                                                placeholder={strings.segmentSizePlaceholder}
                                                validationMessage={this.state.errors[inputNames.segmentSize]}
                                                value={this.state.inputs[inputNames.segmentSize]}
                                            />
                                        </div>
                                    </Column>                                    
                                    <Column large={6}>
                                        <div className={css(styles.rightColumn)}>
                                            <Headline aStyles={styles.title}>{strings.broker}</Headline>
                                            <SearchInput
                                                search={searchApi.searchBrokers}
                                                onItemSelected={this._brokerSelected}
                                                placeholder={strings.brokerNamePlaceholder}
                                                aStyles={styles.brokerSearch}
                                                type={searchInputTypes.text}
                                                validationMessage={this.state.errors[inputNames.brokerId]}
                                                hideLabel={true}
                                            />
                                            <Headline aStyles={styles.titleWithMargin}>{strings.salesRepresentative}</Headline>
                                            <Dropdown
                                                options={Object.keys(this.state.users).map(id => ({
                                                    label: this.state.users[id].lastName + ", " + this.state.users[id].firstName,
                                                    value: this.state.users[id].id.toString(),
                                                }))}
                                                onChange={this._handleSalesRepresentativeChange}
                                                placeholder={strings.salesRepresentativePlaceholder}
                                                validationMessage={this.state.errors[inputNames.salesRepresentativeId]}
                                                value={this.state.inputs[inputNames.salesRepresentativeId]}
                                                hideLabel={true}
                                            />
                                            <Headline aStyles={styles.titleWithMargin}>{strings.renewalRepresentative}</Headline>
                                            <Dropdown
                                                options={Object.keys(this.state.users).map(id => ({
                                                    label: this.state.users[id].lastName + ", " + this.state.users[id].firstName,
                                                    value: this.state.users[id].id.toString(),
                                                }))}
                                                onChange={this._handleRenewalRepresentativeChange}
                                                placeholder={strings.renewalRepresentativePlaceholder}
                                                validationMessage={this.state.errors[inputNames.renewalRepresentativeId]}
                                                value={this.state.inputs[inputNames.renewalRepresentativeId]}
                                                hideLabel={true}
                                            />
                                            <Headline aStyles={styles.titleWithMargin}>{strings.payPeriods}</Headline>
                                            <Dropdown
                                                options={Object.keys(this.state.payrollCycles).map(id => ({
                                                    label: this.state.payrollCycles[id].name,
                                                    value: this.state.payrollCycles[id].id.toString(),
                                                }))}
                                                onChange={this._handlePayPeriodChange}
                                                placeholder={strings.payPeriodPlaceholder}
                                                validationMessage={this.state.errors[inputNames.payrollCycleId]}
                                                value={this.state.inputs[inputNames.payrollCycleId]}
                                                hideLabel={true}                                                
                                            />
                                        </div>
                                    </Column>
                                </Row>
                                <Row>
                                    <div className={css(styles.buttonContainer)}>
                                        <Column large={6}>
                                            <ActionButton
                                                aStyles={styles.buttons}
                                                disabled={this.state.loading}
                                                label={strings.cancelButton}
                                                invertColor={true}
                                                type="button"
                                                onClick={this._goToGroups}                                            
                                            />
                                        </Column>
                                        <Column large={6}>
                                            <ActionButton                                    
                                                aStyles={styles.buttons}
                                                disabled={this.state.loading}
                                                label={strings.submitButton}
                                                type="submit"
                                                isLoading={this.state.isSaving}                                                
                                            />
                                        </Column>
                                    </div>
                                </Row>
                            </Container>
                        </form>
                    </div>
                </div>                
            </div>
        );
    }
}