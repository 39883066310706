import React, { Component } from "react";
import PropTypes from "prop-types";
import * as color from "../../constants/color";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    input: {
        display: "none",
    },
    browse: {
        color: color.mediumYellow1,
        cursor: "pointer",
    },
});

export default class FileBrowser extends Component {
    static propTypes = {
        onSelect: PropTypes.func.isRequired,        
        disableClick: PropTypes.bool,
    };

    componentDidMount = () => {}
    

    _fileSelected = e => {
        e.preventDefault();
        this.props.onSelect(e.target.files[0]);
        e.target.value = null;
    }

    _triggerFileUpload = e => {
        e.preventDefault();
        if (!this.props.disableClick) {
            this.fileInput.click();
        }
    }

    render() {
        return (
            <React.Fragment>
                <input 
                    ref={fileInput => this.fileInput = fileInput} 
                    className={css(styles.input)}
                    onChange={e => this._fileSelected(e)}
                    type="file"
                />
                <span className={css(styles.browse)} onClick={e => this._triggerFileUpload(e)}>Browse</span>
            </React.Fragment>
        );
    }
}