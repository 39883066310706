import React, { Component } from "react";
import { Container, Row, Column } from "../grid";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import * as color from "../../constants/color";
import InputGroup from "./InputGroup";
import ActionButton from "../ActionButton";
import Headline from "../text/Headline";
import TextInput from "../inputs/TextInput";
import TextArea from "../inputs/TextArea";
import Dropdown from "../inputs/Dropdown";
import DatePicker from "../inputs/DatePicker";
import DatePicker2 from "../inputs/DatePicker2";
import * as enrollmentApi from "../../lib/api/enrollment";
import * as enrollmentActions from "../../actions/enrollmentActions";

const styles = StyleSheet.create({
    buttonContainer: {
        marginTop: 40,
        marginBottom: 28,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    pageHeadline: {
        fontSize: 20,
        fontWeight: 500,
        marginLeft: 24,
        marginRight: 24,
        marginTop: 8,
        marginBottom: 12,
        paddingBottom: 0,
    },
    headline: {
        fontSize: 20,
        fontWeight: 500,
        marginBottom: 8,
        paddingBottom: 0,
    },
    cancelButton: {
        flexGrow: 1,
        marginRight: 16,
        flexBasis: 0,
    },
    continueButton: {
        flexGrow: 1,
        marginLeft: 16,
        flexBasis: 0,
    },
    table: {
        fontSize: 12,
        width: "100%",
        marginTop: 0,
        marginBottom: 60,
        borderCollapse: "collapse",
    },
    tableCell: {
        color: color.tableText,
        padding: "12px 6px",
        borderBottom: `1px solid ${color.tableBorder}`,
    },
    tableHeader: {
        color: color.mediumYellow1,
        fontWeight: 500,
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: 12,
        paddingBottom: 12,
        whiteSpace: "nowrap",
        borderBottom: `1px solid ${color.mediumGray}`,
        borderTop: `1px solid ${color.darkGray2}`,
    },
    metalCircle: {
        height: "20px",
        width: "20px",
        borderRadius: "50%",
        display: "inline-block",
    },
    greenBar: {
        borderLeft: `4px solid ${color.mediumRed}`,
        fontWeight: 500,
    },
    leftPadding: {
        paddingLeft: 26,
    },
    hidden: {
        display: "none",
    },
    leftColumn: {
        marginRight: 24,
    },
    rightColumn: {
        marginLeft: 24,
    },
});

const benefitWaitingPeriodAmountOptions = [
    { label: "0", value: 0 },
    { label: "30", value: 30 },
    { label: "60", value: 60 },
    { label: "90", value: 90 },
];

const yesNoOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
];

const enrollmentPortalInputNames = {
    medicalUrlFirstDisplayName: "medicalUrlFirstDisplayName",
    medicalUrlFirstChoice: "medicalUrlFirstChoice",
    medicalUrlSecondDisplayName: "medicalUrlSecondDisplayName",
    medicalUrlSecondChoice: "medicalUrlSecondChoice",
    dentalUrlFirstDisplayName: "dentalUrlFirstDisplayName",
    dentalUrlFirstChoice: "dentalUrlFirstChoice",
    dentalUrlSecondDisplayName: "dentalUrlSecondDisplayName",
    dentalUrlSecondChoice: "dentalUrlSecondChoice",
    visionUrlFirstDisplayName: "visionUrlFirstDisplayName",
    visionUrlFirstChoice: "visionUrlFirstChoice",
    visionUrlSecondDisplayName: "visionUrlSecondDisplayName",
    visionUrlSecondChoice: "visionUrlSecondChoice",
    nonMedicalLinesMatchMedicalEnrollmentEligibilityDetail: "nonMedicalLinesMatchMedicalEnrollmentEligibilityDetail",
};

const enrollmentInputNames = {
    newHireEnrollmentPeriodId: "newHireEnrollmentPeriodId",
    openEnrollmentEndDate: "openEnrollmentEndDate",
    openEnrollmentStartDate: "openEnrollmentStartDate",
    enrollmentNote: "enrollmentNote",
    dentalNewHireEnrollmentPeriodId: "dentalNewHireEnrollmentPeriodId",
    dentalOpenEnrollmentEndDate: "dentalOpenEnrollmentEndDate",
    dentalOpenEnrollmentStartDate: "dentalOpenEnrollmentStartDate",
    dentalEnrollmentNote: "dentalEnrollmentNote",
    visionNewHireEnrollmentPeriodId: "visionNewHireEnrollmentPeriodId",
    visionOpenEnrollmentEndDate: "visionOpenEnrollmentEndDate",
    visionOpenEnrollmentStartDate: "visionOpenEnrollmentStartDate",
    visionEnrollmentNote: "visionEnrollmentNote",
};

const eligibilityInputNames = {
    requiredScheduledHoursAmount: "requiredScheduledHoursAmount",
    requiredScheduledHoursUnitId: "requiredScheduledHoursUnitId",
    benefitWaitingPeriodAmount: "benefitWaitingPeriodAmount",
    benefitWaitingPeriodUnitId: "benefitWaitingPeriodUnitId",
    enrollmentEntryDateId: "enrollmentEntryDateId",
    offeringDomesticPartnerCoverage: "offeringDomesticPartnerCoverage",
    dentalRequiredScheduledHoursAmount: "dentalRequiredScheduledHoursAmount",
    dentalRequiredScheduledHoursUnitId: "dentalRequiredScheduledHoursUnitId",
    dentalBenefitWaitingPeriodAmount: "dentalBenefitWaitingPeriodAmount",
    dentalBenefitWaitingPeriodUnitId: "dentalBenefitWaitingPeriodUnitId",
    dentalEnrollmentEntryDateId: "dentalEnrollmentEntryDateId",
    dentalOfferingDomesticPartnerCoverage: "dentalOfferingDomesticPartnerCoverage",
    visionRequiredScheduledHoursAmount: "visionRequiredScheduledHoursAmount",
    visionRequiredScheduledHoursUnitId: "visionRequiredScheduledHoursUnitId",
    visionBenefitWaitingPeriodAmount: "visionBenefitWaitingPeriodAmount",
    visionBenefitWaitingPeriodUnitId: "visionBenefitWaitingPeriodUnitId",
    visionEnrollmentEntryDateId: "visionEnrollmentEntryDateId",
    visionOfferingDomesticPartnerCoverage: "visionOfferingDomesticPartnerCoverage",
};

const detailInputNames = {
    contractStateId: "contractStateId",
    effectiveDate: "effectiveDate",
    anniversaryDate: "anniversaryDate",
    dentalContractStateId: "dentalContractStateId",
    dentalEffectiveDate: "dentalEffectiveDate",
    dentalAnniversaryDate: "dentalAnniversaryDate",
    visionContractStateId: "visionContractStateId",
    visionEffectiveDate: "visionEffectiveDate",
    visionAnniversaryDate: "visionAnniversaryDate",
};

export class LinesOfCoverage extends Component {
    static propTypes = {
        onContinue: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        medicalPlans: PropTypes.arrayOf(PropTypes.object),
        dentalPlans: PropTypes.arrayOf(PropTypes.object),
        visionPlans: PropTypes.arrayOf(PropTypes.object),
        enrollmentPortal: PropTypes.object.isRequired,
        insuranceTypePlanEnrollments: PropTypes.arrayOf(PropTypes.object).isRequired,
        insuranceTypePlanEligibilities: PropTypes.arrayOf(PropTypes.object).isRequired,
        insuranceTypePlanDetails: PropTypes.arrayOf(PropTypes.object).isRequired,
        updateEnrollmentPortal: PropTypes.func.isRequired,
        updateInsuranceTypePlanEnrollments: PropTypes.func.isRequired,
        updateInsuranceTypePlanEligibilities: PropTypes.func.isRequired,
        updateInsuranceTypePlanDetails: PropTypes.func.isRequired,
        isEditable: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        const {
            enrollmentPortal,
            insuranceTypePlanEnrollments,
            insuranceTypePlanEligibilities,
            insuranceTypePlanDetails,
        } = this.props;

        let enrollmentM = {};
        let enrollmentD = {};
        let enrollmentV = {};
        let enrollmentL = {};
        if (insuranceTypePlanEnrollments.length > 0) {
            enrollmentM = insuranceTypePlanEnrollments[0];
            enrollmentD = insuranceTypePlanEnrollments[1];
            enrollmentV = insuranceTypePlanEnrollments[2];
            enrollmentL = insuranceTypePlanEnrollments[3];
        }

        let eligibilityM = {};
        let eligibilityD = {};
        let eligibilityV = {};
        let eligibilityL = {};
        if (insuranceTypePlanEligibilities.length > 0) {
            eligibilityM = insuranceTypePlanEligibilities[0];
            eligibilityD = insuranceTypePlanEligibilities[1];
            eligibilityV = insuranceTypePlanEligibilities[2];
            eligibilityL = insuranceTypePlanEligibilities[3];
        }

        let detailM = {};
        let detailD = {};
        let detailV = {};
        let detailL = {};
        if (insuranceTypePlanDetails.length > 0) {
            detailM = insuranceTypePlanDetails[0];
            detailD = insuranceTypePlanDetails[1];
            detailV = insuranceTypePlanDetails[2];
            detailL = insuranceTypePlanDetails[3];
        }

        this.state = {
            isSaving: false,
            errors: [],
            enrollmentPortalInputs: {
                [enrollmentPortalInputNames.medicalUrlFirstDisplayName]: enrollmentPortal.medicalUrlFirstDisplayName ? enrollmentPortal.medicalUrlFirstDisplayName : "",
                [enrollmentPortalInputNames.medicalUrlFirstChoice]: enrollmentPortal.medicalUrlFirstChoice ? enrollmentPortal.medicalUrlFirstChoice : "",
                [enrollmentPortalInputNames.medicalUrlSecondDisplayName]: enrollmentPortal.medicalUrlSecondDisplayName ? enrollmentPortal.medicalUrlSecondDisplayName : "",
                [enrollmentPortalInputNames.medicalUrlSecondChoice]: enrollmentPortal.medicalUrlSecondChoice ? enrollmentPortal.medicalUrlSecondChoice : "",
                [enrollmentPortalInputNames.dentalUrlFirstDisplayName]: enrollmentPortal.dentalUrlFirstDisplayName ? enrollmentPortal.dentalUrlFirstDisplayName : "",
                [enrollmentPortalInputNames.dentalUrlFirstChoice]: enrollmentPortal.dentalUrlFirstChoice ? enrollmentPortal.dentalUrlFirstChoice : "",
                [enrollmentPortalInputNames.dentalUrlSecondDisplayName]: enrollmentPortal.dentalUrlSecondDisplayName ? enrollmentPortal.dentalUrlSecondDisplayName : "",
                [enrollmentPortalInputNames.dentalUrlSecondChoice]: enrollmentPortal.dentalUrlSecondChoice ? enrollmentPortal.dentalUrlSecondChoice : "",
                [enrollmentPortalInputNames.visionUrlFirstDisplayName]: enrollmentPortal.visionUrlFirstDisplayName ? enrollmentPortal.visionUrlFirstDisplayName : "",
                [enrollmentPortalInputNames.visionUrlFirstChoice]: enrollmentPortal.visionUrlFirstChoice ? enrollmentPortal.visionUrlFirstChoice : "",
                [enrollmentPortalInputNames.visionUrlSecondDisplayName]: enrollmentPortal.visionUrlSecondDisplayName ? enrollmentPortal.visionUrlSecondDisplayName : "",
                [enrollmentPortalInputNames.visionUrlSecondChoice]: enrollmentPortal.visionUrlSecondChoice ? enrollmentPortal.visionUrlSecondChoice : "",
                [enrollmentPortalInputNames.nonMedicalLinesMatchMedicalEnrollmentEligibilityDetail]: enrollmentPortal.nonMedicalLinesMatchMedicalEnrollmentEligibilityDetail !== null ? enrollmentPortal.nonMedicalLinesMatchMedicalEnrollmentEligibilityDetail : true,
            },
            enrollmentInputs: {
                [enrollmentInputNames.newHireEnrollmentPeriodId]: enrollmentM.newHireEnrollmentPeriodId ? enrollmentM.newHireEnrollmentPeriodId : "",
                [enrollmentInputNames.openEnrollmentEndDate]: enrollmentM.openEnrollmentEndDate ? new Date(enrollmentM.openEnrollmentEndDate) : null,
                [enrollmentInputNames.openEnrollmentStartDate]: enrollmentM.openEnrollmentStartDate ? new Date(enrollmentM.openEnrollmentStartDate) : null,
                [enrollmentInputNames.enrollmentNote]: enrollmentM.enrollmentNote ? enrollmentM.enrollmentNote : "",
                [enrollmentInputNames.dentalNewHireEnrollmentPeriodId]: enrollmentD.newHireEnrollmentPeriodId ? enrollmentD.newHireEnrollmentPeriodId : "",
                [enrollmentInputNames.dentalOpenEnrollmentEndDate]: enrollmentD.openEnrollmentEndDate ? new Date(enrollmentD.openEnrollmentEndDate) : null,
                [enrollmentInputNames.dentalOpenEnrollmentStartDate]: enrollmentD.openEnrollmentStartDate ? new Date(enrollmentD.openEnrollmentStartDate) : null,
                [enrollmentInputNames.dentalEnrollmentNote]: enrollmentD.enrollmentNote ? enrollmentD.enrollmentNote : "",
                [enrollmentInputNames.visionNewHireEnrollmentPeriodId]: enrollmentV.newHireEnrollmentPeriodId ? enrollmentV.newHireEnrollmentPeriodId : "",
                [enrollmentInputNames.visionOpenEnrollmentEndDate]: enrollmentV.openEnrollmentEndDate ? new Date(enrollmentV.openEnrollmentEndDate) : null,
                [enrollmentInputNames.visionOpenEnrollmentStartDate]: enrollmentV.openEnrollmentStartDate ? new Date(enrollmentV.openEnrollmentStartDate) : null,
                [enrollmentInputNames.visionEnrollmentNote]: enrollmentV.enrollmentNote ? enrollmentV.enrollmentNote : "",
            },
            eligibilityInputs: {
                [eligibilityInputNames.requiredScheduledHoursAmount]: eligibilityM.requiredScheduledHoursAmount ? eligibilityM.requiredScheduledHoursAmount.toString() : "",
                [eligibilityInputNames.requiredScheduledHoursUnitId]: eligibilityM.requiredScheduledHoursUnitId ? eligibilityM.requiredScheduledHoursUnitId : "",
                [eligibilityInputNames.benefitWaitingPeriodAmount]: eligibilityM.benefitWaitingPeriodAmount ? eligibilityM.benefitWaitingPeriodAmount.toString() : "",
                [eligibilityInputNames.benefitWaitingPeriodUnitId]: eligibilityM.benefitWaitingPeriodUnitId ? eligibilityM.benefitWaitingPeriodUnitId : "",
                [eligibilityInputNames.enrollmentEntryDateId]: eligibilityM.enrollmentEntryDateId ? eligibilityM.enrollmentEntryDateId : "",
                [eligibilityInputNames.offeringDomesticPartnerCoverage]: eligibilityM.offeringDomesticPartnerCoverage !== null ? eligibilityM.offeringDomesticPartnerCoverage : true,
                [eligibilityInputNames.dentalRequiredScheduledHoursAmount]: eligibilityD.requiredScheduledHoursAmount ? eligibilityD.requiredScheduledHoursAmount.toString() : "",
                [eligibilityInputNames.dentalRequiredScheduledHoursUnitId]: eligibilityD.requiredScheduledHoursUnitId ? eligibilityD.requiredScheduledHoursUnitId : "",
                [eligibilityInputNames.dentalBenefitWaitingPeriodAmount]: eligibilityD.benefitWaitingPeriodAmount ? eligibilityD.benefitWaitingPeriodAmount.toString() : "",
                [eligibilityInputNames.dentalBenefitWaitingPeriodUnitId]: eligibilityD.benefitWaitingPeriodUnitId ? eligibilityD.benefitWaitingPeriodUnitId : "",
                [eligibilityInputNames.dentalEnrollmentEntryDateId]: eligibilityD.enrollmentEntryDateId ? eligibilityD.enrollmentEntryDateId : "",
                [eligibilityInputNames.dentalOfferingDomesticPartnerCoverage]: eligibilityD.offeringDomesticPartnerCoverage !== null ? eligibilityD.offeringDomesticPartnerCoverage : true,
                [eligibilityInputNames.visionRequiredScheduledHoursAmount]: eligibilityV.requiredScheduledHoursAmount ? eligibilityV.requiredScheduledHoursAmount.toString() : "",
                [eligibilityInputNames.visionRequiredScheduledHoursUnitId]: eligibilityV.requiredScheduledHoursUnitId ? eligibilityV.requiredScheduledHoursUnitId : "",
                [eligibilityInputNames.visionBenefitWaitingPeriodAmount]: eligibilityV.benefitWaitingPeriodAmount ? eligibilityV.benefitWaitingPeriodAmount.toString() : "",
                [eligibilityInputNames.visionBenefitWaitingPeriodUnitId]: eligibilityV.benefitWaitingPeriodUnitId ? eligibilityV.benefitWaitingPeriodUnitId : "",
                [eligibilityInputNames.visionEnrollmentEntryDateId]: eligibilityV.enrollmentEntryDateId ? eligibilityV.enrollmentEntryDateId : "",
                [eligibilityInputNames.visionOfferingDomesticPartnerCoverage]: eligibilityV.offeringDomesticPartnerCoverage !== null ? eligibilityV.offeringDomesticPartnerCoverage : true,
            },
            detailInputs: {
                [detailInputNames.contractStateId]: detailM.contractStateId ? detailM.contractStateId : "",
                [detailInputNames.effectiveDate]: detailM.effectiveDate ? new Date(detailM.effectiveDate) : null,
                [detailInputNames.anniversaryDate]: detailM.anniversaryDate ? new Date(detailM.anniversaryDate) : null,
                [detailInputNames.dentalContractStateId]: detailD.contractStateId ? detailD.contractStateId : "",
                [detailInputNames.dentalEffectiveDate]: detailD.effectiveDate ? new Date(detailD.effectiveDate) : null,
                [detailInputNames.dentalAnniversaryDate]: detailD.anniversaryDate ? new Date(detailD.anniversaryDate) : null,
                [detailInputNames.visionContractStateId]: detailV.contractStateId ? detailV.contractStateId : "",
                [detailInputNames.visionEffectiveDate]: detailV.effectiveDate ? new Date(detailV.effectiveDate) : null,
                [detailInputNames.visionAnniversaryDate]: detailV.anniversaryDate ? new Date(detailV.anniversaryDate) : null,
            },
            insuranceTypePlanOptions: {
                newHireEnrollmentPeriods: [],
                requiredScheduledHoursUnits: [],
                benefitWaitingPeriodUnits: [],
                enrollmentEntryDates: [],
                planDetailStatuses: [],
                states: []
            },
        };
    }

    componentDidMount() {
        enrollmentApi.getInsuranceTypePlanOptions()
            .then(result => {
                this.setState({
                    insuranceTypePlanOptions: result,
                });
            });
    }

    _handleEnrollmentPortalTextChanged = e => {
        const { name, value } = e.target;

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                enrollmentPortalInputs: {
                    ...prevState.enrollmentPortalInputs,
                    [name]: value,
                },
                errors,
            };
        });
    };

    _handleEnrollmentTextChanged = e => {
        const { name, value } = e.target;

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                enrollmentInputs: {
                    ...prevState.enrollmentInputs,
                    [name]: value,
                },
                errors,
            };
        });
    };

    _handleEnrollmentPortalDropdownChanged = selectedItem => {
        const { name, value } = selectedItem;
        this.setState(prevState => {
            return {
                enrollmentPortalInputs: {
                    ...prevState.enrollmentPortalInputs,
                    [name]: value,
                },
            };
        });
    };

    _handleEligiblityTextChanged = e => {
        const { name } = e.target;
        let { value } = e.target;

        value = value.replace(/[^0-9]+/g, "");
        //maxlength of 4
        if (value && value.length === 5) {
            return;
        }

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                eligibilityInputs: {
                    ...prevState.eligibilityInputs,
                    [name]: value,
                },
                errors,
            };
        });
    };

    _handleEnrollmentDropdownChanged = selectedItem => {
        const { name, value } = selectedItem;

        this.setState(prevState => {
            return {
                enrollmentInputs: {
                    ...prevState.enrollmentInputs,
                    [name]: value,
                },
            };
        });
    };

    _handleEligibilityDropdownChanged = selectedItem => {
        const { name, value } = selectedItem;

        this.setState(prevState => {
            return {
                eligibilityInputs: {
                    ...prevState.eligibilityInputs,
                    [name]: value,
                },
            };
        });
    };

    _handleEligibilityTextChanged = e => {
        const { name, value } = e.target;

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                eligibilityInputs: {
                    ...prevState.eligibilityInputs,
                    [name]: value,
                },
                errors,
            };
        });
    };

    _handleDetailDropdownChanged = selectedItem => {
        const { name, value } = selectedItem;

        this.setState(prevState => {
            return {
                detailInputs: {
                    ...prevState.detailInputs,
                    [name]: value,
                },
            };
        });
    };

    _handleDetailTextChanged = e => {
        const { name, value } = e.target;

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                detailInputs: {
                    ...prevState.detailInputs,
                    [name]: value,
                },
                errors,
            };
        });
    };

    _handleEnrollmentDateChange = (value, name) => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                enrollmentInputs: {
                    ...prevState.enrollmentInputs,
                    [name]: value,
                },
                errors,
            };
        });
    };

    _handleDetailDateChange = (value, name) => {
        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                detailInputs: {
                    ...prevState.detailInputs,
                    [name]: value,
                },
                errors,
            };
        });
    };

    _submit = e => {
        e.preventDefault();

        if (!this.props.isEditable) {
            this.props.onContinue();
            return;
        }

        let {
            enrollmentPortal,
            insuranceTypePlanEnrollments,
            insuranceTypePlanEligibilities,
            insuranceTypePlanDetails
        } = this.props;

        let enrollmentM = {};
        let enrollmentD = {};
        let enrollmentV = {};
        let enrollmentL = {};
        if (insuranceTypePlanEnrollments.length > 0) {
            enrollmentM = insuranceTypePlanEnrollments[0];
            enrollmentD = insuranceTypePlanEnrollments[1];
            enrollmentV = insuranceTypePlanEnrollments[2];
            enrollmentL = insuranceTypePlanEnrollments[3];
        }

        let eligibilityM = {};
        let eligibilityD = {};
        let eligibilityV = {};
        let eligibilityL = {};
        if (insuranceTypePlanEligibilities.length > 0) {
            eligibilityM = insuranceTypePlanEligibilities[0];
            eligibilityD = insuranceTypePlanEligibilities[1];
            eligibilityV = insuranceTypePlanEligibilities[2];
            eligibilityL = insuranceTypePlanEligibilities[3];
        }

        let detailM = {};
        let detailD = {};
        let detailV = {};
        let detailL = {};
        if (insuranceTypePlanDetails.length > 0) {
            detailM = insuranceTypePlanDetails[0];
            detailD = insuranceTypePlanDetails[1];
            detailV = insuranceTypePlanDetails[2];
            detailL = insuranceTypePlanDetails[3];
        }

        //only one
        Object.keys(enrollmentPortalInputNames).forEach(key => {
            enrollmentPortal[key] = this.state.enrollmentPortalInputs[key];
        });

        //need to capture one answer...  this will override other LOB answers when saving
        const matchMedical = enrollmentPortal[enrollmentPortalInputNames.nonMedicalLinesMatchMedicalEnrollmentEligibilityDetail];

        //4 LOBs
        Object.keys(enrollmentInputNames).forEach(key => {
            let newKey = key;
            if (key.startsWith("life")) {
                newKey = newKey.replace("life", "");
                newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);
                enrollmentL[newKey] = matchMedical ? this.state.enrollmentInputs[newKey] : this.state.enrollmentInputs[key];
            } else if (key.startsWith("vision")) {
                newKey = newKey.replace("vision", "");
                newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);
                enrollmentV[newKey] = matchMedical ? this.state.enrollmentInputs[newKey] : this.state.enrollmentInputs[key];
            } else if (key.startsWith("dental")) {
                newKey = newKey.replace("dental", "");
                newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);
                enrollmentD[newKey] = matchMedical ? this.state.enrollmentInputs[newKey] : this.state.enrollmentInputs[key];
            } else {
                enrollmentM[key] = this.state.enrollmentInputs[key];
            }
        });

        //4 LOBs
        insuranceTypePlanEnrollments = [];
        insuranceTypePlanEnrollments.push(enrollmentM);
        insuranceTypePlanEnrollments.push(enrollmentD);
        insuranceTypePlanEnrollments.push(enrollmentV);
        insuranceTypePlanEnrollments.push(enrollmentL);

        Object.keys(eligibilityInputNames).forEach(key => {
            let newKey = key;
            if (key.startsWith("life")) {
                newKey = newKey.replace("life", "");
                newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);
                eligibilityL[newKey] = matchMedical ? this.state.eligibilityInputs[newKey] : this.state.eligibilityInputs[key];
            } else if (key.startsWith("vision")) {
                newKey = newKey.replace("vision", "");
                newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);
                eligibilityV[newKey] = matchMedical ? this.state.eligibilityInputs[newKey] : this.state.eligibilityInputs[key];
            } else if (key.startsWith("dental")) {
                newKey = newKey.replace("dental", "");
                newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);
                eligibilityD[newKey] = matchMedical ? this.state.eligibilityInputs[newKey] : this.state.eligibilityInputs[key];
            } else {
                eligibilityM[key] = this.state.eligibilityInputs[key];
            }
        });

        //4 LOBs
        insuranceTypePlanEligibilities = [];
        insuranceTypePlanEligibilities.push(eligibilityM);
        insuranceTypePlanEligibilities.push(eligibilityD);
        insuranceTypePlanEligibilities.push(eligibilityV);
        insuranceTypePlanEligibilities.push(eligibilityL);

        Object.keys(detailInputNames).forEach(key => {
            let newKey = key;
            if (key.startsWith("life")) {
                newKey = newKey.replace("life", "");
                newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);
                detailL[newKey] = matchMedical ? this.state.detailInputs[newKey] : this.state.detailInputs[key];
            } else if (key.startsWith("vision")) {
                newKey = newKey.replace("vision", "");
                newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);
                detailV[newKey] = matchMedical ? this.state.detailInputs[newKey] : this.state.detailInputs[key];
            } else if (key.startsWith("dental")) {
                newKey = newKey.replace("dental", "");
                newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);
                detailD[newKey] = matchMedical ? this.state.detailInputs[newKey] : this.state.detailInputs[key];
            } else {
                detailM[key] = this.state.detailInputs[key];
            }
        });

        //4 LOBs
        insuranceTypePlanDetails = [];
        insuranceTypePlanDetails.push(detailM);
        insuranceTypePlanDetails.push(detailD);
        insuranceTypePlanDetails.push(detailV);
        insuranceTypePlanDetails.push(detailL);

        let params = {
            enrollmentPortal,
            insuranceTypePlanDetails,
            insuranceTypePlanEligibilities,
            insuranceTypePlanEnrollments,
        };

        this.setState({
            isSaving: true,
        });

        enrollmentApi.saveLinesOfCoverage(params)
            .then(response => {
                this.props.updateEnrollmentPortal(response.enrollmentPortal);
                this.props.updateInsuranceTypePlanDetails(response.insuranceTypePlanDetails);
                this.props.updateInsuranceTypePlanEligibilities(response.insuranceTypePlanEligibilities);
                this.props.updateInsuranceTypePlanEnrollments(response.insuranceTypePlanEnrollments);

                this.setState({
                    isSaving: false,
                });

                this.props.onContinue();
            });
    };

    render() {
        const { medicalPlans, dentalPlans, visionPlans, isEditable } = this.props;
        return (
            <form onSubmit={this._submit}>
                <div className={css(styles.linesOfCoverageContainer)}>
                    <div className={css(medicalPlans && medicalPlans.length > 0 ? "" : styles.hidden)}>
                        <Headline aStyles={styles.pageHeadline}>Medical Coverage</Headline>
                        <table className={css(styles.table)}>
                            <thead>
                                <tr>
                                    <td className={css(styles.tableHeader, styles.leftPadding)}>Carrier</td>
                                    <td className={css(styles.tableHeader)}>Plan</td>
                                    <td className={css(styles.tableHeader)}>Network</td>
                                    <td className={css(styles.tableHeader)}>Type</td>
                                    <td className={css(styles.tableHeader)}>Mtl</td>
                                    <td className={css(styles.tableHeader)}>Monthly Premium</td>
                                    <td className={css(styles.tableHeader)}>EE</td>
                                    <td className={css(styles.tableHeader)}>ES</td>
                                    <td className={css(styles.tableHeader)}>EC</td>
                                    <td className={css(styles.tableHeader)}>EF</td>
                                    <td className={css(styles.tableHeader)}>Ded</td>
                                    <td className={css(styles.tableHeader)}>Coln</td>
                                    <td className={css(styles.tableHeader)}>OOP</td>
                                    <td className={css(styles.tableHeader)}>Copay</td>
                                    <td className={css(styles.tableHeader)}>Rx</td>
                                </tr>
                            </thead>
                            <tbody>
                                {medicalPlans && medicalPlans.map(p => {
                                    return (
                                        <tr key={p.id}>
                                            <td className={css(styles.tableCell, styles.leftPadding, styles.greenBar)}>{p.carrierName}</td>
                                            <td className={css(styles.tableCell)}>{p.displayName}</td>
                                            <td className={css(styles.tableCell)}>{p.networkName}</td>
                                            <td className={css(styles.tableCell)}>{p.planTypeName}</td>
                                            <td className={css(styles.tableCell)}>
                                                <span className={css(styles.metalCircle)} style={{ backgroundColor: ((p.metallicLevel === "Bronze" || p.metallicLevel === "Expanded Bronze") ? "#cd7f32" : p.metallicLevel === "Silver" ? "#c0c0c0" : p.metallicLevel === "Gold" ? "#ffd700" : p.metallicLevel === "Platinum" ? "#f7f7f7" : "#ffffff") }} />
                                            </td>
                                            <td className={css(styles.tableCell)}>{p.monthlyPremium}</td>
                                            <td className={css(styles.tableCell)}>{p.ee}</td>
                                            <td className={css(styles.tableCell)}>{p.es}</td>
                                            <td className={css(styles.tableCell)}>{p.ec}</td>
                                            <td className={css(styles.tableCell)}>{p.ef}</td>
                                            <td className={css(styles.tableCell)}>{p.deductible}</td>
                                            <td className={css(styles.tableCell)}>{p.coInsurance}</td>
                                            <td className={css(styles.tableCell)}>{p.oop}</td>
                                            <td className={css(styles.tableCell)}>{p.erCopay}</td>
                                            <td className={css(styles.tableCell)}>{p.rxCard}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <Container>
                            <Row>
                                <Column large={6}>
                                    <div className={css(styles.leftColumn)}>
                                        <InputGroup>
                                            <Headline aStyles={styles.headline}>Websites for Medical Coverage</Headline>
                                            <TextInput
                                                name={enrollmentPortalInputNames.medicalUrlFirstDisplayName}
                                                onChange={this._handleEnrollmentPortalTextChanged}
                                                placeholder="Medical Website 1 Display Name"
                                                validationMessage={this.state.errors[enrollmentPortalInputNames.medicalUrlFirstDisplayName]}
                                                value={this.state.enrollmentPortalInputs[enrollmentPortalInputNames.medicalUrlFirstDisplayName]}
                                                disabled={!isEditable}
                                            />
                                            <TextInput
                                                name={enrollmentPortalInputNames.medicalUrlFirstChoice}
                                                onChange={this._handleEnrollmentPortalTextChanged}
                                                placeholder="Medical Website 1 URL"
                                                validationMessage={this.state.errors[enrollmentPortalInputNames.medicalUrlFirstChoice]}
                                                value={this.state.enrollmentPortalInputs[enrollmentPortalInputNames.medicalUrlFirstChoice]}
                                                disabled={!isEditable}
                                            />
                                            <TextInput
                                                name={enrollmentPortalInputNames.medicalUrlSecondDisplayName}
                                                onChange={this._handleEnrollmentPortalTextChanged}
                                                placeholder="Medical Website 2 Display Name"
                                                validationMessage={this.state.errors[enrollmentPortalInputNames.medicalUrlSecondDisplayName]}
                                                value={this.state.enrollmentPortalInputs[enrollmentPortalInputNames.medicalUrlSecondDisplayName]}
                                                disabled={!isEditable}
                                            />
                                            <TextInput
                                                name={enrollmentPortalInputNames.medicalUrlSecondChoice}
                                                onChange={this._handleEnrollmentPortalTextChanged}
                                                placeholder="Medical Website 2 URL"
                                                validationMessage={this.state.errors[enrollmentPortalInputNames.medicalUrlSecondChoice]}
                                                value={this.state.enrollmentPortalInputs[enrollmentPortalInputNames.medicalUrlSecondChoice]}
                                                disabled={!isEditable}
                                            />
                                        </InputGroup>
                                        <InputGroup>
                                            <Headline aStyles={styles.headline}>Medical Plan Eligibility</Headline>
                                            <TextInput
                                                name={eligibilityInputNames.requiredScheduledHoursAmount}
                                                onChange={this._handleEligiblityTextChanged}
                                                placeholder="Required Scheduled Hours"
                                                validationMessage={this.state.errors[eligibilityInputNames.requiredScheduledHoursAmount]}
                                                value={this.state.eligibilityInputs[eligibilityInputNames.requiredScheduledHoursAmount]}
                                                disabled={!isEditable}
                                                tooltip="Any employee who does not meet the Required Scheduled Hours will not see the plan as an option when going through enrollment."
                                            />
                                            <Dropdown
                                                name={eligibilityInputNames.requiredScheduledHoursUnitId}
                                                options={this.state.insuranceTypePlanOptions.requiredScheduledHoursUnits.map(item => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={this._handleEligibilityDropdownChanged}
                                                placeholder="Frequency of Required Hours"
                                                validationMessage={this.state.errors[eligibilityInputNames.requiredScheduledHoursUnitId]}
                                                value={this.state.eligibilityInputs[eligibilityInputNames.requiredScheduledHoursUnitId]}
                                                disabled={!isEditable}
                                            />
                                            <Dropdown
                                                name={eligibilityInputNames.benefitWaitingPeriodAmount}
                                                options={benefitWaitingPeriodAmountOptions}
                                                onChange={this._handleEligibilityDropdownChanged}
                                                placeholder="Benefit Waiting Period (Days)"
                                                validationMessage={this.state.errors[eligibilityInputNames.benefitWaitingPeriodAmount]}
                                                value={this.state.eligibilityInputs[eligibilityInputNames.benefitWaitingPeriodAmount]}
                                                disabled={!isEditable}
                                                tooltip="Employment Probationary Period & Benefits Waiting Period will be added together to determine full waiting period until start of coverage."
                                            />
                                            <Dropdown
                                                name={eligibilityInputNames.enrollmentEntryDateId}
                                                options={this.state.insuranceTypePlanOptions.enrollmentEntryDates.map(item => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={this._handleEligibilityDropdownChanged}
                                                placeholder="Enrollment Entry Date"
                                                validationMessage={this.state.errors[eligibilityInputNames.enrollmentEntryDateId]}
                                                value={this.state.eligibilityInputs[eligibilityInputNames.enrollmentEntryDateId]}
                                                disabled={!isEditable}
                                                tooltip="Enrollment Entry Date setting will be applied after the waiting period has been completed."
                                            />
                                            <Dropdown
                                                name={eligibilityInputNames.offeringDomesticPartnerCoverage}
                                                options={yesNoOptions}
                                                onChange={this._handleEligibilityDropdownChanged}
                                                placeholder="Offering Domestic Partner Coverage"
                                                validationMessage={this.state.errors[eligibilityInputNames.offeringDomesticPartnerCoverage]}
                                                value={this.state.eligibilityInputs[eligibilityInputNames.offeringDomesticPartnerCoverage]}
                                                disabled={!isEditable}
                                            />
                                        </InputGroup>
                                    </div>
                                </Column>
                                <Column large={6}>
                                    <div className={css(styles.rightColumn)}>
                                        <InputGroup>
                                            <Headline aStyles={styles.headline}>Medical Plan Enrollment</Headline>
                                            <DatePicker2
                                                name={enrollmentInputNames.openEnrollmentStartDate}
                                                onChange={this._handleEnrollmentDateChange}
                                                placeholder="Open Enrollment Start Date"
                                                validationMessage={this.state.errors[enrollmentInputNames.openEnrollmentStartDate]}
                                                value={this.state.enrollmentInputs[enrollmentInputNames.openEnrollmentStartDate]}
                                                disabled={!isEditable}
                                                tooltip="Fill in OE dates to designate when employees can enroll. New Hire Enrollment setting is based on Hire Date not Coverage Effective Date or OE dates."
                                            />
                                            <DatePicker2
                                                name={enrollmentInputNames.openEnrollmentEndDate}
                                                onChange={this._handleEnrollmentDateChange}
                                                placeholder="Open Enrollment End Date"
                                                validationMessage={this.state.errors[enrollmentInputNames.openEnrollmentEndDate]}
                                                value={this.state.enrollmentInputs[enrollmentInputNames.openEnrollmentEndDate]}
                                                disabled={!isEditable}
                                            />
                                            <Dropdown
                                                name={enrollmentInputNames.newHireEnrollmentPeriodId}
                                                options={this.state.insuranceTypePlanOptions.newHireEnrollmentPeriods.map(item => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={this._handleEnrollmentDropdownChanged}
                                                placeholder="New Hire Enrollment Period"
                                                validationMessage={this.state.errors[enrollmentInputNames.newHireEnrollmentPeriodId]}
                                                value={this.state.enrollmentInputs[enrollmentInputNames.newHireEnrollmentPeriodId]}
                                                disabled={!isEditable}
                                                tooltip="This period determines when the employee can enroll. It is either prior and/or following the Hire or Rehire Date."
                                            />
                                            <TextArea
                                                name={enrollmentInputNames.enrollmentNote}
                                                onChange={this._handleEnrollmentTextChanged}
                                                placeholder="Notes"
                                                validationMessage={this.state.errors[enrollmentInputNames.enrollmentNote]}
                                                value={this.state.enrollmentInputs[enrollmentInputNames.enrollmentNote]}
                                                disabled={!isEditable}
                                            />
                                        </InputGroup>
                                        <InputGroup>
                                            <Headline aStyles={styles.headline}>Medical Plan Details</Headline>
                                            <Dropdown
                                                name={detailInputNames.contractStateId}
                                                options={this.state.insuranceTypePlanOptions.states.map(item => ({
                                                    label: item.longName,
                                                    value: item.id,
                                                }))}
                                                onChange={this._handleDetailDropdownChanged}
                                                placeholder="Contract State"
                                                validationMessage={this.state.errors[detailInputNames.contractStateId]}
                                                value={this.state.detailInputs[detailInputNames.contractStateId]}
                                                disabled={!isEditable}
                                            />
                                            <DatePicker
                                                name={detailInputNames.effectiveDate}
                                                onChange={this._handleDetailDateChange}
                                                placeholder="Effective Date"
                                                validationMessage={this.state.errors[detailInputNames.effectiveDate]}
                                                value={this.state.detailInputs[detailInputNames.effectiveDate]}
                                                disabled={!isEditable}
                                            />
                                            <DatePicker
                                                name={detailInputNames.anniversaryDate}
                                                onChange={this._handleDetailDateChange}
                                                placeholder="Policy End Date"
                                                validationMessage={this.state.errors[detailInputNames.anniversaryDate]}
                                                value={this.state.detailInputs[detailInputNames.anniversaryDate]}
                                                disabled={!isEditable}
                                                isEndDate={true}
                                            />
                                        </InputGroup>
                                    </div>
                                </Column>
                            </Row>
                        </Container>
                    </div>
                    <div className={css(((medicalPlans && medicalPlans.length > 0) && ((dentalPlans && dentalPlans.length > 0) || (visionPlans && visionPlans.length > 0))) ? "" : styles.hidden)}>
                        <Headline aStyles={styles.pageHeadline}>Ancillary Lines of Business</Headline>
                        <Container>
                            <Row>
                                <Column large={6}>
                                    <div className={css(styles.leftColumn)}>
                                        <InputGroup>
                                            <Headline aStyles={styles.headline}>Plan Enrollment, Plan Eligibility, and Plan Details for non-Medical lines</Headline>
                                            <Dropdown
                                                name={enrollmentPortalInputNames.nonMedicalLinesMatchMedicalEnrollmentEligibilityDetail}
                                                options={yesNoOptions}
                                                onChange={this._handleEnrollmentPortalDropdownChanged}
                                                placeholder="Match Medical (Select Yes/No)"
                                                validationMessage={this.state.errors[enrollmentPortalInputNames.nonMedicalLinesMatchMedicalEnrollmentEligibilityDetail]}
                                                value={this.state.enrollmentPortalInputs[enrollmentPortalInputNames.nonMedicalLinesMatchMedicalEnrollmentEligibilityDetail]}
                                                disabled={!isEditable}
                                            />
                                        </InputGroup>
                                    </div>
                                </Column>
                            </Row>
                        </Container>
                    </div>
                    <div className={css(dentalPlans && dentalPlans.length > 0 ? "" : styles.hidden)}>
                        <Headline aStyles={styles.pageHeadline}>Dental Coverage</Headline>
                        <table className={css(styles.table)}>
                            <thead>
                                <tr>
                                    <td className={css(styles.tableHeader, styles.leftPadding)}>Carrier</td>
                                    <td className={css(styles.tableHeader)}>Plan</td>
                                    <td className={css(styles.tableHeader)}>Network</td>
                                    <td className={css(styles.tableHeader)}>Type</td>
                                    <td className={css(styles.tableHeader)}>Monthly Premium</td>
                                    <td className={css(styles.tableHeader)}>EE</td>
                                    <td className={css(styles.tableHeader)}>ES</td>
                                    <td className={css(styles.tableHeader)}>EC</td>
                                    <td className={css(styles.tableHeader)}>EF</td>
                                    <td className={css(styles.tableHeader)}>Ded</td>
                                    <td className={css(styles.tableHeader)}>Coln</td>
                                    <td className={css(styles.tableHeader)}>Max</td>
                                    <td className={css(styles.tableHeader)}>Funding</td>
                                    <td className={css(styles.tableHeader)}>Per/End</td>
                                </tr>
                            </thead>
                            <tbody>
                                {dentalPlans && dentalPlans.map(p => {
                                    return (
                                        <tr key={p.id}>
                                            <td className={css(styles.tableCell, styles.leftPadding, styles.greenBar)}>{p.carrierName}</td>
                                            <td className={css(styles.tableCell)}>{p.displayName}</td>
                                            <td className={css(styles.tableCell)}>{p.networkName}</td>
                                            <td className={css(styles.tableCell)}>{p.planTypeName}</td>
                                            <td className={css(styles.tableCell)}>{p.monthlyPremium}</td>
                                            <td className={css(styles.tableCell)}>{p.ee}</td>
                                            <td className={css(styles.tableCell)}>{p.es}</td>
                                            <td className={css(styles.tableCell)}>{p.ec}</td>
                                            <td className={css(styles.tableCell)}>{p.ef}</td>
                                            <td className={css(styles.tableCell)}>{p.deductible}</td>
                                            <td className={css(styles.tableCell)}>{p.coInsurance}</td>
                                            <td className={css(styles.tableCell)}>{p.annualMaximum}</td>
                                            <td className={css(styles.tableCell)}>{p.fundingType}</td>
                                            <td className={css(styles.tableCell)}>{p.perioEndo}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <Container>
                            <Row>
                                <Column large={6}>
                                    <div className={css(styles.leftColumn)}>
                                        <InputGroup>
                                            <Headline aStyles={styles.headline}>Websites for Dental Coverage</Headline>
                                            <TextInput
                                                name={enrollmentPortalInputNames.dentalUrlFirstDisplayName}
                                                onChange={this._handleEnrollmentPortalTextChanged}
                                                placeholder="Dental Website 1 Display Name"
                                                validationMessage={this.state.errors[enrollmentPortalInputNames.dentalUrlFirstDisplayName]}
                                                value={this.state.enrollmentPortalInputs[enrollmentPortalInputNames.dentalUrlFirstDisplayName]}
                                                disabled={!isEditable}
                                            />
                                            <TextInput
                                                name={enrollmentPortalInputNames.dentalUrlFirstChoice}
                                                onChange={this._handleEnrollmentPortalTextChanged}
                                                placeholder="Dental Website 1 URL"
                                                validationMessage={this.state.errors[enrollmentPortalInputNames.dentalUrlFirstChoice]}
                                                value={this.state.enrollmentPortalInputs[enrollmentPortalInputNames.dentalUrlFirstChoice]}
                                                disabled={!isEditable}
                                            />
                                            <TextInput
                                                name={enrollmentPortalInputNames.dentalUrlSecondDisplayName}
                                                onChange={this._handleEnrollmentPortalTextChanged}
                                                placeholder="Dental Website 2 Display Name"
                                                validationMessage={this.state.errors[enrollmentPortalInputNames.dentalUrlSecondDisplayName]}
                                                value={this.state.enrollmentPortalInputs[enrollmentPortalInputNames.dentalUrlSecondDisplayName]}
                                                disabled={!isEditable}
                                            />
                                            <TextInput
                                                name={enrollmentPortalInputNames.dentalUrlSecondChoice}
                                                onChange={this._handleEnrollmentPortalTextChanged}
                                                placeholder="Dental Website 2 URL"
                                                validationMessage={this.state.errors[enrollmentPortalInputNames.dentalUrlSecondChoice]}
                                                value={this.state.enrollmentPortalInputs[enrollmentPortalInputNames.dentalUrlSecondChoice]}
                                                disabled={!isEditable}
                                            />
                                        </InputGroup>
                                        <InputGroup>
                                            <Headline aStyles={styles.headline}>Dental Plan Eligibility</Headline>
                                            <TextInput
                                                name={eligibilityInputNames.dentalRequiredScheduledHoursAmount}
                                                onChange={this._handleEligiblityTextChanged}
                                                placeholder="Required Scheduled Hours"
                                                validationMessage={this.state.errors[eligibilityInputNames.dentalRequiredScheduledHoursAmount]}
                                                value={this.state.eligibilityInputs[eligibilityInputNames.dentalRequiredScheduledHoursAmount]}
                                                disabled={!isEditable}
                                                tooltip="Any employee who does not meet the Required Scheduled Hours will not see the plan as an option when going through enrollment."
                                            />
                                            <Dropdown
                                                name={eligibilityInputNames.dentalRequiredScheduledHoursUnitId}
                                                options={this.state.insuranceTypePlanOptions.requiredScheduledHoursUnits.map(item => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={this._handleEligibilityDropdownChanged}
                                                placeholder="Frequency of Required Hours"
                                                validationMessage={this.state.errors[eligibilityInputNames.dentalRequiredScheduledHoursUnitId]}
                                                value={this.state.eligibilityInputs[eligibilityInputNames.dentalRequiredScheduledHoursUnitId]}
                                                disabled={!isEditable}
                                            />
                                            <Dropdown
                                                name={eligibilityInputNames.dentalBenefitWaitingPeriodAmount}
                                                options={benefitWaitingPeriodAmountOptions}
                                                onChange={this._handleEligibilityDropdownChanged}
                                                placeholder="Benefit Waiting Period (Days)"
                                                validationMessage={this.state.errors[eligibilityInputNames.dentalBenefitWaitingPeriodAmount]}
                                                value={this.state.eligibilityInputs[eligibilityInputNames.dentalBenefitWaitingPeriodAmount]}
                                                disabled={!isEditable}
                                                tooltip="Employment Probationary Period & Benefits Waiting Period will be added together to determine full waiting period until start of coverage."
                                            />
                                            <Dropdown
                                                name={eligibilityInputNames.dentalEnrollmentEntryDateId}
                                                options={this.state.insuranceTypePlanOptions.enrollmentEntryDates.map(item => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={this._handleEligibilityDropdownChanged}
                                                placeholder="Enrollment Entry Date"
                                                validationMessage={this.state.errors[eligibilityInputNames.dentalEnrollmentEntryDateId]}
                                                value={this.state.eligibilityInputs[eligibilityInputNames.dentalEnrollmentEntryDateId]}
                                                disabled={!isEditable}
                                                tooltip="Enrollment Entry Date setting will be applied after the waiting period has been completed."
                                            />
                                            <Dropdown
                                                name={eligibilityInputNames.dentalOfferingDomesticPartnerCoverage}
                                                options={yesNoOptions}
                                                onChange={this._handleEligibilityDropdownChanged}
                                                placeholder="Offering Domestic Partner Coverage"
                                                validationMessage={this.state.errors[eligibilityInputNames.dentalOfferingDomesticPartnerCoverage]}
                                                value={this.state.eligibilityInputs[eligibilityInputNames.dentalOfferingDomesticPartnerCoverage]}
                                                disabled={!isEditable}
                                            />
                                        </InputGroup>
                                    </div>
                                </Column>
                                <Column large={6}>
                                    <div className={css(styles.rightColumn)}>
                                        <InputGroup>
                                            <Headline aStyles={styles.headline}>Dental Plan Enrollment</Headline>
                                            <DatePicker2
                                                name={enrollmentInputNames.dentalOpenEnrollmentStartDate}
                                                onChange={this._handleEnrollmentDateChange}
                                                placeholder="Open Enrollment Start Date"
                                                validationMessage={this.state.errors[enrollmentInputNames.dentalOpenEnrollmentStartDate]}
                                                value={this.state.enrollmentInputs[enrollmentInputNames.dentalOpenEnrollmentStartDate]}
                                                disabled={!isEditable}
                                                tooltip="Fill in OE dates to designate when employees can enroll. New Hire Enrollment setting is based on Hire Date not Coverage Effective Date or OE dates."
                                            />
                                            <DatePicker2
                                                name={enrollmentInputNames.dentalOpenEnrollmentEndDate}
                                                onChange={this._handleEnrollmentDateChange}
                                                placeholder="Open Enrollment End Date"
                                                validationMessage={this.state.errors[enrollmentInputNames.dentalOpenEnrollmentEndDate]}
                                                value={this.state.enrollmentInputs[enrollmentInputNames.dentalOpenEnrollmentEndDate]}
                                                disabled={!isEditable}
                                            />
                                            <Dropdown
                                                name={enrollmentInputNames.dentalNewHireEnrollmentPeriodId}
                                                options={this.state.insuranceTypePlanOptions.newHireEnrollmentPeriods.map(item => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={this._handleEnrollmentDropdownChanged}
                                                placeholder="New Hire Enrollment Period"
                                                validationMessage={this.state.errors[enrollmentInputNames.dentalNewHireEnrollmentPeriodId]}
                                                value={this.state.enrollmentInputs[enrollmentInputNames.dentalNewHireEnrollmentPeriodId]}
                                                disabled={!isEditable}
                                                tooltip="This period determines when the employee can enroll. It is either prior and/or following the Hire or Rehire Date."
                                            />
                                            <TextArea
                                                name={enrollmentInputNames.dentalEnrollmentNote}
                                                onChange={this._handleEnrollmentTextChanged}
                                                placeholder="Notes"
                                                validationMessage={this.state.errors[enrollmentInputNames.dentalEnrollmentNote]}
                                                value={this.state.enrollmentInputs[enrollmentInputNames.dentalEnrollmentNote]}
                                                disabled={!isEditable}
                                            />
                                        </InputGroup>
                                        <InputGroup>
                                            <Headline aStyles={styles.headline}>Dental Plan Details</Headline>
                                            <Dropdown
                                                name={detailInputNames.dentalContractStateId}
                                                options={this.state.insuranceTypePlanOptions.states.map(item => ({
                                                    label: item.longName,
                                                    value: item.id,
                                                }))}
                                                onChange={this._handleDetailDropdownChanged}
                                                placeholder="Contract State"
                                                validationMessage={this.state.errors[detailInputNames.dentalContractStateId]}
                                                value={this.state.detailInputs[detailInputNames.dentalContractStateId]}
                                                disabled={!isEditable}
                                            />
                                            <DatePicker
                                                name={detailInputNames.dentalEffectiveDate}
                                                onChange={this._handleDetailDateChange}
                                                placeholder="Effective Date"
                                                validationMessage={this.state.errors[detailInputNames.dentalEffectiveDate]}
                                                value={this.state.detailInputs[detailInputNames.dentalEffectiveDate]}
                                                disabled={!isEditable}
                                            />
                                            <DatePicker
                                                name={detailInputNames.dentalAnniversaryDate}
                                                onChange={this._handleDetailDateChange}
                                                placeholder="Policy End Date"
                                                validationMessage={this.state.errors[detailInputNames.dentalAnniversaryDate]}
                                                value={this.state.detailInputs[detailInputNames.dentalAnniversaryDate]}
                                                disabled={!isEditable}
                                                isEndDate={true}
                                            />
                                        </InputGroup>
                                    </div>
                                </Column>
                            </Row>
                        </Container>
                    </div>
                    <div className={css(visionPlans && visionPlans.length > 0 ? "" : styles.hidden)}>
                        <Headline aStyles={styles.pageHeadline}>Vision Coverage</Headline>
                        <table className={css(styles.table)}>
                            <thead>
                                <tr>
                                    <td className={css(styles.tableHeader, styles.leftPadding)}>Carrier</td>
                                    <td className={css(styles.tableHeader)}>Plan</td>
                                    <td className={css(styles.tableHeader)}>Monthly Premium</td>
                                    <td className={css(styles.tableHeader)}>EE</td>
                                    <td className={css(styles.tableHeader)}>ES</td>
                                    <td className={css(styles.tableHeader)}>EC</td>
                                    <td className={css(styles.tableHeader)}>EF</td>
                                    <td className={css(styles.tableHeader)}>Exam</td>
                                    <td className={css(styles.tableHeader)}>Materials</td>
                                    <td className={css(styles.tableHeader)}>Frames</td>
                                    <td className={css(styles.tableHeader)}>Contacts</td>
                                    <td className={css(styles.tableHeader)}>Funding</td>
                                    <td className={css(styles.tableHeader)}>Frequency</td>
                                </tr>
                            </thead>
                            <tbody>
                                {visionPlans && visionPlans.map(p => {
                                    return (
                                        <tr key={p.id}>
                                            <td className={css(styles.tableCell, styles.leftPadding, styles.greenBar)}>{p.carrierName}</td>
                                            <td className={css(styles.tableCell)}>{p.displayName}</td>
                                            <td className={css(styles.tableCell)}>{p.monthlyPremium}</td>
                                            <td className={css(styles.tableCell)}>{p.ee}</td>
                                            <td className={css(styles.tableCell)}>{p.es}</td>
                                            <td className={css(styles.tableCell)}>{p.ec}</td>
                                            <td className={css(styles.tableCell)}>{p.ef}</td>
                                            <td className={css(styles.tableCell)}>{p.examCopay}</td>
                                            <td className={css(styles.tableCell)}>{p.materialsCopay}</td>
                                            <td className={css(styles.tableCell)}>{p.framesAllowance}</td>
                                            <td className={css(styles.tableCell)}>{p.contactLensAllowance}</td>
                                            <td className={css(styles.tableCell)}>{p.fundingType}</td>
                                            <td className={css(styles.tableCell)}>{p.frequency}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <Container>
                            <Row>
                                <Column large={6}>
                                    <div className={css(styles.leftColumn)}>
                                        <InputGroup>
                                            <Headline aStyles={styles.headline}>Websites for Vision Coverage</Headline>
                                            <TextInput
                                                name={enrollmentPortalInputNames.visionUrlFirstDisplayName}
                                                onChange={this._handleEnrollmentPortalTextChanged}
                                                placeholder="Vision Website 1 Display Name"
                                                validationMessage={this.state.errors[enrollmentPortalInputNames.visionUrlFirstDisplayName]}
                                                value={this.state.enrollmentPortalInputs[enrollmentPortalInputNames.visionUrlFirstDisplayName]}
                                                disabled={!isEditable}
                                            />
                                            <TextInput
                                                name={enrollmentPortalInputNames.visionUrlFirstChoice}
                                                onChange={this._handleEnrollmentPortalTextChanged}
                                                placeholder="Vision Website 1 URL"
                                                validationMessage={this.state.errors[enrollmentPortalInputNames.visionUrlFirstChoice]}
                                                value={this.state.enrollmentPortalInputs[enrollmentPortalInputNames.visionUrlFirstChoice]}
                                                disabled={!isEditable}
                                            />
                                            <TextInput
                                                name={enrollmentPortalInputNames.visionUrlSecondDisplayName}
                                                onChange={this._handleEnrollmentPortalTextChanged}
                                                placeholder="Vision Website 2 Display Name"
                                                validationMessage={this.state.errors[enrollmentPortalInputNames.visionUrlSecondDisplayName]}
                                                value={this.state.enrollmentPortalInputs[enrollmentPortalInputNames.visionUrlSecondDisplayName]}
                                                disabled={!isEditable}
                                            />
                                            <TextInput
                                                name={enrollmentPortalInputNames.visionUrlSecondChoice}
                                                onChange={this._handleEnrollmentPortalTextChanged}
                                                placeholder="Vision Website 2 URL"
                                                validationMessage={this.state.errors[enrollmentPortalInputNames.visionUrlSecondChoice]}
                                                value={this.state.enrollmentPortalInputs[enrollmentPortalInputNames.visionUrlSecondChoice]}
                                                disabled={!isEditable}
                                            />
                                        </InputGroup>
                                        <InputGroup>
                                            <Headline aStyles={styles.headline}>Vision Plan Eligibility</Headline>
                                            <TextInput
                                                name={eligibilityInputNames.visionRequiredScheduledHoursAmount}
                                                onChange={this._handleEligiblityTextChanged}
                                                placeholder="Required Scheduled Hours"
                                                validationMessage={this.state.errors[eligibilityInputNames.visionRequiredScheduledHoursAmount]}
                                                value={this.state.eligibilityInputs[eligibilityInputNames.visionRequiredScheduledHoursAmount]}
                                                disabled={!isEditable}
                                                tooltip="Any employee who does not meet the Required Scheduled Hours will not see the plan as an option when going through enrollment."
                                            />
                                            <Dropdown
                                                name={eligibilityInputNames.visionRequiredScheduledHoursUnitId}
                                                options={this.state.insuranceTypePlanOptions.requiredScheduledHoursUnits.map(item => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={this._handleEligibilityDropdownChanged}
                                                placeholder="Frequency of Required Hours"
                                                validationMessage={this.state.errors[eligibilityInputNames.visionRequiredScheduledHoursUnitId]}
                                                value={this.state.eligibilityInputs[eligibilityInputNames.visionRequiredScheduledHoursUnitId]}
                                                disabled={!isEditable}
                                            />
                                            <Dropdown
                                                name={eligibilityInputNames.visionBenefitWaitingPeriodAmount}
                                                options={benefitWaitingPeriodAmountOptions}
                                                onChange={this._handleEligibilityDropdownChanged}
                                                placeholder="Benefit Waiting Period (Days)"
                                                validationMessage={this.state.errors[eligibilityInputNames.visionBenefitWaitingPeriodAmount]}
                                                value={this.state.eligibilityInputs[eligibilityInputNames.visionBenefitWaitingPeriodAmount]}
                                                disabled={!isEditable}
                                                tooltip="Employment Probationary Period & Benefits Waiting Period will be added together to determine full waiting period until start of coverage."
                                            />
                                            <Dropdown
                                                name={eligibilityInputNames.visionEnrollmentEntryDateId}
                                                options={this.state.insuranceTypePlanOptions.enrollmentEntryDates.map(item => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={this._handleEligibilityDropdownChanged}
                                                placeholder="Enrollment Entry Date"
                                                validationMessage={this.state.errors[eligibilityInputNames.visionEnrollmentEntryDateId]}
                                                value={this.state.eligibilityInputs[eligibilityInputNames.visionEnrollmentEntryDateId]}
                                                disabled={!isEditable}
                                                tooltip="Enrollment Entry Date setting will be applied after the waiting period has been completed."
                                            />
                                            <Dropdown
                                                name={eligibilityInputNames.visionOfferingDomesticPartnerCoverage}
                                                options={yesNoOptions}
                                                onChange={this._handleEligibilityDropdownChanged}
                                                placeholder="Offering Domestic Partner Coverage"
                                                validationMessage={this.state.errors[eligibilityInputNames.visionOfferingDomesticPartnerCoverage]}
                                                value={this.state.eligibilityInputs[eligibilityInputNames.visionOfferingDomesticPartnerCoverage]}
                                                disabled={!isEditable}
                                            />
                                        </InputGroup>
                                    </div>
                                </Column>
                                <Column large={6}>
                                    <div className={css(styles.rightColumn)}>
                                        <InputGroup>
                                            <Headline aStyles={styles.headline}>Vision Plan Enrollment</Headline>
                                            <DatePicker2
                                                name={enrollmentInputNames.visionOpenEnrollmentStartDate}
                                                onChange={this._handleEnrollmentDateChange}
                                                placeholder="Open Enrollment Start Date"
                                                validationMessage={this.state.errors[enrollmentInputNames.visionOpenEnrollmentStartDate]}
                                                value={this.state.enrollmentInputs[enrollmentInputNames.visionOpenEnrollmentStartDate]}
                                                disabled={!isEditable}
                                                tooltip="Fill in OE dates to designate when employees can enroll. New Hire Enrollment setting is based on Hire Date not Coverage Effective Date or OE dates."
                                            />
                                            <DatePicker2
                                                name={enrollmentInputNames.visionOpenEnrollmentEndDate}
                                                onChange={this._handleEnrollmentDateChange}
                                                placeholder="Open Enrollment End Date"
                                                validationMessage={this.state.errors[enrollmentInputNames.visionOpenEnrollmentEndDate]}
                                                value={this.state.enrollmentInputs[enrollmentInputNames.visionOpenEnrollmentEndDate]}
                                                disabled={!isEditable}
                                            />
                                            <Dropdown
                                                name={enrollmentInputNames.visionNewHireEnrollmentPeriodId}
                                                options={this.state.insuranceTypePlanOptions.newHireEnrollmentPeriods.map(item => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={this._handleEnrollmentDropdownChanged}
                                                placeholder="New Hire Enrollment Period"
                                                validationMessage={this.state.errors[enrollmentInputNames.visionNewHireEnrollmentPeriodId]}
                                                value={this.state.enrollmentInputs[enrollmentInputNames.visionNewHireEnrollmentPeriodId]}
                                                disabled={!isEditable}
                                                tooltip="This period determines when the employee can enroll. It is either prior and/or following the Hire or Rehire Date."
                                            />
                                            <TextArea
                                                name={enrollmentInputNames.visionEnrollmentNote}
                                                onChange={this._handleEnrollmentTextChanged}
                                                placeholder="Notes"
                                                validationMessage={this.state.errors[enrollmentInputNames.visionEnrollmentNote]}
                                                value={this.state.enrollmentInputs[enrollmentInputNames.visionEnrollmentNote]}
                                                disabled={!isEditable}
                                            />
                                        </InputGroup>
                                        <InputGroup>
                                            <Headline aStyles={styles.headline}>Vision Plan Details</Headline>
                                            <Dropdown
                                                name={detailInputNames.visionContractStateId}
                                                options={this.state.insuranceTypePlanOptions.states.map(item => ({
                                                    label: item.longName,
                                                    value: item.id,
                                                }))}
                                                onChange={this._handleDetailDropdownChanged}
                                                placeholder="Contract State"
                                                validationMessage={this.state.errors[detailInputNames.visionContractStateId]}
                                                value={this.state.detailInputs[detailInputNames.visionContractStateId]}
                                                disabled={!isEditable}
                                            />
                                            <DatePicker
                                                name={detailInputNames.visionEffectiveDate}
                                                onChange={this._handleDetailDateChange}
                                                placeholder="Effective Date"
                                                validationMessage={this.state.errors[detailInputNames.visionEffectiveDate]}
                                                value={this.state.detailInputs[detailInputNames.visionEffectiveDate]}
                                                disabled={!isEditable}
                                            />
                                            <DatePicker
                                                name={detailInputNames.visionAnniversaryDate}
                                                onChange={this._handleDetailDateChange}
                                                placeholder="Policy End Date"
                                                validationMessage={this.state.errors[detailInputNames.visionAnniversaryDate]}
                                                value={this.state.detailInputs[detailInputNames.visionAnniversaryDate]}
                                                disabled={!isEditable}
                                                isEndDate={true}
                                            />
                                        </InputGroup>
                                    </div>
                                </Column>
                            </Row>
                        </Container>
                    </div>
                    <InputGroup>
                        <div className={css(styles.buttonContainer)}>
                            <ActionButton
                                aStyles={styles.cancelButton}
                                label="Back"
                                invertColor={true}
                                type="button"
                                onClick={this.props.onCancel}
                            />
                            <ActionButton
                                aStyles={styles.continueButton}
                                label="Continue"
                                type="submit"
                                isLoading={this.state.isSaving}
                                onClick={this._continueClicked}
                            />
                        </div>
                    </InputGroup>
                </div>
            </form>
        );
    }
}

const mapStateToProps = state => ({
    medicalPlans: state.enrollment.medicalPlans,
    dentalPlans: state.enrollment.dentalPlans,
    visionPlans: state.enrollment.visionPlans,
    enrollmentPortal: state.enrollment.enrollmentPortal,
    insuranceTypePlanEnrollments: state.enrollment.insuranceTypePlanEnrollments,
    insuranceTypePlanEligibilities: state.enrollment.insuranceTypePlanEligibilities,
    insuranceTypePlanDetails: state.enrollment.insuranceTypePlanDetails,
    isEditable: state.enrollment.isEditable,
});

export default connect(
    mapStateToProps, {
    updateEnrollmentPortal: enrollmentActions.updateEnrollmentPortal,
    updateInsuranceTypePlanEnrollments: enrollmentActions.updateInsuranceTypePlanEnrollments,
    updateInsuranceTypePlanEligibilities: enrollmentActions.updateInsuranceTypePlanEligibilities,
    updateInsuranceTypePlanDetails: enrollmentActions.updateInsuranceTypePlanDetails,
}
)(LinesOfCoverage);
