import * as account from "../../lib/api/account";
import * as breakpoints from "../../constants/breakpoints";
import * as routeHelpers from "../../lib/routeHelpers";
import ActionButton from "../ActionButton";
import BodyCopyHighlight from "../text/BodyCopyHighlight";
import describeNetworkError from "../../lib/describeNetworkError";
import logger from "../../lib/logger";
import React, { Component } from "react";
import TextInput from "../inputs/TextInput";
import { StyleSheet, css } from "aphrodite";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = StyleSheet.create({
    loginForm: {
        maxWidth: "500px",
        marginBottom: "28px",
    },
    title: {
        textTransform: "uppercase",
        textDecoration: "none",
        color: "#660000",
        fontSize: 24,
        fontWeight: "bold",
        [breakpoints.tabletAndDown]: {
            textAlign: "center",
            marginTop: 14,
            marginBottom: 0,
        },
        [breakpoints.desktopOnly]: {
            marginTop: 28,
            marginBottom: 0,
        },
    },
    buttonContainer: {
        marginRight: '100px',
        display: 'flex',
        [breakpoints.desktopOnly]: {
            textAlign: "right",
        },
        width: "30%",
    },
    actionButton: {
        marginTop: 16,

        [breakpoints.tabletAndDown]: {
            width: "100%",
        },
        [breakpoints.desktopOnly]: {
            width: "100%",
        },
    },
    cancelButton: {
        width: "100%",
        marginTop: 10,
    },
    returnButton: {
        marginTop: 32,
        width: "100%",
    },
    emailAddress: {
        fontWeight: 600,
        marginTop: 4,
    }
});

const strings = {
    pageTitle: "Forgot Password",
    submittedPageTitle: "Password reset email sent!",
    instructions: "Enter the email address associated with your account below.",
    emailPlaceholder: "Email Address",
    submitButton: "Continue",
    returnButton: "Return to Sign In",
    cancelButton: "Cancel",
    emailNotFound: "Email address not found",
};

const inputNames = {
    email: "email",
};

export default class ForgotPasswordForm extends Component {
    state = {
        errors: {},
        loading: false,
        submitted: false,
        inputs: {
            [inputNames.email]: "",
        },
    };

    componentDidMount() {
        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    _handleTextChange = e => {
        const { name, value } = e.target;
        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
            };
        });
    };

    _gotoLogin = () => {
        window.location.href = "/account/login";
    };

    _submit = e => {
        e.preventDefault();
        if (this.state.loading) {
            return;
        }

        this.setState({
            loading: true,
            errors: {},
        });

        const email = this.state.inputs[inputNames.email];

        account
            .forgotPassword(email)
            .then(() => {
                this.setState({ loading: false, submitted: true });
            })
            .catch(err => {
                logger.warn(err);
                if (!this._mounted) {
                    return;
                }

                let errors = {};
                if (err && err.response) {
                    const { response } = err;
                    if (
                        response &&
                        response.data &&
                        response.data.errors &&
                        Object.keys(response.data.errors).length > 0
                    ) {
                        // Server provided validation messages
                        errors = response.data.errors;
                    }
                    else if (response && response.status === 400) {
                        errors[inputNames.email] = [strings.emailNotFound];
                    }
                    else {
                        // 500 or something else unexpected
                        const description = describeNetworkError(err);
                        toast.error(description.join("\n"), { toastId: 1 });
                    }
                }

                this.setState({
                    loading: false,
                    errors: errors,
                });
            });
    };

    render() {
        return (
            !this.state.submitted ? (
                <form onSubmit={this._submit} className={css(styles.loginForm)}>
                    <ToastContainer position="top-center" autoClose={2500} />
                    <div className={css(styles.title)}>{strings.pageTitle}</div>
                    <BodyCopyHighlight>{strings.instructions}</BodyCopyHighlight>
                    <TextInput
                        name={inputNames.email}
                        onChange={this._handleTextChange}
                        placeholder={strings.emailPlaceholder}
                        autoComplete="email"
                        required={true}
                        type="email"
                        validationMessage={this.state.errors[inputNames.email]}
                        value={this.state.inputs[inputNames.email]}
                    />

                    <div className={css(styles.buttonContainer)}>
                        <button
                            aStyles={styles.actionButton}
                            className="h-login-submit theme-btn theme-btn--rouge"
                            disabled={this.state.loading}
                            label={this.state.loading ? strings.loading : strings.submitButton}
                            isLoading={this.state.loading}
                            type="submit"
                        >
                            Continue
                        </button>
                        <button
                            aStyles={styles.actionButton}
                            className="h-login-submit theme-btn"
                            disabled={this.state.loading}
                            label={this.state.loading ? strings.loading : strings.cancelButton}
                            isLoading={this.state.loading}
                            type="button"
                            onClick={this._gotoLogin}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            ) : (
                <div className={css(styles.loginForm)}>
                    <div className={css(styles.title)}>{strings.submittedPageTitle}</div>
                    <div>We sent instructions for you to reset your password to:</div>
                    <div className={css(styles.emailAddress)}>{this.state.inputs[inputNames.email]}</div>
                    <button
                        aStyles={styles.actionButton}
                        className="h-login-submit theme-btn theme-btn--rouge"
                        disabled={this.state.loading}
                        label={this.state.loading ? strings.loading : strings.returnButton}
                        isLoading={this.state.loading}
                        type="button"
                        onClick={this._gotoLogin}
                    >
                        Return to Sign in
                    </button>
                </div>
            )
        );
    }
}
