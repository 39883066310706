import * as color from "../constants/color";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import React, { Component } from 'react';

const styles = StyleSheet.create({    
    tabListItem: {
        fontSize: 14,
        fontWeight: 500,
        display: "inline-block",
        whiteSpace: "nowrap",
        listStyle: "none",   
        padding: "10px 24px",            
        minWidth: 130,
        textAlign: "center",
        backgroundColor: color.white,
        color: color.darkRed,        
        borderBottom: `4px solid ${color.darkGray2}`,
        margin: "0px 10px",
        boxShadow: `0 0 3px ${color.blackAlpha20}`,
        ":first-child": {
            marginLeft: 20,
        },
        ":hover": {
            cursor: "pointer",
        }
    },
    tabListActive: {        
        boxShadow: "none",
        backgroundColor: color.mediumRed,
        color: color.white,
        borderBottom: `4px solid ${color.darkRed}`,                
    },
    disabled: {
        color: color.mediumGray,
    },
});

export default class Tab extends Component {
    static propTypes = {
        activeTab: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        count: PropTypes.number,
    };

    _onClick = () => {
        const { label, onClick } = this.props;
        if (!this.props.disabled) {
            onClick(label);
        }
    }

    render() {        
        const { 
            props: {
                activeTab,
                label,
                disabled,
                count,
            },
        } = this;

        let count_string = count && count > 0 ? "(" + count + ")" : null;

        return (
            <li 
                className={css(
                    styles.tabListItem,
                    activeTab === label ? styles.tabListActive : null,
                    disabled ? styles.disabled : null,
                )}
                onClick={this._onClick}>
                {label} {count_string}
            </li>
        );
    }
}