import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container, Row, Column } from "../grid";
import { StyleSheet, css } from "aphrodite";
import * as color from "../../constants/color";
import * as texchangePropTypes from "../../texchangePropTypes";
import * as util from "../../lib/util";
import FileBrowser from "../inputs/FileBrowser";
import TextInput from "../inputs/TextInput";
import SmallDropdown from "../inputs/SmallDropdown";
import ActionButton from "../ActionButton";
import { ReactComponent as DownloadIcon } from "../../images/SVGs/Download.svg";
import { ReactComponent as UploadIcon } from "../../images/SVGs/upload.svg";
import { ReactComponent as InputTypeIcon } from "../../images/SVGs/input-type.svg";
import { ReactComponent as CancelIcon } from "../../images/SVGs/close.svg";
import Dropzone from 'react-dropzone'
import format from "date-fns/format";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "../Spinner";
import Modal from "../Modal";
import * as dataApi from "../../lib/api/data";

const styles = StyleSheet.create({
    censusContainer: {

    },
    censusHeader: {
        backgroundColor: color.lightGray,
        height: 72,
    },
    censusHeaderU: {
        backgroundColor: color.lightGray3,
        height: 72,
    },
    confirmCensusHeader: {
        height: 72,
    },
    censusHeaderText: {
        fontSize: 16,
        color: color.darkestGray,
        lineHeight: "72px",
        display: "inline-block",
        marginLeft: 24,
    },
    confirmCensusHeaderText: {
        fontSize: 24,
        color: color.darkestGray,
        lineHeight: "72px",
        marginLeft: 24,
    },
    confirmCensusButtonHolder: {
        float: "right",
        marginTop: 12,
        marginRight: 24,
    },
    useExistingContainer: {
        float: "right",
        marginTop: 12,
        marginRight: 24,
    },
    censusOptionsText: {
        color: color.darkestGray,
        fontWeight: 300,
        marginTop: 32,
        fontSize: 20,
        marginLeft: 24,
    },
    censusOptionItem: {
        width: 320,
        marginLeft: "auto",
        marginRight: "auto",
    },
    censusOptionsContainer: {
        width: 780,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 64,
        paddingBottom: 24,
    },
    censusOptionSeparatorTextContainer: {
        textAlign: "center",
        position: "relative",
        top: 100,
    },
    censusOptionItemHeader: {
        fontSize: 20,
    },
    downloadIcon: {
        float: "right",
        marginLeft: 8,
    },
    downloadTemplateIcon: {
        fontSize: 13,
        float: "right",
        lineHeight: "24px",
        marginTop: 2,
        cursor: "pointer",
        textDecoration: "none",
        color: color.darkestGray,
    },
    censusOptionItemHeaderText: {
        float: "left",
        lineHeight: "24px",
        marginBottom: 20,
    },
    censusButton: {
        clear: "both",
        borderRadius: 8,
    },
    loadCensusButton: {
        border: `1px dashed ${color.mediumYellow1}`,
        padding: "39px 24px",
        color: color.darkGray,
    },
    censusLoading: {
        marginTop: 50,
    },
    censusUploadErrors: {
        padding: 20,
        margin: 24,
        border: `1px solid ${color.mediumGray}`,
    },
    censusUploadHeader: {
        fontSize: 20,
        marginBottom: 24,
        borderBottom: `1px solid ${color.mediumRed}`,
    },
    censusSelectedButtonText: {
        color: color.darkRed,
        marginTop: 4,
        fontSize: 16,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        display: "inline-block",
        maxWidth: 180,
    },
    censusFileSelected: {
        border: `1px dashed ${color.mediumRed}`,
    },
    createCensusButton: {
        backgroundColor: color.mediumYellow1,
        color: color.white,
        padding: "52px 24px",
        cursor: "pointer",
    },
    censusIcon: {
        float: "left",
        marginRight: 16,
    },
    uploadIcon: {
        fill: color.mediumYellow1,
    },
    censusUploadedIcon: {
        fill: color.darkRed,
    },
    inputTypeIcon: {
        position: "relative",
        top: -8,
        width: 40,
        height: 40,
    },
    censusButtonText: {
        fontSize: 20,
    },
    censusButtonContainer: {
        clear: "both",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 86,
        marginBottom: 30,
        textAlign: "center",
    },
    cancelButton: {
        width: 200,
    },
    submitCensusButton: {
        width: 200,
        marginLeft: 18,
    },
    clearCensusButton: {
        display: "inline-block",
        float: "right",
        cursor: "pointer",
    },
    manualCensusContainer: {
        marginLeft: 2,
        paddingBottom: 24,
    },
    censusTable: {
        width: "100%",
        marginTop: 10,
        marginBottom: 20,
        borderCollapse: "collapse",
    },
    censusTableHeader: {
        fontSize: 13,
        color: color.mediumYellow1,
        padding: 10,
        fontWeight: 500,
        textAlign: "left",
        whiteSpace: "nowrap",
        borderBottom: `1px solid ${color.mediumGray}`,
    },
    censusTableCell: {
        fontSize: 14,
        color: color.tableText,
        padding: "3px 2px",
        borderBottom: `1px solid ${color.tableBorder}`,
        lineHeight: "16px",
        ":nth-child(1),:nth-child(2)": {
            fontWeight: 500,
        },
    },
    censusTableCellLink: {
        fontSize: 16,
        color: color.mediumYellow1,
        textDecoration: "underline",
        cursor: "pointer",
    },
    censusTableCellCenter: {
        textAlign: "center",
    },
    censusTableCellRight: {
        textAlign: "right",
    },
    greenBar: {
        borderLeft: `4px solid ${color.mediumRed}`,
    },
    blueBar: {
        borderLeft: `4px solid ${color.borderBackgroundGray}`,
    },
    redBar: {
        borderLeft: `4px solid ${color.red}`,
    },
    modalContent: {
        width: 400,
    },
    modalInstructions: {
        color: color.darkestGray,
        fontSize: 14,
        marginBottom: 8,
        textAlign: "left",
        lineHeight: "18px",
    },
    modalButtonContainer: {
        marginTop: 24,
        display: "flex",
        justifyContent: "space-around",
    },
    modalButton: {
        width: 150,
    },
});

const strings = {
    useExistingCensusButton: "Use This Census",
    censusOptionsLabel: "Or do one of the following options:",
    addCensusManually: "Add Census Manually",
    loadCensus: "Load Census",
    createCensus: "Create Census",
    censusDetails: "Census Details",
    downloadTemplate: "Download Template",
    confirmCensusButton: "Confirm Census",
    censusUploadErrors: "Please fix these errors",
    cancelButton: "Cancel",
    backButton: "Back",
    submitButton: "Submit",
};

const inputNames = {
    relationship: "relationship",
    firstName: "firstName",
    lastName: "lastName",
    dob: "dob",
    gender: "gender",
    medicalCoverage: "medicalCoverage",
    dentalCoverage: "dentalCoverage",
    visionCoverage: "visionCoverage",
    lifeCoverage: "lifeCoverage",
    homePostalCode: "homePostalCode",
    workPostalCode: "workPostalCode",
    occupation: "occupation",
    salary: "salary",
    isTobacco: "isTobacco",
    onCobra: "onCobra",
};

export default class QuoteCensus extends Component {
    static propTypes = {
        quote: texchangePropTypes.quote,
        uploadCensus: PropTypes.func.isRequired,
        copyCensus: PropTypes.func.isRequired,
        copyCensusU: PropTypes.func.isRequired,
        isSaving: PropTypes.bool.isRequired,
    };

    state = {
        censusUpload: null,
        isSubmittingCensus: false,
        isManualCensus: false,
        inputs: {
            [inputNames.relationship]: "",
            [inputNames.firstName]: "",
            [inputNames.lastName]: "",
            [inputNames.dob]: "",
            [inputNames.gender]: "",
            [inputNames.medicalCoverage]: "",
            [inputNames.dentalCoverage]: "",
            [inputNames.visionCoverage]: "",
            [inputNames.lifeCoverage]: "",
            [inputNames.homePostalCode]: "",
            [inputNames.workPostalCode]: "",
            [inputNames.occupation]: "",
            [inputNames.salary]: "",
            [inputNames.isTobacco]: "",
            [inputNames.onCobra]: "",
        },
        relationship: [
            { name: "SP" },
            { name: "C" },
        ],
        gender: [
            { id: "M", name: "M" },
            { id: "F", name: "F" },
        ],
        coverage: [
            { id: "1", name: "EE" },
            { id: "2", name: "ES" },
            { id: "3", name: "EC" },
            { id: "4", name: "EF" },
            { id: "5", name: "W" },
        ],
        coverage2: [
            { id: "1", name: "EE" },
            { id: "5", name: "W" },
        ],
        yesNo: [
            { id: "1", name: "Yes" },
            { id: "0", name: "No" },
        ],
        errors: [],
        census: [],
        needsISLAndPEPM: false,
        stopLossAmounts: [],
        stopLossAmount: 0,
        defaultPEPM: 0,
        showModal: false,
        isCopy: false,
        isCopyU: false,
        currentCensus: 1,
    };

    componentDidMount() {
        const { quote } = this.props;
        dataApi.checkISLAndPEPM(quote.id)
            .then(response => {
                if (response && response.needsISLAndPEPM) {
                    this.setState({
                        needsISLAndPEPM: true,
                        stopLossAmounts: response.stopLossAmounts,
                        stopLossAmount: response.stopLossAmounts && response.stopLossAmounts.length > 0 ? response.stopLossAmounts[0] : 0,
                        defaultPEPM: response.defaultPEPM,
                    });
                }
            });
    }

    componentDidUpdate(nextProps, nextState) {
        if (nextProps.quote.census !== nextState.census && nextProps.quote.census.length > 0) {
            this.setState({
                census: nextProps.quote.census,
                currentCensus: nextProps.quote.census.length + 1,
                isManualCensus: true,
                isSubmittingCensus: false,
                loading: false,
            }, () => {
                nextProps.quote.census = []
            });
        } else if (nextProps.quote.census !== nextState.census && nextProps.quote.census.length === 0 && nextProps.quote.censusUploadErrors && nextState.isSubmittingCensus === true) {
            //full reset of the upload file...  invalid file...
            this.setState({
                isSubmittingCensus: false,
                loading: false,
            });
        }
    }

    _censusFileSelected = file => {
        //check for xls
        const nm = file.name;
        const tp = file.type;
        if (nm.endsWith(".xls") || tp === "application/vnd.ms-excel") {
            toast.error("Please use a valid Excel (.xlsx not .xls) template", { toastId: 1 });
            return;
        }

        this.setState({ censusUpload: file });
    };

    _cancelClicked = () => {
        const { quote } = this.props;
        quote.census = [];
        quote.censusUploadErrors = null;
        this.setState({
            census: [],
            currentCensus: 1,
            censusUpload: null,
            isSubmittingCensus: false,
            isManualCensus: false,
        });
    };

    _submitCensus = () => {
        const { needsISLAndPEPM, censusUpload } = this.state;
        //check for xls
        const nm = censusUpload.name;
        const tp = censusUpload.type;
        if (nm.endsWith(".xls") || tp === "application/vnd.ms-excel") {
            toast.error("Please use a valid Excel (.xlsx not .xls) template", { toastId: 1 });
            return;
        }
        //continue
        if (needsISLAndPEPM) {
            this.setState({
                showModal: true,
                isCopy: false,
                isCopyU: false,
            });
        }
        else {
            this.setState({ isSubmittingCensus: true });
            this.props.uploadCensus(censusUpload, null);
        }
    };

    _copyClicked = () => {
        const { needsISLAndPEPM } = this.state;
        if (needsISLAndPEPM) {
            this.setState({
                showModal: true,
                isCopy: true,
                isCopyU: false,
            });
        }
        else {
            this.setState({ loading: true });
            this.props.copyCensus();
        }
    };

    _copyClickedU = () => {
        const { needsISLAndPEPM } = this.state;
        if (needsISLAndPEPM) {
            this.setState({
                showModal: true,
                isCopy: false,
                isCopyU: true,
            });
        }
        else {
            this.setState({ loading: true });
            this.props.copyCensusU();
        }
    };

    _continueSubmit = () => {
        const { quote } = this.props;
        const { needsISLAndPEPM, stopLossAmount, defaultPEPM, isCopy, isCopyU, isManualCensus } = this.state;

        this.setState({
            showModal: false,
            isCopy: false,
            isCopyU: false,
        }, () => {
            if (needsISLAndPEPM && (stopLossAmount < 0 || !defaultPEPM)) {
                toast.error("Please enter values for ISL / PEPM", { toastId: 1 });
                return;
            }
            else {
                //save the ISL and PEPM values...  then continue with census
                dataApi.updateISLAndPEPM(quote.id, stopLossAmount, defaultPEPM)
                    .then(() => {
                        this.setState({
                            needsISLAndPEPM: false,
                        }, () => {
                            if (isCopy) {
                                this._copyClicked();
                            }
                            else if (isCopyU) {
                                this._copyClickedU();
                            }
                            else if (isManualCensus) {
                                //do nothing... already on that form
                            }
                            else {
                                this._submitCensus();
                            }
                        });
                    });
            }
        });
    }

    _confirmClicked = () => {
        //validate that the census is filled in...
        const { census } = this.state;
        if (census && census.length > 0) {
            let cnt = 1;
            let err = false;
            census.forEach(cen => {
                //have values for each field...  cen.homePostalCode === null || 
                if (cen.sequenceNumber === null || cen.firstName === null || cen.lastName === null ||
                    cen.firstName.length === 0 || cen.lastName.length === 0 || cen.birthDate === null ||
                    cen.gender === null || cen.medicalCoverageId === null || cen.dentalCoverageId === null ||
                    cen.visionCoverageId === null || cen.lifeCoverageId === null || cen.homePostalCode === null ||
                    cen.homePostalCode.length === 0 || cen.isTobacco === null || cen.onCobra === null) {
                    err = true;
                    toast.error("No. " + cen.sequenceNumber + ": Cannot submit an incomplete employee row.", { toastId: cnt * 1 });
                }
                //check specific values for accuracy
                //DOB
                let d = new Date(cen.birthDate);
                if (cen.birthDate.length < 10 || !(d instanceof Date) || isNaN(d.valueOf())) {
                    err = true;
                    toast.error("No. " + cen.sequenceNumber + ": Cannot submit an invalid DOB.", { toastId: cnt * 10 });
                }
                //H Zip
                if (!/^\d{5}$/.test(cen.homePostalCode)) {
                    err = true;
                    toast.error("No. " + cen.sequenceNumber + ": Cannot submit an invalid H Zip.", { toastId: cnt * 20 });
                }
                //W Zip
                if (cen.workPostalCode !== null && cen.workPostalCode.length > 0 && !/^\d{5}$/.test(cen.workPostalCode)) {
                    err = true;
                    toast.error("No. " + cen.sequenceNumber + ": Cannot submit an invalid W Zip.", { toastId: cnt * 30 });
                }
                //dependent rows
                if (cen.dependents && cen.dependents.length > 0) {
                    cen.dependents.forEach(dep => {
                        //have values for each field...
                        if (dep.sequenceNumber === null || dep.firstName === null || dep.lastName === null ||
                            dep.firstName.length === 0 || dep.lastName.length === 0 || dep.birthDate === null ||
                            dep.gender === null || dep.homePostalCode === null || dep.homePostalCode.length === 0 ||
                            dep.isTobacco === null || dep.onCobra === null) {
                            err = true;
                            toast.error("No. " + dep.sequenceNumber + ": Cannot submit an incomplete dependent row.", { toastId: cnt * 40 });
                        }
                        //check specific values for accuracy
                        //DOB
                        let d = new Date(dep.birthDate);
                        if (dep.birthDate.length < 5 || !(d instanceof Date) || isNaN(d.valueOf())) {
                            err = true;
                            toast.error("No. " + dep.sequenceNumber + ": Cannot submit an invalid DOB.", { toastId: cnt * 50 });
                        }
                        //H Zip
                        if (!/^\d{5}$/.test(dep.homePostalCode)) {
                            err = true;
                            toast.error("No. " + dep.sequenceNumber + ": Cannot submit an invalid H Zip.", { toastId: cnt * 60 });
                        }
                    });
                }
                //increment
                cnt++;
            });

            //passed?
            if (!err) {
                this.setState({ isSubmittingCensus: true });
                if (this.props.quote.census && this.props.quote.census.length > 0) {
                    this.props.uploadCensus(null, null); //this will upload the quote.census, which we are setting manually through props...
                }
                else {
                    this.props.uploadCensus(null, census); //this will upload the manual (from scratch) census.
                }
            }
        } else {
            toast.error("Cannot submit an incomplete census.", { toastId: 1 });
        }
    };

    _addCensusManually = () => {
        const { needsISLAndPEPM } = this.state;
        //need to gather?
        if (needsISLAndPEPM) {
            this.setState({
                showModal: true,
                isCopy: false,
                isCopyU: false,
            });
        }
        //move to manual census
        this.setState({ isManualCensus: true });
    };

    _addCensusMember = () => {
        const { quote } = this.props;
        const { currentCensus } = this.state;
        const newCensus = {
            "quoteId": quote.id,
            "employerId": quote.employerId,
            "sequenceNumber": currentCensus.toString(),
            "relationship": "EE",
            "firstName": "",
            "lastName": "",
            "birthDate": "",
            "gender": "M",
            "medicalCoverageId": 1,
            "dentalCoverageId": 1,
            "visionCoverageId": 1,
            "lifeCoverageId": 1,
            "homePostalCode": "",
            "workPostalCode": "",
            "occupation": "",
            "salary": "",
            "isTobacco": false,
            "onCobra": false,
            "dependents": [],
        };
        const joined = this.state.census.concat(newCensus);
        this.setState({
            census: joined,
            currentCensus: currentCensus + 1,
        });
    };

    _removeCensusMember = (c) => {
        if (!this.state.census) {
            this.setState({ census: [], currentCensus: 1 });
        } else {
            const matchC = this.state.census.find(cen => cen.sequenceNumber === c.sequenceNumber);
            if (matchC) {
                const spliced = [...this.state.census];
                const index = spliced.indexOf(matchC);
                if (index > -1) {
                    spliced.splice(index, 1);
                    this.setState({
                        census: spliced,
                    }, () => {
                        this._reNumberCensus();
                    });
                }
            }
        }
    };

    _addDependent = (c) => {
        const lngth = (c.dependents.length + 1).toString();
        const newDep = {
            "sequenceNumber": c.sequenceNumber + "." + lngth,
            "relationship": c.medicalCoverageId === "3" ? "C" : lngth === "1" ? "SP" : "C",
            "dependentType": c.medicalCoverageId === "3" ? "Child" : lngth === "1" ? "Spouse" : "Child",
            "firstName": "",
            "lastName": "",
            "birthDate": "",
            "gender": "M",
            "homePostalCode": "",
            "isTobacco": false,
        };
        const joined = c.dependents.concat(newDep);

        this.setState({
            census: this.state.census.map(
                person => person.sequenceNumber === c.sequenceNumber
                    ? { ...person, dependents: joined }
                    : person,
            )
        });
    };

    _removeDependent = (c, d) => {
        const matchC = this.state.census.find(cen => cen.sequenceNumber === c.sequenceNumber);
        if (matchC && matchC.dependents.length > 0) {
            const matchD = matchC.dependents.find(dep => dep.sequenceNumber === d.sequenceNumber);
            if (matchD) {
                const spliced = [...matchC.dependents];
                const index = matchC.dependents.indexOf(matchD);
                if (index > -1) {
                    spliced.splice(index, 1);
                    this.setState({
                        census: this.state.census.map(
                            person => person.sequenceNumber === c.sequenceNumber
                                ? { ...person, dependents: spliced }
                                : person,
                        )
                    }, () => {
                        this._reNumberCensus();
                    });
                }
            }
        }
    };

    _reNumberCensus = () => {
        const { census } = this.state;
        if (census && census.length > 1) {
            //make a copy
            let updated = [...census];
            for (let i = 0; i < updated.length; i++) {
                updated[i].sequenceNumber = (i + 1).toString();
                if (updated[i].dependents && updated[i].dependents.length > 0) {
                    for (let j = 0; j < updated[i].dependents.length; j++) {
                        updated[i].dependents[j].sequenceNumber = (i + 1).toString() + "." + (j + 1).toString();
                    }
                }
            }
            this.setState({
                census: updated,
                currentCensus: updated.length + 1,
            });
        }
    };

    _handleTextChanged = e => {
        const { name } = e.target;
        let { value } = e.target;

        if (e.target.getAttribute("data-type") === "date") {
            value = "";
        }

        this.setState(prevState => {
            const errors = { ...prevState.errors };
            delete errors[name];

            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
                errors,
            };
        });
    };

    _handleDropdownChanged = selectedItem => {
        const { name, value } = selectedItem;

        this.setState(prevState => {
            return {
                inputs: {
                    ...prevState.inputs,
                    [name]: value,
                },
            };
        });
    };

    _setRelationship = (e, c, d) => {
        const val = e.value;
        const val2 = val === "SP" ? "Spouse" : "Child";
        const { census } = this.props.quote;
        const matchC = census.find(cen => cen.sequenceNumber === c.sequenceNumber);
        //census or dependent?
        if (d === null) {
            if (matchC) {
                matchC.relationship = val;
            }

            this.setState({
                census: this.state.census.map(
                    person => person.sequenceNumber === c.sequenceNumber
                        ? { ...person, relationship: val, }
                        : person,
                )
            });
        } else {
            if (matchC) {
                const matchD = matchC.dependents.find(dep => dep.sequenceNumber === d.sequenceNumber);
                if (matchD) {
                    matchD.relationship = val;
                    matchD.dependentType = val2;
                }
            }

            this.setState(prevState => ({
                census: prevState.census.map((cen) => {
                    if (cen.dependents.some(dep => dep.sequenceNumber === d.sequenceNumber)) {
                        return {
                            ...cen,
                            dependents: cen.dependents.map(
                                person => person.sequenceNumber === d.sequenceNumber
                                    ? { ...person, relationship: val, dependentType: val2, }
                                    : person,
                            )
                        }
                    }
                    return cen
                })
            }));
        }
    };

    _setFirstName = (e, c, d) => {
        const val = e.target.value;
        const { census } = this.props.quote;
        const matchC = census.find(cen => cen.sequenceNumber === c.sequenceNumber);
        //census or dependent?
        if (d === null) {
            if (matchC) {
                matchC.firstName = val;
            }

            this.setState({
                census: this.state.census.map(
                    person => person.sequenceNumber === c.sequenceNumber
                        ? { ...person, firstName: val }
                        : person,
                )
            });
        } else {
            if (matchC) {
                const matchD = matchC.dependents.find(dep => dep.sequenceNumber === d.sequenceNumber);
                if (matchD) {
                    matchD.firstName = val;
                }
            }

            this.setState(prevState => ({
                census: prevState.census.map((cen) => {
                    if (cen.dependents.some(dep => dep.sequenceNumber === d.sequenceNumber)) {
                        return {
                            ...cen,
                            dependents: cen.dependents.map(
                                person => person.sequenceNumber === d.sequenceNumber
                                    ? { ...person, firstName: val, }
                                    : person,
                            )
                        }
                    }
                    return cen
                })
            }));
        }
    };

    _setLastName = (e, c, d) => {
        const val = e.target.value;
        const { census } = this.props.quote;
        const matchC = census.find(cen => cen.sequenceNumber === c.sequenceNumber);
        //census or dependent?
        if (d === null) {
            if (matchC) {
                matchC.lastName = val;
            }

            this.setState({
                census: this.state.census.map(
                    person => person.sequenceNumber === c.sequenceNumber
                        ? { ...person, lastName: val }
                        : person,
                )
            });
        } else {
            if (matchC) {
                const matchD = matchC.dependents.find(dep => dep.sequenceNumber === d.sequenceNumber);
                if (matchD) {
                    matchD.lastName = val;
                }
            }

            this.setState(prevState => ({
                census: prevState.census.map((cen) => {
                    if (cen.dependents.some(dep => dep.sequenceNumber === d.sequenceNumber)) {
                        return {
                            ...cen,
                            dependents: cen.dependents.map(
                                person => person.sequenceNumber === d.sequenceNumber
                                    ? { ...person, lastName: val, }
                                    : person,
                            )
                        }
                    }
                    return cen
                })
            }));
        }
    };

    _setDOB = (e, c, d) => {
        //valid date
        const val = util.maskDate(e.target.value);
        const { census } = this.props.quote;
        const matchC = census.find(cen => cen.sequenceNumber === c.sequenceNumber);
        //census or dependent?
        if (d === null) {
            if (matchC) {
                matchC.birthDate = val;
                matchC.birthDateString = val;
            }

            this.setState({
                census: this.state.census.map(
                    person => person.sequenceNumber === c.sequenceNumber
                        ? { ...person, birthDate: val, birthDateString: val, }
                        : person,
                )
            });
        } else {
            if (matchC) {
                const matchD = matchC.dependents.find(dep => dep.sequenceNumber === d.sequenceNumber);
                if (matchD) {
                    matchD.birthDate = val;
                    matchD.birthDateString = val;
                }
            }

            this.setState(prevState => ({
                census: prevState.census.map((cen) => {
                    if (cen.dependents.some(dep => dep.sequenceNumber === d.sequenceNumber)) {
                        return {
                            ...cen,
                            dependents: cen.dependents.map(
                                person => person.sequenceNumber === d.sequenceNumber
                                    ? { ...person, birthDate: val, birthDateString: val, }
                                    : person,
                            )
                        }
                    }
                    return cen
                })
            }));
        }
    };

    _setDOH = (e, c) => {
        //valid date
        const val = util.maskDate(e.target.value);
        const { census } = this.props.quote;
        const matchC = census.find(cen => cen.sequenceNumber === c.sequenceNumber);
        //census
        if (matchC) {
            matchC.hireDate = val;
            matchC.hireDateString = val;
        }

        this.setState({
            census: this.state.census.map(
                person => person.sequenceNumber === c.sequenceNumber
                    ? { ...person, hireDate: val, hireDateString: val, }
                    : person,
            )
        });
    };

    _setGender = (e, c, d) => {
        const val = e.value;
        const { census } = this.props.quote;
        const matchC = census.find(cen => cen.sequenceNumber === c.sequenceNumber);
        //census or dependent?
        if (d === null) {
            if (matchC) {
                matchC.gender = val;
            }

            this.setState({
                census: this.state.census.map(
                    person => person.sequenceNumber === c.sequenceNumber
                        ? { ...person, gender: val, }
                        : person,
                )
            });
        } else {
            if (matchC) {
                const matchD = matchC.dependents.find(dep => dep.sequenceNumber === d.sequenceNumber);
                if (matchD) {
                    matchD.gender = val;
                }
            }

            this.setState(prevState => ({
                census: prevState.census.map((cen) => {
                    if (cen.dependents.some(dep => dep.sequenceNumber === d.sequenceNumber)) {
                        return {
                            ...cen,
                            dependents: cen.dependents.map(
                                person => person.sequenceNumber === d.sequenceNumber
                                    ? { ...person, gender: val, }
                                    : person,
                            )
                        }
                    }
                    return cen
                })
            }));
        }
    };

    _setMedicalCoverage = (e, c, d) => {
        const val = e.value;
        const { census } = this.props.quote;
        const matchC = census.find(cen => cen.sequenceNumber === c.sequenceNumber);
        //census or dependent?
        if (d === null) {
            if (matchC) {
                matchC.medicalCoverageId = val;
            }

            this.setState({
                census: this.state.census.map(
                    person => person.sequenceNumber === c.sequenceNumber
                        ? { ...person, medicalCoverageId: val, }
                        : person,
                )
            });
        } else {
            if (matchC) {
                const matchD = matchC.dependents.find(dep => dep.sequenceNumber === d.sequenceNumber);
                if (matchD) {
                    matchD.medicalCoverageId = val;
                }
            }

            this.setState(prevState => ({
                census: prevState.census.map((cen) => {
                    if (cen.dependents.some(dep => dep.sequenceNumber === d.sequenceNumber)) {
                        return {
                            ...cen,
                            dependents: cen.dependents.map(
                                person => person.sequenceNumber === d.sequenceNumber
                                    ? { ...person, medicalCoverageId: val, }
                                    : person,
                            )
                        }
                    }
                    return cen
                })
            }));
        }
    };

    _setDentalCoverage = (e, c, d) => {
        const val = e.value;
        const { census } = this.props.quote;
        const matchC = census.find(cen => cen.sequenceNumber === c.sequenceNumber);
        //census or dependent?
        if (d === null) {
            if (matchC) {
                matchC.dentalCoverageId = val;
            }

            this.setState({
                census: this.state.census.map(
                    person => person.sequenceNumber === c.sequenceNumber
                        ? { ...person, dentalCoverageId: val, }
                        : person,
                )
            });
        } else {
            if (matchC) {
                const matchD = matchC.dependents.find(dep => dep.sequenceNumber === d.sequenceNumber);
                if (matchD) {
                    matchD.dentalCoverageId = val;
                }
            }

            this.setState(prevState => ({
                census: prevState.census.map((cen) => {
                    if (cen.dependents.some(dep => dep.sequenceNumber === d.sequenceNumber)) {
                        return {
                            ...cen,
                            dependents: cen.dependents.map(
                                person => person.sequenceNumber === d.sequenceNumber
                                    ? { ...person, dentalCoverageId: val, }
                                    : person,
                            )
                        }
                    }
                    return cen
                })
            }));
        }
    };

    _setVisionCoverage = (e, c, d) => {
        const val = e.value;
        const { census } = this.props.quote;
        const matchC = census.find(cen => cen.sequenceNumber === c.sequenceNumber);
        //census or dependent?
        if (d === null) {
            if (matchC) {
                matchC.visionCoverageId = val;
            }

            this.setState({
                census: this.state.census.map(
                    person => person.sequenceNumber === c.sequenceNumber
                        ? { ...person, visionCoverageId: val, }
                        : person,
                )
            });
        } else {
            if (matchC) {
                const matchD = matchC.dependents.find(dep => dep.sequenceNumber === d.sequenceNumber);
                if (matchD) {
                    matchD.visionCoverageId = val;
                }
            }

            this.setState(prevState => ({
                census: prevState.census.map((cen) => {
                    if (cen.dependents.some(dep => dep.sequenceNumber === d.sequenceNumber)) {
                        return {
                            ...cen,
                            dependents: cen.dependents.map(
                                person => person.sequenceNumber === d.sequenceNumber
                                    ? { ...person, visionCoverageId: val, }
                                    : person,
                            )
                        }
                    }
                    return cen
                })
            }));
        }
    };

    _setLifeCoverage = (e, c, d) => {
        const val = e.value;
        const { census } = this.props.quote;
        const matchC = census.find(cen => cen.sequenceNumber === c.sequenceNumber);
        //census or dependent?
        if (d === null) {
            if (matchC) {
                matchC.lifeCoverageId = val;
            }

            this.setState({
                census: this.state.census.map(
                    person => person.sequenceNumber === c.sequenceNumber
                        ? { ...person, lifeCoverageId: val, }
                        : person,
                )
            });
        } else {
            if (matchC) {
                const matchD = matchC.dependents.find(dep => dep.sequenceNumber === d.sequenceNumber);
                if (matchD) {
                    matchD.lifeCoverageId = val;
                }
            }

            this.setState(prevState => ({
                census: prevState.census.map((cen) => {
                    if (cen.dependents.some(dep => dep.sequenceNumber === d.sequenceNumber)) {
                        return {
                            ...cen,
                            dependents: cen.dependents.map(
                                person => person.sequenceNumber === d.sequenceNumber
                                    ? { ...person, lifeCoverageId: val, }
                                    : person,
                            )
                        }
                    }
                    return cen
                })
            }));
        }
    };

    _setHZip = (e, c, d) => {
        //valid postal code
        let val = e.target.value;
        val = val.replace(/[^0-9]+/g, "");
        if (val && val.length === 6) {
            return;
        }
        const { census } = this.props.quote;
        const matchC = census.find(cen => cen.sequenceNumber === c.sequenceNumber);
        //census or dependent?
        if (d === null) {
            if (matchC) {
                matchC.homePostalCode = val;
            }

            this.setState({
                census: this.state.census.map(
                    person => person.sequenceNumber === c.sequenceNumber
                        ? { ...person, homePostalCode: val, }
                        : person,
                )
            });
        } else {
            if (matchC) {
                const matchD = matchC.dependents.find(dep => dep.sequenceNumber === d.sequenceNumber);
                if (matchD) {
                    matchD.homePostalCode = val;
                }
            }

            this.setState(prevState => ({
                census: prevState.census.map((cen) => {
                    if (cen.dependents.some(dep => dep.sequenceNumber === d.sequenceNumber)) {
                        return {
                            ...cen,
                            dependents: cen.dependents.map(
                                person => person.sequenceNumber === d.sequenceNumber
                                    ? { ...person, homePostalCode: val, }
                                    : person,
                            )
                        }
                    }
                    return cen
                })
            }));
        }
    };

    _setWZip = (e, c) => {
        //valid postal code
        let val = e.target.value;
        val = val.replace(/[^0-9]+/g, "");
        if (val && val.length === 6) {
            return;
        }
        const { census } = this.props.quote;
        const matchC = census.find(cen => cen.sequenceNumber === c.sequenceNumber);
        if (matchC) {
            matchC.workPostalCode = val;
        }

        this.setState({
            census: this.state.census.map(
                person => person.sequenceNumber === c.sequenceNumber
                    ? { ...person, workPostalCode: val, }
                    : person,
            )
        });
    };

    _setOccupation = (e, c) => {
        const val = e.target.value;
        const { census } = this.props.quote;
        const matchC = census.find(cen => cen.sequenceNumber === c.sequenceNumber);
        if (matchC) {
            matchC.occupation = val;
        }

        this.setState({
            census: this.state.census.map(
                person => person.sequenceNumber === c.sequenceNumber
                    ? { ...person, occupation: val, }
                    : person,
            )
        });
    };

    _setEmail = (e, c) => {
        const val = e.target.value;
        const { census } = this.props.quote;
        const matchC = census.find(cen => cen.sequenceNumber === c.sequenceNumber);
        if (matchC) {
            matchC.email = val;
        }

        this.setState({
            census: this.state.census.map(
                person => person.sequenceNumber === c.sequenceNumber
                    ? { ...person, email: val, }
                    : person,
            )
        });
    };

    _setSalary = (e, c) => {
        //valid number
        let val = e.target.value;
        val = val.replace(/[^0-9]+/g, "");
        if (val && val.length === 7) {
            return;
        }
        const { census } = this.props.quote;
        const matchC = census.find(cen => cen.sequenceNumber === c.sequenceNumber);
        if (matchC) {
            matchC.salary = val;
        }

        this.setState({
            census: this.state.census.map(
                person => person.sequenceNumber === c.sequenceNumber
                    ? { ...person, salary: val, }
                    : person,
            )
        });
    };

    _setTobacco = (e, c, d) => {
        const val = e.value === "1" ? true : false;
        const { census } = this.props.quote;
        const matchC = census.find(cen => cen.sequenceNumber === c.sequenceNumber);
        //census or dependent?
        if (d === null) {
            if (matchC) {
                matchC.isTobacco = val;
            }

            this.setState({
                census: this.state.census.map(
                    person => person.sequenceNumber === c.sequenceNumber
                        ? { ...person, isTobacco: val, }
                        : person,
                )
            });
        } else {
            if (matchC) {
                const matchD = matchC.dependents.find(dep => dep.sequenceNumber === d.sequenceNumber);
                if (matchD) {
                    matchD.isTobacco = val;
                }
            }

            this.setState(prevState => ({
                census: prevState.census.map((cen) => {
                    if (cen.dependents.some(dep => dep.sequenceNumber === d.sequenceNumber)) {
                        return {
                            ...cen,
                            dependents: cen.dependents.map(
                                person => person.sequenceNumber === d.sequenceNumber
                                    ? { ...person, isTobacco: val, }
                                    : person,
                            )
                        }
                    }
                    return cen
                })
            }));
        }
    };

    _setCOB = (e, c) => {
        const val = e.value === "1" ? true : false;
        const { census } = this.props.quote;
        const matchC = census.find(cen => cen.sequenceNumber === c.sequenceNumber);
        if (matchC) {
            matchC.onCobra = val;
        }

        this.setState({
            census: this.state.census.map(
                person => person.sequenceNumber === c.sequenceNumber
                    ? { ...person, onCobra: val, }
                    : person,
            )
        });
    };

    _handleISLChanged = selectedItem => {
        this.setState({
            stopLossAmount: selectedItem.value,
        });
    };

    _fileDropped = droppedFiles => {
        //check for xls
        const nm = droppedFiles[0].name;
        const tp = droppedFiles[0].type;
        if (nm.endsWith(".xls") || tp === "application/vnd.ms-excel") {
            toast.error("Please use a valid Excel (.xlsx not .xls) template", { toastId: 1 });
            return;
        }
        //continue
        this.setState({ censusUpload: droppedFiles[0] });
    };

    _handlePEPMChanged = e => {
        let val = e.target.value;
        val = val.replace(/[^0-9]+/g, "");
        if (val.length > 3) {
            return;
        }

        this.setState({
            defaultPEPM: val,
        });
    };

    render() {
        const { quote } = this.props;
        const { census, isSubmittingCensus, isManualCensus, showModal, stopLossAmounts, stopLossAmount, defaultPEPM } = this.state;

        return (
            <div className={css(styles.censusContainer)}>
                <ToastContainer position="top-center" autoClose={2500} />
                {isSubmittingCensus &&
                    <div className={css(styles.censusLoading)}>
                        <Spinner
                            aStyles={styles.loadingSpinner}
                            text="Uploading..."
                        />
                    </div>
                }
                {!isSubmittingCensus && quote.lastEnrollmentCensusSize > 0 && !isManualCensus &&
                    <React.Fragment>
                        <div className={css(styles.censusHeaderU)}>
                            <div className={css(styles.censusHeaderText)}>
                                We have an existing census from a UBenefit enrollment (last updated on {format(quote.lastEnrollmentDateWithCensus, "MM/DD/YYYY")}) with {quote.lastEnrollmentCensusSize} employees.
                            </div>
                            <ActionButton
                                aStyles={styles.useExistingContainer}
                                disabled={this.state.loading}
                                label={strings.useExistingCensusButton}
                                onClick={this._copyClickedU}
                                type="button"
                            />
                        </div>
                    </React.Fragment>
                }
                {!isSubmittingCensus && quote.lastQuoteCensusSize > 0 && !isManualCensus &&
                    <React.Fragment>
                        <div className={css(styles.censusHeader)}>
                            <div className={css(styles.censusHeaderText)}>
                                We have an existing census from Quote {quote.lastQuoteNumberWithCensus} (created on {format(quote.lastQuoteDateWithCensus, "MM/DD/YYYY")}) with {quote.lastQuoteCensusSize} employees.
                            </div>
                            <ActionButton
                                aStyles={styles.useExistingContainer}
                                disabled={this.state.loading}
                                label={strings.useExistingCensusButton}
                                onClick={this._copyClicked}
                                type="button"
                            />
                        </div>
                        <div className={css(styles.censusOptionsText)}>
                            {strings.censusOptionsLabel}
                        </div>
                    </React.Fragment>
                }
                {/*Census Copy or Upload*/}
                {!isSubmittingCensus && !isManualCensus &&
                    <div className={css(styles.censusOptionsContainer)}>
                        <Container>
                            <Row>
                                <Column large={this.state.censusUpload === null ? 5 : 12}>
                                    <div className={css(styles.censusOptionItem)}>
                                        <div className={css(styles.censusOptionItemHeader)}>
                                            <div className={css(styles.censusOptionItemHeaderText)}>
                                                {strings.loadCensus}
                                            </div>
                                            {this.state.censusUpload === null &&
                                                <a className={css(styles.downloadTemplateIcon)} href="/files/census_import.xlsx">
                                                    {strings.downloadTemplate}
                                                    <DownloadIcon className={css(styles.downloadIcon)} />
                                                </a>
                                            }
                                            <div className={css(
                                                styles.censusButton,
                                                styles.loadCensusButton,
                                                this.state.censusUpload !== null ? styles.censusFileSelected : null
                                            )}>
                                                <UploadIcon className={css(
                                                    styles.censusIcon,
                                                    styles.uploadIcon,
                                                    this.state.censusUpload !== null ? styles.censusUploadedIcon : null
                                                )} />
                                                {this.state.censusUpload === null ? (
                                                    <Dropzone onDrop={this._fileDropped}>
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                <div className={css(styles.censusButtonText)}>
                                                                    Drop your census file here or <FileBrowser onSelect={this._censusFileSelected} disableClick={true} />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                ) : (
                                                    <React.Fragment>
                                                        <div className={css(styles.censusButtonText)}>
                                                            Your File:
                                                        </div>
                                                        <div className={css(styles.censusSelectedButtonText)}>
                                                            {this.state.censusUpload.name}
                                                        </div>
                                                        <CancelIcon className={css(styles.clearCensusButton)} onClick={this._cancelClicked} />
                                                    </React.Fragment>
                                                )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Column>
                                {this.state.censusUpload === null &&
                                    <React.Fragment>
                                        <Column large={2}>
                                            <div className={css(styles.censusOptionSeparatorTextContainer)}>
                                                &mdash; OR &mdash;
                                            </div>
                                        </Column>
                                        <Column large={5}>
                                            <div className={css(styles.censusOptionItem)}>
                                                <div className={css(styles.censusOptionItemHeader)}>
                                                    <div className={css(styles.censusOptionItemHeaderText)}>
                                                        {strings.createCensus}
                                                    </div>
                                                </div>
                                                <div className={css(styles.censusButton, styles.createCensusButton)} onClick={this._addCensusManually}>
                                                    <InputTypeIcon className={css(styles.censusIcon, styles.inputTypeIcon)} />
                                                    <div className={css(styles.censusButtonText)}>
                                                        {strings.addCensusManually}
                                                    </div>
                                                </div>
                                            </div>
                                        </Column>
                                    </React.Fragment>
                                }
                            </Row>
                        </Container>
                        <div className={css(styles.censusButtonContainer)}>
                            {this.state.censusUpload &&
                                <ActionButton
                                    aStyles={styles.cancelButton}
                                    disabled={this.state.loading}
                                    label={strings.backButton}
                                    invertColor={true}
                                    type="button"
                                    onClick={this._cancelClicked}
                                />
                            }
                            {this.state.censusUpload !== null &&
                                <ActionButton
                                    aStyles={styles.submitCensusButton}
                                    disabled={this.state.loading}
                                    label={strings.submitButton}
                                    type="button"
                                    onClick={this._submitCensus}
                                    isLoading={this.props.isSaving}
                                />
                            }
                        </div>
                    </div>}
                {/*Manual Census*/}
                {!isSubmittingCensus && isManualCensus &&
                    <div className={css(styles.manualCensusContainer)}>
                        <Container>
                            <Row>
                                <Column large={12}>
                                    <div className={css(styles.confirmCensusHeader)}>
                                        <div className={css(styles.confirmCensusButtonHolder)}>
                                            <ActionButton
                                                aStyles={styles.cancelButton}
                                                disabled={this.state.loading}
                                                label={strings.cancelButton}
                                                invertColor={true}
                                                type="button"
                                                onClick={this._cancelClicked}
                                            />
                                            <ActionButton
                                                aStyles={styles.submitCensusButton}
                                                disabled={this.state.loading}
                                                label={strings.confirmCensusButton}
                                                onClick={this._confirmClicked}
                                                type="button"
                                            />
                                        </div>
                                        <div className={css(styles.confirmCensusHeaderText)}>
                                            {strings.censusDetails}
                                        </div>
                                    </div>
                                    {quote.censusUploadErrors &&
                                        <div className={css(styles.censusUploadErrors)}>
                                            <div className={css(styles.censusUploadHeader)}>
                                                {strings.censusUploadErrors}
                                            </div>
                                            {quote.censusUploadErrors.split('|').map((err, idx) => (
                                                <span key={idx}>{err}<br /></span>
                                            ))}
                                        </div>
                                    }
                                    <table className={css(styles.censusTable)}>
                                        <thead>
                                            <tr>
                                                <th className={css(styles.censusTableHeader)} style={{ width: "2%" }}>No.</th>
                                                <th className={css(styles.censusTableHeader)} style={{ width: "2%" }}>Rel</th>
                                                <th className={css(styles.censusTableHeader)} style={{ width: "8%", maxWidth: "100px" }}>First Name</th>
                                                <th className={css(styles.censusTableHeader)} style={{ width: "8%", maxWidth: "100px" }}>Last Name</th>
                                                <th className={css(styles.censusTableHeader)} style={{ width: "9%", minWidth: "115px", maxWidth: "125px" }}>DOB</th>
                                                <th className={css(styles.censusTableHeader)} style={{ width: "3%" }}>Gen</th>
                                                <th className={css(styles.censusTableHeader)} style={{ width: "3%" }}>Med</th>
                                                <th className={css(styles.censusTableHeader)} style={{ width: "3%" }}>Den</th>
                                                <th className={css(styles.censusTableHeader)} style={{ width: "3%" }}>Vis</th>
                                                <th className={css(styles.censusTableHeader)} style={{ width: "3%" }}>Life</th>
                                                <th className={css(styles.censusTableHeader)} style={{ width: "6%", maxWidth: "100px" }}>H ZIP</th>
                                                <th className={css(styles.censusTableHeader)} style={{ width: "6%", maxWidth: "100px" }}>W ZIP</th>
                                                <th className={css(styles.censusTableHeader)} style={{ width: "8%", maxWidth: "100px" }}>Title</th>
                                                <th className={css(styles.censusTableHeader)} style={{ width: "8%", maxWidth: "100px" }}>Salary</th>
                                                <th className={css(styles.censusTableHeader)} style={{ width: "9%", minWidth: "115px", maxWidth: "125px" }}>DOH</th>
                                                <th className={css(styles.censusTableHeader)} style={{ width: "12%", minWidth: "115px", maxWidth: "150px" }}>Email</th>
                                                <th className={css(styles.censusTableHeader)} style={{ width: "3%" }}>Tob</th>
                                                <th className={css(styles.censusTableHeader)} style={{ width: "3%" }}>COB</th>
                                                <th className={css(styles.censusTableHeader)} style={{ width: "2%" }}>{" "}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {census.map((cen, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <tr>
                                                            <td className={css(styles.censusTableCell, styles.greenBar)}>{cen.sequenceNumber}</td>
                                                            <td className={css(styles.censusTableCell)}>{cen.relationship}</td>
                                                            <td className={css(styles.censusTableCell)}>
                                                                <TextInput
                                                                    tabIndex={(index * 100) + 1}
                                                                    value={cen.firstName}
                                                                    placeholder="- First -"
                                                                    onChange={e => this._setFirstName(e, cen, null)}
                                                                    hideLabel={true}
                                                                    size="small"
                                                                    validationMessage={!cen.firstName ? "*" : ""}
                                                                />
                                                            </td>
                                                            <td className={css(styles.censusTableCell)}>
                                                                <TextInput
                                                                    tabIndex={(index * 100) + 2}
                                                                    value={cen.lastName}
                                                                    placeholder="- Last -"
                                                                    onChange={e => this._setLastName(e, cen, null)}
                                                                    hideLabel={true}
                                                                    size="small"
                                                                    validationMessage={!cen.lastName ? "*" : ""}
                                                                />
                                                            </td>
                                                            <td className={css(styles.censusTableCell)}>
                                                                <TextInput
                                                                    tabIndex={(index * 100) + 3}
                                                                    value={cen.birthDate && cen.birthDate.length >= 10 ? format(cen.birthDate, "MM/DD/YYYY") : cen.birthDate ? cen.birthDate : ""}
                                                                    placeholder="- MM/DD/YYYY -"
                                                                    onChange={e => this._setDOB(e, cen, null)}
                                                                    hideLabel={true}
                                                                    size="small"
                                                                    validationMessage={!cen.birthDate || cen.birthDate.length < 10 ? "*" : ""}
                                                                />
                                                            </td>
                                                            <td className={css(styles.censusTableCell)}>
                                                                <SmallDropdown
                                                                    tabIndex={(index * 100) + 4}
                                                                    name={inputNames.gender}
                                                                    placeholder="- M/F -"
                                                                    options={this.state.gender.map(o => ({
                                                                        label: o.name,
                                                                        value: o.id,
                                                                    }))}
                                                                    onChange={e => this._setGender(e, cen, null)}
                                                                    value={cen.gender}
                                                                    validationMessage={!cen.gender ? "*" : ""}
                                                                />
                                                            </td>
                                                            <td className={css(styles.censusTableCell)}>
                                                                <SmallDropdown
                                                                    tabIndex={(index * 100) + 5}
                                                                    name={inputNames.medicalCoverage}
                                                                    placeholder="- Med -"
                                                                    options={this.state.coverage.map(o => ({
                                                                        label: o.name,
                                                                        value: o.id,
                                                                    }))}
                                                                    onChange={e => this._setMedicalCoverage(e, cen, null)}
                                                                    value={cen.medicalCoverageId ? cen.medicalCoverageId.toString() : "1"}
                                                                />
                                                            </td>
                                                            <td className={css(styles.censusTableCell)}>
                                                                <SmallDropdown
                                                                    tabIndex={(index * 100) + 6}
                                                                    name={inputNames.dentalCoverage}
                                                                    placeholder="- Den -"
                                                                    options={this.state.coverage.map(o => ({
                                                                        label: o.name,
                                                                        value: o.id,
                                                                    }))}
                                                                    onChange={e => this._setDentalCoverage(e, cen, null)}
                                                                    value={cen.dentalCoverageId ? cen.dentalCoverageId.toString() : "1"}
                                                                />
                                                            </td>
                                                            <td className={css(styles.censusTableCell)}>
                                                                <SmallDropdown
                                                                    tabIndex={(index * 100) + 7}
                                                                    name={inputNames.visionCoverage}
                                                                    placeholder="- Vis -"
                                                                    options={this.state.coverage.map(o => ({
                                                                        label: o.name,
                                                                        value: o.id,
                                                                    }))}
                                                                    onChange={e => this._setVisionCoverage(e, cen, null)}
                                                                    value={cen.visionCoverageId ? cen.visionCoverageId.toString() : "1"}
                                                                />
                                                            </td>
                                                            <td className={css(styles.censusTableCell)}>
                                                                <SmallDropdown
                                                                    tabIndex={(index * 100) + 8}
                                                                    name={inputNames.lifeCoverage}
                                                                    placeholder="- Lif -"
                                                                    options={this.state.coverage2.map(o => ({
                                                                        label: o.name,
                                                                        value: o.id,
                                                                    }))}
                                                                    onChange={e => this._setLifeCoverage(e, cen, null)}
                                                                    value={cen.lifeCoverageId ? cen.lifeCoverageId.toString() : "1"}
                                                                />
                                                            </td>
                                                            <td className={css(styles.censusTableCell)}>
                                                                <TextInput
                                                                    tabIndex={(index * 100) + 9}
                                                                    value={cen.homePostalCode ? cen.homePostalCode : ""}
                                                                    placeholder="- 00000 -"
                                                                    onChange={e => this._setHZip(e, cen, null)}
                                                                    hideLabel={true}
                                                                    size="small"
                                                                    validationMessage={!cen.homePostalCode || cen.homePostalCode.length < 5 ? "*" : ""}
                                                                />
                                                            </td>
                                                            <td className={css(styles.censusTableCell)}>
                                                                <TextInput
                                                                    tabIndex={(index * 100) + 10}
                                                                    value={cen.workPostalCode ? cen.workPostalCode : ""}
                                                                    placeholder="- 00000 -"
                                                                    onChange={e => this._setWZip(e, cen)}
                                                                    hideLabel={true}
                                                                    size="small"
                                                                />
                                                            </td>
                                                            <td className={css(styles.censusTableCell)}>
                                                                <TextInput
                                                                    tabIndex={(index * 100) + 11}
                                                                    value={cen.occupation ? cen.occupation : ""}
                                                                    placeholder="- Title -"
                                                                    onChange={e => this._setOccupation(e, cen)}
                                                                    hideLabel={true}
                                                                    size="small"
                                                                />
                                                            </td>
                                                            <td className={css(styles.censusTableCell)}>
                                                                <TextInput
                                                                    tabIndex={(index * 100) + 12}
                                                                    value={cen.salary ? cen.salary : ""}
                                                                    placeholder="- Salary -"
                                                                    onChange={e => this._setSalary(e, cen)}
                                                                    hideLabel={true}
                                                                    size="small"
                                                                />
                                                            </td>
                                                            <td className={css(styles.censusTableCell)}>
                                                                <TextInput
                                                                    tabIndex={(index * 100) + 13}
                                                                    value={cen.hireDate && cen.hireDate.length >= 10 ? format(cen.hireDate, "MM/DD/YYYY") : cen.hireDate ? cen.hireDate : ""}
                                                                    placeholder="- MM/DD/YYYY -"
                                                                    onChange={e => this._setDOH(e, cen)}
                                                                    hideLabel={true}
                                                                    size="small"
                                                                />
                                                            </td>
                                                            <td className={css(styles.censusTableCell)}>
                                                                <TextInput
                                                                    tabIndex={(index * 100) + 14}
                                                                    value={cen.email ? cen.email : ""}
                                                                    placeholder="- Email -"
                                                                    onChange={e => this._setEmail(e, cen)}
                                                                    hideLabel={true}
                                                                    size="small"
                                                                />
                                                            </td>
                                                            <td className={css(styles.censusTableCell)}>
                                                                <SmallDropdown
                                                                    tabIndex={(index * 100) + 15}
                                                                    name={inputNames.isTobacco}
                                                                    placeholder="- Y/N -"
                                                                    options={this.state.yesNo.map(o => ({
                                                                        label: o.name,
                                                                        value: o.id,
                                                                    }))}
                                                                    onChange={e => this._setTobacco(e, cen, null)}
                                                                    value={cen.isTobacco ? "1" : "0"}
                                                                />
                                                            </td>
                                                            <td className={css(styles.censusTableCell)}>
                                                                <SmallDropdown
                                                                    tabIndex={(index * 100) + 16}
                                                                    name={inputNames.onCobra}
                                                                    placeholder="- Y/N -"
                                                                    options={this.state.yesNo.map(o => ({
                                                                        label: o.name,
                                                                        value: o.id,
                                                                    }))}
                                                                    onChange={e => this._setCOB(e, cen)}
                                                                    value={cen.onCobra ? "1" : "0"}
                                                                />
                                                            </td>
                                                            <td className={css(styles.censusTableCell)}>
                                                                <CancelIcon className={css(styles.clearCensusButton)} onClick={() => this._removeCensusMember(cen)} />
                                                            </td>
                                                        </tr>
                                                        {cen.dependents.map((dep, index2) => (
                                                            <tr key={(index * 100) + index2} className="sub">
                                                                <td className={css(styles.censusTableCell, styles.blueBar)}>{dep.sequenceNumber}</td>
                                                                <td className={css(styles.censusTableCell)}>
                                                                    <SmallDropdown
                                                                        tabIndex={(index * 100) + (index2 * 100) + 15}
                                                                        name={inputNames.relationship}
                                                                        placeholder="- SP/C -"
                                                                        options={this.state.relationship.map(o => ({
                                                                            label: o.name,
                                                                            value: o.name,
                                                                        }))}
                                                                        onChange={e => this._setRelationship(e, cen, dep)}
                                                                        value={dep.dependentType === "Spouse" ? "SP" : "C"}
                                                                        validationMessage={!dep.relationship ? "*" : ""}
                                                                    />
                                                                </td>
                                                                <td className={css(styles.censusTableCell)}>
                                                                    <TextInput
                                                                        tabIndex={(index * 100) + (index2 * 100) + 16}
                                                                        value={dep.firstName}
                                                                        placeholder="- First -"
                                                                        onChange={e => this._setFirstName(e, cen, dep)}
                                                                        hideLabel={true}
                                                                        size="small"
                                                                        validationMessage={!dep.firstName ? "*" : ""}
                                                                    />
                                                                </td>
                                                                <td className={css(styles.censusTableCell)}>
                                                                    <TextInput
                                                                        tabIndex={(index * 100) + (index2 * 100) + 17}
                                                                        value={dep.lastName}
                                                                        placeholder="- Last -"
                                                                        onChange={e => this._setLastName(e, cen, dep)}
                                                                        hideLabel={true}
                                                                        size="small"
                                                                        validationMessage={!dep.lastName ? "*" : ""}
                                                                    />
                                                                </td>
                                                                <td className={css(styles.censusTableCell)}>
                                                                    <TextInput
                                                                        tabIndex={(index * 100) + (index2 * 100) + 18}
                                                                        value={dep.birthDate && dep.birthDate.length >= 10 ? format(dep.birthDate, "MM/DD/YYYY") : dep.birthDate ? dep.birthDate : ""}
                                                                        placeholder="- MM/DD/YYYY -"
                                                                        onChange={e => this._setDOB(e, cen, dep)}
                                                                        data-type="date"
                                                                        hideLabel={true}
                                                                        size="small"
                                                                        validationMessage={!dep.birthDate || dep.birthDate.length < 10 ? "*" : ""}
                                                                    />
                                                                </td>
                                                                <td className={css(styles.censusTableCell)}>
                                                                    <SmallDropdown
                                                                        tabIndex={(index * 100) + (index2 * 100) + 19}
                                                                        name={inputNames.gender}
                                                                        placeholder="- M/F -"
                                                                        options={this.state.gender.map(o => ({
                                                                            label: o.name,
                                                                            value: o.id,
                                                                        }))}
                                                                        onChange={e => this._setGender(e, cen, dep)}
                                                                        value={dep.gender}
                                                                        validationMessage={!dep.gender ? "*" : ""}
                                                                    />
                                                                </td>
                                                                <td className={css(styles.censusTableCell)}>{" "}</td>
                                                                <td className={css(styles.censusTableCell)}>{" "}</td>
                                                                <td className={css(styles.censusTableCell)}>{" "}</td>
                                                                <td className={css(styles.censusTableCell)}>{" "}</td>
                                                                <td className={css(styles.censusTableCell)}>
                                                                    <TextInput
                                                                        tabIndex={(index * 100) + (index2 * 100) + 20}
                                                                        value={dep.homePostalCode ? dep.homePostalCode : ""}
                                                                        placeholder="- 00000 -"
                                                                        onChange={e => this._setHZip(e, cen, dep)}
                                                                        hideLabel={true}
                                                                        size="small"
                                                                        validationMessage={!dep.homePostalCode || dep.homePostalCode.length < 5 ? "*" : ""}
                                                                    />
                                                                </td>
                                                                <td className={css(styles.censusTableCell)}>{" "}</td>
                                                                <td className={css(styles.censusTableCell)}>{" "}</td>
                                                                <td className={css(styles.censusTableCell)}>{" "}</td>
                                                                <td className={css(styles.censusTableCell)}>{" "}</td>
                                                                <td className={css(styles.censusTableCell)}>{" "}</td>
                                                                <td className={css(styles.censusTableCell)}>
                                                                    <SmallDropdown
                                                                        tabIndex={(index * 100) + (index2 * 100) + 21}
                                                                        name={inputNames.isTobacco}
                                                                        placeholder="- Y/N -"
                                                                        options={this.state.yesNo.map(o => ({
                                                                            label: o.name,
                                                                            value: o.id,
                                                                        }))}
                                                                        onChange={e => this._setTobacco(e, cen, dep)}
                                                                        value={dep.isTobacco ? "1" : "0"}
                                                                    />
                                                                </td>
                                                                <td className={css(styles.censusTableCell)}>{" "}</td>
                                                                <td className={css(styles.censusTableCell)}>
                                                                    <CancelIcon className={css(styles.clearCensusButton)} onClick={() => this._removeDependent(cen, dep)} />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <td colSpan="19" className={css(styles.censusTableCell, styles.censusTableCellLink, styles.censusTableCellRight, styles.blueBar)} onClick={() => this._addDependent(cen)}>Add Dependent</td>
                                                        </tr>
                                                    </React.Fragment>);
                                            })}
                                            <tr>
                                                <td colSpan="19" className={css(styles.censusTableCell, styles.censusTableCellLink, styles.censusTableCellCenter, styles.greenBar)} onClick={this._addCensusMember}>Add Employee</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Column>
                            </Row>
                        </Container>
                    </div>}
                <Modal show={showModal} header="ISL / PEPM Settings">
                    <div className={css(styles.modalContent)}>
                        <div>
                            <p>You chose to include Level Funded products that require {stopLossAmount > 0 ? "ISL and" : ""} PEPM. Please confirm the {stopLossAmount > 0 ? "amounts" : "amount"} below.</p>
                            <table className={css(styles.censusTable)}>
                                <tbody>
                                    {stopLossAmount > 0 ? (
                                        <tr>
                                            <td className={css(styles.censusTableCell)}>Stop Loss:</td>
                                            <td className={css(styles.censusTableCell)}>
                                                <SmallDropdown
                                                    placeholder="- Stop Loss -"
                                                    options={stopLossAmounts.map(o => ({
                                                        label: o.toString(),
                                                        value: o.toString(),
                                                    }))}
                                                    onChange={this._handleISLChanged}
                                                    value={stopLossAmount ? stopLossAmount.toString() : ""}
                                                />
                                            </td>
                                        </tr>
                                    ) : null}
                                    <tr>
                                        <td className={css(styles.censusTableCell)}>PEPM:</td>
                                        <td className={css(styles.censusTableCell)}>
                                            <TextInput
                                                placeholder="- PEPM -"
                                                onChange={this._handlePEPMChanged}
                                                data-type="number"
                                                hideLabel={true}
                                                size="small"
                                                value={defaultPEPM ? defaultPEPM.toString() : ""}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={css(styles.modalButtonContainer)}>
                        <ActionButton
                            aStyles={styles.modalButton}
                            label="Continue"
                            invertColor={true}
                            type="button"
                            onClick={this._continueSubmit}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}