import instance from "./instance";

const responseData = response => response.data;

export function getAll() {
    return instance
        .request({
            url: "/agency/get-agencies",
            method: "GET",
        })
        .then(responseData);
}

export function getPaged(page, sortColumn, sortDirection) {
    return instance
        .request({
            url: "/agency/get-agencies-page",
            method: "POST",
            data: {
                page,
                sortColumn,
                sortDirection
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(responseData);
}

export function searchAgencies(term) {
    term = term.replace(/[^0-9a-zA-Z\s]+/g,"");
    if (term.length === 0) { term = "*"; }
    return instance
        .request({
            url: `/agency/search-agencies/${term}`,
            method: "GET",
        })
        .then(responseData);
}

export function getById(id) {
    return instance
        .request({
            url: `/agency/get-agency/${id}`,
            method: "GET",
        })
        .then(responseData);
}

export function saveAgency(agency) {
    return instance
        .request({
            url: "/agency/save-agency",
            method: "POST",
            data: { ...agency },
        })
        .then(responseData);
}