import * as breakpoints from "../../constants/breakpoints";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";

export const GUTTER_WIDTH = 8;

const styles = StyleSheet.create({
    container: {
        paddingLeft: 0,
        paddingRight: 0,
        margin: "0 auto",

        [breakpoints.tabletAndDown]: {
            paddingLeft: 24,
            paddingRight: 24,
        },
    },
});

export default class Container extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        fluid: PropTypes.bool.isRequired,
        maxWidth: PropTypes.string.isRequired,
        backgroundColor: PropTypes.string,
    };

    static defaultProps = {
        fluid: false,
        maxWidth: "100vw",
        /*maxWidth: "calc(100vw - 160px)", -- to get the gray side bars back */
    };

    render() {
        let style;
        if (!this.props.fluid) {
            style = { maxWidth: this.props.maxWidth };
        }
        if (this.props.backgroundColor) {
            style = {...style, backgroundColor: this.props.backgroundColor };
        }
        return (
            <div className={css(styles.container)} style={style}>
                {this.props.children}
            </div>
        );
    }
}
