import * as color from "../../constants/color";
import * as breakpoints from "../../constants/breakpoints";
import * as texchangePropTypes from "../../texchangePropTypes";
import ErrorMessage from "../text/ErrorMessage";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";

const placeholder = {
    color: color.darkGray5,
    fontWeight: 400,
};

const styles = StyleSheet.create({
    container: {
        position: "relative",
    },
    input: {
        width: "100%",
        backgroundColor: color.white,
        border: "solid",
        borderWidth: 1,
        borderRadius: 4,
        borderColor: color.mediumGray,
        color: color.darkestGray,
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "22px",
        outline: "none",
        padding: "18px 16px",        
        margin: "10px 0px",
        height: 120,

        "::-webkit-input-placeholder": placeholder,
        "::-moz-placeholder": placeholder,
        "::-ms-input-placeholder": placeholder,
        "::-placeholder": placeholder,

        ":focus": {
            borderColor: color.lightOrange,
            borderWidth: 2,
        },

        [breakpoints.tabletAndDown]: {
            letterSpacing: 0.17,
        },

        [breakpoints.desktopOnly]: {
            
        },
    },
        
    hasValue: {
        paddingTop: 24,        
        paddingBottom: 12,
    },
    title: {
        position: "absolute",
        top: 18,
        left: 16,
        fontSize: 13,
        color: color.darkGray,
    },
    disabled: {
        color: color.darkestBlueAlpha30,
    },
    withValidationError: {
        color: color.darkRed,
    },
    validationMessage: {
        position: "absolute",
        top: 16,
        right: 8,
        pointerEvents: "none",
    },
});

class TextArea extends Component {
    static propTypes = {
        aStyles: texchangePropTypes.aphroditeStyles,
        forwardedRef: PropTypes.object,
        validationMessage: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.string,
        ]),
        disabled: PropTypes.bool,
        value: PropTypes.string,
        placeholder: PropTypes.string,
        hideLabel: PropTypes.bool,                
    };

    render() {
        const { aStyles, forwardedRef, validationMessage, hideLabel, ...rest } = this.props;        

        return (
            <div className={css(styles.container, aStyles)}>
                
                <textarea
                    {...rest}
                    ref={forwardedRef}
                    className={css(
                        styles.input,                        
                        this.props.disabled ? styles.disabled : null,
                        validationMessage ? styles.withValidationError : null,
                        this.props.value !== "" && !hideLabel ? styles.hasValue : null
                    )}                    
                />                
                
                {this.props.value !== "" && this.props.value !== null && !hideLabel ? (
                    <div className={css(styles.title)}>{this.props.placeholder}</div>
                ) : null}

                {validationMessage ? (
                    <ErrorMessage aStyles={styles.validationMessage}>
                        {validationMessage}
                    </ErrorMessage>
                ) : null}
            </div>
        );
    }
}

const WrappedComponent = React.forwardRef((props, ref) => {
    return <TextArea {...props} forwardedRef={ref} />;
});

WrappedComponent.displayName = "WrappedTextInput";
export default WrappedComponent;
