import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import * as texchangePropTypes from "../../texchangePropTypes";
import * as color from "../../constants/color";

const styles = StyleSheet.create({
	container: {
		fontSize: 16,
		color: color.darkestGray,
	},
	toggleContainer: {
		display: "flex",
		position: "relative",
		cursor: "pointer",
		backgroundColor: "transparent",
		border: 0,
		padding: 0,		
		alignItems: "center",
	},	
	bar: {
		backgroundColor: "#DDD",
		width: 40,
		height: 16,
		padding: 0,		
		borderRadius: 24,
	},
	circle: {
		// transition: "all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms",
		position: "relative",
		top: -3,
		left: -2,
		width: 22,
		height: 22,
		border: `1px solid ${color.darkOrange}`,
		borderRadius: "50%",
		backgroundColor: color.darkOrange,

		"-webkit-box-sizing": "border-box",
		"-moz-box-sizing": "border-box",
		"box-sizing": "border-box",

		"-webkit-transition": "all 0.25s ease",
		"-moz-transition": "all 0.25s ease",
		"transition": "all 0.25s ease",
	},
	unchecked: {
		left: 18,
	},
	checkedLabel: {
		marginRight: 12,		
	},
	uncheckedLabel: {
		marginLeft: 12,		
	},
});

export default class Toggle extends Component {
	static propTypes = {
		aStyles: texchangePropTypes.aphroditeStyles,
        checked: PropTypes.bool,
        defaultChecked: PropTypes.bool,        
        onChange: PropTypes.func,
        name: PropTypes.string,
        value: PropTypes.string,
        checkedLabel: PropTypes.string,
        uncheckedLabel: PropTypes.string,
    };

	constructor(props) {
		super(props);

		this.state = {
			checked: !!(props.checked || props.defaultChecked)
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.checked !== this.props.checked) {
			this.setState({
				checked: !!(this.props.checked || this.props.defaultChecked)
			});
		}
	}

	_handleClick = () => {		
		let { checked } = this.state;		
		checked = !checked;
		this.setState({ checked });
		this.props.onChange(checked);
	};

	render() {
		const { checked } = this.state;		
		return (
			<div className={css(styles.container, this.props.aStyles)}>
				<div 
					className={css(styles.toggleContainer)}
					onClick={this._handleClick}
				>
					<div className={css(styles.checkedLabel)}>{this.props.checkedLabel}</div>
					<div className={css(styles.bar)}>
						<div className={css(
							styles.circle,
							!checked ? styles.unchecked : null,
						)} />
					</div>
					<div className={css(styles.uncheckedLabel)}>{this.props.uncheckedLabel}</div>				
				</div>
			</div>
		);
	}
}
