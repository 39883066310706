import * as color from "../constants/color";
import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    questionMark: {
        borderRadius: "50%",        
        backgroundColor: color.mediumYellow1,
        width: 24,
        height: 24,
        textAlign: "center",
        color: color.white,
        float: "right",
        position: "relative",
        top: -42,
        left: 36,
        cursor: "pointer",  
        paddingTop: 4,              
        ":hover > span": {
            visibility: "visible",
        }
    },
    tooltipText: {
        visibility: "hidden",
        backgroundColor: color.black,
        width: 300,
        color: color.white,
        textAlign: "center",
        padding: "12px 12px",        
        borderRadius: 6,
        position: "absolute",
        zIndex: 1,
        fontSize: 14,        
    }
});

export default function Tooltip(props) {
    const { text } = props;
    return (
        <div className={css(styles.questionMark)}>
            ?
            <span className={css(styles.tooltipText)}>{text}</span>
        </div>
    );
}

Tooltip.propTypes = {    
    text: PropTypes.string,
};
