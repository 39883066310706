import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import Tabs from "../Tabs";
import Plans from "./Plans";
import LinesOfCoverage from "./LinesOfCoverage";
import coverageTypes from "../../constants/coverageTypes";
import * as planTypes from "../../constants/planTypes";
import * as color from "../../constants/color";
import * as texchangePropTypes from "../../texchangePropTypes";
import SelectedPlans from "./SelectedPlans";

const styles = StyleSheet.create({
    coverageContainer: {        
        marginTop: 30,
    },
    left: {
        clear: "both",
        float: "left",
        position: "relative",
        top: 8,
        left: 8,
        marginTop: 10,
        fontSize: 18,
        color: color.darkRed,
    },
    right: {
        float: "right",
        position: "relative",
        top: 8,
        right: 20,
        marginTop: 10,
        fontSize: 14,
        color: color.darkestGray,
    },
    contain: {
        width: "100%",
        overflowX: "scroll",
    },
});

export class CurrentPlans extends Component {
    static propTypes = {
        quote: texchangePropTypes.quote.isRequired,
        isVisible: PropTypes.bool.isRequired,
        selectedPlans: PropTypes.object.isRequired,
        onContinue: PropTypes.func,
    };

    state = {
        selectedCoverage: ["Medical"],
        selectionComplete: false,
    };

    _handleCoverageSelected = selectedCoverage => {
        this.setState({ 
            selectedCoverage, 
            selectionComplete: true,
         });
    };

	render() {
        const { quote, isVisible, selectedPlans } = this.props;
        const { selectedCoverage } = this.state;
        const showLinesOfCoverage = selectedCoverage.length === 0;   
        
        const curMedCount = quote.currentMedicalPlans && quote.currentMedicalPlans.length > 0 ? quote.currentMedicalPlans.length : null;
        const renMedCount = quote.renewalMedicalPlans && quote.renewalMedicalPlans.length > 0 ? quote.renewalMedicalPlans.length : null;
        const newMedCount = selectedPlans.medical && selectedPlans.medical.length > 0 ? selectedPlans.medical.length : null;
        const curDenCount = quote.currentDentalPlans && quote.currentDentalPlans.length > 0 ? quote.currentDentalPlans.length : null;
        const renDenCount = quote.renewalDentalPlans && quote.renewalDentalPlans.length > 0 ? quote.renewalDentalPlans.length : null;
        const newDenCount = selectedPlans.dental && selectedPlans.dental.length > 0 ? selectedPlans.dental.length : null;
        const curVisCount = quote.currentVisionPlans && quote.currentVisionPlans.length > 0 ? quote.currentVisionPlans.length : null;
        const renVisCount = quote.renewalVisionPlans && quote.renewalVisionPlans.length > 0 ? quote.renewalVisionPlans.length : null;
        const newVisCount = selectedPlans.vision && selectedPlans.vision.length > 0 ? selectedPlans.vision.length : null;
        const curLifCount = quote.currentLifePlans && quote.currentLifePlans.length > 0 ? quote.currentLifePlans.length : null;
        const renLifCount = quote.renewalLifePlans && quote.renewalLifePlans.length > 0 ? quote.renewalLifePlans.length : null;
        const newLifCount = selectedPlans.life && selectedPlans.life.length > 0 ? selectedPlans.life.length : null;
        //count them all up
        const allCount = curMedCount + renMedCount + newMedCount + 
                         curDenCount + renDenCount + newDenCount +
                         curVisCount + renVisCount + newVisCount +
                         curLifCount + renLifCount + newLifCount;

        //census election counts
        const census = quote.census;
        const medCensusCount = census ? census.filter(x => x.relationship === "EE" && x.medicalCoverageId < 5).length : 0;
        const denCensusCount = census ? census.filter(x => x.relationship === "EE" && x.dentalCoverageId < 5).length : 0;
        const visCensusCount = census ? census.filter(x => x.relationship === "EE" && x.visionCoverageId < 5).length : 0;
        const lifCensusCount = census ? census.filter(x => x.relationship === "EE" && x.lifeCoverageId < 5).length : 0;

        return (
            <React.Fragment>
                {showLinesOfCoverage && isVisible &&
                    <div className={css(styles.coverageContainer)}>
                        <LinesOfCoverage 
                            selectedCoverage={selectedCoverage} 
                            coverageSelected={this._handleCoverageSelected}
                        />    
                    </div>
                }
                
                <Tabs isVisible={isVisible && !showLinesOfCoverage}>
                    {medCensusCount > 0 &&
                        <div label="Medical" count={curMedCount}>
                            <Plans
                                quote={quote}
                                planType={planTypes.currentPlan}
                                coverageType={coverageTypes.medical}
                                onContinue={this.props.onContinue}
                            />
                        </div>
                    }
                    {denCensusCount > 0 &&
                        <div label="Dental" count={curDenCount}>
                            <Plans
                                quote={quote}
                                planType={planTypes.currentPlan}
                                coverageType={coverageTypes.dental}
                            />
                        </div>
                    }
                    {visCensusCount > 0 &&
                        <div label="Vision" count={curVisCount}>
                            <Plans
                                quote={quote}
                                planType={planTypes.currentPlan}
                                coverageType={coverageTypes.vision}
                            />
                        </div>
                    }
                    {/*lifCensusCount > 0 &&
                        <div label="Life" count={curLifCount}>
                            <Plans
                                quote={quote}
                                planType={planTypes.currentPlan}
                                coverageType={coverageTypes.life}
                            />
                        </div>
                    */}
                    <div label="Selected" count={allCount}>
                        <SelectedPlans
                            quote={quote}
                        />                        
                    </div>
                </Tabs>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = state => ({
    selectedPlans: state.proposal.selectedPlans,
});

export default connect(
    mapDispatchToProps,
)(CurrentPlans);