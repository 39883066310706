import * as color from "../../constants/color";
import * as texchangePropTypes from "../../texchangePropTypes";
import * as routeHelpers from "../../lib/routeHelpers";
import Spinner from "../Spinner";
import SearchInput from "../inputs/SearchInput";
import alertIcon from "../../images/SVGs/alert.svg";
import downloadIcon from "../../images/SVGs/Download.svg";
import guageIcon from "../../images/guage.png";
import HomeDashboardIcon from "../../images/SVGs/HomeDashboard.svg"
import shieldIcon from "../../images/shield.png";
import PageTitle from "../text/PageTitle";
import React, { Component } from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import Tabs from "../Tabs";
import * as newsApi from "../../lib/api/news";
import * as searchApi from "../../lib/api/search";
import * as dataApi from "../../lib/api/data";
import format from "date-fns/format";
import { ToastContainer, toast } from 'react-toastify';
import { Chart, Doughnut, Bar } from 'react-chartjs-2';
import 'react-toastify/dist/ReactToastify.css';
import 'chartjs-plugin-datalabels';

Chart.defaults.global.defaultFontFamily = "'Roboto','Helvetica','Arial',sans-serif";

const styles = StyleSheet.create({
    content: {
        paddingRight: 10,
        paddingBottom: 80,
        ":after": {
            content: '""',
            display: "table",
            clear: "both",
        },
    },
    contentSection: {
        width: "100%",
        padding: 0,
        backgroundColor: color.white,
        borderTop: `8px solid ${color.mediumRed}`,
        marginBottom: 20,
    },
    titleSection: {
        heigth: 64,
    },
    searchBox: {
        maxWidth: 600,
        textAlign: "center",
        margin: "30px auto 30px auto",
    },
    charts: {
        textAlign: "center",
        padding: 30,
    },
    table: {
        width: "100%",
        marginTop: 10,
        marginBottom: 20,
        borderCollapse: "collapse",
    },
    tableHeader: {
        fontSize: 13,
        fontWeight: "bold",
        color: color.mediumYellow1,
        backgroundColor: color.white,
        padding: 10,
        whiteSpace: "nowrap",
        borderBottom: `1px solid ${color.mediumGray}`,
        verticalAlign: "bottom",
    },
    tableHeaderNoBorder: {
        fontSize: 16,
        fontWeight: "bold",
        color: color.borderBackgroundGray,
        backgroundColor: color.white,
        padding: 10,
        whiteSpace: "nowrap",
        verticalAlign: "bottom",
        textAlign: "center",
    },
    tableHeaderBlue: {
        backgroundColor: color.lightGray6,
        color: color.mediumYellow1
    },
    tableHeaderCurrentQuarter: {
        backgroundColor: color.lightGray6,
        color: color.black
    },
    tableHeaderGreen: {
        backgroundColor: color.lightGray7,
        color: color.mediumYellow1
    },
    tableHeaderNextQuarter: {
        backgroundColor: color.lightGray7,
        color: color.black
    },
    stateNameColBlue: {
        backgroundColor: color.lightGray6,
        color: color.black
    },
    stateNameColGreen: {
        backgroundColor: color.lightGray7,
        color: color.black
    },
    tableRow: {
        fontSize: 14,
        color: color.tableText,
        padding: "10px 10px",
        borderBottom: `1px solid ${color.tableBorder}`,
    },
    tableRowTopBorder: {
        borderTop: `1px solid ${color.tableBorder}`,
    },
    tableRowRightBorder: {
        fontSize: 16,
        fontWeight: "bold",
        color: color.tableText,
        backgroundColor: color.white,
        padding: "10px 10px",
        borderRight: `1px solid ${color.tableBorder}`,
        textAlign: "center",
    },
    tableRowLeftBorder: {
        fontSize: 14,
        color: color.tableText,
        backgroundColor: color.white,
        padding: "10px 10px",
        borderLeft: `1px solid ${color.tableBorder}`,
    },
    tdLive: {
        fontSize: 24,
        color: color.mediumGreen,
        fontWeight: "bold",
    },
    tdNotLive: {
        fontSize: 24,
        color: color.red,
        fontWeight: "bold",
    },
    tdSmall: {
        fontSize: 12,
    },
    tdNA: {
        fontSize: 13,
        color: color.mediumGray,
        fontWeight: 300,
        fontStyle: "italic",
    },
    tdChart: {
        backgroundColor: color.white,
        padding: "10px 10px 40px 10px",
        verticalAlign: "top",
    },
    thirty: {
        width: "30%",
    },
    forty: {
        width: "40%",
    },
    chartTitle: {
        fontSize: 27,
        fontWeight: 500,
        color: color.tableText,
        display: "inline-block",
        padding: "15px 0px",
    },
    chartInfo: {
        fontSize: 20,
        fontWeight: 200,
        color: color.tableText,
    },
    chartLegend: {
        display: "inline-block",
        color: color.white,
        fontWeight: "bold",
    },
    table2: {
        width: "100%",
        marginLeft: 10,
        borderCollapse: "collapse",
    },
    stage1Color: {
        backgroundColor: "#268FE2",
        padding: 6,
    },
    stage2Color: {
        backgroundColor: "#0A6FBE",
        padding: 6,
    },
    stage3Color: {
        backgroundColor: "#065592",
        padding: 6,
    },
    brokerColor: {
        backgroundColor: "#6D5DCE",
        padding: 6,
    },
    rbgColor: {
        backgroundColor: "#271EB2",
        padding: 6,
    },
    barChartHolder: {
        backgroundColor: color.lightGray4,
        borderRadius: 8,
        paddingTop: 20,
        minHeight: 240,
    },
    doughnutHolderLeft: {
        float: "left",
    },
    doughnutHolderRight: {
        float: "right",
    },
    groupSizeHolder: {
        clear: "both",
    },
    relative: {
        position: "relative",
    },
    absoluteCenter: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    textCenter: {
        textAlign: "center",
    },
    minus10: {
        marginTop: -10,
    },
    largeText: {
        fontSize: 30,
        fontWeight: "bold",
    },
    smallText: {
        fontSize: 18,
        fontWeight: "bold",
    },
    gray: {
        color: color.mediumGray,
    },
    green: {
        color: color.mediumRed,
    },
    darkred: {
        color: color.darkRed,
    },
    minHeight: {
        minHeight: 200,
        padding: 20,
    },
    downloadFloatRight: {
        float: "right",
        marginTop: -54,
        marginRight: 20,
        fontSize: 18,
        fontWeight: "bold",
        color: color.mediumYellow1,
        textDecoration: "underline",
        cursor: "pointer",
    },
    downloadReport: {
        height: "30px",
        verticalAlign: "middle",
    },
    loadingSpinner: {
        paddingTop: 48,
        paddingBottom: 48,
    },
});

const strings = {
    brokerTitle: "Broker Dashboard (By Effective Date)",
    rbgTitle: "Dashboard (By Effective Date)",
    alerts: "Alerts & News",
    products: "Product Availability",
    searchPlaceholder: "Search for Brokers, Groups, or Quotes",
};

const inputNames = {
    search: "search",
};

export class UserDashboardScreen extends Component {
    static propTypes = {
        history: texchangePropTypes.history.isRequired,
        me: texchangePropTypes.me.isRequired,
    };

    state = {
        dashboardLoaded: false,
        inputs: {
            [inputNames.search]: "",
        },
        news: [],
        alerts: [],
        groupsQuotedData: {},
        incompleteQuotesData: {},
        brokerRBGQuotesData: {},
        renewalsData: {},
        closeRatio3AgoData: {},
        closeRatio2AgoData: {},
        closeRatio1AgoData: {},
        products: [],
        generatingReport: false,
    };

    componentDidMount() {
        this._mounted = true;

        //loading
        Promise.all([newsApi.getNews(), newsApi.getAlerts(), dataApi.getProductAvailability()])
            .then(responses => {
                if (responses && responses[0] && responses[1] && responses[2] && this._mounted) {
                    this.setState({
                        news: responses[0],
                        alerts: responses[1],
                        products: responses[2]
                    });
                }
            });

        this._getChartData();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    _getChartData = () => {
        //broker?
        const { me } = this.props;
        const brokerId = me.isBroker && me.brokerId ? me.brokerId : 0;

        Promise.all([dataApi.getChartData("groups_quoted", brokerId), dataApi.getChartData("incomplete_quotes", brokerId), dataApi.getChartData("broker_vs_rbg_quotes", brokerId), dataApi.getChartData("renewals", brokerId), dataApi.getChartData("close_ratio", brokerId)])
            .then(responses => {
                if (responses && responses[0] && responses[1] && responses[2] && responses[3] && responses[4] && this._mounted) {
                    this.setState({
                        groupsQuotedData: responses[0],
                        incompleteQuotesData: responses[1],
                        brokerRBGQuotesData: responses[2],
                        renewalsData: responses[3],
                        closeRatio3AgoData: responses[4][0],
                        closeRatio2AgoData: responses[4][1],
                        closeRatio1AgoData: responses[4][2],
                        dashboardLoaded: true
                    });
                }
            })

    };


    _searchResultSelected = (item) => {
        if (item.type === "Employer") {
            this.props.history.push(routeHelpers.groupDetail(item.value));
        } else if (item.type === "Broker") {
            this.props.history.push(routeHelpers.brokerDetail(item.value));
        } else if (item.type === "Quote") {
            this.props.history.push(routeHelpers.quoteDetail(item.value));
        }
    };

    _goToGroups = (month) => {
        window.location.href = "/groups?ren=" + month;
    };

    _goToQuotes = (month, stage) => {
        window.location.href = "/quotes?eff=" + month + "&sta=" + stage;
    };

    renderTable = (what) => {
        let news = what === 1 ? this.state.news : this.state.alerts;
        return (
            <table className={css(styles.table)}>
                <thead>
                    <tr>
                        <td className={css(styles.tableHeader)}>Carrier</td>
                        <td className={css(styles.tableHeader)}>Effective Date</td>
                        <td className={css(styles.tableHeader)}>Valid Through</td>
                        <td className={css(styles.tableHeader)}>Notes</td>
                    </tr>
                </thead>
                <tbody>
                    {news.map(n => {
                        return (
                            <tr key={n.id}>
                                <td className={css(styles.tableRow)}>{n.carrierName}</td>
                                <td className={css(styles.tableRow)}>{n.startDateString}</td>
                                <td className={css(styles.tableRow)}>{n.endDateString}</td>
                                <td className={css(styles.tableRow)}>{n.text}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        );
    }

    renderTableProductAvailability = (products) => {
        let currentState = "";
        let currentColor = "green";
        return (
            <table className={css(styles.table)}>
                <thead>
                    <tr>
                        <td rowSpan="2" className={css(styles.tableHeader)}>State</td>
                        <td rowSpan="2" className={css(styles.tableHeader)}>Carrier</td>
                        <td rowSpan="2" className={css(styles.tableHeader)}>Medical Market</td>
                        <td rowSpan="2" className={css(styles.tableHeader)}>Quoting Method</td>
                        <td rowSpan="2" className={css(styles.tableHeader)}>Can Quote<br />Medical Through</td>
                        <td colSpan="4" className={css(styles.tableHeaderNoBorder, styles.tableHeaderCurrentQuarter)}>Current Quarter</td>
                        <td colSpan="4" className={css(styles.tableHeaderNoBorder, styles.tableHeaderNextQuarter)}>Next Quarter</td>
                        <td rowSpan="2" className={css(styles.tableHeaderNoBorder)} style={{ width: "25%" }}>{""}</td>
                    </tr>
                    <tr>
                        <td className={css(styles.tableHeader, styles.tableHeaderBlue)}>Medical</td>
                        <td className={css(styles.tableHeader, styles.tableHeaderBlue)}>Dental</td>
                        <td className={css(styles.tableHeader, styles.tableHeaderBlue)}>Vision</td>
                        <td className={css(styles.tableHeader, styles.tableHeaderBlue)}>Life</td>
                        <td className={css(styles.tableHeader, styles.tableHeaderGreen)}>Medical</td>
                        <td className={css(styles.tableHeader, styles.tableHeaderGreen)}>Dental</td>
                        <td className={css(styles.tableHeader, styles.tableHeaderGreen)}>Vision</td>
                        <td className={css(styles.tableHeader, styles.tableHeaderGreen)}>Life</td>
                    </tr>
                </thead>
                <tbody>
                    {products.map((n, index) => {
                        if (currentState === "" || currentState !== n.state) {
                            currentState = n.state;
                            currentColor = currentColor === "blue" ? "green" : "blue";
                        }
                        else {
                            n.state = "";
                        }
                        return (
                            <tr key={index + n.carrier}>
                                <td className={css(styles.tableRowRightBorder, n.state === "" ? "" : styles.tableRowTopBorder, currentColor === "blue" ? styles.stateNameColBlue : styles.stateNameColGreen)}>{n.state}</td>
                                <td className={css(styles.tableRow)}>{n.carrier}</td>
                                <td className={css(styles.tableRow)}>{n.medicalMarket}</td>
                                <td className={css(styles.tableRow)}>{n.quotingMethod}</td>
                                <td className={css(styles.tableRow)}>{(n.medicalPlansCurrentQuarter === "YES" || n.medicalPlansNextQuarter === "YES") && n.medicalValidThroughDate !== "" ? format(n.medicalValidThroughDate, "M/D/YYYY") : "-"}</td>
                                <td className={css(styles.tableRow, n.medicalPlansCurrentQuarter === "YES" ? styles.tdLive : n.medicalPlansCurrentQuarter === "NO" ? styles.tdNotLive : styles.tdNA)}>{n.medicalPlansCurrentQuarter === "YES" ? String.fromCharCode(10003) : n.medicalPlansCurrentQuarter === "NO" ? String.fromCharCode(10005) : n.medicalPlansCurrentQuarter}</td>
                                <td className={css(styles.tableRow, n.dentalPlansCurrentQuarter === "YES" ? styles.tdLive : n.dentalPlansCurrentQuarter === "NO" ? styles.tdNotLive : styles.tdNA)}>{n.dentalPlansCurrentQuarter === "YES" ? String.fromCharCode(10003) : n.dentalPlansCurrentQuarter === "NO" ? String.fromCharCode(10005) : n.dentalPlansCurrentQuarter}</td>
                                <td className={css(styles.tableRow, n.visionPlansCurrentQuarter === "YES" ? styles.tdLive : n.visionPlansCurrentQuarter === "NO" ? styles.tdNotLive : styles.tdNA)}>{n.visionPlansCurrentQuarter === "YES" ? String.fromCharCode(10003) : n.visionPlansCurrentQuarter === "NO" ? String.fromCharCode(10005) : n.visionPlansCurrentQuarter}</td>
                                <td className={css(styles.tableRow, n.lifePlansCurrentQuarter === "YES" ? styles.tdLive : n.lifePlansCurrentQuarter === "NO" ? styles.tdNotLive : styles.tdNA)}>{n.lifePlansCurrentQuarter === "YES" ? String.fromCharCode(10003) : n.lifePlansCurrentQuarter === "NO" ? String.fromCharCode(10005) : n.lifePlansCurrentQuarter}</td>
                                <td className={css(styles.tableRow, n.medicalPlansNextQuarter === "YES" ? styles.tdLive : n.medicalPlansNextQuarter === "NO" ? styles.tdNotLive : styles.tdNA)}>{n.medicalPlansNextQuarter === "YES" ? String.fromCharCode(10003) : n.medicalPlansNextQuarter === "NO" ? String.fromCharCode(10005) : n.medicalPlansNextQuarter}</td>
                                <td className={css(styles.tableRow, n.dentalPlansNextQuarter === "YES" ? styles.tdLive : n.dentalPlansNextQuarter === "NO" ? styles.tdNotLive : styles.tdNA)}>{n.dentalPlansNextQuarter === "YES" ? String.fromCharCode(10003) : n.dentalPlansNextQuarter === "NO" ? String.fromCharCode(10005) : n.dentalPlansNextQuarter}</td>
                                <td className={css(styles.tableRow, n.visionPlansNextQuarter === "YES" ? styles.tdLive : n.visionPlansNextQuarter === "NO" ? styles.tdNotLive : styles.tdNA)}>{n.visionPlansNextQuarter === "YES" ? String.fromCharCode(10003) : n.visionPlansNextQuarter === "NO" ? String.fromCharCode(10005) : n.visionPlansNextQuarter}</td>
                                <td className={css(styles.tableRow, n.lifePlansNextQuarter === "YES" ? styles.tdLive : n.lifePlansNextQuarter === "NO" ? styles.tdNotLive : styles.tdNA)}>{n.lifePlansNextQuarter === "YES" ? String.fromCharCode(10003) : n.lifePlansNextQuarter === "NO" ? String.fromCharCode(10005) : n.lifePlansNextQuarter}</td>
                                <td className={css(styles.tableRowLeftBorder)}>{" "}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        );
    }

    _getProductAvailabilityReport = () => {
        if (this.state.generatingReport) {
            return;
        }
        this.setState({ generatingReport: true });
        //submit to output generator
        dataApi.getProductAvailabilityReport()
            .then(response => {
                this.setState({ generatingReport: false });
                if (response && response.startsWith("ERROR")) {
                    console.log("Report generation error: " + response);
                    toast.error("There was an error encountered while generating your report.", { toastId: 3 });
                }
            });
    }

    render() {
        const { me } = this.props;
        const { groupsQuotedData, incompleteQuotesData, brokerRBGQuotesData, renewalsData, closeRatio3AgoData, closeRatio2AgoData, closeRatio1AgoData, news, alerts, products, dashboardLoaded } = this.state;

        if (!dashboardLoaded) {
            return (
                <div className={css(styles.content)}>
                    <div className={css(styles.contentSection)}>
                        <Spinner
                            aStyles={styles.loadingSpinner}
                            text="Loading Dashboard..."
                        />
                    </div>
                </div>
            );
        }

        const closeRatio1Ago = closeRatio1AgoData.datasets && closeRatio1AgoData.datasets[0] && closeRatio1AgoData.datasets[0].data && closeRatio1AgoData.datasets[0].data[0] !== "0" && closeRatio1AgoData.datasets[0].data[1] !== 0 ? parseInt((closeRatio1AgoData.datasets[0].data[1] / (closeRatio1AgoData.datasets[0].data[0] + closeRatio1AgoData.datasets[0].data[1])) * 100) : 0;
        const closeRatio2Ago = closeRatio2AgoData.datasets && closeRatio2AgoData.datasets[0] && closeRatio2AgoData.datasets[0].data && closeRatio2AgoData.datasets[0].data[0] !== "0" && closeRatio2AgoData.datasets[0].data[1] !== 0 ? parseInt((closeRatio2AgoData.datasets[0].data[1] / (closeRatio2AgoData.datasets[0].data[0] + closeRatio2AgoData.datasets[0].data[1])) * 100) : 0;
        const closeRatio3Ago = closeRatio3AgoData.datasets && closeRatio3AgoData.datasets[0] && closeRatio3AgoData.datasets[0].data && closeRatio3AgoData.datasets[0].data[0] !== "0" && closeRatio3AgoData.datasets[0].data[1] !== 0 ? parseInt((closeRatio3AgoData.datasets[0].data[1] / (closeRatio3AgoData.datasets[0].data[0] + closeRatio3AgoData.datasets[0].data[1])) * 100) : 0;

        const closeRatio1AgoQuoted = closeRatio1AgoData.datasets && closeRatio1AgoData.datasets[0] && closeRatio1AgoData.datasets[0].data ? (closeRatio1AgoData.datasets[0].data[0] + closeRatio1AgoData.datasets[0].data[1]) : "0";
        const closeRatio1AgoSold = closeRatio1AgoData.datasets && closeRatio1AgoData.datasets[0] && closeRatio1AgoData.datasets[0].data ? closeRatio1AgoData.datasets[0].data[1] : "0";
        const closeRatio1AgoLost = closeRatio1AgoData.datasets && closeRatio1AgoData.datasets[0] && closeRatio1AgoData.datasets[0].data ? closeRatio1AgoData.datasets[0].data[2] : "0";
        const closeRatio2AgoQuoted = closeRatio2AgoData.datasets && closeRatio2AgoData.datasets[0] && closeRatio2AgoData.datasets[0].data ? (closeRatio2AgoData.datasets[0].data[0] + closeRatio2AgoData.datasets[0].data[1]) : "0";
        const closeRatio2AgoSold = closeRatio2AgoData.datasets && closeRatio2AgoData.datasets[0] && closeRatio2AgoData.datasets[0].data ? closeRatio2AgoData.datasets[0].data[1] : "0";
        const closeRatio2AgoLost = closeRatio2AgoData.datasets && closeRatio2AgoData.datasets[0] && closeRatio2AgoData.datasets[0].data ? closeRatio2AgoData.datasets[0].data[2] : "0";
        const closeRatio3AgoQuoted = closeRatio3AgoData.datasets && closeRatio3AgoData.datasets[0] && closeRatio3AgoData.datasets[0].data ? (closeRatio3AgoData.datasets[0].data[0] + closeRatio3AgoData.datasets[0].data[1]) : "0";
        const closeRatio3AgoSold = closeRatio3AgoData.datasets && closeRatio3AgoData.datasets[0] && closeRatio3AgoData.datasets[0].data ? closeRatio3AgoData.datasets[0].data[1] : "0";
        const closeRatio3AgoLost = closeRatio3AgoData.datasets && closeRatio3AgoData.datasets[0] && closeRatio3AgoData.datasets[0].data ? closeRatio3AgoData.datasets[0].data[2] : "0";

        const censusAvg = parseInt((closeRatio1AgoData.censusAvg + closeRatio2AgoData.censusAvg + closeRatio3AgoData.censusAvg) / 3);

        //have data?
        const groupsQuotedHasData = groupsQuotedData && groupsQuotedData.datasets &&
            groupsQuotedData.datasets[0] && groupsQuotedData.datasets[0].data && (groupsQuotedData.datasets[0].data[0] > 0 || groupsQuotedData.datasets[0].data[1] > 0 || groupsQuotedData.datasets[0].data[2] > 0) ? true : false;
        const incompleteQuotesHasData = incompleteQuotesData && incompleteQuotesData.datasets &&
            ((incompleteQuotesData.datasets[0] && incompleteQuotesData.datasets[0].data && (incompleteQuotesData.datasets[0].data[0] > 0 || incompleteQuotesData.datasets[0].data[1] > 0 || incompleteQuotesData.datasets[0].data[2] > 0)) ||
                (incompleteQuotesData.datasets[1] && incompleteQuotesData.datasets[1].data && (incompleteQuotesData.datasets[1].data[0] > 0 || incompleteQuotesData.datasets[1].data[1] > 0 || incompleteQuotesData.datasets[1].data[2] > 0)) ||
                (incompleteQuotesData.datasets[2] && incompleteQuotesData.datasets[2].data && (incompleteQuotesData.datasets[2].data[0] > 0 || incompleteQuotesData.datasets[2].data[1] > 0 || incompleteQuotesData.datasets[2].data[2] > 0))) ? true : false;
        const brokerRBGQuotesHasData = brokerRBGQuotesData && brokerRBGQuotesData.datasets &&
            ((brokerRBGQuotesData.datasets[0] && brokerRBGQuotesData.datasets[0].data && (brokerRBGQuotesData.datasets[0].data[0] > 0 || brokerRBGQuotesData.datasets[0].data[1] > 0 || brokerRBGQuotesData.datasets[0].data[2] > 0)) ||
                (brokerRBGQuotesData.datasets[1] && brokerRBGQuotesData.datasets[1].data && (brokerRBGQuotesData.datasets[1].data[0] > 0 || brokerRBGQuotesData.datasets[1].data[1] > 0 || brokerRBGQuotesData.datasets[1].data[2] > 0))) ? true : false;
        const renewalsHasData = renewalsData && renewalsData.datasets &&
            renewalsData.datasets[0] && renewalsData.datasets[0].data && (renewalsData.datasets[0].data[0] > 0 || renewalsData.datasets[0].data[1] > 0 || renewalsData.datasets[0].data[2] > 0) ? true : false;
        const closeRatio1AgoHasData = closeRatio1AgoQuoted > 0;
        const closeRatio2AgoHasData = closeRatio2AgoQuoted > 0;
        const closeRatio3AgoHasData = closeRatio3AgoQuoted > 0;

        //chart options
        const doughnut_options1 = {
            responsive: true,
            cutoutPercentage: 65,
            plugins: {
                datalabels: {
                    display: false,
                },
            },
            onClick: (event, elements) => {
                const chart = elements[0]._chart;
                const element = chart.getElementAtEvent(event)[0];
                const xLabel = chart.data.labels[element._index];
                let stage = "";
                if (xLabel !== undefined && xLabel !== "Quoted") {
                    stage = xLabel;
                }
                const month = closeRatio1AgoData ? closeRatio1AgoData.month : "";
                this._goToQuotes(month, stage);
            }
        };
        const doughnut_options2 = {
            legend: {
                display: false
            },
            responsive: true,
            cutoutPercentage: 60,
            plugins: {
                datalabels: {
                    display: false,
                },
            },
            onClick: (event, elements) => {
                const chart = elements[0]._chart;
                const element = chart.getElementAtEvent(event)[0];
                const xLabel = chart.data.labels[element._index];
                let stage = "";
                if (xLabel !== undefined && xLabel !== "Quoted") {
                    stage = xLabel;
                }
                //left or right doughnut?
                let month = closeRatio2AgoData ? closeRatio2AgoData.month : "";
                if (chart.id === 3) {
                    month = closeRatio3AgoData ? closeRatio3AgoData.month : "";
                }
                this._goToQuotes(month, stage);
            }
        };
        const bar_options = {
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        fontSize: 20,
                        fontStyle: 'bold',
                        fontColor: color.darkBlue2,
                    },
                    position: 'top',
                }],
                yAxes: [{
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                        beginAtZero: true,
                    }
                }],
            },
            responsive: true,
            plugins: {
                datalabels: {
                    color: 'white',
                    font: {
                        weight: 'bold',
                        size: 20,
                    },
                    anchor: 'end',
                    align: 'start',
                    offset: 0,
                },
            },
            onClick: (event, elements) => {
                const chart = elements[0]._chart;
                const element = chart.getElementAtEvent(event)[0];
                const dataset = chart.data.datasets[element._datasetIndex];
                let stage = "";
                if (dataset.label !== undefined && dataset.label.startsWith("Stage")) {
                    stage = dataset.label;
                }
                const xLabel = chart.data.labels[element._index];
                let month = "";
                if (xLabel !== undefined) {
                    month = xLabel;
                }
                //Renewals...  go to Groups, else Quotes
                if (chart.id === 6) {
                    this._goToGroups(month);
                } else {
                    this._goToQuotes(month, stage);
                }
            }
        };

        return (
            <div className={css(styles.content)}>
                <ToastContainer position="top-center" autoClose={2500} />
                <div>
                    <SearchInput
                        aStyles={styles.searchBox}
                        search={searchApi.searchEmployersBrokersAndQuotes}
                        onItemSelected={this._searchResultSelected}
                        placeholder={strings.searchPlaceholder}
                        hideLabel={true}
                    />
                </div>
                <div className={css(styles.contentSection)}>
                    <div className={css(styles.titleSection)}>
                        <PageTitle icon={HomeDashboardIcon}>{me.isBroker && me.brokerId ? strings.brokerTitle : strings.rbgTitle}</PageTitle>
                    </div>
                    <div className={css(styles.charts)}>
                        <table className={css(styles.table)}>
                            <tbody>
                                <tr>
                                    <td className={css(styles.tdChart, styles.thirty)}>
                                        <span className={css(styles.chartTitle)}>Groups Quoted</span>
                                        <div className={css(styles.barChartHolder)}>
                                            {groupsQuotedHasData ? (
                                                <Bar data={groupsQuotedData} options={bar_options} />
                                            ) : <span className={css(styles.smallText)}>No Data</span>}
                                        </div>
                                    </td>
                                    <td className={css(styles.tdChart, styles.forty)} rowSpan="2">
                                        <span className={css(styles.chartTitle)}>Close Ratio</span>
                                        {closeRatio1AgoHasData ? (
                                            <div className={css(styles.relative)}>
                                                <Doughnut data={closeRatio1AgoData} options={doughnut_options1} />
                                                <div className={css(styles.absoluteCenter, styles.textCenter)}>
                                                    <span className={css(styles.largeText)}><span className={css(styles.gray)} onClick={() => this._goToQuotes(closeRatio1AgoData.month, null)}>{closeRatio1AgoQuoted}</span><br /><span className={css(styles.green)} onClick={() => this._goToQuotes(closeRatio1AgoData.month, 'Sold')}>{closeRatio1AgoSold}</span>{" "}<span className={css(styles.darkred)} onClick={() => this._goToQuotes(closeRatio1AgoData.month, 'Lost')}>{closeRatio1AgoLost}</span><br />{closeRatio1Ago}%</span>
                                                </div>
                                                <div className={css(styles.textCenter)}>
                                                    <span className={css(styles.largeText)}>{closeRatio1AgoData.month}</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={css(styles.relative, styles.minHeight)}>
                                                <span className={css(styles.smallText)}>No Data<br />{closeRatio1AgoData.month}</span>
                                            </div>
                                        )}
                                        <div className={css(styles.doughnutHolderLeft)}>
                                            {closeRatio2AgoHasData ? (
                                                <div className={css(styles.relative)}>
                                                    <Doughnut data={closeRatio2AgoData} options={doughnut_options2} />
                                                    <div className={css(styles.absoluteCenter, styles.textCenter, styles.minus10)}>
                                                        <span className={css(styles.smallText)}><span className={css(styles.gray)} onClick={() => this._goToQuotes(closeRatio2AgoData.month, null)}>{closeRatio2AgoQuoted}</span><br /><span className={css(styles.green)} onClick={() => this._goToQuotes(closeRatio2AgoData.month, 'Sold')}>{closeRatio2AgoSold}</span>{" "}<span className={css(styles.darkred)} onClick={() => this._goToQuotes(closeRatio2AgoData.month, 'Lost')}>{closeRatio2AgoLost}</span><br />{closeRatio2Ago}%</span>
                                                    </div>
                                                    <div className={css(styles.textCenter)}>
                                                        <span className={css(styles.smallText)}>{closeRatio2AgoData.month}</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className={css(styles.relative, styles.minHeight)}>
                                                    <span className={css(styles.smallText)}>No Data<br />{closeRatio2AgoData.month}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className={css(styles.doughnutHolderRight)}>
                                            {closeRatio3AgoHasData ? (
                                                <div className={css(styles.relative)}>
                                                    <Doughnut data={closeRatio3AgoData} options={doughnut_options2} />
                                                    <div className={css(styles.absoluteCenter, styles.textCenter, styles.minus10)}>
                                                        <span className={css(styles.smallText)}><span className={css(styles.gray)} onClick={() => this._goToQuotes(closeRatio3AgoData.month, null)}>{closeRatio3AgoQuoted}</span><br /><span className={css(styles.green)} onClick={() => this._goToQuotes(closeRatio3AgoData.month, 'Sold')}>{closeRatio3AgoSold}</span>{" "}<span className={css(styles.darkred)} onClick={() => this._goToQuotes(closeRatio3AgoData.month, 'Lost')}>{closeRatio3AgoLost}</span><br />{closeRatio3Ago}%</span>
                                                    </div>
                                                    <div className={css(styles.textCenter)}>
                                                        <span className={css(styles.smallText)}>{closeRatio3AgoData.month}</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className={css(styles.relative, styles.minHeight)}>
                                                    <span className={css(styles.smallText)}>No Data<br />{closeRatio3AgoData.month}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className={css(styles.groupSizeHolder)}>
                                            <span className={css(styles.chartTitle)}><br />Avg Group Size: {censusAvg}<br /></span>
                                            <span className={css(styles.chartInfo)}><br />For in force business</span>
                                        </div>
                                    </td>
                                    <td className={css(styles.tdChart, styles.thirty)}>
                                        <span className={css(styles.chartTitle)}>Incomplete Quotes</span>
                                        <span className={css(styles.chartLegend)}>
                                            <table className={css(styles.table2)}>
                                                <tbody>
                                                    <tr>
                                                        <td className={css(styles.stage1Color)}>Stage 1</td>
                                                        <td className={css(styles.stage2Color)}>Stage 2</td>
                                                        <td className={css(styles.stage3Color)}>Stage 3</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </span>
                                        <div className={css(styles.barChartHolder)}>
                                            {incompleteQuotesHasData ? (
                                                <Bar data={incompleteQuotesData} options={bar_options} />
                                            ) : <span className={css(styles.smallText)}>No Data</span>}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={css(styles.tdChart, styles.thirty)}>
                                        <span className={css(styles.chartTitle)}>Broker vs. Emerson Rogers Quotes</span>
                                        <span className={css(styles.chartLegend)}>
                                            <table className={css(styles.table2)}>
                                                <tbody>
                                                    <tr>
                                                        <td className={css(styles.brokerColor)}>Broker</td>
                                                        <td className={css(styles.rbgColor)}>RBG</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </span>
                                        <div className={css(styles.barChartHolder)}>
                                            {brokerRBGQuotesHasData ? (
                                                <Bar data={brokerRBGQuotesData} options={bar_options} />
                                            ) : <span className={css(styles.smallText)}>No Data</span>}
                                        </div>
                                    </td>
                                    <td className={css(styles.tdChart, styles.thirty)}>
                                        <span className={css(styles.chartTitle)}>Renewals</span>
                                        <div className={css(styles.barChartHolder)}>
                                            {renewalsHasData ? (
                                                <Bar data={renewalsData} options={bar_options} />
                                            ) : <span className={css(styles.smallText)}>No Data</span>}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {products.length > 0 &&
                    <div className={css(styles.contentSection)}>
                        <div className={css(styles.titleSection)}>
                            <PageTitle icon={alertIcon}>{strings.products}</PageTitle>
                        </div>
                        <div label="Product Availability">
                            {me.isSysAdmin &&
                                <div className={css(styles.downloadFloatRight)} onClick={this._getProductAvailabilityReport}>
                                    <img className={css(styles.downloadReport)} src={downloadIcon} alt="download" /> Full Report (All States)
                                </div>
                            }
                            {this.renderTableProductAvailability(products)}
                        </div>
                    </div>}
                {(news.length > 0 || alerts.length > 0) &&
                    <div className={css(styles.contentSection)}>
                        <div className={css(styles.titleSection)}>
                            <PageTitle icon={shieldIcon}>{strings.alerts}</PageTitle>
                        </div>
                        <Tabs>
                            <div label="Rate Availability">
                                {this.renderTable(1)}
                            </div>
                            <div label="Alerts">
                                {this.renderTable(0)}
                            </div>
                        </Tabs>
                    </div>}
            </div>
        );
    }
}
const mapDispatchToProps = state => ({
    me: state.session.me,
});

export default connect(mapDispatchToProps)(UserDashboardScreen);
