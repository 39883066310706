import * as color from "../../constants/color";
import * as texchangePropTypes from "../../texchangePropTypes";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { ReactComponent as EditIcon } from "../../images/SVGs/edit-field.svg";


const styles = StyleSheet.create({    
    container: {
        borderBottom: `1px solid ${color.darkGray6}`,
        paddingBottom: 12,
        marginBottom: 12,
    },
    placeholder: {
        color: color.darkGray5,
        fontWeight: 400,
    },
    editIcon: {
        width: 18,
        height: 18,
        fill: color.darkBlue2,
    },
    editButton: {
        backgroundColor: color.white,
        border: "none",
        outline: "none",
        cursor: "pointer",
        float: "right",
        marginTop: 0,
        color: color.mediumYellow1,
        fontSize: 14,
    },
    headerLabel: {
        float: "left",
        fontSize: 14,
        fontWeight: 500,
        color: color.darkGray,
    },
    cancelButton: {
        
    },
    saveButton: {

    },
    editControlContainer: {
        clear: "both",
    },
    displayContainer: {
        clear: "both",
        color: color.darkestGray,
        fontSize: 16,
    },
    empty: {
        paddingBottom: 18,
    }
});

const strings = {
    cancel: "Cancel",
    save: "Save",
}   

export default class InlineEditInput extends Component {
    static propTypes = {
        aStyles: texchangePropTypes.aphroditeStyles,
        headerLabel: PropTypes.string.isRequired,        
        children: PropTypes.instanceOf(Array).isRequired,
        onSave: PropTypes.func,
        onCancel: PropTypes.func,
        editable: PropTypes.bool,
    };

    static defaultProps = {
        editable: true,
    };

    state = {
        isEditing: false,
        originalValue: "",
    }

    constructor(props) {
        super(props);
    }

    _onEdit = () => {
        this.setState({ isEditing: true });
    };

    _onCancelEdit = () => {        
        this.setState({ isEditing: false });

        if (this.props.onCancel) {
            this.props.onCancel();
        }
    };

    _onSave = () => {        
        if (this.props.onSave) {
            this.props.onSave()
                .then(result => {
                    if (result) {
                        this.setState({ isEditing: false });
                    }
                });
        }
    };

    render() {        
        const { aStyles, headerLabel, children, editable } = this.props
        const { isEditing } = this.state;
        return (
            <div className={css(styles.container, aStyles)}>
                {!isEditing ? (
                    <div>
                        <div className={css(styles.headerLabel)}>{headerLabel}</div>
                        {editable ? (
                            <button className={css(styles.editButton)} type="button" onClick={this._onEdit}>
                                <EditIcon className={css(styles.editIcon)} />
                            </button>
                        ) : (
                            <div className={css(styles.empty)}></div>
                        )}
                        <div className={css(styles.displayContainer)}>
                            {children.map((child) => {                                                            
                                if (child.props.display) {
                                    return child.props.children;
                                }
                                else {
                                    return null;
                                }
                            })}
                        </div>
                    </div>                    
                    ) : (
                    <div>                        
                        <button className={css(styles.editButton, styles.cancelButton)} type="button" onClick={this._onCancelEdit}>
                            {strings.cancel}
                        </button>
                        <button className={css(styles.editButton, styles.saveButton)} type="button" onClick={this._onSave}>
                            {strings.save}
                        </button>
                        <div className={css(styles.editControlContainer)}>
                            {children.map((child) => {                                                            
                                if (child.props.edit) {
                                    return child.props.children;
                                }
                                else {
                                    return null;
                                }
                            })}                                                        
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

