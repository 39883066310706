import * as color from "../constants/color";
import * as texchangePropTypes from "../texchangePropTypes";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    container: {
        position: "relative",
        display: "inline-block",
        borderRadius: 12,

        ":after": {
            content: "",
            clear: "both",
            display: "table",
        },
    },
    containerDisabled: {
        backgroundColor: color.transparent,
    },
    button: {
        backgroundColor: color.blue1,
        color: "inherit",
        cursor: "pointer",
        font: "inherit",
        paddingTop: 13,
        paddingRight: 24,
        paddingBottom: 13,
        paddingLeft: 24,
        margin: 0,
        width: "100%",
        marginRight: 4,
        marginBottom: 3,
        float: "left",
        outlineColor: color.borderBackgroundGray,
        border: `1px solid ${color.darkGray7}`,
        borderRadius: 4,
        outlineOffset: 2,

        ":focus": {
            outline: "none",
        },
        ":enabled:active": {
            outline: "none",
            marginTop: 3,
            marginBottom: 0,
            marginLeft: 2,
            paddingRight: 22,
            paddingLeft: 22,
        },
    },
    buttonDisabled: {
        outlineColor: color.mediumBlue3,
        border: `1px solid ${color.mediumBlue3}`,
        backgroundColor: color.blue1,
        cursor: "default",
    },
    buttonDisabledWhite: {
        backgroundColor: color.white,
        border: `1px solid ${color.darkGray}`,
    },
    label: {
        color: color.white,
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: 0.3,
        float: "left",
        textAlign: "left",
        whiteSpace: "nowrap",
    },
    labelCentered: {
        width: "100%",
        textAlign: "center",
    },
    labelDisabled: {
        color: color.mediumBlue3,
    },
    secondaryLabel: {
        marginLeft: 32,
        textAlign: "right",
        float: "right",
    },
    labelInverted: {
        color: color.darkGray,
    },
    loadingImage: {
        fill: color.white,
        float: "right",
        width: 20,
        height: 20,
    },
    loading: {
        ":before": {
            margin: "-13px 0 0 -13px",
            width: 24,
            height: 24,
            position: "absolute",
            left: "50%",
            top: "50%",
            content: "''",
            backgroundClip: "padding-box",
            borderRadius: 24,
            border: `2px solid ${color.whiteAlpha25}`,
            borderTopColor: color.white,
            animation: "animation-rotate 750ms linear infinite",

        },
    },
});

export default class ActionButton extends Component {
    static propTypes = {
        aStyles: texchangePropTypes.aphroditeStyles,
        disabled: PropTypes.bool.isRequired,
        invertColor: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
        secondaryLabel: PropTypes.string,
        href: PropTypes.string,
        isLoading: PropTypes.bool.isRequired,
        loadingText: PropTypes.string,
    };

    static defaultProps = {
        disabled: false,
        invertColor: false,
        isLoading: false,
    };

    _renderLabel = (label, secondaryLabel, disabled, invertColor, isLoading) => (
        <div>
            <span
                className={css(
                    styles.label,
                    disabled || isLoading ? styles.labelDisabled : null,
                    !secondaryLabel ? styles.labelCentered : null,
                    invertColor ? styles.labelInverted : null,
                    isLoading ? styles.loading : null
                )}
            >
                {!isLoading ? label : <span>&nbsp;</span>}


            </span>
            {secondaryLabel ? (
                <span
                    className={css(
                        styles.label,
                        styles.secondaryLabel,
                        disabled ? styles.labelDisabled : null,
                    )}
                >
                    {secondaryLabel}
                </span>
            ) : null}
        </div>
    );

    render() {
        const { aStyles, disabled, invertColor, label, secondaryLabel, href, isLoading, ...rest } = this.props;

        let element;
        if (href) {
            element = (
                <a
                    href={href}
                    disabled={disabled}
                    className={css(
                        styles.button,
                        disabled ? styles.buttonDisabled : null,
                        invertColor ? styles.buttonDisabledWhite : null,
                    )}
                >
                    {this._renderLabel(label, secondaryLabel, disabled, invertColor)}
                </a>
            );
        } else {
            element = (
                <button
                    type="button"
                    {...rest}
                    disabled={disabled || isLoading}
                    className={css(
                        styles.button,
                        disabled || isLoading ? styles.buttonDisabled : null,
                        invertColor ? styles.buttonDisabledWhite : null,
                    )}
                >
                    {this._renderLabel(label, secondaryLabel, disabled, invertColor, isLoading)}
                </button>
            );
        }

        return (
            <div
                className={css(
                    styles.container,
                    disabled ? styles.containerDisabled : null,
                    aStyles,
                )}
            >
                {element}
            </div>
        );
    }
}
